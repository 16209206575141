import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {DataTablesModule} from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { WebcamModule } from 'ngx-webcam';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PopoverModule } from "ngx-bootstrap/popover";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ResizableModule } from './shared/generic-table-resizable-column-directive/resizable.module';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare, faAnglesLeft, faAnglesRight, faArrowRightFromBracket, faPlaneArrival, faPlaneDeparture, faChartSimple, faChartColumn, faEllipsisVertical, faPenToSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AppRoutingModule,appRoutes } from './routes';
import { AppComponent } from './app.component';
import { UserService } from './shared/user.service';
import { ExcelService } from './shared/excel.service';
import { asyncCustumValidation } from './shared/async-custum-validation';
import { AlertDialogBoxComponent } from './shared/alert-dialog-box/alert-dialog-box.component';
import { AlertDialogBoxService } from './shared/alert-dialog-box/alert-dialog-box.service';
import { ConfirmDialogBoxService } from './shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from './shared/global-alert.service';
import { AlertDialogBoxEventComponent } from './shared/alert-dialog-box-event/alert-dialog-box-event.component';
import { ConfirmDialogBoxEventComponent } from './shared/confirm-dialog-box-event/confirm-dialog-box-event.component';
import { GenericPrintFormComponent } from './shared/generic-print-form/generic-print-form.component';
import {GenericPrintService} from './shared/generic-print-service';
import { PrintLayoutComponent } from './shared/print-layout/print-layout.component';
import { SafeHtmlPipe } from './shared/safe-html-pipe';
import { DragDropDirective } from './shared/customDirective/drag-drop.directive';
import { SearchFilterPipe } from './shared/customPipes/search-filter.pipe';
import { CaptchaComponent } from './shared/captcha/captcha.component';
import { DefaultComponent } from './layout/default/default.component';
import { PublicComponent } from './layout/public/public.component';
import { SecureComponent } from './layout/secure/secure.component';
import { HomeComponent } from './default/home/home.component';
import { RedirectComponent } from './default/redirect-component';
import { UserComponent } from './public/user/user.component';
import { SignInComponent } from './public/user/sign-in/sign-in.component';
import { ResetPasswordComponent } from './public/user/reset-password/reset-password.component';
import { LandingPageComponent } from './public/landing-page/landing-page.component';
import { PublicGenericMasterFormComponent } from './custum/public-generic-master-form/public-generic-master-form.component';
import { PublicGenericMultiFormComponent } from './custum/public-generic-multi-form/public-generic-multi-form.component';
import { PublicGenericReportFormComponent } from './custum/public-generic-report-form/public-generic-report-form.component';
import { HeaderComponent } from './secure/header/header.component';
import { FooterComponent } from './secure/footer/footer.component';
import { SideLoginComponent } from './secure/sidelogin/sidelogin.component';
import { BreadCumComponent } from './secure/bread-cum/bread-cum.component';
import { DashboardComponent } from './secure/dashboard/dashboard.component';
import { ChangepasswordComponent } from './secure/changepassword/changepassword.component';
import { ManageprofileComponent } from './secure/manageprofile/manageprofile.component';
import { WFMasterComponent } from './secure/wfmaster/wfmaster.component';
import { GenericMasterFormComponent } from './secure/generic-master-form/generic-master-form.component';
import { GenerateMasterFormComponent } from './secure/generate-master-form/generate-master-form.component';
import { generateReportFormComponent } from './secure/generate-report-form/generate-report-form.component';
import { GenerateCustumFormComponent } from './secure/generate-custum-form/generate-custum-form.component';
import { GenericReportFormComponent } from './secure/generic-report-form/generic-report-form.component';
import { GenericMultiTableFormComponent } from './secure/generic-multi-table-form/generic-multi-table-form.component';
import { UploadFileComponent } from './shared/upload-file/upload-file.component';
import { WorkFlowProcessComponent } from './shared/work-flow-process/work-flow-process.component';
import { CaptureImageComponent } from './shared/capture-image/capture-image.component';
import { PreviewImageComponent } from './shared/preview-image/preview-image.component';
import { GenericPrintComponent } from './shared/generic-print/generic-print.component';
import { GenericListFormComponent } from './secure/generic-list-form/generic-list-form.component';
import { EllipsifyLongTextDirective } from './shared/sharedDirective/ellipsify-long-text.directive';
import { GenericTableListViewComponent } from './shared/generic-table-list-view/generic-table-list-view.component';
import { GenericTableViewAlertComponent } from './shared/generic-table-view-alert/generic-table-view-alert.component';
import { GenericChildActionModalComponent } from './shared/generic-child-action-modal/generic-child-action-modal.component';
import { PublicGenericLandingPageComponent } from './custum/public-generic-landing-page/public-generic-landing-page.component';
import { CustumComponent } from './layout/custum/custum.component';
import { PublicGenericHeaderComponent } from './custum/public-generic-header/public-generic-header.component';
import { PublicGenericFooterComponent } from './custum/public-generic-footer/public-generic-footer.component';
import { PublicGenericAboutPageComponent } from './custum/public-generic-about-page/public-generic-about-page.component';
import { PublicGenericContactPageComponent } from './custum/public-generic-contact-page/public-generic-contact-page.component';
import { PublicGenericPhotoPageComponent } from './custum/public-generic-photo-page/public-generic-photo-page.component';
import { PayFeeDuesComponent } from './secure/SchoolERPFee/pay-fee-dues/pay-fee-dues.component';
import { GenericChildDropDownComponent } from './shared/genericChildDropDown/generic-child-drop-down/generic-child-drop-down.component';
import { GenericChildTimePickerComponent } from './shared/generic-child-time-picker/generic-child-time-picker.component';
import { GenericChildSwitchButtonComponent } from './shared/generic-child-switch-button/generic-child-switch-button.component';
import { GenericTableListLocalMemoryViewComponent } from './shared/generic-table-list-local-memory-view/generic-table-list-local-memory-view.component';
import { AboutSchoolComponent } from './custum/about-school/about-school.component';
import { TableDataPopupComponent } from './secure/table-data-popup/table-data-popup.component';
import { MasterFormRowdataPopupComponent } from './shared/master-form-rowdata-popup/master-form-rowdata-popup.component';
import { GenericFilterSideComponent } from './shared/generic-filter-side/generic-filter-side.component';
import { CarouselBannerComponent } from './custum/carousel-banner/carousel-banner.component';
import { ExperienceAdComponent } from './custum/experience-ad/experience-ad.component';
import { PublicGenericLessonComponent } from './custum/public-generic-lesson/public-generic-lesson.component';
import { PublicGenricCategoriesComponent } from './custum/public-genric-categories/public-genric-categories.component';
import { PublicGenricProgramComponent } from './custum/public-genric-program/public-genric-program.component';
import { PublicGenericClientsayComponent } from './custum/public-generic-clientsay/public-generic-clientsay.component';
import { DashboardHomepageComponent } from './custum/dashboard-homepage/dashboard-homepage.component';
import { HomeDashboardComponent } from './secure/Frontend Setting/dashboard/Home/home-dashboard/home-dashboard.component';
import { PublicCustumHomePageComponent } from './custum/pages/public-custum-home-page/public-custum-home-page.component';
import { PublicCustumAboutpageComponent } from './custum/pages/public-custum-aboutpage/public-custum-aboutpage.component';
import { PublicCustumContactusComponent } from './custum/pages/public-custum-contactus/public-custum-contactus.component';
import { PublicCustumClasslistComponent } from './custum/pages/public-custum-classlist/public-custum-classlist.component';
import { PublicCustumClassDetailsComponent } from './custum/pages/public-custum-class-details/public-custum-class-details.component';

import { ExperienceDashboardComponent } from './secure/Frontend Setting/dashboard/Home/experience-dashboard/experience-dashboard.component';
import { LessonmanagementDashboardComponent } from './secure/Frontend Setting/dashboard/Home/lessonmanagement-dashboard/lessonmanagement-dashboard.component';
import { CategoriesDashboardComponent } from './secure/Frontend Setting/dashboard/Home/categories-dashboard/categories-dashboard.component';
import { ProgramDashboardComponent } from './secure/Frontend Setting/dashboard/Home/program-dashboard/program-dashboard.component';
import { TestimonialDashboardComponent } from './secure/Frontend Setting/dashboard/Home/testimonial-dashboard/testimonial-dashboard.component';
import { AboutComponent } from './secure/Frontend Setting/dashboard/about/about.component';
import { HomePgeModelComponent } from './secure/Frontend Setting/allFormPopUpModel/home-pge-model/home-pge-model.component';
import { AboutPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/about-page-model/about-page-model.component';
import { ContactusDashboardComponent } from './secure/Frontend Setting/dashboard/contactus-dashboard/contactus-dashboard.component';
import { ClasseslistDashboardComponent } from './secure/Frontend Setting/dashboard/classes/classeslist-dashboard/classeslist-dashboard.component';
import { ClassesPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/classes-page-model/classes-page-model.component';
import { PublicCustumBlogComponent } from './custum/pages/public-custum-blog/public-custum-blog.component';
import { PublicCustumBloglistComponent } from './custum/pages/public-custum-bloglist/public-custum-bloglist.component';
import { BlogDashboardComponent } from './secure/Frontend Setting/dashboard/blogs/blog-dashboard/blog-dashboard.component';
import { BlogdetailsDashboardComponent } from './secure/Frontend Setting/dashboard/blogs/blogdetails-dashboard/blogdetails-dashboard.component';
import { BlogPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/blog-page-model/blog-page-model.component';
import { FooterDashboardComponent } from './secure/Frontend Setting/dashboard/footer-dashboard/footer-dashboard.component';
import { HeaderDashboardComponent } from './secure/Frontend Setting/dashboard/header-dashboard/header-dashboard.component';
import { HeaderPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/header-page-model/header-page-model.component';

// import { GenericTableResizableColumnDirectiveComponent } from './shared/generic-table-resizable-column-directive/generic-table-resizable-column-directive.component';


import { PublicCustumAdmissonComponent } from './custum/pages/public-custum-admisson/public-custum-admisson.component';
// import { ExperienceDashboardComponent } from './secure/Frontend Setting/Home/experience-dashboard/experience-dashboard.component';
// import { LessonmanagementDashboardComponent } from './secure/Frontend Setting/Home/lessonmanagement-dashboard/lessonmanagement-dashboard.component';
// import { CategoriesDashboardComponent } from './secure/Frontend Setting/Home/categories-dashboard/categories-dashboard.component';
// import { ProgramDashboardComponent } from './secure/Frontend Setting/Home/program-dashboard/program-dashboard.component';
// import { TestimonialDashboardComponent } from './secure/Frontend Setting/Home/testimonial-dashboard/testimonial-dashboard.component';
import { PublicCustumGalleryComponent } from './custum/pages/public-custum-gallery/public-custum-gallery.component';
import { FooerPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/fooer-page-model/fooer-page-model.component';
import { AdmissionDashboardComponent } from './secure/Frontend Setting/dashboard/Addmission/admission-dashboard/admission-dashboard.component';
import { AdmissionPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/admission-page-model/admission-page-model.component';
import { AdmissiondeadlineComponent } from './secure/Frontend Setting/dashboard/Addmission/admissiondeadline/admissiondeadline.component';
import { FrontendSettingTableComponent } from './shared/frontend-setting-table/frontend-setting-table.component';
import { AdmissionLinkDashboardComponent } from './secure/Frontend Setting/dashboard/Addmission/admission-link-dashboard/admission-link-dashboard.component';
import { GalleryDashboardComponent } from './secure/Frontend Setting/dashboard/gallery-dashboard/gallery-dashboard.component';
import { GalleryPageModelComponent } from './secure/Frontend Setting/allFormPopUpModel/gallery-page-model/gallery-page-model.component';
import { GenericAddEditComponent } from './shared/generic-add-edit/generic-add-edit.component';





@NgModule({
  declarations: [
    AppComponent,

    AlertDialogBoxComponent,
    AlertDialogBoxEventComponent,
    ConfirmDialogBoxEventComponent,
    PublicCustumGalleryComponent,
    GenericPrintFormComponent,
    PrintLayoutComponent,
    SafeHtmlPipe,
    SearchFilterPipe,
    DragDropDirective,
    CaptchaComponent,

    DefaultComponent,
    PublicComponent,
    SecureComponent,

    // HomeComponent,

    UserComponent,
    SignInComponent,
    ResetPasswordComponent,
    LandingPageComponent,

    HeaderComponent,
    FooterComponent,
    SideLoginComponent,
    BreadCumComponent,
    DashboardComponent,
    ChangepasswordComponent,
    ManageprofileComponent,
    RedirectComponent,

    WFMasterComponent,
    GenericMasterFormComponent,
    GenerateMasterFormComponent,
    generateReportFormComponent,
    GenerateCustumFormComponent,
    GenericReportFormComponent,
    GenericMultiTableFormComponent,
    UploadFileComponent,
    WorkFlowProcessComponent,
    CaptureImageComponent,
    PreviewImageComponent,
    GenericPrintComponent,

    GenericListFormComponent,

    EllipsifyLongTextDirective,
    GenericTableListViewComponent,
    GenericTableViewAlertComponent,
    GenericChildActionModalComponent,
    PublicGenericMasterFormComponent,
    PublicGenericMultiFormComponent,
    PublicGenericReportFormComponent,
    PublicGenericLandingPageComponent,
    CustumComponent,
    PublicGenericHeaderComponent,
    PublicGenericFooterComponent,
    PublicGenericAboutPageComponent,
    PublicGenericContactPageComponent,
    PublicGenericPhotoPageComponent,
    PayFeeDuesComponent,
    GenericChildDropDownComponent,
    GenericChildTimePickerComponent,
    GenericChildSwitchButtonComponent,
    GenericTableListLocalMemoryViewComponent,
    AboutSchoolComponent,
    TableDataPopupComponent,
    MasterFormRowdataPopupComponent,
    GenericFilterSideComponent,
    CarouselBannerComponent,
    ExperienceAdComponent,
    PublicGenericLessonComponent,
    PublicGenricCategoriesComponent,
    PublicGenricProgramComponent,
    PublicGenericClientsayComponent,
    DashboardHomepageComponent,
    HomeDashboardComponent,
    PublicCustumHomePageComponent,
    PublicCustumAboutpageComponent,
    PublicCustumContactusComponent,
    PublicCustumClasslistComponent,
    PublicCustumClassDetailsComponent,

    PublicCustumAdmissonComponent,
    PublicCustumClassDetailsComponent,
    ExperienceDashboardComponent,
    LessonmanagementDashboardComponent,
    CategoriesDashboardComponent,
    ProgramDashboardComponent,
    TestimonialDashboardComponent,
    HomePgeModelComponent,
    AboutComponent,
    AboutPageModelComponent,
    ContactusDashboardComponent,
    ClasseslistDashboardComponent,
    ClassesPageModelComponent,
    PublicCustumBlogComponent,
    PublicCustumBloglistComponent,
    BlogDashboardComponent,
    BlogdetailsDashboardComponent,
    BlogPageModelComponent,
    FooterDashboardComponent,
    HeaderDashboardComponent,
    HeaderPageModelComponent,
    FooerPageModelComponent,
    AdmissionDashboardComponent,
    AdmissionPageModelComponent,
    AdmissiondeadlineComponent,
    FrontendSettingTableComponent,
    AdmissionLinkDashboardComponent,
    GalleryDashboardComponent,
    GalleryPageModelComponent,
    GenericAddEditComponent

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    DataTablesModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 10000, // 10 seconds
      progressBar: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    AmChartsModule,
    NgxSpinnerModule,
    AngularMultiSelectModule,
    WebcamModule,
    FontAwesomeModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    CommonModule,
    CarouselModule,
    NgxChartsModule,
    InfiniteScrollModule,
    ResizableModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDzMXqR8GWGDP3ddFBdl5O_e0Ngc-U86kY' }),
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot()
  ],
  providers: [UserService, AuthGuard,
    ExcelService,asyncCustumValidation,
   AlertDialogBoxService,GlobalAlertService,ConfirmDialogBoxService,GenericPrintService,
   {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
  ],
  entryComponents: [
    AlertDialogBoxComponent,
   AlertDialogBoxEventComponent, ConfirmDialogBoxEventComponent
  ],

  bootstrap: [AppComponent]

})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSquare,
      faCheckSquare,
      faAnglesLeft,
      faAnglesRight,
      faArrowRightFromBracket,
      faPlaneArrival,
      faPlaneDeparture,
      faChartSimple,
      faChartColumn,
      faEllipsisVertical,
      faPenToSquare,
      faSearch
    );
  }
 }
