<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/bgtopimg.jpg" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Know Us Better</p>
                <h1>About Us</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">About Us</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="aboutsection text-white">
    <div class="aboutsection_wrapper">
        <div class="container">
            <div class="tophistory row aboutDetails" data-aos="fade-left">
                <div class="nameofHistory col-md-4">
                    <h1>{{Aboutdata.title}}</h1>
                </div>
                <div class="historylist col-md-4">
                    <p>{{Aboutdata.description1}}</p>
                </div>
                <div class="historylist col-md-4">
                    <p>{{Aboutdata.description2}}</p>
                </div>
            </div>
        </div>
        <!-- <div class="clint_wrapper" data-aos="fade-left">
            <div class="clint_box position-relative">
                <div class="bg-img"><img src="assets/img/about-clintbg.webp" alt=""></div>
                <div class="clint_content">
                    <div class="container">
                        <ngb-carousel>
                            <ng-template ngbSlide *ngFor="let group of clintgroup">
                                <div class="row">
                                    <ng-container *ngFor="let item of group">
                                        <div class="col" *ngIf="item.isactive">
                                            <div class="categoriesBox clintlogo">
                                                <div class="categoriesBoxImg">
                                                    <img [src]="item.imgurl" alt="clintlogo">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="aboutDetails">
            <div class="aboutdetails_wrapper">
                <div class="container">
                    <div class="row detailsaboutbg">
                        <div class="col-md-6 imgside p-0">
                            <div class="img_box">
                                <img [src]="Aboutdata.aboutdetailImg" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 align-content-center p-5">
                            <h3>{{Aboutdata.aboutdetailtitle}}</h3>
                            <p>{{Aboutdata.aboutdetailtxt}}</p>
                        </div>
                    </div>
                    <div class="row detailsaboutbg">
                        <div class="col-md-6 align-content-center p-5">
                            <h3>{{Aboutdata.aboutdetailtitle2}}</h3>
                            <p>{{Aboutdata.aboutdetailtxt}}</p>
                        </div>
                        <div class="col-md-6 imgside p-0">
                            <div class="img_box">
                                <img [src]="Aboutdata.aboutdetailImg2" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="aboutbtn">
            <div class="container text-white">
                <div class="row">
                    <div class="col-md-6">
                        <h1>For More Info</h1>
                    </div>
                    <div class="col-md-6 text-right btnli">
                        <button>Connect Us</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>