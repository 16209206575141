<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/aboug-bgimg.jpg" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Discover Our All Class Story</p>
                <h1>All Classes</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">All Classes</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container py-5" data-aos="fade-left">
    <div class="card-container classlistpage">
        <div class="card" routerLink="/class/{{item.routerlink}}" *ngFor="let item of paginatedItems">
            <img [src]="item.image" alt="image" class="card-image" />
            <h2>{{ item.title }}</h2>
            <p>Class Time : {{ item.classTime }}</p>
            <p>{{ item.description }}</p>
            <div class="info">
                <div>
                    <div class="textProColor">Class Size</div>
                    <div class="text-center">{{ item.classSize }}</div>
                </div>
                <div>
                    <div class="textProColor">Duration</div>
                    <div class="text-center">{{ item.duration }}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pagination classlistpage">
    <button (click)="prevPage()" [disabled]="currentPage === 1">&laquo;</button>
    <button *ngFor="let page of visiblePages" 
            [class.active]="page === currentPage" 
            (click)="goToPage(page)"
            [disabled]="page === ''">
      {{ page }}
    </button>
    <button (click)="nextPage()" [disabled]="currentPage === totalPages">&raquo;</button>
  </div>
  

