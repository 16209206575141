<div class="wrapper">
  <div class="overley" (click)="removeLayout()" [ngClass]="userService.ststusNav ? 'active' : ''"></div>
  <div id="sidebar" [ngClass]="userService.ststusNav ? 'active' : ''">
    <app-sidelogin></app-sidelogin>
  </div>
  <!-- <div id="content" [ngClass]="{userService.ststusNav ? 'active' : '', toggleClass ? '' : 'overHide'}"> -->
    <!-- [class.overHide]="toggleClassOnTableFilteropen" -->
    <div id="content" [class.active]="userService.ststusNav"   [class.overHide]="toggleClass"  >
    <app-header></app-header>

    <app-bread-cum></app-bread-cum>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <!-- Nav tabs -->
          <div class="card mb-4 backgornd_fee border-0">


            <router-outlet></router-outlet>


          </div>
        </div>

      </div>
    </div>
    <!-- <ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff"
type = "line-spin-clockwise-fade"
></ngx-spinner> -->

    <app-footer></app-footer>
  </div>
</div>