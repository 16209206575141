<div *ngIf="Crflag">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">Home Banner</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="container carouseldata">
        <div>
            <form [formGroup]="homeCrEditForm">
                <div>
                    <div class="row  align-items-center">
                        <div class="col-md-4 my-3">
                            <div class="ImageShowBox">
                                <label class="ImageShow">
                                    <div class="img_box">
                                        <img [src]="HomeCrLink.ImgUrl" alt="Image Preview">
                                    </div>
                                    <div class="hoverImg">
                                        <div class="hover_content">
                                            <img style="width: 30px; display: block;" class="hoverImgIcon"
                                                src="assets\img\Mask group1.png" alt="Upload Icon">
                                            <span class="text-light">Upload</span>
                                        </div>
                                    </div>
                                    <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                                </label>
                            </div>
                            <div class="text-center"><span class="imgError">* only jpg / png / webp</span></div>
                        </div>
                        <div class="col-md-8">
                            <div class="input-home-box">
                                <div class="titlecontainer">
                                    <label for="home_carousel">{{modelPopUpTitle}}</label>
                                    <input class="form-control" id="home_carousel" placeholder="Enter Headline"
                                        type="text" formControlName="headline">
                                    <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                                </div>
                            </div>
                            <div class="input-home-box">
                                <div class="titlecontainer">
                                    <label for="home_carousel">{{modelPopUpSetTitle}}</label>
                                    <input class="form-control" id="home_carousel" placeholder="Enter Headline"
                                        type="text" formControlName="headline2">
                                    <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-home-box">
                        <div class="titlecontainer">
                            <label for="home_carousel">{{modelPopUpdetails}}</label>
                            <textarea class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                                formControlName="description"></textarea>
                            <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                        </div>
                    </div>



                    <div class="carouselbtn">
                        <!-- <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"  *ngIf="!changebtnabout" (click)="onAddHomeCrmModal()">AddNew</button> -->
                        <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                        <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="onUpdateHomeCrmModal()">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>





<!------------------------------ Home Experiance Model Start Here -------------------------------------->
<div *ngIf="Expflag">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">Home Banner</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="container carouseldata">
        <div>
            <form [formGroup]="HomeExpEditForm">
                <div class="row  align-items-center">
                    <div class="col-md-4 my-3">
                        <div class="ImageShowBox">
                            <label class="ImageShow">
                                <div class="img_box">
                                    <img [src]="HomeExpLink.ImgUrl" alt="Image Preview">
                                </div>
                                <div class="hoverImg">
                                    <div class="hover_content">
                                        <img style="width: 30px; display: block;" class="hoverImgIcon"
                                            src="assets\img\Mask group1.png" alt="Upload Icon">
                                        <span class="text-light">Upload</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="text-center"><span class="imgError">* only jpg / png / webp</span></div>
                    </div>
                    <div class="col-md-8">
                        <div class="input-home-box">
                            <div class="titlecontainer">
                                <label for="home_carousel">Headline</label>
                                <input class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                                    formControlName="headline">
                                <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                            </div>
                        </div>

                        <div class="input-home-box">
                            <div class="titlecontainer">
                                <label for="home_carousel">Description</label>
                                <textarea class="form-control" id="home_carousel" placeholder="Enter Headline"
                                    type="text" formControlName="description"></textarea>
                                <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                            </div>
                        </div>
                    </div>



                    <div class="carouselbtn">
                        <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                        <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                            (click)="OnUpdateHomeExpmModal()">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>










<!------------------------------ Home Lessons Management Model Start Here -------------------------------------->
<div *ngIf="LessonShowflag">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">Home Banner</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="container carouseldata">
        <div>
            <form [formGroup]="HomeLessonForm">
                <div class="row  align-items-center">
                    <div class="col-md-6 my-3">
                        <div class="input-home-box">
                            <div class="titlecontainer">
                                <label for="home_carousel">Tab Headline</label>
                                <input class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                                    formControlName="title">
                                <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="input-home-box">
                            <div class="titlecontainer">
                                <label for="home_carousel">Detail Tab</label>
                                <input class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                                    formControlName="heading">
                                <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carouselbtn">
                    <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                    <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                        (click)="OnUpdateHomeLessonTabModal()">Update</button>
                </div>

            </form>
        </div>
    </div>
</div>

<!------------------------------ Home Lessons List View Flag Management Model Start Here -------------------------------------->
<div *ngIf="ListViewShowflag">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">list</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="container my-5">
        <ul class="">
            <li *ngFor="let item of HomeListViewTabLink">{{item.list}}</li>
        </ul>
    </div>
</div>


<!------------------------------ Home Lessons List edit Flag Management Model Start Here -------------------------------------->
<div *ngIf="ListEditShowflag">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">Home Banner2</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="container carouseldata">
        <div>
            <form [formGroup]="HomeLessonListForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-home-box">
                            <div class="titlecontainer">
                                <label for="home_carousel">List Add</label>
                                <input class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                                    formControlName="lessonlist">
                            </div>
                        </div>
                    </div>

                    <div class="carouselbtn">
                        <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                        <button type="submit" *ngIf="isCheckBtnFlag" id="btnSubmit" (click)="OnclickAddNewList()"
                            class="btn btn-primary ml-2">Add New</button>
                        <button type="submit" *ngIf="!isCheckBtnFlag" id="btnSubmit" (click)="OnclickEditList()"
                            class="btn btn-primary ml-2">Update</button>
                    </div>


                    <div class="table">
                        <div class="container-fluid">
                            <div class="row justify-content-center">
                                <div class=" p-0">
                                    <div class="table-responsive main-table-wrappr">
                                        <table class="table row-border hover w-100 ">
                                            <thead>
                                                <tr>
                                                    <th scope="col" resizable>S.No.</th>
                                                    <th scope="col" resizable>Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody id="EditTableBody">

                                                <tr *ngFor="let data of HomeListViewTabLink; let sr = index">
                                                    <td>{{sr+1}}</td>

                                                    <td>
                                                        <div class="">{{data.list}}</div>
                                                    </td>

                                                    <td style="width: 100px;">
                                                        <a href="javascript:void(0)" (click)="onClickEditForm(sr, data)"
                                                            ngbTooltip="Edit Record" container="wrapper"
                                                            placement="left" ngbTooltip="Tooltip on left"
                                                            tooltipClass="tooltipBlue" class='edit opendetail'><i
                                                                class='fa fa-pencil'></i></a>
                                                        
                                                        <a href="javascript:void(0)" (click)="deleteKey(sr)"
                                                            ngbTooltip="Delete Record" container="wrapper"
                                                            placement="left" ngbTooltip="Tooltip on left"
                                                            tooltipClass="tooltipBlue" class='delete opendetail'><i
                                                                class='fa fa-trash'></i></a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="carouselbtn">
                    <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                    <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                        (click)="OnUpdateHomeLessonListTabModal()">Update</button>
                </div>

            </form>
        </div>
    </div>
</div>