<!-- <ul class="nav nav-tabs master-form-tab mb-4" role="tablist">
  <li role="presentation" class="active"><a class="nav-link active" id="LinkAdd" href="#divAddNew"
      (click)="onMainTab();" data-toggle="tab">Add New Detail</a></li>
  <li role="presentation"> <a class="nav-link" id="LinkView" href="#divView" (click)="onViewTab();"
      data-toggle="tab">View Detail</a></li>
</ul> -->

<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onMainTab();" class="nav-link">Add New Detail</a>
    <ng-template ngbNavContent>

  <div class="" id="divAddContainer">
    
    <div class="">
      <form *ngIf="mastertableForm" [formGroup]="mastertableForm" class="formwidth w-100 pb-3">
        <div class="">
         

          <div class="row m-0">
            <div class="col-lg-3">
              <label class="col-form-label ">Select Role</label>
              <angular2-multiselect id="DrpRole" formControlName="userRole" [data]="dropdownListRole" [(ngModel)]="selectedItemsRole"
              (onSelect)="onItemSelectRole($event)" (onDeSelect)="OnItemDeSelectRole($event)"
              (onClose)="OnClose($event,'userRole','mastertableForm')"
              [ngClass]="{'is-invalid': (mastertableForm.get('userRole')?.touched || mastertableForm.get('userRole')?.dirty) && !mastertableForm.get('userRole')?.valid,'is-valid': (mastertableForm.get('userRole')?.touched || mastertableForm.get('userRole')?.dirty) && mastertableForm.get('userRole')?.valid }"
                [settings]="dropdownGridSettings">
              </angular2-multiselect>   
              <span class=" invalid-feedback" style="padding-top: 10px;">               
                {{mastertableForm.get('userRole')?.errors?.['GenericError']['errorMsg']}}
              </span>
                    
            </div>
            <div class="col-xl-3">
              <label class="col-form-label ">Form Name</label>
              <input type='text' class='form-control' formControlName="formName" Placeholder='Please Enter Form Name' autocomplete="off"
                 [disabled]="selectedItemsRole.length ==0? true: formValuesData.length > 0 ? true : false" 
                [ngClass]="{'is-invalid': (mastertableForm.get('formName')?.touched || mastertableForm.get('formName')?.dirty) && !mastertableForm.get('formName')?.valid,'is-valid': (mastertableForm.get('formName')?.touched || mastertableForm.get('formName')?.dirty) && mastertableForm.get('formName')?.valid }">
              <span class=" invalid-feedback" style="padding-top: 10px;" >
                {{mastertableForm.get('formName')?.errors?.['GenericError']['errorMsg']}}
              </span>
              <input type="hidden" id="formID" name="formID">
            </div>
            <div  class=" col-xl-3"> 
              <label class="col-form-label ">Master Table</label>
              <angular2-multiselect id="DrpMaster" formControlName="tableName" [data]="dropdownListMasterTable"
                [(ngModel)]="selectedItemsMasterTable" [settings]="dropdownGridSettings"
                (onClose)="OnClose($event,'tableName','mastertableForm')"
                [ngClass]="{'is-invalid': (mastertableForm.get('tableName')?.touched || mastertableForm.get('tableName')?.dirty) && !mastertableForm.get('tableName')?.valid,'is-valid': (mastertableForm.get('tableName')?.touched || mastertableForm.get('tableName')?.dirty) && mastertableForm.get('tableName')?.valid }">
              </angular2-multiselect>
              <span class=" invalid-feedback" style="padding-top: 10px;">               
                {{mastertableForm.get('tableName')?.errors?.['GenericError']['errorMsg']}}
              </span>
            </div>
            <div class=" col-xl-3">
              <label class="col-form-label ">Master Table Alias</label>
              <input type='text' class='form-control' formControlName="formAliasName" [attr.disabled]="formValuesData.length > 0 ? 'disabled': null"
                Placeholder='Please Enter Parent Table Alias Name'  autocomplete="off"
                [ngClass]="{'is-invalid': (mastertableForm.get('formAliasName')?.touched || mastertableForm.get('formAliasName')?.dirty) && !mastertableForm.get('formAliasName')?.valid,'is-valid': (mastertableForm.get('formAliasName')?.touched || mastertableForm.get('formAliasName')?.dirty) && mastertableForm.get('formAliasName')?.valid }">
                <span class=" invalid-feedback" style="padding-top: 10px;">               
                  {{mastertableForm.get('formAliasName')?.errors?.['GenericError']['errorMsg']}}
                </span>
              </div>
            </div>
            <div class="row mx-0 mt-4">
            <div class="col-xl-12 text-right">
              <button *ngIf="formValuesData.length == 0" id="btnReset"
              (click)="openResetModal()" class="btn btn-secondry" >Reset</button>
              <button *ngIf="formValuesData.length == 0" id="btnSearch" style="margin: 3px;"
                (click)="onAddclick()" class="btn btn-primary ml-3" [disabled]="!mastertableForm.valid">Add New</button>
              <!-- <input type="button"  style="margin: 3px;" class="btn btn-primary "
              (click)="testformData()" value="Search"> -->
              <!-- <input type="button" id="btnView" style="margin: 3px;"  class="btn btn-info " (click)="openViewtModal()" value="View"> -->
             
            </div>
          </div>
        </div>
      </form>
    </div> 
    <div class="" *ngIf="formValuesData && formValuesData.length > 0">
      <div class="row m-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <h4 id="Formheading" class="mb-4 text-center border-bottom mt-3 pb-3 master-table-form-heading">Select child table to form details</h4>
        </div>
      </div>
      <form *ngIf="childtableForm" [formGroup]="childtableForm" class="formwidth w-100">
        <div class="form-group">
          <!-- <div class="row">

            <div class="col-xl-3">
              <h5> Child Table</h5>
            </div>
            <div class="col-xl-3">
              <h5> Child Table Alias</h5>
            </div>
            <div class="col-xl-3">
              <h5> Is Required</h5>
            </div>
            <div class="col-xl-3">
              <h5> Column for Sum</h5>
            </div>
          </div> -->
          <div class="row m-0">
            <div  class=" col-xl-3">
              <label class="col-form-label ">Child Table</label>
              <angular2-multiselect id="DrpChild" formControlName="childTableName" [data]="dropdownChildTabledrp"
                [(ngModel)]="selectedItemsdropdownChildTabledrp" [settings]="dropdownSettings"
                (onClose)="OnClose($event,'childTableName','childtableForm')"
                (onSelect)="onItemSelect($event,'childTableName','childtableForm','I')" (onDeSelect)="OnItemDeSelect($event,'childTableName','childtableForm','I')"
                [ngClass]="{'is-invalid': (childtableForm.get('childTableName')?.touched || childtableForm.get('childTableName')?.dirty) && !childtableForm.get('childTableName')?.valid,'is-valid': (childtableForm.get('childTableName')?.touched || childtableForm.get('childTableName')?.dirty) && childtableForm.get('childTableName')?.valid }">
              </angular2-multiselect>
              <span class=" invalid-feedback">
             
                {{childtableForm.get('childTableName')?.errors?.['GenericError']['errorMsg']}}
              </span>
            </div>
            <div class="col-xl-3">
              <label class="col-form-label ">Child Table Alias</label>
              <input type='text' class='form-control' formControlName="childTableAliasName"
                Placeholder='Please Enter Form Name'  autocomplete="off"
                [ngClass]="{'is-invalid': (childtableForm.get('childTableAliasName')?.touched || childtableForm.get('childTableAliasName')?.dirty) && !childtableForm.get('childTableAliasName')?.valid,'is-valid': (childtableForm.get('childTableAliasName')?.touched || childtableForm.get('childTableAliasName')?.dirty) && childtableForm.get('childTableAliasName')?.valid }">
              <span class=" invalid-feedback">               
                {{childtableForm.get('childTableAliasName')?.errors?.['GenericError']['errorMsg']}}
              </span>
            </div>
            <div class="col-xl-3">
              <label class="col-form-label ">Is Required</label>
              <angular2-multiselect id="DrpChildTableIsRequired" formControlName="childTableIsRequired"
                [data]="dropdownChildTableIsReq" [(ngModel)]="selectedItemsdropdownChildTableIsReq"
                [settings]="dropdownSettings"
                (onClose)="OnClose($event,'childTableIsRequired','childtableForm')"
                [ngClass]="{'is-invalid': (childtableForm.get('childTableIsRequired')?.touched || childtableForm.get('childTableIsRequired')?.dirty) && !childtableForm.get('childTableIsRequired')?.valid,'is-valid': (childtableForm.get('childTableIsRequired')?.touched || childtableForm.get('childTableIsRequired')?.dirty) && childtableForm.get('childTableIsRequired')?.valid }">
              </angular2-multiselect>
              <span class=" invalid-feedback">
               
                {{childtableForm.get('childTableIsRequired')?.errors?.['GenericError']['errorMsg']}}
              </span>

            </div>
            <div class="col-lg-3">
              <label class="col-form-label ">Column for Sum</label>
              <angular2-multiselect id="DrpChildTablesumColumn" formControlName="childTablesumColumn"
              [data]="dropdownChildTableSumColumn" [(ngModel)]="selectedItemsdropdownChildTableSumColumn"
              [settings]="dropdownMultiSelectSettings"
              (onClose)="OnClose($event,'childTablesumColumn','childtableForm')"
              [ngClass]="{'is-invalid': (childtableForm.get('childTablesumColumn')?.touched || childtableForm.get('childTablesumColumn')?.dirty) && !childtableForm.get('childTablesumColumn')?.valid,'is-valid': (childtableForm.get('childTablesumColumn')?.touched || childtableForm.get('childTablesumColumn')?.dirty) && childtableForm.get('childTablesumColumn')?.valid }">
            </angular2-multiselect>
            <span class=" invalid-feedback">
              <!-- {{childtableForm.get('childTableAliasName').errors?.value['GenericError']}}  -->
              {{childtableForm.get('childTablesumColumn')?.errors?.['GenericError']['errorMsg']}}
            </span>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-lg-6 text-left mt-4">
              <label class="col-form-label ">Child Table Custom Event</label>
              <input type='text' class='form-control' formControlName="childTableCustomEvent"
                Placeholder='Please Enter Custom Event'  autocomplete="off"
                [ngClass]="{'is-invalid': (childtableForm.get('childTableCustomEvent')?.touched || childtableForm.get('childTableCustomEvent')?.dirty) && !childtableForm.get('childTableCustomEvent')?.valid,'is-valid': (childtableForm.get('childTableCustomEvent')?.touched || childtableForm.get('childTableCustomEvent')?.dirty) && childtableForm.get('childTableCustomEvent')?.valid }">
              <span class=" invalid-feedback">               
                {{childtableForm.get('childTableCustomEvent')?.errors?.['GenericError']['errorMsg']}}
              </span>
            </div> 
            <div class="col-lg-6 text-right mt-4">
              <input type="button" id="btnResetChild" (click)="openResetModal()"
              class="btn btn-secondry" value="Reset">
              <input type="button" id="btnAddChild" class="btn btn-primary ml-3"
                [disabled]="!childtableForm.valid" (click)="onAddChildClick('P')" value="Add Child">
              <!-- <input type="button" style="margin: 3px;" class="btn btn-primary " (click)="testChildformData()"
                value="Search"> -->
              <!-- <input type="button" id="btnView" style="margin: 3px;"  class="btn btn-info " (click)="openViewtModal()" value="View"> -->
             
            </div> 
          </div>
        </div>
      </form>
    </div> 
  </div>
  <div role="tabpanel" class="tab-pane" id="divAddNew">

    <div class="inner-content Divbackground px-3" *ngIf="DivMainShow" id="DivMain">
     
      <div id="MultitableformDiv">
        <div class="table-responsive">
        <table id="Multitableform" class='table  table-bordered'>
          <thead>
            <tr>
              <th>Sno</th>
              <th>Form Name</th>
              <th>Sequence</th>
              <th>Parent Table</th>
              <th>Table Name</th>
              <th>Table Alias</th>
              <th>Is Required</th>
              <th>Column for Sum</th>
              <th>Custom Event</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rowData of formValuesData; let i=index;" class='Uiparams'>
              <td>{{(i +1)}}</td>
              <td>{{rowData['formName']}}</td>
              <td>{{(i +1)}}</td>
              <td>{{rowData['parentTableName']}}</td>
              <td>{{rowData['tableName']}}</td>
              <td>{{rowData['tableAliasName']}}</td>
              <td>{{rowData['isRequired'] =="1" ? 'Yes' : 'No'}}</td>
              <td>{{rowData['sumColumn']}}</td>
              <td>{{rowData['customEvent']}}</td>
              <td *ngIf="(i +1) ==1"></td>             
              <td *ngIf="(i +1) >1">
                <a href="javascript:void(0)" class="delete btn-link opendetail"
                  (click)="deleteRow($event,i,'P')"><i class="fa fa-trash"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>

      <div class="Submitbtn-content mt-4">

        <button type="button" id="btnSubmit" (click)="OnSubmitModal()"
          class="btn btn-primary btn-raised">Submit</button>

      </div>

    </div>

    <!-- </div> -->
  </div>
</ng-template>
</li>
<li [ngbNavItem]="2">
  <a ngbNavLink class="nav-link" (click)="onViewTab();">View Detail</a>
  <ng-template ngbNavContent>
  <div role="tabpanel" class="tab-pane p-3" id="divView">
    <div class="row justify-content-md-center tableDiv">
      <div class="col-12" style="margin-bottom: 15px;"><button type="button" class="btn btn-danger" (click)="clearAllFilter()">Clear</button>
        <ng-container *ngFor="let filterQuery of filterDisplayQuery; let i=index">
          <b  *ngIf="i != 0">{{filterQuery.operator}}</b> 
        <span class="badge-box">  {{filterQuery.displayQuery}}   <a (click)="delFilter(filterQuery.columnName)"><i class="fa fa-trash" aria-hidden="true"></i></a></span>
        </ng-container> </div>
      <div id="DivtableDataEdit" class="table-responsive main-table-wrappr">
        <table [id]="Edittableid" class="table table-bordered row-border hover w-100 ">
          <thead>
            <tr>
              <th>S.No.</th>

              <!-- <th>Form Name</th>   
              <th>User Role</th>         -->
              <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">
                <th scope="headerDetailData">
                  {{FirstLetterUpper(headerDetailData['displayName'])}} 
                 
                  <i (click)="onSortClick($event,headerDetailData['key'])" class="fa fa-sort-amount-desc" ></i>
                  <div class="filter-box">
                   
                    <a placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="manual"
                      #p2="ngbPopover" [autoClose]="'outside'"
                      (click)="toggleWithFilterCol(p2, ConvertStringLowerCase(headerDetailData['key']), FirstLetterUpper(headerDetailData['displayName']),'','',true)"> <i class="fa fa-filter"
                        aria-hidden="true"></i></a>

                  </div>
                </th>
              </ng-container>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <!-- <ng-container *ngIf="detailTableData?.length >0; else elsedetailTableEmpty"> -->

              <tr *ngFor="let rowDetailData of detailTableData; let i=index;"
                [attr.tblcolformname]="rowDetailData['formName']">
                <td>{{(i + 1) }}</td>
                <!-- <td>    <a href="javascript:void(0)" 
                  (click)="onEditCustumFormDetailsByFormId(rowDetailData['formName'],rowDetailData['id'],'view',$event)">
                  {{rowDetailData["formName"]}}</a>
                </td>  
                <td>{{rowDetailData["userRole"]}}</td>   -->
                <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">

                  <td>
                  <div >
                    {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["key"])])}}
                  </div>

                </td>

                </ng-container>           
                <!-- <td>{{rowDetailData["isActive"]}}</td> <td>{{rowDetailData["isDeleted"]}}</td>  -->
                <td>
                  <a href="javascript:void(0)" 
                    (click)="onEditCustumFormDetailsByFormId(rowDetailData['formName'],rowDetailData['id'],'update',$event)"
                    class='edit opendetail'><i class='fa fa-pencil'></i></a>
                    <a href="javascript:void(0)"  *ngIf="rowDetailData['isActive']" 
                    (click)="onTableAliasAction(rowDetailData['id'],'InActive',i,rowDetailData['userRoleId'])" class='active opendetail'><i
                      class='fa fa-check' ></i></a>
                      <a href="javascript:void(0)"  *ngIf="!rowDetailData['isActive']"
                    (click)="onTableAliasAction(rowDetailData['id'],'Active',i,rowDetailData['userRoleId'])" class='inactive opendetail'><i
                      class='fa fa-times' ></i></a>
                      <a href="javascript:void(0)"   (click)="onTableAliasAction(rowDetailData['id'],'Deleted',i,rowDetailData['userRoleId'])"
                    class='delete opendetail'><i class='fa fa-trash'></i></a>

                </td>

              </tr>
            <!-- </ng-container>
            <ng-template #elsedetailTableEmpty>
              <ng-container>
                <tr>
                  <td colspan="9"> No Data Available</td>
                </tr>

              </ng-container>
            </ng-template> -->

          </tbody>
        </table>
      </div>
      <ng-template #popContent>

        <form [formGroup]="filterForm" #form="ngForm">
          <div class="row">
            <div class="col mb-2">
              <div class="btn-group">
                <input type="radio" class="btn-check form-control form-control-sm" formControlName="columnCondition"
                  id="radio1" autocomplete="off" value="AND" checked>
                <label class="btn btn-outline-primary" for="radio1">AND</label>

                <input type="radio" class="btn-check form-control form-control-sm" formControlName="columnCondition"
                  id="radio2" autocomplete="off" value="OR">
                <label class=" btn btn-outline-primary" for="radio2">OR</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mb-2">
              <select class="form-control form-control-sm form-select form-select-sm"
                formControlName="filterColumnOperator" aria-label=".form-select-sm example">
                <option selected value=1>Equal</option>
                <option value=2>Not Equal</option>
                <option value=3>Contains</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col mb-2">
              <input type="text" [placeholder]="filterColumnDisplayName" required minlength="1" maxlength="200"
                class="form-control form-control-sm"
                [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                formControlName="filterValue">

              <div *ngIf="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                class="invalid-feedback">
                <div *ngIf="filterValue.errors?.['required']">
                  This field is required.
                </div>
                <div *ngIf="filterValue.errors?.['minlength']">
                  This field must have at least 1 character.
                </div>
                <div *ngIf="filterValue.errors?.['maxlength']">
                  This field must have at most 200 characters.
                </div>
                <!-- <div *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
              Invalid email format.
            </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt-2 popover-btn d-flex">
              <button type="button" class="btn btn-sm clear-btn" (click)="clearFilterForm()">Clear</button>
              <button type="button" [disabled]="!filterValue.valid" (click)="applyFilter()"
                class="btn btn-sm apply-btn btn-primary">Apply</button>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template #popTitle>Filter {{filterColumnDisplayName}}</ng-template>
    </div>
    <div class="table-footer">
      <div class="footer-selct">
              <select class="custom-select form-control" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="pageSizeChange();">
                <option [ngValue]="05">05 items</option>
                <option [ngValue]="10">10 items</option>
                <option [ngValue]="25">25 items</option>
                <option [ngValue]="50">50 items</option>
                <option [ngValue]="100">100 items</option>
              </select>
              <pre><span class="float-md-left">Page: {{ currentPage }} / {{ dataPagination.pageCount }}</span></pre>
            </div>
            <div class="footer-pagintion">
    <ngb-pagination class="d-flex justify-content-center mt-2" #dataPagination [collectionSize]="totalRecords" [(page)]="currentPage" [pageSize]="pageSize"
      [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="SearchEdit(1)"></ngb-pagination>
    </div>
   
    </div>
  </div>
</ng-template>
</li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
  <!-- </div> -->
  <!-- start Modals -->
  <!-- <div class="backdropCustom" [ngStyle]="{'display':display}"></div> -->
  <!-- <div class="backdropCustom" style="display: none;"></div>
  <div class="AlertbackdropCustom" style="display: none;"></div>  -->
  <!-- start UpdateMaster Modal -->
  <div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content edit-modal-form">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="MasterUpdateHeaderId">Update Custum Form</h2>
          <input type="hidden" id="updateId" name="updateId">
          <button type="button" class="close" aria-label="Close" (click)="onCloseEditModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body ">

          <div  class="table-responsive"> 
              <div class="mb-2 pb-3 border-bottom">
                <form *ngIf="mastertableFormE" [formGroup]="mastertableFormE" class="formwidth">
                  <div class="form-group">
                    <!-- <div class="row">
                      <div class="col-xl-4">
                        <h5> Form Name</h5>
                      </div>                       
                      <div class=" col-xl-4">
                        <h5>Master Table Alias</h5>
                      </div> 
                    </div> -->
          
                    <div class="row m-0">
                      <div class="col-xl-4">
                        <label class="col-form-label">Form Name</label>
                        <input type='text' class='form-control' formControlName="formNameE" Placeholder='Please Enter Form Name' autocomplete="off"
                         
                          [ngClass]="{'is-invalid': (mastertableFormE.get('formNameE')?.touched || mastertableFormE.get('formNameE')?.dirty) && !mastertableFormE.get('formNameE')?.valid,'is-valid': (mastertableFormE.get('formNameE')?.touched || mastertableFormE.get('formNameE')?.dirty) && mastertableFormE.get('formNameE')?.valid }">
                        <span class=" invalid-feedback" style="padding-top: 10px;" >
                          {{mastertableFormE.get('formNameE')?.errors?.['GenericError']['errorMsg']}}
                        </span>
                        <input type="hidden" id="formID" name="formID">
                      </div>                     
                      <div class=" col-xl-4">
                        <label class="col-form-label">Master Table Alias</label>
                        <input type='text' class='form-control' formControlName="formAliasNameE" 
                          Placeholder='Please Enter Parent Table Alias Name'  autocomplete="off"
                          [ngClass]="{'is-invalid': (mastertableFormE.get('formAliasNameE')?.touched || mastertableFormE.get('formAliasNameE')?.dirty) && !mastertableFormE.get('formAliasNameE')?.valid,'is-valid': (mastertableFormE.get('formAliasNameE')?.touched || mastertableFormE.get('formAliasNameE')?.dirty) && mastertableFormE.get('formAliasNameE')?.valid }">
                          <span class=" invalid-feedback" style="padding-top: 10px;">               
                            {{mastertableFormE.get('formAliasNameE')?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      <div class="col-lg-4 text-right pt-2">
                        <input type="button"  id="btnSearchE"
                          (click)="openUpdateMasterAliasModal()" class="btn btn-primary mt-4" [disabled]="!mastertableFormE.valid" value="Update">
                        
                      </div>
                    </div>
                  </div>
                </form>
              </div> 
              <div class="mt-4" *ngIf="formValuesDataE && formValuesDataE.length > 0">
                <div class="row m-0">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                    <h4 id="Formheading" class="master-table-form-heading pb-3 mb-4 border-bottom text-center">Select child table to form details</h4>
                  </div>
                </div>
                <form *ngIf="childtableFormE" [formGroup]="childtableFormE" class="formwidth ">
                  <div class="form-group">
                    <!-- <div class="row m-0">
          
                      <div class="col-xl-3">
                        <h5> Child Table</h5>
                      </div>
                      <div class="col-xl-3">
                        <h5> Child Table Alias</h5>
                      </div>
                      <div class="col-xl-3">
                        <h5> Is Required</h5>
                      </div>
                      <div class="col-xl-3">
                        <h5> Column for Sum</h5>
                      </div>
                    </div> -->
                    <div class="row m-0">
                      <div  class=" col-xl-3">
                        <label class="col-form-label requiredSign">Child Table</label>
                        <angular2-multiselect id="DrpChild" formControlName="childTableName" [data]="dropdownChildTabledrp"
                          [(ngModel)]="selectedItemsdropdownChildTabledrpE" [settings]="dropdownSettings"
                          (onClose)="OnClose($event,'childTableName','childtableFormE')"
                          (onSelect)="onItemSelect($event,'childTableName','childtableFormE','E')" (onDeSelect)="OnItemDeSelect($event,'childTableName','childtableFormE','E')"
                          [ngClass]="{'is-invalid': (childtableFormE.get('childTableName')?.touched || childtableFormE.get('childTableName')?.dirty) && !childtableFormE.get('childTableName')?.valid,'is-valid': (childtableFormE.get('childTableName')?.touched || childtableFormE.get('childTableName')?.dirty) && childtableFormE.get('childTableName')?.valid }">
                        </angular2-multiselect>
                        <span class=" invalid-feedback" style="padding-top: 10px;">
                  
                          {{childtableFormE.get('childTableName')?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                      <div class="col-xl-3">
                        <label class="col-form-label requiredSign">Child Table Alias</label>
                        <input type='text' class='form-control' formControlName="childTableAliasName"
                          Placeholder='Please Enter Form Name'  autocomplete="off"
                          [ngClass]="{'is-invalid': (childtableFormE.get('childTableAliasName')?.touched || childtableFormE.get('childTableAliasName')?.dirty) && !childtableFormE.get('childTableAliasName')?.valid,'is-valid': (childtableFormE.get('childTableAliasName')?.touched || childtableFormE.get('childTableAliasName')?.dirty) && childtableFormE.get('childTableAliasName')?.valid }">
                        <span class=" invalid-feedback" style="padding-top: 10px;">               
                          {{childtableFormE.get('childTableAliasName')?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                      <div class="col-xl-3">
                        <label class="col-form-label requiredSign">Is Required</label>
                        <angular2-multiselect id="DrpChildTableIsRequired" formControlName="childTableIsRequired"
                          [data]="dropdownChildTableIsReq" [(ngModel)]="selectedItemsdropdownChildTableIsReqE"
                          [settings]="dropdownSettings"
                          (onClose)="OnClose($event,'childTableIsRequired','childtableForm')"
                          [ngClass]="{'is-invalid': (childtableFormE.get('childTableIsRequired')?.touched || childtableFormE.get('childTableIsRequired')?.dirty) && !childtableFormE.get('childTableIsRequired')?.valid,'is-valid': (childtableFormE.get('childTableIsRequired')?.touched || childtableFormE.get('childTableIsRequired')?.dirty) && childtableFormE.get('childTableIsRequired')?.valid }">
                        </angular2-multiselect>
                        <span class=" invalid-feedback" style="padding-top: 10px;">
                     
                          {{childtableFormE.get('childTableIsRequired')?.errors?.['GenericError']['errorMsg']}}
                        </span>
          
                      </div>
                      <div class="col-lg-3">
                        <label class="col-form-label">Column for Sum</label>
                        <angular2-multiselect id="DrpChildTablesumColumn" formControlName="childTablesumColumn"
                        [data]="dropdownChildTableSumColumn" [(ngModel)]="selectedItemsdropdownChildTableSumColumnE"
                        [settings]="dropdownMultiSelectSettings"
                        (onClose)="OnClose($event,'childTablesumColumn','childtableFormE')"
                        [ngClass]="{'is-invalid': (childtableFormE.get('childTablesumColumn')?.touched || childtableFormE.get('childTablesumColumn')?.dirty) && !childtableFormE.get('childTablesumColumn')?.valid,'is-valid': (childtableFormE.get('childTablesumColumn')?.touched || childtableFormE.get('childTablesumColumn')?.dirty) && childtableFormE.get('childTablesumColumn')?.valid }">
                      </angular2-multiselect>
                      <span class=" invalid-feedback" style="padding-top: 10px;">
                        <!-- {{childtableForm.get('childTableAliasName').errors?.value['GenericError']}}  -->
                        {{childtableFormE.get('childTablesumColumn')?.errors?.['GenericError']['errorMsg']}}
                      </span>
                      </div>
                    </div>
                    <div class="row mx-0 mt-4">
                      <div class="col-lg-6 text-left">
                        <label class="col-form-label ">Child Table Custom Event</label>
                        <input type='text' class='form-control' formControlName="childTableCustomEvent"
                          Placeholder='Please Enter Custom Event'  autocomplete="off"
                          [ngClass]="{'is-invalid': (childtableFormE.get('childTableCustomEvent')?.touched || childtableFormE.get('childTableCustomEvent')?.dirty) && !childtableFormE.get('childTableCustomEvent')?.valid,'is-valid': (childtableFormE.get('childTableCustomEvent')?.touched || childtableFormE.get('childTableCustomEvent')?.dirty) && childtableFormE.get('childTableCustomEvent')?.valid }">
                        <span class=" invalid-feedback">               
                          {{childtableFormE.get('childTableCustomEvent')?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div> 
                      <div class="col-lg-6 text-right">
                        <button (click)="openEditResetModal()"
                          class="btn btn-secondry" >Reset</button>
                        <button class="btn btn-primary ml-3"
                          [disabled]="!childtableFormE.valid" (click)="onAddChildClick('E')">Add Child</button>
                     
                      </div>
                    </div>
                  </div>
                </form>
              </div> 

            <div class="inner-content px-3 mt-5 table-responsive">
              <table [id]="mainTableIdE" class='table  table-bordered'>
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Form Name</th>
                    <th>Sequence</th>
                    <th>Parent Table</th>
                    <th>Table Name</th>
                    <th>Table Alias</th>
                    <th>Is Required</th>
                    <th>Column for Sum</th>
                    <th>Custom Event</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rowData of formValuesDataE; let i=index;" class='Uiparams'>
                    <td>{{(i +1)}}</td>
                    <td>{{rowData['formName']}}</td>
                    <td>{{(i +1)}}</td>
                    <td>{{rowData['parentTableName']}}</td>
                    <td>{{rowData['tableName']}}</td>
                    <td>{{rowData['tableAliasName']}}</td>
                    <td>{{rowData['isRequired'] =="1" ? 'Yes' : 'No'}}</td>
                    <td>{{rowData['sumColumn']}}</td>
                    <td>{{rowData['customEvent']}}</td>
                    <td *ngIf="(i +1) ==1"></td>
                    <td *ngIf="(i +1) >1">
                      <a href="javascript:void(0)" type="button" class="delete btn-link opendetail"
                        (click)="deleteRow($event,i,'E')"><i class="fa fa-trash" ></i></a>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondry" (click)="onCloseEditModal()">Close</button>
          <button type="button" id="btnUpdate" class="btn btn-primary" (click)="updateEditTableEntry()">Update</button>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- /.modal !-->
  <!-- End UpdateMaster Modal -->


  <!-- start View Modal -->

  <div id="ViewModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg inner-content " role="document">
      <div class="modal-content edit-modal-form">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="HmodalHeaderId"></h2>
          <button type="button" class="close" aria-label="Close" (click)="onCloseViewModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div>
            <table [id]="mainTableIdE" class='table  table-bordered' >
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Form Name</th>
                  <th>Sequence</th>
                  <th>Parent Table</th>
                  <th>Table Name</th>
                  <th>Table Alias</th>
                  <th>Is Required</th>
                  <th>Column for Sum</th>
                  <th>Custom Event</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rowData of formValuesDataE; let i=index;" class='Uiparams'>
                  <td>{{(i +1)}}</td>
                  <td>{{rowData['formName']}}</td>
                  <td>{{(i +1)}}</td>
                  <td>{{rowData['parentTableName']}}</td>
                  <td>{{rowData['tableName']}}</td>
                  <td>{{rowData['tableAliasName']}}</td>
                  <td>{{rowData['isRequired'] =="1" ? 'Yes' : 'No'}}</td>
                  <td>{{rowData['sumColumn']}}</td>
                  <td>{{rowData['customEvent']}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">


          <button type="button" class="btn btn-secondry" (click)="onCloseViewModal()">Cancel</button>


        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End View Modal -->

  <!-- End Modals -->


