import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-table-data-popup',
  templateUrl: './table-data-popup.component.html',
  styleUrls: ['./table-data-popup.component.css']
})
export class TableDataPopupComponent implements OnInit {



  constructor(){

  }



  ngOnInit(): void {
  }



}
