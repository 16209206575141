<div id="DivData" class="DivData-content form-header-div-content m-0 p-3">
<div class="form-header-div">
  <a [routerLink]="backURL">Back</a>
</div>
     <fieldset class="mb-3 form-header-div-field">
      <legend> Student </legend>
      <div class="inner-content">
  
        <div id="DivData" class="DivData-content m-0 px-3">
          <!-- <form *ngIf="isRender" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductForm"> -->
          <form *ngIf="isRender && masterProductForm" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductForm">
            <!-- {{masterJsonData.formType}} -->
            <div *ngIf="masterJsonDataFull" class="row">
              <ng-container *ngFor="let formData of masterJsonData; let i=index">
  
                <ng-container
                  *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                  <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                      <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                        [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                        [(ngModel)]="formData.drpDataSelected"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        (onSelect)="onItemSelect($event,i,false)" (onDeSelect)="OnItemDeSelect($event,i,false)"
                        (onClose)="OnClose($event,formData.controlName)">
                        <c-badge>
                          <ng-template let-item="item">
                            <label class="m-0">{{item.itemName}}</label>
                          </ng-template>
                        </c-badge>
                      </angular2-multiselect>
                      <span class="invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
  
                    </div>
                  </div>
                  <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                      <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                        [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                        [(ngModel)]="formData.drpDataSelected"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        (onSelect)="onItemSelect($event,i, true)" (onDeSelect)="OnItemDeSelect($event,i,true)"
                        (onSelectAll)="onItemSelectAll($event,i, true)" (onDeSelectAll)="OnItemDeSelectAll($event,i,true)"
                        (onClose)="OnClose($event,formData.controlName)">
                        <c-badge>
                          <ng-template let-item="item">
                            <label class="m-0">{{item.itemName}}</label>
                          </ng-template>
                        </c-badge>
                      </angular2-multiselect>
                      <span class="invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
  
                    </div>
                  </div>
                  <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                      class='{{formData.controlDivClass}} calnder-icon'>
                      <input type='text' class="{{formData.controlClass}}"
                        (ngModelChange)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                        [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                        [bsConfig]="bsConfig" autocomplete="off" readonly
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        formControlName="{{formData.controlName}}">
                      <div class="calenderinput-icon"><a href="javascript: void(0);"
                          (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                            aria-hidden="true"></i></a></div>
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
  
                    </div>
  
                  </div>
                  <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label"
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                      class="form-check {{formData.controlDivClass}}">
  
                      <input type='checkbox' value='' class="{{formData.controlClass}}" id="{{formData.controlId}}"
                        (change)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        formControlName="{{formData.controlName}}">
                      <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                        for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                      </label>
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
                    </div>
  
                  </div>
                  <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                      <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                        [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                        formControlName="{{formData.controlName}}"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">
                          </textarea>
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
                    </div>
  
                  </div>
                  <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
  
                      <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                      [FormReset]=formData.ChildComponentReset
                      [childComponentFormSequence] = formData.childComponentFormSequence
                      [childComponentFileDelete] = formData.childComponentFileDelete
                        (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,i)"
                        id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                        [controlisDisabled]=formData.isDisabled
                        (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName)'></app-upload-file>
  
                      <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                        formControlName="{{formData.controlName}}"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">
  
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
                    </div>
  
                  </div>
                  <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
  
                      <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                        id="{{formData.controlId}}" formControlName="{{formData.controlName}}" readonly [disabled]="true"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                      <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                        id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        readonly [disabled]="true">
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                    <div>
                      <label id="{{formData.spanId}}" class="col-form-label "
                        [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                        for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                      <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                        ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                    </div>
  
                    <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                      [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                      <input type='text' class="{{formData.controlClass}}"
                        (change)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                        [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                        [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                        formControlName="{{formData.controlName}}">
  
                      <span class=" invalid-feedback mt-1">
                        {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                      </span>
  
                    </div>
  
                  </div>
                </ng-container>
  
              </ng-container>
  
            </div>
            
            <div class="d-flex mt-4 form-header-div-capture">
            <div class="capture-code m-0">
              <app-captcha (captchVerifictionEvent)="onCaptchVerification($event)" class="w-100" [isShowErrorMsg]="true"
                [isShowValidateMsg]="false" [pageReset]="isPageReset"></app-captcha>
              </div> 
  
  
            <div class="Submitbtn-content">
              <button type="button" class="btn btn-secondry" (click)="resetForm(2)">Reset</button>
              <button type="button" *ngIf="formTestPermission == 'True'" class="btn btn-secondry ml-2" (click)="testformData()">Test Form</button> 
              <button type="submit" id="btnSubmit" [disabled]="isFormDisabled()"
                class="btn btn-primary ml-2">Submit</button>
            </div>
          </div>
  
          </form>
        </div>
      </div>
    </fieldset>
  </div>
      <!-- </div> -->

  
    <!-- End Modals -->
    <div id="printContaint"  *ngIf="GenericPrintDataArray">
      <app-generic-print [GenericPrintDataArray]="GenericPrintDataArray" style="display: none;"></app-generic-print>
    </div>
  
  