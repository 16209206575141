import { Component, OnInit, Output,Input, EventEmitter , forwardRef} from '@angular/core';

import {  NG_VALUE_ACCESSOR,  NG_VALIDATORS,  ControlValueAccessor,  Validator,  FormControl} from "@angular/forms";
import { IfileValidationType, IfileTypeOutput, IfileType ,filetypeEnum} from '../model/fileType';
@Component({
  selector: 'app-generic-child-switch-button',
  templateUrl: './generic-child-switch-button.component.html',
  styleUrls: ['./generic-child-switch-button.component.css'],  
})
export class GenericChildSwitchButtonComponent implements OnInit {
  // @Output() changed = new EventEmitter<boolean>();
  // @Input("value") val: string = "";
  // onTouched: any = () => {};
  // onChange = (_: any) => {};
  @Input() toggleControlId: string  ='';
  tChecked: boolean = false;
  private _disabled: boolean = false;
  private _reverse: boolean= false;
  @Input() fileValidationstring?: string |null |undefined;
  @Input() formName: string ='';
  @Input() 
  set controlisDisabled(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
      if(target?.toString() == "disabled"){
        this.disabled = true;
      }
      else{
        this.disabled = false;
      }
      
    }
    else{

      this.disabled = false;
    }
  }
  @Input() childComponentFormSequence?: string |null |undefined;
  @Output() FormResetEvent = new EventEmitter();
  @Output() toggleChanged = new EventEmitter<boolean>();
  @Input() 
  set FormReset(target:  filetypeEnum){
    
    if(target !== filetypeEnum.No)
      {
   
      this.tChecked = false;
      this.toggleChanged.emit(this.tChecked);
      setTimeout(() => {
        this.FormResetEvent.emit(filetypeEnum.No);}, 0);
    }
  }
  _timerValue: string = "";
  @Input() 
  set toggleCheckedValue(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
     
    if(target == "1" || target.toLocaleLowerCase() =="yes" || target.toLocaleLowerCase() =="true")
    this.tChecked = true;
    else{
    this.tChecked = false;
    this.toggleChanged.emit(this.tChecked);
    }
   
  }
  else{
  this.tChecked = false;
  this.toggleChanged.emit(this.tChecked);
  }
    

  }

  
  @Input("label") label: string = "";
  isShowMeridian: boolean = true;

  
  @Input("placeholder") placeholder: string = "";
  @Input("readonly") readonly: boolean = false;
  @Input("required") required: boolean = false;
  @Input() disabled = false;
  isValid: boolean = true;
  timePickerVal: any = null;
  @Input("value") val: string = "";
  onTouched: any = () => {};
  onChange = (_: any) => {};
  constructor() { }

  ngOnInit(): void {
  }

 

  onChangeValue(event:any){
    this.toggleChanged.emit(event.target?.checked);
  }
}
