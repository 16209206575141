
<app-public-generic-header></app-public-generic-header>
<div class="about">
  <div class="bg-top">
    <div class="background-img"><img src="assets/school-img/about-bg.jpg" alt="aboutus_img"></div>
    <h1>{{aboutdata.aboutUstittle}}</h1>
  </div>
  <div class="about-us">
    <p>{{aboutdata.about_paragraph}}</p>
  </div>
</div>
<app-public-generic-footer></app-public-generic-footer>
