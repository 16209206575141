<div class="modal-box">
    <div class="inner-modal">
        <div class="modal-header">
            <div class="headlinemodal">
                Modal
            </div>
            <div class="closbtn">
                <button type="button" class="close" (click)="oneditaddbtn()">X</button>
            </div>
        </div>
        
        
        <div class="modal-body">
        
        </div>
        
        <div class="modal-footer footer-modal-btn">
            <button type="button" class="btn btn-primary ml-3">
                Save
            </button>
            <button (click)="oneditaddbtn()" type="button" class="btn btn-secondry m-0">
                Close
            </button>
        </div>
    </div>
</div>