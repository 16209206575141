import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../../../shared/user.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customvalidation } from "../../../shared/Validation";
import 'jquery';
import { ConfirmDialogBoxService } from '../../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
declare var jQuery: any;
import { Commonfunction } from "../../../shared/commonutility";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { faTShirt } from '@fortawesome/free-solid-svg-icons';
import { IpDeatilsService } from '../../../shared/ip-deatils.service';

interface IUser {
  userName: string;
  password: string;
  captcha: string;
  remember: boolean;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  isPageReset: boolean = false;
  isCaptchVerified: boolean = false;

  isPageResetForgetPassword: boolean = false;
  isCaptchVerifiedForgetPassword: boolean = false;

  loginReactiveForm!: FormGroup;
  user: IUser;
  forgetPassword: boolean = false;
  isRender: boolean = false;
  @ViewChild('Log1nModal') LoginModal?: ElementRef;
  isLoginError: boolean = false;
  isCaptcha: number = 1;
  //1= all (captcha and Remember me)
  //2= all (!captcha and Remember me)
  //3= all (captcha and !Remember me)
  //4= all (!captcha and !Remember me)
  errorMessgae: string = "";
  AlertMessgae: string = "";
  userData1: any;
  TokenExpireAfter: number = 0;
  UserId: any;
  pass: any;
  flag: boolean = false;
  display = "none";
  invalidMessage: string = "";
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  externalIPAddress:string|null|undefined='';
  localIpAddress: string|null|undefined='';
  ForgotPassFlag: boolean = true;
  forgetReactiveForm!: FormGroup;
  constructor(private userService: UserService, private router: Router, private location: Location, private spinner: NgxSpinnerService, public Customvalidation: Customvalidation, private globalConfirmService: ConfirmDialogBoxService, private commonfunction: Commonfunction
    , private ipDeatilsService:IpDeatilsService) {
      // localStorage.clear();
    this.isCaptcha = this.commonfunction.loadJson("~/../assets/config.json")["loginStyle"];
    if(this.isCaptcha == 2 || this.isCaptcha == 4)
      this.isCaptchVerified = true;
    this.user = {} as IUser;
    sessionStorage.clear();
  }

  ngOnInit() {
    sessionStorage.clear();
  if(this.ipDeatilsService.localStorageHasKeyWithValue('ExternalIPAddress')){
  this.externalIPAddress = localStorage.getItem('ExternalIPAddress')?.toString();
}
if(this.ipDeatilsService.localStorageHasKeyWithValue('LocalIPAddress')){
  this.localIpAddress = localStorage.getItem('LocalIPAddress')?.toString();
}
// this.localIpAddress = this.ipDeatilsService.determineLocalIp();
    this.loginReactiveForm = new FormGroup({
      userName: new FormControl(this.user.userName, [Validators.required, Validators.minLength(1), Validators.maxLength(250),]),
      password: new FormControl(this.user.password, [Validators.required, Validators.minLength(1),]),
      remember: new FormControl(this.user.remember),
    });

    this.forgetReactiveForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern(this.emailPattern)]),

    });

    if (localStorage.getItem('UserId') != null && localStorage.getItem('pass') != null) {
      this.UserId = localStorage.getItem('UserId');
      this.pass = localStorage.getItem('pass');
      this.flag = true;
    }
  }

  showpwd: any;
  passwordToggel() {
    this.showpwd = !this.showpwd;
  }

  removeSpaces(value: string) {
    return value.split(' ').join('');
  }

  OnSubmit() {
    let userName: string = this.loginReactiveForm.get('userName')?.value;
    let password: string = this.loginReactiveForm.get('password')?.value;
    let Remember: boolean = this.isCaptcha == 1 || this.isCaptcha == 2 ? this.loginReactiveForm.get('remember')?.value : false;
    let that = this;
    this.spinner.show();

    if (this.isCaptchVerified) {
      if (Remember && Remember != null) {
        localStorage.setItem('UserId', userName);
        localStorage.setItem('pass', password);

      }
      else {
        // localStorage.clear();
        localStorage.removeItem('UserId');
        localStorage.removeItem('pass');
      }
      if(this.isCaptcha == 2 || this.isCaptcha == 4)
      this.isCaptchVerified = true;
      else
      this.isCaptchVerified = false;
      this.userService.userAuthentication(userName + '[Page' + '[' + this.isCaptcha +'['+localStorage.getItem('ExternalIPAddress')?.toString()+'['+localStorage.getItem('LocalIPAddress')?.toString(), password).subscribe((datacom: any) => {
        console.log(datacom);
        this.isPageReset = true;
        sessionStorage.setItem('userToken', datacom['access_token']);
        sessionStorage.setItem('userId', datacom['userName']);
        sessionStorage.setItem('expires', datacom[".expires"]);
        sessionStorage.setItem('issued', datacom[".issued"]);
        if (datacom['userRole'] != '') {
          sessionStorage.setItem('userRole', '{"Roles":[' + datacom['userRole'].replace(/'/g, '"') + ']}');
          let userRole = that.commonfunction.getUserRoleValuestring();
          var text = userRole != null ? JSON.parse(userRole) : null;
          sessionStorage.setItem('currentRole', JSON.stringify(text.Roles[0]));
        }
        else {
          sessionStorage.setItem('userRole', '');
          sessionStorage.setItem('currentRole', '');
        }

        let time: any = new Date(datacom[".expires"]);
        let date2: any = new Date(datacom[".issued"]);
        this.TokenExpireAfter = time - date2;
        this.userService.UpdateClaims(this.commonfunction.getUserTokenValuestring(), this.commonfunction.getUserIdValuestring(), this.TokenExpireAfter / 60000,localStorage.getItem('ExternalIPAddress')?.toString(),localStorage.getItem('LocalIPAddress')?.toString()).subscribe((datacom: any) => {
          this.spinner.hide();
          this.userData1 = datacom.table1;
          this.router.navigate(['/Landing']);
        },
          (err: HttpErrorResponse) => {
            this.spinner.hide();
            console.log(err.message);
          });
      },
        (err: HttpErrorResponse) => {
          this.isPageReset = true;
          setTimeout(() => {
            this.isPageReset = false;
          }, 5000);

          if (err.error.error == 'A') {
            this.spinner.hide();
            this.openModal();
          }
          else {
            this.spinner.hide();
            this.isLoginError = true;
            this.errorMessgae = err.error.error;
            this.isPageReset = true;
            setTimeout(() => {
              this.isPageReset = false;
            }, 2000);
            this.loginReactiveForm.reset();

          }

        },
        () => {
          this.isPageReset = true;
          setTimeout(() => {
            this.isPageReset = false;
          }, 5000);
        });
    }    
  }



  OnSubmitModal() {
    let that = this;
    this.spinner.show();
    let Remember: boolean = this.isCaptcha == 1 || this.isCaptcha == 2 ? this.loginReactiveForm.get('remember')?.value : false;
    this.userService.userAuthentication(this.loginReactiveForm.get('userName')?.value + '[Popup' + '[' + this.isCaptcha +'['+localStorage.getItem('ExternalIPAddress')?.toString()+'['+localStorage.getItem('LocalIPAddress')?.toString(), this.loginReactiveForm.get('password')?.value.toString()).subscribe((datacom: any) => {

      sessionStorage.setItem('userToken', datacom.access_token);
      sessionStorage.setItem('userId', datacom.userName);
      sessionStorage.setItem('userRole', datacom.userRole);
      sessionStorage.setItem('expires', datacom[".expires"]);
      sessionStorage.setItem('issued', datacom[".issued"]);
      if (datacom.userRole != '') {
        sessionStorage.setItem('userRole', '{"Roles":[' + datacom.userRole.replace(/'/g, '"') + ']}');
        let userRole = that.commonfunction.getUserRoleValuestring();
        var text = userRole != null ? JSON.parse(userRole) : null;
        sessionStorage.setItem('currentRole', JSON.stringify(text.Roles[0]));
      }
      else {
        sessionStorage.setItem('userRole', '');
        sessionStorage.setItem('currentRole', '');
      }
      let time: any = new Date(datacom[".expires"]);
      let date2: any = new Date(datacom[".issued"]);
      this.TokenExpireAfter = time - date2;
      this.userService.UpdateClaims(this.commonfunction.getUserTokenValuestring(), this.commonfunction.getUserIdValuestring(), this.TokenExpireAfter / 60000,this.externalIPAddress,localStorage.getItem('LocalIPAddress')?.toString()).subscribe((datacom: any) => {
        this.spinner.hide();
        this.userData1 = datacom.table1;
        
        this.router.navigate(['/Landing']);
      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
        });
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.isLoginError = true;
        this.errorMessgae = err.error.error;


      });
  }


  openModal() {
    this.globalConfirmService.ConfirmFunction("Confirm !!", "You are already login in system. Do you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.OnSubmitModal();
        }
      });
  }




  onAlertCloseHandled() {
    jQuery('#AlertModal').hide();
    this.display = 'none';
  }

  openAlertModal() {

    jQuery('#AlertModal').show();
    this.display = 'block';
  }

  onSendMail() {
    let Email = this.forgetReactiveForm.get('email')?.value;
    this.spinner.show();
    if (this.isCaptchVerifiedForgetPassword) {
      this.isCaptchVerifiedForgetPassword = false;
      this.userService.getEmail(Email).subscribe((datacom: any) => {
        this.spinner.hide();
        if (datacom.table.length < 0 || datacom.table.length == 0) {
          this.isPageResetForgetPassword = true;
          setTimeout(() => {
            this.isPageResetForgetPassword = false;
          }, 2000);
          this.AlertMessgae = "wrong email !. Please try again.";

          this.invalidMessage = "invalidMessage";

        }
        else {
          this.AlertMessgae = "Email Send Successfully.";
          this.invalidMessage = "validMessage";
          setTimeout(() => { location.reload(); }, 3000);
        }

      },
        (err: HttpErrorResponse) => {
          this.isPageResetForgetPassword = true;
          setTimeout(() => {
            this.isPageResetForgetPassword = false;
          }, 2000);

          this.spinner.hide();
          console.log(err.message);
        });
    }


  }


  onforgotPassword() {
    this.forgetPassword = !this.forgetPassword;
    let callfor = this.forgetPassword ? 2 : 1;

  }

  onCaptchVerification(verificationResult: boolean) {

    this.isCaptchVerified = verificationResult;

  }

  isFormDisabled() {
    if (this.loginReactiveForm.valid && this.isCaptchVerified) {
      return false;
    }

    else

      return true;
  }

  onCaptchVerificationForgetPassword(verificationResult: boolean) {

    this.isCaptchVerifiedForgetPassword = verificationResult;

  }

  isForgetPasswordFormDisabled() {
    if (this.forgetReactiveForm.valid && this.isCaptchVerifiedForgetPassword) {
      return false;
    }

    else

      return true;
  }
}
