<header class="custom-website" [ngClass]="{ scrollbgcolor: scrolled }">
  <div class="container">
    <div class="header-container">
      <div class="brand-logo">
        <a href="#">
          <img [src]="schLogo.logo" alt="">
        </a>
      </div>
      <div class="sidemenu" (click)="clickEvent()">
        <img class="menu-icon" style="filter: invert(1);" src="assets/school-img/menu.png" width="20px" />
      </div>
      <div class="header-navigation">
        <nav>
          <ul class="header_list">
            <li class="dropHead" *ngFor="let headlink of menulevel0"> <a [routerLink]="headlink.routkey">{{headlink.text}}</a>
                <ul *ngIf="checkLevel0child(headlink.text)" class="drop-menu menu-2">
                  <li *ngFor="let droplink of menulevel1" class="dorpHead2">
                    <a *ngIf="headlink.text == droplink.level0 " class="dropLink" [routerLink]="droplink.routkey">{{droplink.text}}</a>
                    <ul class="drop-menu2 menu-1"> 
                      <li *ngFor="let submenulink of menulevel2"><a  *ngIf="droplink.text == submenulink.level1 " [routerLink]="submenulink.routkey" 
                          class="dropLink">{{submenulink.text}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
            </li>
            <li class="btnli" *ngFor="let headBtn of menuBtn">
                  <button [routerLink]="headBtn.routkey">{{headBtn.text}}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>

<div class="overley" (click)="clickEvent()" [ngClass]="status ? 'success' : 'danger'"></div>
<aside class="side-menu" [ngClass]="status ? 'success' : 'danger'">
  <div class="side-navigation">
    <nav>
      <ul class="header_list">
        <li class="dropHead" *ngFor="let headlink of menulevel0"> <a [routerLink]="headlink.routkey">{{headlink.text}}</a>
            <ul *ngIf="checkLevel0child(headlink.text)" class="drop-menu menu-2">
              <li *ngFor="let droplink of menulevel1" class="dorpHead2">
                <a *ngIf="headlink.text == droplink.level0 " class="dropLink" [routerLink]="droplink.routkey">{{droplink.text}}</a>
                <ul class="drop-menu2 menu-1"> 
                  <li *ngFor="let submenulink of menulevel2"><a  *ngIf="droplink.text == submenulink.level1 " [routerLink]="submenulink.routkey" 
                      class="dropLink">{{submenulink.text}}</a>
                  </li>
                </ul>
              </li>
            </ul>
        </li>
        <li class="btnli" *ngFor="let headBtn of menuBtn">
              <button [routerLink]="headBtn.routkey">{{headBtn.text}}</button>
        </li>
      </ul>
    </nav>
  </div>
</aside>