import { HomeComponent } from '../../default/home/home.component';
import { RedirectComponent } from '../../default/redirect-component'

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';

import { PublicGenericMasterFormComponent } from '../../custum/public-generic-master-form/public-generic-master-form.component';
import { PublicGenericMultiFormComponent } from '../../custum/public-generic-multi-form/public-generic-multi-form.component';
import { PublicGenericLandingPageComponent } from '../../custum/public-generic-landing-page/public-generic-landing-page.component';
import { PublicGenericContactPageComponent } from '../../custum/public-generic-contact-page/public-generic-contact-page.component';
import { PublicGenericPhotoPageComponent } from '../../custum/public-generic-photo-page/public-generic-photo-page.component';
import { PublicGenericAboutPageComponent } from '../../custum/public-generic-about-page/public-generic-about-page.component';
import { DashboardHomepageComponent } from 'src/app/custum/dashboard-homepage/dashboard-homepage.component';
import { PublicCustumHomePageComponent } from 'src/app/custum/pages/public-custum-home-page/public-custum-home-page.component';
import { PublicCustumAboutpageComponent } from 'src/app/custum/pages/public-custum-aboutpage/public-custum-aboutpage.component';
import { PublicCustumContactusComponent } from 'src/app/custum/pages/public-custum-contactus/public-custum-contactus.component';
import { PublicCustumClasslistComponent } from 'src/app/custum/pages/public-custum-classlist/public-custum-classlist.component';
import { PublicCustumClassDetailsComponent } from 'src/app/custum/pages/public-custum-class-details/public-custum-class-details.component';
import { PublicCustumAdmissonComponent } from 'src/app/custum/pages/public-custum-admisson/public-custum-admisson.component';
import { PublicCustumGalleryComponent } from 'src/app/custum/pages/public-custum-gallery/public-custum-gallery.component';
import { PublicCustumBlogComponent } from 'src/app/custum/pages/public-custum-blog/public-custum-blog.component';
import { PublicCustumBloglistComponent } from 'src/app/custum/pages/public-custum-bloglist/public-custum-bloglist.component';

export const CUSTUM_ROUTES: Routes = [
  // By default, route to PublicGenericLandingPageComponent
  // { path: '', component: PublicGenericLandingPageComponent },
  
  // Other routes to load components, but path will remain blank
  { path: '', component: PublicGenericLandingPageComponent, children: [
      {path: '', component:PublicCustumHomePageComponent},
      {path: 'home', component:PublicCustumHomePageComponent},
      { path: 'aboutus', component: PublicCustumAboutpageComponent },
      { path: 'contact', component: PublicCustumContactusComponent },
      { path: 'ClassList', component: PublicCustumClasslistComponent },
      { path: 'admisson', component: PublicCustumAdmissonComponent },
      { path: 'class/:routerlink', component: PublicCustumClassDetailsComponent },
      { path: 'gallery', component: PublicCustumGalleryComponent },
      { path: 'blogs', component: PublicCustumBlogComponent },
      { path: 'BlogList/:routeKey', component: PublicCustumBloglistComponent },

  ]},
  
  { path: 'PublicGenericMasterForm/:formName', component: PublicGenericMasterFormComponent },   
  { path: 'PublicGenericMultiForm/:formName', component: PublicGenericMultiFormComponent }
];
