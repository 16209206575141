import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import { IhomeCr, Itableth } from '../../../allUsedModule';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-testimonial-dashboard',
  templateUrl: './testimonial-dashboard.component.html',
  styleUrls: ['./testimonial-dashboard.component.css']
})
export class TestimonialDashboardComponent implements OnInit {
  activeTabId:number|any=1;
  Testimonialdata:any;
  AddNewFrom !: FormGroup;
  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "ImgUrl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Name", "colName": "Heading","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Position", "colName": "position","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "description","isActionBtn":false,"isImagePreviewEnabled":false },
  ]
  constructor(public commonfunction:Commonfunction,public homeCrservice:HomePageModelService,private confirm: ConfirmDialogBoxService, private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
    this.Testimonialdata = this.commonfunction.loadJson("~/../assets/clientStory.json");

    this.AddNewFrom = new FormGroup({
      ImgUrl:new FormControl("" , [Validators.required]),
      Heading:new FormControl("" , [Validators.required]),
      position:new FormControl("", [Validators.required]),
      description:new FormControl("" , [Validators.required])
    })
  }

  onClickResetFrom(){
    this.AddNewFrom.reset();
  }

  OnClickSubmit(){
    this.confirm.ConfirmFunction("Confirm Add New!!", "Are you sure you want to Update Record From Home?")
    .then((confirmed) => {
      if (confirmed) {
        const _item : IhomeCr={} as IhomeCr;
        _item.id = this.Testimonialdata.length + 1;
        _item.Heading = this.AddNewFrom.get("Heading")?.value;
        _item.position = this.AddNewFrom.get("position")?.value;
        _item.description = this.AddNewFrom.get("description")?.value;
        _item.ImgUrl = this.AddNewFrom.get("ImgUrl")?.value;
        this.Testimonialdata.push(_item)
        this.AddNewFrom.reset()
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
    
  }

  onClickEditForm(value:IhomeCr){
    this.homeCrservice.HomeCrService(value,true,"Name","Position","Description")
    .then((confirm)=>{
      if (confirm) {
        // this.Testimonialdata[index] = value;
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }
  deleteKey(index:number){
    this.confirm.ConfirmFunction("Confirm Delete!!", "Are you sure you want to Update Record From Home?")
    .then((confirmed) => {
      if (confirmed) {
        this.Testimonialdata.splice(index,1);
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }

}
