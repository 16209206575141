<div class="main-caro">
    <ngb-carousel>
        <ng-template ngbSlide *ngFor="let HomePagedata of carsouselbannerdata?.carouselData">
            <div class="picsum-img-wrapper carousel">
                <img class="bannerImg" [src]="HomePagedata.ImgUrl" alt="Random first slide" />
            </div>
            <div class="overlay-custom">
                <div class="container" data-aos="fade-right">
                    <h1 class="strok-text">{{HomePagedata.Heading}}</h1>
                    <h1 class="planheadline">{{HomePagedata.position}}</h1>
                    <p>{{HomePagedata.description}}</p>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
</div>