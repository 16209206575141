import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import{IhomeExperiance,Itableth} from "../../../allUsedModule";
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-experience-dashboard',
  templateUrl: './experience-dashboard.component.html',
  styleUrls: ['./experience-dashboard.component.css']
})
export class ExperienceDashboardComponent implements OnInit {
  liveExperianceTab:any;
  MainHeadingTxtForm !: FormGroup;
  activeTabId:number=1;

  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "ImgUrl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Title", "colName": "Heading","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "Content","isActionBtn":false,"isImagePreviewEnabled":false },
  ]

  constructor(public commonfunction:Commonfunction,public homePageservice:HomePageModelService,private confirm: ConfirmDialogBoxService, private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
   const data = this.commonfunction.loadJson("~/../assets/experience-custm.json"); 
    this.liveExperianceTab = data?.contentlop;

    this.MainHeadingTxtForm =new FormGroup({
      heading:new FormControl(this.liveExperianceTab?.tophead?.headline , [Validators.required]),
      details:new FormControl(this.liveExperianceTab?.tophead?.description , [Validators.required]),
    })
  }

  onClickResetFrom(){
    this.MainHeadingTxtForm.reset()
  }
  OnClickSubmit(){
    this.confirm.ConfirmFunction("Confirm Update!!", "Are you sure you want to Update Record From Home?")
    const _item = this.MainHeadingTxtForm.get("")?.value;
    this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
  }



  //

  onClickEditForm(value:IhomeExperiance){
      this.homePageservice.HomeExperianceService(value,true)
      .then((confirm)=>{
        if(confirm){
          this.liveExperianceTab?.contentlop.push(value);
          this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
        }
      })
  }
  deleteKey(index:number){}

}
