import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Commonfunction } from 'src/app/shared/commonutility';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-public-custum-class-details',
  templateUrl: './public-custum-class-details.component.html',
  styleUrls: ['./public-custum-class-details.component.css']
})
export class PublicCustumClassDetailsComponent implements OnInit {
    classDetails: any;
  popularClasses: any;
  paramsSub: Subscription | undefined;
 

   constructor(public commonfunction: Commonfunction, private route:ActivatedRoute) {}

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe((params: any) => {
      const routeKey = params['routerlink'];
      console.log(routeKey, "routeKey");

      const allclassesData = this.commonfunction.loadJson("~/../assets/classesdetailspage.json");
      this.classDetails = allclassesData.find((x:any) =>x.routerlink === routeKey);
      console.log(this.classDetails)
      this.popularClasses = this.classDetails;
    });          
  }

  ngOnDestroy(): void {
    this.paramsSub?.unsubscribe();
  }

}
