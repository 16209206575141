import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import {Ifooter,Itableth} from '../../allUsedModule'
import { FooerPageModelService } from '../../allFormPopUpModel/fooer-page-model/fooer-page-model.service';

@Component({
  selector: 'app-footer-dashboard',
  templateUrl: './footer-dashboard.component.html',
  styleUrls: ['./footer-dashboard.component.css']
})
export class FooterDashboardComponent implements OnInit {
  footerUpdateForm !:FormGroup;
  footerdata:any;
  activeTabId:number=1;
  footerdataArray:any[]=[];
  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "socialImgurl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Url Link", "colName": "socialLink","isActionBtn":false,"isImagePreviewEnabled":false }
  ]

  constructor(public commonfunction:Commonfunction,private footerService:FooerPageModelService) { }

  ngOnInit(): void {
    this.footerdata = this.commonfunction.loadJson("~/../assets/footer.json")[0];

    this.footerdataArray = this.footerdata?.socialmedia;

    this.footerUpdateForm = new FormGroup({
      address:new FormControl(this.footerdata.address , [Validators.required]),
      addresslastline:new FormControl(this.footerdata.addresslastline , [Validators.required]),
      addressline2:new FormControl(this.footerdata.addressline2 , [Validators.required]),
      emailIdsDiv:new FormControl(this.footerdata.emailIdsDiv , [Validators.required]),
      phonenumber:new FormControl(this.footerdata.phonenumber , [Validators.required]),
      botomLine:new FormControl(this.footerdata.botomLine , [Validators.required]),
      footerdescription:new FormControl(this.footerdata.footerdescription , [Validators.required]),
    })
    
  }

  OnClickSubmit(){
    let _item :Ifooter={} as Ifooter;
    _item.address = this.footerUpdateForm.get('address')?.value;
    _item.addresslastline = this.footerUpdateForm.get('addresslastline')?.value;
    _item.addressline2 = this.footerUpdateForm.get('addressline2')?.value;
    _item.emailIdsDiv = this.footerUpdateForm.get('emailIdsDiv')?.value;
    _item.phonenumber = this.footerUpdateForm.get('phonenumber')?.value;
    _item.botomLine = this.footerUpdateForm.get('botomLine')?.value;
    _item.footerdescription = this.footerUpdateForm.get('footerdescription')?.value;
    console.log(_item);
    
  }

  onClickEditForm(value:any){
    this.footerService.FooterService(value)
  }

}
