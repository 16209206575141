<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/about-bg.webp" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Discover Our Blogs </p>
                <h1>Blogs</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">Blogs</span></p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="classDetailspage">
    <div class="classdetail_wrapper py-5">
        <div class="container">
            <div class="row position-relative">
                <div class="col-md-8">
                    <div class="headTitle">
                        <div>
                            <h1>{{classDetails.title}}</h1>
                        </div>
                        <div>
                            <p> {{classDetails.description}}</p>
                        </div>
                    </div>
                    <div class="topimgbox">
                        <img src="{{classDetails.mainData[0]?.imageUrl}}" alt="">
                    </div>
                    <div class="content mt-4">
                        <h1>{{classDetails.mainData[0]?.headline1}}</h1>
                        <p>{{classDetails.mainData[0]?.description}}</p>
                    </div>
                    <div class="topimgbox mt-5">
                        <video src="{{classDetails.mainData[0]?.videoUrl}}"></video>
                    </div>
                    <div class="content mt-4">
                        <h1>{{classDetails.mainData[0]?.headline2}}</h1>
                        <p>{{classDetails.mainData[0]?.description2}}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="holdBox">
                        <div class="hold_warpper">
                            <h4>Blog Details</h4>
                            <div class="topsection">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Start Date</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.createdDate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Created By</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.Createdby}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Share By</p>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex shareBlog">
                                            <a href="www.facebook.com"><i class="fa fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/direct/inbox"><i class="fa fa-instagram"></i></a>
                                            <a href="www.web.whatsapp.com"><i class="fa fa-whatsapp"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="bottomdata">
                                <h4>Recently Uploaded Blogs</h4>
                                <div class="row py-3"  *ngFor="let item of recentBlogs">
                                    <div class="col-md-4">
                                        <div class="imgbox-prvw">
                                            <img [src]="item.imageUrl" width="100%" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <h6>{{item.title}}</h6>
                                        <p>{{item.createdDate}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>