import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Response, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';

import { FileSizePipe } from '../customFilter/fileSizePipe-Filer';
import { FileTypePipe } from '../customFilter/fileTypePipe-Filer';
import { UserService } from '../user.service';
import { IfileValidationType, IfileTypeOutput, IfileType ,filetypeEnum} from '../model/fileType';
import { GlobalAlertService } from '../global-alert.service';
import { CaptureImageServiceService } from '../capture-image/capture-image-service.service';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { PreviewImageService } from '../../shared/preview-image/preview-image.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
  providers: [FileSizePipe, FileTypePipe]
})
export class UploadFileComponent implements OnInit {
  imagePreview: string | null = null; 

  @Input() fileValidationstring?: string |null |undefined;
  fileValidationObject?: IfileValidationType;
  maxFileSize: number=0;
  fileSizeMessage?: string |null |undefined;
  maxFileCount: number = 0;
  fileCountMessage?: string |null |undefined;
  maxFileType: any=[];
  fileTypeMessage?: string |null |undefined;
  _uploadedFile?: string|null |undefined ="";
  _uploadedPreviousFile?: string|null |undefined ="";
  _childComponentFileDelete: boolean = false;
  @Input() formName: string ='';
  @Input() controlisDisabled?: string |null |undefined;
  @Input() childComponentFormSequence?: string |null |undefined;
  @Input()
  set uploadedPreviousFile(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
      setTimeout(() => {      
      this._uploadedPreviousFile = target;
    });
  } 
  }

  get uploadedPreviousFile(){
    return this._uploadedPreviousFile ;
  }
  @Input()
  set childComponentFileDelete(target: boolean){
    if(target != null && target != undefined){
      setTimeout(() => {      
      this._childComponentFileDelete = target;
    });
  } 
  }

  get childComponentFileDelete(){
    return this._childComponentFileDelete ;
  }
  // @Input() uploadedFile: string="";
  
  @Input() 
  set uploadedFile(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
      
      setTimeout(() => {
      let file: IfileType = {};
      if(this.files.length>0)
      this.files.splice(0,1);
      file.uploadedFiles = 1;
      file.uploadedFileNewName = target;
      file.uploadedFileName = this.fileColumnName+'.'+target.slice((Math.max(0, target.lastIndexOf(".")) || Infinity) + 1);
      this.files.push(file);
      this._uploadedFile = target;
    });
  }
    

  }

  get uploadedFile(){
    return this._uploadedFile ;
  }
  @Input() fileColumnName: string  ='';
  // @Input() FormReset: Subject<boolean> = new Subject<boolean>();
  @Input() 
  set FormReset(target: filetypeEnum){
    
    if(target == filetypeEnum.Yes)
      {
        if(this.files.length>0)
        this.files.splice(0,1);
        setTimeout(() => {
        this.FormResetEvent.emit(filetypeEnum.No);}, 0);
      }
      else if(target == filetypeEnum.YesWithDel)
      {
        if(this.files.length>0 && this.uploadedPreviousFile == "")       
        this.deleteAttachment(0,this.files[0].uploadedFileNewName);
        else
        this.files.splice(0, 1);
        setTimeout(() => {
        this.FormResetEvent.emit(filetypeEnum.No);}, 0);
      }
      else if(target == filetypeEnum.YesPreviousDel)
      {
        if(this.files[0].uploadedFileNewName != this.uploadedPreviousFile)
        {
          this.deletePreviousAttachment(0,this.uploadedPreviousFile);
        }
        else
        {
          if(this.files.length>0)
        this.files.splice(0,1);
        }       
        setTimeout(() => {
        this.FormResetEvent.emit(filetypeEnum.No);}, 0);
      }
  }
 
  @Output() FormResetEvent = new EventEmitter();
  @Output() upFile = new EventEmitter<IfileTypeOutput>();
  files: Array<IfileType> = [];
  mediaDeviceAvailable:any;
  constructor(private fileSizePipe: FileSizePipe, private fileTypePipe: FileTypePipe, private userService: UserService
    ,private globalAlertService: GlobalAlertService,private captureImageService: CaptureImageServiceService,
    private previewImageService:PreviewImageService) {



  }

  
  ngOnInit() {
    
    let that = this;
    if(this.fileValidationstring !="")
    this.fileValidationObject = this.fileValidationstring ? JSON.parse(this.fileValidationstring): null;
    if (this.fileValidationObject != null) {

      if (this.fileValidationObject.fileCount != null) {
        this.maxFileCount = this.fileValidationObject.fileCount;
        this.fileCountMessage = this.fileValidationObject.fileCountMessage != null ? this.fileValidationObject.fileCountMessage : "File Count should not more than " + this.maxFileCount + " file.";
      }
      else {
        this.fileCountMessage = "File Count should not more than " + this.maxFileCount + " file.";
      }
      if (this.fileValidationObject.fileSize != null) {
        this.maxFileSize = this.fileValidationObject.fileSize;
        this.fileSizeMessage = this.fileValidationObject.fileSizeMessage != null ? this.fileValidationObject.fileSizeMessage : "File Size should be less than " + this.maxFileSize + "MB.";
      }
      else {
        this.fileSizeMessage = "File Size should be less than " + this.maxFileSize + "MB.";
      }

      if (this.fileValidationObject.fileType != null) {
        this.maxFileType = this.fileValidationObject.fileType;
        this.fileTypeMessage = this.fileValidationObject.fileTypeMessage != null ? this.fileValidationObject.fileTypeMessage : "File Type should be like " + this.maxFileType.join(', ');
      }
      else {
        this.fileTypeMessage = "File Type should be like " + this.maxFileType?.join(', ');
      }

    }
    else {

      this.maxFileCount = 1;
      this.maxFileSize = 2;
      this.maxFileType = ["image/png","image/jpeg"];
      this.fileCountMessage = "File Count should not more than " + this.maxFileCount + " file.";
      this.fileSizeMessage = "File Size should be less than " + this.maxFileSize + "MB.";
      this.fileTypeMessage = "File Type should be like " + this.maxFileType.join(', ');
    }

    // if(this._uploadedFile != "" && this._uploadedFile != null && this._uploadedFile != undefined){
    //   let file: IfileType = {};
    //   if(this.files.length>0)
    //   this.files.splice(0,1);
    //   file.uploadedFiles = 1;
    //   file.uploadedFileNewName = this._uploadedFile;
    //   file.uploadedFileName = this.fileColumnName+'.'+this._uploadedFile.slice((Math.max(0, this._uploadedFile.lastIndexOf(".")) || Infinity) + 1);
    //   this.files.push(file);
    // }
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
       this.mediaDeviceAvailable = mediaDevices.length
      });


  }


  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const file = input.files[0];
  
      // Check if the file is an image
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result as string; // Set image preview
  
          // Create an object matching the IfileType structure
          const fileObject: IfileType = {
            uploadedFileName: file.name,
            uploadedFileNewName: `uploaded_${Date.now()}_${file.name}`, // Example unique name
            fileData: this.imagePreview // Optional: Preview data
          };
  
          // Add to the files array
          this.files.push(fileObject);
        };
        reader.readAsDataURL(file); // Read file and convert to Base64
      } else {
        alert('Please upload an image file!');
      }
    }
  }
  



  uploadFile(event:any, fileControl:any) {
    var Eventfiles = event.target.files;
    
    let fileOutPut: IfileTypeOutput = {};
    
    if (Eventfiles.length > this.maxFileCount) {
      fileOutPut.fileErrror = true;
      fileOutPut.fileErrorMessage = this.fileCountMessage;
      fileOutPut.formSequence = this.childComponentFormSequence;
      this.upFile.emit(fileOutPut);
    }
    else {

      var formData: FormData = new FormData();
      let isSizeofallfilevalidated: Boolean = true;
      let isFileTypeofallfilevalidated: Boolean = true;

      for (var i = 0; i < Eventfiles.length; i++) {
        
        isSizeofallfilevalidated = this.fileSizePipe.transform(Eventfiles[i].size, this.maxFileSize);
        isFileTypeofallfilevalidated = this.fileTypePipe.transform(Eventfiles[i].type, this.maxFileType);
      }

      if (isSizeofallfilevalidated && isFileTypeofallfilevalidated) {

        for (var i = 0; i < Eventfiles.length; i++) {
          formData.append('uploadedFiles', Eventfiles[i], Eventfiles[i].name);
         
        }
        
        this.userService.filesupload(formData).subscribe((data: any) => {
          
          if(this.files.length>0)
          {
            if(this.uploadedPreviousFile == "" || this._childComponentFileDelete)
            {
              this.deleteAttachment(0,this.files[0].uploadedFileNewName);
              this.files.splice(0,1);
            }
            else if(this.uploadedPreviousFile != this.files[0].uploadedFileNewName || this._childComponentFileDelete){
              this.deleteAttachment(0,this.files[0].uploadedFileNewName);
              this.files.splice(0,1);
            }
            else
            {
              this.files.splice(0,1);
            }
            
          }
          for (let index = 0; index < data.table1.length; index++) {
            var file = data.table1[0];
            file['uploadedFileName']= this.fileColumnName+'.'+file['uploadedFileName'].slice((Math.max(0, file['uploadedFileName'].lastIndexOf(".")) || Infinity) + 1);
            this.files.push(file);           
          }
        },
          (err:any) => {
            fileOutPut.fileErrror = true;
            fileOutPut.fileErrorMessage = "File upload error!";
          },
          () => {
            if (this.files.length > 0) {
              fileOutPut.fileErrror = false;
              fileOutPut.fileName = this.files[0].uploadedFileNewName;
              fileOutPut.formSequence = this.childComponentFormSequence;
              fileControl.value = null;
              this.upFile.emit(fileOutPut);

            }
          }
        );
      }
      else if (!isFileTypeofallfilevalidated) {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = this.fileTypeMessage;
        fileOutPut.formSequence = this.childComponentFormSequence;
        this.upFile.emit(fileOutPut);
      }
      else if (!isSizeofallfilevalidated) {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = this.fileSizeMessage;
        fileOutPut.formSequence = this.childComponentFormSequence;
        this.upFile.emit(fileOutPut);
      }
    }
   
  }

  deleteAttachment(index:number, fileName:any) {
    let fileOutPut: IfileTypeOutput = {};
    let that = this;
    this.userService.deleteFile(fileName, this.formName).subscribe((res:any) => {
      
      if (res == 1) {
        if(this.files.length > 0 && this.files[index].uploadedFileNewName == fileName)
        this.files.splice(index, 1);
      }
    },
      (err:any) => {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = "File delete error!";
        fileOutPut.formSequence = that.childComponentFormSequence ;
        this.upFile.emit(fileOutPut);
      },
      () => {
        
        // if(this.files.length > 0 && this.files[index].uploadedFileNewName == fileName)
        // {
          fileOutPut.fileErrror = false;
          fileOutPut.fileName = "";
          fileOutPut.formSequence = that.childComponentFormSequence ;

        // }
        // else
        // {
        //   fileOutPut.fileErrror = false;
        //   fileOutPut.fileName = this.files[index].uploadedFileNewName;
        //   fileOutPut.formSequence = that.childComponentFormSequence ;
        // }
        
        this.upFile.emit(fileOutPut);
      }
    );
   
  }
  deletePreviousAttachment(index:number, fileName:any) {
    let fileOutPut: IfileTypeOutput = {};
    let that = this;
    this.userService.deleteFile(fileName, this.formName).subscribe((res:any) => {
      if (res == 1) {
        if(this.files.length > 0)
        this.files.splice(index, 1);
      }     
    },
      (err:any) => {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = "File delete error!";
        fileOutPut.formSequence = that.childComponentFormSequence ;
        this.upFile.emit(fileOutPut);
      }
    );
   
  }

  deleteListItem(index: number, fileName : string, isReset: boolean) {
    let fileOutPut: IfileTypeOutput = {};
   
        if(this.files.length > 0 && this.files[index].uploadedFileNewName == fileName)
        this.files.splice(index, 1);
    
        if((this.files.length > 0 && this.files[index].uploadedFileNewName == fileName) || isReset)
        {
          fileOutPut.fileErrror = false;
          fileOutPut.fileName = "";

        }
        else
        {
          fileOutPut.fileErrror = false;
        fileOutPut.fileName = this.files[index].uploadedFileNewName;

        }
        this.upFile.emit(fileOutPut); 
  }

  downLoadAttachment(fileName: any,flagDownload:number) {
    let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + fileName + "&columnName=" + this.fileColumnName;
        //e.preventDefault();  //stop the browser from following
        
        this.userService.downloadFile(fileName, this.fileColumnName, this.formName).subscribe((res:any) => {
            if (res == "true") {
                // window.open(_url);
                if(flagDownload == 2){

                  this.previewImageService.PreviewImageFunction('Preview',fileName,this.fileColumnName,this.formName,"Download","Cancel","sm")
                  .then((confirmed:any) => {
                      if (confirmed) {
                       
                      }
                  });


              }
              else{
                window.location.href = _url;
              }
            }
            else {
                this.globalAlertService.AlertMsg("Alert!!", "File not Exists", "Ok", " NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            }

        });
  }
  fileClickEvent(){
    let fileOutPut: IfileTypeOutput = {};
    fileOutPut.fileErrror = false;
        fileOutPut.fileName = "";
        this.upFile.emit(fileOutPut);
  }
  uploadCapturedFile(webcamImage: WebcamImage) {
    let fileOutPut: IfileTypeOutput = {};
    
      let formData: FormData = new FormData();
      let isSizeofallfilevalidated: Boolean = true;
      let isFileTypeofallfilevalidated: Boolean = true;    
      isFileTypeofallfilevalidated = this.fileTypePipe.transform(webcamImage['_mimeType'], this.maxFileType);  
      if (isFileTypeofallfilevalidated) {

          // formData.append('uploadedFiles',webcamImage.imageAsDataUrl.replace('data:image/jpeg;base64,', ''),"CapturedImage.jpg" );
        

        this.userService.Capturefilesupload(webcamImage.imageAsDataUrl.replace('data:image/jpeg;base64,', '')).subscribe((data: any) => {
          
          if(this.files.length>0)
          {
            if(this.uploadedPreviousFile == "" || this._childComponentFileDelete)
            {
              this.deleteAttachment(0,this.files[0].uploadedFileNewName);
              this.files.splice(0,1);
            }
            else if(this.uploadedPreviousFile != this.files[0].uploadedFileNewName || this._childComponentFileDelete){
              this.deleteAttachment(0,this.files[0].uploadedFileNewName);
              this.files.splice(0,1);
            }
            else
            this.files.splice(0,1);
          }
          for (let index = 0; index < data.table1.length; index++) {
            var file = data.table1[0];
            file['uploadedFileName']= this.fileColumnName+'.'+file['uploadedFileName'].slice((Math.max(0, file['uploadedFileName'].lastIndexOf(".")) || Infinity) + 1);
            this.files.push(file);           
          }
        },
          (err:any) => {
            fileOutPut.fileErrror = true;
            fileOutPut.fileErrorMessage = "File upload error!";
            fileOutPut.formSequence = this.childComponentFormSequence;       
          },
          () => {
            if (this.files.length > 0) {
              fileOutPut.fileErrror = false;
              fileOutPut.fileName = this.files[0].uploadedFileNewName;  
              fileOutPut.formSequence = this.childComponentFormSequence;            
              this.upFile.emit(fileOutPut);

            }
          }
        );
      }
      else if (!isFileTypeofallfilevalidated) {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = this.fileTypeMessage;
        fileOutPut.formSequence = this.childComponentFormSequence;       
        this.upFile.emit(fileOutPut);
      }
      else if (!isSizeofallfilevalidated) {
        fileOutPut.fileErrror = true;
        fileOutPut.fileErrorMessage = this.fileSizeMessage;
        fileOutPut.formSequence = this.childComponentFormSequence;       
        this.upFile.emit(fileOutPut);
      }
    
   
  }
  captureFileClickEvent(){
    this.captureImageService.CaptureImageFunction('Capture Picture','Test',this.formName,'Upload Image','Cancel','sm')
    .then((confirmed) => {
      
      if (confirmed["GenericCaptureFileUpload"].flag) {
        
         this.uploadCapturedFile(confirmed["GenericCaptureFileUpload"].fileImage);
      }
  });
  }




}
