import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericTableViewAlertComponent} from  './generic-table-view-alert.component';

@Injectable({
  providedIn: 'root'
})
export class GenericTableViewAlertServiceService {

  constructor(private modalService: NgbModal) { }

  public TableviewModelFunction(
    title: string,
    ViewDetailChildtableHeaderData =[],
    ViewDetailChildtableRowData = [],
    btnOkText: string = 'OK123',
    AlertDialogType: string,
    AlertDialogClass: string,   
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(GenericTableViewAlertComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.DialogTitle = title;
    modalRef.componentInstance.ViewDetailChildtableHeaderData = ViewDetailChildtableHeaderData;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.AlertDialogType = AlertDialogType + " custom-action-modal";
    modalRef.componentInstance.AlertDialogClass = AlertDialogClass ;
    modalRef.componentInstance.ViewDetailChildtableRowData = ViewDetailChildtableRowData;
    return modalRef.result;
  }
}
