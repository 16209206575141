<!-- <table>
  <thead><tr><td>
    <div class="header-space"></div>
  </td></tr></thead>
  <tbody><tr><td>
    <div class="content"> -->
      <router-outlet></router-outlet>
    <!-- </div>
  </td></tr></tbody>
  <tfoot><tr><td>
    <div class="footer-space"></div>
  </td></tr></tfoot>
</table>

<div class="header">COOL HEADER</div>
<div class="footer">AWESOME FOOTER</div> -->