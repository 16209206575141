<!-- <ul class="nav nav-tabs master-form-tab mb-4" role="tablist">
  <li role="presentation" class="active"><a class="nav-link active" id="LinkAdd" href="#divAddNewForm"
      (click)="onMainFormTab();" data-toggle="tab">Add New Detail</a></li>
  <li role="presentation"> <a class="nav-link" id="LinkView" href="#divViewDetail" (click)="onViewDetailTab();"
      data-toggle="tab">View Detail</a></li>
</ul> -->

<!-- Tab panes -->
<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onMainFormTab();" class="nav-link">Add New Detail</a>
    <ng-template ngbNavContent>
      <div class=" pb-3" [ngStyle]="{'display':displayContainer}">

        <div class="form-group">

          <div class="row m-0">
            <div class="col-lg-4">
              <label for="DrpRole" class="bmd-label-floating">Select Role</label>
              <angular2-multiselect id="DrpRole" [data]="dropdownListRole" [(ngModel)]="selectedItemsRole"
                (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-4">
              <label for="Drptable" class="bmd-label-floating">Select Table</label>
              <angular2-multiselect id="Drptable" [data]="dropdownListTable" [(ngModel)]="selectedItemsTable"
                [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-4 text-right">
              <button id="btnReset" (click)="openResetModal()" class="btn btn-secondry">Reset</button>
              <button id="btnSearch" (click)="onAddclick()" class="btn btn-primary ml-3">Add New</button>
            </div>
          </div>
        </div>

        <!-- </div>  -->

      </div>
      <div role="tabpanel" class="tab-pane" id="divAddNewForm">

        <div class="inner-content Divbackground mt-4 pt-4 border-top" *ngIf="isCreatedDivRender">

          <div class="row m-0">

            <div class="col-lg-2">
              <label class="col-form-label">Create Permission</label>
              <angular2-multiselect id="DrpCreate" [data]="dropdownListpageloadCreatePermission"
                [(ngModel)]="selectedItemspageloadCreatePermission" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Edit Permission</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadEditPermission"
                [(ngModel)]="selectedItemspageloadEditPermission" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Active Permission</label>
              <angular2-multiselect id="DrpActive" [data]="dropdownListpageloadActivePermission"
                [(ngModel)]="selectedItemspageloadActivePermission" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Delete Permission</label>
              <angular2-multiselect id="DrpDelete" [data]="dropdownListpageloadDeletePermission"
                [(ngModel)]="selectedItemspageloadDeletePermission" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Print Permission</label>
              <angular2-multiselect id="DrpPrint" [data]="dropdownListpageloadPrintPermission"
                [(ngModel)]="selectedItemspageloadPrintPermission" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label>Action</label>
              <angular2-multiselect id="DrpAction" [data]="dropdownListReportCheckBoxAction"
                [(ngModel)]="selectedItemsReportCheckBoxAction" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <hr />

          <div class="row m-0">
            <div class="col-lg-2">
              <label class="col-form-label">Enter Table Alias Name</label>
              <input [(ngModel)]="txtTableAlias" type="text" class="form-control" id="txtTableAlias"
                (blur)="txtTableNameFocuscheckFormAlreadyExists($event,'I')" required>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Show Test Form</label>
              <angular2-multiselect id="DrpTestForm" [data]="dropdownListpageloadTestForm"
                [(ngModel)]="selectedItemspageloadTestForm" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Page Style</label>
              <angular2-multiselect id="DrpStyle" [data]="dropdownListpageloadPageStyle"
                [(ngModel)]="selectedItemspageloadPageStyle" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>

            <div class="col-lg-2">
              <label class="col-form-label">Mail Template</label>
              <angular2-multiselect id="Drpwf" [data]="dropdownListpageloadMailTemplatedrp"
                [(ngModel)]="selectedItemspageloadMailTemplatedrp" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Work Flow</label>
              <angular2-multiselect id="Drpwf" [data]="dropdownListpageloadWfdrp"
                [(ngModel)]="selectedItemspageloadWfdrp" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label>Action Group</label>
              <angular2-multiselect id="DrpActionGroup" [data]="dropdownListpageloadActiondrp"
                [(ngModel)]="selectedItemspageloadActiondrp" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <hr />
          <div class="row m-0">
            <div class="col-lg-4">
              <label class="col-form-label">Enter Where Condition For Select</label>
              <textarea [(ngModel)]="txtTableAliaswhereCondition" class="form-control"
                id="txtTableAliaswhereCondition"></textarea>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Enter Select statement with column statement</label>
              <textarea [(ngModel)]="txtTableAliasSelect" class="form-control" id="txtTableAliasSelect"></textarea>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is History</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsHistory"
                [(ngModel)]="selectedItemspageloadIsHistory" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is Excel</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsExcel"
                [(ngModel)]="selectedItemspageloadIsExcel" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is PDF</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsPdf"
                [(ngModel)]="selectedItemspageloadIsPdf" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <div  id="DivData" class="table-responsive-class mx-3 mt-5">
            <table [id]="mainTableId"  class='table  table-bordered'>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Column Name</th>
                  <th>Data Type</th>
                  <th>Column Alias</th>
                  <th>Column Sequence</th>
                  <th>Column Class</th>
                  <th>Column Event</th>
                  <th>Column Length</th>
                  <th>Required</th>
                  <th>View Link On</th>
                  <th>Hide Column</th>
                  <th>Hide Column Form</th>
                  <th>Hide Column Update Form</th>
                  <th>Edit Disabled</th>
                  <th>Column HideView</th>
                  <th>Column HideMobileView</th>
                  <th>Column FilterMobileView</th>
                  <th>Column HideMobileForm</th>
                  <th>Excel DownloadColumn</th>
                  <th>PDF DownloadColumn</th>
                  <th>Value Fill Type</th>
                  <th>ChildTable Name</th>
                  <th>DropDownQuery</th>
                  <th>Select Control</th>
                  <th>Select Validation</th>
                  <th>Validation Message</th>
                  <th>RegExp String</th>
                  <th>Select Data Exists</th>
                  <th>Data Exists Params</th>
                  <th>Comparison Column</th>
                  <th>Range(min-max)</th>
                  <th>Column Information</th>
                  <th>Column Default Value</th>
                  <th>column Miscellaneous Type</th>
                  <th>Expression Json</th>
                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let rowData of tableData; let i=index;">
                  <td>{{(i +1)}}</td>
                  <td>
                    <ng-container
                    *ngIf="rowData.columnName.toLowerCase() != 'id' && rowData.columnName.toLowerCase() != 'guid'">
                    <a  href="javascript:void(0)" (click)="onClickOpenRowDetail(rowData, i)">{{rowData['columnName']}}</a>
                    </ng-container>
                    <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'">
                    {{rowData['columnName']}}
                    </ng-container>
                  </td>
                  <td>{{rowData.dataType}}</td>
                  <td>{{rowData.columnAliasName}}</td>
                  <td>{{rowData.columnSequence}}</td>
                  <td>{{ReplaceNullDataTable(rowData.controlClass)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnEventDefination)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnLength)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.iS_NULLABLE =="no" ? "Yes":"No")}}</td>
                  <td>{{ReplaceNullDataTable(rowData.isViewLink)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.createColumnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.editColumnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.isEditDisabled)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideMobileView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnFilterMobileView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideMobileForm)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.excelDownloadColumn)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.pdfDownloadColumn)}}</td>
                  <td>{{ReplaceNullDataTable(showFillTypeText(rowData.valueFillType))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.dropDownChildtable)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.dropDownQuery)}}</td>
                  <td>{{ReplaceNullDataTable(showControlTypeText(rowData.controlType))}}</td>
                  <td>{{ReplaceNullDataTable(showValidationTypeText(rowData.Validation))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.customValidationMsg)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.regExp)}}</td>
                  <td>{{ReplaceNullDataTable(showDataExistsTypeText(rowData.checkExistsDataValidationType))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.checkExistsDataValidationParams)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.compareColumnName)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.range)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnInformation)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnDefaultValue)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.miscellaneousType)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.expressionJson)}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="Submitbtn-content mt-5 px-3">

            <button type="button" id="btnSubmit" (click)="OnSubmitModal()" class="btn btn-primary">Submit</button>

          </div>

        </div>

        <!-- </div> -->
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink class="nav-link" (click)="onViewDetailTab();">View Detail</a>
    <ng-template ngbNavContent>
      <div role="tabpanel" class="tab-pane" id="divViewDetail">
        <div class="row justify-content-md-center tableDiv">
          <div class="col-12" style="margin-bottom: 15px;"><button type="button" class="btn btn-danger"
              (click)="clearAllFilter()">Clear</button>
            <ng-container *ngFor="let filterQuery of filterDisplayQuery; let i=index">
              <b *ngIf="i != 0">{{filterQuery.operator}}</b>
              <span class="badge-box"> {{filterQuery.displayQuery}} <a (click)="delFilter(filterQuery.columnName)"><i
                    class="fa fa-trash" aria-hidden="true"></i></a></span>
            </ng-container>
          </div>
          <div class="col-12">
            <div id="DivtableDataEdit" class="table-responsive main-table-wrappr">
              <table [id]="Edittableid" class="table table-bordered row-border hover w-100 ">

                <thead>
                  <tr>
                    <th>S.No.</th>
                    <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">
                      <th scope="headerDetailData">
                        {{FirstLetterUpper(headerDetailData['displayName'])}}

                        <i (click)="onSortClick($event,headerDetailData['key'])" class="fa fa-sort-amount-desc"></i>
                        <div class="filter-box">

                          <a placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="manual"
                            #p2="ngbPopover" [autoClose]="'outside'"
                            (click)="toggleWithFilterCol(p2, ConvertStringLowerCase(headerDetailData['key']), FirstLetterUpper(headerDetailData['displayName']),'','',true)">
                            <i class="fa fa-filter" aria-hidden="true"></i></a>

                        </div>
                      </th>
                    </ng-container>
                    <!-- <th style='display:none;'>MailId</th> -->
                    <th>Table Columns</th>
                    <th class="sorting-none">Action</th>
                  </tr>

                </thead>
                <tbody>
                  <ng-container *ngIf="detailTableData?.length >0; else elsedetailTableEmpty">

                    <tr *ngFor="let rowDetailData of detailTableData; let i=index;"
                      [attr.tblcoltablename]="rowDetailData['tableName']"
                      [attr.tblaliasname]="rowDetailData['tableAliasName']"
                      [attr.pageStyle]="rowDetailData['pageStyle']" [attr.mailId]="rowDetailData['mailId']"
                      [attr.wfId]="rowDetailData['wfid']">
                      <td>{{(i + 1) }}</td>
                      <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">

                        <td>
                          <div>
                            {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["key"])])}}
                          </div>

                        </td>

                      </ng-container>
                      <!-- <td>{{rowDetailData["tableName"]}}</td>
                    <td>{{rowDetailData["tableAliasName"]}}</td>
                    <td>{{rowDetailData["userRole"]}}</td>
                    <td>{{rowDetailData["addPermission"] == 0 ? 'No':'Yes'}}</td>
                    <td>{{rowDetailData["editPermission"] == 0 ? 'No':'Yes'}}</td>
                    <td>{{rowDetailData["activePermission"] == 0 ? 'No':'Yes'}}</td>
                    <td>{{rowDetailData["deletePermission"] == 0 ? 'No':'Yes'}}</td>
                    <td>{{rowDetailData["printPermission"] == 0 ? 'No':'Yes'}}</td>
                    <td>
                      {{rowDetailData["checkBoxFlag"] == 0 ? 'No': rowDetailData["checkBoxFlag"] == 1 ?'Yes' : 'Select All'}}
                    </td>
                    <td>{{rowDetailData["mailTriggerSP"]}}</td>
                    <td>{{rowDetailData["mailtemp"]}}</td>
                    <td>{{rowDetailData["workflow"]}}</td>
                
                    <td>{{rowDetailData["pageStyle"]}}</td>
                    <td>{{rowDetailData["isHistory"] == 0 ? 'No' : 'Yes'}}</td> -->

                      <td><a href="javascript:void(0)"
                          (click)="onGetTableDetailsByTableId(rowDetailData['tableName'],rowDetailData['tableAliasName'],rowDetailData['userRoleId'],rowDetailData['pageStyle'],rowDetailData['mailId'],rowDetailData['wfid'],rowDetailData['addPermission'],rowDetailData['editPermission'],rowDetailData['activePermission'],rowDetailData['deletePermission'],rowDetailData['printPermission'],rowDetailData['checkBoxFlag'],rowDetailData['mailTriggerSPId'],rowDetailData['isHistory'],rowDetailData['selectQuery'],rowDetailData['selWhereCondition'],rowDetailData['isExcelDownload'],rowDetailData['isPdfDownload'],$event)"
                          class='active opendetail'><i class='fa fa-info'></i></a>
                      </td>
                      <td><a href="javascript:void(0)"
                          (click)="onGetEditableTableDetailsByTableId(rowDetailData['tableName'],rowDetailData['tableAliasName'],rowDetailData['userRoleId'],rowDetailData['pageStyle'],rowDetailData['mailId'],rowDetailData['wfid'],rowDetailData['addPermission'],rowDetailData['editPermission'],rowDetailData['activePermission'],rowDetailData['deletePermission'],rowDetailData['printPermission'],rowDetailData['checkBoxFlag'],rowDetailData['mailTriggerSPId'],rowDetailData['isHistory'],rowDetailData['selectQuery'],rowDetailData['selWhereCondition'],rowDetailData['isExcelDownload'],rowDetailData['isPdfDownload'],rowDetailData['formTestPermission'],$event)"
                          class='edit opendetail'><i class='fa fa-pencil'></i></a>

                        <a href="javascript:void(0)" *ngIf="rowDetailData['isActive']"
                          (click)="onTableAliasAction(rowDetailData['id'],'InActive',i,rowDetailData['userRoleId'])"
                          class='active opendetail'><i class='fa fa-check'></i></a>

                        <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive']"
                          (click)="onTableAliasAction(rowDetailData['id'],'Active',i,rowDetailData['userRoleId'])"
                          class='active opendetail'><i class='fa fa-times'></i></a>

                        <a href="javascript:void(0)"
                          (click)="onTableAliasAction(rowDetailData['id'],'Deleted',i,rowDetailData['userRoleId'])"
                          class='delete opendetail'><i class='fa fa-trash'></i></a>

                      </td>

                    </tr>
                  </ng-container>
                  <ng-template #elsedetailTableEmpty>
                    <ng-container>
                      <tr>
                        <td colspan="9"> No Data Available</td>
                      </tr>

                    </ng-container>
                  </ng-template>

                </tbody>
              </table>
            </div>
          </div>
          <ng-template #popContent>

            <form [formGroup]="filterForm" #form="ngForm">
              <div class="row">
                <div class="col mb-2">
                  <div class="btn-group">
                    <input type="radio" class="btn-check form-control form-control-sm" formControlName="columnCondition"
                      id="radio1" autocomplete="off" value="AND" checked>
                    <label class="btn btn-outline-primary" for="radio1">AND</label>

                    <input type="radio" class="btn-check form-control form-control-sm" formControlName="columnCondition"
                      id="radio2" autocomplete="off" value="OR">
                    <label class=" btn btn-outline-primary" for="radio2">OR</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-2">
                  <select class="form-control form-control-sm form-select form-select-sm"
                    formControlName="filterColumnOperator" aria-label=".form-select-sm example">
                    <option selected value=1>Equal</option>
                    <option value=2>Not Equal</option>
                    <option value=3>Contains</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col mb-2">
                  <input type="text" [placeholder]="filterColumnDisplayName" required minlength="1" maxlength="200"
                    class="form-control form-control-sm"
                    [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                    formControlName="filterValue">

                  <div *ngIf="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                    class="invalid-feedback">
                    <div *ngIf="filterValue.errors?.['required']">
                      This field is required.
                    </div>
                    <div *ngIf="filterValue.errors?.['minlength']">
                      This field must have at least 1 character.
                    </div>
                    <div *ngIf="filterValue.errors?.['maxlength']">
                      This field must have at most 200 characters.
                    </div>
                    <!-- <div *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
                  Invalid email format.
                </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mt-2 popover-btn d-flex">
                  <button type="button" class="btn btn-sm clear-btn" (click)="clearFilterForm()">Clear</button>
                  <button type="button" [disabled]="!filterValue.valid" (click)="applyFilter()"
                    class="btn btn-sm apply-btn btn-primary">Apply</button>
                </div>
              </div>
            </form>
          </ng-template>
          <ng-template #popTitle>Filter {{filterColumnDisplayName}}</ng-template>
        </div>
        <div class="table-footer">
          <div class="footer-selct">
            <select class="custom-select form-control" style="width: auto" [(ngModel)]="pageSize"
              (ngModelChange)="pageSizeChange();">
              <option [ngValue]="10">10 items</option>
              <option [ngValue]="25">25 items</option>
              <option [ngValue]="50">50 items</option>
              <option [ngValue]="100">100 items</option>
            </select>
            <pre><span class="float-md-left">Page: {{ currentPage }} / {{ dataPagination.pageCount }}</span></pre>
          </div>
          <div class="footer-pagintion">
            <ngb-pagination class="d-flex justify-content-center mt-2" #dataPagination [collectionSize]="totalRecords"
              [(page)]="currentPage" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
              (pageChange)="SearchEdit(1)"></ngb-pagination>
          </div>

        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
<!-- </div> -->
<!-- start Modals -->
<div class="backdrop" [ngStyle]="{'display':display}"></div>

<!-- start UpdateMaster Modal -->
<div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content edit-modal-form">
      <div class="modal-header">
        <!-- <h4 class="modal-title pull-left">View</h4> -->
        <h2 class="modal-title pull-left" id="MasterUpdateHeaderId">Table Column Record</h2>

        <button type="button" class="close" aria-label="Close" (click)="onCloseEditModal()"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body p-0">

        <div class="p-3">
          <div class="row mx-0 mb-4 pb-3 border-bottom">

            <div class="col-lg-2">
              <label class="col-form-label">Create Permission</label>
              <angular2-multiselect id="DrpCreateE" [data]="dropdownListpageloadCreatePermission"
                [(ngModel)]="selectedItemspageloadCreatePermissionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Edit Permission</label>
              <angular2-multiselect id="DrpEditE" [data]="dropdownListpageloadEditPermission"
                [(ngModel)]="selectedItemspageloadEditPermissionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Active Permission</label>
              <angular2-multiselect id="DrpActiveE" [data]="dropdownListpageloadActivePermission"
                [(ngModel)]="selectedItemspageloadActivePermissionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Delete Permission</label>
              <angular2-multiselect id="DrpDeleteE" [data]="dropdownListpageloadDeletePermission"
                [(ngModel)]="selectedItemspageloadDeletePermissionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Print Permission</label>
              <angular2-multiselect id="DrpPrintE" [data]="dropdownListpageloadPrintPermission"
                [(ngModel)]="selectedItemspageloadPrintPermissionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label>Action</label>
              <angular2-multiselect id="DrpActionE" [data]="dropdownListReportCheckBoxAction"
                [(ngModel)]="selectedItemsReportCheckBoxActionE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>

          </div>
          <div class="row m-0">
            <!-- <div class="col-lg-3">Enter Table Alias Name</div> -->
            <div class="col-lg-2">
              <label>Enter Table Alias Name</label>
              <input [(ngModel)]="txtTableAliasE" type="text" class="form-control" id="txtTableAliasE"
                (blur)="txtTableNameFocuscheckFormAlreadyExists($event,'E')" name="txtTableAliasE" required>

            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Show Test Form</label>
              <angular2-multiselect id="DrpTestFormE" [data]="dropdownListpageloadTestForm"
                [(ngModel)]="selectedItemspageloadTestFormE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <!-- <div class="col-lg-3">Select Page Style</div> -->
            <div class="col-lg-2">
              <label>Select Page Style</label>
              <angular2-multiselect id="DrpStyleE" [data]="dropdownListpageloadPageStyle"
                [(ngModel)]="selectedItemspageloadPageStyleE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <!-- <div class="col-lg-3">Select Mail Template</div> -->
            <div class="col-lg-2">
              <label>Select Mail Template</label>
              <angular2-multiselect id="DrpmailE" [data]="dropdownListpageloadMailTemplatedrp"
                [(ngModel)]="selectedItemspageloadMailTemplatedrpE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <!-- <div class="col-lg-3">Select Work Flow </div> -->
            <div class="col-lg-2">
              <label>Select Work Flow</label>
              <angular2-multiselect id="DrpwfE" [data]="dropdownListpageloadWfdrp"
                [(ngModel)]="selectedItemspageloadWfdrpE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label>Action Group</label>
              <angular2-multiselect id="DrpActionGroupE" [data]="dropdownListpageloadActiondrp"
                [(ngModel)]="selectedItemspageloadActiondrpE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-lg-4">
              <label class="col-form-label">Enter Where Condition For Select</label>
              <textarea [(ngModel)]="txtTableAliaswhereConditionE" class="form-control"
                id="txtTableAliaswhereConditionE"></textarea>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Enter Select statement with column statement</label>
              <textarea [(ngModel)]="txtTableAliasSelectE" class="form-control" id="txtTableAliasSelectE"></textarea>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is History</label>
              <angular2-multiselect id="DrpEditE" [data]="dropdownListpageloadIsHistory"
                [(ngModel)]="selectedItemspageloadIsHistoryE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is Excel</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsExcel"
                [(ngModel)]="selectedItemspageloadIsExcelE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
            <div class="col-lg-2">
              <label class="col-form-label">Is PDF</label>
              <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsPdf"
                [(ngModel)]="selectedItemspageloadIsPdfE" [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <br>
          <br>
          <div  id="DivData" class="table-responsive-class mx-3 mt-5">
            <table [id]="mainTableIdE" class='table generateMasterFormEdit  table-bordered'>
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Column Name</th>
                  <th>Saved Data Type</th>
                  <th>Current Data Type</th>
                  <th>Column Alias</th>
                  <th>Column Sequence</th>
                  <th>Column Class</th>
                  <th>Column Event</th>
                  <th>Saved Column Length</th>
                  <th>Current Column Length</th>
                  <th>Saved Required</th>
                  <th>Current Required</th>
                  <th>View Link On</th>
                  <th>Hide Column</th>
                  <th>Hide Column Form</th>
                  <th>Hide Column Update Form</th>
                  <th>Edit Disabled</th>
                  <th>Column HideView</th>
                  <th>Column HideMobileView</th>
                  <th>Column FilterMobileView</th>
                  <th>Column HideMobileForm</th>
                  <th>Excel DownloadColumn</th>
                  <th>PDF DownloadColumn</th>
                  <th>Value Fill Type</th>
                  <th>ChildTable Name</th>
                  <th>DropDownQuery</th>
                  <th>Select Control</th>
                  <th>Select Validation</th>
                  <th>Validation Message</th>
                  <th>RegExp String</th>
                  <th>Select Data Exists</th>
                  <th>Data Exists Params</th>
                  <th>Comparison Column</th>
                  <th>Range(min-max)</th>
                  <th>Column Information</th>
                  <th>Column Default Value</th>
                  <th>column Miscellaneous Type</th>
                  <th>Expression Json</th>
                  <th>Action</th>
                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let rowData of editTableData ; let i=index;" 
                  [ngClass]="{'alart_danger': rowData.columnFlag==1, 'alart_info': rowData.columnFlag==0 }">
                  <!-- <td>{{(i +1)}}</td>
                  <td><span id="spn_{{i}}E">{{rowData['columnName']}}</span></td>
                  <td><span id="spn_{{rowData['columnName']}}E">{{rowData['dataType']}}</span></td>
                  <td><span id="spnCurrent_{{rowData['columnName']}}E">{{rowData['originalDataType']}}</span></td>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseif1E">
                    <td><input type='text' style='width:140px;' readonly disabled id="txtc_{{rowData['columnName']}}E"
                        class='form-control' name="txtc_{{rowData['columnName']}}E" value="{{rowData['columnAlias']}}">
                    </td>
                  </ng-container>
                  <ng-template #elseif1E>
                    <ng-container>
                      <td><input type='text' style='width:140px;' id="txtc_{{rowData['columnName']}}E"
                          class='form-control' name="txtc_{{rowData['columnName']}}E"
                          value="{{rowData['columnAlias'] }}"></td>
                    </ng-container>
                  </ng-template>
                  <td>
                    <input type='text' style='width:140px;' id="txtSeq_{{rowData['columnName']}}E" class='form-control'
                      name="txtSeq_{{rowData['columnName']}}E" value="{{rowData['columnSequence'] }}">
                  </td>
                  <td>
                    <input type='text' style='width:140px;' id="txtClass_{{rowData['columnName']}}E"
                      class='form-control' name="txtClass_{{rowData['columnName']}}E"
                      value="{{rowData['controlClass'] }}">
                  </td>
                  <td>
                    <input type='text' style='width:140px;' id="txtEvent_{{rowData['columnName']}}E"
                      class='form-control' name="txtEvent_{{rowData['columnName']}}E"
                      value="{{rowData['columnEventDefination']}}">
                  </td>
                  <ng-container *ngIf="rowData['columnLength'] == '-1'; else elseifcolumnlengthSaved1E">
                    <td><input type='text' style='width:140px;' id="txt_{{rowData['columnName']}}E"
                        class='form-control' name="txt_{{rowData['columnName']}}E" value="Max"></td>
                  </ng-container>
                  <ng-template #elseifcolumnlengthSaved1E>
                    <ng-container
                      *ngIf="rowData['originalDataType'] == 'date' || rowData['originalDataType'] == 'datetime' || rowData['originalDataType'] == 'bit'; else elseifcolumnlengthSaved2E">
                      <td><input type='text' style='width:140px;' id="txt_{{rowData['columnName']}}E"
                          class='form-control' name="txt_{{rowData['columnName']}}E" value="" disabled></td>
                    </ng-container>
                  </ng-template>
                  <ng-template #elseifcolumnlengthSaved2E>
                    <ng-container
                      *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifcolumnlength3EE">
                      <td><input type='text' style='width:140px;' id="txt_{{rowData['columnName']}}E"
                          class='form-control' name="txt_{{rowData['columnName']}}E"
                          value="{{rowData['columnLength']}}" disabled></td>
                    </ng-container>
                  </ng-template>
                  <ng-template #elseifcolumnlength3EE>
                    <ng-container>
                      <td><input type='text' style='width:140px;' id="txt_{{rowData['columnName']}}E"
                          class='form-control' name="txt_{{rowData['columnName']}}E"
                          value="{{rowData['columnLength']}}"></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf="rowData['table_columnLength'] == '-1'; else elseifcolumnlengthE1E">
                    <td><input type='text' style='width:140px;' id="txtCurrent_{{rowData['columnName']}}E"
                        class='form-control' name="txtCurrent_{{rowData['columnName']}}E" disabled value="Max"></td>
                  </ng-container>
                  <ng-template #elseifcolumnlengthE1E>
                    <ng-container
                      *ngIf="rowData['originalDataType'] == 'date' || rowData['originalDataType'] == 'datetime' || rowData['originalDataType'] == 'bit'; else elseifcolumnlengthE2E">
                      <td><input type='text' style='width:140px;' id="txtCurrent_{{rowData['columnName']}}E"
                          class='form-control' name="txtCurrent_{{rowData['columnName']}}E" value="" disabled></td>
                    </ng-container>
                  </ng-template>
                  <ng-template #elseifcolumnlengthE2E>
                    <ng-container
                      *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifcolumnlengthE3E">
                      <td><input type='text' style='width:140px;' id="txtCurrent_{{rowData['columnName']}}E"
                          class='form-control' name="txtCurrent_{{rowData['columnName']}}E"
                          value="{{rowData['table_columnLength']}}" disabled></td>
                    </ng-container>
                  </ng-template>
                  <ng-template #elseifcolumnlengthE3E>
                    <ng-container>
                      <td><input type='text' style='width:140px;' id="txtCurrent_{{rowData['columnName']}}E"
                          class='form-control' name="txtCurrent_{{rowData['columnName']}}E" disabled
                          value="{{rowData['table_columnLength']}}"></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf="rowData['iS_NULLABLE'].toLowerCase() == 'yes'; else elseifrequiredE">
                    <td><input type='checkbox' id="chk_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifrequiredE>
                    <ng-container>
                      <td><input type='checkbox' id="chk_{{rowData['columnName']}}E" checked
                          [attr.disabled]="rowData['isOriginalRequired'].toLowerCase() == 'no' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>

                  <ng-container
                    *ngIf="rowData['isOriginalRequired'].toLowerCase() == 'yes'; else elseifrequiredcurrentE">
                    <td><input type='checkbox' id="chkCurrent_{{rowData['columnName']}}E " disabled> <span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifrequiredcurrentE>
                    <ng-container>
                      <td><input type='checkbox' id="chkCurrent_{{rowData['columnName']}}E" checked disabled><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() != 'id' && rowData['columnName'].toLowerCase() != 'guid'; else elseifViewLinkE">
                    <td><input type='checkbox' id="chkView_{{rowData['columnName']}}E"
                        [attr.checked]="rowData['isViewLink'].toLowerCase() == 'yes' ? true : null"><span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifViewLinkE>
                    <ng-container>
                      <td><input type='checkbox' id="chkView_{{rowData['columnName']}}E" disabled
                          [attr.checked]="rowData['isViewLink'].toLowerCase() == 'yes' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf=" rowData['hideColumn'].toLowerCase() == 'yes'; else elseifHideE">
                    <td><input type='checkbox' checked id="chkHide_{{rowData['columnName']}}E"><span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifHideE>
                    <ng-container>
                      <td><input type='checkbox' id="chkHide_{{rowData['columnName']}}E"
                          [attr.disabled]="rowData['isOriginalRequired'].toLowerCase() == 'no' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf=" rowData['createColumnHide'].toLowerCase() == 'yes'; else elseifHideCFE">
                    <td><input type='checkbox' checked id="chkHideCF_{{rowData['columnName']}}E"><span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifHideCFE>
                    <ng-container>
                      <td><input type='checkbox' id="chkHideCF_{{rowData['columnName']}}E"
                          [attr.disabled]="rowData['isOriginalRequired'].toLowerCase() == 'no' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf=" rowData['editColumnHide'].toLowerCase() == 'yes'; else elseifHideUFE">
                    <td><input type='checkbox' checked id="chkHideUF_{{rowData['columnName']}}E"><span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifHideUFE>
                    <ng-container>
                      <td><input type='checkbox' id="chkHideUF_{{rowData['columnName']}}E"
                          [attr.disabled]="rowData['isOriginalRequired'].toLowerCase() == 'no' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf="rowData['isEditDisabled'].toLowerCase() == 'yes'; else elseifiseditDisabledE">
                    <td><input type='checkbox' checked id="chkEditDiabled_{{rowData['columnName']}}E"><span
                        class='checkmark'></span></td>
                  </ng-container>
                  <ng-template #elseifiseditDisabledE>
                    <ng-container>
                      <td><input type='checkbox' id="chkEditDiabled_{{rowData['columnName']}}E"
                          [attr.disabled]="rowData['isOriginalRequired'].toLowerCase() == 'no' ? true : null"><span
                          class='checkmark'></span></td>
                    </ng-container>
                  </ng-template>
                  <td><input type='checkbox' [checked]="rowData['columnHideView'].toLowerCase() == 'yes'? true : null "
                      id="chkcolumnHideView_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td><input type='checkbox'
                      [checked]="rowData['columnHideMobileView'].toLowerCase() == 'yes'? true : null "
                      id="chkcolumnHideMobileView_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td><input type='checkbox'
                      [checked]="rowData['columnFilterMobileView'].toLowerCase() == 'yes'? true : null "
                      id="chkcolumnFilterMobileView_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td><input type='checkbox'
                      [checked]="rowData['columnHideMobileForm'].toLowerCase() == 'yes'? true : null "
                      id="chkcolumnHideMobileForm_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td><input type='checkbox'
                      [checked]="rowData['excelDownloadColumn'].toLowerCase() == 'yes'? true : null "
                      id="chkexcelDownloadColumn_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td><input type='checkbox'
                      [checked]="rowData['pdfDownloadColumn'].toLowerCase() == 'yes'? true : null "
                      id="chkpdfDownloadColumn_{{rowData['columnName']}}E"><span class='checkmark'></span></td>
                  <td> <select style='width:200px;' id="DrpValue_{{rowData['columnName']}}E" class="form-control">
                      <ng-container *ngFor="let opt of dropdownListpageloadValueFillTypedrp">
                        <option *ngIf="opt['id'] == rowData['valueFillType']" value="{{opt['id']}}" selected>
                          {{opt['itemName']}}</option>
                        <option *ngIf="opt['id'] != rowData['valueFillType']" value="{{opt['id']}}">{{opt['itemName']}}
                        </option>
                      </ng-container>
                    </select> </td>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifchildTableE">
                    <td><input type='text' style='width:140px;' readonly disabled
                        id="txtChild_{{rowData['columnName']}}E" class='form-control'
                        name="txtChild_{{rowData['columnName']}}E" value="{{rowData['dropDownChildtable']}}"></td>
                  </ng-container>
                  <ng-template #elseifchildTableE>
                    <ng-container>
                      <td><input type='text' style='width:140px;' id="txtChild_{{rowData['columnName']}}E"
                          class='form-control' name="txtChild_{{rowData['columnName']}}E"
                          value="{{rowData['dropDownChildtable']}}"></td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDropQueryE">
                    <td><textarea rows='2' cols='50' type='text' style='width:140px;' readonly disabled
                        id="txtQuery_{{rowData['columnName']}}E" class='form-control'
                        name="txtQuery_{{rowData['columnName']}}E">{{rowData['dropDownQuery']}}</textarea></td>
                  </ng-container>
                  <ng-template #elseifDropQueryE>
                    <ng-container>
                      <td><textarea rows='2' cols='50' type='text' style='width:140px;'
                          id="txtQuery_{{rowData['columnName']}}E" class='form-control'
                          name="txtQuery_{{rowData['columnName']}}E">{{rowData['dropDownQuery']}}</textarea></td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDropControlE">
                    <td><select disabled id="DrpC_{{rowData['columnName']}}E" class="form-control">
                        <option value="">Select</option>
                        <ng-container *ngFor="let opt of dropdownListpageloadControldrp">
                          <option *ngIf="opt['id'] == rowData['control']" value="{{opt['id']}}" selected>
                            {{opt['itemName']}}
                          </option>
                          <option *ngIf="opt['id'] != rowData['control']" value="{{opt['id']}}">{{opt['itemName']}}
                          </option>
                        </ng-container>
                      </select>
                    </td>
                  </ng-container>
                  <ng-template #elseifDropControlE>
                    <ng-container>
                      <td><select id="DrpC_{{rowData['columnName']}}E" class="form-control">
                          <option value="">Select</option>
                          <ng-container *ngFor="let opt of dropdownListpageloadControldrp">
                            <option *ngIf="opt['id'] == rowData['control']" value="{{opt['id']}}" selected>
                              {{opt['itemName']}}
                            </option>
                            <option *ngIf="opt['id'] != rowData['control']" value="{{opt['id']}}">{{opt['itemName']}}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDropValidationE">
                    <td><select disabled id="Drp_{{rowData['columnName']}}E" class="form-control">
                        <option value="">Select</option>
                        <ng-container *ngFor="let opt of dropdownListpageloadValidationdrp">
                          <option *ngIf="opt['id'] == rowData['validation']" value="{{opt['id']}}" selected>
                            {{opt['itemName']}}
                          </option>
                          <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">{{opt['itemName']}}
                          </option>
                        </ng-container>
                      </select>
                    </td>
                  </ng-container>
                  <ng-template #elseifDropValidationE>
                    <ng-container>
                      <td><select id="Drp_{{rowData['columnName']}}E" class="form-control">
                          <option value="">Select</option>
                          <ng-container *ngFor="let opt of dropdownListpageloadValidationdrp">
                            <option *ngIf="opt['id'] == rowData['validation']" value="{{opt['id']}}" selected>
                              {{opt['itemName']}}</option>
                            <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">{{opt['itemName']}}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifValidationMsgE">
                    <td><input type='text' id="txtValidationMsg_{{rowData['columnName']}}E" disabled
                        class='form-control' name="txtValidationMsg_{{rowData['columnName']}}E"
                        value="{{rowData['customValidationMsg']}}" title='Custom Validation Message'
                        placeholder='Validation Message'>
                    </td>
                  </ng-container>
                  <ng-template #elseifValidationMsgE>
                    <ng-container>
                      <td><input type='text' id="txtValidationMsg_{{rowData['columnName']}}E" class='form-control'
                          name="txtValidationMsg_{{rowData['columnName']}}E" value="{{rowData['customValidationMsg']}}"
                          title='Custom Validation Message' placeholder='Validation Message'>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifRegExpE">
                    <td><input type='text' id="txtRegExp_{{rowData['columnName']}}E" disabled class='form-control'
                        name="txtRegExp_{{rowData['columnName']}}E" value="" title='Custom RegExp String'
                        placeholder='RegExp String'>
                    </td>
                  </ng-container>
                  <ng-template #elseifRegExpE>
                    <ng-container>
                      <td><input type='text' id="txtRegExp_{{rowData['columnName']}}E" class='form-control'
                          name="txtRegExp_{{rowData['columnName']}}E" value="{{rowData['regExp']}}"
                          title='Custom RegExp String' placeholder='RegExp String'>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDropDataExixtsE">
                    <td><select disabled id="DrpDataExists_{{rowData['columnName']}}E" class="form-control">
                        <option value="">Select</option>
                        <ng-container *ngFor="let opt of dropdownListpageloadDataExists">
                          <option *ngIf="opt['id'] == rowData['checkExistsDataValidationType']" value="{{opt['id']}}"
                            selected>{{opt['itemName']}}
                          </option>
                          <option *ngIf="opt['id'] != rowData['checkExistsDataValidationType']" value="{{opt['id']}}">
                            {{opt['itemName']}}</option>
                        </ng-container>
                      </select>
                    </td>
                  </ng-container>
                  <ng-template #elseifDropDataExixtsE>
                    <ng-container>
                      <td><select id="DrpDataExists_{{rowData['columnName']}}E" class="form-control">
                          <option value="">Select</option>
                          <ng-container *ngFor="let opt of dropdownListpageloadDataExists">
                            <option *ngIf="opt['id'] == rowData['checkExistsDataValidationType']" value="{{opt['id']}}"
                              selected>
                              {{opt['itemName']}}</option>
                            <option *ngIf="opt['id'] != rowData['checkExistsDataValidationType']" value="{{opt['id']}}">
                              {{opt['itemName']}}
                            </option>
                          </ng-container>
                        </select>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDataExistsParamE">
                    <td><textarea rows='2' cols='50' type='text' style='width:140px;' readonly disabled
                        id="txtDataExistsParam_{{rowData['columnName']}}E" class='form-control'
                        name="txtDataExistsParam_{{rowData['columnName']}}E">{{rowData['checkExistsDataValidationParams']}}</textarea>
                    </td>
                  </ng-container>
                  <ng-template #elseifDataExistsParamE>
                    <ng-container>
                      <td><textarea rows='2' cols='50' type='text' style='width:140px;'
                          id="txtDataExistsParam_{{rowData['columnName']}}E" class='form-control'
                          name="txtDataExistsParam_{{rowData['columnName']}}E">{{rowData['checkExistsDataValidationParams']}}</textarea>
                      </td>
                    </ng-container>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifDropCompairE">
                    <td><select disabled id="DrpCC_{{rowData['columnName']}}E" class="form-control">
                        <option value="">Select</option>
                        <ng-container *ngFor="let opt of dropdownListpageloadControldrpNumber">
                          <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}" selected>
                            {{opt['itemName']}}
                          </option>
                          <option *ngIf="opt['id'] != rowData['compareColumnName']" value="{{opt['id']}}">
                            {{opt['itemName']}}</option>
                        </ng-container>
                      </select>
                    </td>
                  </ng-container>
                  <ng-template #elseifDropCompairE>
                    <ng-container
                      *ngIf="rowData['dataType'].toLowerCase() == 'int' || rowData['dataType'].toLowerCase() == 'decimal'|| rowData['dataType'].toLowerCase() == 'numeric'; else elseifDropCompair1">
                      <td><select id="DrpCC_{{rowData['columnName']}}E" class="form-control">
                          <option value="">Select</option>
                          <ng-container *ngFor="let opt of dropdownListpageloadControldrpNumber">
                            <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}" selected>
                              {{opt['itemName']}}
                            </option>
                            <option *ngIf="opt['id'] != rowData['compareColumnName']" value="{{opt['id']}}">
                              {{opt['itemName']}}</option>
                          </ng-container>
                        </select>
                      </td>
                    </ng-container>
                    <ng-template #elseifDropCompair1>
                      <ng-container
                        *ngIf="rowData['dataType'].toLowerCase() == 'date' || rowData['dataType'].toLowerCase() == 'datetime'; else elseifDropCompair2E">
                        <td><select id="DrpCC_{{rowData['columnName']}}E" class="form-control">
                            <option value="">Select</option>
                            <option *ngIf="'Today' == rowData['compareColumnName']" value='Today' selected> Current Date
                            </option>
                            <option *ngIf="'Today' != rowData['compareColumnName']" value='Today'> Current Date</option>
                            <ng-container *ngFor="let opt of dropdownListpageloadControlDatedrp">
                              <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}" selected>
                                {{opt['itemName']}}</option>
                              <option *ngIf="opt['id'] != rowData['compareColumnName']" value="{{opt['id']}}">
                                {{opt['itemName']}}
                              </option>
                            </ng-container>

                          </select>
                        </td>
                      </ng-container>
                    </ng-template>
                    <ng-template #elseifDropCompair2E>
                      <ng-container>
                        <td><select disabled id="DrpCC_{{rowData['columnName']}}E" class="form-control">
                            <option value="">Select</option>
                            <ng-container *ngFor="let opt of dropdownListpageloadControldrpNumber">
                              <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}" selected>
                                {{opt['itemName']}}</option>
                              <option *ngIf="opt['id'] != rowData['compareColumnName']" value="{{opt['id']}}">
                                {{opt['itemName']}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                      </ng-container>
                    </ng-template>
                  </ng-template>
                  <ng-container
                    *ngIf="rowData['dataType'].toLowerCase() == 'int' || rowData['dataType'].toLowerCase() == 'decimal' || rowData['dataType'].toLowerCase() == 'numeric'; else elseifRange1E">
                    <td><input type='text' id="txtRange_{{rowData['columnName']}}E" class='form-control'
                        name="txtRange_{{rowData['columnName']}}E" value="{{rowData['range']}}"
                        title='Range should be in given format  ex:- 20-40' placeholder='ex:- 20-40'></td>
                  </ng-container>
                  <ng-template #elseifRange1E>
                    <ng-container
                      *ngIf="rowData['dataType'].toLowerCase() == 'date' || rowData['dataType'].toLowerCase() == 'datetime'; else elseifRange2E">
                      <td><input type='text' id="txtRange_{{rowData['columnName']}}E" class='form-control'
                          name="txtRange_{{rowData['columnName']}}E" value="{{rowData['range']}}"
                          title='Date Range should be in numeric months ex:- 1year = 12' placeholder='ex:- 24'></td>
                    </ng-container>
                  </ng-template>
                  <ng-template #elseifRange2E>
                    <ng-container>
                      <td><input type='text' disabled id="txtRange_{{rowData['columnName']}}E" class='form-control'
                          name="txtRange_{{rowData['columnName']}}E" value="{{rowData['range']}}"></td>
                    </ng-container>
                  </ng-template>

                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifcolumnInformationE">
                    <td><input type='text' id="txtcolumnInformation_{{rowData['columnName']}}E" disabled
                        class='form-control' name="txtcolumnInformation_{{rowData['columnName']}}E"
                        value="{{rowData['columnInformation']}}" title='Column Information Text'
                        placeholder='Information Text'>
                    </td>
                  </ng-container>
                  <ng-template #elseifcolumnInformationE>
                    <ng-container>
                      <td><input type='text' id="txtcolumnInformation_{{rowData['columnName']}}E" class='form-control'
                          name="txtcolumnInformation_{{rowData['columnName']}}E"
                          value="{{rowData['columnInformation']}}" title='Column Information Text'
                          placeholder='Information Text'>
                      </td>
                    </ng-container>
                  </ng-template>

                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifcolumnDefaultValueE">
                    <td><input type='text' id="txtcolumnDefaultValue_{{rowData['columnName']}}E" disabled
                        class='form-control' name="txtcolumnDefaultValue_{{rowData['columnName']}}E"
                        value="{{rowData['columnDefaultValue']}}" title='Column Default Value'
                        placeholder='Default value'>
                    </td>
                  </ng-container>
                  <ng-template #elseifcolumnDefaultValueE>
                    <ng-container>
                      <td><input type='text' id="txtcolumnDefaultValue_{{rowData['columnName']}}E" class='form-control'
                          name="txtcolumnDefaultValue_{{rowData['columnName']}}E"
                          value="{{rowData['columnDefaultValue']}}" title='Column Default Value'
                          placeholder='Default value'>
                      </td>
                    </ng-container>
                  </ng-template>

                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifmiscellaneousTypeE">
                    <td><textarea rows='2' cols='50' type='text' readonly disabled
                        id="txtmiscellaneousType_{{rowData['columnName']}}E" class='form-control'
                        name="txtmiscellaneousType_{{rowData['columnName']}}E"
                        value="">{{rowData['miscellaneousType']}}</textarea></td>
                  </ng-container>
                  <ng-template #elseifmiscellaneousTypeE>
                    <ng-container>
                      <td><textarea rows='2' cols='50' type='text' id="txtmiscellaneousType_{{rowData['columnName']}}E"
                          class='form-control' name="txtmiscellaneousType_{{rowData['columnName']}}E"
                          value="">{{rowData['miscellaneousType']}}</textarea></td>
                    </ng-container>
                  </ng-template>

                  <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'; else elseifJsonE">
                    <td><textarea rows='2' cols='50' type='text' readonly disabled
                        id="txtEJson_{{rowData['columnName']}}E" class='form-control'
                        name="txtEJson_{{rowData['columnName']}}E" value="">{{rowData['expressionJson']}}</textarea>
                    </td>
                  </ng-container>
                  <ng-template #elseifJsonE>
                    <ng-container>
                      <td><textarea rows='2' cols='50' type='text' id="txtEJson_{{rowData['columnName']}}E"
                          class='form-control' name="txtEJson_{{rowData['columnName']}}E"
                          value="">{{rowData['expressionJson']}}</textarea></td>
                    </ng-container>
                  </ng-template> -->
                  <td>{{(i +1)}}</td>
                  <td>
                    <ng-container
                    *ngIf="rowData.columnName.toLowerCase() != 'id' && rowData.columnName.toLowerCase() != 'guid'">
                    <a  href="javascript:void(0)" (click)="onClickOpenEditRowDetail(rowData, i)">{{rowData['columnName']}}</a>
                    </ng-container>
                    <ng-container
                    *ngIf="rowData['columnName'].toLowerCase() == 'id' || rowData['columnName'].toLowerCase() == 'guid'">
                    {{rowData['columnName']}}
                    </ng-container>
                  </td>
                  <td>{{rowData.dataType}}</td>
                  <td>{{rowData.originalDataType}}</td>
                  <td>{{rowData.columnAliasName}}</td>
                  <td>{{rowData.columnSequence}}</td>
                  <td>{{ReplaceNullDataTable(rowData.controlClass)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnEventDefination)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnLength)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.originalColumnLength)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.iS_NULLABLE =="no" ? "Yes":"No")}}</td>
                  <td>{{ReplaceNullDataTable(rowData.isOriginalRequired =="no" ? "Yes":"No")}}</td>
                  <td>{{ReplaceNullDataTable(rowData.isViewLink)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.createColumnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.editColumnHide)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.isEditDisabled)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideMobileView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnFilterMobileView)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnHideMobileForm)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.excelDownloadColumn)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.pdfDownloadColumn)}}</td>
                  <td>{{ReplaceNullDataTable(showFillTypeText(rowData.valueFillType))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.dropDownChildtable)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.dropDownQuery)}}</td>
                  <td>{{ReplaceNullDataTable(showControlTypeText(rowData.controlType))}}</td>
                  <td>{{ReplaceNullDataTable(showValidationTypeText(rowData.Validation))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.customValidationMsg)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.regExp)}}</td>
                  <td>{{ReplaceNullDataTable(showDataExistsTypeText(rowData.checkExistsDataValidationType))}}</td>
                  <td>{{ReplaceNullDataTable(rowData.checkExistsDataValidationParams)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.compareColumnName)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.range)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnInformation)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.columnDefaultValue)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.miscellaneousType)}}</td>
                  <td>{{ReplaceNullDataTable(rowData.expressionJson)}}</td>
                  <td>
                    <a href="javascript:void(0)" *ngIf="rowData.columnFlag==1" class='delete opendetail' type='button'
                      (click)=onDeletecustumtablechild(i)><i class='fa fa-trash'></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondry" (click)="onCloseEditModal()">Close</button>
        <button type="button" id="btnUpdate" class="btn btn-primary ml-3"
          (click)="updateEditTableEntry()">Update</button>

      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<!-- /.modal !-->
<!-- End UpdateMaster Modal -->


<!-- start View Modal -->

<div id="ViewModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg inner-content " role="document">
    <div class="modal-content edit-modal-form">
      <div class="modal-header">
        <!-- <h4 class="modal-title pull-left">View</h4> -->
        <h2 class="modal-title pull-left" id="HmodalHeaderId"> View Column Record</h2>
        <button type="button" class="close" aria-label="Close" (click)="onCloseViewModal()"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div id="DivtableViewData" class="table-responsive">
          <table class="table table-bordered row-border hover">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Column Name</th>
                <th>Saved Data Type</th>
                <th>Current Data Type</th>
                <th>Column Alias</th>
                <th>Column Sequence</th>
                <th>Column Class</th>
                <th>Column Event</th>
                <th>Saved Column Length</th>
                <th>Current Column Length</th>
                <th>Saved Required</th>
                <th>Current Required</th>
                <th>View Link On</th>
                <th>Hide Column</th>
                <th>Hide Column Form</th>
                <th>Hide Column Update Form</th>
                <th>Edit Disabled</th>
                <th>Column HideView</th>
                <th>Column HideMobileView</th>
                <th>Column FilterMobileView</th>
                <th>Column HideMobileForm</th>
                <th>Excel DownloadColumn</th>
                <th>PDF DownloadColumn</th>
                <th>Value Fill Type</th>
                <th>ChildTable Name</th>
                <th>DropDownQuery</th>
                <th>Select Control</th>
                <th>Select Validation</th>
                <th>Validation Message</th>
                <th>Select Data Exists</th>
                <th>Data Exists Params</th>
                <th>Comparison Column</th>
                <th>Range(min-max)</th>
                <th>Column Information</th>
                <th>Column Default Value</th>
                <th>column Miscellaneous Type</th>
                <th>Expression Json</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let trData of viewTableData; let i=index;"
                [ngClass]="{'bg-danger text-white': trData.columnFlag==1, 'bg-info text-white': trData.columnFlag==0 }">
                <td>{{(i+1) }}</td>
                <td>{{ trData.columnName }}</td>
                <td>{{ trData.dataType }}</td>
                <td>{{ trData.table_datA_TYPE }}</td>
                <td>{{ trData.columnAlias }}</td>
                <td>{{ trData.columnSequence }}</td>
                <td>{{ trData.controlClass }}</td>
                <td>{{ trData.columnEventDefination }}</td>
                <td>{{ trData.columnLength }}</td>
                <td>{{ trData.table_columnLength }}</td>
                <td>{{ trData.iS_NULLABLE }}</td>
                <td>{{ trData.table_iS_NULLABLE }}</td>
                <td>{{ trData.isViewLink }}</td>
                <td>{{ trData.hideColumn }}</td>
                <td>{{ trData.createColumnHide }}</td>
                <td>{{ trData.editColumnHide }}</td>
                <td>{{ trData.isEditDisabled }}</td>
                <td>{{ trData.columnHideView }}</td>
                <td>{{ trData.columnHideMobileView }}</td>
                <td>{{ trData.columnFilterMobileView }}</td>
                <td>{{ trData.columnHideMobileForm }}</td>
                <td>{{ trData.excelDownloadColumn }}</td>
                <td>{{ trData.pdfDownloadColumn }}</td>
                <td>{{ trData.valueFillTypeText }}</td>
                <td>{{ trData.dropDownChildtable }}</td>
                <td>{{ trData.dropDownQuery }}</td>
                <td>{{ trData.controlText }}</td>
                <td>{{ trData.validation }}</td>
                <td>{{ trData.customValidationMsg}}</td>
                <td>
                  {{ trData.checkExistsDataValidationType == 1 ? 'From Database': trData.checkExistsDataValidationType == 2?'From Array':'' }}
                </td>
                <td>{{ trData.checkExistsDataValidationParams }}</td>
                <td>{{ trData.compareColumnName }}</td>
                <td>{{ trData.range }}</td>
                <td>{{ trData.columnInformation }}</td>
                <td>{{ trData.columnDefaultValue }}</td>
                <td>{{ trData.miscellaneousType }}</td>
                <td>{{ trData.expressionJson }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">


        <button type="button" class="btn btn-secondry" (click)="onCloseViewModal()">Cancel</button>


      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
<!-- End View Modal -->

<!-- End Modals -->