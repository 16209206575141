  <!-- start Alert Modal -->
  <div id="AlertDialogModal" [class]="AlertDialogType " >
    <div  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <div [class]="AlertDialogClass">
           
          </div> -->
          <button type="button" class="close" aria-label="Close" (click)="onCloseAlertModal()">
            <span   aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">{{DialogTitle}}</h2>
        <div class="modal-body">
            <form *ngIf="isRenderEdit && masterChildActionProductForm" novalidate [formGroup]="masterChildActionProductForm">
                
                  <div id="DivDataEdit" class="">      
                   
                    <div *ngIf="masterChildActionJsonData" class="row m-0">
                      <ng-container *ngFor="let formData of masterChildActionJsonData; let i=index">
          
                        <ng-container *ngIf="formData.identityColumn !=='yes' && formData.datA_TYPE !=='uniqueidentifier'">
                          <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
                              <angular2-multiselect id="{{masterChildActionJsonData[i].controlId}}"
                                formControlName="{{masterChildActionJsonData[i].controlName}}" [data]="masterChildActionJsonData[i].drpDataSet"
                                [settings]="masterChildActionJsonData[i].drpSetting" [(ngModel)]="masterChildActionJsonData[i].drpDataSelected"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                (onSelect)="onItemSelectEdit($event,i,false)" (onDeSelect)="OnItemDeSelectEdit($event,i,false)"
                                (onClose)="OnCloseEdit($event,masterChildActionJsonData[i].controlName)">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label style="margin: 0px;">{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
                              <angular2-multiselect id="{{masterChildActionJsonData[i].controlId}}"
                                formControlName="{{masterChildActionJsonData[i].controlName}}" [data]="masterChildActionJsonData[i].drpDataSet"
                                [settings]="masterChildActionJsonData[i].drpSetting" [(ngModel)]="masterChildActionJsonData[i].drpDataSelected"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                (onSelect)="onItemSelectEdit($event,i,true)" (onDeSelect)="OnItemDeSelectEdit($event,i,true)"
                                (onSelectAll)="onItemSelectEditAll($event,i,true)" (onDeSelectAll)="OnItemDeSelectEditAll($event,i,true)"
                                (onClose)="OnCloseEdit($event,masterChildActionJsonData[i].controlName)">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label style="margin: 0px;">{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class='{{masterChildActionJsonData[i].controlDivClass}} calnder-icon'>
                              <input type='text' class="{{masterChildActionJsonData[i].controlClass}}"
                                [attr.Placeholder]="masterChildActionJsonData[i].placeHolder" id="{{masterChildActionJsonData[i].controlId}}"
                                bsDatepicker [bsConfig]="bsConfig" autocomplete="off" readonly
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                formControlName="{{masterChildActionJsonData[i].controlName}}">
                                <div class="calenderinput-icon"><a href="javascript: void(0);" (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar" aria-hidden="true"></i></a></div>
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
          
                            </div>
          
                          </div>
                          <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label"
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME"
                              class="form-check {{masterChildActionJsonData[i].controlDivClass}}">
          
                              <input type='checkbox' value='' class="{{masterChildActionJsonData[i].controlClass}}"
                                id="{{masterChildActionJsonData[i].controlId}}"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                formControlName="{{masterChildActionJsonData[i].controlName}}">
                              <label id="lbl_{{masterChildActionJsonData[i].columN_NAME}}" class="form-check-label"
                                for="{{masterChildActionJsonData[i].controlId}}">Please check {{masterChildActionJsonData[i].columnAliasName}}
                              </label>
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
          
                          </div>
                          <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
                              <textarea rows='2' cols='50' type='text' class="{{masterChildActionJsonData[i].controlClass}}"
                                [attr.Placeholder]="masterChildActionJsonData[i].placeHolder" id="{{masterChildActionJsonData[i].controlId}}"
                                formControlName="{{masterChildActionJsonData[i].controlName}}"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }">
                                  </textarea>
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
          
                          </div>
                          
                          <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
          
                              <input type='text' class="{{masterChildActionJsonData[i].controlClass}}"
                                [attr.Placeholder]="masterChildActionJsonData[i].placeHolder" id="{{masterChildActionJsonData[i].controlId}}"
                                formControlName="{{masterChildActionJsonData[i].controlName}}" readonly [disabled]="true"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }">
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
                              <input type='text' class="{{masterChildActionJsonData[i].controlClass}}"
                                [attr.Placeholder]="masterChildActionJsonData[i].placeHolder" id="{{masterChildActionJsonData[i].controlId}}"
                                formControlName="{{masterChildActionJsonData[i].controlName}}"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                readonly [disabled]="true">
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{masterChildActionJsonData[i].spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (masterChildActionJsonData[i].iS_NULLABLE === 'no')}"
                                for="{{masterChildActionJsonData[i].controlId}}">{{masterChildActionJsonData[i].columnAliasName}}</label>
                                <span *ngIf="masterChildActionJsonData[i].columnInformation != '' && masterChildActionJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterChildActionJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                                tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{masterChildActionJsonData[i].controlDivId}}"
                              [attr.columnAliasName]="masterChildActionJsonData[i].columnAliasName" [attr.colIndex]="i"
                              [attr.columnId]="masterChildActionJsonData[i].columN_NAME" class={{masterChildActionJsonData[i].controlDivClass}}>
                              <input type='text' class="{{masterChildActionJsonData[i].controlClass}}"
                                [attr.Placeholder]="masterChildActionJsonData[i].placeHolder" id="{{masterChildActionJsonData[i].controlId}}"
                                [ngClass]="{'is-invalid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && !masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid,'is-valid': (masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.touched || masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.dirty) && masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.valid }"
                                formControlName="{{masterChildActionJsonData[i].controlName}}">
          
                              <span class=" invalid-feedback" style="padding-top: 10px;">
                                {{masterChildActionProductForm.get(masterChildActionJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
          
                            </div>
          
                          </div>
                        </ng-container>
          
                      </ng-container>
          
                    </div>
      
                    <!-- </form> -->
                  </div>
               
                
              </form>
        </div>
        <div class="NP_popup-footer text-center mt-2"> 
            <div class="d-inline-block align-middle">
                <p><a href="javascript: void(0);" class="btn btn-secondry" (click)="onCloseConfirmModal()">{{btnCancelText}}</a></p>
              </div>
              <div class="d-inline-block align-middle ml-3">
                <p class="yes"><a href="javascript: void(0);"  class="btn btn-primary" (click)="onAcceptConfirmModal()">{{btnOkText}}</a></p>
              </div>  
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End Alert Modal -->
