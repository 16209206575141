<div class="container-fluid">
  <div class="row justify-content-center">
    <!-- <span>Mobile device <span *ngIf="!mobileView">not</span> detected.</span> -->
    <div class=" p-0" *ngIf="!mobileView">
      <!-- Card Header - Dropdown -->
      <!-- <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold">Leave Records</h6>

            </div> -->
      <!-- Card Body -->
      <div class="card-body">
        <div class="row mb-2">
          <div class="add-btn-data">
            <button (click)="oneditaddbtn()">+</button>
          </div>
          <div class="col-xl-6 col-sm-12 col-md-6">
            <angular2-multiselect *ngIf="ischeckboxshow > 0" id="DrpActionGroupSP" [data]="dropdownListActionGroupSpdrp"
              ngbTooltip="Select Action" container="wrapper" placement="left" tooltipClass="tooltipBlue"
              [(ngModel)]="selectedItemsActionGroupSpdrp" [settings]="dropdownSettings"
              (onSelect)="onItemSelectChildAction($event)" (onDeSelect)="OnItemDeSelectChildAction($event)">
            </angular2-multiselect>
          </div>
          <div *ngIf="isExcelDownload || isPdfDownload" class="col-xl-6 col-sm-12 col-md-6 text-right">
            <button class="btn btn-excel" *ngIf="isExcelDownload" ngbTooltip="Download Excel" container="wrapper"
              placement="left" tooltipClass="tooltipBlue" (click)="exportAsXLSX()"><i class="excel_icon"></i></button>
            <button class="btn btn-pdf ml-2" *ngIf="isPdfDownload" ngbTooltip="Download PDF" container="wrapper"
              placement="left" tooltipClass="tooltipBlue" (click)="exportAsPDF()"><i
                class="pdf_icon"></i></button>
          </div>
        </div>

        <div class="row justify-content-md-center tableDiv">
          <div *ngIf="filterColumnQuery.length > 0" class="col-12" style="margin-bottom: 15px;">
            <div class="filter-select-box">
              <div class="filter-select">
                <ng-container *ngFor="let filterQuery of filterDisplayQuery; let i=index">
                  <b *ngIf="i != 0">{{filterQuery.operator}}</b>
                  <span class="badge-box"> {{filterQuery.displayQuery}} <a
                      (click)="delFilter(filterQuery.columnName)"><i class="fa fa-trash"
                        aria-hidden="true"></i></a></span>
                </ng-container>
              </div>
              <button type="button" class="btn btn-danger" (click)="clearAllFilter()">Clear</button>
            </div>
          </div>
          <div class="col-12">

            <ng-template #popContent>

              <form [formGroup]="filterForm" #form="ngForm">
                <div class="row">
                  <div class="col mb-2">
                    <div class="btn-group">
                      <input type="radio" class="btn-check btn_and_or form-control form-control-sm"
                        formControlName="columnCondition" id="radio1" autocomplete="off" value="AND" checked>
                      <label class="btn btn-outline-primary" for="radio1">AND</label>

                      <input type="radio" class="btn-check btn_and_or form-control form-control-sm"
                        formControlName="columnCondition" id="radio2" autocomplete="off" value="OR">
                      <label class=" btn btn-outline-primary" for="radio2">OR</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <select class="form-control btn_and_or form-control-sm form-select form-select-sm" (change)="onConditionDropdown($event)"
                      formControlName="filterColumnOperator" aria-label=".form-select-sm example">
                      <option selected value=1>Equal</option>
                      <option value=2>Not Equal</option>
                      <option *ngIf="(filterColumnDataType != 'date') && (filterControlType !='datetime') && (filterControlType !='date')" value=3>Contains</option>
                      <option *ngIf="(filterColumnDataType != 'date') && (filterControlType !='datetime') && (filterControlType !='date')" value=4>IN</option>
                      <option value=5>IS Blank</option>
                      <option value=6>IS Not Blank</option>
                      <option *ngIf="(filterColumnDataType == 'date' || filterColumnDataType == 'datetime') && (filterControlType =='datetime')" value=7>Range</option>
                      <option *ngIf="(filterColumnDataType == 'numeric' || filterColumnDataType == 'datetime' || filterColumnDataType == 'date') && (filterControlType =='textbox' || filterControlType =='datetime')" value=8>Greater Then</option>
                      <option *ngIf="(filterColumnDataType == 'numeric' || filterColumnDataType == 'datetime' || filterColumnDataType == 'date') && (filterControlType =='textbox' || filterControlType =='datetime')" value=9>Smaller Then</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <input type="text" *ngIf="filterControlType !='datetime'" [placeholder]="filterColumnDisplayName"
                      class="form-control btn_and_or form-control-sm"
                      [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                      formControlName="filterValue">

                      <input type='text' [placeholder]="filterColumnDisplayName" id="test1"
                      class="form-control form-control-sm btn_and_or"
                      ngbDatepicker
                      #date="ngbDatepicker" *ngIf="filterControlType =='datetime' && isShowSecondFilterControl==false"
                       readonly
                       (click)="openNormalDatefilter(date)"
                      [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                      formControlName="filterValue">

                   <div  *ngIf="(filterControlType =='datetime') && isShowSecondFilterControl== true">
                      <div class="col-12">
                        <div class="dp-hidden position-absolute">
                          <div class="input-group">
                            <input
                              name="datepicker"
                              class="form-control btn_and_or"
                              ngbDatepicker
                              #datepicker="ngbDatepicker"
                              [autoClose]="'outside'"
                              (dateSelect)="onDateSelection(datepicker,$event)"
                              [displayMonths]="2"
                              [dayTemplate]="t"
                              outsideDays="hidden"
                              [startDate]="fromDate!"
                              tabindex="-1"
                            />
                            <ng-template #t let-date let-focused="focused">
                              <span
                                class="custom-day btn_and_or"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null"
                              >
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                        <div class="input-group">
                          <input
                            #dpFromDate
                            class="form-control btn_and_or"
                            [placeholder]="filterColumnDisplayName"
                            name="dpFromDate"
                            [value]="rangeDates"
                            readonly
                            formControlName="filterValue"
                            (click)="openRangeDatefilter(datepicker)"
                            [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                          />
                        </div>
                      </div>

                    </div>

                    <div *ngIf="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                      class="invalid-feedback">
                      <div *ngIf="filterValue.errors?.['required']">
                        This field is required.
                      </div>
                      <div *ngIf="filterValue.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="filterValue.errors?.['maxlength']">
                        This field must have at most 200 characters.
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="mt-2 popover-btn d-flex">
                    <button type="button" class="btn btn_andmor btn-sm clear-btn btn-primary" (click)="clearFilterForm()">Clear</button>
                    <button type="button" [disabled]="!filterForm.valid" (click)="applyFilter()"
                      class="btn btn_andmor btn-sm apply-btn btn-primary">Apply</button>
                  </div>
                </div>
              </form>
            </ng-template>
            

            <div class="table-responsive main-table-wrappr" [ngClass]="isDateFilter == true ? 'active':''">
              <table class="table row-border hover w-100 ">
                <thead>
                  <tr>
                    <th *ngIf="ischeckboxshow > 0 ">
                      <input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" />

                    </th>
                    <th resizable>S.No.</th>
                    <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">

                      <th resizable scope="headerDetailData"
                        *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'">
                        {{FirstLetterUpper(headerDetailData['columnAliasName'])}}

                        <i (click)="onSortClick($event,headerDetailData['columN_NAME'])"  *ngIf="headerDetailData['controlType'] != 'ChildTable'"
                          class="fa fa-sort-amount-desc"></i>
                        <div class="filter-box"  *ngIf="headerDetailData['controlType'] != 'ChildTable'">

                          <a placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="manual"
                            #p2="ngbPopover" [autoClose]="'outside'" (hidden)="hiddenPopover(p2)"
                            (click)="toggleWithFilterCol(p2, ConvertStringLowerCase(headerDetailData['columN_NAME']), FirstLetterUpper(headerDetailData['columnAliasName']),headerDetailData['dataType'],headerDetailData['parentTable'],true,headerDetailData['controlType']); ">
                            <i class="fa fa-filter" aria-hidden="true"></i></a>

                        </div>

                      </th>

                    </ng-container>
                    <th resizable class="sorting-none"
                      *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 || isWorkflowImplemented == true">
                      Action</th>
                    <!-- <th scope="col" *ngFor="let col of tableCols">{{col.displayName}}</th> -->
                  </tr>

                </thead>

                <tbody id="EditTableBody">
                  <ng-container *ngIf="DetailtableRowData.length > 0; else elseEmptyRow">
                  <!-- <ng-container *ngIf="DetailtableRowData?.length >0; else elsedetailTableEmpty"> -->
                  <tr *ngFor="let rowDetailData of DetailtableRowData; let rowi=index;"
                    [ngClass]="rowDetailData['pendingRole']">
                    <td *ngIf="ischeckboxshow > 0">
                      <input type="checkbox" [(ngModel)]="rowDetailData['isSelected']" (change)="isAllSelected()" />

                    </td>
                    <td>{{((currentPage-1) * pageSize )+ (rowi + 1)}}

                    </td>
                    <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">
                      <td
                        *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2' && headerDetailData['isViewLink'] == true; else elsedetailTableViewLink">
                        <a href="javascript:void(0)" ngbTooltip="Show Details - {{rowDetailData['toolTipRow']}}"
                          container="wrapper" placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                          (click)="OnViewFullDetail($event, rowDetailData['id'],'view',rowDetailData['toolTipRow'])">
                          <div  class="overTextHover">
                            {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}
                          </div>
                        </a>

                      </td>
                      <ng-template #elsedetailTableViewLink>
                        <ng-container>
                          <td
                            *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file' && ConvertStringLowerCase(headerDetailData['controlType']) != 'childtable'; else elsedetailChildTableViewLink">
                            <div  class="overTextHover">
                              {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}
                            </div>

                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-template #elsedetailChildTableViewLink>
                        <ng-container>
                          <td
                            *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file' && ConvertStringLowerCase(headerDetailData['controlType']) == 'childtable'; else elsedetailrowCondition">
                            <a class='active'
                              ngbTooltip="Show {{headerDetailData['columN_NAME']}} Details - {{rowDetailData['toolTipRow']}}"
                              container="wrapper" placement="left" tooltipClass="tooltipBlue"
                              (click)="onChildOpenDetail($event, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),rowDetailData['id'], rowDetailData['toolTipRow'])">
                              <i class='fa fa-info'></i></a>

                          </td>
                        </ng-container>
                      </ng-template>

                      <ng-template #elsedetailrowCondition>
                        <ng-container>
                          <td
                            *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  == 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file' && ConvertStringLowerCase(headerDetailData['controlType']) != 'childtable'; else elsedetailTableFile">
                            <div [innerHTML]="rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]"></div>

                          </td>
                        </ng-container>
                      </ng-template>

                      <ng-template #elsedetailTableFile>
                        <ng-container>
                          <td class="file-dowmload"
                            *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'">
                            <!-- <div [attr.columnAliasName]="headerDetailData['columnAliasName']?.toLowerCase()"> -->
                            <a href="javascript:void(0)" class='active'
                              [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                              (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                              ngbTooltip="Download item - {{rowDetailData['toolTipRow']}}" container="wrapper"
                              placement="left" tooltipClass="tooltipBlue">
                              <i class='fa fa-download'></i></a>
                            <a href="javascript:void(0)" class='active'
                              [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                              (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                              ngbTooltip="Open {{headerDetailData['columN_NAME']}} item - {{rowDetailData['toolTipRow']}}"
                              container="wrapper" ngbTooltip="Tooltip on left" placement="left"
                              tooltipClass="tooltipBlue">
                              <i class='fa fa-file-image-o'></i></a>
                            <!-- </div> -->
                          </td>
                        </ng-container>
                      </ng-template>



                    </ng-container>
                    <ng-container *ngIf="isWorkflowImplemented == false;else elseWFButtonAdd">

                      <td
                        *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0">
                        <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                          (click)="OnViewFullDetail($event, rowDetailData['id'],rowDetailData['rowEditCondition'],rowDetailData['toolTipRow'])"
                          ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                          placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue"
                          class='edit opendetail'><i class='fa fa-pencil'></i></a>

                        <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                          (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',rowi, rowDetailData['toolTipRow'])"
                          ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                          placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue"
                          class='active opendetail'><i class='fa fa-check'></i> </a>

                        <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                          ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                          placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                          (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',rowi, rowDetailData['toolTipRow'])"
                          class='inactive opendetail'><i class='fa fa-times '></i></a>

                        <a *ngIf="printPermission != 0" href="javascript:void(0)"
                          (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                          ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                          placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                          class='edit opendetail'><i class='fa fa-print'></i></a>

                        <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                          (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',rowi, rowDetailData['toolTipRow'])"
                          ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                          placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                          class='delete opendetail'><i class='fa fa-trash'></i></a>

                      </td>
                    </ng-container>
                    <ng-template #elseWFButtonAdd>
                      <ng-container *ngIf="isWorkflowImplemented == true">
                        <td
                          *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 || rowDetailData['workFlowImplemented'] == true">
                          <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                            (click)="OnViewFullDetail($event, rowDetailData['id'],rowDetailData['rowEditCondition'],rowDetailData['toolTipRow'])"
                            ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='edit opendetail'><i class='fa fa-pencil'></i></a>

                          <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                            (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',rowi, rowDetailData['toolTipRow'])"
                            ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='active opendetail'><i class='fa fa-check'></i> </a>

                          <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                            ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',rowi, rowDetailData['toolTipRow'])"
                            class='inactive opendetail'><i class='fa fa-times '></i></a>

                          <a *ngIf="printPermission != 0" href="javascript:void(0)"
                            (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                            ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='edit opendetail'><i class='fa fa-print'></i></a>

                          <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                            (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',rowi, rowDetailData['toolTipRow'])"
                            ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='delete opendetail'><i class='fa fa-trash'></i></a>

                          <a *ngIf="ConvertStringLowerCase(rowDetailData['pendingLevel']) == 'pending' && rowDetailData['workFlowStep'] > 1"
                            href="javascript:void(0)"
                            (click)="OnDynamicGenericWorkFlow($event,rowDetailData['workFlowApprove'],rowDetailData['workFlowReject'],rowDetailData['workFlowReturn'],rowDetailData['workFlowForward'],rowDetailData['id'],rowDetailData['tableName'],rowDetailData['toolTipRow'])"
                            ngbTooltip="Workflow Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='edit opendetail'><i class='fa fa-snowflake-o'></i></a>

                          <a href="javascript:void(0)"
                            (click)="onWorkFlowHistoryOpenDetail($event,rowDetailData['tableName'],rowDetailData['id'],rowDetailData['toolTipRow'])"
                            ngbTooltip="Workflow  Record History - {{rowDetailData['toolTipRow']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                            class='edit opendetail ml-2'><i class='fa fa-history'></i></a>

                        </td>
                      </ng-container>
                    </ng-template>
                  </tr>
                </ng-container>
                <ng-template #elseEmptyRow>
                  <ng-container><tr ><td [attr.colspan]="tableCols.length + 2">No Data Found!</td></tr></ng-container>
                  </ng-template>
                  <!-- </ng-container>
                                <ng-template #elsedetailTableEmpty>
                                  <ng-container>

                                    <tr>
                                      <td [attr.colspan]="13" class="no-data-available"> {{tableCols?.length}}No Data Available
                                      </td>
                                    </tr>

                                  </ng-container>
                                </ng-template> -->
                </tbody>
              </table>
            </div>

            <ng-template #popTitle>Filter {{filterColumnDisplayName}}</ng-template>
          </div>
        </div>

        <div class="table-footer">
          <div class="table-footer-list">
            <div class="footer-selct">
              <select class="custom-select form-control" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="pageSizeChange();">
                <option [ngValue]="5">5 items</option>
                <option [ngValue]="10">10 items</option>
                <option [ngValue]="25">25 items</option>
                <option [ngValue]="50">50 items</option>
                <option [ngValue]="100">100 items</option>
                <option [ngValue]="100">500 items</option>
              </select>

              <pre><span class="float-md-left">Total Records: {{ countTotalRecords() }} / {{ totalRecords }}</span></pre>

            </div>
            <div class="footer-pagintion">
              <div class="page-item-txt"><span class="float-md-left">Page: {{ currentPage }} /
                  {{ dataPagination.pageCount }}</span></div>
              <ngb-pagination class="d-flex justify-content-center mt-2" #dataPagination [collectionSize]="totalRecords"
                [(page)]="currentPage" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                (pageChange)="getFormDetailsData('T')"></ngb-pagination>
            </div>
          </div>
          <div *ngIf="ischeckboxshow > 0 && formType==3" class="mt-5 px-3">
            <div class="Submitbtn-content">

              <button type="button" id="btnAction" [disabled]="enableChildActionButton()"
                class="btn btn-primary btn-raised mx-2" ngbTooltip="Perform dropdown selected Action"
                container="wrapper" placement="left" tooltipClass="tooltipBlue"
                (click)="onActionArrayList()">Submit</button>
            </div>

          </div>
          <div
            *ngIf="ischeckboxshow > 0 && dropdownListActionGroupSpdrp && dropdownListActionGroupSpdrp.length > 0 && (formType ==1 || formType ==2)"
            class="mt-5 px-3">
            <div class="Submitbtn-content">
              <!-- <button type="button" id="btnSendMessage"
                [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
                class="btn btn-primary btn-raised mx-2" (click)="sendMessageArrayList()">Send Message</button>
              <button type="button" id="btnSendMail"
                [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
                class="btn btn-primary btn-raised mx-2" (click)="sendEmailArrayList()">Send Mail</button> -->
              <button type="button" id="btnAction"
                [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
                class="btn btn-primary btn-raised mx-2" (click)="onActionArrayList()">Submit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card shadow mb-4 p-0" *ngIf="mobileView">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <div class="left_side_mob">
          <h6 class="m-0 font-weight-bold">Records </h6>
          <pre><span class="float-md-left">Total Records: {{ countTotalRecords() }} / {{ totalRecords }}</span></pre>
        </div>
        <div class="right-side_mob">
          <div *ngIf="isExcelDownload || isPdfDownload" class="col-xl-6 col-sm-12 col-md-6 text-right">
            <button class="btn btn-excel" *ngIf="isExcelDownload" ngbTooltip="Download Excel" container="wrapper"
              placement="left" tooltipClass="tooltipBlue" (click)="exportAsXLSX()"><i class="excel_icon"></i></button>
            <button class="btn btn-pdf ml-2" *ngIf="isPdfDownload" ngbTooltip="Download PDF" container="wrapper"
              placement="left" tooltipClass="tooltipBlue" (click)="exportAsPDF()"><i
                class="pdf_icon"></i></button>
          </div>
        </div>
      </div>
      <div class="card-header radio-check bg-white p-2">
        <ng-container *ngFor="let headerDetailData of tableCols">
          <ng-container
          *ngIf="headerDetailData['columnFilterMobileView'] == true && headerDetailData['columnHideMobileView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'">

        <div class="form-check form-check-inline listviewfilter">

            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="div_{{ConvertStringLowerCase(headerDetailData['columN_NAME'])}}"
              [value]="ConvertStringLowerCase(headerDetailData['columN_NAME'])" [(ngModel)]="listViewSelectedSearchCol">
            <label class="form-check-label"
              for="div_{{ConvertStringLowerCase(headerDetailData['columN_NAME'])}}">{{FirstLetterUpper(headerDetailData['columnAliasName'])}}</label>


        </div>
      </ng-container>
      </ng-container>
        <div class="input-group mt-1">
          <input class="form-control border-end-0 border" type="search" value="" id="example-search-input"
            placeholder="search" (input)="onSearchQueryInput($event)">
          <!-- <span class="input-group-append">
            <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5"
              type="button">

              <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
          </span> -->
        </div>
      </div>
      <!-- Card Body -->
      <div class="card-body p-1" infinite-scroll [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScroll()">
        <!-- <div class="row justify-content-md-center tableDiv">
                    <div class="col-12">


                    </div>
                </div> -->
                <ng-container *ngIf="DetailtableRowData.length > 0; else elseEmptyRow">

        <div class=" mb-2" *ngFor="let rowDetailData of DetailtableRowData; let listi=index;">
          <div class="card-body p-0 grey-bg">
            <ng-container>
              <div class="mobile-grid-head">
                <a (click)="OnViewFullDetail($event, rowDetailData['id'],'view',rowDetailData['toolTipRow'])">
                  <h6>{{rowDetailData['toolTipRow']}}</h6>
                </a>
                <ng-container *ngIf="isWorkflowImplemented == false;else elseWFButtonAdd">

                  <ng-container
                    *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0">
                    <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                      (click)="OnViewFullDetail($event, rowDetailData['id'],rowDetailData['rowEditCondition'],rowDetailData['toolTipRow'])"
                      ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i
                        class='fa fa-pencil'></i></a>

                    <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',listi, rowDetailData['toolTipRow'])"
                      ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='active opendetail'><i
                        class='fa fa-check'></i> </a>

                    <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                      ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                      placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',listi, rowDetailData['toolTipRow'])"
                      class='inactive opendetail'><i class='fa fa-times '></i></a>

                    <a *ngIf="printPermission != 0" href="javascript:void(0)"
                      (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                      ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left" class='edit opendetail'><i
                        class='fa fa-print'></i></a>

                    <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',listi, rowDetailData['toolTipRow'])"
                      ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left" class='delete opendetail'><i
                        class='fa fa-trash'></i></a>

                  </ng-container>
                </ng-container>
                <ng-template #elseWFButtonAdd>
                  <ng-container *ngIf="isWorkflowImplemented == true">
                    <ng-container
                      *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 || rowDetailData['workFlowImplemented'] == true">
                      <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                        (click)="OnViewFullDetail($event, rowDetailData['id'],rowDetailData['rowEditCondition'],rowDetailData['toolTipRow'])"
                        ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left" class='edit opendetail'><i
                          class='fa fa-pencil'></i></a>

                      <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                        (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',listi, rowDetailData['toolTipRow'])"
                        ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                        placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                        class='active opendetail'><i class='fa fa-check'></i> </a>

                      <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                        ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                        placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                        (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',listi, rowDetailData['toolTipRow'])"
                        class='inactive opendetail'><i class='fa fa-times '></i></a>

                      <a *ngIf="printPermission != 0" href="javascript:void(0)"
                        (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                        ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                        tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left" class='edit opendetail'><i
                          class='fa fa-print'></i></a>

                      <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                        (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',listi, rowDetailData['toolTipRow'])"
                        ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                        placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                        class='delete opendetail'><i class='fa fa-trash'></i></a>

                      <a *ngIf="ConvertStringLowerCase(rowDetailData['pendingLevel']) == 'pending' && rowDetailData['workFlowStep'] > 1"
                        href="javascript:void(0)"
                        (click)="OnDynamicGenericWorkFlow($event,rowDetailData['workFlowApprove'],rowDetailData['workFlowReject'],rowDetailData['workFlowReturn'],rowDetailData['workFlowForward'],rowDetailData['id'],rowDetailData['tableName'],rowDetailData['toolTipRow'])"
                        ngbTooltip="Workflow Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                        placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                        class='edit opendetail'><i class='fa fa-snowflake-o'></i></a>

                      <a href="javascript:void(0)"
                        (click)="onWorkFlowHistoryOpenDetail($event,rowDetailData['tableName'],rowDetailData['id'],rowDetailData['toolTipRow'])"
                        ngbTooltip="Workflow  Record History - {{rowDetailData['toolTipRow']}}" container="wrapper"
                        placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                        class='edit opendetail ml-2'><i class='fa fa-history'></i></a>

                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-12">
                <div class="card overflow-hidden">
                  <div class="card-content">
                    <div class="card-body cleartfix">
                      <div class="row new-boder">
                        <div class="col-12">
                          <div class="d-flex py-2  border-right pr-2 justify-content-between">
                            <span class="font-sm">S.No.</span>
                            <span class="fw-bold font-sm">{{(listi+1)}}</span>
                          </div>
                        </div>
                        <ng-container *ngFor="let headerDetailData of tableCols">


                          <ng-container
                            *ngIf="headerDetailData['columnHideMobileView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' &&  ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file'; else elsedetailrowCondition">
                            <div class="col-12">
                              <div class="d-flex py-2 justify-content-between">
                                <span
                                  class="font-sm">{{FirstLetterUpper(headerDetailData['columnAliasName'])}}</span>
                                <span class="fw-bold font-sm">{{rowDetailData[headerDetailData['columN_NAME']]}}</span>
                              </div>
                            </div>

                          </ng-container>
                          <ng-template #elsedetailrowCondition>
                            <ng-container
                                *ngIf="headerDetailData['columnHideView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  == 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file' && ConvertStringLowerCase(headerDetailData['controlType']) != 'childtable'; else elsedetailTableFileView">
                                <div class="col-12">
                                  <div class="d-flex py-2 justify-content-between">
                                    <span
                                      class="font-sm">{{FirstLetterUpper(headerDetailData['columnAliasName'])}}</span>
                                    <span class="fw-bold font-sm">
                                <div [innerHTML]="rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]"></div></span>
                              </div>
                            </div>
                            </ng-container>
                          </ng-template>
                          <ng-template #elsedetailTableFileView>
                            <ng-container
                              *ngIf="headerDetailData['columnHideMobileView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'  && ConvertStringLowerCase(headerDetailData['controlType']) == 'file'">
                              <div class="col-12">
                                <div class="d-flex justify-content-between">
                                  <span
                                    class="font-sm">{{FirstLetterUpper(headerDetailData['columnAliasName'])}}</span>
                                  <ng-container class="file-dowmload"
                                    *ngIf="headerDetailData['columnHideMobileView'] == true && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'rowcondition' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param1'&& ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'param2'">
                                    <!-- <div [attr.columnAliasName]="headerDetailData['columnAliasName']?.toLowerCase()"> -->
                                    <a href="javascript:void(0)" class='active'
                                      [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                                      (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                                      ngbTooltip="Download item - {{rowDetailData['toolTipRow']}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue">
                                      <i class='fa fa-download'></i></a>
                                    <a href="javascript:void(0)" class='active'
                                      [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                                      (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                                      ngbTooltip="Open {{headerDetailData['columN_NAME']}} item - {{rowDetailData['toolTipRow']}}"
                                      container="wrapper" ngbTooltip="Tooltip on left" placement="left"
                                      tooltipClass="tooltipBlue">
                                      <i class='fa fa-file-image-o'></i></a>
                                    <!-- </div> -->

                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </ng-template>

                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <ng-template #elseEmptyRow>
          <ng-container>No Data Found!</ng-container>
          </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="add-edit-modalbox" [ngClass]="toggleform ? 'active' : ''">
  <app-generic-add-edit (removeActive)="deactivate()"></app-generic-add-edit>
</div>
