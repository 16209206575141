import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-generic-about-page',
  templateUrl: './public-generic-about-page.component.html',
  styleUrls: ['./public-generic-about-page.component.css']
})
export class PublicGenericAboutPageComponent implements OnInit {
  aboutdata: any;

  constructor(private commonfunction: Commonfunction) {
    const allDatafile = this.commonfunction.loadJson("~/../assets/about.json");
    this.aboutdata = allDatafile[0];

    // console.log(this.aboutdata);
    
   }

  ngOnInit(): void {

    
  }

 
}
