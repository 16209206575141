import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericFormDropDownCustomFunctionServiceService } from '../generic-form-drop-down-custom-function-service.service';
// import { Commonfunction } from '../commonutility';
@Injectable({
  providedIn: 'root'
})
export class CustomActionService {

  constructor(private genericDropDownService: GenericFormDropDownCustomFunctionServiceService) { }
  dynamicMethods(method: string) {
    switch (method) {
        case 'IDryCleaner_CustomerOrderDetail_CalculateItemsAmount': return this.IDryCleaner_CustomerOrderDetail_CalculateItemsAmount.bind(this);
        case 'IDryCleaner_CustomerOrderDetail_CalculateTotalAmount': return this.IDryCleaner_CustomerOrderDetail_CalculateTotalAmount.bind(this);
        case 'IDryCleaner_CustomerOrder_CalculateTotalAmount': return this.IDryCleaner_CustomerOrder_CalculateTotalAmount.bind(this);
        case 'ISchool_StudentFeeDetail_CalculateTotalAmount': return this.ISchool_StudentFeeDetail_CalculateTotalAmount.bind(this);
        case 'ISchool_StudentEnquiry_CalculateAgeOn': return this.ISchool_StudentEnquiry_CalculateAgeOn.bind(this);
        case 'ISchool_StudentRegistrationAdmin_CalculateAgeOn': return this.ISchool_StudentRegistrationAdmin_CalculateAgeOn.bind(this);
        case 'ISchool_StudentEnquiryAdmin_CalculateAgeOn': return this.ISchool_StudentEnquiryAdmin_CalculateAgeOn.bind(this);
        case 'ISchool_StudentEnquiryAdmin_Address': return this.ISchool_StudentEnquiryAdmin_Address.bind(this);
        case 'ISchool_SessionClasswiseSeat_CalculateTotalSeats': return this.ISchool_SessionClasswiseSeat_CalculateTotalSeats.bind(this);
        case 'ISchool_SessionFeeDues_AmountSignCheck': return this.ISchool_SessionFeeDues_AmountSignCheck.bind(this);
        default: return  this.fakeFunction.bind(this);
    }
}
  IDryCleaner_CustomerOrderDetail_CalculateItemsAmount(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {
        masterProductForm.get(formAlias)?.value['EtxtName_itemAmount_OrderDetails'];
        let itemsubCategory = masterProductForm.get(formAlias)?.value['EDrpName_subCategory_OrderDetails'];
        let itemQuantity = masterProductForm.get(formAlias)?.value['EtxtName_quantity_OrderDetails'];
        let itemPrice = itemsubCategory[0].itemName.split("(")[1].split(")")[0].split(" ")[0];

        const newValue = JSON.parse('{"EtxtName_itemAmount_OrderDetails": "' + itemPrice * itemQuantity + '"}');

        masterProductForm.get(formAlias)?.patchValue(newValue);
        masterProductForm.get(formAlias)?.get('EtxtName_itemAmount_OrderDetails')?.markAsTouched();
        console.log(itemPrice);
        console.log(itemQuantity);
        console.log(newValue);
      }
      else {
        masterProductForm.get(formAlias)?.value['txtName_itemAmount_OrderDetails'];
        let itemsubCategory = masterProductForm.get(formAlias)?.value['DrpName_subCategory_OrderDetails'];
        let itemQuantity = masterProductForm.get(formAlias)?.value['txtName_quantity_OrderDetails'];
        let itemPrice = itemsubCategory[0].itemName.split("(")[1].split(")")[0].split(" ")[0];

        const newValue = JSON.parse('{"txtName_itemAmount_OrderDetails": "' + itemPrice * itemQuantity + '"}');

        masterProductForm.get(formAlias)?.patchValue(newValue);
        masterProductForm.get(formAlias)?.get('txtName_itemAmount_OrderDetails')?.markAsTouched();
        console.log(itemPrice);
        console.log(itemQuantity);
        console.log(newValue);
      }

    }
    else {
      masterProductForm.controls['txtName_itemAmount_OrderDetails']?.value;
      let itemsubCategory = masterProductForm.controls['DrpName_subCategory_OrderDetails']?.value;
      let itemQuantity = masterProductForm.controls['txtName_quantity_OrderDetails']?.value;
      let itemPrice = itemsubCategory[0].itemName.split("(")[1].split(")")[0].split(" ")[0];

      const newValue = JSON.parse('{"txtName_itemAmount_OrderDetails": "' + itemPrice * itemQuantity + '"}');

      masterProductForm.patchValue(newValue);
      masterProductForm.get('txtName_itemAmount_OrderDetails')?.markAsTouched();
      console.log(itemPrice);
      console.log(itemQuantity);
      console.log(newValue);
    }
  }
  }

  IDryCleaner_CustomerOrderDetail_CalculateTotalAmount(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {

        let formItemSum = childTableDataDynamicArrayList[formAlias]['itemAmount'] == undefined ? 0 : childTableDataDynamicArrayList[formAlias]['itemAmount'];
        let orderDiscountAmount = +masterProductForm.get(parentformAlias)?.value['EtxtName_discountAmount_Order'];
        let orderAdvancedAmount = +masterProductForm.get(parentformAlias)?.value['EtxtName_advancedAmount_Order'];
        let TotalAmount = +formItemSum - orderDiscountAmount - orderAdvancedAmount;

        const newValue = JSON.parse('{"EtxtName_totalAmount_Order": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('EtxtName_totalAmount_Order')?.markAsTouched();

        console.log(newValue);
      }
      else {

        let formItemSum = childTableDataDynamicArrayList[formAlias]['itemAmount'] == undefined ? 0 : childTableDataDynamicArrayList[formAlias]['itemAmount'];
        let orderDiscountAmount = +masterProductForm.get(parentformAlias)?.value['txtName_discountAmount_Order'];
        let orderAdvancedAmount = +masterProductForm.get(parentformAlias)?.value['txtName_advancedAmount_Order'];
        let TotalAmount = +formItemSum - orderDiscountAmount - orderAdvancedAmount;

        const newValue = JSON.parse('{"txtName_totalAmount_Order": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_totalAmount_Order')?.markAsTouched();

        console.log(newValue);

      }

    }
    else {

    }
  }
  }

  IDryCleaner_CustomerOrder_CalculateTotalAmount(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {

        let formItemSum = +masterProductForm.get(parentformAlias)?.value['EtxtName_totalAmount_Order'];
        let orderDiscountAmount = +masterProductForm.get(parentformAlias)?.value['EtxtName_discountAmount_Order'];
        let orderAdvancedAmount = +masterProductForm.get(parentformAlias)?.value['EtxtName_advancedAmount_Order'];
        if (formItemSum < 0)
          formItemSum = 0
        let TotalAmount = +formItemSum - orderDiscountAmount - orderAdvancedAmount;

        const newValue = JSON.parse('{"EtxtName_totalAmount_Order": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('EtxtName_totalAmount_Order')?.markAsTouched();

        console.log(newValue);
      }
      else {

        let formItemSum = +masterProductForm.get(parentformAlias)?.value['txtName_totalAmount_Order'];
        let orderDiscountAmount = +masterProductForm.get(parentformAlias)?.value['txtName_discountAmount_Order'];
        let orderAdvancedAmount = +masterProductForm.get(parentformAlias)?.value['txtName_advancedAmount_Order'];
        if (formItemSum < 0)
          formItemSum = 0
        let TotalAmount = +formItemSum - orderDiscountAmount - orderAdvancedAmount;

        const newValue = JSON.parse('{"txtName_totalAmount_Order": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_totalAmount_Order')?.markAsTouched();

        console.log(newValue);

      }

    }
    else {

    }
  }
  }
  ISchool_StudentFeeDetail_CalculateTotalAmount(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {

        let formItemSum = childTableDataDynamicArrayList['FeeDetails']['amount'] == undefined || childTableDataDynamicArrayList['FeeDetails']['amount'] == null? 0 : +childTableDataDynamicArrayList['FeeDetails']['amount'];
        let formItemCustomSum = childTableDataDynamicArrayList['FeeCustomDetails']['amount'] == undefined || childTableDataDynamicArrayList['FeeCustomDetails']['amount'] == null? 0 : +childTableDataDynamicArrayList['FeeCustomDetails']['amount'];
        let orderDiscountAmount = masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'];
        let orderAdvancedAmount = masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'] == null?0:+masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'];
        let TotalAmount = +formItemSum +formItemCustomSum - orderDiscountAmount + orderAdvancedAmount;

        const newValue = JSON.parse('{"txtName_feeAmount_StudentFee": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_feeAmount_StudentFee')?.markAsTouched();

        console.log(newValue);
      }
      else {

        let formItemSum = childTableDataDynamicArrayList['FeeDetails']['amount'] == undefined || childTableDataDynamicArrayList['FeeDetails']['amount'] == null? 0 : +childTableDataDynamicArrayList['FeeDetails']['amount'];
        let formItemCustomSum = childTableDataDynamicArrayList['FeeCustomDetails']['amount'] == undefined || childTableDataDynamicArrayList['FeeCustomDetails']['amount'] == null? 0 : +childTableDataDynamicArrayList['FeeCustomDetails']['amount'];

        let orderDiscountAmount = masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_studentDiscount_StudentFee'];
        let orderAdvancedAmount = masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'] == null?0:+masterProductForm.get(parentformAlias)?.value['txtName_previousBalance_StudentFee'];
        let TotalAmount = +formItemSum +formItemCustomSum - orderDiscountAmount + orderAdvancedAmount;

        const newValue = JSON.parse('{"txtName_feeAmount_StudentFee": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_feeAmount_StudentFee')?.markAsTouched();

        console.log(newValue);

      }

    }
    else {

    }
  }
  }

  ISchool_StudentEnquiry_CalculateAgeOn(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {
       
        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentDetail": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentDetail')?.markAsTouched();
        console.log(newValue);
      }
      else {

        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentDetail": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentDetail')?.markAsTouched();

      }

    }
    else {

    }
  }
  }

  ISchool_StudentEnquiryAdmin_CalculateAgeOn(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {
       if(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != "" && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != null && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != undefined){
        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentEnquire": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentEnquire')?.markAsTouched();
      
        console.log(newValue);
      }
      }
      else {
        if(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != "" && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != null && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire'] != undefined){
        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentEnquire']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentEnquire": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentEnquire')?.markAsTouched();
        }

      }

    }
    else {

    }
  }
  }

  ISchool_StudentEnquiryAdmin_Address(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    let filterValue:string="";
   
    let testValue = masterJson.filter((x:any)=> x["columN_NAME"] == "addressTypeId");
    let columnIndex = +masterJson.findIndex((x:any)=> x["columN_NAME"] == "addressTypeId");
    let dropDownValues = masterJson.filter((x:any)=> x["columN_NAME"] == "addressTypeId")[0]['drpDataSet'];
    let conttrolName = masterJson.filter((x:any)=> x["columN_NAME"] == "addressTypeId")[0]['controlName'];
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
  
       if(masterProductForm?.value[conttrolName] != "" && masterProductForm?.value[conttrolName] != null && masterProductForm?.value[conttrolName] != undefined){
        let SelectedAddress = masterProductForm?.value[conttrolName][0];
        if(+SelectedAddress['id'] == 4 || +SelectedAddress['id'] == 5){
       if(+SelectedAddress['id'] == 4){
        filterValue = dropDownValues.filter((x:any)=> x['id'] == 1)[0]['itemName'];
       }
       else{
        filterValue = dropDownValues.filter((x:any)=> x['id'] == 2)[0]['itemName'];
       }

       let filteredValue:[] = childTableResultJSon.filter((x:any)=>x['addressTypeId'] == filterValue);
       if(filteredValue.length >0){
        let resultSet = this.editChildTableRow("addressTypeId",masterProductForm?.value[conttrolName],columnIndex,filteredValue,masterJson,masterProductForm,formAlias);
        masterProductForm.patchValue(resultSet);
        masterProductForm?.markAsTouched();
        masterProductForm?.updateValueAndValidity();
        console.log(resultSet);
       }
        
      }
      }
      

    }
    else {

    }
  }

  ISchool_StudentRegistrationAdmin_CalculateAgeOn(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      if (isEditForm) {
       if(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != "" && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != null && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != undefined){
        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentDetail": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentDetail')?.markAsTouched();
      
        console.log(newValue);
      }
      }
      else {
        if(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != "" && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != null && masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail'] != undefined){
        let dobDate = new Date(masterProductForm.get(parentformAlias)?.value['txtName_dob_StudentDetail']);
        let matchDate = new Date("03-31-2023"); 
        let timeDiff = Math.abs(matchDate.getTime() - dobDate.getTime());
        let showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        const newValue = JSON.parse('{"txtName_ageOn_StudentDetail": "' + showAge + '"}');
        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_ageOn_StudentDetail')?.markAsTouched();
        }

      }

    }
    else {

    }
  }
  }
  
  ISchool_SessionClasswiseSeat_CalculateTotalSeats(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
    if (isMultiForm) {
      let formItemSum = childTableDataDynamicArrayList[formAlias]['seats'] == undefined || childTableDataDynamicArrayList[formAlias]['seats'] == null? 0 : +childTableDataDynamicArrayList[formAlias]['seats'];

      if (isEditForm) {

               let SeatSum = masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'];
        let filledSeats = masterProductForm.get(parentformAlias)?.value['txtName_filledSeats_SessionClassDetail'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_filledSeats_SessionClassDetail'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_filledSeats_SessionClassDetail'];
        let remainingSeat = masterProductForm.get(parentformAlias)?.value['txtName_remainingSeat_SessionClassDetail'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_remainingSeat_SessionClassDetail'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_remainingSeat_SessionClassDetail'];
        let TotalAmount = +formItemSum ;
        let remainingSeatSum = TotalAmount - filledSeats;

        const newValueTotal = JSON.parse('{"txtName_totalSeat_SessionClassDetail": "' + TotalAmount + '"}');
        const newValueRemainingTotal = JSON.parse('{"txtName_remainingSeat_SessionClassDetail": "' + remainingSeatSum + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValueTotal);
        masterProductForm.get(parentformAlias)?.get('txtName_totalSeat_SessionClassDetail')?.markAsTouched();
        masterProductForm.get(parentformAlias)?.patchValue(newValueRemainingTotal);
        masterProductForm.get(parentformAlias)?.get('txtName_remainingSeat_SessionClassDetail')?.markAsTouched();

        console.log(newValueTotal);
      }
      else {

        // let formItemSum = childTableDataDynamicArrayList[formAlias]['seats'] == undefined || childTableDataDynamicArrayList[formAlias]['seats'] == null? 0 : +childTableDataDynamicArrayList[formAlias]['seats'];
        let SeatSum = masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'] == undefined || masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'] == null ? 0 : +masterProductForm.get(parentformAlias)?.value['txtName_totalSeat_SessionClassDetail'];
        let TotalAmount = +formItemSum  ;

        const newValue = JSON.parse('{"txtName_totalSeat_SessionClassDetail": "' + TotalAmount + '"}');

        masterProductForm.get(parentformAlias)?.patchValue(newValue);
        masterProductForm.get(parentformAlias)?.get('txtName_totalSeat_SessionClassDetail')?.markAsTouched();

        console.log(newValue);

      }

    }
    else {

    }
  }
  }

  ISchool_SessionFeeDues_AmountSignCheck(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup|undefined>this.masterProductForm.get(frmName)).controls;
    if(masterProductForm != null && masterProductForm !=undefined){
      let procValue ='';
    if (isMultiForm) {
      
      if (isEditForm) {

        if(masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != "" && masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != null && masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != undefined && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != "" && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != null && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != undefined){
         let amount = +masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'];
         let chkSign = masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'][0]['itemExtra'];
         if(chkSign == "True" && amount > 0)
         {
          const newValue = JSON.parse('{"txtName_amount_FeeCustomDetails": "' + -1 * amount + '"}');

          masterProductForm.get(formAlias)?.patchValue(newValue);
          masterProductForm.get(formAlias)?.get('txtName_amount_FeeCustomDetails')?.markAsTouched();
         }
        
           console.log(masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails']);
      }
    }
      else {

        if(masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != "" && masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != null && masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'] != undefined && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != "" && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != null && masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'] != undefined){
          let amount = +masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails'];
          let chkSign = masterProductForm.get(formAlias)?.value['DrpName_feeTypeId_FeeCustomDetails'][0]['itemExtra'];
          if(chkSign == "True" && amount > 0)
          {
           const newValue = JSON.parse('{"txtName_amount_FeeCustomDetails": "' + -1 * amount + '"}');
 
           masterProductForm.get(formAlias)?.patchValue(newValue);
           masterProductForm.get(formAlias)?.get('txtName_amount_FeeCustomDetails')?.markAsTouched();
          }
         
            console.log(masterProductForm.get(formAlias)?.value['txtName_amount_FeeCustomDetails']);
       }

      }

    }
    else {

    }
  }
  }


  fakeFunction(masterProductForm: FormGroup, childTableDataDynamicArrayList: { [kName: string]: any }, formAlias: string, parentformAlias: string, isMultiForm: boolean, isEditForm: boolean, masterJson:any,childTableResultJSon:any) {
    
    // this.masterProductForm.get(frmName);
    // (<FormGroup>this.masterProductForm.get(frmName)).controls;
    
  }

  editChildTableRow(comtrolName:any, controlValue: any, columnIndex:number, childTableResultJSon:any, masterJsonForm:any,masterProductForm: FormGroup,formAliasName:string) {
    
    let that = this;
    let reactiveFormDataDefaultValue = {};
        let createdBy = childTableResultJSon[0]['rowCreatedBy'];
        let modifiedBy = childTableResultJSon[0]['rowModifiedBy'];
        let ChildFormColumns = childTableResultJSon[0]['processedColumnName'].replace('^' + createdBy + modifiedBy, '').split('^');
        let ChildFormColumnsValue = childTableResultJSon[0]['processedColumnValue'].split('^');
        
        let newValue: Array<{ controlName: string, value: any }> = [];
        masterJsonForm.forEach(function (value:any, index:number) {
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let ColumIndex = ChildFormColumns.findIndex((x:any) => x == value['columN_NAME']);
                value['previousControlValue'] = ChildFormColumnsValue[ColumIndex] == undefined ? null : ChildFormColumnsValue[0][ColumIndex].toString();
                let columnValue;
                let drpColumnValue: Array<{ id: string, itemName: string }> = [];
                if (value['controlType'].toString().toLowerCase() == 'dropdown') {
                    drpColumnValue = [{ id: ChildFormColumnsValue[ColumIndex].toString(), itemName: childTableResultJSon[0][value['columN_NAME']] }];
                    columnValue = value['columN_NAME'] == comtrolName ? controlValue: drpColumnValue;
                    value['drpDataSelected'] = columnValue;
                    if (columnValue != null && columnValue != undefined)
                      that.genericDropDownService.onItemSelect(columnValue, index, false, masterJsonForm, masterProductForm, 'home', false, false, formAliasName,childTableResultJSon);

                }
                else if (value['controlType'].toString().toLowerCase() == 'mdropdown') {
                    let mValue = ChildFormColumnsValue[ColumIndex].split(',');
                    mValue.forEach(function (mvalue:any, mindex:string) {
                        drpColumnValue.push({ id: mvalue.toString(), itemName: childTableResultJSon[0][value['columN_NAME']].split(',')[mindex] });
                    });
                    columnValue = value['columN_NAME'] == comtrolName ? controlValue: drpColumnValue;
                    value['drpDataSelected'] = columnValue;
                    if (columnValue != null && columnValue != undefined)
                    that.genericDropDownService.onItemSelect(columnValue, index, true, masterJsonForm, masterProductForm, 'home', false, false, formAliasName,childTableResultJSon);
  
                }
                else if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') {
                    columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' ? value['columN_NAME'] == comtrolName ? that.ConvertDateFormatEditChild(controlValue): that.ConvertDateFormatEditChild(ChildFormColumnsValue[ColumIndex]) : '';
  
                }
                else if (value['controlType'].toString().toLowerCase() == 'checkbox') {
                    columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' && ChildFormColumnsValue[ColumIndex] == '1' ? true : false;
                }
                else if (value['controlType'].toString().toLowerCase() == 'file') {
                    columnValue = ChildFormColumnsValue[ColumIndex];
                    value['controlValue'] = columnValue;
  
                }
                else
                    columnValue = ChildFormColumnsValue[ColumIndex];
  
                reactiveFormDataDefaultValue[value['controlName']] = columnValue;
                //  that.GenericFormGroup(childFormAliasName).get(value['controlName']).markAsTouched();
              }
           
        });
        
    return reactiveFormDataDefaultValue;
    
  }
  public ConvertDateFormatEditChild(value: string) {
    if (value !== null && value !== "") {
      let Cudate = value.split('_')[1].split('-');
      return new Date(Cudate[1] + '/' + Cudate[0] + '/' + Cudate[2]);
    }
    else {
      return value;
    }
  }

  

}
