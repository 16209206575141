import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpErrorResponse, HttpEventType, HttpSentEvent, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { UserService } from "../shared/user.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalAlertService } from "../shared/global-alert.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private globalAlertService: GlobalAlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') == "True")
            return next.handle(req.clone());
        if (sessionStorage.getItem('userToken') != null) {

            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + sessionStorage.getItem('userToken'))
            });
            return next.handle(clonedreq)
                .pipe(tap(
                    succ => { },
                    err => {
                        if (err.status === 401)
                            this.router.navigateByUrl('/login');
                        if (err.status === 409)
                            this.globalAlertService.AlertMsgRedirect("Alert!!", "Requested Page accessed inside alloweded Premises.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error","Dashboard");
                    }
                ));
        }
        else {
            this.router.navigateByUrl('/login');
            return next.handle(req.clone());
        }


    }
}