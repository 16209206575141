import { Component, OnInit } from '@angular/core';
import { Iaboutimgs } from '../../allUsedModule';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-page-model',
  templateUrl: './about-page-model.component.html',
  styleUrls: ['./about-page-model.component.css']
})
export class AboutPageModelComponent implements OnInit {
  aboutImg:Iaboutimgs ={} as Iaboutimgs;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.aboutImg);
    
  }

  onCloseConfirmModal(){
    this.activeModal.close()
  }
  OnUpdateAbotModal(){}

}
