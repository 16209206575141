import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '../../../node_modules/@angular/router';
//import 'rxjs/add/operator/map';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from './user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomActionService } from './customAction/custom-action.service';
import { Commonfunction } from './commonutility';

@Injectable({
  providedIn: 'root'
})
export class GenericFormDropDownServiceService {

  constructor(private spinner: NgxSpinnerService, private userService: UserService, private customActionService: CustomActionService,private router : Router, private commonfunction:Commonfunction) { }

  commonCustumFunction(param: string,SeltableIDForm: string) {
    let that = this;
    let result = { status: 200, message: "Ok" };
    this.userService.getCommonDataSet(SeltableIDForm.toString(), param).subscribe((data: any) => {
        eval(data.table[0]['msg']);
    },
        (err: HttpErrorResponse) => {
            this.spinner.hide();
            console.log(err.message);
            result.status = err.status;
              result.message = err.message;
        },
        () => {
            this.spinner.hide();
        });
        return result;
}

  textBoxChangeEvent(event: Event, formAliasName: string, customActionName: string,masterFormGroup: FormGroup, isMultiForm: boolean=false, isEditForm: boolean=false, masterJson:any,childTableResultJSon:any) {
    
    if (customActionName != undefined && customActionName.trim() != "") {     
       //newLocal:String = eval("'" + customActionName );
            this.customActionService.dynamicMethods(customActionName)(masterFormGroup, {},formAliasName, "", isMultiForm,isEditForm, masterJson, childTableResultJSon);      
  
    }
  }
    drpRecursiveFunctionMasterForm(Index: number, masterJsonData: any, masterFormGroup: FormGroup , isEditFlag: boolean = false) {
      let that = this;
      if (Index > -1) {
        let selectedData = masterJsonData[Index];
        masterJsonData[Index]['drpDataSet'] = [];
        masterJsonData[Index]['drpDataSelected'] = null;
        if (isEditFlag && masterFormGroup!=null){
        let controlName:string = selectedData?.controlName.toString();
          masterFormGroup.get(controlName)?.markAsTouched();
        }
        if(selectedData.drpEventinformation != null && selectedData.drpEventinformation != undefined){
        selectedData.drpEventinformation.forEach(function (valueRes:any, indexRes: number) {
          if (valueRes['child_column_name'] != "" && valueRes['child_column_name'] != null) {
            let nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == valueRes['child_column_name'].toLowerCase());
            if (nextIndex > -1)
              that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
          }
        });
      }
      }
      return;
    }
  
    onItemSelect(item: any, Index: number, multiselect: boolean, masterJsonData: any, masterFormGroup: FormGroup, SeltableIDForm: string, isReportForm: boolean = true, isEditFlag: boolean = false,formAliasName: string = "",childTableResultJSon:any) {
      let that = this;
      let selectedData = masterJsonData[Index];
      let selectedValues: string = '';
      let result = { status: 200, message: "Ok" };
  
      if (multiselect) {
        selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
      }
      else {
        selectedValues = selectedData.drpDataSelected[0]['id'].toString();
      }
      console.log(selectedData)
      selectedData.drpEventinformation.forEach(function (value:any, index:any) {
        if (value['child_column_name'] != "" && value['child_column_name'] != null) {
          let nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
          if (nextIndex > -1) {
            that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
            let SPName = isReportForm ? "uspAppGenericReportDropdownValuesOnchange" : "uspAppGenericDropdownValuesOnchange";
            let SpParams = SPName + "|tableName=" + value['parent_table_name'] + "|basetableName=" + value['child_table_name'] + "|ValueColumn=" + value['onchangeColumnNamechild'] + "|filterValueColumn=" + value['onchangeColumnName'] + "|filtervalue=" + selectedValues + "|valueFillType=" + value['valueFill'] + "|childTableName=" + value['dropDownChildtable'] + "|queryId=" + value['queryId']
            that.userService.getCommonDataSet(SeltableIDForm,SpParams).subscribe((data:any) => {
  
              if (Object.keys(data).length == 1) {
                masterJsonData[nextIndex]['drpDataSet'] = data['table'];
              }
              else if (Object.keys(data).length == 2) {
                data['table'].forEach(function (valueData:any, indexData:number) {
  
                  masterJsonData[nextIndex].drpEventinformation[index] = valueData;
                });
  
                masterJsonData[nextIndex]['drpDataSet'] = data['table1'];
              }
  
            },
              (err: HttpErrorResponse) => {
                that.spinner.hide();
                console.log(err.message);
                result.status = err.status;
                result.message = err.message;
              });
          }
        }
      });
  
      if(!isReportForm && result.status == 200){
        // custum Server side action start from here on basis of DB expressionJson
        if (selectedData['expressionJson'] != "" && selectedData['expressionJson'] != "null" && selectedData['expressionJson'] != null) {
          let param = '';
          let expressionJson = JSON.parse(masterJsonData[Index]['expressionJson']);
          
          let singleForm = expressionJson['singleForm'];
          let procName = singleForm['procName'];
          let procParams = singleForm['procParams'];
          let procParamControls = singleForm['procParamControls'];
          let callingFrom = singleForm['callingFrom'];
          let procParamfinal = '';
          if (procParams.indexOf("%") > -1) {
              let paramArray = procParams.split('%');
              let paramControlArrayNew = procParamControls.split('%');
              paramArray.forEach(function (valueArray:any, index:number) {
                  let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == paramControlArrayNew[index]);
                  let selectedColumnValue: string = '';
                  if (selectedColumn[0]['controlType'] == 'mdropdown') {
                      selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
                  }
                  else
                      selectedColumnValue = masterFormGroup?.value[paramControlArrayNew[index]][0]['id'].toString();
  
  
                  procParamfinal = selectedColumnValue == '' ? procParamfinal + '' : procParamfinal + '|' + valueArray + '=' + selectedColumnValue;
              });
  
          }
          else {
              let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == procParamControls);
  
              let selectedColumnValue: string = '';
              if (selectedColumn[0]['controlType'] == 'mdropdown') {
                  selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
              }
              else
                  selectedColumnValue = masterFormGroup?.value[procParamControls][0]['id'].toString();
  
  
              procParamfinal = selectedColumnValue == '' ? '' : '|' + procParams + '=' + selectedColumnValue;
          }
  
          param = procName + procParamfinal + '|callingFrom=' + callingFrom + 'Se';
         result = this.commonCustumFunction(param,SeltableIDForm);
      }
      // custum Server side action end from here on basis of DB expressionJson
      // custum Client side action start from here on basis of  customEvent
      if (selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
          this.textBoxChangeEvent(item, formAliasName, selectedData['customEvent'], masterFormGroup,false,isEditFlag, masterJsonData,childTableResultJSon);
      }
      // custum Client side action end from here on basis of  customEvent
      }
      return result;
    }
    OnItemDeSelect(item: any, Index: number, multiselect: boolean, masterJsonData: any, masterFormGroup: FormGroup , SeltableIDForm: string, isReportForm: boolean = true, isEditFlag: boolean = false,formAliasName: string = "",childTableResultJSon:any) {
      let that = this;
      let selectedData = masterJsonData[Index];
      let selectedValues: string = '';
      let nextIndex: number = 0;
      let result = { status: 200, message: "Ok" };
      if (selectedData.drpDataSelected.length > 0) {
      if(multiselect)
      {
          selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
      }
      else{
          selectedValues = selectedData.drpDataSelected[0]['id'].toString();
      }
  }
  
      console.log(selectedData);
      selectedData.drpEventinformation.forEach(function (value:any, index:number) {
      if (value['child_column_name'] != "" && value['child_column_name'] != null && !multiselect) {
          nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
          that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
      }
      else if (value['child_column_name'] != "" && value['child_column_name'] != null && multiselect) {
          nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
         if(selectedData.drpDataSelected.length > 0){
          if (nextIndex > -1) {
            that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
            let SPName = isReportForm ? "uspAppGenericReportDropdownValuesOnchange" : "uspAppGenericDropdownValuesOnchange";
            let SpParams = SPName + "|tableName=" + value['parent_table_name'] + "|basetableName=" + value['child_table_name'] + "|ValueColumn=" + value['onchangeColumnNamechild'] + "|filterValueColumn=" + value['onchangeColumnName'] + "|filtervalue=" + selectedValues + "|valueFillType=" + value['valueFill'] + "|childTableName=" + value['dropDownChildtable'] + "|queryId=" + value['queryId']
            that.userService.getCommonDataSet(SeltableIDForm,SpParams).subscribe((data:any) => {
  
                  if (Object.keys(data).length == 1) {
  
                      masterJsonData[nextIndex]['drpDataSet'] = data['table'];
                  }
                  else if (Object.keys(data).length == 2) {
  
                    data['table'].forEach(function (valueData:any, indexData:number) {
  
                      masterJsonData[nextIndex].drpEventinformation[index] = valueData;
                    });
                      masterJsonData[nextIndex]['drpDataSet'] = data['table1'];
                  }
  
              },
                  (err: HttpErrorResponse) => {
                      that.spinner.hide();
                      console.log(err.message);
                      result.status = err.status;
                      result.message = err.message;
                  });
          } 
  
         }
         else{
          that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
         }
          
          
      }
    });
    if(!isReportForm && result.status == 200){
      // custum Server side action start from here on basis of DB expressionJson
      if (selectedData['expressionJson'] != "" && selectedData['expressionJson'] != "null" && selectedData['expressionJson'] != null) {
        let param = '';
        let expressionJson = JSON.parse(masterJsonData[Index]['expressionJson']);
        
        let singleForm = expressionJson['singleForm'];
        let procName = singleForm['procName'];
        let procParams = singleForm['procParams'];
        let procParamControls = singleForm['procParamControls'];
        let callingFrom = singleForm['callingFrom'];
        let procParamfinal = '';
        if (procParams.indexOf("%") > -1) {
            let paramArray = procParams.split('%');
            let paramControlArrayNew = procParamControls.split('%');
            paramArray.forEach(function (valueArray:any, index:number) {
                let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == paramControlArrayNew[index]);
                let selectedColumnValue: string = '';
                if (selectedColumn[0]['controlType'] == 'mdropdown') {
                    selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
                }
                else
                    selectedColumnValue = masterFormGroup?.value[paramControlArrayNew[index]][0]['id'].toString();
  
  
                procParamfinal = selectedColumnValue == '' ? procParamfinal + '' : procParamfinal + '|' + valueArray + '=' + selectedColumnValue;
            });
  
        }
        else {
            let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == procParamControls);
  
            let selectedColumnValue: string = '';
            if (selectedColumn[0]['controlType'] == 'mdropdown') {
                selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
            }
            else
                selectedColumnValue = masterFormGroup?.value[procParamControls][0]['id'].toString();
  
  
            procParamfinal = selectedColumnValue == '' ? '' : '|' + procParams + '=' + selectedColumnValue;
        }
  
        param = procName + procParamfinal + '|callingFrom=' + callingFrom + 'Se';
       result = this.commonCustumFunction(param,SeltableIDForm);
    }
    // custum Server side action end from here on basis of DB expressionJson
    // custum Client side action start from here on basis of  customEvent
    if (selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
        this.textBoxChangeEvent(item, formAliasName, selectedData['customEvent'], masterFormGroup,false,isEditFlag, masterJsonData,childTableResultJSon);
    }
    // custum Client side action end from here on basis of  customEvent
    }
    return result;
  }
  
  onItemSelectAll(item: any, Index: number, multiselect: boolean, masterJsonData: any, masterFormGroup: FormGroup, SeltableIDForm: string, isReportForm: boolean = true, isEditFlag: boolean = false,formAliasName: string = "",childTableResultJSon:any) {
  let that = this;
    let selectedData = masterJsonData[Index];
    let selectedValues: string = '';
    let result = { status: 200, message: "Ok" };
    if (multiselect) {
        selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
    }
    else {
        selectedValues = selectedData.drpDataSelected[0]['id']?.toString();
    }
    console.log(selectedData)
    selectedData.drpEventinformation.forEach(function (value:any, index:number) {
    if (value['child_column_name'] != "" && value['child_column_name'] != null) {
        let nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
        if (nextIndex > -1) {
          that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
          let SPName = isReportForm ? "uspAppGenericReportDropdownValuesOnchange" : "uspAppGenericDropdownValuesOnchange";
          let SpParams = SPName + "|tableName=" + value['parent_table_name'] + "|basetableName=" + value['child_table_name'] + "|ValueColumn=" + value['onchangeColumnNamechild'] + "|filterValueColumn=" + value['onchangeColumnName'] + "|filtervalue=" + selectedValues + "|valueFillType=" + value['valueFill'] + "|childTableName=" + value['dropDownChildtable'] + "|queryId=" + value['queryId']
        
          that.userService.getCommonDataSet(SeltableIDForm,SpParams).subscribe((data:any) => {
                if (Object.keys(data).length == 1) {
  
                    masterJsonData[nextIndex]['drpDataSet'] = data['table'];
                }
                else if (Object.keys(data).length == 2) {
  
                  data['table'].forEach(function (valueData:any, indexData:number) {
  
                    masterJsonData[nextIndex].drpEventinformation[index] = valueData;
                  });                 
                    masterJsonData[nextIndex]['drpDataSet'] = data['table1'];
                }
  
            },
                (err: HttpErrorResponse) => {
                    that.spinner.hide();
  
                    console.log(err.message);
                    result.status = err.status;
                result.message = err.message;
                    
                });
        }
    }
  });
  if(!isReportForm && result.status == 200){
    // custum Server side action start from here on basis of DB expressionJson
    if (selectedData['expressionJson'] != "" && selectedData['expressionJson'] != "null" && selectedData['expressionJson'] != null) {
      let param = '';
      let expressionJson = JSON.parse(masterJsonData[Index]['expressionJson']);
      
      let singleForm = expressionJson['singleForm'];
      let procName = singleForm['procName'];
      let procParams = singleForm['procParams'];
      let procParamControls = singleForm['procParamControls'];
      let callingFrom = singleForm['callingFrom'];
      let procParamfinal = '';
      if (procParams.indexOf("%") > -1) {
          let paramArray = procParams.split('%');
          let paramControlArrayNew = procParamControls.split('%');
          paramArray.forEach(function (valueArray:any, index:number) {
              let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == paramControlArrayNew[index]);
              let selectedColumnValue: string = '';
              if (selectedColumn[0]['controlType'] == 'mdropdown') {
                  selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
              }
              else
                  selectedColumnValue = masterFormGroup?.value[paramControlArrayNew[index]][0]['id'].toString();
  
  
              procParamfinal = selectedColumnValue == '' ? procParamfinal + '' : procParamfinal + '|' + valueArray + '=' + selectedColumnValue;
          });
  
      }
      else {
          let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == procParamControls);
  
          let selectedColumnValue: string = '';
          if (selectedColumn[0]['controlType'] == 'mdropdown') {
              selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
          }
          else
              selectedColumnValue = masterFormGroup?.value[procParamControls][0]['id'].toString();
  
  
          procParamfinal = selectedColumnValue == '' ? '' : '|' + procParams + '=' + selectedColumnValue;
      }
  
      param = procName + procParamfinal + '|callingFrom=' + callingFrom + 'Se';
     result = this.commonCustumFunction(param,SeltableIDForm);
  }
  // custum Server side action end from here on basis of DB expressionJson
  // custum Client side action start from here on basis of  customEvent
  if (selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
      this.textBoxChangeEvent(item, formAliasName, selectedData['customEvent'], masterFormGroup,false,isEditFlag, masterJsonData,childTableResultJSon);
  }
  // custum Client side action end from here on basis of  customEvent
  }
  return result;
  }
  OnItemDeSelectAll(item: any, Index: number, multiselect: boolean, masterJsonData: any, masterFormGroup: FormGroup, SeltableIDForm: string, isReportForm: boolean = true, isEditFlag: boolean = false,formAliasName: string = "",childTableResultJSon:any) {
    let that = this;
    let selectedData = masterJsonData[Index];
    let selectedValues: string = '';
    let nextIndex: number = 0;
    let result = { status: 200, message: "Ok" };
    if (multiselect) {
        selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
    }
    else {
        selectedValues = selectedData.drpDataSelected[0]['id']?.toString();
    }
    console.log(selectedData);
    selectedData.drpEventinformation.forEach(function (value:any, index:number) {
    if (value['child_column_name'] != "" && value['child_column_name'] != null && !multiselect) {
        nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
        that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
    }
    else if (value['child_column_name'] != "" && value['child_column_name'] != null && multiselect) {
        nextIndex = masterJsonData.findIndex((d:any) => d.columN_NAME.toLowerCase() == value['child_column_name'].toLowerCase());
        if (selectedData.drpDataSelected.length > 0) {
            if (nextIndex > -1) {
              that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
              let SPName = isReportForm ? "uspAppGenericReportDropdownValuesOnchange" : "uspAppGenericDropdownValuesOnchange";
              let SpParams = SPName + "|tableName=" + value['parent_table_name'] + "|basetableName=" + value['child_table_name'] + "|ValueColumn=" + value['onchangeColumnNamechild'] + "|filterValueColumn=" + value['onchangeColumnName'] + "|filtervalue=" + selectedValues + "|valueFillType=" + value['valueFill'] + "|childTableName=" + value['dropDownChildtable'] + "|queryId=" + value['queryId']
              //that.userService.GetDataSetResult(SpParams, SeltableIDForm).subscribe((data) => {
                that.userService.getCommonDataSet(SeltableIDForm,SpParams).subscribe((data:any) => {
                    if (Object.keys(data).length == 1) {
  
                        masterJsonData[nextIndex]['drpDataSet'] = data['table'];
                    }
                    else if (Object.keys(data).length == 2) {
  
                      data['table'].forEach(function (valueData:any, indexData:number) {
  
                        masterJsonData[nextIndex].drpEventinformation[index] = valueData;
                      });
                        masterJsonData[nextIndex]['drpDataSet'] = data['table1'];
                    }
  
                },
                    (err: HttpErrorResponse) => {
                        that.spinner.hide();
  
                        console.log(err.message);
                        result.status = err.status;
                result.message = err.message;                      
                    });
            }
  
        }
        else {
          that.drpRecursiveFunctionMasterForm(nextIndex, masterJsonData, masterFormGroup,isEditFlag);
        }
  
  
    }
  });
  if(!isReportForm && result.status == 200){
    // custum Server side action start from here on basis of DB expressionJson
    if (selectedData['expressionJson'] != "" && selectedData['expressionJson'] != "null" && selectedData['expressionJson'] != null) {
      let param = '';
      let expressionJson = JSON.parse(masterJsonData[Index]['expressionJson']);
      
      let singleForm = expressionJson['singleForm'];
      let procName = singleForm['procName'];
      let procParams = singleForm['procParams'];
      let procParamControls = singleForm['procParamControls'];
      let callingFrom = singleForm['callingFrom'];
      let procParamfinal = '';
      if (procParams.indexOf("%") > -1) {
          let paramArray = procParams.split('%');
          let paramControlArrayNew = procParamControls.split('%');
          paramArray.forEach(function (valueArray:any, index:number) {
              let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == paramControlArrayNew[index]);
              let selectedColumnValue: string = '';
              if (selectedColumn[0]['controlType'] == 'mdropdown') {
                  selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
              }
              else
                  selectedColumnValue = masterFormGroup?.value[paramControlArrayNew[index]][0]['id'].toString();
  
  
              procParamfinal = selectedColumnValue == '' ? procParamfinal + '' : procParamfinal + '|' + valueArray + '=' + selectedColumnValue;
          });
  
      }
      else {
          let selectedColumn = masterJsonData.filter((x:any) => x['controlName'] == procParamControls);
  
          let selectedColumnValue: string = '';
          if (selectedColumn[0]['controlType'] == 'mdropdown') {
              selectedColumnValue = Array.prototype.map.call(selectedColumn[0]['drpDataSelected'], s => s['id']).toString(); //that.masterProductForm.value[paramControlArrayNew[index]][0]['id'].toString()
  
          }
          else
              selectedColumnValue = masterFormGroup?.value[procParamControls][0]['id'].toString();
  
  
          procParamfinal = selectedColumnValue == '' ? '' : '|' + procParams + '=' + selectedColumnValue;
      }
  
      param = procName + procParamfinal + '|callingFrom=' + callingFrom + 'Se';
     result = this.commonCustumFunction(param,SeltableIDForm);
  }
  // custum Server side action end from here on basis of DB expressionJson
  // custum Client side action start from here on basis of  customEvent
  if (selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
      this.textBoxChangeEvent(item, formAliasName, selectedData['customEvent'], masterFormGroup,false,isEditFlag, masterJsonData,childTableResultJSon);
  }
  // custum Client side action end from here on basis of  customEvent
  }
  return result;
  }
  
  Logout() {
    this.userService.userAuthenticationLogOut( this.commonfunction.getUserTokenValuestring(),this.commonfunction.getUserIdValuestring()).subscribe((datacom : any)=>{
      sessionStorage.removeItem('userToken');
      this.router.navigate(['/login']);    
      },
      (err : HttpErrorResponse)=>{
       console.log(err.message);
      });  
    
  }

}
