
<div class="wrapper">
  <div id="sidebar"  [ngClass]="userService.ststusNav ? 'active' : ''">
    <app-sidelogin></app-sidelogin>
  </div>
  <div id="content"  [ngClass]="userService.ststusNav ? 'active' : ''">
<app-header></app-header>
<br>
      <br>
      <br>
    <!-- <app-bread-cum></app-bread-cum> -->

      <div class="container">
        <div class="row">
        <div class="col-md-12">

 <!-- Nav tabs -->
 <div class="card" style="background-image: linear-gradient(to bottom, #c5dada, #b3caca, #a0baba, #8faaaa, #7d9b9b);">
  
<router-outlet></router-outlet>

</div>
</div>

</div>
</div>
<app-footer></app-footer>
</div>  
</div> 