<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <!-- Tab for Add New Detail -->
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Add New Details</a>
        <ng-template ngbNavContent>
            <div class="row" [formGroup]="AddNewFrom">
                <div class="col-md-4">
                    <div class="ImageShowBox">
                        <label class="ImageShow">
                            <div class="img_box">
                                <img src="" alt="Image Preview">
                            </div>
                            <div class="hoverImg">
                                <div class="hover_content">
                                    <img style="width: 30px; display: block;" class="hoverImgIcon"
                                        src="assets\img\Mask group1.png" alt="Upload Icon">
                                    <span class="text-light">Upload</span>
                                </div>
                            </div>
                            <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                        </label>
                    </div>
                </div>

                <div class="col-md-8">
                    <select name="" id="" formControlName="tabs"  class="form-control">
                        <option value="Select" >Select</option>
                        <option value="Event">Event</option>
                        <option value="Highlights">Highlights</option>
                    </select>
                </div>

                <div class="Submitbtn-content mt-3  ">
                    <!-- <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button> -->
                    <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickAddNew()">Update</button>
                </div>
            </div>
        </ng-template>
    </li>    

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">View Details</a>
        <ng-template ngbNavContent>
            <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="Gallerydata" (customActionDetail)="onClickEditForm($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>


</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>