import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-experience-ad',
  templateUrl: './experience-ad.component.html',
  styleUrls: ['./experience-ad.component.css']
})
export class ExperienceAdComponent implements OnInit {
  experiencedata: any;

  constructor(public commonfunction:Commonfunction) {
    const alldatafile = this.commonfunction.loadJson("~/../assets/experience-custm.json");
    
    this.experiencedata = alldatafile;
    console.log(this.experiencedata);
  }

  ngOnInit(): void {
  }

}
