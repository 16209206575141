import { Component, HostListener,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { NavigationEnd, Router } from '../../node_modules/@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from './shared/user.service';
import { HttpErrorResponse } from '../../node_modules/@angular/common/http';
import { setTheme } from 'ngx-bootstrap/utils';
import { DataTablesModule } from 'angular-datatables';
import {GenericPrintService} from './shared/generic-print-service';
import { IpDeatilsService } from './shared/ip-deatils.service';
import * as AOS from 'aos';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements AfterContentChecked {
  public header = [
    'name','email','website'
];
  public data = [
    {name: 'therichpost', email: 'therichpost@gmail.com', website:'therichpost.com'},
    {name: 'therichpost', email: 'therichpost@gmail.com', website:'therichpost.com'},
    {name: 'therichpost', email: 'therichpost@gmail.com', website:'therichpost.com'},
    {name: 'therichpost', email: 'therichpost@gmail.com', website:'therichpost.com'},
];
typeSelected:string = "ball-clip-rotate-multiple";
dtOptions: any;
  constructor(
    private userService : UserService,
    private router : Router,
    private changeDetectorRef : ChangeDetectorRef,
    public genericPrintService: GenericPrintService){ 
  
  }
 
  ngAfterContentChecked() : void {
    this.changeDetectorRef.detectChanges();
}
    
ngOnInit(): void {

  AOS.init({
    duration: 1200, 
    once: true
  });


  this.router.events.subscribe((evt:any) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
}

ngAfterViewInit(): void {
  AOS.refresh();  
}



}
