import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewImageService } from '../preview-image/preview-image.service';
import { GlobalAlertService } from '../global-alert.service';
import { UserService } from '../user.service';
import { Commonfunction } from '../commonutility';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { Customvalidation } from "../../shared/Validation";
import { FormGroup, FormControl, ValidatorFn, AsyncValidatorFn, FormBuilder } from '@angular/forms';
import { GenericFormDropDownServiceService } from "../generic-form-drop-down-service.service";
import { custumValidation } from '../../shared/custum-validation';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { asyncCustumValidation } from '../../shared/async-custum-validation';
import { async } from '../../../../node_modules/@angular/core/testing';
import { TableColumnName } from "../../shared/user.model";
import 'jquery';
declare var jQuery: any;
// import * as moment from 'moment';
import { Subject } from 'rxjs';
import { lstObjectParam, ObjectParam } from '../object-param.model';
import { GenericChildActionModalServiceService} from './generic-child-action-modal-service.service';
import { IgenericChildActionOutput } from '../model/genericTableListViewModel';


@Component({
  selector: 'app-generic-child-action-modal',
  templateUrl: './generic-child-action-modal.component.html',
  styleUrls: ['./generic-child-action-modal.component.css']
})
export class GenericChildActionModalComponent implements OnInit {
    childAction: IgenericChildActionOutput={} as IgenericChildActionOutput;
  masterChildActionJsonData: any[]=[];
  masterChildActionProductForm!: FormGroup;
    isRenderEdit: boolean = false;
    @Input() DialogTitle: string |null |undefined;
 
    @Input() btnOkText: string |null |undefined;
    @Input() btnCancelText: string |null |undefined;
    @Input() AlertDialogType: string |null |undefined;
    @Input() AlertDialogClass: string |null |undefined;
   
    @Input() ViewDetailChildtableHeaderData = [];
    @Input() ViewDetailChildtableRowData = [];
    @Input() formName: string = "";
    @Input() selectedActionName: string = "";
    bsConfig:any;
    callfor: string="";
      AlertMessage: any;
      AlertTitle: any;
      AlertClass: any = "NP_emoji-success-bg";
      AlertType: any = " NP_popup_success";
      AlertAnimationType: any = "smile";
      minDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() - year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    maxDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() + year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    childActionParam: string="";
  constructor(private activeModal: NgbActiveModal,private previewImageService: PreviewImageService, public asyncCustomvalidation: asyncCustumValidation,
    private userService: UserService, private commonfunction: Commonfunction, private spinner: NgxSpinnerService,  private fb: FormBuilder, private genChildActionService:GenericChildActionModalServiceService,
    private globalAlertService: GlobalAlertService, public Customvalidation: Customvalidation, private genericDropDown:GenericFormDropDownServiceService) { 
      this.bsConfig = {
        dateInputFormat: 'DD/MM/YYYY',
        isAnimated: true,
        adaptivePosition: true,
        containerClass: 'theme-blue',
        minDate: this.minDate(50),
        maxDate: this.maxDate(50)
    };
    }

  ngOnInit(): void {
this.OnCreateChildAction();
  }
  public onCloseAlertModal() {
    this.activeModal.close(false);
  }
  public onCloseConfirmModal() {
    this.activeModal.close(false);
  }

  public accept() {
    
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
  calanderClick(inputControl:any) {
    $('#' + inputControl).click();
}
  onItemSelectEdit(item: any, Index: number, multiselect: boolean) {

    let result = this.genericDropDown.onItemSelect(item, Index, multiselect,this.masterChildActionJsonData, this.masterChildActionProductForm, this.formName,false,true,"","");
    if (result.status != 200) {
        if (result.status == 401) {

            this.AlertTitle = "Error!!";
            this.AlertMessage = "You are not authorized!";
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        else if (result.status == 403) {
            this.Customvalidation.loginroute(result.status);
        }
        else {

            this.AlertTitle = "Error!!";
            this.AlertMessage = result.message.toString();
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    }
}

OnItemDeSelectEdit(item: any, Index: number, multiselect: boolean) {
    let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect,this.masterChildActionJsonData, this.masterChildActionProductForm, this.formName,false,true,"","");
    if (result.status != 200) {
        if (result.status == 401) {

            this.AlertTitle = "Error!!";
            this.AlertMessage = "You are not authorized!";
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        else if (result.status == 403) {
            this.Customvalidation.loginroute(result.status);
        }
        else {

            this.AlertTitle = "Error!!";
            this.AlertMessage = result.message.toString();
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    }

}

onItemSelectEditAll(item: any, Index: number, multiselect: boolean) {

    let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect,this.masterChildActionJsonData, this.masterChildActionProductForm, this.formName,false,true,"","");
    if (result.status != 200) {
        if (result.status == 401) {

            this.AlertTitle = "Error!!";
            this.AlertMessage = "You are not authorized!";
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        else if (result.status == 403) {
            this.Customvalidation.loginroute(result.status);
        }
        else {

            this.AlertTitle = "Error!!";
            this.AlertMessage = result.message.toString();
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    }
}

OnItemDeSelectEditAll(item: any, Index: number, multiselect: boolean) {
    let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterChildActionJsonData, this.masterChildActionProductForm, this.formName,false,true,"","");
    if (result.status != 200) {
        if (result.status == 401) {

            this.AlertTitle = "Error!!";
            this.AlertMessage = "You are not authorized!";
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        else if (result.status == 403) {
            this.Customvalidation.loginroute(result.status);
        }
        else {

            this.AlertTitle = "Error!!";
            this.AlertMessage = result.message.toString();
            this.AlertClass = "NP_emoji-fail-bg";
            this.AlertAnimationType = "sad";
            this.AlertType = "NP_popup_fail";
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    }

}

OnSubmitModal(){

}
OnCreateChildAction() {

  this.spinner.show();
  var trdata = '';
  const that = this;
  // this.editModelCallfrom = callfrom;
  // if (callfrom == "update")
  //     this.editModelHeading = "Update Record";
  // else 
  //this.editModelHeading = "Provide " + this.selectedItemsActionGroupSpdrp[0].itemName.toString() + " Values";
  this.userService.getGenericReportFormPage(this.selectedActionName, this.formName, 'update').subscribe((data: any) => {

      let reactiveFormData = {};
      let chkAsyncGenericDbCheck: boolean = false;
      this.masterChildActionJsonData = data['formData'];
      if (this.masterChildActionJsonData != null) {
          this.masterChildActionJsonData.forEach(function (valueEdit, index) {
           
              let ArrayData: ValidatorFn[] = [];
              let checkExsistAsync: AsyncValidatorFn[] = [];
              if (valueEdit["identityColumn"].toLowerCase() != "yes" && valueEdit["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                  let requiredMsg = '';
                  let sizeMsg = '';
                  let customMsg = '';
                  if (valueEdit["customValidationMsg"] != "" && valueEdit["customValidationMsg"] != null) {
                      let customValidationMsg = JSON.parse(valueEdit["customValidationMsg"]);
                      requiredMsg = customValidationMsg['requiredMsg'];
                      sizeMsg = customValidationMsg['sizeMsg'];
                      customMsg = customValidationMsg['customMsg'];
                  }
                  if (valueEdit['iS_NULLABLE'].toLowerCase() == "no") {
                      ArrayData.push(custumValidation['validateRequired'](requiredMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                  }
                  if ((valueEdit['datA_TYPE'] != "date" && valueEdit['datA_TYPE'] != "datetime" && valueEdit['datA_TYPE'] != "bit")) {

                      ArrayData.push(custumValidation['validateSize'](sizeMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                  }
                  if (valueEdit['validations'] != "" && valueEdit['validations'] != null) {

                      let ValName = "'" + valueEdit['validations'] + "'"
                      ArrayData.push(custumValidation[eval(ValName)](customMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));

                  }

                  if (valueEdit['checkDataExists'] != "" && valueEdit['checkDataExists'] != null && valueEdit['checkDataExistsParam'] != "" && valueEdit['checkDataExistsParam'] != null) {
                    let columnValueEdit;
                       if(valueEdit['controlType'].toLowerCase()=="dropdown"){
                        columnValueEdit = valueEdit['drpDataSelected'] !="" && valueEdit['drpDataSelected'] != null && valueEdit['drpDataSelected'] != undefined ? valueEdit['drpDataSelected'][0]['id'] : "";
                       }
                       else if(valueEdit['controlType'].toLowerCase()=="date" || valueEdit['controlType'].toLowerCase()=="datetime"){
                        columnValueEdit = valueEdit['columnValue'] !="" && valueEdit['columnValue'] != null && valueEdit['columnValue'] != undefined ? new Date(valueEdit['columnValue']) : null;
                       }
                       else{
                        columnValueEdit = valueEdit['columnValue']; 
                       }
                    if (valueEdit['checkDataExists'] == 1) {
                        let sqlParams = valueEdit['checkDataExistsParam'].split('%');

                        checkExsistAsync.push(that.asyncCustomvalidation['validateValueInDBExists_Check'](valueEdit['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                    }
                    else  if (valueEdit['checkDataExists'] == 3) {
                        let sqlParams = valueEdit['checkDataExistsParam'].split('%');
                        chkAsyncGenericDbCheck = true;
                        checkExsistAsync.push(that.asyncCustomvalidation['validateValueInGenericDBExists_Check'](valueEdit['controlType'],"E",valueEdit['columN_NAME'],valueEdit['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                    }
                    else if (valueEdit['checkDataExists'] == 2) {
                        let arrayParams = valueEdit['checkDataExistsParam'].split('%');
                        // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                        // ArrayData.push(custfunction);

                        ArrayData.push(custumValidation['validateValueInArrayExists_Check'](valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString(), eval(arrayParams[0]), arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));


                    }

                }
              }
              if (valueEdit['controlType'].toString().toLowerCase() == 'dropdown')
                  reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
              else if (valueEdit['controlType'].toString().toLowerCase() == 'mdropdown')
                  reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

              else if (valueEdit['controlType'].toString().toLowerCase() == 'datetime') {
                  //console.log(moment(valueEdit['columnValue']).format(that.dateFormat));
                  reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? new Date(valueEdit['columnValue']) : '', disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
              }
              else if (valueEdit['controlType'].toString().toLowerCase() == 'checkbox') {

                  reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? valueEdit['columnValue'] : false, disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
              }
              else
                  reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

          });
          this.masterChildActionProductForm = this.fb.group(reactiveFormData);

          console.log(this.masterChildActionJsonData);
          console.log(this.masterChildActionProductForm);
      }
      this.spinner.hide();
      if(chkAsyncGenericDbCheck){
        this.masterChildActionProductForm.valueChanges.pipe().subscribe((res: FormGroup) =>{
            this.commonfunction.setFormLatestValue(this.masterChildActionProductForm,"E");
        });
    }
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();

          console.log(err.message);
          if (err.status == 401) {

              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

          if (this.masterChildActionJsonData != null) {
              console.log("test 1");
              this.isRenderEdit = true;
            //  this.onOpenEditMasterModal();
          }
          else {
            console.log("test 2");
             // this.childActionParam = this.selectedActionName;
              this.onAcceptConfirmModal();

          }
      });
}
onAcceptConfirmModal(){
    this.updateEditMasterForm();
    this.childAction.Action = true;
    this.childAction.genericSPParmas = this.childActionParam;
    this.activeModal.close(this.childAction);
}

updateEditMasterForm() {
  let that = this;
  let flag = true;
  let msg = '';
  let _data;
  let tableNamefor;
  let objColumn = <lstObjectParam>{};
  let genericColumnList:any = [];
  let mylist = '[';
  let genericSPParams = '';
if(this.masterChildActionJsonData){
  this.masterChildActionJsonData.forEach(function (value, index) {

      let objList = <ObjectParam>{};
      if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
          let colValue;
          let CompareColumnValue;
          if (value["controlType"].toLowerCase() == "dropdown") {
              let drpvalue = [];
              drpvalue = that.masterChildActionProductForm?.value[value["controlName"]]
              if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                  colValue = null;
              else
                  colValue = drpvalue[0]['id'].toString();
          }
          else if (value["controlType"].toLowerCase() == "checkbox") {
              let chkValue = that.masterChildActionProductForm?.value[value["controlName"]]
              if (chkValue == null || chkValue == false) {
                  colValue = "false";
              }
              else {
                  colValue = "true";
              }
          }
          else if (value["controlType"].toLowerCase() == "file") {
              colValue = that.masterChildActionProductForm?.value[value["controlName"]]
          }
          else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
              colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterChildActionProductForm?.value[value["controlName"]]);
          }
          else {
              colValue = that.masterChildActionProductForm?.value[value["controlName"]]
          }
          if (value["compareColumnName"] != "") {
              if (value["compareColumnName"].toLowerCase() != "today") {
                  CompareColumnValue = $.trim(jQuery('#' + value["controlName"]).val().toString());
              }
              else {
                  CompareColumnValue = '';
              }
          }
          if (colValue != null || colValue != "") {
              genericSPParams = genericSPParams + '@' + value["columN_NAME"] + " = '" + colValue + "',";
          }

      }
  });
}
  if (genericSPParams != "") {

      objColumn.genericColumnList = genericColumnList;
      _data = JSON.stringify(objColumn);
      this.childActionParam = this.selectedActionName + " " + genericSPParams;

  }
  else {
      this.childActionParam = this.selectedActionName;
  }
  //this.onCloseEditMasterModal(1);

}

OnCloseEdit(item: any, controlName: string) {
    this.masterChildActionProductForm.get(controlName)?.markAsTouched();
}
}
