<div class="tab-content">
  <div class="container col-xl-12">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
        <h4 id="Formheading" class="user-profile-manage text-center">Manage Profile</h4>
      </div>
    </div>
    <!-- <form *ngIf="userData" #imageForm=ngForm (ngSubmit)="OnSubmit(Caption,Image)"> -->
    <form *ngIf="userData" #imageForm=ngForm>
      <div class="row">

        <div class="col-lg-3 col-md-3 col-sm-3 col-xl-3">
          <div class="profile-img-edit">
            <!-- <img alt="Profile Picture" class="picture-src" [src]="url"> -->
            <!-- <div class="image-preview">
          <img class="picture-src" [src]="imagePreview">
        </div>
        <input style="display: none" type="file"  (change)="onFileUpload($event)"  #selectFile>
        <button (click)="selectFile.click()" class='btn btn-link opendetail'><i class='fa fa-upload' style='font-size:24px;color:green'></i></button>
        <button (click)="OnCancelFile()" class='btn btn-link opendetail'><i class='fa fa-close' style='font-size:24px;color:red'></i></button> -->

            <!-- <form #imageForm=ngForm (ngSubmit)="OnSubmit(Caption,Image)"> -->
            <div class="">
              <div class="input-field col s12" style="display:none">
                <!-- <input type="text" #Caption ngModel name="Caption" id="Caption" required> -->
                <input type="text" ngModel name="Caption" id="Caption" required>
                <label for="Caption">Caption</label>
              </div>
            </div>
            <div class="">
              <div class="picture">
                <div class="image-preview">


                  <!-- <button type="button" (click)="getImageFromService()">Get Image</button> -->

                  <!-- <img [src]="imageToShow"
                   alt="Place image title"
                   *ngIf="!isImageLoading; else noImageFound">
              <ng-template #noImageFound>
                   <img src="fallbackImage.png" alt="Fallbackimage">
              </ng-template> -->

                  <img [src]="imageUrl" class="picture-src">

                </div>
              </div>
            </div>

            <div class="upload-btn">

              <input type="file" style="display: none" #Image accept="image/*"
                (change)="handleFileInput($event)" multiple>
                <ul class="list-unstyled text-center">
                  <li class="d-inline-block align-middle">
                    <button (click)="Image.click()" class='btn upload-photo opendetail'
                      data-toggle="tooltip" data-placement="top" title="Change Photo"><i class='fa fa-upload'></i></button>
                  </li>
                  <li class="d-inline-block align-middle ml-3">
                    <button (click)="OnCancelFile()" class='btn delet-photo opendetail'
                      data-toggle="tooltip" data-placement="top" title="Cancel Photo"><i class='fa fa-trash' ></i></button>
                  </li>
                </ul>
              <!-- <table style="width:100%">
                <tr>
                  <td></td>
                  <td ><button (click)="Image.click()" class='btn upload-photo opendetail'
                      data-toggle="tooltip" data-placement="top" title="Change Photo"><i class='fa fa-upload'></i></button>
                  <td>
                  <td><button (click)="OnCancelFile()" class='btn delet-photo opendetail'
                      data-toggle="tooltip" data-placement="top" title="Cancel Photo"><i class='fa fa-trash' ></i></button></td>
                  <td></td>
                </tr>
              </table>   -->
            </div> 
          </div>
        </div>

        <div class="form-group col-lg-9 col-md-9 col-sm-9 col-xl-9" [formGroup]="profileFormGroup">

          <div class="form-group">
            <input class="form-control" (keypress)=_keyPressString($event) #empName value={{userData.empnam}}
              type="text" placeholder="Enter Name">
          </div>
          <div class="form-group">
            <!-- <input class="form-control" #empEmail value={{userData.personalemail}} type="text"  placeholder="Enter Email"> -->

            <input type="text" [(ngModel)]="userData.personalemail" formControlName="empEmail" #empEmail
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.empEmail.errors }"
              placeholder="Enter Email" />

            <div *ngIf="submitted && f.empEmail.errors" class="text-danger">
              <div *ngIf="f.empEmail.errors.required">Email is required</div>
              <div *ngIf="f.empEmail.errors.email">Email must be a valid email address</div>
            </div>

            <!-- <p class="text-danger" *ngIf="f.empEmail.errors.required">Email is required</p>
         <p class="text-danger" *ngIf="f.empEmail.errors.email">Email must be a valid email address</p> -->

          </div>
          <div class="form-group">
            <input type="text" formControlName="phone" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" (keypress)=_keyPressNumber($event) #empPhone
              value={{userData.phone}} placeholder="Enter Mobile No" />
            <!-- <input class="form-control" maxlength="10" (keypress)=_keyPressNumber($event) #empPhone value={{userData.phone}} type="text"  placeholder="Enter Mobile No"> -->
            <div *ngIf="submitted && f.phone.errors" class="text-danger">

              <div *ngIf="f.phone.errors.minlength">Mobile no must be at least 10 digits</div>
            </div>
            <!-- <p class="text-danger" *ngIf="f.phone.errors.minlength">Mobile no must be at least 10 digits</p> -->

          </div>
          <!-- <div class="form-group">
         <input type='file' #empFile value={{userData.photopath}} class="form-control" (change)="onFileChanged($event)">
     </div> -->
          <div class="form-group">
            <!-- <button class="form-control btn btn-primary" type="submit" (click)="UpdateProfile(empName.value,empEmail.value,empPhone.value,empFile.value)">Update Profile</button> -->
            <button class="form-control btn btn-primary" type="submit"
              (click)="OnSubmit(Image,empName,empEmail,empPhone)">Update Profile</button>
          </div>

        </div>


        

      </div>
    </form>

  </div>
</div>