<div class="wrapper">

	<div id="content">
		<br><br><br>

		<div class="container">

			<div class="row">

				<div class="col-md-12">
					<div class="card"
						style="background-image: linear-gradient(to bottom, #c5dada, #b3caca, #a0baba, #8faaaa, #7d9b9b);min-height:100%">
						<br>
						<br>
						<br>


						<div class="tab-content">
							<div class="container col-xl-12">
								<div class="row">
									<div class="container col-lg-12 col-md-12 col-sm-12 col-xl-12">
										<h4 id="Formheading" class=" text-center">Reset Password</h4>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xl-12">
										<form id="ResetForm" #ResetForm="ngForm"
											(ngSubmit)="onResetPass(paw.value,conpaw.value)">
											<div class="form-group">
												<input type="password" id="paw" #paw name="paw" class="form-control"
													ngModel (focus)="onFocus()" (blur)="onblur()"
													(keyup)="onKey(paw.value)" placeholder="Password" required>
												<span id="result">{{PMessage}}</span>
												<input type="password" id="conpaw" #conpaw name="conpaw"
													class="form-control" ngModel
													(keyup)="confirmPassword(paw.value,conpaw.value)"
													placeholder="Confirm Password" required>
												<span id="Cresult">{{CPMessage}}</span>
												<button [disabled]="!ResetForm.valid" type="submit" id="submit"
													class="form-control btn btn-primary">Submit</button>
											</div>
										</form>

									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="aro-pswd_info">
											<div id="pswd_info">
												<h4>Password must be requirements</h4>
												<ul>
													<li id="letter" class="invalid">At least <strong>one letter</strong>
													</li>
													<li id="capital" class="invalid">At least <strong>one capital
															letter</strong></li>
													<li id="number" class="invalid">At least <strong>one number</strong>
													</li>
													<li id="length" class="invalid">Be at least <strong>8
															characters</strong></li>
													<li id="space" class="invalid">be<strong> use
															[~,!,@,#,$,%,^,&,*,-,=,.,;,']</strong></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>



			</div>



		</div>



	</div>
</div>


<!--Reset Password start Modal -->
<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div id="AlertModal" class="modal" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left">Alert</h4>
				<button type="button" class="close" aria-label="Close" (click)="closeModal()"><span
						aria-hidden="true">&times;</span></button>
			</div>
			<div class="modal-body">
				{{AlertMessgae}}
			</div>
			<div class="modal-footer">

				<button type="button" class="btn btn-primary  " (click)="closeModal()">Ok</button>
			</div>
		</div><!-- /.modal-content -->
	</div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
<!--Reset Password End Modal -->