import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { formatDate } from '@angular/common';

import { Commonfunction } from "../../shared/commonutility";
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { custumValidation } from '../../shared/custum-validation';
import { Customvalidation } from "../../shared/Validation";
import { Subject } from 'rxjs';
//import * as CanvasJS from '../../assets/Script/canvasjs-2.2/canvasjs.min.js';
import 'jquery';
declare var jQuery: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  //constructor() { }
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = " NP_popup_success";
  AlertAnimationType: any = "smile";
  public options: any;
  public display = "none";
  private timer: number=0;
  DetailHeading: string='';
  public dtOptionsDetail: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    Width: "100%",
    autoWidth: true,
    responsive: true,
    //select from drop down list to select number of records per page
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 400,
    scrollX: true,
    scrollCollapse: true,
    // bDestroy: true,
    // fixedHeader: {
    //     header: true,
    //     footer: true
    // },
    emptyMessage: "No data available!!",
    // fixedColumns:   {
    //     leftColumns: 2
    // }

};
public dtTriggerDetail: Subject<any> = new Subject();
public isRenderDetail: boolean = false;
public Detailtableid: string ='';
public DetailtableHeaderData:any[] = [];
public   DetailtableRowData:any[] = []
  private PieChart?: AmChart;
  private BarChart?: AmChart;
 
  menulabel: string;
  formlable: string;
  dashboardSummaryCount: any;

  dashboardSummaryStatus: any;
  dashboardSummaryRetire: any;
  dashboardSummaryAll: any;


  constructor(public commonfunction: Commonfunction,public userService: UserService,public Customvalidation: Customvalidation, private router: Router,private spinner: NgxSpinnerService, private AmCharts: AmChartsService, private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService) {
    this.dashboardSummaryCount = [];
    this.dashboardSummaryStatus = [];
    this.dashboardSummaryRetire = [];
    this.dashboardSummaryAll = [];
    $('#ShowDetailModal').hide();

    this.menulabel = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"))?.menu;
    this.formlable = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"))?.formName;


    this.onTwoPreviousMonthFeeSummary();
    this.onThreePreviousMonthFeeSummary();
    this.OnCurrentYearFeeStatusSummary();
    this.onCurentMonthFeeSummary();
    this.onPreviousMonthFeeSummary();
    this.onStudentTodayAttendanceSummary();   
    this.onStudentYesterdayAttendanceSummary();
  }
  ngOnInit() {
    // $('#ShowDetailModal').hide();

    // this.param = sessionStorage.getItem("menuItem");
    // let paramjs = JSON.parse(this.param);
    // this.menulabel = paramjs.menu;
    // this.formlable = paramjs.formName;
  


    // this.OnCurrentYearFeeStatusSummary();
    // this.onCurentMonthFeeSummary();
    // this.onStudentTodayAttendanceSummary();
    // this.onPreviousMonthFeeSummary();
    // this.onStudentYesterdayAttendanceSummary();
   

  }

  FirstLetterLower(value: string) {
    return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
    return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
    return this.commonfunction.ReplaceNullDataTable(value);
}

  ngOnDestroy() {
    clearInterval(this.timer);

    // Cleanup chartdiv2
    if (this.PieChart) {
      this.AmCharts.destroyChart(this.PieChart);
    }
    if (this.BarChart) {
      this.AmCharts.destroyChart(this.BarChart);
    }

    this.dtTriggerDetail.unsubscribe();

  }

  OnCurrentYearFeeStatusSummary() {
    this.userService.getDashboardSummaryGraph().subscribe((data: any) => {
      this.dashboardSummaryCount = data["table"];
      console.log(this.dashboardSummaryCount);
      let dataPointsSummary: any[] = [];
      let Sno = 1;
      $.each(data["table"], function (key:number, value: any) {
        console.log(value.Sno);
        if (value.sNo === 1) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff0f00" , });
        }
        if (value.sNo === 2) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff6701" });
        }
        if (value.sNo === 3) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff9e01" });
        }
        if (value.sNo === 4) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#fcd202" });
        }
        if (value.sNo === 5) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#f8ff01" });
        }
        if (value.sNo === 6) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#b0de09" });
        }
        if (value.sNo === 7) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff0000" });
        }
        if (value.sNo === 8) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#4dc3ff" });
        }
        if (value.sNo === 9) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#00ffcc" });
        }
        if (value.sNo === 10) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#4dff4d" });
        }
        if (value.sNo === 11) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff9966" });
        }
        if (value.sNo === 12) {
          dataPointsSummary.push({ xAxis: value.xAxis, yAxis: value.yAxis, color: "#ff6699" });
        }
      });

      this.AmCharts.makeChart("BarChartFull", {
        "theme": "light",
        "type": "serial",
        "startDuration": 2,
        "dataProvider": dataPointsSummary,
        "valueAxes": [{
          "position": "left",
          "title": "Count"
        }],
        "titles": [{
          "text": "Current Year Fee Status Summary",
          "size": 16
        }],
        "graphs": [{
          "balloonText": "[[category]]: <b>[[yAxis]]</b>",
          "fillColorsField": "color",
          "fillAlphas": 1,
          "lineAlpha": 0.1,
          "type": "column",
          "valueField": "yAxis"
        }],
        "depth3D": 20,
        "angle": 30,
        "chartCursor": {
          "categoryBalloonEnabled": false,
          "cursorAlpha": 0,
          "zoomable": false
        },
        "categoryField": "xAxis",
        "categoryAxis": {
          "gridPosition": "start",
          "labelRotation": 45
        },
        "export": {
          "enabled": true
        }
      });

    });
  }

  onCurentMonthFeeSummary() {
    let that = this;
    let today= new Date();
    let  jstoday = formatDate(today, 'yyyy/MM/dd', 'en');
    let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaid @date['"+jstoday.toString()+"',"
    let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaidDeatil @date['"+jstoday.toString()+"',"

    this.userService.getDashboardColumnBar("Bar", proc).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key: number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("currentMonth", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Curent Month Fee Summary",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",

          //"useGraphSettings": true
        }
        // ,
        // "listeners": [{
        //   "event": "clickSlice",
        //   "method": (jQueryevent) => that.onClickChartDetails(jQueryevent, jstoday,that)
        // }]
      });
      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Curent Month Fee Summary")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onPreviousMonthFeeSummary() {
    let that = this;
    let today= new Date();
    let  jstoday = formatDate(today.setMonth(today.getMonth()-1), 'yyyy/MM/dd', 'en');
    console.log(jstoday , today.getMonth())
    let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaid @date['"+jstoday.toString()+"',"
    let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaidDeatil @date['"+jstoday.toString()+"',"
    this.userService.getDashboardColumnBar("Bar", proc).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key:number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("previousMonth", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Previous Month Fee Summary",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",

          //"useGraphSettings": true
        }
        // ,
        // "listeners": [{
        //   "event": "clickSlice",
        //   "method": (jQueryevent) => this.onClickChartDetails(jQueryevent, jstoday, that)
        // }]
      });
      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Previous Month Fee Summary")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onTwoPreviousMonthFeeSummary() {
    let that = this;       
    let today= new Date();
    let  jstoday = formatDate(today.setMonth(today.getMonth()-2), 'yyyy/MM/dd', 'en');
    console.log(jstoday , today.getMonth())
    let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaid @date['"+jstoday.toString()+"',"
    let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaidDeatil @date['"+jstoday.toString()+"',"
    this.userService.getDashboardColumnBar("Bar", proc).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key:number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("TwopreviousMonth", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Previous 2 Month's Fee Summary",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",

          //"useGraphSettings": true
        }
        // ,
        // "listeners": [{
        //   "event": "clickSlice",
        //   "method": (jQueryevent) => this.onClickChartDetails(jQueryevent, jstoday, that)
        // }]
      });
      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Previous 2 Month's Fee Summary")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onThreePreviousMonthFeeSummary() {
    let that = this;    
    let today= new Date();
    let  jstoday = formatDate(today.setMonth(today.getMonth()-3), 'yyyy/MM/dd', 'en');
    console.log(jstoday , today.getMonth())
    let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaid @date['"+jstoday.toString()+"',"
    let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_GetFeePaidOrUnPaidDeatil @date['"+jstoday.toString()+"',"
    this.userService.getDashboardColumnBar("Bar", proc).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key:number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("ThreepreviousMonth", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Previous 3 Month's Fee Summary",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",

          //"useGraphSettings": true
        }
        // ,
        // "listeners": [{
        //   "event": "clickSlice",
        //   "method": (jQueryevent) => this.onClickChartDetails(jQueryevent, jstoday, that)
        // }]
      });
      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Previous 3 Month's Fee Summary")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onStudentTodayAttendanceSummary() {
let that = this;
   let today= new Date();
   let  jstoday = formatDate(new Date(), 'yyyy/MM/dd', 'en');
   console.log(jstoday);
let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDate @fromDate['"+jstoday.toString()+"', @toDate['"+jstoday.toString()+"',"
let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDateDetail @fromDate['"+jstoday.toString()+"', @toDate['"+jstoday.toString()+"',"
console.log(proc);
    this.userService.getDashboardColumnBar("Bar",proc ).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key:number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("todayAttendance", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Student's Today Attendance",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",
          "autoMargins": true
          //"useGraphSettings": true
        }
        // ,
        // "listeners": [{
        //   "event": "clickSlice",
        //   "method": (jQueryevent) => this.onClickChartDetails(jQueryevent, jstoday, that)
        // }]
      });
      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Student's Today Attendance")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

      
  }

  

  onStudentYesterdayAttendanceSummary() {
let that = this;
   let today= new Date();
   let  jstoday = formatDate(today.setDate(today.getDate()-1), 'yyyy/MM/dd', 'en');
   console.log(jstoday);
let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDate @fromDate['"+jstoday.toString()+"', @toDate['"+jstoday.toString()+"',"
let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDateDetail @fromDate['"+jstoday.toString()+"', @toDate['"+jstoday.toString()+"',"
console.log(proc);
    this.userService.getDashboardColumnBar("Bar",proc ).subscribe((data: any) => {
      let dataPoints:any[] = [];

      $.each(data["table"], function (key:number, value:any) {
        dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis });
      });

      console.log(dataPoints);

      var chart = this.AmCharts.makeChart("yesterdayAttendance", {
        "type": "pie",
        "theme": "none",
        "titles": [{
          "text": "Student's Yesterday Attendance",
          "size": 16
        }],
        "dataProvider": dataPoints,
        "valueField": "yAxis",
        "titleField": "xAxis",
        "labelText": "[[yAxis]]",
        "startEffect": "elastic",
        "startDuration": 2,
        "labelRadius": 15,
        "innerRadius": "50%",
        "depth3D": 10,
        //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
        "angle": 15,
        "export": {
          "enabled": true
        },
        "legend": {
          "accessibleLabel": "xAxis",
          "align": "center",
          "autoMargins": true
          //"useGraphSettings": true
        }
      });

      this.AmCharts.addListener(chart, "clickSlice", (e) => {
        this.onClickChartDetails(e, proClick, "Student's Yesterday Attendance")
      });
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
     
  }

  onClickChartDetails(e: any, proc: string, ref: string) {
    let that = this;
    // console.log(e.chart.div);
    // e.chart.div
    let procDetail = proc + "@status['"+e.dataItem.dataContext.xAxis+"',"
    // let procDetail  = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDateDetail @fromDate['2020/05/16', @toDate['2020/05/16',@status['Present',"
    // let divname = e.dataItem.dataContext.xAxis;
   this.DetailHeading =ref+"("+ e.dataItem.dataContext.xAxis+")";
        let headerData:any[] = [];
        let dataRow = [];
        that.spinner.show();
        //   if(that.DetailtableHeaderData.length > 0)
        //   that.DetailtableHeaderData.splice(0, that.DetailtableHeaderData.length);
        if (that.DetailtableRowData.length > 0)
            that.DetailtableRowData.splice(0, that.DetailtableRowData.length);
            if (that.DetailtableHeaderData.length > 0)
            that.DetailtableHeaderData.splice(0, that.DetailtableHeaderData.length);
       
        that.Detailtableid = 'EditDynamictable';
        // $('#' + that.Detailtableid).DataTable().destroy();
    that.userService.getDashboardDetail(procDetail).subscribe((datacom: any) => {
      that.spinner.hide();
      // console.log(datacom);
      if (datacom.table.length > 0) {
          
        
              $.each(Object.keys(datacom.table[0]), function (index: number, value: string) {
                  if(value.toLowerCase() != "id" && value.toLowerCase() != "param1" && value.toLowerCase() != "param2" )
                  headerData.push({ "key": value, title: value.toUpperCase() });

              });
              that.DetailtableHeaderData = headerData;
         
          dataRow = datacom.table;
         
          that.DetailtableRowData = dataRow;
          // console.log(that.DetailtableHeaderData);
          // console.log(that.DetailtableRowData);
      }
  },
      (err: HttpErrorResponse) => {
          that.spinner.hide();
          if (err.status == 401) {
              that.AlertTitle = "Error!!";
              that.AlertMessage = "You are not authorized!";
              that.AlertClass = "NP_emoji-fail-bg";
              that.AlertAnimationType = "sad";
              that.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              that.Customvalidation.loginroute(err.status);
          }
          else {
              that.AlertTitle = "Error!!";
              that.AlertMessage = err.message.toString();
              that.AlertClass = "NP_emoji-fail-bg";
              that.AlertAnimationType = "sad";
              that.AlertType = " NP_popup_fail";
          }
          that.globalAlertService.AlertMsg(that.AlertTitle, that.AlertMessage, "Ok", that.AlertType, that.AlertClass, that.AlertAnimationType);
      },
      () => {


          that.onOpenEditMasterModal() ;
          console.log(that.display);
          that.spinner.hide();
          //   that.globalAlertService.AlertMsg(that.AlertTitle, that.AlertMessage, "Ok", that.AlertType, that.AlertClass, that.AlertAnimationType);
      });
  }

  onOpenEditMasterModal() {
    this.display = "block";
    $('#ShowChartDetailModal').show();
    this.isRenderDetail = true;
    
}
  onCloseShowDetailModal() {
    $('#ShowChartDetailModal').hide();
    this.display = "none";
    this.isRenderDetail = false;
    
  }
}






