import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Commonfunction } from "../../shared/commonutility";
import { UserService } from 'src/app/shared/user.service';


@Component({
  selector: 'app-bread-cum',
  templateUrl: './bread-cum.component.html',
  styleUrls: ['./bread-cum.component.css']
})
export class BreadCumComponent implements OnInit {
  param: string="";
  menulabel: string="";
  subMenulabel: string="";
  userData: any;
  formlable: string="";
  landingPage:string="";

  constructor(private router: Router, private commonfunction:Commonfunction, private userService: UserService) { }

  ngOnInit() {
    //let menuItem:string ='';
    let userInfo = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('UserInfo')) ? sessionStorage.getItem("UserInfo")?.toString():null;
    let menuItem = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('menuItem')) ? sessionStorage.getItem("menuItem")?.toString():null;
    // this.userService.getdynamicformparam().subscribe((data:any) => { menuItem = data });
    this.userData = userInfo!=null ? JSON.parse(userInfo) : null; 
    this.param = menuItem != null ? menuItem : '';
    let paramjs = JSON.parse(this.param);
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.subMenulabel = paramjs.subMenu;
    if(this.userData['landingpage'] === null || this.userData['landingpage'] === "")
    {
      this.landingPage = "home";
    }
    else{
      this.landingPage = this.userData['landingpage'];

    }
  }

  HomeAction()
  {
    this.menulabel = '';
    this.subMenulabel = '';
    this.formlable = this.landingPage.toString();
    this.router.navigate(['/'+this.landingPage.toString()]);
  }

}
