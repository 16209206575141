<div class="ContactPage" style="background-color: #2B2B2B;">
    <div class="position-relative" style="background-color: #232323;">
        <div class="ContactPageBacImg" style="background-image: url(assets/img/website-contact-us.jpg);">
    </div>
        <div  class="container row m-auto"  style="position: absolute; top: 0;bottom: 0; left: 0;right: 0;">
            <div class="col-md-6 p-0">
                <!-- <div class="leftdata">
                        <img src="assets/img/Image.jpeg" alt="">
                    </div> -->
            </div>
            <!-- <div class="col-md-6 " style="box-shadow: -60px 0 137px 1px #2b2b2b;"> -->
            <div class="col-md-6" data-aos="fade-left">
                <div class="right" style="margin: 30px 0px;">
                    <div class="container">
                        <!--Section: Contact v.2-->
                        <div >
                            <h1 class="text-light pb-2">Contact Us</h1>
                        </div>
                        <section class="mb-4">
    
                            <div class="w-100">
    
                                <!--Grid column-->
                                <div class="w-100">
                                    <form id="contact-form" name="contact-form " [formGroup]="FormData"
                                        (ngSubmit)="onSubmit(FormData.value)">
    
                                        <!--Grid row-->
                                        <div class="row">
    
                                            <!--Grid column-->
                                            <div class="col-md-12">
                                                <div class="md-form contactusform mb-3">
                                                    <input placeholder="Name" type="text" id="Fullname" name="Fullname"
                                                        class="form-control inputform" formControlName="Fullname"
                                                        [class.is-invalid]="Fullname.invalid && (Fullname.dirty || Fullname.touched)">
                                                    <div *ngIf="Fullname.invalid && (Fullname.dirty || Fullname.touched)"
                                                        class="invalid-feedback">
                                                        <div *ngIf="Fullname.errors?.['required']">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="Fullname.errors?.['minlength']">
                                                            This field must have at least 1 character.
                                                        </div>
                                                        <div *ngIf="Fullname.errors?.['maxlength']">
                                                            This field must have at most 100 characters.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Grid column-->
    
                                        </div>
                                        <!--Grid row-->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="md-form mb-3 contactusform">
                                                    <input type="text" placeholder="Email" id="Email" name="Email"
                                                        class="form-control inputform" formControlName="Email"
                                                        [class.is-invalid]="Email.invalid && (Email.dirty || Email.touched)">
                                                    <div *ngIf="Email.invalid && (Email.dirty || Email.touched)"
                                                        class="invalid-feedback">
                                                        <div *ngIf="Email.errors?.['required']">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="Email.errors?.['minlength']">
                                                            This field must have at least 1 character.
                                                        </div>
                                                        <div *ngIf="Email.errors?.['maxlength']">
                                                            This field must have at most 100 characters.
                                                        </div>
                                                        <div *ngIf="Email.errors?.['email']">
                                                            This field must be a valid email.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Grid row-->
    
    
    
                                        <!--Grid row-->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="md-form contactusform mb-3">
                                                    <input placeholder="Subject" type="text" id="subject" name="subject"
                                                        class="form-control inputform" formControlName="Subject"
                                                        [class.is-invalid]="Subject.invalid && (Subject.dirty || Subject.touched)">
                                                    <div *ngIf="Subject.invalid && (Subject.dirty || Subject.touched)"
                                                        class="invalid-feedback">
                                                        <div *ngIf="Subject.errors?.['required']">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="Subject.errors?.['minlength']">
                                                            This field must have at least 1 character.
                                                        </div>
                                                        <div *ngIf="Subject.errors?.['maxlength']">
                                                            This field must have at most 100 characters.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Grid row-->
    
                                        <!--Grid row-->
                                        <div class="row">
    
                                            <!--Grid column-->
                                            <div class="col-md-12">
    
                                                <div class="md-form contactusform">
                                                    <textarea placeholder="Message" type="text" id="message" name="message"
                                                        rows="2" class="form-control md-textarea inputform" formControlName="Message"
                                                        [class.is-invalid]="Message.invalid && (Message.dirty || Message.touched)"></textarea>
                                                    <div *ngIf="Message.invalid && (Message.dirty || Message.touched)"
                                                        class="invalid-feedback">
                                                        <div *ngIf="Message.errors?.['required']">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="Message.errors?.['minlength']">
                                                            This field must have at least 1 character.
                                                        </div>
                                                        <div *ngIf="Message.errors?.['maxlength']">
                                                            This field must have at most 1500 characters.
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="capcha">
                                                    <div class="capcha_wapper">
    
                                                    </div>
                                                </div>
    
                                            </div>
                                        </div>
                                        <!--Grid row-->
    
                                        <div class="captcha-section d-flex justify-content-between align-items-center mt-3">
                                            <app-captcha (captchVerifictionEvent)="onCaptchVerification($event)" class="w-100 capchaBoxCss"
                                                [isShowErrorMsg]="true" [isShowValidateMsg]="false"
                                                [pageReset]="isPageReset"></app-captcha>
                                        </div>
    
                                        <div class="text-center text-md-left mt-3">
                                            <button type="submit" class="btn getdatabtn w-100"
                                                [disabled]="isFormDisabled()">Submit</button>
                                        </div>
                                        <div *ngIf="queryPostedShowMsg"> Query Posting! please wait ....</div>
                                    </form>
                                    <div class="status"></div>
                                </div>
                            </div>
    
                        </section>
    
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>