import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Response, RequestOptions } from "@angular/http";
// import { map } from 'rxjs/operators';
import { User } from "../../shared/user.model";
import { AuthGuard } from "../../auth/auth.guard";
import { environment } from '../../../environments/environment';

import { Commonfunction } from '../../shared/commonutility';
import { UserService } from '../../shared/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicGenericServiceService {
  readonly rootUrl = environment.rootUrl;
  isLoginError: boolean = false;
  // isLogin: boolean = false;
  isCaptcha: number =3;
  jsonFiledata:any[] = [] ;
  //1= all (captcha and Remember me)
  //2= all (!captcha and Remember me)
  //3= all (captcha and !Remember me)
  //4= all (!captcha and !Remember me)
  errorMessgae: string="";
  AlertMessgae: string="";
  private isLogin = new BehaviorSubject(false);
  constructor(private http: HttpClient, private commonfunction: Commonfunction, private userService: UserService, private router: Router
    , private spinner: NgxSpinnerService) { 
      this.jsonFiledata = this.commonfunction.loadJson("~/../assets/punlicFormsConfig.json");
    }
    Setdynamicformparam(param: boolean) {
      this.isLogin.next(param)
    }
    getdynamicformparam() {
      return this.isLogin.asObservable();
      // this.currentMessage;
    }
  public loginPublicGenericForm(fromPage: string){
    let that = this;
    let paramjs = this.jsonFiledata[fromPage];
    if(paramjs != undefined && paramjs !=""){
    this.Setdynamicformparam(paramjs['islogin']);
    if(paramjs['islogin']){
    this.userService.userAuthentication("Guest[["+'['+localStorage.getItem('ExternalIPAddress')?.toString()+'['+localStorage.getItem('LocalIPAddress')?.toString(),"").subscribe((datacom: any) => {
   
     
      sessionStorage.setItem('userToken', datacom['access_token']);
      sessionStorage.setItem('userId', datacom['userName']);
      sessionStorage.setItem('expires', datacom[".expires"]);
      sessionStorage.setItem('issued', datacom[".issued"]);
      if (datacom['userRole'] != '') {
        sessionStorage.setItem('userRole', '{"Roles":[' + datacom['userRole'].replace(/'/g, '"') + ']}');
        let userRole = that.commonfunction.getUserRoleValuestring();
        var text = userRole != null ?JSON.parse(userRole) : null;
        // var parsedJSON = JSON.parse(text);

        sessionStorage.setItem('currentRole', JSON.stringify(text.Roles[0]));


      }
      else {
        sessionStorage.setItem('userRole', '');
        sessionStorage.setItem('currentRole', '');
      }
      let time: any = new Date(datacom[".expires"]);
      let date2: any = new Date(datacom[".issued"]);
      
      this.userService.UpdateClaims(this.commonfunction.getUserTokenValuestring(), this.commonfunction.getUserIdValuestring(), 600,'','').subscribe((datacom: any) => {
        this.spinner.hide();
        
      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
        });
    },
      (err: HttpErrorResponse) => {
        
          this.spinner.hide();
          this.isLoginError = true;
          this.errorMessgae = err.error.error;
        
       
      });
    }
    else{
     
      this.router.navigate(['/']);
      
    }
  }
  }

}
