<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/admisnbg.jpg" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Get to Know Our Admissions</p>
                <h1>Admission</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">Admission</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="admission_wapper">
    <div class="admission_main">
        <div class="container">
            <div class="topsection pt-3" data-aos="fade-right">
                <div class="mainreq">
                    <h1>{{Admissiondata?.admissionrequirments?.admissionrequirmentsheading}}</h1>
                    <div class="row reqlistmain">
                        <div class="col-md-12 pt-4 reqlist">
                            <ul>
                                <li *ngFor="let item of Admissiondata?.admissionrequirments?.admissionrequirmentsData">
                                    <p>{{item.title}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="applicationtable">
            <div class="applicationtable" data-aos="fade-left">
                <div class="application_wapper">
                    <h1>{{Admissiondata?.Applicationdeadline?.Applicationdeadlineheading}}</h1>
                    <div class="tableappliction pt-4">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Restrictive Early Action</th>
                                    <th>Regular Decision</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="active" *ngFor="let item of Admissiondata?.Applicationdeadline?.ApplicationdeadlineData">
                                    <td>{{item.title}}</td>
                                    <td>{{item.year}}</td>
                                    <td>{{item.monthly}}</td>
                                </tr>
                                <!-- <tr>
                                    <td>Standard Application Deadline</td>
                                    <td>November 1</td>
                                    <td>January 10</td>
                                </tr>
                                <tr>
                                    <td>Standard Application Deadline</td>
                                    <td>November 1</td>
                                    <td>January 10</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row pt-5 mainformadm position-relative">
                <div class="col-md-8" data-aos="fade-right">
                    <h1>Application Details</h1>
                    <form class="adminformdata pt-4">
                        <h5>Personal Information</h5>
                        <div class="row formadmis py-3">
                            <div class="col-md-6 form-control-box">
                                <input type="text" placeholder="First Name">
                            </div>
                            <div class="col-md-6 form-control-box">
                                <input type="text" placeholder="Last Name">
                            </div>
                        </div>
                        <div class="row formadmis py-3">
                            <div class="col-md-6 form-control-box">
                                <input type="email" placeholder="Your Email">
                            </div>
                            <div class="col-md-6 form-control-box">
                                <input type="number" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="row formadmis py-3">
                            <div class="col-md-6 form-control-box">
                                <input type="date" placeholder="Date of Birth">
                            </div>
                            <div class="col-md-6 form-control-box">
                                <select name="male">
                                    <option value="null" selected>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div class="row adminform">
                            <textarea name="" id="" placeholder="Message"></textarea>
                        </div>
                        <div class="captachbox py-3">
                            <span>
                                <app-captcha class="w-100 capchaBoxCss"></app-captcha>
                            </span>
                        </div>
                        <div class="row adminform">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
                <div class="col-md-4" data-aos="fade-left">
                    <div class="shortlinkform holdBox">
                        <div class="hold_warpper">
                            <div class="topheadlink">
                                <h5>{{Admissiondata?.Addimssionlink?.Addimssionlinkheading}}</h5>
                            </div>
                            <div class="btnlink pt-3">
                                <ul>
                                    <li *ngFor="let item of Admissiondata?.Addimssionlink?.AddimssionlinkData"><a href="#"><span><img src="../../../../assets/img/Arrow.png" alt=""></span>{{item.title}}</a></li>
                                    <!-- <li><a href="#"><span><img src="../../../../assets/img/Arrow.png" alt=""></span>
                                            BCA</a></li>
                                    <li><a href="#"><span><img src="../../../../assets/img/Arrow.png" alt=""></span>
                                            BCom</a></li>
                                    <li><a href="#"><span><img src="../../../../assets/img/Arrow.png" alt=""></span>
                                            BEd</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>