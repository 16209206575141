import { Component, ElementRef, OnInit } from '@angular/core';
import { Fancybox } from '@fancyapps/ui'
import { Commonfunction } from 'src/app/shared/commonutility';


@Component({
  selector: 'app-public-generic-photo-page',
  templateUrl: './public-generic-photo-page.component.html',
  styleUrls: ['./public-generic-photo-page.component.css']
})
export class PublicGenericPhotoPageComponent implements OnInit {

  sclgallery:any;
  constructor(private elRef: ElementRef,private commonfunction: Commonfunction) {
      const alldatafile = this.commonfunction.loadJson("~/../assets/gallerydb.json");
      this.sclgallery =  alldatafile.splice(0,10);
      console.log(this.sclgallery,"gallery");
      
   }


  ngOnInit() {
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });
  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }
  
}
