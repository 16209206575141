import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-generic-footer',
  templateUrl: './public-generic-footer.component.html',
  styleUrls: ['./public-generic-footer.component.css']
})
export class PublicGenericFooterComponent implements OnInit {
  footerData: any;
  schLogo: any;
  Linkdata:any;

  constructor( public commonfunction:Commonfunction) {
    const footerdatas = this.commonfunction.loadJson("~/../assets/footer.json");
    this.footerData = footerdatas[0];    
    const information = this.commonfunction.loadJson("~/../assets/header.json");
    this.Linkdata = information;
    this.schLogo = this.commonfunction.loadJson("~/../assets/SchoolLogo.json");
    }

  ngOnInit(): void {
  }

}
