import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormControlName, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms'
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { async } from '../../../../node_modules/@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { GenericFormDropDownServiceService } from "../../shared/generic-form-drop-down-service.service";
import { ObjectParam, lstObjectParam, customAction } from "../../shared/object-param.model";
import { DataTableDirective } from '../../../../node_modules/angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../../shared/excel.service';
// import  'src/typings';
import 'jquery';
import { custumValidation } from '../../shared/custum-validation';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { asyncCustumValidation } from '../../shared/async-custum-validation';
declare var jQuery: any;
// import * as moment from 'moment';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-generic-report-form',
    templateUrl: './generic-report-form.component.html',
    styleUrls: ['./generic-report-form.component.css']
})
export class GenericReportFormComponent implements OnDestroy, OnInit {
    customSearchString: string = '';
    get customSearch(): string {
        return this.customSearchString;
    }
    set customSearch(val: string) {
        this.customSearchString = val;
        this.CustomFilterusingColumn();
    }
    SeltableID: string="";
    SeltableIDForm: string="";
    dateFormat = 'MM-DD-YYYY';
    @ViewChild(DataTableDirective)
    dtElement?: DataTableDirective;
    masterProductForm!: FormGroup;
    isRender: boolean = false;
    isRenderDetail: number = 0;
    masterProductFormEdit!: FormGroup;
    isRenderEdit: boolean = false;
    editModelHeading: string="";
    editModelCallfrom: string="";
    childActionParam: string="";
    dtOptionsDetail: any = {
        pagingType: 'full_numbers',
        pageLength: 10,
        Width: "100%",
        autoWidth: true,
        responsive: true,
        //select from drop down list to select number of records per page
        lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
        scrollY: 400,
        scrollX: true,
        scrollCollapse: true,
        // bDestroy: true,
        // fixedHeader: {
        //     header: true,
        //     footer: true
        // },
        emptyMessage: "No data available!!",
        // fixedColumns:   {
        //     leftColumns: 2
        // }

    };
    viewtableHeaders: TableColumnName[] = [];
    dtTrigger: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    tableid: string="";
    Detailtableid: string="";
    DetailtableHeaderData = [];
    DetailtableRowData = []
    dropdownSettings = {};
    display = "none";
    Divdisplay = "none";
    displayMain = "none";
    displayMainEdit = "none";
    displayContainer = "block";
    sel: any;
    masterJsonDataFull: any[];
    masterJsonData: any[];
    masterJsonDataEdit: any[]=[];
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    tblecount: number=0;
    public value: string[]=[];
    public current: string="";
    public selected: string="";
    public dynamicdivdata: any;
    ischeckboxshow: number = 0;
    dropdownListActionGroupSpdrp = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    dropdownList = [];
    selectedItems = [];
    bsConfig:any;
    changeDetectorRefs: ChangeDetectorRef[] = [];
    paramvaluedata: any='';
    FileerrorMessage: string="";
    filesToUpload: Array<File>;
    selectedFileNames: string[] = [];
    public isLoadingData: Boolean = false;
    @ViewChild('fileUpload') fileUploadVar: any;
    uploadResult: any;
    res: Array<string>=[];
    param: string="";
    menulabel: string="";
    formlable: string="";
    minDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() - year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    maxDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() + year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    selectedRows:any = [];
    masterSelected: boolean = false;
    formValue: string="";
    formName: string="";
    currentRoleString: string | undefined = '';
    currentRole: string  ="";
    userId: string| undefined  ="";
    constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation, private userService: UserService, public asyncCustomvalidation: asyncCustumValidation,
        private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, route: ActivatedRoute, private spinner: NgxSpinnerService, private fb: FormBuilder,
         private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService, private excelService: ExcelService, private genericDropDown:GenericFormDropDownServiceService) {
        this.FileerrorMessage = "";
        this.filesToUpload = [];
        this.selectedFileNames = [];
        this.uploadResult = "";
        this.masterJsonData = [];
        this.masterJsonDataFull = [];
        this.bsConfig = {
            dateInputFormat: 'DD/MM/YYYY',
            isAnimated: true,
            adaptivePosition: true,
            containerClass: 'theme-blue',
            minDate: this.minDate(50),
            maxDate: this.maxDate(50)
        };
        this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))? sessionStorage.getItem('currentRole')?.toString()  : "";
      this.currentRole = this.currentRoleString != null ? JSON.parse(this.currentRoleString)?.roleId.toString():"";
      this.userId= sessionStorage.getItem('userId')?.toString();
    }

    ngOnInit() {
       
        let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
        this.formValue = paramjs.formValue;
        this.formName = paramjs.formName;
        this.SeltableID = paramjs.formValue;
        this.SeltableIDForm = paramjs.formName;
        this.menulabel = paramjs.menu;
        this.formlable = paramjs.formName;
        this.Search(paramjs.formValue, paramjs.formName);

        this.dropdownSettings = {
            singleSelection: true,
            // text:"Select Countries",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            // primaryKey:'DrpValue',
            // labelKey:'DrpOption',
            classes: "global-select-box",
            disabled: false
        };
        setTimeout(() => {  
           
        });
      // }
       
        
    }
    ngOnChanges(): void {
    }
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
    }


    Search(tbname:string, formName:string) {
        this.spinner.show();
        var trdata = '';
        const that = this;
        this.userService.getGenericReportFormPage(tbname, formName, 'insert').subscribe((data: any) => {
            this.displayMain = 'block';
            let reactiveFormData = {};

            this.masterJsonDataFull = data;
            this.masterJsonData = data['formData'];
            console.log(this.masterJsonData);
            this.masterJsonData.forEach(function (value, index) {
                let ArrayData: ValidatorFn[] = [];
                if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                    let requiredMsg = '';
                    let sizeMsg = '';
                    let customMsg = '';
                    if (value["customValidationMsg"] != "" && value["customValidationMsg"] != null) {
                        let customValidationMsg = JSON.parse(value["customValidationMsg"]);
                        requiredMsg = customValidationMsg['requiredMsg'];
                        sizeMsg = customValidationMsg['sizeMsg'];
                        customMsg = customValidationMsg['customMsg'];
                    }
                    if (value['iS_NULLABLE'] == "no") {
                        // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));

                        ArrayData.push(custumValidation['validateRequired'](requiredMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                    }
                    if ((value['datA_TYPE'] != "date" && value['datA_TYPE'] != "datetime" && value['datA_TYPE'] != "bit")) {

                        ArrayData.push(custumValidation['validateSize'](sizeMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                    }
                    if (value['validations'] != "" && value['validations'] != null) {

                        let ValName = "'" + value['validations'] + "'"
                        // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                        // ArrayData.push(custfunction);

                        ArrayData.push(custumValidation[eval(ValName)](customMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));

                    }
                }

                // validatenumeric('16','numeric','drop','Role','','','')
                if (value['controlType'].toString().toLowerCase() == 'dropdown')
                    reactiveFormData[value['controlName']] = new FormControl(value['drpDataSelected'], ArrayData);
                else if (value['controlType'].toString().toLowerCase() == 'mdropdown')
                    reactiveFormData[value['controlName']] = new FormControl(value['drpDataSelected'], ArrayData);
                else
                    reactiveFormData[value['controlName']] = new FormControl(value['columnValue'], ArrayData);

            });

            this.masterProductForm = that.fb.group(reactiveFormData);
            console.log(that.masterProductForm);
            this.isRender = true;

            this.spinner.hide();

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {

                this.ischeckboxshow = +this.masterJsonDataFull['formIsCheckbox'];
                if (this.masterJsonDataFull['formActionGroupList'] != null)
                    this.dropdownListActionGroupSpdrp = this.masterJsonDataFull['formActionGroupList'];
                console.log(this.ischeckboxshow);
                if(this.masterProductForm)     
                this.masterProductForm.valueChanges.subscribe((changedValue:any) => {
                    if(this.masterProductForm.invalid)
                    this.isRenderDetail = 0;
                    this.OnFormChangedModal();
                   
                  })
            });
    };

    drpRecursiveFunction(Index: number) {

        if (Index > -1) {
            let selectedData = this.masterJsonData[Index];
            this.masterJsonData[Index]['drpDataSet'] = [];
            this.masterJsonData[Index]['drpDataSelected'] = null;
            if (selectedData.drpEventinformation['child_column_name'] != "" && selectedData.drpEventinformation['child_column_name'] != null) {
                let nextIndex = this.masterJsonData.findIndex(d => d.columN_NAME.toLowerCase() == selectedData.drpEventinformation['child_column_name'].toLowerCase());
                if (nextIndex > -1)
                    this.drpRecursiveFunction(nextIndex);
            }
            return;
        }


    }

    onItemSelect(item: any, Index: number, multiselect: boolean) {
        // if(this.masterJsonData[Index].drpDataSelected)
        // this.masterJsonData[Index].drpDataSelected.push(item);
        // else{
        //     this.masterJsonData[Index].drpDataSelected = [];
        //     this.masterJsonData[Index].drpDataSelected.push(item);
        // }
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelect(item: any, Index: number, multiselect: boolean) {
        // if(this.masterJsonData[Index].drpDataSelected)
        // this.masterJsonData[Index].drpDataSelected.pop(item);
        
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectAll(item: any, Index: number, multiselect: boolean) {
        // if(this.masterJsonData[Index].drpDataSelected)
        // this.masterJsonData[Index].drpDataSelected.push(item);
        // else{
        //     this.masterJsonData[Index].drpDataSelected = [];
        //     this.masterJsonData[Index].drpDataSelected.push(item);
        // }
        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectAll(item: any, Index: number, multiselect: boolean) {
        // if(this.masterJsonData[Index].drpDataSelected)
        // this.masterJsonData[Index].drpDataSelected.pop(item);
        
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectEdit(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelect(item, Index, multiselect,this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm,true,true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectEdit(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect,this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm,true,true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectEditAll(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect,this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm,true,true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectEditAll(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm,true,true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    OnClose(item: any, controlName: string) {
        this.masterProductForm.get(controlName)?.markAsTouched();
    }


    OnCloseEdit(item: any, controlName: string) {
        this.masterProductFormEdit.get(controlName)?.markAsTouched();
    }
    onItemSelectChildAction(item: any) {

        this.OnViewFullDetail(item);

    }
    OnItemDeSelectChildAction(item: any) {


    }


    openSubmitModal() {
        this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.submitcall();
                    this.paramvaluedata = '';
                }
            });
    }
    FirstLetterLower(value:string) {
        return this.commonfunction.FirstLetterLower(value);
    }
    FirstLetterUpper(value:string) {

        return this.commonfunction.FirstLetterUpper(value);
    }
    ReplaceNullDataTable(value:string) {
        return this.commonfunction.ReplaceNullDataTable(value);
    }
    OnFormChangedModal() {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let objColumn = <lstObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';
        let GenericRptProcParams: string="";
        GenericRptProcParams = '';
        this.masterJsonData.forEach(function (value, index) {
            
            let objList = <ObjectParam>{};
            if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                let colValue;
                let CompareColumnValue;
                if (value["controlType"] == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"] == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue.length <= 0)
                        colValue = null;
                    else {
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                    }

                }
                else if (value["controlType"] == "checkbox") {
                    let chkValue = that.masterProductForm?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"] == "file") {
                    colValue = that.masterProductForm?.value[value["controlName"]]
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterProductForm?.value[value["controlName"]]);
                }
                else if (value["controlType"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterProductForm?.value[value["controlName"]]);
                }
                else {
                    colValue = that.masterProductForm?.value[value["controlName"]]
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        CompareColumnValue = $.trim(jQuery('#' + value["controlName"]).val().toString());
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue != null && colValue != "") {

                    GenericRptProcParams += '|' + value["columN_NAME"] + '=' + colValue;
                }

            }
        });

        this.paramvaluedata = GenericRptProcParams !="" ? this.SeltableID + GenericRptProcParams :"";        
    }

    OnSubmitModal() {
        
            this.isRenderDetail += 1;
    }
    CustomFilterusingColumn() {
        
        if (this.selectedItemsCustomColumnSearchdrp.length > 0) {
            $('#' + this.Detailtableid).DataTable()
                .column(this.selectedItemsCustomColumnSearchdrp[0].id)
                .search(this.customSearch)
                .draw();
        }
    }
    submitcall() {
        let that = this;
        let headerData:any = [];
        let dataRow = [];
        this.spinner.show();
        //   if(this.DetailtableHeaderData.length > 0)
        //   this.DetailtableHeaderData.splice(0, this.DetailtableHeaderData.length);
        if (this.DetailtableRowData.length > 0)
            this.DetailtableRowData.splice(0, this.DetailtableRowData.length);
        this.Divdisplay = "block";
        
       
        this.Detailtableid = 'EditDynamictable' + this.SeltableID.toString().replace(/\s/g, '');
        $('#' + this.Detailtableid).DataTable().destroy();


        //   this. resetDatatable();
        this.userService.getGenericReportData(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {

            that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? '1' : '0', itemName: 'S.No.' });
            this.spinner.hide();
            // console.log(datacom);
            if (datacom.table.length > 0) {
                // that.DetailtableHeaderData = [];
                // that.DetailtableRowData  = [];
                if (this.DetailtableHeaderData.length == 0) {
                    let headerdata = datacom.table.filter((x:any) => x['columnHeader'] != 'id' && x['columnHeader'] != 'param1' && x['columnHeader'] != 'param2');

                    $.each(headerdata, function (index: number, value: string) {

                        that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? (index + 2).toString() : (index + 1).toString(), itemName: value['columnHeaderShow'] });
                        headerData.push({ "key": value['columnHeader'], title: value['columnHeaderShow'].toUpperCase() });

                    });
                    this.DetailtableHeaderData = headerData;

                }
                dataRow = datacom.table1;
                if (this.ischeckboxshow) {
                    dataRow.forEach((obj:any) => {
                        obj.isSelected = false
                    });
                }
                this.DetailtableRowData = dataRow;
                console.log(that.DetailtableHeaderData);
                console.log(that.DetailtableRowData);
            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {


                // $('#' + this.Detailtableid).empty();

                this.dtTriggerDetail.next(true);
                if (this.ischeckboxshow == 2) {
                    this.masterSelected = true;
                    this.checkUncheckAll();
                }
                this.spinner.hide();
                //   this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            }
        );



    }

    resetForm() {
        this.paramvaluedata = "";
this.isRenderDetail = 0;
        this.Divdisplay = "none";
        this.masterProductForm?.reset();
        let filecomtrols = this.masterJsonData.filter(x => x.control.toLowerCase() == 'file');
        filecomtrols.forEach(function (value, index) {
            let btnfile;
            let fhdn;
            let BtnDownload;
            btnfile = '#btnfile_';
            fhdn = '#fhdn_';
            BtnDownload = '#BtnDownload_';
            $(btnfile + value['columN_NAME']).attr('hidden', 'hidden');
            $(BtnDownload + value['columN_NAME']).attr('hidden', 'hidden');

        });

    }
    exportAsXLSX(): void {
        
        let fileName = this.SeltableIDForm;
        let dataXL = this.DetailtableRowData;
        dataXL.forEach(obj => {
            delete obj['id'];
            delete obj['isSelected'];
            delete obj['param1'];
            delete obj['param2'];
        });
        //alert(fileName.toString())
        this.excelService.exportAsExcelFile(dataXL,dataXL, fileName.toString());
    }

    exportAsPDF(): void {
        let fileName = this.SeltableIDForm;
        // let PDFcolumns = [
        //     {title: "ID", key: "id"}, 
        //     {title: "Name", key: "name"}, 
        //     {title: "Country", key: "country"}, 
        //     {title: "Email", key: "email"}
        // ];
        // let PDFdata = [
        //     {"id": 1, "name": "Shaw", "country": "Tanzania", "email": "abrown@avamba.info"},
        //     {"id": 2, "name": "Nelson", "country": "Kazakhstan", "email": "jjordan@agivu.com"},
        //     {"id": 3, "name": "Garcia", "country": "Madagascar", "email": "jdean@skinte.biz"}
        // ];
        // console.log(PDFcolumns);
        // console.log(this.header);
        // console.log(PDFdata);
        // console.log(this.data)
        let dataXL = this.DetailtableRowData;
        dataXL.forEach(obj => {
            delete obj['id'];
            delete obj['isSelected'];
            delete obj['param1'];
            delete obj['param2'];
        });
        this.excelService.exportAsPDFFile(this.DetailtableHeaderData, dataXL, fileName.toString());
    }

    exportAsDoc(): void {
        let fileName = this.SeltableIDForm;
        // this.excelService.exportAsDoc(this.DetailtableHeaderData, this.DetailtableRowData, this.DetailtableHeaderData.length, this.DetailtableRowData.length, fileName.toString());
    }


    checkUncheckAll() {

        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            value.isSelected = that.masterSelected;
        });
        this.getCheckedItemList();
    }
    isAllSelected() {
        this.masterSelected = this.DetailtableRowData.every(function (item: any) {
            return item.isSelected == true;
        })
        this.getCheckedItemList();
    }

    getCheckedItemList() {
        this.selectedRows = [];
        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            if (value.isSelected) {
                that.selectedRows.push(value);
            }
        });
    }

    sendMessageArrayList() {

    }
    sendEmailArrayList() {

    }
    // onActionArrayList() {

    //     let that = this;
    //     let flag = true;
    //     let msg = '';
    //     let _data;
    //     let tableNamefor;
    //     let objColumn = <lstObjectParam>{};
    //     let genericColumnList:any = [];
    //     let mylist = '[';
    //     let GenericRptProcParams: string="";
    //     GenericRptProcParams = '';
    //     console.log(this.DetailtableRowData);
    //     this.DetailtableRowData.forEach(function (value, index) {

    //         let objList = <customAction>{};
    //         objList.rowId = value["id"];
    //         objList.isChecked = value["isSelected"];
    //         objList.param1 = value["param1"];
    //         objList.param2 = value["param2"];
    //         genericColumnList.push(objList);

    //     });

    //     if (genericColumnList.length > 0) {
    //         objColumn.genericColumnList = genericColumnList;
    //         console.log(genericColumnList);
    //         _data = JSON.stringify(objColumn);
    //         this.openCustomActionModal();
    //         this.paramvaluedata = _data;
    //     }

    // }

    // openCustomActionModal() {
    //     this.globalConfirmService.ConfirmFunction("Confirm Action !!", "Are you sure you want to proceed?", "Yes", "No")
    //         .then((confirmed) => {
    //             if (confirmed) {
    //                 this.custumActioncall();
    //                 this.paramvaluedata = '';
    //             }
    //         });
    // }

    // custumActioncall() {

    //     this.spinner.show();
    //     this.userService.CustumActionSubmitForm(this.paramvaluedata, this.childActionParam, this.SeltableIDForm).subscribe((datacom: any) => {
    //         this.spinner.hide();

    //         if (datacom != "") {
    //             let resultarray = JSON.parse(datacom);
    //             // resultarray['successFlag'] 
    //             // resultarray['resultMessage']
    //             // resultarray['printAction']
    //             // resultarray['printActionResult']
    //             // resultarray['otherAction']
    //             // resultarray['otherActionResult']
    //             // resultarray['outputVarResult']

    //             this.masterSelected = false;
    //             this.checkUncheckAll();
    //             this.resetForm();
    //             if (this.selectedItemsActionGroupSpdrp.length > 0)
    //                 this.selectedItemsActionGroupSpdrp.splice(0, this.selectedItemsActionGroupSpdrp.length);
    //             if (resultarray['successFlag'] == "success") {
    //                 // this.masterProductForm.reset();
    //                 this.AlertTitle = "Success!!";
    //                 this.AlertMessage = resultarray['resultMessage'].toString();
    //                 this.AlertClass = "NP_emoji-success-bg";
    //                 this.AlertAnimationType = "smile";
    //                 this.AlertType = "NP_popup_success";
    //             }

    //             else {
    //                 this.AlertTitle = "Error!!";
    //                 this.AlertMessage = resultarray['resultMessage'].toString();
    //                 this.AlertClass = "NP_emoji-fail-bg";
    //                 this.AlertAnimationType = "sad";
    //                 this.AlertType = "NP_popup_fail";
    //             }

    //         }
    //     },
    //         (err: HttpErrorResponse) => {
    //             this.spinner.hide();
    //             if (err.status == 401) {
    //                 this.AlertTitle = "Error!!";
    //                 this.AlertMessage = "You are not authorized!";
    //                 this.AlertClass = "NP_emoji-fail-bg";
    //                 this.AlertAnimationType = "sad";
    //                 this.AlertType = " NP_popup_fail";
    //             }
    //             else if (err.status == 403) {
    //                 this.Customvalidation.loginroute(err.status);
    //             }
    //             else {
    //                 this.AlertTitle = "Error!!";
    //                 this.AlertMessage = err.message.toString();
    //                 this.AlertClass = "NP_emoji-fail-bg";
    //                 this.AlertAnimationType = "sad";
    //                 this.AlertType = " NP_popup_fail";
    //             }
    //             this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    //         },
    //         () => {
    //             this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    //         }
    //     );
    // }

    OnViewFullDetail(event:any) {

        this.spinner.show();
        var trdata = '';
        const that = this;
        // this.editModelCallfrom = callfrom;
        // if (callfrom == "update")
        //     this.editModelHeading = "Update Record";
        // else 
        this.editModelHeading = "Provide " + this.selectedItemsActionGroupSpdrp[0].itemName.toString() + " Values";
        this.userService.getGenericReportFormPage(this.selectedItemsActionGroupSpdrp[0].id.toString(), this.formName, 'update').subscribe((data: any) => {

            let reactiveFormData = {};
            let chkAsyncGenericDbCheck: boolean = false;
            this.masterJsonDataEdit = data['formData'];
            if (this.masterJsonDataEdit != null) {
                this.masterJsonDataEdit.forEach(function (valueEdit, index) {

                    let ArrayData: ValidatorFn[] = [];
                    let checkExsistAsync: AsyncValidatorFn[] = [];
                    if (valueEdit["identityColumn"].toLowerCase() != "yes" && valueEdit["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                        let requiredMsg = '';
                        let sizeMsg = '';
                        let customMsg = '';
                        if (valueEdit["customValidationMsg"] != "" && valueEdit["customValidationMsg"] != null) {
                            let customValidationMsg = JSON.parse(valueEdit["customValidationMsg"]);
                            requiredMsg = customValidationMsg['requiredMsg'];
                            sizeMsg = customValidationMsg['sizeMsg'];
                            customMsg = customValidationMsg['customMsg'];
                        }
                        if (valueEdit['iS_NULLABLE'].toLowerCase() == "no") {
                            ArrayData.push(custumValidation['validateRequired'](requiredMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                        }
                        if ((valueEdit['datA_TYPE'] != "date" && valueEdit['datA_TYPE'] != "datetime" && valueEdit['datA_TYPE'] != "bit")) {

                            ArrayData.push(custumValidation['validateSize'](sizeMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                        }
                        if (valueEdit['validations'] != "" && valueEdit['validations'] != null) {

                            let ValName = "'" + valueEdit['validations'] + "'"
                            ArrayData.push(custumValidation[eval(ValName)](customMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));

                        }

                        if (valueEdit['checkDataExists'] != "" && valueEdit['checkDataExists'] != null && valueEdit['checkDataExistsParam'] != "" && valueEdit['checkDataExistsParam'] != null) {
                            let columnValueEdit;
                               if(valueEdit['controlType'].toLowerCase()=="dropdown"){
                                columnValueEdit = valueEdit['drpDataSelected'] !="" && valueEdit['drpDataSelected'] != null && valueEdit['drpDataSelected'] != undefined ? valueEdit['drpDataSelected'][0]['id'] : "";
                               }
                               else if(valueEdit['controlType'].toLowerCase()=="date" || valueEdit['controlType'].toLowerCase()=="datetime"){
                                columnValueEdit = valueEdit['columnValue'] !="" && valueEdit['columnValue'] != null && valueEdit['columnValue'] != undefined ? new Date(valueEdit['columnValue']) : null;
                               }
                               else{
                                columnValueEdit = valueEdit['columnValue']; 
                               }
                            if (valueEdit['checkDataExists'] == 1) {
                                let sqlParams = valueEdit['checkDataExistsParam'].split('%');
    
                                checkExsistAsync.push(that.asyncCustomvalidation['validateValueInDBExists_Check'](valueEdit['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))
    
                            }
                            else  if (valueEdit['checkDataExists'] == 3) {
                                let sqlParams = valueEdit['checkDataExistsParam'].split('%');
                                chkAsyncGenericDbCheck = true;
                                checkExsistAsync.push(that.asyncCustomvalidation['validateValueInGenericDBExists_Check'](valueEdit['controlType'],"E",valueEdit['columN_NAME'],valueEdit['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))
    
                            }
                            else if (valueEdit['checkDataExists'] == 2) {
                                let arrayParams = valueEdit['checkDataExistsParam'].split('%');
                                // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                // ArrayData.push(custfunction);
    
                                ArrayData.push(custumValidation['validateValueInArrayExists_Check'](valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString(), eval(arrayParams[0]), arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));
    
    
                            }
    
                        }
                    }
                    if (valueEdit['controlType'].toString().toLowerCase() == 'dropdown')
                        reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                    else if (valueEdit['controlType'].toString().toLowerCase() == 'mdropdown')
                        reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

                    else if (valueEdit['controlType'].toString().toLowerCase() == 'datetime') {
                        //console.log(moment(valueEdit['columnValue']).format(that.dateFormat));
                        reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? new Date(valueEdit['columnValue']) : '', disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                    }
                    else if (valueEdit['controlType'].toString().toLowerCase() == 'checkbox') {

                        reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? valueEdit['columnValue'] : false, disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                    }
                    else
                        reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

                });
                this.masterProductFormEdit = this.fb.group(reactiveFormData);

                console.log(this.masterJsonDataEdit);
                console.log(this.masterProductFormEdit);
            }
            this.spinner.hide();
            if(chkAsyncGenericDbCheck){
                this.masterProductFormEdit.valueChanges.pipe().subscribe((res: FormGroup) =>{
                    this.commonfunction.setFormLatestValue(this.masterProductFormEdit,"E");
                });
            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();

                console.log(err.message);
                if (err.status == 401) {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                if (this.masterJsonDataEdit != null) {
                    this.isRenderEdit = true;
                    this.onOpenEditMasterModal();
                }
                else {
                    this.childActionParam = this.selectedItemsActionGroupSpdrp[0].id.toString();

                }
            });
    }

    updateEditMasterForm() {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let objColumn = <lstObjectParam>{};
        let genericColumnList:any = [];
        let mylist = '[';
        let genericSPParams = '';

        this.masterJsonDataEdit.forEach(function (value, index) {

            let objList = <ObjectParam>{};
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let colValue;
                let CompareColumnValue;
                if (value["controlType"].toLowerCase() == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductFormEdit?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"].toLowerCase() == "checkbox") {
                    let chkValue = that.masterProductFormEdit?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"].toLowerCase() == "file") {
                    colValue = that.masterProductFormEdit?.value[value["controlName"]]
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterProductFormEdit?.value[value["controlName"]]);
                }
                else {
                    colValue = that.masterProductFormEdit?.value[value["controlName"]]
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        CompareColumnValue = $.trim(jQuery('#' + value["controlName"]).val().toString());
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue != null || colValue != "") {
                    genericSPParams = genericSPParams + '@' + value["columN_NAME"] + " = '" + colValue + "',";
                }

            }
        });
        if (genericSPParams != "") {

            objColumn.genericColumnList = genericColumnList;
            _data = JSON.stringify(objColumn);
            this.childActionParam = this.selectedItemsActionGroupSpdrp[0].id.toString() + " " + genericSPParams;

        }
        else {
            this.childActionParam = this.selectedItemsActionGroupSpdrp[0].id.toString();
        }
        this.onCloseEditMasterModal(1);

    }

    onCloseEditMasterModal(callfrom: number) {
        if (callfrom == 0)
            this.childActionParam = this.selectedItemsActionGroupSpdrp[0].id.toString();
        $('#EditMasterModal').hide();
        this.display = "none";
    }
    onOpenEditMasterModal() {
        $('#EditMasterModal').show();
        this.display = "block";
    }

    calanderClick(inputControl:any) {
        $('#' + inputControl).click();
    }

}
