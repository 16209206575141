<!-- start submit Modal -->
<div id="ConfirmModal" class="edit-form-modal priview-capture-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
        <div class="modal-content border-0 edit-modal-form">
            <div class="modal-header">

                <h2 class="modal-title pull-left">{{title}}</h2>
                <button type="button" class="close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                        aria-hidden="true">&times;</span></button>

            </div>


            <div class="modal-body">

                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 snapshot">
                            <div class="video-capture position-relative">
                                <div class="capture-img">
                                    <img width="100%" [src]="ImageUrl" />
                                    <!-- <ngx-image-zoom [thumbImage]=ImageUrl [fullImage]=ImageUrl
                                        
                                       ></ngx-image-zoom> -->
                                </div>

                            </div>
                        </div>

                    </div>



                </div>
            </div>
            <div class="modal-footer">
                <div class="mt-2 text-center">
                    <div class="d-inline-block align-middle">
                        <button type="button" class="btn btn-secondry"
                            (click)="onCloseConfirmModal()">{{btnCancelText}}</button>
                        <button type="button"  class="btn btn-primary ml-2"
                            (click)="onAcceptConfirmModal()">{{btnOkText}}</button>
                    </div>
                </div>


            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->

<!-- End submit Modal -->