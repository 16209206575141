import { Component, NgModule, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Commonfunction } from 'src/app/shared/commonutility';


@Component({
  selector: 'app-public-generic-landing-page',
  templateUrl: './public-generic-landing-page.component.html',
  styleUrls: ['./public-generic-landing-page.component.css']
})
export class PublicGenericLandingPageComponent implements OnInit {
  //islogin: boolean = false;

  galleryphoto:any;
  homePagedata:any;
  constructor(public commonfunction:Commonfunction) {
      const alldatafile = this.commonfunction.loadJson("~/../assets/homePage.json");
      this.homePagedata = alldatafile[0];
      // console.log(this.homePagedata );

      const gallery = this.commonfunction.loadJson("~/../assets/sclgallery.json");
      this.galleryphoto = gallery[0].school_gallery
      // console.log(this.galleryphoto);
      
      
  }

  ngOnInit(): void {


  }


}
