<!-- start Alert Modal -->
<div id="AlertDialogModal" class="new-modal-box" [class]="AlertDialogType ">
  <div role="document" class="new-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <div [class]="AlertDialogClass">
           
          </div> -->
        <h2 class="modal-title">{{DialogTitle}}</h2>
        <button type="button" class="close" aria-label="Close" (click)="onCloseAlertModal()">
          <span aria-hidden="true">&times;</span></button>

      </div>
      <div class="modal-body">
        <div id="DivtableViewData" class="table-responsive" style="height: 410px;width:100%">
          <table style="width:100%" class="table table-bordered row-border hover">
            <thead>
              <tr style="color:#ffffff; background-color:#0479cc !important;">
                <th>S.No.</th>
                <ng-container *ngFor="let headerDetailData of ViewDetailChildtableHeaderData; let i=index;">
                  <th
                    *ngIf="ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid'">

                    {{FirstLetterUpper(headerDetailData['columnAliasName'])}}

                  </th>
                </ng-container>



              </tr>

            </thead>
            <tbody>
              <ng-container *ngIf="ViewDetailChildtableRowData.length > 0">
                <tr *ngFor="let rowDetailData of ViewDetailChildtableRowData; let rowi=index;">
                  <td>{{(rowi + 1)}}</td>

                  <ng-container *ngFor="let headerDetailData of ViewDetailChildtableHeaderData; let i=index">
                    <ng-container
                      *ngIf="ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['controlType']) != 'hid' && ConvertStringLowerCase(headerDetailData['controlType']) != 'file'; else elsedetailTableFile">
                      <td>
                        <div appEllipsifyLongText>
                          {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}
                        </div>
                      </td>
                    </ng-container>
                    <ng-template #elsedetailTableFile>
                      <ng-container
                        *ngIf="ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && ConvertStringLowerCase(headerDetailData['controlType']) != 'hid' && ConvertStringLowerCase(headerDetailData['controlType']) == 'file'">
                        <td class="file-dowmload">
                          <a href="javascript:void(0)" class='active'
                            [hidden]="ReplaceNullDataTable(rowDetailData[headerDetailData['columN_NAME']]) =='-'"
                            (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowDetailData[headerDetailData['columN_NAME']]),headerDetailData['columnAliasName'])"
                            ngbTooltip="Download {{headerDetailData['columnAliasName']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue">
                            <i class='fa fa-download'></i></a>
                          <a href="javascript:void(0)" class='active'
                            [hidden]="ReplaceNullDataTable(rowDetailData[headerDetailData['columN_NAME']]) =='-'"
                            (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowDetailData[headerDetailData['columN_NAME']]),headerDetailData['columnAliasName'])"
                            ngbTooltip="Open {{headerDetailData['columnAliasName']}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue">
                            <i class='fa fa-file-image-o'></i></a>
                        </td>
                      </ng-container>
                    </ng-template>
                  </ng-container>



                  <!-- <ng-container *ngFor="let headerDetailData of ViewDetailChildtableHeaderData; let i=index;">
                      
                          <td
                            *ngIf="ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && ConvertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid'  && ConvertStringLowerCase(headerDetailData['controlType']) != 'file' && ConvertStringLowerCase(headerDetailData['controlType']) != 'childtable'">
      
                            {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}
      
                          </td>
                      
                    </ng-container>               -->
                </tr>
              </ng-container>
              <ng-container *ngIf="ViewDetailChildtableRowData.length == 0">
                <tr>
                  <td [attr.colspan]="(ViewDetailChildtableHeaderData.length + 1)">No Data Found</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="NP_popup-footer text-right mt-2">
        <div class="">
          <p><a href="javascript: void(0);" class="btn btn-secondry" ngbAutofocus
              (click)="onCloseAlertModal()">{{btnOkText}}</a></p>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
<!-- End Alert Modal -->