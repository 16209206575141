import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { async } from '../../../../node_modules/@angular/core/testing';
import { custumValidation } from "../../shared/custum-validation";
import { Customvalidation } from "../../shared/Validation";
import { asyncCustumValidation } from "../../shared/async-custum-validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { IDropDownListObject } from "../../shared/model/generic-model";

import { NgxSpinnerService } from 'ngx-spinner';
import { UserObjectParam, lstUserObjectParam, ObjectParam, lstObjectParam, UserReportObjectParam, MultiTableEntries, lstFormObjectParam, FormObjectParam, lstFormFinalObjectParam, FormFinalObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from '../../../../node_modules/angular-datatables';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { IFilterColumns, IviewFullDetails } from '../../shared/model/genericTableListViewModel';
// import  'src/typings';
import 'jquery';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
declare let jQuery: any;



@Component({
  selector: 'app-generate-custum-form',
  templateUrl: './generate-custum-form.component.html',
  styleUrls: ['./generate-custum-form.component.css']
})
export class GenerateCustumFormComponent implements OnDestroy, OnInit {
  DivMainShow:boolean = false;
  totalRecords:number = 0;
  pageSize:number = 10;
  currentPage:number = 1;
  sortOrder: string ="ASC";
  sortColumn:string = "1";
  popoverEvent:any;
  activeTabId: number = 1;
  filterFormValue: IFilterColumns= {} as IFilterColumns;
  filterColumnName: string = ""; 
  filterColumnDataType:string = ""
  filterColumnParentTableName:string = ""
  filterColumnDisplayName: string = "";
  filterColumnQuery: IFilterColumns[] = [];  
  filterForm!: FormGroup;
  filterDisplayQuery: Array<{ columnName: string, displayQuery: string, operator: string }> =[];
  filterActualQuery: Array<{ columnName: string, columnValue: string, conditionOperator: string , filterColConditionOperator:string}> = [];
  tableCols:any[] = [
    {key:'formName',displayName:'Form Name'},
    {key:'userRole',displayName:'User Role'},
  
  ]; 

  SeltableID: string="";
    SeltableIDForm: string="";
  mastertableForm: FormGroup;
 childtableForm: FormGroup;
 mastertableFormE: FormGroup;
 childtableFormE: FormGroup;
  @ViewChild(DataTableDirective) 
  dtElement!: DataTableDirective;
  validationdrpOption: string = '';
  controldrpOption: string = '';
  controldrpOptionDate: string = '';
  controldrpOptionNumber: string = '';
  valueFillTypedrpOption: string = '';
  MailTemplatedrpOption: string = 'testtt';
  wfdrpOption: string = '';
  PageStyledrpOption: string = '';
  controldrpOption2: string = '';
  validationdrpOption2: string = '';
  dtOptions: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    //select from drop down list to select number of records per page
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 300,
    scrollX: true,
    scrollCollapse: true,
    fixedHeader: {
      header: true,
      footer: true
    },
    // Declare the use of the extension in the dom parameter
    dom: 'Bfltip',

    // Configure the buttons
    buttons: ['print', 'excel']

  };
  dtOptionsEdit: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    //select from drop down list to select number of records per page
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 400,
    scrollX: true,
    scrollCollapse: true,
    // bDestroy: true,
    fixedHeader: {
        header: true,
        footer: true
    },
    emptyMessage: "No data available!!",
    // fixedColumns:   {
    //     leftColumns: 2
    // }


  };
  viewtableHeaders: TableColumnName[] = []; 
  dtTrigger: Subject<any> = new Subject();
  dtTriggerEdit: Subject<any> = new Subject();
  mainTableId: string="";
  mainTableIdE: string="";
  selectedTableId: string="";
  tableData:any;
  viewTableData:any;
  editTableData:any;
  detailTableData:any;
  tableid: string="";
  Edittableid: string="";
  display = "none";
  sel: any;
  callfor: string="";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "NP_popup_success";
  AlertAnimationType: any = "smile";
  tblecount: number=0;
  public value: string[]=[];
  public current: string="";
  public Validationcurrent: string="";
  public selected: string="";
  public dynamicdivdata: any;
  public exampleArray: Array<IDropDownListObject>=[] as Array<IDropDownListObject>;
  
  dropdownListMasterTable = [];
  selectedItemsMasterTable: Array<IDropDownListObject> = [] as Array<IDropDownListObject>; 
  dropdownChildTabledrp = [];
  selectedItemsdropdownChildTabledrp: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
  selectedItemsdropdownChildTabledrpE: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
  dropdownChildTableIsReq = [{ id: "0", itemName: 'Not Required' },
  { id: "1", itemName: 'Required' }];
  selectedItemsdropdownChildTableIsReq: Array<IDropDownListObject> = [ { id: "1", itemName: 'Required' }] as Array<IDropDownListObject>;
  selectedItemsdropdownChildTableIsReqE: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
  dropdownChildTableSumColumn = [];
  selectedItemsdropdownChildTableSumColumn: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
  selectedItemsdropdownChildTableSumColumnE: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
  dropdownListRole = [];
  selectedItemsRole: Array<IDropDownListObject> = [] as Array<IDropDownListObject>;
   selectedItems: string = '';
  validationselectedItems = [];
  dropdownSettings = {};
  dropdownMultiSelectSettings = {};
  dropdownGridSettings = {};
  ValidationdropdownSettings = {};
  changeDetectorRefs: ChangeDetectorRef[] = [];
  paramvaluedata: any;
  column_Data: any[]=[];
  param: any;
  menulabel: string="";
  formlable: string="";
  formValuesData: Array<MultiTableEntries> = [];
  formValuesDataE: Array<MultiTableEntries> = [];
  currentRoleString: string | undefined = '';
  currentRole: string  ="";
  userId: string| undefined  ="";
  constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation,public asyncCustomvalidation: asyncCustumValidation, private userService: UserService,
    private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, private spinner: NgxSpinnerService, private fb: FormBuilder,
    private http: HttpClient, private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService) {
      this.mastertableForm = this.fb.group({
        userRole: ['', [custumValidation['validateRequired']("","","varchar","drop","User Role","","","")]],
        formName: ['',  [custumValidation['validateRequired']("","","varchar","text","Form Name","","",""),custumValidation['validatealphanumeric']("","","varchar","text","Form Name","","",""),custumValidation['validateSize']("","50","varchar","text","Form Name","","","")]],
        tableName: ['', [custumValidation['validateRequired']("","","varchar","drop","Master Table","","","")]],
        formAliasName: ['', [custumValidation['validateRequired']("","","varchar","text","Master Table Alias","","",""),custumValidation['validatealphanumericwithoutspace']("","","varchar","text","Master Table Alias","","",""),custumValidation['validateSize']("","50","varchar","text","Master Table Alias","","",""),custumValidation['validateValueInArrayExists_Check']("","varchar","text","Master Table Alias","","","",this.formValuesData,'tableAliasName',true,true,"","id")]]
      }
      );

      this.childtableForm = this.fb.group({       
        childTableName: ['', [custumValidation['validateRequired']("","","varchar","drop","Child Table","","",""),custumValidation['validateValueInArrayExists_Check']("","varchar","drop","Child Table Name","","","",this.formValuesData,'tableName',true,true,"","id")]],
        childTableAliasName: ['', [custumValidation['validateRequired']("","","varchar","text","Child Table Alias","","",""),custumValidation['validatealphanumericwithoutspace']("","","varchar","text","Child Table Alias","","",""),custumValidation['validateSize']("","50","varchar","text","Child Table Alias","","",""),custumValidation['validateValueInArrayExists_Check']("","varchar","text","Child Table Alias Name","","","",this.formValuesData,'tableAliasName',true,false,"","id")]],
        childTableIsRequired: ['1',[custumValidation['validateRequired']("","","varchar","drop","Is Required","","","")]],
        childTablesumColumn: [''],
        childTableCustomEvent: ['']
      }
      );

      this.mastertableFormE = this.fb.group({
        formNameE: [''],
        tableNameE: [''],
        formAliasNameE: ['']
      }
      );

      this.childtableFormE = this.fb.group({       
        childTableName: [''],
        childTableAliasName: [''],
        childTableIsRequired: ["1"],
        childTablesumColumn: [''],
        childTableCustomEvent: ['']
      }
      );

      this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))? sessionStorage.getItem('currentRole')?.toString()  : "";
      this.currentRole = this.currentRoleString != null ? JSON.parse(this.currentRoleString)?.roleId.toString():"";
      this.userId= sessionStorage.getItem('userId')?.toString();

  }
 testformData() {
  let that = this;
    console.log(this.mastertableForm);
    Object.keys(this.mastertableForm.controls).forEach(key => {
      const controlErrors: ValidationErrors | null | undefined = that.mastertableForm.get(key) != null ? that.mastertableForm.get(key)?.errors: null;
      if (controlErrors != null && controlErrors != undefined) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });

    // this.userService
    //     .getcheckFuncionName("Naveen", '').subscribe((res: any) => {
    //       console.log(res);
    //     // .pipe(
    //     //   map(res => {
    //     //     return res === (null || '') ? null : { GenericError: 'Form Name Already Exits. Please Choose Another Form Name.'};
    //     //   })
    //     // );
    //     });
  }
  testChildformData() {
    
     console.log(this.childtableForm);
     Object.keys(this.childtableForm.controls).forEach(key => {
       const controlErrors: ValidationErrors | null | undefined = this.childtableForm.get(key) != null  ? this.childtableForm.get(key)?.errors: null;
       if (controlErrors != null && controlErrors != undefined) {
         Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
         });
       }
     });
 
     // this.userService
     //     .getcheckFuncionName("Naveen", '').subscribe((res: any) => {
     //       console.log(res);
     //     // .pipe(
     //     //   map(res => {
     //     //     return res === (null || '') ? null : { GenericError: 'Form Name Already Exits. Please Choose Another Form Name.'};
     //     //   })
     //     // );
     //     });
   }
  ngOnInit() {
    
    // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
    let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
    this.SeltableID = paramjs.formValue;
    this.SeltableIDForm = paramjs.formName;
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.pageloaddrp();

   
    this.onMainTab();
    this.DivMainShow = false;
   
    $('#DivMainEdit').hide();
    this.dropdownSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
    this.dropdownMultiSelectSettings = {
      singleSelection: false,
      text:"Select Column",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
    this.dropdownGridSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTriggerEdit.unsubscribe();
  }

  OnClose(item: any, controlName: string,formName: string) {
    
    if(formName == "mastertableForm"){
    this.mastertableForm.get(controlName)?.markAsTouched();
    }
    else if(formName == "childtableForm"){
      this.childtableForm.get(controlName)?.markAsTouched();
    }
    else if(formName == "mastertableFormE"){
      this.mastertableFormE.get(controlName)?.markAsTouched();
    }
    else if(formName == "childtableFormE"){
      this.childtableFormE.get(controlName)?.markAsTouched();
    }

}
  pageloaddrp() {
    let item = '';
    let itemMulti = '';
    
    let param ="uspAppGenericGetAllRole";
    this.userService.getCommonDrpdownList(this.SeltableIDForm.toString(),param).subscribe((res: any) => {
      this.dropdownListRole = res;
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);         
          return false;
        }
        return true;
      },
      ()=>{
        
      });

  }
  onMainTab() {   
    $('#Formheading').html('Enter Form Details');
    $('SeltableIDEdit').val('');
    this.paramvaluedata = '';   
    $('#DivMainEdit').hide();    
    $('#divAddContainer').show();
    
    this.DivMainShow = false;
  }
  onViewTab() {
    
    this.SearchEdit(1);
   
    $('#divAddContainer').hide();
    $('#tblHeadEdit').hide();    
    this.paramvaluedata = '';
    $('#tblHead').html('');
   
    this.DivMainShow = false;
    this.selectedItemsMasterTable = [] as Array<IDropDownListObject>;
  }
  onItemSelectRole(item: any) {
    
    let that = this;
    let param ="uspAppGenericGetTableNamesWithAlias|roleId="+  this.selectedItemsRole[0].id.toString();
    this.userService.getCommonDrpdownList(this.SeltableIDForm.toString(),param).subscribe((res: any) => {
     
      this.dropdownListMasterTable = res;
      if(this.selectedItemsMasterTable.length > 0)
      {
        this.selectedItemsMasterTable = [] as Array<IDropDownListObject>;
      }
     
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      ()=>{
        that.mastertableForm.controls.formName.patchValue('');
        that.mastertableForm.controls.formName.setAsyncValidators([that.asyncCustomvalidation.validateValueInDBExists_Check("txt","uspAppGenericCheckFormName","userRoleId!formName","msg", '',that.selectedItemsRole[0].id.toString()).bind(this.asyncCustomvalidation)]);
        that.mastertableForm.controls.formName.updateValueAndValidity();
      });
  }
  OnItemDeSelectRole(item: any) {

      if(this.dropdownListMasterTable.length > 0)
      {
        this.dropdownListMasterTable.splice(0,this.dropdownListMasterTable.length)
      }
      if(this.selectedItemsMasterTable.length > 0)
      {
        this.selectedItemsMasterTable = [] as Array<IDropDownListObject>;
      }
     
      this.mastertableForm.controls.formName.patchValue('');
      this.mastertableForm.controls.formName.setAsyncValidators(null);
      this.mastertableFormE.controls.formName.updateValueAndValidity();
  
  

  }
onAddclick() {
      const that = this;   
      this.userService.getchildTableName(this.selectedItemsMasterTable[0].id.toString(), this.SeltableIDForm, this.selectedItemsRole[0].id.toString()).subscribe((data: any) => {
         this.dropdownChildTabledrp = data.table;  
       
        this.DivMainShow = true;
      },
      (err: HttpErrorResponse) => {          
          this.Customvalidation.loginroute(err.status);
      },
      ()=>{
        let obj = <MultiTableEntries>{};
        obj.baseTable = 'P2PtblGenericFormDetails';
        obj.formName = $.trim(this.mastertableForm.get('formName')?.value.toString());
        obj.isRequired = "1";
        obj.parentTableName = "";
        obj.tableAliasName = $.trim(this.mastertableForm.get('formAliasName')?.value.toString());
        obj.tableName = this.selectedItemsMasterTable[0].id;   
        obj.sumColumn = '';     
        this.formValuesData.push(obj);
        this.dropdownGridSettings = { 
          singleSelection: true,
          // text:"Select Countries",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          // primaryKey:'DrpValue',
          // labelKey:'DrpOption',
          classes: "global-select-box",
          disabled: true
        };
      });
    
  };

  onAddChildClick(callFrom: string) {
    
    const that = this;       
      let obj = <MultiTableEntries>{};       
      if(callFrom == 'P'){
        obj.baseTable = 'P2PtblGenericFormDetails';
      obj.formName = $.trim(this.mastertableForm.get('formName')?.value.toString());
      obj.isRequired = this.selectedItemsdropdownChildTableIsReq[0].id; 
      obj.parentTableName = this.selectedItemsMasterTable[0].id; 
      obj.tableAliasName = $.trim(this.childtableForm.get('childTableAliasName')?.value.toString());
      obj.tableName = this.selectedItemsdropdownChildTabledrp[0].id; 
      obj.customEvent = this.childtableForm.get('childTableCustomEvent')?.value != null && this.childtableForm.get('childTableCustomEvent')?.value != undefined ? $.trim(this.childtableForm.get('childTableCustomEvent')?.value.toString()):"";
      let sumcolumlist='';

      if(this.selectedItemsdropdownChildTableSumColumn.length >0 )
      {
        this.selectedItemsdropdownChildTableSumColumn.forEach(function(valuecolumn,index){
          sumcolumlist = sumcolumlist + valuecolumn.id +","
        });
        sumcolumlist = sumcolumlist.slice(0, -1); 
      }
      obj.sumColumn =this.selectedItemsdropdownChildTableSumColumn.length >0 ? sumcolumlist : '';
        this.formValuesData.push(obj);  
        this.onSubmitChildReset();
      } 
      else if(callFrom == 'E'){
        obj.baseTable = 'P2PtblGenericFormDetails';
      obj.formName = $.trim(this.mastertableFormE.get('formNameE')?.value.toString());
      obj.isRequired = this.selectedItemsdropdownChildTableIsReqE[0].id; 
      obj.parentTableName = this.formValuesDataE[0].tableName; 
      obj.tableAliasName = $.trim(this.childtableFormE.get('childTableAliasName')?.value.toString());
      obj.tableName = this.selectedItemsdropdownChildTabledrpE[0].id; 
      obj.customEvent = this.childtableFormE.get('childTableCustomEvent')?.value != null && this.childtableFormE.get('childTableCustomEvent')?.value != undefined ? $.trim(this.childtableFormE.get('childTableCustomEvent')?.value.toString()):"";
      let sumcolumlist=''; 
      if(this.selectedItemsdropdownChildTableSumColumnE.length >0 )
      {
        this.selectedItemsdropdownChildTableSumColumnE.forEach(function(valuecolumn,index){
          sumcolumlist = sumcolumlist + valuecolumn.id +","
        });
        sumcolumlist = sumcolumlist.slice(0, -1); 
      }
      obj.sumColumn = this.selectedItemsdropdownChildTableSumColumnE.length >0 ? sumcolumlist: '';
      obj.formNameId = this.formValuesDataE[0].formNameId;
      obj.id = "0";
        this.formValuesDataE.push(obj);  
        this.onEditChildReset();
      }     
      
  
};

  openResetModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset? you will losse all below data.", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.OnResetModal();
        }
      });
  }
 
  OnResetModal() {
    
   
   
    // $('SeltableIDEdit').val('');
    this.paramvaluedata = '';  
    if(this.selectedItemsRole?.length>0)
    this.selectedItemsRole=[] as Array<IDropDownListObject>; 
    console.log(this.selectedItemsRole)
    if(this.dropdownListMasterTable!== null)
    this.dropdownListMasterTable.splice(0,this.dropdownListMasterTable.length);
    if(this.selectedItemsMasterTable?.length >0)
    this.selectedItemsMasterTable = [] as Array<IDropDownListObject>;
    if(this.selectedItemsdropdownChildTabledrp?.length >0 )
    this.selectedItemsdropdownChildTabledrp = [] as Array<IDropDownListObject>;
    if(this.formValuesData?.length >0)
    this.formValuesData=[];
    console.log(this.formValuesData);
    if(this.selectedItemsdropdownChildTabledrpE?.length >0)
    this.selectedItemsdropdownChildTabledrpE = [] as Array<IDropDownListObject>;
    if(this.formValuesDataE !== null)
    this.formValuesDataE.splice(0,this.formValuesDataE.length);
    this.dropdownGridSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box",
      disabled: false
    };
    this.mastertableForm.reset();
    this.onSubmitChildReset();
    this.mastertableFormE.reset();
    this.onEditChildReset();
    this.DivMainShow = false;
   
  }
  openSubmitModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.submitcall();
          this.paramvaluedata = '';
        }
      });
  }

  OnSubmitModal() {
    
        let that = this;
        let flag = true;
        let _data;
        let msg = '';
        let errormsg = '';
        let objColumn = <lstFormObjectParam>{};
        let genericColumnList: any[] = [];
        let mylist = '[';
        let userRoleId = '';
        if (this.selectedItemsRole.length > 0) {
          userRoleId = this.selectedItemsRole[0].id;
        }
        this.formValuesData.forEach(function (value, index) {
            let objList = <FormObjectParam>{};
            objList.BaseTable = 'P2PtblGenericFormDetails';
            objList.formName = value.formName;
            objList.sequence = (index + 1).toString();
            objList.tableName = value.tableName;
            objList.parentTable = value.parentTableName;
            objList.tableAlias = value.tableAliasName;
            objList.sumColumn = value.sumColumn
            objList.isRequired = value.isRequired;
            objList.formNameId = 'ReplaceFormID';
            objList.userRoleId = userRoleId;
            objList.customEvent = value.customEvent;
            genericColumnList.push(objList);

        });

        let objColumnfinal = <lstFormFinalObjectParam>{};
        let genericColumnListParam: any[] = [];

        $.each(genericColumnList, function (index: number, value: any) {
            let objListfinal = <FormFinalObjectParam>{};
            let tbleName = '';
            let columnName = '';
            let ColumnValue = '';
            $.each(value, function (indexc: string, valuec:any) {

                if (indexc == "BaseTable") {
                    tbleName = valuec
                }
                else {

                    columnName = columnName + indexc + "^"
                    ColumnValue = ColumnValue + "" + valuec + "^"
                }
            });
            columnName = columnName +  "CreatedBy^createdRoleId^ModifiedBy^modifiedRoleId^";
            ColumnValue = ColumnValue +that.userId+"^"+that.currentRole +"^"+that.userId+"^"+that.currentRole+"^";
           
            objListfinal.tbleName = tbleName;
            objListfinal.tbleSequence = '1';
            objListfinal.columnName = columnName;
            objListfinal.ColumnValue = ColumnValue;
            genericColumnListParam.push(objListfinal);
        });

        if (genericColumnListParam.length > 0) {
            objColumnfinal.genericColumnListParam = genericColumnListParam;
            _data = JSON.stringify(objColumnfinal);
        }
      if (flag == true) {

        this.openSubmitModal();
        this.paramvaluedata = _data;
      }
      else {
        this.paramvaluedata = '';
        this.globalAlertService.AlertMsg("Alert!!", msg.toString(), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;
      }
    }
    
 
  submitcall() {

    //alert("hello");
    this.userService.SubmitGenrateForm(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {

      
      if (datacom != "") {
        let resultarray  = JSON.parse(datacom);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
       
        if (resultarray['successFlag'] == "success") {
          this.SearchEdit(2);
          this.OnResetModal();
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
        }

        else {
          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";
        }
      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      () => {
        this.pageloaddrp();
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });



  }


  deleteRow(event: Event,index: number, callFrom: string){
    if(callFrom =="P"){
      this.formValuesData.splice(index,1);
      this.onSubmitChildReset();
    }
    else if(callFrom =="E"){
      this.formValuesDataE.splice(index,1);
      this.onEditChildReset();
    }

  }

  openViewtModal() {

    $('#ViewModal').show();
    this.display = 'block';

  }

  onCloseViewModal() {
    if(this.formValuesDataE !== null)
    this.formValuesDataE.splice(0,this.formValuesDataE.length);
    $('#ViewModal').hide();
    this.display = 'none';
  }

  SearchEdit(callfrom: number) {

    this.spinner.show();
    
    let tablename:string="";
    if (callfrom == 1) {
      tablename = this.SeltableID;
    }
    else if (callfrom == 2) {
      tablename = this.SeltableID;
    }
    $('#DivMainEdit').show();
   
  

    let that = this;

    $('#SeltableIDEdit').val(tablename);
    let filterQueryString: string = this.filterActualQuery.length >0 ? JSON.stringify(this.filterActualQuery) : "";
    let data = 'uspAppGenericGetFormNamesDetail'+ '|filterParams=' + filterQueryString + '|pageSize=' + this.pageSize.toString() + '|page=' + this.currentPage.toString() + '|callFlag='+callfrom  + '|sortOrder=' + this.sortOrder +'|sortColumn='+ this.sortColumn;
    this.userService.GetDataSetResult(data, this.SeltableIDForm).subscribe((data: any) => {
      if(data?.table1?.length >0)  
      this.totalRecords = data.table1[0]['totalRecords'];
      this.detailTableData = data.table;
      console.log(this.detailTableData);
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err.message);
        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      ()=>{
        this.dtTriggerEdit.next(true);
      });


  }
  // onGetProcDetailsByProcId
  onGetProcDetailsByProcId(spName: string, event: Event) {
    this.userService.getProcParametersEntryView(spName, this.SeltableIDForm)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;
        // this.mainTableId ="Dynamictable" + tblName + "E";
        this.selectedTableId = spName;
        this.viewTableData = data.table;       
        this.openViewtModal();
        console.log(this.viewTableData);
      });
  }
  openEditResetModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.onEditChildReset();
        }
      });
  }
  onEditChildReset(){
    this.childtableFormE.reset();
    this.selectedItemsdropdownChildTableSumColumnE = [];
  }
  onSubmitChildReset(){
    this.childtableFormE.reset();
    this.selectedItemsdropdownChildTableSumColumn = [];
    this.selectedItemsdropdownChildTableIsReq = [ { id: "1", itemName: 'Required' }];
  }
  openUpdateMasterAliasModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Update !!", "Are you sure you want to update Form Name or Master table Alias?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.onUpdateMasterAliasclick();
        }
      });
  }
  onUpdateMasterAliasclick(){
    let that = this;
    this.formValuesDataE[0].tableAliasName = this.mastertableFormE.controls.formAliasNameE.value;
this.formValuesDataE.forEach(function (value, index) {
value.formName = that.mastertableFormE.controls.formNameE.value;
});
  }

  // onGetEditProcedureDetailsByProcId(tbname)
  onEditCustumFormDetailsByFormId(formName: string, formId: string, callfrom: string, event: Event) {
    let that = this;
    let param = 'uspAppGenericGetCustumFormNamesDetailwithFilterByFormId|formNameId=' + formId 
    this.userService.getCommonDataSet( this.SeltableIDForm.toString(),param)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;        
        this.mainTableId = "Dynamictable" +this.commonfunction.ReplaceStringSpace(formName) + "E";
        this.selectedTableId = formName;
        this.editTableData = data.table; 
      },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },      
      ()=>{
       

        this.editTableData.forEach(function (value:any, index:number) {
      let obj = <MultiTableEntries>{};
      obj.baseTable = "P2PtblGenericFormDetails";
      obj.formName = value["formName"];
      obj.formNameId = value["formNameId"];
      obj.id = value["id"];
      obj.isRequired = value["isRequired"];
      obj.parentTableName = value["parentTable"];
      obj.tableAliasName = value["tableAlias"];
      obj.sumColumn = value["sumColumn"];
      obj.tableName = value["tableName"];
      obj.userRoleId = value["userRoleId"];
    
      that.formValuesDataE.push(obj)
     
        });
if(callfrom == "update"){
        that.mastertableFormE.patchValue({
          formNameE: this.formValuesDataE[0].formName,
          tableNameE: this.formValuesDataE[0].tableName,
          formAliasNameE: this.formValuesDataE[0].tableAliasName
        });
        let formNameChk = that.formValuesDataE[0].formName ? that.formValuesDataE[0].formName :'';
        let tblenameChk = that.formValuesDataE[0].tableAliasName ? that.formValuesDataE[0].tableAliasName : '';
        let tblename = that.formValuesDataE[0].tableName ? that.formValuesDataE[0].tableName : '';
        let formUserId = that.formValuesDataE[0].userRoleId ? that.formValuesDataE[0].userRoleId : '0';
        that.mastertableFormE.controls.formNameE.setAsyncValidators([that.asyncCustomvalidation.validateValueInDBExists_Check("txt","uspAppGenericCheckFormName","userRoleId!formName","msg", formNameChk,'').bind(this.asyncCustomvalidation)]);
        that.mastertableFormE.controls.formNameE.setValidators([custumValidation['validateRequired']("","","varchar","text","Form Name","","","")
        ,custumValidation['validatealphanumeric']("","","varchar","text","Form Name","","","")
        ,custumValidation['validateSize']("","50","varchar","text","Form Name","","","")]);
        that.mastertableFormE.controls.formAliasNameE.setValidators([custumValidation['validateRequired']("","","varchar","text","Master Table Alias","","","")
        ,custumValidation['validatealphanumericwithoutspace']("","","varchar","text","Master Table Alias","","","")
        ,custumValidation['validateSize']("","50","varchar","text","Master Table Alias","","","")
        ,custumValidation['validateValueInArrayExists_Check']("","varchar","text","Master Table Alias","","","",that.formValuesDataE,'tableAliasName',true,true,tblenameChk,"id")]);
        that.mastertableFormE.controls.formNameE.updateValueAndValidity();
        that.mastertableFormE.controls.formAliasNameE.updateValueAndValidity();
        that.childtableFormE.controls.childTableName.setValidators([custumValidation['validateRequired']("","","varchar","drop","Child Table","","","")
        ,custumValidation['validateValueInArrayExists_Check']("","varchar","drop","Child Table Name","","","",that.formValuesDataE,'tableName',true,true,"","id")]);
        that.childtableFormE.controls.childTableAliasName.setValidators([custumValidation['validateRequired']("","","varchar","text","Child Table Alias","","","")
        ,custumValidation['validatealphanumericwithoutspace']("","","varchar","text","Child Table Alias","","","")
        ,custumValidation['validateSize']("","50","varchar","text","Child Table Alias","","","")
        ,custumValidation['validateValueInArrayExists_Check']("","varchar","text","Child Table Alias Name","","","",that.formValuesDataE,'tableAliasName',true,false,"","id")]);
        that.childtableFormE.controls.childTableIsRequired.setValidators([custumValidation['validateRequired']("","","varchar","drop","Is Required","","","")]);
        that.childtableFormE.controls.childTableName.updateValueAndValidity();
        that.childtableFormE.controls.childTableAliasName.updateValueAndValidity();
        that.childtableFormE.controls.childTableIsRequired.updateValueAndValidity();
        that.userService.getchildTableName(tblename, this.SeltableIDForm,formUserId).subscribe((data: any) => {
        that.dropdownChildTabledrp = data.table; 
       },
       (err: HttpErrorResponse) => {          
           this.Customvalidation.loginroute(err.status);
       }
       );
       this.onOpenEditModal();
      }
      else{
        this.openViewtModal();
      }
      }
      );
  }

  onTableAliasAction(id: string, action:string, TableId:number,roleId:string) {
    this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + "?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.TableAliasAllAction(id, action, TableId,roleId);
          this.paramvaluedata = '';
        }
      });
  }
  TableAliasAllAction(id: string, action: string ,index: number,roleId: string) {
    
    //code to be done
    let resultarray:any = [];
    this.userService.generateCustumFormAliasAction(id, action,roleId, this.SeltableIDForm.toString()).subscribe((data: any) => {
      if (data != "") {
        resultarray  = JSON.parse(data);			
        // resultarray['successFlag'] 
        // resultarray['resultMessage']
        // resultarray['printAction']
        // resultarray['printActionResult']
        // resultarray['otherAction']
        // resultarray['otherActionResult']
        // resultarray['outputVarResult']
        
        if (action == "Active" || action == "InActive") {
          if (resultarray['successFlag']?.toString() == "success") {                      
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
            return;
          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;
          }
        }
        else {
          if (resultarray['successFlag'] == "success") {
            //            
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

            return;

          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;

          }
        }
    
      }
    },
      (err: HttpErrorResponse) => {

        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      () => {
        if(resultarray['successFlag'] == "success"){
        if (action == "Active") {
          this.detailTableData[index]['isActive'] = true;
        }
        else if (action == "InActive") {
          this.detailTableData[index]['isActive'] = false;
        }
        else {
          this.SearchEdit(2); 
          this.pageloaddrp();
        }
      }

      });
    //

  }


  onOpenEditModal() {
    $('#EditMasterModal').show();
    this.display = 'block';

  }
  onCloseEditModal() {
    if(this.selectedItemsdropdownChildTabledrpE?.length >0)
    this.selectedItemsdropdownChildTabledrpE = [] as Array<IDropDownListObject>;
    if(this.formValuesDataE !== null)
    this.formValuesDataE.splice(0,this.formValuesDataE?.length);
    let that = this;
  
        that.mastertableFormE.controls.formNameE.setAsyncValidators(null);
        that.mastertableFormE.controls.formNameE.setValidators(null);
        that.mastertableFormE.controls.formAliasNameE.setValidators(null);
        that.mastertableFormE.controls.formNameE.updateValueAndValidity();
        that.mastertableFormE.controls.formAliasNameE.updateValueAndValidity();
        that.childtableFormE.controls.childTableName.setValidators(null);
        that.childtableFormE.controls.childTableAliasName.setValidators(null);
        that.childtableFormE.controls.childTableIsRequired.setValidators(null);
        that.childtableFormE.controls.childTableName.updateValueAndValidity();
        that.childtableFormE.controls.childTableAliasName.updateValueAndValidity();
        that.childtableFormE.controls.childTableIsRequired.updateValueAndValidity();
    $('#EditMasterModal').hide();
    this.display = 'block';
  }

  updateEditTableEntry() {
    
    let that = this;
    let flag = true;
    let _data;
    let msg = '';
    let errormsg = '';
    let objColumn = <lstFormObjectParam>{};
    let genericColumnList:any = [];
    let mylist = '[';
    this.formValuesDataE.forEach(function (value, index) {
        let objList = <FormObjectParam>{};
        objList.BaseTable = 'P2PtblGenericFormDetails';
        objList.formName = value.formName;
        objList.sequence = (index + 1).toString();
        objList.tableName = value.tableName;
        objList.parentTable = value.parentTableName;
        objList.tableAlias = value.tableAliasName;
        objList.sumColumn = value.sumColumn;
        objList.isRequired = value.isRequired;
        objList.formNameId = value.formNameId;
        objList.userRoleId = that.editTableData[0]['userRoleId'];
        objList.customEvent = value.customEvent;
        objList.id = value.id;
        genericColumnList.push(objList);

    });

    let objColumnfinal = <lstFormFinalObjectParam>{};
    let genericColumnListParam:any = [];

    $.each(genericColumnList, function (index: number, value:any) {
        let objListfinal = <FormFinalObjectParam>{};
        let tbleName = '';
        let columnName = '';
        let ColumnValue = '';
        $.each(value, function (indexc: string, valuec:any) {

            if (indexc == "BaseTable") {
                tbleName = valuec
            }
            else {

                columnName = columnName + indexc + "^"
                ColumnValue = ColumnValue + "" + valuec + "^"
            }
        });
        columnName = columnName +  "ModifiedBy^modifiedRoleId^";
        ColumnValue = ColumnValue +that.userId+"^"+that.currentRole+"^";
        objListfinal.tbleName = tbleName;
        objListfinal.tbleSequence = '1';
        objListfinal.columnName = columnName;
        objListfinal.ColumnValue = ColumnValue;
        genericColumnListParam.push(objListfinal);
    });

    if (genericColumnListParam.length > 0) {
        objColumnfinal.genericColumnListParam = genericColumnListParam;
        _data = JSON.stringify(objColumnfinal);
    }
  if (flag == true) {

    this.openUpdatemodel();
    this.paramvaluedata = _data;
  }
  else {
    this.paramvaluedata = '';
    this.globalAlertService.AlertMsg("Alert!!", msg.toString(), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
    return;
  }
  }
  openUpdatemodel() {
    this.globalConfirmService.ConfirmFunction("Confirm Update!!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.updatesubmitcall();

        }
        else {
          this.paramvaluedata = '';
        }
      });
  }

  updatesubmitcall() {
    this.userService.EditGenratedForm(this.paramvaluedata, this.SeltableIDForm).subscribe((data: any) => {
      
      if (data != "") {
        let resultarray  = JSON.parse(data);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']

        if (resultarray['successFlag']  == "success") {
          this.SearchEdit(2);
          this.OnResetModal();
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
          $('#EditMasterModal').hide();
          this.paramvaluedata = '';
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";

        }

      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        return true;
      },
      () => {
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });
  }
 
  txtTableNameFocuscheckFormAlreadyExists(event: any) {
    
    let result = this.Customvalidation.validatealphanumeric($.trim(event.target.value.toString()), '', '', '', '', '', '', '')
    if (!result[0] && $.trim(event.target.value.toString()) != "") {
      this.globalAlertService.AlertMsgFocus("Alert!!", "Please Enter valid Form Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);
    }
    else if ($.trim(event.target.value.toString()) == $('#hdnTableNameE').val()) {
      return;

    }
    this.onCheckFunctionTableNameonfocus(event);
  }
  onCheckFunctionTableNameonfocus(event: any) {
    let flag = true;
    let that = this;
    this.userService.getTableNameExists($.trim(event.target.value.toString()), this.selectedItemsRole[0].id.toString(), this.SeltableIDForm).subscribe((data: any) => {
      
      if (data.table.length > 0) {
        flag = false;
      }

    },
      (err: HttpErrorResponse) => {

        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },

      () => {
        if (!flag) {
          that.globalAlertService.AlertMsgFocus("Alert!!", "Table Alias Name Already Exits. Please Choose Another Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);

        }

      });

    return flag;
  }
  onDeletecustumtablechild(index: number) {
    this.editTableData.splice(index, 1);
  }
  onItemSelect(item: any, controlName: string,formName: string,callfrom: string) {
    
    let param ="uspAppGenericGetColumnNamesWithAliasforChildTable|tableName="+ (callfrom == "I"? this.selectedItemsdropdownChildTabledrp[0].id.toString(): this.selectedItemsdropdownChildTabledrpE[0].id.toString());
    this.userService.getCommonDataSet(this.SeltableIDForm.toString(),param).subscribe((res: any) => {
      if(res.table.length>0)
      this.dropdownChildTableSumColumn = res.table;
      if(this.selectedItemsdropdownChildTableSumColumn.length > 0)
      {
        this.selectedItemsdropdownChildTableSumColumn.splice(0,this.selectedItemsdropdownChildTableSumColumn.length)
      }
      if(this.selectedItemsdropdownChildTableSumColumnE.length > 0)
      {
        this.selectedItemsdropdownChildTableSumColumnE.splice(0,this.selectedItemsdropdownChildTableSumColumnE.length)
      }
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      ()=>{
        
      });
  }
  OnItemDeSelect(item: any, controlName: string,formName: string,callfrom: string) {
    if(callfrom == "I"){
      if(this.dropdownChildTableSumColumn.length > 0)
      {
        this.dropdownChildTableSumColumn.splice(0,this.dropdownChildTableSumColumn.length)
      }
      if(this.selectedItemsdropdownChildTableSumColumn.length > 0)
      {
        this.selectedItemsdropdownChildTableSumColumn.splice(0,this.selectedItemsdropdownChildTableSumColumn.length)
      }
    }
    else  if(callfrom == "E"){
      if(this.dropdownChildTableSumColumn.length > 0)
      {
        this.dropdownChildTableSumColumn.splice(0,this.dropdownChildTableSumColumn.length)
      }
      if(this.selectedItemsdropdownChildTableSumColumnE.length > 0)
      {
        this.selectedItemsdropdownChildTableSumColumnE.splice(0,this.selectedItemsdropdownChildTableSumColumnE.length)
      }
    }

  }

  toggleWithFilterCol(popover:any, colName: string, colDisplayName: string,dataType: string,filterColParentTableName: string, callfrom:boolean) {
    if(callfrom)
    this.popoverEvent = popover;
    if (popover.isOpen()) {
      this.filterColumnName = "";
      this.filterColumnDisplayName = "";  
      this.filterColumnDataType = "";
      this.filterColumnParentTableName = "";
      popover.close();
    } else {
      this.filterColumnName = colName;
      this.filterColumnDisplayName = colDisplayName;    
      this.filterColumnDataType = dataType;
      this.filterColumnParentTableName = filterColParentTableName;
      this.reactiveFilterForm();
      popover.open({colName, colDisplayName});
    }
  }
  reactiveFilterForm()
  {
    this.filterFormValue = {} as IFilterColumns;
    this.filterFormValue.cloumnName = this.filterColumnName;    
    this.filterFormValue.cloumnDisplayName = this.filterColumnDisplayName;
    this.filterFormValue.filterColumnDataType = this.filterColumnDataType;
    this.filterFormValue.filterColumnParentTableName = this.filterColumnParentTableName;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    {
      this.filterFormValue.columnCondition = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].columnCondition) ? "AND" : getExistingValues[0].columnCondition;
      this.filterFormValue.filterColumnOperator = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterColumnOperator?.toString()) ? 1 : getExistingValues[0].filterColumnOperator;
      this.filterFormValue.filterValue = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterValue) ? "" : getExistingValues[0].filterValue;
    }
    else
    {
      this.filterFormValue.columnCondition ="AND";
      this.filterFormValue.filterColumnOperator = 1;
    }
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
     
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [
        Validators.required,
      ]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [
        Validators.required,
      ]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),        
      ])      
    });
  }
  get columnCondition() {
    return this.filterForm.get('columnCondition')!;
  }

  get filterColumnOperator() {
    return this.filterForm.get('filterColumnOperator')!;
  }

  get filterValue() {
    return this.filterForm.get('filterValue')!;
  }
  genrateFilterQuery(){
    this.filterActualQuery = [];
    this.filterDisplayQuery = [];
    this.filterColumnQuery.forEach((value:IFilterColumns,index:number)=>{
      //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
      let filterColumnOperator = value.filterColumnOperator ==1 ? "equal"  : value.filterColumnOperator ==2 ? "notEqual" : "Like"
      let filterDisplayColumnOperatorValue = value.filterColumnOperator ==1 ? " = "+ value.filterValue  : value.filterColumnOperator ==2 ? " ≠ "+ value.filterValue : " ≈ "+ value.filterValue;
      this.filterActualQuery.push({columnName: value.cloumnName , columnValue: value.filterValue, conditionOperator: value.columnCondition, filterColConditionOperator:filterColumnOperator});
      this.filterDisplayQuery.push({ columnName: value.cloumnName , displayQuery: value.cloumnDisplayName + filterDisplayColumnOperatorValue, operator: value.columnCondition});

    });
    this.SearchEdit(1);
  }
  delFilter(columnName:string)
  {    
    let filtercolumnIndex: number = this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == columnName);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    this.genrateFilterQuery();
  }
  clearAllFilter()
  {
    this.filterColumnQuery.splice(0, this.filterColumnQuery.length);
    this.genrateFilterQuery();
  }
  clearFilterForm()
  {
    this.filterFormValue.columnCondition="AND";
    this.filterFormValue.filterColumnOperator=1;
    this.filterFormValue.filterValue="";
    let filtercolumnIndex:number =this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == this.filterFormValue.cloumnName);
    this.filterForm.reset(this.filterFormValue);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    console.log(this.filterColumnQuery);
    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName,false);
    this.genrateFilterQuery();
  }
  applyFilter()
  {
    this.filterFormValue = this.filterForm.value;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    { 
      getExistingValues[0].columnCondition = this.filterFormValue.columnCondition;
      getExistingValues[0].filterColumnOperator = this.filterFormValue.filterColumnOperator;
      getExistingValues[0].filterValue = this.filterFormValue.filterValue;
    }
    else
    this.filterColumnQuery.push(this.filterFormValue);

    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName, false);
    this.genrateFilterQuery();

  }
  ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}
onSortClick(event:any, columnName:string) {
  let target = event.currentTarget,
    classList = target.classList;
   
  if (classList.contains('sort-up')) {
    classList.remove('sort-up');
    this.sortOrder="ASC";
  } else {
    classList.add('sort-up');
    this.sortOrder="DESC";
  }
  this.sortColumn = columnName;
  this.currentPage = 1;
  this.SearchEdit(1);
}
pageSizeChange(){
  this.currentPage = 1;
  this.SearchEdit(1);
}

}
