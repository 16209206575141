import { Injectable } from '@angular/core';
import {AlertDialogBoxService} from './alert-dialog-box/alert-dialog-box.service';
import {GenericTableViewAlertServiceService} from './generic-table-view-alert/generic-table-view-alert-service.service';
import { Router } from '../../../node_modules/@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {

  constructor(private AlertService : AlertDialogBoxService, private router: Router,private modalService: NgbModal, private globalTableModelService: GenericTableViewAlertServiceService) { }
  AlertMsgFocus(title: string, msg: string,btnOkText: string, AlertDialogType: string, AlertDialogClass: string,AlertDialogAnimationType: string, event: any)
  {
   
         this.AlertService.AlertFunction(title,msg,btnOkText,AlertDialogType,AlertDialogClass,AlertDialogAnimationType) 
        .then((confirmed) => {
          if(!confirmed)
          {
            event.target.focus(); 
          }
        })
        .catch(() => event.target.focus() );
  }

  AlertMsg(title: string, msg: string,btnOkText: string, AlertDialogType: string, AlertDialogClass: string,AlertDialogAnimationType: string)
  {   
        this.AlertService.AlertFunction(title,msg,btnOkText,AlertDialogType,AlertDialogClass,AlertDialogAnimationType);
  }

  AlertMsgRedirect(title: string, msg: string,btnOkText: string, AlertDialogType: string, AlertDialogClass: string,AlertDialogAnimationType: string, componentName: any)
  {   
        this.AlertService.AlertFunction(title,msg,btnOkText,AlertDialogType,AlertDialogClass,AlertDialogAnimationType)
        .then((confirmed) => {
          if(!confirmed)
          {
            this.router.navigate(['/'+componentName]);
          }
        })
        .catch(() =>   this.router.navigate(['/'+componentName]) );
  }

  AlertMsgReturnOuputtoPage(title: string, msg: string,btnOkText: string, AlertDialogType: string, AlertDialogClass: string,AlertDialogAnimationType: string)
  {   
      return  this.AlertService.AlertFunction(title,msg,btnOkText,AlertDialogType,AlertDialogClass,AlertDialogAnimationType);
  }

  ShowTableModel(title: string, ViewDetailChildtableHeaderData: any,ViewDetailChildtableRowData: any,btnOkText: string, AlertDialogType: string, AlertDialogClass: string)
  {
    this.globalTableModelService.TableviewModelFunction(title, ViewDetailChildtableHeaderData,ViewDetailChildtableRowData,btnOkText, AlertDialogType, AlertDialogClass)
  }

}
