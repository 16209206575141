import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: '<p>redirecting...</p>'
})
export class RedirectComponent { 
    
  public static async navigateTo(router: Router): Promise<boolean> {
    return router.navigateByUrl('redirect', {skipLocationChange: true});
  }

}

