<!-- Tab panes -->
<div class="tab-content">

  <div role="tabpanel" class="p-3 tab-panel " id="divAddNewData">


    <div class="inner-content" [ngStyle]="{'display':displayMain}">

      
      <div id="DivData" class="DivData-content">
        <form *ngIf="isRender && masterProductForm" (ngSubmit)="OnSubmitModal()" class="" novalidate [formGroup]="masterProductForm">

          <!-- {{masterJsonData.formType}} -->
          <div *ngIf="masterJsonData && masterProductForm" class="row m-0">
            <ng-container *ngFor="let formData of masterJsonData; let i=index">

              <ng-container *ngIf="formData.identityColumn !=='yes' && formData.datA_TYPE !=='uniqueidentifier'">
                <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>
                    <angular2-multiselect id="{{masterJsonData[i].controlId}}"
                      formControlName="{{masterJsonData[i].controlName}}" [data]="masterJsonData[i].drpDataSet"
                      [settings]="masterJsonData[i].drpSetting" [(ngModel)]="masterJsonData[i].drpDataSelected"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      (onSelect)="onItemSelect($event,i,false)" (onDeSelect)="OnItemDeSelect($event,i,false)"
                      (onClose)="OnClose($event,masterJsonData[i].controlName)">
                      <c-badge>
                        <ng-template let-item="item">
                          <label style="margin: 0px;">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge>
                    </angular2-multiselect>
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>
                    <angular2-multiselect id="{{masterJsonData[i].controlId}}"
                      formControlName="{{masterJsonData[i].controlName}}" [data]="masterJsonData[i].drpDataSet"
                      [settings]="masterJsonData[i].drpSetting" [(ngModel)]="masterJsonData[i].drpDataSelected"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      (onSelect)="onItemSelect($event,i,true)" (onDeSelect)="OnItemDeSelect($event,i,true)"
                      (onSelectAll)="onItemSelectAll($event,i,true)" (onDeSelectAll)="OnItemDeSelectAll($event,i,true)"
                      (onClose)="OnClose($event,masterJsonData[i].controlName)">
                      <c-badge>
                        <ng-template let-item="item">
                          <label style="margin: 0px;">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge>
                    </angular2-multiselect>
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class='{{masterJsonData[i].controlDivClass}} calnder-icon'>
                    <input type='text' class="{{masterJsonData[i].controlClass}}"
                      [attr.Placeholder]="masterJsonData[i].placeHolder" id="{{masterJsonData[i].controlId}}"
                      bsDatepicker [bsConfig]="bsConfig" autocomplete="off" readonly
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      formControlName="{{masterJsonData[i].controlName}}">
                      <div class="calenderinput-icon"><a href="javascript: void(0);" (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar" aria-hidden="true"></i></a></div>
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>

                </div>
                <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label"
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME"
                    class="form-check {{masterJsonData[i].controlDivClass}}">

                    <input type='checkbox' value='' class="{{masterJsonData[i].controlClass}}"
                      id="{{masterJsonData[i].controlId}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      formControlName="{{masterJsonData[i].controlName}}">
                    <label id="lbl_{{masterJsonData[i].columN_NAME}}" class="form-check-label"
                      for="{{masterJsonData[i].controlId}}">Please check {{masterJsonData[i].columnAliasName}}
                    </label>
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>

                </div>
                <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>
                    <textarea rows='2' cols='50' type='text' class="{{masterJsonData[i].controlClass}}"
                      [attr.Placeholder]="masterJsonData[i].placeHolder" id="{{masterJsonData[i].controlId}}"
                      formControlName="{{masterJsonData[i].controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }">
                        </textarea>
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>

                </div>
                
                <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>

                    <input type='text' class="{{masterJsonData[i].controlClass}}"
                      [attr.Placeholder]="masterJsonData[i].placeHolder" id="{{masterJsonData[i].controlId}}"
                      formControlName="{{masterJsonData[i].controlName}}" readonly [disabled]="true"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }">
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>
                    <input type='text' class="{{masterJsonData[i].controlClass}}"
                      [attr.Placeholder]="masterJsonData[i].placeHolder" id="{{masterJsonData[i].controlId}}"
                      formControlName="{{masterJsonData[i].controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      readonly [disabled]="true">
                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{masterJsonData[i].spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (masterJsonData[i].iS_NULLABLE === 'no')}"
                      for="{{masterJsonData[i].controlId}}">{{masterJsonData[i].columnAliasName}}</label>
                      <span *ngIf="masterJsonData[i].columnInformation != '' && masterJsonData[i].columnInformation != null" class="ml-2" ngbTooltip="{{masterJsonData[i].columnInformation}}" container="wrapper" placement="left" 
                      tooltipClass="tooltipBlue" > <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{masterJsonData[i].controlDivId}}"
                    [attr.columnAliasName]="masterJsonData[i].columnAliasName" [attr.colIndex]="i"
                    [attr.columnId]="masterJsonData[i].columN_NAME" class={{masterJsonData[i].controlDivClass}}>
                    <input type='text' class="{{masterJsonData[i].controlClass}}"
                      [attr.Placeholder]="masterJsonData[i].placeHolder" id="{{masterJsonData[i].controlId}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && !masterProductForm.get(masterJsonData[i].controlName)?.valid,'is-valid': (masterProductForm.get(masterJsonData[i].controlName)?.touched || masterProductForm.get(masterJsonData[i].controlName)?.dirty) && masterProductForm.get(masterJsonData[i].controlName)?.valid }"
                      formControlName="{{masterJsonData[i].controlName}}">

                    <span class=" invalid-feedback" style="padding-top: 10px;">
                      {{masterProductForm.get(masterJsonData[i].controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>

                </div>
              </ng-container>

            </ng-container>

          </div>




          <div class="Submitbtn-content mt-3 pr-3">
            <button type="button" class="btn btn-secondry" (click)="resetForm()">Reset</button>
            <button type="submit" id="btnSubmit" [disabled]="!masterProductForm.valid"
            ngbTooltip="Show filtered Report" container="wrapper" placement="left" tooltipClass="tooltipBlue"
             class="btn btn-primary ml-2">Show Report</button>
          </div>

        </form>
      </div>
    </div>
    <!---------------------------------------------------------------------------------------------------------------->



    <!------------------------Data Div Start----------------------------------------------------------------------------------------->
  
    <div *ngIf="isRenderDetail" id="divViewDataDetail">
      <div class="mt-4 pt-4 border-top">
        <div id="DivtableDataDetail" class="inner-content">
          <!-- <div class="row mb-4">
            <div  class="col-xl-6 col-sm-12 col-md-6">
              <angular2-multiselect *ngIf="ischeckboxshow > 0" id="DrpActionGroupSP" [data]="dropdownListActionGroupSpdrp"
                ngbTooltip="Select Action" container="wrapper" placement="left" tooltipClass="tooltipBlue"  [(ngModel)]="selectedItemsActionGroupSpdrp" [settings]="dropdownSettings"
                (onSelect)="onItemSelectChildAction($event)" (onDeSelect)="OnItemDeSelectChildAction($event)">
              </angular2-multiselect>
            </div>
            <div class="col-xl-6 col-sm-12 col-md-6 text-right">
              <button class="btn btn-excel"  ngbTooltip="Download Excel" container="wrapper" placement="left" tooltipClass="tooltipBlue" (click)="exportAsXLSX()"><i class="fa fa-file-excel-o"></i></button>
              <button class="btn btn-pdf ml-2" ngbTooltip="Download PDF" container="wrapper" placement="left" tooltipClass="tooltipBlue" (click)="exportAsPDF()"><i class="fa fa-file-pdf-o"></i></button>
            </div> 
          </div>
          <div class="custom-search-input mx-3 pr-4">
            <div class="row m-0">
              <div class="col-xl-6 col-sm-12 col-md-4">
              <label class="pt-3"> Custom Filter: </label>
              </div> 
              <div class="col-xl-3 col-sm-12 col-md-4">
                <angular2-multiselect  id="DrpColumn" [data]="dropdownListCustomColumnSearchdrp"
                  [(ngModel)]="selectedItemsCustomColumnSearchdrp" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>  
              <div class="col-xl-3 col-sm-12 col-md-4">
                <div class="custom-input-box position-relative">
                <input type='search' class="form-control report-input  custom-column-serch" [(ngModel)]="customSearch" 
                Placeholder="Custom Column Search" id="txtcustomSearch">
                <span class="input-search"><i class="fa fa-search" aria-hidden="true"></i></span>
              </div>
              </div>        
            </div>
            </div> -->
          <!-- <div class="mt-4 inner-content custom-column-serch">
            <table [id]="Detailtableid" datatable="ng" [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail"
           class="table table-bordered row-border hover w-100">
              <thead>
                <tr *ngIf="isRenderDetail">
                  <th *ngIf="ischeckboxshow > 0 ">
                    <input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" />

                  </th>
                  <th>S.No.</th>                 
                  <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                    <th>
                      {{headerDetailData['title']}}
                    </th>
                  </ng-container>


                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let rowDetailData of DetailtableRowData; let rowi=index;">
                  <ng-container *ngIf="rowDetailData">
                  <td *ngIf="ischeckboxshow > 0">
                    <input type="checkbox"  (change)="isAllSelected()" />

                  </td>
                  <td>{{(rowi + 1)}}</td>
                
                  <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                    <td>
                      {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["key"])])}}
                    </td>
                  </ng-container>
                </ng-container>
                </tr>
              </tbody>

            </table>
          </div> -->
          <app-generic-table-list-view [tbname] ="SeltableID" [formName]="SeltableIDForm" [formType]="3"  [ischeckboxshow]="ischeckboxshow" [dropdownListActionGroupSpdrp]="dropdownListActionGroupSpdrp"
            [filterFromMainScreen]="paramvaluedata" [isRenderDetail]="isRenderDetail" ></app-generic-table-list-view>
          <!-- <div *ngIf="ischeckboxshow > 0" class="mt-5 px-3">
            <div class="Submitbtn-content">
            
              <button type="button" id="btnAction"
                [disabled]="selectedItemsActionGroupSpdrp.length === 0? true:false"
                class="btn btn-primary btn-raised mx-2" ngbTooltip="Perform dropdown selected Action" container="wrapper" placement="left" tooltipClass="tooltipBlue" (click)="onActionArrayList()">Submit</button>
            </div>

          </div> -->
        </div>

      </div>
    </div>
<!------------------------Data Div End----------------------------------------------------------------------------------------->
  
  
  </div>
    <!-- start Modals -->
    <div class="backdrop " [ngStyle]="{'display':display}"></div>
    <!-- start EditMaster Modal -->
    <div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content edit-modal-form">
          
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->
      <!-- </div> -->
  <!-- </div> -->

</div>