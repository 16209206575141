import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GenericPrintService {
  isPrinting = false;
  printDataArray: any =[];
  constructor(private router: Router) { }

  printDocument() {
    this.isPrinting = true;
      this.router.navigate(['/',
      { outlets: {
        'printForm': ['printForm','printRecord']
      }}]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { printForm: null }}]);
    });
  }

  GenericPrintFunction(GenericPrintDataArray:any) {
    if (GenericPrintDataArray != null) {
        let printContents:any, popupWin:any;
        setTimeout(() => {
            
            //   printContents = document.getElementById('printContaint').innerHTML;
            printContents = $('#printContaint').html();
            let FINALDATA = printContents.replace("display: none", "");
            popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            popupWin.document.write(`<html>
         <body style="font-family: sans-serif" onload="window.print();window.close()">${FINALDATA}</body></html>`);
            popupWin.document.close();
            setTimeout(() => {
                popupWin.document.write(`<html><body>${printContents}</body></html>`);
            }, 200);
        }, 500);
    }
}

}
