import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import{IaboutUs,Iaboutimgs,Itableth}from "../../allUsedModule";
import { AboutPageModelService } from '../../allFormPopUpModel/about-page-model/about-page-model.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  activeTabId:number=1;
  AboutUsData:any;
  AboutUpdateForm !: FormGroup;
  satisfiedSclList:any;

  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "imgurl" ,"isActionBtn":false,"isImagePreviewEnabled":true}
  ]

  constructor(public commonfunction:Commonfunction,private aboutService:AboutPageModelService) { }

  ngOnInit(): void {
    this.AboutUsData = this.commonfunction.loadJson("~/../assets/about.json")[0]; 
    const data = this.commonfunction.loadJson("~/../assets/about-clint.json"); 
    this.satisfiedSclList = data?.clintlogo
    console.log(this.satisfiedSclList);
    
    this.AboutUpdateForm = new FormGroup({
      HomePageTitle: new FormControl(this.AboutUsData.logobotmheadline,[Validators.required]),
      aboutTitle: new FormControl(this.AboutUsData.title,[Validators.required]),
      aboutDetails: new FormControl(this.AboutUsData.description1,[Validators.required]),
      aboutDetails1: new FormControl(this.AboutUsData.description2,[Validators.required]),
      subtitle: new FormControl(this.AboutUsData.aboutdetailtxt,[Validators.required]),
      subtitle1: new FormControl(this.AboutUsData.aboutdetailtxt2,[Validators.required]),
    })
  }

  onClickResetFrom(){}
  OnClickSubmit(){
    let _item : IaboutUs={} as IaboutUs;
    _item.logobotmheadline = this.AboutUpdateForm.get("HomePageTitle")?.value;
    _item.title = this.AboutUpdateForm.get("aboutTitle")?.value;
    _item.description1 = this.AboutUpdateForm.get("aboutDetails")?.value;
    _item.description2 = this.AboutUpdateForm.get("aboutDetails1")?.value;
    _item.aboutdetailtxt = this.AboutUpdateForm.get("subtitle")?.value;
    _item.aboutdetailtxt2 = this.AboutUpdateForm.get("subtitle1")?.value;

    console.log(_item);
    
  }


  deleteKey(index:number){
    this.satisfiedSclList?.clintlogo.splice(index,1)
  }

  onClickEditForm(value:any){
    this.aboutService.AboutService(value)
  }

  OnClickAddNew(){}
}
