import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-genric-program',
  templateUrl: './public-genric-program.component.html',
  styleUrls: ['./public-genric-program.component.css']
})
export class PublicGenricProgramComponent implements OnInit {
  GenricProgramList:any
  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.GenricProgramList = this.commonfunction.loadJson('~/../assets/Program.json');
    console.log(this.GenricProgramList);
  }

}
