import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IadmissionDeadline,Iadmission} from '../../allUsedModule'

@Component({
  selector: 'app-admission-page-model',
  templateUrl: './admission-page-model.component.html',
  styleUrls: ['./admission-page-model.component.css']
})
export class AdmissionPageModelComponent implements OnInit {
  RequirementData:Iadmission = {} as Iadmission;
  updateEditMasterForm!:FormGroup;
  reqShowFlag:boolean=false;
  
  AdmissionDeadlineData:IadmissionDeadline={} as IadmissionDeadline;
  DeadlineShowFlag:boolean=false;
  updateAdmissionDeadlineMasterForm!:FormGroup;

  constructor(private activeModel:NgbActiveModal) { }

  ngOnInit(): void {
    this.updateEditMasterForm =new FormGroup({
      title:new FormControl (this.RequirementData.title)
    })

    // console.log(this.AdmissionDeadlineData);
    

    this.updateAdmissionDeadlineMasterForm = new FormGroup({
      title:new FormControl (this.AdmissionDeadlineData.title),
      year:new FormControl (this.AdmissionDeadlineData.year),
      month:new FormControl (this.AdmissionDeadlineData.monthly),
    })
  }

  onCloseConfirmModal(){
    this.activeModel.close()
  }

  OnClickSubmit(){
    this.RequirementData.title = this.updateEditMasterForm.get("title")?.value;
    this.activeModel.close(this.RequirementData);
  }


  OnClickAdmissionDeadlineSubmit(){
    this.AdmissionDeadlineData.title = this.updateAdmissionDeadlineMasterForm.get("title")?.value;
    this.AdmissionDeadlineData.year = this.updateAdmissionDeadlineMasterForm.get("year")?.value;
    this.AdmissionDeadlineData.monthly = this.updateAdmissionDeadlineMasterForm.get("month")?.value;
    this.activeModel.close(this.AdmissionDeadlineData);
  }

}
