import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = 'https://globalp2pcrm.com:4150';

  constructor(private http: HttpClient) {}
  
sendEmail(payload: any): Observable<any> {
  
  return this.http.post<any>(this.apiUrl+'/sendEmail', payload);
}
jobApply(payload: any): Observable<any> {
  
  return this.http.post<any>(this.apiUrl+'/jobApply', payload);
}
internShipApply(payload: any): Observable<any> {
  
  return this.http.post<any>(this.apiUrl+'/internShipApply', payload);
}
}
