 <!-- start submit Modal -->
 <div id="ConfirmModal" class="NP_popup_confirm custom-action-modal" tabindex="-1" role="dialog" 
 aria-hidden="true">
 <div  role="document">
   <div class="modal-content">
     <div class="modal-header">
       <div class="NP_emoji-confirm-bg">
         <div class="NP_emoji-error"></div>
       </div>
       <button type="button" class="close" aria-label="Close" (click)="onCloseConfirmModal()"><span
           aria-hidden="true">&times;</span></button>

     </div>
     <h2 class="modal-title">{{AlertDialogTitle}}</h2>
     <div class="modal-body">
       <p>{{AlertDialogMessage}}</p>
     </div>
     <div class="NP_popup-footer mt-2 text-center"> 
       <div class="d-inline-block align-middle">
         <p><a href="javascript: void(0);" class="btn btn-secondry" (click)="onCloseConfirmModal()">{{btnCancelText}}</a></p>
       </div>
       <div class="d-inline-block align-middle ml-3">
         <p class="yes"><a href="javascript: void(0);" class="btn btn-primary" (click)="onAcceptConfirmModal()">{{btnOkText}}</a></p>
       </div> 

     </div>
   </div><!-- /.modal-content -->
 </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
<!-- End submit Modal -->