import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import { AdmissionPageModelService } from '../../../allFormPopUpModel/admission-page-model/admission-page-model.service';
import {Itableth} from '../../../allUsedModule'

@Component({
  selector: 'app-admission-dashboard',
  templateUrl: './admission-dashboard.component.html',
  styleUrls: ['./admission-dashboard.component.css']
})
export class AdmissionDashboardComponent implements OnInit {
  AddNewFrom !: FormGroup;
  Addmissiondata: any;
  rowdata:any[]=[];
  activeTabId: number = 1;
  heading = {
    title: ""
  }

  tableHead : Itableth[] = [
    { "displayName": "Title", "colName": "title","isActionBtn":false,"isImagePreviewEnabled":false }
  ]

  constructor(public commonfunction: Commonfunction, private admissionService: AdmissionPageModelService) { }

  ngOnInit(): void {
    this.Addmissiondata = this.commonfunction.loadJson("~/../assets/Admission.json");
    this.rowdata = this.Addmissiondata?.admissionrequirments?.admissionrequirmentsData;

    this.AddNewFrom = new FormGroup({
      headingName: new FormControl(this.Addmissiondata.admissionrequirments.admissionrequirmentsheading, [Validators.required]),
      title: new FormControl(''),
    })

  }

  onClickResetFrom() {
    const titleControl = this.AddNewFrom.get('title');
    if (titleControl) {
      titleControl.setValue('');
    } else {
      console.warn('Title control is not found in the form group.');
    }
  }



  OnClickSubmit() {
    this.heading.title = this.AddNewFrom.get("title")?.value;
    let _heading = this.AddNewFrom.get("headingName")?.value;
    console.log(_heading);

    this.Addmissiondata.admissionrequirments.admissionrequirmentsData.push(this.heading);

    const titleControl = this.AddNewFrom.get('title');
    if (titleControl) {
      titleControl.setValue(''); 
    } else {
      console.warn('Title control is not found in the form group.');
    }

  }

  onClickEditForm(value: any) {
    this.admissionService.AdmissionService(value, true)
  }


  deleteKey(index: number) {
    this.Addmissiondata.splice(index, 1);
  }

}
