<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Add New Detail</a>
        <ng-template ngbNavContent [formGroup]="AddNewFrom">
            <div class="row" >
                <div class="col-md-6">
                    <label for="title">Tab Name</label>
                    <input placeholder="Add New  Tab Name" (change)="addrouterlink($event)" class="form-control" type="text"
                        formControlName="title">
                </div>
                <div class="col-md-6">
                    <label for="type">Type</label>
                    <input placeholder="Add New Type" class="form-control" type="text"
                        formControlName="type">
                </div>
                <div class="col-md-6">
                    <label for="routkey">Route Key</label>
                    <input placeholder="Add New routeKey" class="form-control" type="text"
                        formControlName="routkey">
                </div>
                <div class="col-md-6">
                    <label for="position">Position</label>
                    <input placeholder="Add New Position" class="form-control" type="text"
                        formControlName="position">
                </div>
                <div class="col-md-6">
                    <label for="level0">Menu</label>
                    <input placeholder="Add New Menu" class="form-control" type="text"
                        formControlName="level0">
                </div>
                <div class="col-md-6">
                    <label for="level1">Submenu</label>
                    <input placeholder="Add New Submenu" class="form-control" type="text"
                        formControlName="level1">
                </div>
            </div>
            <div class="Submitbtn-content mt-5">
                <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
            </div>
        </ng-template>
    </li>

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">View Detail</a>
        <ng-template ngbNavContent>
            <!-- <div class="container-fluid">
                <div class="row justify-content-center">
                     <div class=" p-0">
                         <div class="table-responsive main-table-wrappr">
                             <table class="table row-border hover w-100 ">
                                 <thead>
                                     <tr>
 
                                         <th scope="col" resizable>S.No.</th>
                                         <th scope="col" resizable>Tittle</th>
                                         <th scope="col" resizable>Type</th>
                                         <th scope="col" resizable>show</th>
                                         <th scope="col" resizable>Router Link</th>
                                         <th scope="col" resizable>Position</th>
                                         <th scope="col" resizable>Menu</th>
                                         <th scope="col" resizable>Sub Menu</th>
                                         <th scope="col">Action</th>
                                     </tr>
 
                                 </thead>
 
                                 <tbody id="EditTableBody">
 
                                     <tr *ngFor="let data of headerdata ; let sr = index">
                                         <td>{{sr+1}}</td>
 
                                         <td>
                                             <div >{{data.text}}</div>
                                         </td>
                                         <td>
                                             <div>{{data.type}}</div>
                                         </td>
                                         <td>
                                             <div  >{{data.show}}</div>
                                         </td>
                                         <td>
                                             <div  >{{data.routkey}}</div>
                                         </td>
                                         <td>
                                             <div >{{data.position}}</div>
                                         </td>
                                         <td>
                                             <div >{{data.level0}}</div>
                                         </td>
                                         <td>
                                             <div >{{data.level1}}</div>
                                         </td>
                                        
                                         <td style="width: 100px;">
                                             <a  href="javascript:void(0)" (click)="onClickEditForm( data)" ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                             <a  href="javascript:void(0)" (click)="deleteKey(sr)" ngbTooltip="Delete Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>
                                         </td>
                                     </tr>
 
                                 </tbody>
                             </table>
                         </div>
                     </div>
                 </div> 


                 
             </div> -->
             <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="headerdata" (customActionDetail)="onClickEditForm($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>
</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>