<div class="edit-modal-form">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Header</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateEditMasterForm">
        <div class="row" >
            <div class="col-md-6">
                <label for="title">Tab Name</label>
                <input placeholder="Add New  Tab Name" class="form-control" type="text"
                    formControlName="title">
            </div>
            <div class="col-md-6">
                <label for="type">Type</label>
                <input placeholder="Add New Type" class="form-control" type="text"
                    formControlName="type">
            </div>
            <div class="col-md-6">
                <label for="routkey">Route Key</label>
                <input placeholder="Add New routeKey" class="form-control" type="text"
                    formControlName="routkey">
            </div>
            <div class="col-md-6">
                <label for="position">Position</label>
                <input placeholder="Add New Position" class="form-control" type="text"
                    formControlName="position">
            </div>
            <div class="col-md-6">
                <label for="level0">Menu</label>
                <input placeholder="Add New Menu" class="form-control" type="text"
                    formControlName="level0">
            </div>
            <div class="col-md-6">
                <label for="level1">Submenu</label>
                <input placeholder="Add New Submenu" class="form-control" type="text"
                    formControlName="level1">
            </div>
        </div>
        <div class="Submitbtn-content mt-5">
            <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
            <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
        </div>
    </div>
</div>    