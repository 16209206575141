<div class="container clintsay">
    <ngb-carousel>
        <ng-template ngbSlide *ngFor="let item of ClientStroy">
            <div class="row text-light py-5 align-items-center">   
                <div class="col-md-8 testimonialTxt" data-aos="fade-right">
                    <div class="StoryBoxContent">
                        <div class="StoryBoxContentImg"><img src="../../../assets/img/MaskgroupLeft.png" alt=""></div>
                        <span class="StoryBoxContentText">{{item.description}}</span>
                        <span class="StoryBoxContentImg2"><img src="../../../assets/img/Mask group.png" alt=""></span>
                    </div>
                </div>
                <div class="col-md-4 testimonialImg" data-aos="fade-left">
                    <div class="StoryBox">
                        <div class="StoryBoxImg">
                            <img [src]="item.ImgUrl" alt="">
                            <div class="StoryBoxHeading">
                                <h4 class="mb-0">{{item.Heading}}</h4>
                                <p class="mb-0">{{item.position}}</p>
                            </div>
                        </div>
                       
                    </div>
                </div>  
            </div>
        </ng-template>
    </ngb-carousel>    


    <!-- <div class="row text-light py-5 align-items-center" *ngFor="let item of ClientStroy">   
        <div class="col-md-8">
            <div class="StoryBoxContent">
                <div class="StoryBoxContentImg"><img src="../../../assets/img/MaskgroupLeft.png" alt=""></div>
                <span class="StoryBoxContentText">{{item.Content}}</span>
                <span class="StoryBoxContentImg2"><img src="../../../assets/img/Mask group.png" alt=""></span>
            </div>
        </div>
        <div class="col-md-4">
            <div class="StoryBox">
                <div class="StoryBoxImg"><img [src]="item.ImgUrl" alt=""></div>
                <div class="StoryBoxHeading">
                    <h4 class="mb-0">{{item.Heading}}</h4>
                    <p class="mb-0">{{item.position}}</p>
                </div>
            </div>
        </div>  
    </div> -->
</div>