<!------------------------------------------- table popup  -------------------------------------------------->


<div class="table_popup">
    <div class="table_popup_warpper">
        <div class="table_popup_header">
            <div class="headline_tbpop">
                <div class="col">
                    <div>Column Name (Data Type)</div>
                </div>
            </div>
            <div class="closeBtn_tbpop">
                <img src="assets/images/close.svg" alt="">
            </div>
        </div>

        <div class="table_popup_content">
            <div class="tablepopup_wrepper">
                <div class="modal-content">
                    <div class="modal-body">
                        <form>
                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnAlias" class="form-label">Column Alias</label>
                                        <input type="text" class="form-control" id="columnAlias" value="">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnSequence" class="form-label">Column Sequence</label>
                                        <input type="text" class="form-control" id="columnSequence">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnClass" class="form-label">Column Class</label>
                                        <input type="text" class="form-control" id="columnClass">
                                    </div>
                                </div>
                            </div>

                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="savedColumnLength" class="form-label">Column Length</label>
                                        <input type="text" class="form-control" id="savedColumnLength">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="currentColumnLength" class="form-label">Current Column
                                            Length</label>
                                        <input type="text" class="form-control" id="currentColumnLength">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnEvent" class="form-label">Column Event</label>
                                        <input type="text" class="form-control" id="columnEvent">
                                    </div>
                                </div>
                            </div>
                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="valueFillType" class="form-label">Value Fill Type</label>
                                        <select name="valueFillType" class="form-control" id="valueFillType">
                                            <option value="ParentandChild"> Parent and Child </option>
                                            <option value="ParentandChild2"> Only Child </option>
                                            <option value="ParentandChild3"> Neither Parent Nor Child </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="childTableName" class="form-label">ChildTable Name</label>
                                        <input type="text" class="form-control" id="childTableName">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="dropDownQuery" class="form-label">DropDownQuery</label>
                                        <input type="text" class="form-control" id="dropDownQuery">
                                    </div>
                                </div>
                            </div>

                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="selectValidation" class="form-label">Select Validation</label>
                                        <select name="selectValidation" class="form-control" id="selectValidation">
                                            <option value="ParentandChild"> Parent and Child </option>
                                            <option value="ParentandChild2"> Only Child </option>
                                            <option value="ParentandChild3"> Neither Parent Nor Child </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="validationMessage" class="form-label">Validation Message</label>
                                        <input type="text" class="form-control" id="validationMessage">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="selectControl" class="form-label">Select Control</label>
                                        <select name="selectControl" class="form-control" id="selectControl">
                                            <option value="ParentandChild"> Select </option>
                                            <option value="ParentandChild2"> Only Child </option>
                                            <option value="ParentandChild3"> Neither Parent Nor Child </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="regexp" class="form-label">RegExp String</label>
                                        <input type="text" class="form-control" id="regexp">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="selectDataExists" class="form-label">Select Data Exists</label>
                                        <select name="selectDataExists" class="form-control" id="selectDataExists">
                                            <option value="ParentandChild"> Select </option>
                                            <option value="ParentandChild2"> Only Child </option>
                                            <option value="ParentandChild3"> Neither Parent Nor Child </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="dataExistsParams" class="form-label">Data Exists Params</label>
                                        <input type="text" class="form-control" id="dataExistsParams">
                                    </div>
                                </div>
                            </div>

                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="range" class="form-label">Range(min-max)</label>
                                        <input type="text" class="form-control" id="range">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnInformation" class="form-label">Column Information</label>
                                        <input type="text" class="form-control" id="columnInformation">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="comparisonColumn" class="form-label">Comparison Column</label>
                                        <input type="text" class="form-control" id="comparisonColumn">
                                    </div>
                                </div>
                            </div>
                            <div class="row center_class">
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnDefaultValue" class="form-label">Column Default Value</label>
                                        <input type="text" class="form-control" id="columnDefaultValue">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="columnMiscellaneousType" class="form-label">Column Miscellaneous
                                            Type</label>
                                        <input type="text" class="form-control" id="columnMiscellaneousType">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col">
                                        <label for="expressionJson" class="form-label">Expression Json</label>
                                        <input type="text" class="form-control" id="expressionJson">
                                    </div>
                                </div>
                            </div>

                            <div class="toggle_div extraDiv">
                                <div class="row center_class">
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Required</h5>
                                            <span class="switch">
                                                <input id="switch-rounded" type="checkbox" />
                                                <label for="switch-rounded"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>View Link On</h5>
                                            <span class="switch">
                                                <input id="View-Link" type="checkbox" />
                                                <label for="View-Link"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Hide Column</h5>
                                            <span class="switch">
                                                <input id="Hide-Column" type="checkbox" />
                                                <label for="Hide-Column"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Hide Column Form</h5>
                                            <span class="switch">
                                                <input id="Column-Form" type="checkbox" />
                                                <label for="Column-Form"></label>
                                            </span>
                                        </section>
                                    </div>
                                </div>

                                <div class="row center_class">
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Hide Column Update Form</h5>
                                            <span class="switch">
                                                <input id="Update-Form" type="checkbox" />
                                                <label for="Update-Form"></label>
                                            </span>
                                        </section>
                                    </div>

                                    <div class="col-md-3">
                                        <section>
                                            <h5>Edit Disabled</h5>
                                            <span class="switch">
                                                <input id="Edit-Disabled" type="checkbox" />
                                                <label for="Edit-Disabled"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Column HideView</h5>
                                            <span class="switch">
                                                <input id="Column-HideView" type="checkbox" />
                                                <label for="Column-HideView"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Column HideMobileView</h5>
                                            <span class="switch">
                                                <input id="HideMobileView" type="checkbox" />
                                                <label for="HideMobileView"></label>
                                            </span>
                                        </section>
                                    </div>
                                </div>
                                <div class="row center_class">
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Column FilterMobileView</h5>
                                            <span class="switch">
                                                <input id="FilterMobileView" type="checkbox" />
                                                <label for="FilterMobileView"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Column HideMobileForm</h5>
                                            <span class="switch">
                                                <input id="HideMobileForm" type="checkbox" />
                                                <label for="HideMobileForm"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>Excel DownloadColumn</h5>
                                            <span class="switch">
                                                <input id="DownloadColumn" type="checkbox" />
                                                <label for="DownloadColumn"></label>
                                            </span>
                                        </section>
                                    </div>
                                    <div class="col-md-3">
                                        <section>
                                            <h5>PDF DownloadColumn</h5>
                                            <span class="switch">
                                                <input id="PDF-DownloadColumn" type="checkbox" />
                                                <label for="PDF-DownloadColumn"></label>
                                            </span>
                                        </section>
                                    </div>

                                </div>
                            </div>
                            <div class="row center_class">
                                <div class="col">
                                    <div class="modal-footer m-3">
                                        <button type="button" class="btn btn-secondary closebtn-table">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>