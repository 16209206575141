import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-generic-clientsay',
  templateUrl: './public-generic-clientsay.component.html',
  styleUrls: ['./public-generic-clientsay.component.css']
})
export class PublicGenericClientsayComponent implements OnInit {

  ClientStroy:any

  constructor(private commonfunction: Commonfunction) { }

  ngOnInit(): void {
    this.ClientStroy = this.commonfunction.loadJson("~/../assets/clientStory.json");    
  }

}
