import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewImageComponent} from '../preview-image/preview-image.component';

@Injectable({
  providedIn: 'root'
})
export class PreviewImageService {

  constructor(private modalService: NgbModal) { }
  public PreviewImageFunction(
    title: string,    
    fileName: string,
    columnName: string,
    formName: string,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',    
    dialogSize: 'sm'|'lg'| 'md' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(PreviewImageComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.title = title;    
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.fileColumnName = columnName;
    modalRef.componentInstance.formName = formName;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText; 
    return modalRef.result;
  }
}
