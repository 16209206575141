import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GenericPrintService} from '../generic-print-service';
import { SafeHtmlPipe } from '../safe-html-pipe';
@Component({
  selector: 'app-generic-print-form',
  templateUrl: './generic-print-form.component.html',
  styleUrls: ['./generic-print-form.component.css']
})
export class GenericPrintFormComponent implements OnInit {
  printDataArray: any=[];
  constructor(route: ActivatedRoute,
    public genericPrintService: GenericPrintService) { }

  ngOnInit() {
    this.printDataArray = this.genericPrintService.printDataArray;
    console.log(this.genericPrintService.printDataArray );
    setTimeout(() =>
    Promise.all(this.printDataArray)
      .then(() => this.genericPrintService.onDataReady()),1000);
    
  }
 
}
