import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import{Iblogs,Itableth}from '../../../allUsedModule'
import { BlogPageModelService } from '../../../allFormPopUpModel/blog-page-model/blog-page-model.service';

@Component({
  selector: 'app-blog-dashboard',
  templateUrl: './blog-dashboard.component.html',
  styleUrls: ['./blog-dashboard.component.css']
})
export class BlogDashboardComponent implements OnInit {
  activeTabId:number=1;
  Blogsdata:any;
  AddNewFrom !: FormGroup;

  
  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "imageUrl","isActionBtn":false,"isImagePreviewEnabled":true},
    { "displayName": "Tittle", "colName": "title","isActionBtn":false,"isImagePreviewEnabled":false},
    { "displayName": "Description", "colName": "description","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Router Link", "colName": "routeKey","isActionBtn":false,"isImagePreviewEnabled":false },
  ]
  
  constructor(public commonfunction:Commonfunction,private blogservice:BlogPageModelService) { }

  ngOnInit(): void {
    this.Blogsdata = this.commonfunction.loadJson("~/../assets/blogs.json");
    
    this.AddNewFrom = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''), 
    })
  }


    onClickResetFrom(){
      this.AddNewFrom.reset()
    }

    OnClickSubmit(){
      const _item : Iblogs = {} as Iblogs;
      _item.title = this.AddNewFrom.get('title')?.value;
      _item.description = this.AddNewFrom.get('description')?.value;
      _item.routeKey = this.AddNewFrom.get('title')?.value;
      _item.id = this.Blogsdata.length +1;
      _item.isActive = true;
      console.log(_item);
      this.Blogsdata.push(_item)  
    }

    onClickEditForm(value:Iblogs){
      this.blogservice.BlogService(value)
      .then((confirm)=>{
        if(confirm){
          // this.Blogsdata[index].push(value)ks
        }
      })
    }
    deleteKey(index:number){
      this.Blogsdata.splice(index,1);
    }

}
