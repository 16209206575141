import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationValidatorComponent } from '../../shared/register.validator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Customvalidation } from "../../shared/Validation";
import { Commonfunction } from "../../shared/commonutility";
import 'jquery';
declare let jQuery: any;
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  display = "none";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "modal NP_popup_success";
  AlertAnimationType: any = "smile";
  isflag: boolean = false;
  PMessage: any;

  registrationFormGroup: FormGroup;
  passwordFormGroup: FormGroup;
  //constructor(private userService : UserService ,private router : Router,private location: Location,private spinner: NgxSpinnerService){ 
  param: string = '';
  menulabel: string ='';
  formlable: string='';
  constructor(private formBuilder: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService, private router: Router, public Customvalidation: Customvalidation, private commonfunction:Commonfunction) {
    this.passwordFormGroup = this.formBuilder.group({
      password: ['', Validators.required],
      repeatPassword: ['', Validators.required]
    }, {
      validator: RegistrationValidatorComponent.validate.bind(this)
    });
    this.registrationFormGroup = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      passwordFormGroup: this.passwordFormGroup
    });
  }

  ngOnInit() {
    let menuItem = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('menuItem')) ? sessionStorage.getItem("menuItem")?.toString():null;
    this.param = menuItem != null ? menuItem : '';
    // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
    let paramjs = JSON.parse(this.param);
    jQuery('#SeltableID').val(paramjs.formValue);
    jQuery('#SeltableIDForm').val(paramjs.formName);
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
  }
  onSearchChange() {
    this.passwordValidate(this.passwordFormGroup.value["password"]);
  }
  onClickRegister() {
    
    this.spinner.show();
    this.userService.userAuthenticationChangePassword(sessionStorage.getItem('userId'), this.registrationFormGroup.value["currentpassword"], this.passwordFormGroup.value["password"]).subscribe((datacom: any) => {
      if (datacom != "") {
        this.openAlertModal();
        let resultarray  = JSON.parse(datacom.table1[0]["result"]);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
        if (resultarray['successFlag']  == "success") {
          this.isflag = true;
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage'];
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "modal NP_popup_success";

        }

        else {
          this.isflag = false;
          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage'];
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";

        }
      }
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.Customvalidation.loginroute(err.status);
      });
    this.spinner.hide();
  }

  passwordValidate(value: string) {
    var pswd = value;
    this.checkStrength(pswd);
    //validate the length
    if (pswd.length < 8) {
      jQuery('#length').removeClass('valid').addClass('invalid');
    } else {
      jQuery('#length').removeClass('invalid').addClass('valid');
    }

    //validate letter
    if (pswd.match(/[A-z]/)) {
      jQuery('#letter').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#letter').removeClass('valid').addClass('invalid');
    }

    //validate capital letter
    if (pswd.match(/[A-Z]/)) {
      jQuery('#capital').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#capital').removeClass('valid').addClass('invalid');
    }

    //validate number
    if (pswd.match(/\d/)) {
      jQuery('#number').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#number').removeClass('valid').addClass('invalid');
    }

    //validate space
    if (pswd.match(/[^a-zA-Z0-9\-\/]/)) {
      jQuery('#space').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#space').removeClass('valid').addClass('invalid');
    }
  }

  onFocus() {
    jQuery('#pswd_info').show();
  }
  onblur() {
    jQuery('#pswd_info').hide();
  }

  checkStrength(password: string) {
    var strength = 0
    if (password.length == 0) {
      // jQuery('#result').removeClass('short')
      // jQuery('#result').removeClass('weak')
      // jQuery('#result').removeClass('good')
      // jQuery('#result').removeClass('strong')
      jQuery("#result").removeAttr("style");
      return this.registrationFormGroup.value["PMessage"] = '';
      // jQuery('#conpaw').attr("disabled", "disabled");
    }
    else {
      // jQuery('#conpaw').removeAttr("disabled");
      if (password.length < 8) {
        //jQuery('#result').removeClass()
        //jQuery('#result').addClass('short')
        jQuery("#result").removeAttr("style");
        jQuery('#result').css('color', '#FF0000');
        jQuery('#result').css('font-weight', 'bold');
        return jQuery('#result').text('Too short');
      }
      if (password.length > 7) strength += 1
      // If password contains both lower and uppercase characters, increase strength value.
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1
      // If it has numbers and characters, increase strength value.
      if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1
      // If it has one special character, increase strength value.
      if (password.match(/([!,%,&,@,#,jQuery,^,*,?,_,~])/)) strength += 1
      // If it has two special characters, increase strength value.
      if (password.match(/(.*[!,%,&,@,#,jQuery,^,*,?,_,~].*[!,%,&,@,#,jQuery,^,*,?,_,~])/)) strength += 1
      // Calculated strength value, we can return messages
      // If value is less than 2
      if (strength < 2) {
        //jQuery('#result').removeClass()
        //jQuery('#result').addClass('weak')
        jQuery("#result").removeAttr("style");
        jQuery('#result').css('color', '#FFA500');
        jQuery('#result').css('font-weight', 'bold');
        return jQuery('#result').text('Weak');
      } else if (strength == 2) {
        //jQuery('#result').removeClass()
        //jQuery('#result').addClass('good')   
        jQuery("#result").removeAttr("style");
        jQuery('#result').css('font-weight', 'bold');
        jQuery('#result').css('color', '#000080');

        return jQuery('#result').text('Good');
      } else {
        //jQuery('#result').removeClass()
        //jQuery('#result').addClass('strong') 
        jQuery("#result").removeAttr("style");
        jQuery('#result').css('font-weight', 'bold');
        jQuery('#result').css('color', '#32CD32');
        return jQuery('#result').text('Strong');
      }
    }
  }


  openAlertModal() {

    jQuery('#AlertModal').show();
    this.display = 'block';
  }

  onCloseAlertModal() {
    jQuery('#AlertModal').hide();
    jQuery('#AlertModalJs').hide();
    jQuery(".AlertbackdropCustom").css({ display: "none" });
    this.display = 'none';
    if (this.isflag == true) {
      this.Logout();
    }
  }

  Logout() {
    this.userService.userAuthenticationLogOut(this.commonfunction.getUserTokenValuestring(), this.commonfunction.getUserIdValuestring()).subscribe((datacom: any) => {
      sessionStorage.removeItem('userToken');
      this.router.navigate(['/login']);
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }

  myFunction(Password: any) {
    var x = Password;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

}

