<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Add New Detail</a>
        <ng-template ngbNavContent [formGroup]="AddNewFrom">
            <div class="row" >
                <div class="col-md-4">
                    <div class="ImageShowBox">
                        <label class="ImageShow">
                            <div class="img_box">
                                <img src="" alt="Image Preview">
                            </div>
                            <div class="hoverImg">
                                <div class="hover_content">
                                    <img style="width: 30px; display: block;" class="hoverImgIcon"
                                        src="assets\img\Mask group1.png" alt="Upload Icon">
                                    <span class="text-light">Upload</span>
                                </div>
                            </div>
                            <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                        </label>
                    </div>
                    <div class="text-center"><span class="imgError" style="font-size: 14px;"><span
                                style="color: red;">*</span> only jpg / png / webp</span></div>
                </div>
                <div class="col-md-8">
                    <div>
                        <label for="">Title</label>
                        <input placeholder="Add New Heading" class="form-control" type="text"
                            formControlName="title">
                    </div>
                    <div class="mt-2">
                        <label for="">Description</label>
                        <textarea placeholder="Add New Description" class="form-control" type="text"
                            formControlName="description"></textarea>
                    </div>
                    <!-- <div>
                        <label for="">Title</label>
                        <input placeholder="Add New Heading" class="form-control" type="text"
                            formControlName="title">
                    </div> -->
                </div>
            </div>
            <div class="Submitbtn-content mt-5">
                <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
            </div>
        </ng-template>
    </li>

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">View Detail</a>
        <ng-template ngbNavContent>
            <!-- <div class="container-fluid">
                <div class="row justify-content-center">
                     <div class=" p-0">
                         <div class="table-responsive main-table-wrappr">
                             <table class="table row-border hover w-100 ">
                                 <thead>
                                     <tr>
 
                                         <th scope="col" resizable>S.No.</th>
                                         <th scope="col" resizable>Image</th>
                                         <th scope="col" resizable>Tittle</th>
                                         <th scope="col" resizable>Description</th>
                                         <th scope="col" resizable>Router Link</th>
                                         <th scope="col">Action</th>
                                     </tr>
 
                                 </thead>
 
                                 <tbody id="EditTableBody">
 
                                     <tr *ngFor="let data of Blogsdata ; let sr = index">
                                         <td>{{sr+1}}</td>
 
                                         <td>
                                             <img [src]="data.imageUrl" style="width: 15%;" alt="">                                           
                                         </td>
                                         <td>
                                             <div >{{data.title}}</div>
                                         </td>
                                         <td>
                                             <div class="why" >{{data.description}}</div>
                                        </td>
                                            
                                        <td>
                                            <div >{{data.routeKey}}</div>
                                        </td>
                                            
                                         <td style="width: 100px;">
                                             <a  href="javascript:void(0)" (click)="onClickEditForm( data)" ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                             <a  href="javascript:void(0)" (click)="deleteKey(sr)" ngbTooltip="Delete Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>
                                         </td>
                                     </tr>
 
                                 </tbody>
                             </table>
                         </div>
                     </div>
                 </div> 
             </div> -->


             <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="Blogsdata" (customActionDetail)="onClickEditForm($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>
</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>