<div>
    <div [formGroup]="ContactUpdateForm">
        <div class="row mt-4">
            <div class="row">
                <div class="col-md-12">
                    <label for="">Contact Details</label>
                    <input type="text" formControlName="TitleTxt" class="form-control">
                    <textarea class="form-control mt-2" formControlName="paratxt"   name="" id=""></textarea>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6">
                    <div>
                        <label for=""> Details</label>
                        <input class="form-control" formControlName="officialdetail"  type="text">
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Phone Number</label>
                        <input class="form-control" formControlName="officialNo"  type="text">
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Emails Address</label>
                        <textarea class="form-control" formControlName="officialEmail"  type="text"></textarea>
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Address</label>
                        <textarea class="form-control" formControlName="officialaddrss"  type="text"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <div>
                        <label for="">Branch Details</label>
                        <input class="form-control" formControlName="branchoffdetails" type="text">
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Phone Number</label>
                        <input class="form-control" formControlName="branchoffNo" type="text">
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Emails Address</label>
                        <textarea class="form-control" formControlName="branchoffEmail" type="text"></textarea>
                    </div>
                </div>

                <div class="col-md-6">
                    <div>
                        <label for="">Address</label>
                        <textarea class="form-control" formControlName="branchoffAdderess" type="text"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div>
                    <label for="">Text</label>
                    <input formControlName="bannertxt" class="form-control" type="text">

                    <textarea formControlName="bannertxtmsg" name="" class="form-control mt-2" id=""></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="Submitbtn-content mt-3  ">
        <!-- <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button> -->
        <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Update</button>
    </div>
</div>