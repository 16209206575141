import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Itableth} from '../../../allUsedModule'
import { Commonfunction } from 'src/app/shared/commonutility';
import { AdmissionPageModelService } from '../../../allFormPopUpModel/admission-page-model/admission-page-model.service';

@Component({
  selector: 'app-admission-link-dashboard',
  templateUrl: './admission-link-dashboard.component.html',
  styleUrls: ['./admission-link-dashboard.component.css']
})
export class AdmissionLinkDashboardComponent implements OnInit {
  activeTabId:number=1;
  AddNewFrom !:FormGroup;
  rowdata :any[]=[];
  Addmissiondata:any;

  tableHead : Itableth[] = [
    { "displayName": "Title", "colName": "title","isActionBtn":false,"isImagePreviewEnabled":false }
  ]

  heading = {
    title: ""
  }


  constructor(public commonfunction: Commonfunction, private admissionService: AdmissionPageModelService) { }

  ngOnInit(): void {
    this.Addmissiondata = this.commonfunction.loadJson("~/../assets/Admission.json");
    this.rowdata = this.Addmissiondata?.Addimssionlink?.AddimssionlinkData;


    this.AddNewFrom = new FormGroup({
      Admissionlink: new FormControl(this.Addmissiondata.Addimssionlink.Addimssionlinkheading,[Validators.required]),
      title: new FormControl('')
    })
  }


  onClickResetFrom() {
    const titleControl = this.AddNewFrom.get("title");
    
    // Check if each control is defined and not empty, then reset it
    if (titleControl && titleControl.value) {
        titleControl.setValue('');
    }
  }

  OnClickSubmit(){
    this.heading.title = this.AddNewFrom.get("title")?.value;
    this.Addmissiondata?.Addimssionlink?.AddimssionlinkData.push(this.heading);

    
    const titleControl = this.AddNewFrom.get('title');
    if (titleControl) {
      titleControl.setValue(''); 
    } else {
      console.warn('Title control is not found in the form group.');
    }
  }


  receiveData(msg:any){
    this.admissionService.AdmissionService(msg, true)
  }

}
