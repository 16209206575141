export class ObjectParam {
    TblName: string="";
    columnName: string="";
    ColumnType: string="";
    ColumnValue: string="";
    RequiredField: string="";
    ColSize: string="";
    ContType: string="";
    validation: string="";
    columnAliasName: string="";
    compareColumnAliasName: string="";
    CompareColumnName: string="";
    CompareColumnValue: string="";
    Rangestring: string="";
}

export class lstObjectParam {
    genericColumnList: any[]=[];
}
export interface UploadDoc {
    LlojiKampanjesId: number;
    FileToUpload: File;
}
export interface masterFormRowData {
    index: number;
    rowData: UserObjectParam;
}
export class UserObjectParam {
    rowId: string="";
    tbleName: string="";
    tableid: string = "";
    tbleAliasName: string="";  
    userRoleId: string="";  
    addPermission: string="";
    editPermission: string="";
    activePermission: string="";
    deletePermission: string="";
    printPermission: string="";
    isExcelDownload: string="";
    isPdfDownload: string="";
    checkBoxFlag: string="";
    mailTriggerSP: string="";
    tablePageStyle: string="";
    tableMailId: string="";
    tableWfId: string="";
    isHistory: string="";
    selWhereCondition: string="";
    selectQuery: string="";
    columnName: string="";
    dataType: string="";
    originalDataType: string="";
    columnLength: string="";
    originalColumnLength: string="";
    columnAliasName: string="";
    Validation: string="";
    checkExistsDataValidationType: string="";
    checkExistsDataValidationParams: string="";
    columnHide: string="";
    isEditDisabled: string="";
    isViewLink: string="";
    valueFillType: string="";
    dropDownChildtable: string="";
    dropDownQuery: string="";
    controlType: string="";
    isRequired: string="";
    isOriginalRequired: string="";
    compareColumnName: string="";
    range: string="";
    expressionJson: string="";
    columnSequence: string="";
    controlClass: string="";
    columnEventDefination: string="";
    customValidationMsg: string="";
    columnInformation: string="";
    columnDefaultValue: string="";
    miscellaneousType: string="";
    createColumnHide: string="";
    editColumnHide: string="";
    regExp: string="";
    columnHideView: string="";
    columnHideMobileView: string="";
    columnFilterMobileView: string="";
    columnHideMobileForm: string="";
    excelDownloadColumn: string="";
    pdfDownloadColumn: string="";
    child_column_name: string ="";
    iS_NULLABLE: string ="";
    identityColumn: string ="";
    parent_column_name: string = "";
    parent_table_name:string="";
    columnFlag: number=0;
    formTestPermission: string = "";
}
export class UserReportObjectParam {
    rowId: string="";
    formType: string="";
    procName: string="";
    procAliasName: string="";
    userRoleId: string="";
    tablePageStyle: string="";
    tablecheckBoxFlag: string="";
    tablemailTriggerSP: string="";
    isExcelDownload: string="";
    isPdfDownload: string="";
    columnName: string="";
    dataType: string="";
    columnLength: string="";    
    columnAliasName: string="";
    Validation: string="";
    columnHide: string="";    
    valueFillType: string="";
    dropDownChildtable: string="";
    dropDownQuery: string="";
    controlType: string="";
    isRequired: string="";   
    compareColumnName: string=""; 
    range: string="";
    expressionJson: string="";
    columnSequence: string="";
    controlClass: string="";
    baseTable: string="";
    customValidationMsg: string="";
    columnInformation: string="";
    regExp: string="";
    
}
export class dynamicformvalues {
    formName: string="";
    formValue: string="";
    menu: string="";
    subMenu: string="";

}
export class lstUserObjectParam {
    genericColumnList: any[]=[];
}
export class FormObjectParam {
    BaseTable: string="";
    formName: string="";
    sequence: string="";
    tableName: string="";
    parentTable: string="";
    tableAlias: string="";
    sumColumn: string="";
    customEvent: string="";
    isRequired: string="";
    id: string="";
    formNameId: string="";
    userRoleId: string="";
}

export class lstFormObjectParam {
    lstFormColumnList: any[]=[];
}

export class FormFinalObjectParam {
    tbleName: string="";
    tbleSequence: string="";
    columnName: string="";
    ColumnValue: string="";

}
export class lstFormFinalObjectParam {
    genericColumnListParam: any[]=[];
}

export class FormObjectSubmitParam {
    TblName: string="";
    columnName: string="";
    ColumnType: string="";
    ColumnValue: string="";
    RequiredField: string="";
    ColSize: string="";
    ContType: string="";
    sequence: string="";

}

export class lstFormObjectSubmitParam {
    lstFormColumnList: any[]=[];
}

export class GISFile {
    id: string="";
    fileName: string="";
}
export class lstGISFileObjectParam {
    genericColumnListParam: any[]=[];
}

export class GISCoordinates {
    id: string="";
    lat: string="";
    long: string="";
    markericon: string="";
    beatId: string="";
    createdOn: string="";
    employeeId: string="";

}

export class MultiTableEntries {
    id: string="";
    baseTable: string="";
    formName: string="";
    parentTableName: string="";
    tableName: string="";
    tableAliasName: string="";
    isRequired: string="";
    formNameId: string="";
    sumColumn: string="";
    customEvent: string="";
    userRoleId: string = "";
    
}

export class customAction {
    rowId: string="";
    isChecked: string="";
    param1: string="";
    param2: string="";
}
export class lstcustomAction {
    genericColumnList: any[]=[];
}

