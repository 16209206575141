import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterContentChecked,ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { UserService } from '../../shared/user.service';
import { Subscription, Subject } from 'rxjs';
import { formTypeEnum } from '../model/formTypeEnum';
import { Commonfunction } from '../commonutility'
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalAlertService } from '../global-alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PreviewImageService } from '../preview-image/preview-image.service';
import { Customvalidation } from "../../shared/Validation";
import { WorkFlowProcessServiceService } from '../work-flow-process/work-flow-process-service.service';
import { ConfirmDialogBoxService } from '../confirm-dialog-box-event/confirm-dialog-box.service';
import { GenericPrintService } from '../generic-print-service';
import { IFilterColumns, IviewFullDetails, IgenericFilterParamValue,IgenericMemoryTablePageConfig, IActualQueryFilter, IDisplayQueryFilter, IviewFullDetailRowData } from '../model/genericTableListViewModel';
import { ExcelService } from '../excel.service';
import { lstObjectParam, customAction } from '../object-param.model';
import { GenericChildActionModalServiceService } from '../generic-child-action-modal/generic-child-action-modal-service.service';
import { NgbCalendar,  NgbDateAdapter,  NgbDateParserFormatter,  NgbDateStruct, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../ngbDatePickerFormatter.service'
import { add } from 'ngx-bootstrap/chronos';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-frontend-setting-table',
  templateUrl: './frontend-setting-table.component.html',
  styleUrls: ['./frontend-setting-table.component.css']
})
export class FrontendSettingTableComponent implements OnInit,OnDestroy, AfterContentChecked {
  p = 1;
  searchText: string ="";
  isShowHeaderCheckbox: boolean = false;
  config: IgenericMemoryTablePageConfig = {} as IgenericMemoryTablePageConfig;
  isDateFilter: boolean = false;
  hoveredDate: NgbDate | null = null;
  rangeDates: string ="";
  buttom_clicked:boolean = true;
  isShowSecondFilterControl: boolean = false;
  filterDataType: number = 0;
  filterControlType: string = "";
  
  columnHideView: boolean = false;
  columnHideMobileView: boolean = false;
  columnFilterMobileView: boolean = false;
  columnHideMobileForm: boolean = false;
  popoverEvent:any;
  filterForm!: FormGroup;
  filterFormValue: IFilterColumns= {} as IFilterColumns;
  filterColumnName: string = "";
  filterColumnDataType:string = ""
  filterColumnParentTableName:string = ""
  filterColumnDisplayName: string = "";
  filterColumnQuery: IFilterColumns[] = []
  _filterFromMainScreen: string ="";
  _isRenderDetail: number=0;
  _preFilterParmValue: Array<IgenericFilterParamValue> = [] as Array<IgenericFilterParamValue>;
  ActionParamvalue: string = "";
  childActionParam: string="";
  excelPDFCall:string ="T";
  filterDisplayQuery: Array<IDisplayQueryFilter> =[];
  filterActualQuery: Array<IActualQueryFilter> = [];
  jsonDataFilterStringfinal: string = '';
  jsonDataFilterStringMobilefinal: string = '';
  @Input() spName: string="";
  @Input() formName: string="";
  @Input() formType: formTypeEnum = 1;
  @Input() editPermission: boolean = false;
  @Input() activePermission: boolean = false;
  @Input() deletePermission: boolean = false;
  @Input() customActionPermission: boolean = true;
  @Input() printPermission: number = 0;
  @Input() isWorkflowImplemented: boolean = false;
  @Input() ischeckboxshow:number = 0;
  @Input() sendSelectedData:boolean = false;
  @Input() isExcelDownload: boolean = false;
  @Input() isPdfDownload: boolean = false;
  @Input() customActionToolTip: string = "";
  @Input() customActionIcon: string = "";
  @Output() viewFullDetail = new EventEmitter<IviewFullDetailRowData>();
  @Output() customActionDetail = new EventEmitter<any>();
  @Output() getExportExcelMainFilter = new EventEmitter();
  @Output() viewRowData = new EventEmitter<any>();
  @Output() editRowData = new  EventEmitter<any>();
  _preFilterParmValueString: string = ""; 
  columnWidth: string="200px";
  @Input()
  set isRenderDetail(target:number){
    this._isRenderDetail = target;
    // this.bindSearchDebounce();

    // this.getFormDetailsData("T");

  }
  get isRenderDetail(){
    return this._isRenderDetail ;
  }
  @Input()
  set filterFromMainScreen(target: string){
    if(target != null && target != undefined){     
      this._filterFromMainScreen = target;
  }
  }

  get filterFromMainScreen(){
    return this._filterFromMainScreen ;
  }
  @Input() set preFilterParmValue(target : Array<IgenericFilterParamValue>){
    if(target != null && target != undefined && target.length > 0){
    this._preFilterParmValue = target;    
    this._preFilterParmValueString = '';
    this._preFilterParmValue.forEach((value:IgenericFilterParamValue, index: number)=>{     
      this._preFilterParmValueString = this._preFilterParmValueString + "|"+value.paramName+"="+value.paramValue;      
    });   
   
  }
  }
  get preFilterParmValue(){
    return this._preFilterParmValue;
  }

  
    @Input() dropdownListActionGroupSpdrp:any = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownSettings:any = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box",
      disabled: false
  };
  isFilterShow: boolean = false;
  updateRecordID: string="";
  ViewDetailChildtableHeaderData = [];
    ViewDetailChildtableRowData = [];
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    @Input() tableCols:any[] = [];
    tableExportCols:any[] = [];
    searchColsVal:any={};
    listViewSelectedSearchCol:any = 'id';
    @Input() DetailtableRowData:any[] = [];
//  DetailtableRowData2:any[] = [];
  DetailtableRowDatafromDB:any[] = [];
  DetailtableExportRowData:any[] = [];
  masterSelected: boolean = false;
  selectedRows:any = [];
  tooltipHeader:any = [];
  dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
  selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
  resultData: any;
  GenericPrintDataArray: any[]=[];
  allEmployeeData: any[] = [];
  mobileView: boolean = false;
  totalRecords:number = 0;
  currentPage:number = 1;
  pageSize:number = 10;
  tableOptions:any = {
    limit:this.pageSize,
    offset:0
    // sort options to be added later
  }
  sortOrder: string ="DESC";
  sortColumn:string = "1";
  // tableCols:any[] = [
  //   {key:'id',displayName:'Id'},
  //   {key:'code',displayName:'Code'},
  //   {key:'fName',displayName:'First Name'},
  //   {key:'lName',displayName:'Last Name'},
  //   {key:'total',displayName:'Total'},
  //   {key:'availed',displayName:'Availed'},
  //   {key:'balance',displayName:'Balance'}
  // ];

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  private readonly searchSubject = new Subject<string | undefined>();
  private readonly getDataSubject = new Subject<string | undefined>();
  private searchSubscription?: Subscription;
  private getDataSubscription?: Subscription;
  minDate(year: number) {
    let currentdate = new Date();
    let Cmonth = currentdate.getMonth() + 1;
    let Cdate = currentdate.getDate();
    let Cyear = currentdate.getFullYear() - year;
    return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
}
maxDate(year: number) {
    let currentdate = new Date();
    let Cmonth = currentdate.getMonth() + 1;
    let Cdate = currentdate.getDate();
    let Cyear = currentdate.getFullYear() + year;
    return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
}
bsConfig:any;

  constructor(private userService: UserService, private commonfunction: Commonfunction, private spinner: NgxSpinnerService, private GlobalWorkFlowProcessService: WorkFlowProcessServiceService,
    private globalAlertService: GlobalAlertService, private previewImageService: PreviewImageService, public Customvalidation: Customvalidation, private excelService: ExcelService,
     private globalConfirmService: ConfirmDialogBoxService, private genericPrintService: GenericPrintService, private changeDetectorRef : ChangeDetectorRef,private genChildActionService:GenericChildActionModalServiceService,
     private ngbCalendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>, private route: ActivatedRoute, private router: Router) {
      this.columnWidth = this.commonfunction.loadJson("~/../assets/config.json")['wordWrapWidth'];
      console.log("Naveen In Memory 1");
    console.log(this.formName);
   
 
 
  this.config.currentPage = this.currentPage;
  this.config.itemsPerPage = this.pageSize;
 
  // this.route.queryParamMap.pipe(
  // map((params:any) => params.get("page").json())
  // .subscribe((page:any) => (this.config.currentPage = page));
  }
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  ngOnInit(): void {
    // console.log("Naveen In Memory 2");
    this.totalRecords = this.DetailtableRowData?.length;
   
    // console.log(this.totalRecords,"total recordes");
    // console.log(this.DetailtableRowData,"row data");
    // console.log(this.DetailtableExportRowData);
  }

  

  ngAfterContentChecked() : void {
    this.changeDetectorRef.detectChanges();
}
  
  filterDatafromOriginalDBData(){

    this.DetailtableRowData.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
      this.totalRecords = this.DetailtableRowData?.length;

      console.log(this.totalRecords,"function total recrd");
      

    // if(this.jsonDataFilterStringfinal !=""){
   
      
    // this.DetailtableRowData =  this.DetailtableRowDatafromDB.filter((x:any)=> eval(this.jsonDataFilterStringfinal))
    // .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    // this.totalRecords = this.DetailtableRowData?.filter((x:any)=> eval(this.jsonDataFilterStringfinal)).length;
    // console.log(this.totalRecords);
    
    // }
    // else{
    //   this.DetailtableRowData =  this.DetailtableRowDatafromDB
    //   .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    //   this.totalRecords = this.DetailtableRowData?.length;
    // }   
  }

  

  pageSizeChange(){
    this.currentPage = 1;
    this.filterDatafromOriginalDBData();
    // let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    // this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;
    this.DetailtableRowData.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    console.log(this.DetailtableRowData);
    
  }

   ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}
 



OnCustomActionClickDetail(event:any, rowData:any) {
  let viewFullDetailsParam: IviewFullDetailRowData = {} as IviewFullDetailRowData;
  viewFullDetailsParam.event = event;
  viewFullDetailsParam.rowData = rowData;
  // console.log(rowData,"viewdataparam");
  
  this.customActionDetail.emit(rowData);
}



openCustomActionModal() {
  this.globalConfirmService.ConfirmFunction("Confirm Action !!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
              this.custumActioncall();
              this.ActionParamvalue = '';
          }
      });
}

custumActioncall() {

  this.spinner.show();
  let data:string = JSON.stringify("uspAppGenericCustumActionforAllObject|spName=" + this.childActionParam + "|formName=" + this.formName + "|rowIds=" + this.ActionParamvalue +"|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
  this.userService.CustumActionSubmitForm(data, this.formName).subscribe((datacom: any) => {
      this.spinner.hide();

      if (datacom != "") {
          let resultarray = JSON.parse(datacom);
          // resultarray['successFlag']
          // resultarray['resultMessage']
          // resultarray['printAction']
          // resultarray['printActionResult']
          // resultarray['otherAction']
          // resultarray['otherActionResult']
          // resultarray['outputVarResult']

          this.masterSelected = false;
          //this.resetForm();
          if (this.selectedItemsActionGroupSpdrp.length > 0)
              this.selectedItemsActionGroupSpdrp.splice(0, this.selectedItemsActionGroupSpdrp.length);
          if (resultarray['successFlag'] == "success") {
              // this.masterProductForm.reset();
              this.AlertTitle = "Success!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-success-bg";
              this.AlertAnimationType = "smile";
              this.AlertType = "NP_popup_success";
          }

          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }

      }
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      }
  );
}

countTotalRecords(){
  let showRecord = this.currentPage * this.pageSize >= this.totalRecords ? this.totalRecords : this.currentPage * this.pageSize;
  return showRecord;
}
  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.getDataSubscription?.unsubscribe();
  }

 
  pageChange(event: any) {
    this.filterDatafromOriginalDBData();
    let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false; 
  }

  openSideFilter(){
    console.log("okk");
  }

  OnCustomActionClickDetailView(value:any){
   this.viewRowData.emit(value) 
  }

  OnCustomActionClickDetailEdit(value:any){
    this.editRowData.emit(value)
  }
}
