import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import { IcontactUs } from '../../allUsedModule';

@Component({
  selector: 'app-contactus-dashboard',
  templateUrl: './contactus-dashboard.component.html',
  styleUrls: ['./contactus-dashboard.component.css']
})
export class ContactusDashboardComponent implements OnInit {
  ContactUpdateForm !:FormGroup;
  contactUs:any;

  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.contactUs = this.commonfunction.loadJson("~/../assets/Contactus.json");

    this.ContactUpdateForm = new FormGroup({
      TitleTxt: new FormControl(this.contactUs.contactdetailstitle,[Validators.required]),
      paratxt: new FormControl(this.contactUs.contactdetail,[Validators.required]),
      officialdetail: new FormControl(this.contactUs.stateaddrssing,[Validators.required]),
      officialNo: new FormControl(this.contactUs.stateaddrssingno,[Validators.required]),
      officialEmail: new FormControl(this.contactUs.stateaddrssingEmail,[Validators.required]),
      officialaddrss: new FormControl(this.contactUs.stateaddrssingAdd,[Validators.required]),
      branchoffdetails: new FormControl(this.contactUs.branchaddrssing,[Validators.required]),
      branchoffNo: new FormControl(this.contactUs.branchaddrssingno,[Validators.required]),
      branchoffEmail: new FormControl(this.contactUs.branchaddrssingEmail,[Validators.required]),
      branchoffAdderess: new FormControl(this.contactUs.branchaddrssingAdd,[Validators.required]),
      bannertxt: new FormControl(this.contactUs.rigthsidetxt,[Validators.required]),
      bannertxtmsg: new FormControl(this.contactUs.rigthsidedetails,[Validators.required]),
    })
  }

  OnClickSubmit(){
    let _item : IcontactUs = {} as IcontactUs;
    _item.contactdetailstitle = this.ContactUpdateForm.get('TitleTxt')?.value;
    _item.contactdetail = this.ContactUpdateForm.get('paratxt')?.value;
    _item.stateaddrssing = this.ContactUpdateForm.get("officialdetail")?.value;
    _item.stateaddrssingno = this.ContactUpdateForm.get("officialNo")?.value;
    _item.stateaddrssingEmail = this.ContactUpdateForm.get("officialEmail")?.value;
    _item.stateaddrssingAdd = this.ContactUpdateForm.get("officialaddrss")?.value;
    _item.branchaddrssing = this.ContactUpdateForm.get("branchoffdetails")?.value;
    _item.branchaddrssingno = this.ContactUpdateForm.get("branchoffNo")?.value;
    _item.branchaddrssingEmail = this.ContactUpdateForm.get("branchoffEmail")?.value;
    _item.branchaddrssingAdd = this.ContactUpdateForm.get("branchoffAdderess")?.value;
    _item.rigthsidetxt = this.ContactUpdateForm.get("bannertxt")?.value;
    _item.rigthsidedetails = this.ContactUpdateForm.get("bannertxtmsg")?.value;
    console.log(_item);
  }

}
