<div id="AlertDialogModal" class="new-modal-box" [class]="AlertDialogType ">
    <div role="document" class="new-modal">
        <div class="modal-content filter_side_modal">

<div class="dynmic_filter">
    <div class="filter_wrapper">
        <div class="top_heading">
            <h6>filter by click</h6>
        </div>
        <div class="bottmrow"></div>
        <div class="andOrbtn">
            <button>AND</button>
            <button>OR</button>
        </div>
        <div class="selector_box">
            <select class="selector_filter" name="filter_select" id="">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
            </select>
        </div>
        <div class="filter_input_box">
            <input type="text" class="filter_input" placeholder="Sub Exam"/>
        </div>
        <div class="btn_box_filter">
            <div class="clear_filter_btn"><button>Clear</button></div>
            <div class="apply_filter_btn"><button>Apply</button></div>
        </div>
    </div>
    </div>
    </div>
</div>
</div>












