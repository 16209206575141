<div class="row printData col-sm-12 text-left pt-4 mb-4 mx-0 px-3 " *ngFor="let printData of printDataArray; let i = index">
 <div [innerHTML]="printData.template.toString()  | safehtml" >

 </div>
</div>

<!-- <div> 
 
    <table width="100%" border="1" bordercolor="#F1F1F1" align="center" cellpadding="0" cellspacing="0" style="max-width:700px;margin:0 auto;">
        <tr>
          <td align="center" valign="middle">
     <table width="100%" border="0" cellspacing="0" cellpadding="0">
         
      
      </table>
      
      
      
      
      <table width="100%" border="0" cellspacing="0" cellpadding="0">
        
        <tr>
          <td align="center" valign="top" >
            <a href="#" style="display: block; border-bottom: 1px solid #eee; margin-bottom: 20px;"> 
               <img src="assets/School/BRPS-001.png" style="width:100%;" alt="School Name"> 
               </a>
          </td>
          </tr>  
       
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <div style="padding: 20px 10px 15px; margin: 15px; border: 1px solid #d8d8d8; position: relative; border-radius: 4px;">
                      <span style="position: absolute; top: -20px; left: 20px; padding: 6px 20px; border-radius: 4px; background: #012257;
                       border: 1px solid #d8d8d8; color: #fff; font-weight: bold;">Student Info</span>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 10px;">
                            <span>Admission No.:-</span><label style="padding-left: 10px;"><strong>@@AdmissionNumber</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Old Admission No.:-</span><label style="padding-left: 10px;"><strong>@@OldAdmissionNumber</strong></label>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <span>Student Name :-</span><label style="padding-left: 10px;"><strong>@@StudentName</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Date of Birth :-</span><label style="padding-left: 10px;"><strong>@@DateOfBirth</strong></label>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
      
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <div style="padding: 20px 10px 15px; margin: 15px; border: 1px solid #d8d8d8; position: relative; border-radius: 4px;">
                      <span style="position: absolute; top: -20px; left: 20px; padding: 6px 20px; border-radius: 4px; background: #012257;
                       border: 1px solid #d8d8d8; color: #fff; font-weight: bold;">Parent/Gurdian Info</span>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 10px;">
                            <span>Father's Name :-</span><label style="padding-left: 10px;"><strong>@@FatherName</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Occupation :-</span><label style="padding-left: 10px;"><strong>@@FatherOccupation</strong></label>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <span>Qualification :-</span><label style="padding-left: 10px;"><strong>@@FatherQualification</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Parents Income :-</span><label style="padding-left: 10px;"><strong>@@ParentIncome</strong></label>
                          </td>
                        </tr>
                         <tr>
                          <td style="padding: 10px;">
                            <span>Mother's Name :-</span><label style="padding-left: 10px;"><strong>@@MotherName</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Mother's Occupation :-</span><label style="padding-left: 10px;"><strong>@@MotherOccupation</strong></label>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <span>Mother's Qualification :-</span><label style="padding-left: 10px;"><strong>@@MotherQualification</strong></label>
                          </td> 
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
      
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <div style="padding: 20px 10px 15px; margin: 15px; border: 1px solid #d8d8d8; position: relative; border-radius: 4px;">
                      <span style="position: absolute; top: -20px; left: 20px; padding: 6px 20px; border-radius: 4px; background: #012257;
                       border: 1px solid #d8d8d8; color: #fff; font-weight: bold;">Address/Contact Info</span>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 10px;">
                            <span>Primary Contact :-</span><label style="padding-left: 10px;"><strong>@@PrimaryContact</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Secondry Contact :-</span><label style="padding-left: 10px;"><strong>@@SecondryContact</strong></label>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <span>Caste :-</span><label style="padding-left: 10px;"><strong>@@Caste</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Religion :-</span><label style="padding-left: 10px;"><strong>@@Religion</strong></label>
                          </td>
                        </tr>
                         <tr>
                          <td style="padding: 10px;">
                            <span>Category :-</span><label style="padding-left: 10px;"><strong>@@Category</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Address :-</span><label style="padding-left: 10px;"><strong>@@Address</strong></label>
                          </td>
                        </tr> 
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
      
           <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <div style="padding: 20px 10px 15px; margin: 15px; border: 1px solid #d8d8d8; position: relative; border-radius: 4px;">
                      <span style="position: absolute; top: -20px; left: 20px; padding: 6px 20px; border-radius: 4px; background: #012257;
                       border: 1px solid #d8d8d8; color: #fff; font-weight: bold;">Admission Info</span>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 10px;">
                            <span>Previous School :-</span><label style="padding-left: 10px;"><strong>@@PreviousSchool</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Previous Class :-</span><label style="padding-left: 10px;"><strong>@@PreviousClass</strong></label>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <span>Class for Which Addmission Sought:-</span><label style="padding-left: 10px;"><strong>@@ClassforWhichAddmissionSought</strong></label>
                          </td>
                          <td style="padding: 10px;">
                            <span>Section :-</span><label style="padding-left: 10px;"><strong>@@Section</strong></label>
                          </td>
                        </tr>
                         <tr>
                          <td style="padding: 10px;">
                            <span>Session :-</span><label style="padding-left: 10px;"><strong>@@Session</strong></label>
                          </td> 
                        </tr> 
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
      
          <tr >
            <td>
              
                @@RouteInfo
            </td>
          </tr>
      
          <tr>
            <td>
                      
                @@SiblingInfo
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <div style="padding: 20px 10px 40px; margin: 15px; position: relative; border-radius: 4px;"> 
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td style="padding: 10px;">
                           Date:- <span>@@TodayDate</span>
                          </td>
                          <td style="padding: 10px; text-align: right;">
                            <span>Authurised Signature</span>
                          </td>
                        </tr> 
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
      </table>
      </td>
      
          </tr>
        
    
      
        
      
      </table>
    
      
    </div>  -->

