import { Component, ElementRef, HostBinding } from "@angular/core";

@Component({
  selector: 'th[resizable]',
  templateUrl: './generic-table-resizable-column-directive.component.html',
  styleUrls: ['./generic-table-resizable-column-directive.component.css']
})
export class GenericTableResizableColumnDirectiveComponent  {


  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) {
    this.width = width;
  }

}
