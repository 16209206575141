import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { async } from '@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";

import { NgxSpinnerService } from 'ngx-spinner';
import { UserObjectParam, lstUserObjectParam, ObjectParam, lstObjectParam, masterFormRowData } from "../../shared/object-param.model";
import { DataTableDirective } from 'angular-datatables';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { IFilterColumns, IviewFullDetails } from '../../shared/model/genericTableListViewModel';
import { MasterFormRowdataPopupService} from '../../shared/master-form-rowdata-popup/master-form-rowdata-popup.service'
// import  'src/typings';
import 'jquery';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare let jQuery: any;

@Component({
  selector: 'app-generate-master-form',
  templateUrl: './generate-master-form.component.html',
  styleUrls: ['./generate-master-form.component.css']
})
export class GenerateMasterFormComponent implements OnDestroy, OnInit {

  totalRecords:number = 0;
  pageSize:number = 10;
  currentPage:number = 1;
  sortOrder: string ="ASC";
  sortColumn:string = "1";
  popoverEvent:any;
  activeTabId: number = 1;
  filterFormValue: IFilterColumns= {} as IFilterColumns;
  filterColumnName: string = ""; 
  filterColumnDataType:string = ""
  filterColumnParentTableName:string = ""
  filterColumnDisplayName: string = "";
  filterColumnQuery: IFilterColumns[] = [];  
  filterForm!: FormGroup;
  filterDisplayQuery: Array<{ columnName: string, displayQuery: string, operator: string }> =[];
  filterActualQuery: Array<{ columnName: string, columnValue: string, conditionOperator: string , filterColConditionOperator:string}> = [];
  dropDownValues:any[] =[];
  tableCols:any[] = [
    {key:'tableName',displayName:'Table Name'},
    {key:'tableAliasName',displayName:'Table Alias Name'},
    {key:'userRole',displayName:'Table User'},
    {key:'addPermission',displayName:'Add Permission'},
    {key:'editPermission',displayName:'Edit Permission'},
    {key:'activePermission',displayName:'Active Permission'},
    {key:'deletePermission',displayName:'Delete Permission'},
    {key:'printPermission',displayName:'Print Permission'},
    {key:'formTestPermission',displayName:'Form Test Permission'},
    {key:'checkBoxFlag',displayName:'CheckBox Show'},
    {key:'mailTriggerSP',displayName:'Action Group'},
    {key:'mailtemp',displayName:'Mail Templte'},
    {key:'workflow',displayName:'Work Flow'},
    {key:'isExcelDownload',displayName:'Is Excel Download'},
    {key:'isPdfDownload',displayName:'Is Pdf Download'},
    {key:'pageStyle',displayName:'Page Style'},
    {key:'isHistory',displayName:'History'}
  ]; 


  SeltableID: string="";
  SeltableIDEDit: string="";
  SeltableIDForm: string="";
  savedTablename: string="";
  public txtTableAlias: string = '';
  public txtTableAliaswhereCondition: string = '';
  public txtTableAliasSelect: string = '';
  public txtTableAliasE: string = '';
  public txtTableAliaswhereConditionE: string = '';
  public txtTableAliasSelectE: string = '';
  @ViewChild(DataTableDirective) 
  dtElement!: DataTableDirective;
  validationdrpOption: string = '';
  controldrpOption: string = '';
  controldrpOptionDate: string = '';
  controldrpOptionNumber: string = '';
  valueFillTypedrpOption: string = '';
  MailTemplatedrpOption: string = 'testtt';
  wfdrpOption: string = '';
  PageStyledrpOption: string = '';
  controldrpOption2: string = '';
  validationdrpOption2: string = '';

  viewtableHeaders: TableColumnName[] = []; 
  
  mainTableId: string="";
  mainTableIdE: string="";
  selectedTableId: string="";
  tableData:Array<UserObjectParam> = [];
  viewTableData:any = [];
  editTableData:Array<UserObjectParam> = [];
  detailTableData:any = [];
  tableid: string="";
  Edittableid: string="";
  display = "none";
  isCreatedDivRender:boolean = false;
  displayMainEdit = "none";
  displayContainer = "block";
  sel: any;
  callfor: string="";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "NP_popup_success";
  AlertAnimationType: any = "smile";
  tblecount: number=0;
  public value: string[]=[];
  public current: string="";
  public Validationcurrent: string="";
  public selected: string="";
  public dynamicdivdata: any;
  public exampleArray: Array<{ id: string, text: string }>=[];
  dropdownListTable = [];
  selectedItemsTable: Array<{ id: string, itemName: string }> = [];
  dropdownListRole = [];
  selectedItemsRole: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadValidationdrp = [];
  selectedItemspageloadValidationdrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControldrp = [];
  selectedItemspageloadControldrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControlDatedrp = [];
  selectedItemspageloadControlDatedrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControldrpNumber = [];
  selectedItemspageloadControldrpNumber: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadValueFillTypedrp = [];
  selectedItemspageloadValueFillTypedrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadMailTemplatedrp = [];
  selectedItemspageloadMailTemplatedrp: Array<{ id: string, itemName: string }> = [];
  selectedItemspageloadMailTemplatedrpE: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadWfdrp = [];
  selectedItemspageloadWfdrp: Array<{ id: string, itemName: string }> = [];
  selectedItemspageloadWfdrpE: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadPageStyle: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: '1 control in line' },
  { id: 2, itemName: '2 control in line' },
  { id: 3, itemName: '3 control in line' },
  { id: 4, itemName: '4 control in line' }];
  selectedItemspageloadPageStyle: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: '1 control in line' }];
  selectedItemspageloadPageStyleE: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: '1 control in line' }];
  dropdownListpageloadCreatePermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadCreatePermission: Array<{ id: number, itemName: string }> = [{ id:1, itemName: 'Yes' }];
  selectedItemspageloadCreatePermissionE: Array<{ id: number, itemName: string }> = [];
  dropdownListpageloadEditPermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadEditPermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' }];
  selectedItemspageloadEditPermissionE: Array<{ id: number, itemName: string }> = [];
  dropdownListpageloadIsHistory: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadIsHistory: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadIsHistoryE: Array<{ id: number, itemName: string }> = [];

  dropdownListpageloadIsExcel: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadIsExcel: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadIsExcelE: Array<{ id: number, itemName: string }> = [];

  dropdownListpageloadIsPdf: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadIsPdf: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadIsPdfE: Array<{ id: number, itemName: string }> = [];

  dropdownListpageloadActivePermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadActivePermission: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadActivePermissionE: Array<{ id: number, itemName: string }> = [];
  dropdownListpageloadTestForm: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadTestForm: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadTestFormE: Array<{ id: number, itemName: string }> = [];
  dropdownListpageloadDeletePermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadDeletePermission: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadDeletePermissionE: Array<{ id: number, itemName: string }> = [];
  dropdownListpageloadPrintPermission: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes- Same Window' },
  { id: 2, itemName: 'Yes- New Window' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadPrintPermission: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadPrintPermissionE: Array<{ id: number, itemName: string }> = [];

  dropdownListReportCheckBoxAction: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox' },
  { id: 1, itemName: 'Yes Checkbox' },
  { id: 2, itemName: 'Select All' }];
  selectedItemsReportCheckBoxAction: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox' }];
  selectedItemsReportCheckBoxActionE: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox' }];
  dropdownListpageloadActiondrp = [];
  selectedItemspageloadActiondrp: Array<{ id: string, itemName: string }> = [];
  selectedItemspageloadActiondrpE: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadDataExists = [];
  validationdropdownList = [];
  selectedItems: string = '';
  validationselectedItems = [];
  dropdownSettings = {};
  dropdownGridSettings = {};
  ValidationdropdownSettings = {};
  changeDetectorRefs: ChangeDetectorRef[] = [];
  paramvaluedata: any;
  column_Data: any[]=[];
  param: any;
  menulabel: string="";
  formlable: string="";
  constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation, private userService: UserService,
    private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, private spinner: NgxSpinnerService, private masterFormRowdataPopupService:MasterFormRowdataPopupService,
    private http: HttpClient, private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService) {


  }

  ngOnInit() {

    this.param = sessionStorage.getItem("menuItem");
    // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
    let paramjs = JSON.parse(this.param);
    this.SeltableID = paramjs.formValue;
    this.SeltableIDForm = paramjs.formName;
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.pageloaddrp();    
    this.onMainFormTab();
    this.dropdownSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
    this.dropdownGridSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      position: 'bottom',
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "form-control myclass custom-class"
    };

  }

  ngOnDestroy(): void {
  
  }

 

  pageloaddrp() {
    let item = '';
    let itemMulti = '';
    
    let param ="uspAppGenericGetAllRole";
    this.userService.getCommonDrpdownList(this.SeltableIDForm!.toString(),param).subscribe((res: any) => {
      this.dropdownListRole = res;
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      });

  }
  onMainFormTab() { 
    this.SeltableIDEDit = '';
    this.paramvaluedata = ''; 
    this.displayMainEdit = "none";
    this.isCreatedDivRender = false;
    this.displayContainer = "block";
  }
  onViewDetailTab() {
    this.SearchEdit(1);
    this.paramvaluedata = '';    
    this.isCreatedDivRender = false;
    this.displayContainer = "none";
    this.selectedItemsTable = [];
  }

  onItemSelect(item: any) {
    let param ="uspAppGenericGetTableNamesWithoutAlias|roleId="+  this.selectedItemsRole[0].id!.toString();
    this.userService.getCommonDrpdownList(this.SeltableIDForm!.toString(),param).subscribe((res: any) => {     
      this.dropdownListTable = res;
      if(this.selectedItemsTable.length > 0)
      {
        this.selectedItemsTable.splice(0,this.selectedItemsTable.length)
      }
     
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      ()=>{
        
      });
  }
  OnItemDeSelect(item: any) {
  
      if(this.dropdownListTable.length > 0)
      {
        this.dropdownListTable.splice(0,this.dropdownListTable.length)
      }
      if(this.selectedItemsTable.length > 0)
      {
        this.selectedItemsTable.splice(0,this.selectedItemsTable.length)
      }  
  }

  onAddclick() {
    this.Search(this.selectedItemsTable);
  }

  Search(tbname:any) {
    // this.isRowRendered = false;
    if (tbname.length > 0) {
      const that = this;
      this.tableData =[];
      this.dropDownValues =[];
      this.userService.getUserMasterFormcolumnDetails(tbname[0]['id'], this.SeltableIDForm, this.selectedItemsRole[0].id).subscribe((data: any) => {
        this.mainTableId = "Dynamictable" + tbname[0]['id'];
        this.selectedTableId = tbname[0]['id'];
        // this.tableData = data.table;
        data.table.forEach((x:any, i:number)=>{
          let rowData: UserObjectParam = {} as UserObjectParam;        
          rowData.columnSequence = i.toString();
          rowData.tbleName = x['tblname'];
          rowData.columnAliasName = x['columnAliasName'] != null && x['columnAliasName'] != ""? x['columnAliasName'] : x['columN_NAME'];
          rowData.child_column_name = x['child_column_name'];
          rowData.columnName = x['columN_NAME'];
          rowData.columnLength = x['columnLength'] ;
          rowData.originalColumnLength = x['columnLength'];
          rowData.isOriginalRequired = x['iS_NULLABLE'].toLowerCase();
          rowData.dataType = x['datA_TYPE'];
          rowData.iS_NULLABLE = x['iS_NULLABLE'].toLowerCase();
          rowData.identityColumn = x['identityColumn'];
          rowData.parent_column_name = x['parent_column_name'];
          rowData.parent_table_name = x['parent_table_name'];
          rowData.Validation = "";
          rowData.checkExistsDataValidationParams = "";
          rowData.checkExistsDataValidationType = "";
          rowData.columnDefaultValue = "";
          rowData.columnEventDefination = "";
          rowData.columnFilterMobileView = "";
          rowData.columnHide = "" ;
          rowData.columnHideMobileForm = "";
          rowData.columnHideMobileView = "";
          rowData.columnHideView = "";
          rowData.columnInformation = "";
          rowData.compareColumnName = "";
          rowData.controlClass = "";
          rowData.controlType = "";
          rowData.createColumnHide = "";
          rowData.customValidationMsg = "";
          rowData.dropDownChildtable = "";
          rowData.dropDownQuery = "";
          rowData.editColumnHide = "";
          rowData.expressionJson = "";
          rowData.isEditDisabled = "";
          rowData.isViewLink = "";
          rowData.miscellaneousType = "";
          rowData.pdfDownloadColumn = "";
          rowData.excelDownloadColumn = "";
          rowData.range = "";
          rowData.regExp = "";
          rowData.valueFillType = data.table5[0]['id'];
          this.tableData.push(rowData);
        })
        console.log(this.tableData);
        this.dropDownValues.push(data.table1);
        this.dropDownValues.push(data.table2);
        this.dropDownValues.push(data.table3);
        this.dropDownValues.push(data.table4);
        this.dropDownValues.push(data.table5);
        // this.dropDownValues.push(data.table6);
        // this.dropDownValues.push(data.table7);
        this.dropDownValues.push(data.table8);
        this.dropDownValues.push(data.table9);
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;
        this.dropdownListpageloadControlDatedrp = data.table3;
        this.dropdownListpageloadControldrpNumber = data.table4;
        this.dropdownListpageloadValueFillTypedrp = data.table5;
        this.dropdownListpageloadMailTemplatedrp = data.table6;
        this.dropdownListpageloadWfdrp = data.table7;
        this.dropdownListpageloadDataExists = data.table8;
        this.dropdownListpageloadActiondrp = data.table9;       
        this.txtTableAlias = tbname[0]['id']!.toString();
        
      },
      (err: HttpErrorResponse) => {},
      ()=>{
        this.isCreatedDivRender = true;
      });
    }
    else {
      this.globalAlertService.AlertMsg("Alert!!", "Please Select Table.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");

    }
  };

  openResetModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.OnResetModal();
        }
      });
  }

  OnResetModal() {
    this.isCreatedDivRender = false;
    this.displayMainEdit = "none";
    this.displayContainer = "block"; 
    this.SeltableIDEDit = '';    
    this.paramvaluedata = '';   
    this.selectedItemsTable = [];
    this.selectedItemsRole = [];
  }
  openSubmitModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.submitcall();
          this.paramvaluedata = '';
        }
      });
  }

  OnSubmitModal() {   
    let tablePageStyle = $.trim(this.selectedItemspageloadPageStyle[0].id!.toString());
    let mailId = '';
    if (this.selectedItemspageloadMailTemplatedrp.length > 0) {
      mailId = this.selectedItemspageloadMailTemplatedrp[0].id;
    }

    let wfId = '';
    if (this.selectedItemspageloadWfdrp.length > 0) {
      wfId = this.selectedItemspageloadWfdrp[0].id;
    }

    let actionGrp = 0;
    if (this.selectedItemspageloadActiondrp.length > 0) {
      actionGrp = +this.selectedItemspageloadActiondrp[0].id;
    }

    let CheckBoxAction = 0;
    if (this.selectedItemsReportCheckBoxAction.length > 0) {
      CheckBoxAction = this.selectedItemsReportCheckBoxAction[0].id;
    }
    
    let userRoleId = '';
    if (this.selectedItemsRole.length > 0) {
      userRoleId = this.selectedItemsRole[0].id;
    }
    let createPermission = 1;
    if (this.selectedItemspageloadCreatePermission.length > 0) {
      createPermission = this.selectedItemspageloadCreatePermission[0].id;
    }
    let editPermission = 1;
    if (this.selectedItemspageloadEditPermission.length > 0) {
      editPermission = this.selectedItemspageloadEditPermission[0].id;
    }
    let activePermission = 0;
    if (this.selectedItemspageloadActivePermission.length > 0) {
      activePermission = this.selectedItemspageloadActivePermission[0].id;
    }
    let deletePermission = 0;
    if (this.selectedItemspageloadDeletePermission.length > 0) {
      deletePermission = this.selectedItemspageloadDeletePermission[0].id;
    }
    let printPermission = 0;
    if (this.selectedItemspageloadPrintPermission.length > 0) {
      printPermission = this.selectedItemspageloadPrintPermission[0].id;
    }
    let isHistory = 0;
    if (this.selectedItemspageloadIsHistory.length > 0) {
      isHistory = this.selectedItemspageloadIsHistory[0].id;
    }
    let isExcel = 0;
    if (this.selectedItemspageloadIsExcel.length > 0) {
      isExcel = this.selectedItemspageloadIsExcel[0].id;
    }
    let isPDF = 0;
    if (this.selectedItemspageloadIsPdf.length > 0) {
      isPDF = this.selectedItemspageloadIsPdf[0].id;
    }
    let formTestPermission = 0;
    if (this.selectedItemspageloadTestForm.length > 0) {
      formTestPermission = this.selectedItemspageloadTestForm[0].id;
    }
    if (this.txtTableAlias != "") {
      let that = this;
      let flag = true;
      let msg = '';
      let _data="";
      let  _rowValidations:Array<string>=[];
      let objColumn = <lstUserObjectParam>{};
      let genericColumnList:any = [];
      let mylist = '[';
      this.tableData.forEach((rowItem:UserObjectParam, index:number) =>{      
        let objList = <UserObjectParam>{};        
        let _columnLength = rowItem.dataType.toLowerCase() == "date" && rowItem.dataType.toLowerCase() == "datetime" && rowItem.dataType.toLowerCase() == "bit" ? "0" : rowItem.columnLength.toLowerCase() == "max" ? "-1" :rowItem.columnLength.toLowerCase();
          if (that.Customvalidation.validateRequired(rowItem.columnAliasName) == false) {
            _rowValidations.push('Please enter column alias for ' + rowItem.columnName);         
        }
        if (rowItem.columnName.toLowerCase() != "id" && rowItem.columnName.toLowerCase() != "guid" && rowItem.columnHide.toLowerCase() !="yes") {         
            if (that.Customvalidation.validateRequired(rowItem.controlType) == false) {
              _rowValidations.push('Please Select Control for ' + rowItem.columnName);
          }
        }
        else {
          rowItem.controlType = "empty";
        }
        if (rowItem.dataType.toLowerCase() != "date" && rowItem.dataType.toLowerCase() != "datetime" && rowItem.dataType.toLowerCase() != "bit") {
         
            if (that.Customvalidation.validateRequired(_columnLength) == false) {
              _rowValidations.push('Please enter column length for ' + rowItem.columnName);
          }
          if (_columnLength != "-1") {
            if (!that.Customvalidation.validatenumeric(_columnLength, '', '', '', '', '', '', '')[0]) {
              _rowValidations.push('Please enter column length in only digts for ' + rowItem.columnName);  
            }        
            if (that.Customvalidation.validateSize(rowItem.originalColumnLength, _columnLength) == false) {
              _rowValidations.push('Max length exceeded from table column length for ' + rowItem.columnName);
          }
        }
      }

        if (_rowValidations.length == 0) {
          objList.formTestPermission = formTestPermission.toString();
          objList.tbleName = rowItem.tbleName;
          objList.tbleAliasName = that.txtTableAlias;
          objList.selWhereCondition = that.txtTableAliaswhereCondition;
          objList.selectQuery = that.txtTableAliasSelect;
          objList.isHistory = isHistory!.toString();
          objList.userRoleId = userRoleId;
          objList.addPermission = createPermission!.toString();
          objList.editPermission = editPermission!.toString();
          objList.activePermission = activePermission!.toString();
          objList.deletePermission = deletePermission!.toString();
          objList.printPermission = printPermission!.toString();
          objList.checkBoxFlag = CheckBoxAction!.toString();
          objList.mailTriggerSP = actionGrp!.toString();
          objList.tablePageStyle = tablePageStyle;
          objList.tableMailId = mailId;
          objList.tableWfId = wfId;
          objList.columnName = rowItem.columnName;
          objList.dataType = rowItem.dataType;
          objList.columnLength = _columnLength;
          objList.columnAliasName = rowItem.columnAliasName;
          objList.Validation = rowItem.Validation;
          objList.checkExistsDataValidationType = rowItem.checkExistsDataValidationType;
          objList.checkExistsDataValidationParams = rowItem.checkExistsDataValidationParams;
          objList.columnHide = rowItem.columnHide.toLowerCase() =="yes"?"true":"false";
          objList.isEditDisabled = rowItem.isEditDisabled.toLowerCase() =="yes"?"true":"false";
          objList.valueFillType = rowItem.valueFillType;
          objList.dropDownChildtable = rowItem.dropDownChildtable;
          objList.dropDownQuery = rowItem.dropDownQuery;
          objList.controlType = rowItem.controlType;
          objList.isRequired = rowItem.iS_NULLABLE.toLowerCase() =="no"?"true":"false";
          objList.isViewLink = rowItem.isViewLink.toLowerCase() =="yes"?"true":"false";
          objList.compareColumnName = rowItem.compareColumnName;
          objList.range = rowItem.range;
          objList.expressionJson = rowItem.expressionJson;
          objList.columnSequence = rowItem.columnSequence;
          objList.controlClass = rowItem.controlClass;
          objList.columnEventDefination = rowItem.columnEventDefination;
          objList.customValidationMsg = rowItem.customValidationMsg;
          objList.columnDefaultValue = rowItem.columnDefaultValue;
          objList.columnInformation = rowItem.columnInformation;
          objList.miscellaneousType = rowItem.miscellaneousType;
          objList.createColumnHide = rowItem.createColumnHide.toLowerCase() =="yes"?"true":"false";
          objList.editColumnHide = rowItem.editColumnHide.toLowerCase() =="yes"?"true":"false";
          objList.regExp = rowItem.regExp;
          objList.isExcelDownload = isExcel!.toString();
          objList.isPdfDownload = isPDF!.toString();
          objList.columnHideView = rowItem.columnHideView.toLowerCase() =="yes"?"true":"false";
          objList.columnHideMobileView = rowItem.columnHideMobileView.toLowerCase() =="yes"?"true":"false";
          objList.columnFilterMobileView = rowItem.columnFilterMobileView.toLowerCase() =="yes"?"true":"false";
          objList.columnHideMobileForm = rowItem.columnHideMobileForm.toLowerCase() =="yes"?"true":"false";
          objList.excelDownloadColumn = rowItem.excelDownloadColumn.toLowerCase() =="yes"?"true":"false";
          objList.pdfDownloadColumn = rowItem.pdfDownloadColumn.toLowerCase() =="yes"?"true":"false";
          genericColumnList.push(objList);
        }
      });
      if (genericColumnList.length > 0) {
        objColumn.genericColumnList = genericColumnList;
        _data = JSON.stringify(objColumn);
       
      }
      
      let objDatestatusFinalModal = <lstUserObjectParam>{};
      objDatestatusFinalModal.genericColumnList = genericColumnList;
      if (_rowValidations.length == 0) {

        this.openSubmitModal();
        this.paramvaluedata = _data;
      }
      else {
        this.paramvaluedata = '';
        this.globalAlertService.AlertMsg("Alert!!", _rowValidations.join(", "), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;
      }
    }
    else {
      this.paramvaluedata = '';
      this.globalAlertService.AlertMsg("Alert!!", "Please enter Table Alias Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
      return;
    }
  }
  submitcall() {

    //alert("hello");
    this.userService.UserAliasSubmitForm(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {

      
      if (datacom != "") {
        let resultarray  = JSON.parse(datacom);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
        this.SearchEdit(2);
        if (resultarray['successFlag']  == "success") {
          //   this.Search(this.SeltableID);   
          this.OnResetModal();
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage']!.toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage']!.toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";
        }
      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message!.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      () => {
        this.pageloaddrp();
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });



  }


  openViewtModal() {

    $('#ViewModal').show();
    this.display = 'block';

  }

  onCloseViewModal() {

    $('#ViewModal').hide();
    this.display = 'none';
  }

  SearchEdit(callfrom: number) {
    this.spinner.show();
    
    let tablename:string='';
    if (callfrom == 1) {
      tablename = this.SeltableID;
    }
    else if (callfrom == 2) {
      tablename = this.SeltableID;
    }
    this.displayMainEdit = "block";
    this.Edittableid = 'EditDynamictable' + tablename!.toString().replace(/\s/g, '');
  
    let that = this;
    this.SeltableIDEDit = tablename;
    let filterQueryString: string = this.filterActualQuery.length >0 ? JSON.stringify(this.filterActualQuery) : "";
    let data: string = 'uspAppGenericTableEntryGetViewDetails|tblname=' + tablename + '|filterParams=' + filterQueryString + '|pageSize=' + this.pageSize!.toString() + '|page=' + this.currentPage!.toString() + '|callFlag='+callfrom  + '|sortOrder=' + this.sortOrder +'|sortColumn='+ this.sortColumn;
    this.userService.GetDataSetResult(data, this.SeltableIDForm).subscribe((data: any) => {
      if(data?.table1?.length >0)  
      this.totalRecords = data.table1[0]['totalRecords'];
      this.detailTableData = data.table;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err.message);
        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message!.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      });


  }
  // getTableDetailsByTableId
  onGetTableDetailsByTableId(tblName: string, tblAliasName: string, userRoleId: string, formPageStyle: string, mailId: string, workFlowId: string, 
    addPermission: string, editPermission: string, activePermission: string, deletePermission: string, printPermission: string, checkBoxFlag: string,
     mailTriggerSP: string, isHistory: string, selQuery: string, selWhereQuery: string, isExcelDownload: string, isPdfDownload: string, event: Event) {
    let param = "uspAppGenericTableColumnsGetDetails|tblname="+ tblName + "|userRoleId="+userRoleId;
    this.userService.getCommonDataSet( this.SeltableIDForm!.toString(),param)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;
       
        this.selectedTableId = tblName;
        this.viewTableData = data.table;
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;
        this.dropdownListpageloadControlDatedrp = data.table3;
        this.dropdownListpageloadControldrpNumber = data.table4;
        this.dropdownListpageloadValueFillTypedrp = data.table5;
        this.dropdownListpageloadMailTemplatedrp = data.table6;
        this.dropdownListpageloadWfdrp = data.table7;
        this.dropdownListpageloadDataExists = data.table8;
      },
      (err: HttpErrorResponse) => {

        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message!.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      () => {
        this.openViewtModal(); 
      });
  }
  // getEditableTableDetailsByTableId(tbname)
  onGetEditableTableDetailsByTableId(tblName: string, tblAliasName: string, userRoleId: string, formPageStyle: string, mailId: string, workFlowId: string,
     addPermission: string, editPermission: string, activePermission: string, deletePermission: string, printPermission: string, checkBoxFlag: string,
     mailTriggerSP: string, isHistory: string, selQuery: string, selWhereQuery: string, isExcelDownload: string, isPdfDownload: string,formTestPermission: string, event: Event) {
      this.editTableData = [];
      this.dropDownValues = [];
    let param = "uspAppGenericTableColumnsGetDetails|tblname="+ tblName + "|userRoleId="+userRoleId;
    this.userService.getCommonDataSet( this.SeltableIDForm!.toString(),param)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;
        this.txtTableAliasE = tblAliasName;
        this.txtTableAliasSelectE = selQuery;
        this.txtTableAliaswhereConditionE = selWhereQuery;        
        this.savedTablename = tblAliasName;
        this.mainTableId = "Dynamictable" + tblName + "E";
        this.selectedTableId = tblName;
        console.log(data.table);
        data.table.forEach((x:any, i:number)=>{
          let rowData: UserObjectParam = {} as UserObjectParam;
          rowData.rowId = x['id'];
          rowData.userRoleId = x['userRoleId'];
          rowData.tableid = x['tableid'];
          rowData.columnSequence = i.toString();
          rowData.tbleName = x['tblname'];
          rowData.columnAliasName =  x['columnAliasName'] ;
          rowData.child_column_name = x['child_column_name'];
          rowData.columnName = x['columN_NAME'];
          rowData.columnLength = x['columnLength'] ;
          rowData.originalColumnLength = x['table_columnLength'];
          rowData.isOriginalRequired = x['table_iS_NULLABLE'].toLowerCase();
          rowData.dataType = x['datA_TYPE'];
          rowData.originalDataType = x['table_datA_TYPE'];
          rowData.iS_NULLABLE = x['iS_NULLABLE'].toLowerCase();
          rowData.identityColumn = x['identityColumn'];
          rowData.parent_column_name = x['parent_column_name'];
          rowData.parent_table_name = x['parent_table_name'];
          rowData.Validation = x['validation'];
          rowData.checkExistsDataValidationParams = x['checkExistsDataValidationParams'];
          rowData.checkExistsDataValidationType = x['checkExistsDataValidationType'];
          rowData.columnDefaultValue = x['columnDefaultValue'];
          rowData.columnEventDefination = x['columnEventDefination'];
          rowData.columnFilterMobileView = x['columnFilterMobileView'];
          rowData.columnHide = x['hideColumn'];
          rowData.columnHideMobileForm = x['columnHideMobileForm'];
          rowData.columnHideMobileView = x['columnHideMobileView'];
          rowData.columnHideView = x['columnHideView'];
          rowData.columnInformation = x['columnInformation'];
          rowData.compareColumnName = x['compareColumnName'];
          rowData.controlClass = x['controlClass'];
          rowData.controlType = x['control'];
          rowData.createColumnHide = x['createColumnHide'];
          rowData.customValidationMsg = x['customValidationMsg'];
          rowData.dropDownChildtable = x['dropDownChildtable'];
          rowData.dropDownQuery = x['dropDownQuery'];
          rowData.editColumnHide = x['editColumnHide'];
          rowData.expressionJson = x['expressionJson'];
          rowData.isEditDisabled = x['isEditDisabled'];
          rowData.isViewLink = x['isViewLink'];
          rowData.miscellaneousType = x['miscellaneousType'];
          rowData.pdfDownloadColumn = x['pdfDownloadColumn'];
          rowData.excelDownloadColumn = x['excelDownloadColumn'];
          rowData.range = x['range'];
          rowData.regExp = x['regExp'];
          rowData.columnFlag = +x['columnFlag'];
          rowData.valueFillType = x['valueFillType'];
          this.editTableData.push(rowData);
        })
        console.log(data.editTableData);
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;
        this.dropdownListpageloadControlDatedrp = data.table3;
        this.dropdownListpageloadControldrpNumber = data.table4;
        this.dropdownListpageloadValueFillTypedrp = data.table5;
        this.dropdownListpageloadMailTemplatedrp = data.table6;
        this.dropdownListpageloadWfdrp = data.table7;
        this.dropdownListpageloadDataExists = data.table8;
        this.dropdownListpageloadActiondrp = data.table9;

        this.dropDownValues.push(data.table1);
        this.dropDownValues.push(data.table2);
        this.dropDownValues.push(data.table3);
        this.dropDownValues.push(data.table4);
        this.dropDownValues.push(data.table5);
        // this.dropDownValues.push(data.table6);
        // this.dropDownValues.push(data.table7);
        this.dropDownValues.push(data.table8);
        this.dropDownValues.push(data.table9);

        this.selectedItemspageloadPageStyleE = this.dropdownListpageloadPageStyle.filter(ps => ps.id == +formPageStyle);
        this.selectedItemspageloadMailTemplatedrpE = this.dropdownListpageloadMailTemplatedrp.filter((ps:any) => ps.id == mailId);
        this.selectedItemspageloadWfdrpE = this.dropdownListpageloadWfdrp.filter((ps:any) => ps.id == workFlowId);
        this.selectedItemspageloadCreatePermissionE = this.dropdownListpageloadCreatePermission.filter(ps => ps.itemName == addPermission);
        this.selectedItemspageloadEditPermissionE = this.dropdownListpageloadEditPermission.filter(ps => ps.itemName == editPermission);
        this.selectedItemspageloadActivePermissionE = this.dropdownListpageloadActivePermission.filter(ps => ps.itemName == activePermission);
        this.selectedItemspageloadDeletePermissionE = this.dropdownListpageloadDeletePermission.filter(ps => ps.itemName == deletePermission);
        this.selectedItemsReportCheckBoxActionE = this.dropdownListReportCheckBoxAction.filter(ps => ps.itemName == checkBoxFlag);
        this.selectedItemspageloadPrintPermissionE = this.dropdownListpageloadPrintPermission.filter(ps => ps.itemName == printPermission);
        this.selectedItemspageloadIsExcelE = this.dropdownListpageloadIsExcel.filter(ps => ps.itemName == isExcelDownload);
        this.selectedItemspageloadIsPdfE = this.dropdownListpageloadIsPdf.filter(ps => ps.itemName == isPdfDownload);
        this.selectedItemspageloadActiondrpE = this.dropdownListpageloadActiondrp.filter((ps:any) => ps.itemName == mailTriggerSP);
        this.selectedItemspageloadIsHistoryE = this.dropdownListpageloadIsHistory.filter(ps => ps.itemName == isHistory);
        this.selectedItemspageloadTestFormE = this.dropdownListpageloadTestForm.filter(ps => ps.itemName == formTestPermission);
       
      },
      (err: HttpErrorResponse) => {

        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message!.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      () => {
        this.onOpenEditModal(); 
      });
  }

  onTableAliasAction(id:string, action:string, TableId:number,userRoleId:string) {
    this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + "?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.TableAliasAllAction(id, action, TableId,userRoleId);
          this.paramvaluedata = '';
        }
      });
  }
  TableAliasAllAction(id: string, action: string, index: number,userRoleId: string) {
    
    //code to be done
    this.userService.TableAliasAction(id, action,userRoleId, this.SeltableIDForm!.toString()).subscribe((data: any) => {
      if (data != "") {
        let resultarray  = JSON.parse(data);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
        if (action == "Active" || action == "InActive") {
          if (resultarray['successFlag'] == "success") {                      
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
            return;
          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;
          }
        }
        else {
          if (resultarray['successFlag'] == "success") {
            //            
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

            return;

          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;

          }
        }
      }
    },
      (err: HttpErrorResponse) => {

        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message!.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      () => {
        if (action == "Active") {
          this.detailTableData[index]['isActive'] = true;
        }
        else if (action == "InActive") {
          this.detailTableData[index]['isActive'] = false;
        }
        else {
          this.SearchEdit(2); 
          this.pageloaddrp();
        }

      });
    //

  }


  onOpenEditModal() {
    $('#EditMasterModal').show();
    this.display = 'block';

  }
  onCloseEditModal() {
    $('#EditMasterModal').hide();
    this.display = 'none';
  }

  updateEditTableEntry() {
    
    
  
    let tablePageStyle = this.selectedItemspageloadPageStyleE[0].id!.toString();
    let mailId = '';

    if (this.selectedItemspageloadMailTemplatedrpE.length > 0) {
      mailId = this.selectedItemspageloadMailTemplatedrpE[0].id;
    }

    let wfId = '';
    if (this.selectedItemspageloadWfdrpE.length > 0) {
      wfId = this.selectedItemspageloadWfdrpE[0].id;
    }
    let actionGrp = 0;
    if (this.selectedItemspageloadActiondrpE.length > 0) {
      actionGrp = +this.selectedItemspageloadActiondrpE[0].id;
    }

    let CheckBoxAction = 0;
    if (this.selectedItemsReportCheckBoxActionE.length > 0) {
      CheckBoxAction = this.selectedItemsReportCheckBoxActionE[0].id;
    }

    let printPermission = 0;
    if (this.selectedItemspageloadPrintPermissionE.length > 0) {
      printPermission = this.selectedItemspageloadPrintPermissionE[0].id;
    }
   
    let createPermission = 1;
    if (this.selectedItemspageloadCreatePermissionE.length > 0) {
      createPermission = this.selectedItemspageloadCreatePermissionE[0].id;
    }
    let editPermission = 1;
    if (this.selectedItemspageloadEditPermissionE.length > 0) {
      editPermission = this.selectedItemspageloadEditPermissionE[0].id;
    }
    let activePermission = 0;
    if (this.selectedItemspageloadActivePermissionE.length > 0) {
      activePermission = this.selectedItemspageloadActivePermissionE[0].id;
    }
    let deletePermission = 0;
    if (this.selectedItemspageloadDeletePermissionE.length > 0) {
      deletePermission = this.selectedItemspageloadDeletePermissionE[0].id;
    }
    let isHistory = 0;
    if (this.selectedItemspageloadIsHistoryE.length > 0) {
      isHistory = this.selectedItemspageloadIsHistoryE[0].id;
    }

    let isExcel = 0;
    if (this.selectedItemspageloadIsExcelE.length > 0) {
      isExcel = this.selectedItemspageloadIsExcelE[0].id;
    }
    let isPDF = 0;
    if (this.selectedItemspageloadIsPdfE.length > 0) {
      isPDF = this.selectedItemspageloadIsPdfE[0].id;
    }
    let formTestPermission = 0;
    if (this.selectedItemspageloadTestFormE.length > 0) {
      formTestPermission = this.selectedItemspageloadTestFormE[0].id;
    }
    // alert(tableAliasName);
    if (this.txtTableAliasE != "") {
      let that = this;
      let flag = true;    
      let _data;
      let  _rowValidations:Array<string>=[];
      let objColumn = <lstUserObjectParam>{};
      let genericColumnList:any = [];

      this.editTableData.forEach((itemRow:UserObjectParam, index:number) => {
        let objList = <UserObjectParam>{}; 
        let _columnLength:string = itemRow.dataType.toLowerCase() == "date" && itemRow.dataType.toLowerCase() == "datetime" && itemRow.dataType.toLowerCase() == "bit" ? "0" : itemRow.columnLength.toString().toLowerCase() == "max" ? "-1" :itemRow.columnLength.toString();
        let _originalColumnLength: string = itemRow.dataType.toLowerCase() == "date" && itemRow.dataType.toLowerCase() == "datetime" && itemRow.dataType.toLowerCase() == "bit" ? "0" : itemRow.originalColumnLength.toString().toLowerCase() == "max" ? "-1" :itemRow.originalColumnLength.toString();
       
        if (itemRow.columnName.toLowerCase() == "to_be_deleted") {
          _rowValidations.push("Please remove all 'to_be_deleted' row's.");         
        }
        if (that.Customvalidation.validateRequired(itemRow.columnAliasName) == false) {
          _rowValidations.push('Please enter column alias for ' + itemRow.columnName);         
      }
      if (itemRow.columnName.toLowerCase() != "id" && itemRow.columnName.toLowerCase() != "guid" && itemRow.columnHide.toLowerCase() !="yes") {         
          if (that.Customvalidation.validateRequired(itemRow.controlType) == false) {
            _rowValidations.push('Please Select Control for ' + itemRow.columnName);
        }
      }
      else {
        itemRow.controlType = "empty";
      }
      if (itemRow.dataType.toLowerCase() != "date" && itemRow.dataType.toLowerCase() != "datetime" && itemRow.dataType.toLowerCase() != "bit") {
       
          if (that.Customvalidation.validateRequired(_columnLength) == false) {
            _rowValidations.push('Please enter column length for ' + itemRow.columnName);
        }
        if (_columnLength != "-1") {
          if (!that.Customvalidation.validatenumeric(_columnLength, '', '', '', '', '', '', '')[0]) {
            _rowValidations.push('Please enter column length in only digts for ' + itemRow.columnName);  
          }        
          if (that.Customvalidation.validateSize(_originalColumnLength, _columnLength) == false) {
            _rowValidations.push('Max length exceeded from table column length for ' + itemRow.columnName);
        }
      }
    }
          
        
        if (_rowValidations.length == 0) {
          objList.formTestPermission = formTestPermission.toString();
          objList.rowId = itemRow.rowId.toString();
          objList.tableid = itemRow.tableid.toString();
          objList.tbleName = itemRow.tbleName;
          objList.tbleAliasName = that.txtTableAliasE;
          objList.selWhereCondition = that.txtTableAliaswhereConditionE;
          objList.selectQuery = that.txtTableAliasSelectE;
          objList.isHistory = isHistory!.toString();
          objList.userRoleId = itemRow.userRoleId.toString() ;
          objList.addPermission = createPermission!.toString();
          objList.editPermission = editPermission!.toString();
          objList.activePermission = activePermission!.toString();
          objList.deletePermission = deletePermission!.toString();
          objList.printPermission = printPermission!.toString();
          objList.checkBoxFlag = CheckBoxAction!.toString();
          objList.mailTriggerSP = actionGrp!.toString();
          objList.tablePageStyle = tablePageStyle;
          objList.columnName = itemRow.columnName;
          objList.dataType = itemRow.dataType;
          objList.columnLength = _columnLength;
          objList.columnAliasName = itemRow.columnAliasName;
          objList.Validation = itemRow.Validation;
          objList.checkExistsDataValidationType = itemRow.checkExistsDataValidationType;
          objList.checkExistsDataValidationParams = itemRow.checkExistsDataValidationParams;
          objList.columnHide = itemRow.columnHide.toLowerCase() =="yes"?"true":"false";
          objList.isEditDisabled = itemRow.isEditDisabled.toLowerCase() =="yes"?"true":"false";
          objList.valueFillType = itemRow.valueFillType;
          objList.dropDownChildtable = itemRow.dropDownChildtable;
          objList.dropDownQuery = itemRow.dropDownQuery;
          objList.controlType = itemRow.controlType;
          objList.isRequired = itemRow.iS_NULLABLE.toLowerCase() =="no"?"true":"false";
          objList.isViewLink = itemRow.isViewLink.toLowerCase() =="yes"?"true":"false";
          objList.compareColumnName = itemRow.compareColumnName;
          objList.range = itemRow.range;
          objList.expressionJson = itemRow.expressionJson;
          objList.columnSequence = itemRow.columnSequence;
          objList.controlClass = itemRow.controlClass;
          objList.columnEventDefination = itemRow.columnEventDefination;
          objList.customValidationMsg = itemRow.customValidationMsg;
          objList.columnDefaultValue = itemRow.columnDefaultValue;
          objList.columnInformation = itemRow.columnInformation;
          objList.miscellaneousType = itemRow.miscellaneousType;
          objList.createColumnHide = itemRow.createColumnHide.toLowerCase() =="yes"?"true":"false";
          objList.editColumnHide = itemRow.editColumnHide.toLowerCase() =="yes"?"true":"false";
          objList.regExp = itemRow.regExp;
          objList.isExcelDownload = isExcel!.toString();
          objList.isPdfDownload = isPDF!.toString();
          objList.columnHideView = itemRow.columnHideView.toLowerCase() =="yes"?"true":"false";
          objList.columnHideMobileView = itemRow.columnHideMobileView.toLowerCase() =="yes"?"true":"false";
          objList.columnFilterMobileView = itemRow.columnFilterMobileView.toLowerCase() =="yes"?"true":"false";
          objList.columnHideMobileForm = itemRow.columnHideMobileForm.toLowerCase() =="yes"?"true":"false";
          objList.excelDownloadColumn = itemRow.excelDownloadColumn.toLowerCase() =="yes"?"true":"false";
          objList.pdfDownloadColumn = itemRow.pdfDownloadColumn.toLowerCase() =="yes"?"true":"false";
          
          genericColumnList.push(objList);
          
        }

      });
      if (genericColumnList.length > 0) {
        objColumn.genericColumnList = genericColumnList;
        _data = JSON.stringify(objColumn);

      }
      let objDatestatusFinalModal = <lstUserObjectParam>{};
      objDatestatusFinalModal.genericColumnList = genericColumnList;
      if (_rowValidations.length == 0) {
        this.openUpdatemodel();
        this.paramvaluedata = _data;

      }
      else {
        this.paramvaluedata = '';
        this.globalAlertService.AlertMsg("Alert!!", _rowValidations.join(", "), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;
      }
    }
    else {
      this.paramvaluedata = '';
      this.globalAlertService.AlertMsg("Alert!!", "Please enter Table Alias Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
      return;
    }
    
  }
  openUpdatemodel() {
    this.globalConfirmService.ConfirmFunction("Confirm Update!!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.updatesubmitcall();

        }
        else {
          this.paramvaluedata = '';
        }
      });
  }

  updatesubmitcall() {
    let tableId =  this.editTableData.filter((x:any)=> x['tableid'] >0)[0]['tableid']
    this.userService.userAliasUpdateForm(this.paramvaluedata,tableId ,this.SeltableIDForm).subscribe((data: any) => {
      
      if (data != "") {
        let resultarray  = JSON.parse(data);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']

        if (resultarray['successFlag'] == "success") {
          this.SearchEdit(2);
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage']!.toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
          this.onCloseEditModal();
          this.paramvaluedata = '';
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage']!.toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";

        }

      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message!.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        return true;
      },
      () => {
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });
  }
 
  txtTableNameFocuscheckFormAlreadyExists(event: any, callfrom: string) {
    
    let result = this.Customvalidation.validatealphanumeric($.trim(event.target.value!.toString()), '', '', '', '', '', '', '')
    if (!result[0] && $.trim(event.target.value!.toString()) != "") {
      this.globalAlertService.AlertMsgFocus("Alert!!", "Please Enter valid Form Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);
    }
    else if ($.trim(event.target.value!.toString()) == this.savedTablename) {
      return;

    }

    this.onCheckFunctionTableNameonfocus(event, callfrom=="I"?this.selectedItemsRole[0].id!.toString() :this.editTableData[0]['userRoleId'] );
  }
  onCheckFunctionTableNameonfocus(event: any,userRoleId:any) {
    let flag = true;
    let that = this;
    this.userService.getTableNameExists($.trim(event.target.value!.toString()),userRoleId ,this.SeltableIDForm).subscribe((data: any) => {
      
      if (data.table.length > 0) {
        flag = false;
      }

    },
      (err: HttpErrorResponse) => {

        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },

      () => {
        if (!flag) {
          that.globalAlertService.AlertMsgFocus("Alert!!", "Table Alias Name Already Exits. Please Choose Another Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);

        }

      });

    return flag;
  }
  toggleWithFilterCol(popover:any, colName: string, colDisplayName: string,dataType: string,filterColParentTableName: string, callfrom:boolean) {
    if(callfrom)
    this.popoverEvent = popover;
    if (popover.isOpen()) {
      this.filterColumnName = "";
      this.filterColumnDisplayName = "";  
      this.filterColumnDataType = "";
      this.filterColumnParentTableName = "";
      popover.close();
    } else {
      this.filterColumnName = colName;
      this.filterColumnDisplayName = colDisplayName;    
      this.filterColumnDataType = dataType;
      this.filterColumnParentTableName = filterColParentTableName;
      this.reactiveFilterForm();
      popover.open({colName, colDisplayName});
    }
  }
  reactiveFilterForm()
  {
    this.filterFormValue = {} as IFilterColumns;
    this.filterFormValue.cloumnName = this.filterColumnName;    
    this.filterFormValue.cloumnDisplayName = this.filterColumnDisplayName;
    this.filterFormValue.filterColumnDataType = this.filterColumnDataType;
    this.filterFormValue.filterColumnParentTableName = this.filterColumnParentTableName;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    {
      this.filterFormValue.columnCondition = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].columnCondition) ? "AND" : getExistingValues[0].columnCondition;
      this.filterFormValue.filterColumnOperator = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterColumnOperator?.toString()) ? 1 : getExistingValues[0].filterColumnOperator;
      this.filterFormValue.filterValue = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterValue) ? "" : getExistingValues[0].filterValue;
    }
    else
    {
      this.filterFormValue.columnCondition ="AND";
      this.filterFormValue.filterColumnOperator = 1;
    }
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
     
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [
        Validators.required,
      ]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [
        Validators.required,
      ]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),        
      ])      
    });
  }
  get columnCondition() {
    return this.filterForm.get('columnCondition')!;
  }

  get filterColumnOperator() {
    return this.filterForm.get('filterColumnOperator')!;
  }

  get filterValue() {
    return this.filterForm.get('filterValue')!;
  }
  genrateFilterQuery(){
    this.filterActualQuery = [];
    this.filterDisplayQuery = [];
    this.filterColumnQuery.forEach((value:IFilterColumns,index:number)=>{
      //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
      let filterColumnOperator = value.filterColumnOperator ==1 ? "equal"  : value.filterColumnOperator ==2 ? "notEqual" : "Like"
      let filterDisplayColumnOperatorValue = value.filterColumnOperator ==1 ? " = "+ value.filterValue  : value.filterColumnOperator ==2 ? " ≠ "+ value.filterValue : " ≈ "+ value.filterValue;
      this.filterActualQuery.push({columnName: value.cloumnName , columnValue: value.filterValue, conditionOperator: value.columnCondition, filterColConditionOperator:filterColumnOperator});
      this.filterDisplayQuery.push({ columnName: value.cloumnName , displayQuery: value.cloumnDisplayName + filterDisplayColumnOperatorValue, operator: value.columnCondition});

    });
    this.SearchEdit(1);
  }
  delFilter(columnName:string)
  {    
    let filtercolumnIndex: number = this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == columnName);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    this.genrateFilterQuery();
  }
  clearAllFilter()
  {
    this.filterColumnQuery.splice(0, this.filterColumnQuery.length);
    this.genrateFilterQuery();
  }
  clearFilterForm()
  {
    this.filterFormValue.columnCondition="AND";
    this.filterFormValue.filterColumnOperator=1;
    this.filterFormValue.filterValue="";
    let filtercolumnIndex:number =this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == this.filterFormValue.cloumnName);
    this.filterForm.reset(this.filterFormValue);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    console.log(this.filterColumnQuery);
    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName,false);
    this.genrateFilterQuery();
  }
  applyFilter()
  {
    this.filterFormValue = this.filterForm.value;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    { 
      getExistingValues[0].columnCondition = this.filterFormValue.columnCondition;
      getExistingValues[0].filterColumnOperator = this.filterFormValue.filterColumnOperator;
      getExistingValues[0].filterValue = this.filterFormValue.filterValue;
    }
    else
    this.filterColumnQuery.push(this.filterFormValue);

    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName, false);
    this.genrateFilterQuery();

  }
  ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}

onSortClick(event:any, columnName:string) {
  let target = event.currentTarget,
    classList = target.classList;
   
  if (classList.contains('sort-up')) {
    classList.remove('sort-up');
    this.sortOrder="ASC";
  } else {
    classList.add('sort-up');
    this.sortOrder="DESC";
  }
  this.sortColumn = columnName;
  this.currentPage = 1;
  this.SearchEdit(1);
}
pageSizeChange(){
  this.currentPage = 1;
  this.SearchEdit(1);
}
  onDeletecustumtablechild(index: number) {
    this.editTableData.splice(index, 1);
  }

  onClickOpenRowDetail(rowData:any, i:number){
    let viewRowData:masterFormRowData ={} as masterFormRowData;
    viewRowData.index = i;
    viewRowData.rowData = rowData;
this.masterFormRowdataPopupService.updateViewMasterFormRowDataModelFunction(viewRowData,this.dropDownValues,"","Save changes") .then((rowData) => {
  if (rowData) {
      this.tableData[rowData['index']] = rowData['rowData'];
      // console.log(this.tableData);
  }
});
  }
  onClickOpenEditRowDetail(rowData:any, i:number){
    let viewRowData:masterFormRowData ={} as masterFormRowData;
    viewRowData.index = i;
    viewRowData.rowData = rowData;
this.masterFormRowdataPopupService.updateViewMasterFormRowDataModelFunction(viewRowData,this.dropDownValues,"","Update changes") .then((rowData) => {
  if (rowData) {
      this.editTableData[rowData['index']] = rowData['rowData'];
      // console.log(this.tableData);
  }
});
  }

  showFillTypeText(id:string){  
    let selectedValue = this.dropdownListpageloadValueFillTypedrp?.filter((x:any)=>x['id']==id);
    if(selectedValue.length >0)
    return selectedValue[0]['itemName'];
    else
    return "";
   }

   showControlTypeText(id:string){  
    let selectedValue = this.dropdownListpageloadControldrp?.filter((x:any)=>x['id']==id);
    if(selectedValue.length >0)
    return selectedValue[0]['itemName'];
    else
    return "";
   }

   showValidationTypeText(id:string){  
    let selectedValue = this.dropdownListpageloadValidationdrp?.filter((x:any)=>x['id']==id);
    if(selectedValue.length >0)
    return selectedValue[0]['itemName'];
    else
    return "";
   }

   showDataExistsTypeText(id:string){  
    let selectedValue = this.dropdownListpageloadDataExists?.filter((x:any)=>x['id']==id);
    if(selectedValue.length >0)
    return selectedValue[0]['itemName'];
    else
    return "";
   }

}
