import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import {Iheader,Itableth} from  '../../allUsedModule'
import { HeaderPageModelService } from '../../allFormPopUpModel/header-page-model/header-page-model.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.css']
})
export class HeaderDashboardComponent implements OnInit {
  activeTabId:number=1;
  headerdata:any;
  AddNewFrom !: FormGroup;

  
  tableHead : Itableth[] = [
    { "displayName": "Tittle", "colName": "text","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Type", "colName": "type","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "show", "colName": "show","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Router Link", "colName": "routkey" ,"isActionBtn":false,"isImagePreviewEnabled":false},
    { "displayName": "Position", "colName": "position","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Menu", "colName": "level0" ,"isActionBtn":false,"isImagePreviewEnabled":false},
    { "displayName": "Sub Menu", "colName": "level1","isActionBtn":false,"isImagePreviewEnabled":false },
  ]

  constructor(public commonfunction:Commonfunction,private headerService:HeaderPageModelService) { }

  ngOnInit(): void {
    this.headerdata = this.commonfunction.loadJson("~/../assets/header.json");

    this.AddNewFrom = new FormGroup({
      title:new FormControl("",[Validators.required]),
      type:new FormControl("",[Validators.required]),
      routkey:new FormControl("",[Validators.required]),
      position:new FormControl("",[Validators.required]),
      level0:new FormControl("",[Validators.required]),
      level1:new FormControl("",[Validators.required]),
    })
  }

  onClickResetFrom(){
    this.AddNewFrom.reset()
  }

  OnClickSubmit(){
    const _item:Iheader={} as Iheader;
    _item.id = this.headerdata.length +  1;
    _item.text = this.AddNewFrom.get('title')?.value;
    _item.type = this.AddNewFrom.get('type')?.value;
    _item.routkey = this.AddNewFrom.get('routkey')?.value;
    _item.position = this.AddNewFrom.get('position')?.value;
    _item.level0 = this.AddNewFrom.get('level0')?.value;
    _item.level1 = this.AddNewFrom.get('level1')?.value;
    this.headerdata.push(_item);
  }

  addrouterlink(value:any){
    this.AddNewFrom.patchValue({
      routkey:value.target.value
    })
  }






  onClickEditForm(value:any){
    this.headerService.HeaderService(value)
    .then((confirm)=>{
      if(confirm){

      }
    })
  }
  deleteKey(index:number){}

}
