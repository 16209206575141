import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../../shared/user.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customvalidation } from "../../shared/Validation";
import { Commonfunction } from "../../shared/commonutility";
import 'jquery';
import { dynamicformvalues } from '../../shared/object-param.model';
declare var jQuery: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  roleid: number = 0;
  userData: any;
  menuData = [];
  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router, private location: Location, private spinner: NgxSpinnerService,
     public Customvalidation: Customvalidation, private commonfunction:Commonfunction) {


  }

  ngOnInit() {
let that = this;
    this.spinner.show();
    let currentRole = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole')) ? sessionStorage.getItem('currentRole')?.toString() : '' ;
  
    let userInfo:any;
    let currentUserMenu:any;

    if (currentRole != '') {
      let roleid = currentRole != null ?JSON.parse(currentRole).roleId: null;
           this.userService.getChangeRoleUserData(this.commonfunction.getUserIdValuestring(), roleid, 'header').subscribe((datacomget: any) => {
             console.log(datacomget);
        sessionStorage.removeItem('UserInfo');
        sessionStorage.removeItem('CurrentUserMenu');
        sessionStorage.removeItem('CurrentUserMenusub');
        sessionStorage.removeItem('CurrentUserSubMenu');
        sessionStorage.removeItem('CurrentUserSubMenusub');
        sessionStorage.removeItem('CurrentFeaturesToggle');
        sessionStorage.setItem("UserInfo", JSON.stringify(datacomget.table[0]));
        sessionStorage.setItem("CurrentUserMenu", JSON.stringify(datacomget.table1));
        sessionStorage.setItem("CurrentUserSubMenu", JSON.stringify(datacomget.table2));
        sessionStorage.setItem("CurrentUserMenusub", JSON.stringify(datacomget.table3));
        sessionStorage.setItem("CurrentUserSubMenusub", JSON.stringify(datacomget.table4));
        sessionStorage.setItem("CurrentFeaturesToggle", JSON.stringify(datacomget.table5));
        userInfo = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('UserInfo')) ? sessionStorage.getItem("UserInfo")?.toString():null;
        this.userData = userInfo != null ? JSON.parse(userInfo) : null;       
        if(this.userData['landingpage'] === null || this.userData['landingpage'] === "")
        this.router.navigate(['/home']);
        else
        {
          currentUserMenu = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentUserMenu')) ? sessionStorage.getItem("CurrentUserMenu")?.toString():null;
          this.menuData = currentUserMenu != null? JSON.parse(currentUserMenu):null;  
          let menu = this.menuData.filter( x => x['formValue'] == this.userData['landingpage'])
          let objList = <dynamicformvalues>{};
          objList.formName = menu[0]['menu'];
          objList.formValue =  menu[0]['formValue'];
          objList.menu =  '';
          objList.subMenu =  '';
          this.userService.Setdynamicformparam(JSON.stringify(objList));
          if (!sessionStorage["menuItem"]) {
            sessionStorage.setItem("menuItem", JSON.stringify(objList));
          }
          else {
            sessionStorage.removeItem('menuItem');
            sessionStorage.setItem("menuItem", JSON.stringify(objList));
          }
          this.router.navigate(['/'+this.userData['landingpage'].toString()]);
        }
      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.Customvalidation.loginroute(err.status);
        },
        ()=>{
          this.spinner.hide();
        });

    }
    else {
      this.spinner.hide();
      this.userData = userInfo != null ? JSON.parse(userInfo) : null;        
        if(this.userData['landingpage'].toString() === null || this.userData['landingpage'].toString() === "")
        this.router.navigate(['/home']);
        else
        {
          this.menuData = currentUserMenu != null? JSON.parse(currentUserMenu):null;  
          let menu = this.menuData.filter( x => x['formValue'] == this.userData['landingpage'])
          let objList = <dynamicformvalues>{};
          objList.formName = menu[0]['menu'];
          objList.formValue =  menu[0]['formValue'];
          objList.menu =  '';
          objList.subMenu =  '';
          this.userService.Setdynamicformparam(JSON.stringify(objList));
          if (!sessionStorage["menuItem"]) {
            sessionStorage.setItem("menuItem", JSON.stringify(objList));
          }
          else {
            sessionStorage.removeItem('menuItem');
            sessionStorage.setItem("menuItem", JSON.stringify(objList));
          }
          this.router.navigate(['/'+this.userData['landingpage'].toString()]);
        }
      
    }
  }
}
