import { Component,Input, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { custumValidation } from "../../shared/custum-validation";
import { Customvalidation } from "../../shared/Validation";
import { WorkFlowActionEnum } from '../../shared/model/WorkFlowEnum';
import { FormFinalObjectParam, lstFormFinalObjectParam } from '../object-param.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogBoxService } from '../confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../global-alert.service';

import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.css']
})
export class CaptureImageComponent implements OnInit, OnDestroy {

  AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";

   // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string |null |undefined;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage?: WebcamImage | undefined |null = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
 

  @Input() tableName: string = '';
  @Input() formName: string ='';
  @Input() title: string |null |undefined;
  @Input() btnOkText: string |null |undefined;
  @Input() btnCancelText: string |null |undefined;
  dropdownGridSettings = {};
  mastertableFormWF?: FormGroup;
  dropdownListWFStatus = [];
  selectedItemsWFStatus: Array<{ id: string, itemName: string }> = [];
  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder, public Customvalidation: Customvalidation,private spinner: NgxSpinnerService
    , private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService,  private userService: UserService,
   private router: Router, private renderer: Renderer2) { }

   public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public RetriggerSnapshot(): void {
    this.webcamImage = null;
  }
  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }


  ngOnDestroy()
  {
    this.showWebcam = false;
  
  }

  



  public onCloseConfirmModal() {
    this.activeModal.close({ 'GenericCaptureFileUpload': { flag: false, fileImage: null } });
  }

  public onAcceptConfirmModal() {
    this.activeModal.close({ 'GenericCaptureFileUpload': { flag: true, fileImage:  this.webcamImage } });   
  }

  public dismiss() {
    this.activeModal.close({ 'GenericCaptureFileUpload': { flag: false, fileImage: null } });
    // this.activeModal.dismiss();
  }
  
}
