<div class="footer mt-5">
    <div class="footer-div container">
        <div class="logo-div indexcl" data-aos="fade-right">
            <div class="logo_div">
                <img [src]="schLogo?.logo" alt="">
            </div>
            <div class="descriptionfooter">
                <p>{{footerData?.footerdescription}}</p>
            </div>
            <div class="phone">
                <p><a href="tel:{{ footerData.phonenumber }}"><span class="footer_dymk">{{ footerData.phonenumber
                            }}</span></a></p>
            </div>
            <div class="emailidfooter">
                <p>{{footerData?.emailIdsDiv}}</p>
            </div>

        </div>

        <div class="info indexcl" data-aos="zoom-in">
            <h2 class="pb-2 m-0">Information</h2>
            <div class="newsletter my-3">
                <div class="newsletter_wapper">
                    <form>
                        <input class="newsletterinput" type="text" placeholder="Enter Email">
                        <button class="newsbtn">Subscribe</button>
                    </form>
                </div>
            </div>
            <div class="address d-flex">
                <span>
                    <img src="assets/img/location.png" alt="">
                </span>
                <div class="addresbox">
                    <p class="mb-0">{{footerData.address}}</p>
                    <p class="mb-0">{{footerData.addressline2}}</p>
                    <p class="mb-0">{{footerData.addresslastline}}</p>
                </div>
            </div>
        </div>

        <div class="services indexcl" data-aos="fade-left">
            <h2 class="pb-2 m-0">schedule</h2>
            <div class="openingday_wrapper">
                <div class="openingbox">
                    <p>Opening Days</p>
                    <p><span>Mon</span>- <span>Sat</span></p>
                </div>
                <div class="openingbox">
                    <p>Opening Times</p>
                    <p><span>08:00AM</span>- <span>02:00PM</span></p>
                </div>
            </div>
        </div>
    </div>




</div>
<div class="pop-up">
    <div class="popup-main">
    </div>
</div>
<div class="container row py-2 text-light mx-auto">
    <div class="leftText col-md-6 text-left">
        {{footerData?.botomLine}}
    </div>
    <div class="socialDiv col-md-6 text-right">
        <div>
            <div class="icon-div">
                <a *ngFor="let social of footerData?.socialmedia" [href]="social.socialLink" target="_blank"><img
                        [src]="social.socialImgurl" alt="facebook"></a>
            </div>
        </div>
    </div>
</div>