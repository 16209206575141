  <!-- start Alert Modal -->
  <div id="AlertDialogModal" [class]="AlertDialogType " >
    <div  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div [class]="AlertDialogClass">
            <div [class]="AlertDialogAnimationType "></div>
          </div>
          <button type="button" class="close" aria-label="Close" (click)="onCloseAlertModal()">
            <span   aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">{{AlertDialogTitle}}</h2>
        <div class="modal-body">
          <p>{{AlertDialogMessage}}</p>
        </div>
        <div class="NP_popup-footer text-center mt-2"> 
          <div class="">
            <p><a href="javascript: void(0);" class="btn btn-primary" ngbAutofocus  (click)="onCloseAlertModal()">{{btnOkText}}</a></p>
          </div>     
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End Alert Modal -->
