import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import { IhomeExperiance,Itableth } from "../../../allUsedModule";
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-categories-dashboard',
  templateUrl: './categories-dashboard.component.html',
  styleUrls: ['./categories-dashboard.component.css']
})
export class CategoriesDashboardComponent implements OnInit {
  activeTabId: number | any = 1;
  AddNewDetailFrom !: FormGroup;
  HomeCategories: any;

  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "ImgUrl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Title", "colName": "Heading","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "Content","isActionBtn":false,"isImagePreviewEnabled":false },
  ]

  constructor(public commonfunction: Commonfunction, public homePageservice: HomePageModelService, private confirm: ConfirmDialogBoxService, private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
    this.HomeCategories = this.commonfunction.loadJson("~/../assets/categories.json");

    //Add new List
    this.AddNewDetailFrom = new FormGroup({
      title: new FormControl("", [Validators.required]),
      details: new FormControl("", [Validators.required])
    })
  }

  onClickResetFrom() {
    this.AddNewDetailFrom.reset();
  }
  OnClickSubmit() {

    this.confirm.ConfirmFunction("Confirm Add New!!", "Are you sure you want to Deleted Record From Home?")
      .then((confirm) => {
        if (confirm) {
          let _item: IhomeExperiance = {} as IhomeExperiance;
          _item.Heading = this.AddNewDetailFrom.get('title')?.value;
          _item.Content = this.AddNewDetailFrom.get('details')?.value;
          _item.id = this.HomeCategories.length + 1
          // _item.ImgUrl = 
          this.HomeCategories.push(_item);

          this.AddNewDetailFrom.reset();
          this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
        }
      })

  }


  onClickEditForm(value: IhomeExperiance) {
    this.homePageservice.HomeExperianceService(value, true)
      .then((confirm) => {
        if (confirm) {
          // this.HomeCategories[index] = value;
          this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
        }
      })
  }

  deleteKey(index: number) {
    this.confirm.ConfirmFunction("Confirm Delete!!", "Are you sure you want to Deleted Record From Home?")
    .then((confirm) => {
      if (confirm) {
        this.HomeCategories.splice(index, 1);
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Delete", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })  
  }

}
