import { Component, OnInit, HostListener } from '@angular/core';
import {GenericPrintService} from '../../shared/generic-print-service';
import { UserService } from '../../shared/user.service';
@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit {

  toggleClass: boolean = true;
  toggleClassOnTableFilteropen: boolean = false;
  constructor( public userService: UserService) { }

  ngOnInit() {
    this.userService.readToggleClass().subscribe((x:boolean)=> this.toggleClass = x);
    this.userService.readClass().subscribe((x:boolean)=>  this.toggleClassOnTableFilteropen = x);
  }
  removeLayout(){
    this.userService.ststusNav = false;
  }




}
