import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'validateFileType' })
export class FileTypePipe implements PipeTransform {
    transform(fileType: string,validFileTypes: string[]): boolean {
        
        if( validFileTypes.filter(s => s == fileType).length > 0)
            return true;
        else
            return false;
        
      }
}