<div class="photogridBox">
  <div class="photogrids">
    <div class=""  data-aos="fade-left">
      <div class="gallery-content" style="margin-right: 20px;">
        <div class="photogrids-box mb-4">
          <a *ngFor="let Sclgallery of sclgallery" data-fancybox="gallery"
            [href]="Sclgallery.imgurl">
            <img [src]="Sclgallery.imgurl" />
          </a>
         
        </div>
        <div class="photogrids-box" style="flex-direction: row-reverse;">
          <a *ngFor="let Sclgallery of sclgallery" data-fancybox="gallery"
            [href]="Sclgallery.imgurl">
            <img [src]="Sclgallery.imgurl" />
          </a>
         
        </div>
      </div>
    </div>
  </div>

  <div class="text-center">
    <button class="btn text-light" routerLink="/gallery">View More</button>
  </div>
</div>