export interface IfileValidationType {
    
    fileCount?: number,
    fileCountMessage?: string |null |undefined,
    fileSize?: number,
    fileSizeMessage?: string |null |undefined,
    fileType?: string |null |undefined[],
    fileTypeMessage?: string |null |undefined      
    
  }

  export interface IfileTypeOutput {
    
    fileName?: string |null |undefined,
    fileErrror?: boolean,
    fileErrorMessage?: string |null |undefined,
    formSequence?: string |null |undefined
       
    
  }
  export interface IfileType {
    
    
    uploadedFiles?: number,
    uploadedFileName?: string |null |undefined,
    uploadedFileNewName?: string |null |undefined,
    fileData?: string;
       
    
  }

  export enum filetypeEnum {
    No = 0,
    Yes = 1,
    YesWithDel = 2,
    YesPreviousDel = 3
  }