import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-dialog-box-event',
  templateUrl: './alert-dialog-box-event.component.html',
  styleUrls: ['./alert-dialog-box-event.component.css']
})
export class AlertDialogBoxEventComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
