import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '../../../node_modules/@angular/router';
//import 'rxjs/add/operator/map';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
//import { UserService } from './user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomActionService } from './customAction/custom-action.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Commonfunction  {
 dummyForm!: FormGroup;
  private masterFormLatest = new BehaviorSubject<FormGroup>(this.dummyForm);
  private masterFormLatestEdit = new BehaviorSubject<FormGroup>(this.dummyForm);

  constructor(private spinner: NgxSpinnerService, private customActionService: CustomActionService,private router : Router) { }


  

  /*
      Author      - Naveen Tehlan
      Description - ReplaceNullDataTable function used to replace null with "-" for tables.
      CreatedOn   - 18-Sep-2019
      ModifiedBy  - 
      ModifiedOn  - 
      Purpose     -

  */
  public checkStringIsNullorEmpty(value: string | null| undefined) :boolean{
    if (typeof value !== 'undefined' && value) {
      return true;
    }
    else {
      return false;
    }
    // return value || "-";
  }

  public getMenuItemValues(value: string | null){
    let menuItem = this.checkStringIsNullorEmpty(sessionStorage.getItem('menuItem')) ? sessionStorage.getItem("menuItem")?.toString():null;
    return menuItem != null ? JSON.parse(menuItem) : null;
  }

  // public getUserId(){    
  //   let userInfo = this.checkStringIsNullorEmpty(sessionStorage.getItem('UserInfo')) ? sessionStorage.getItem("UserInfo")?.toString():null;
  //   return userInfo != null ? JSON.parse(userInfo).result : null;
  // }
  public getUserId(){   
     
    let userInfo = this.checkStringIsNullorEmpty(sessionStorage.getItem('UserInfo')) ? sessionStorage.getItem("UserInfo")?.toString():null;
    return userInfo != null ? JSON.parse(userInfo) : null;
  }

  public getUserIdValuestring(): string{
    let userId = this.checkStringIsNullorEmpty(sessionStorage.getItem('userId'))  ? sessionStorage.getItem("userId")?.toString():'';
    return userId != null ? userId : '';
  }

  public getUserTokenValuestring(): string{
    let userToken = this.checkStringIsNullorEmpty(sessionStorage.getItem('userToken'))  ? sessionStorage.getItem("userToken")?.toString():'';
    return userToken != null ? userToken : '';
  }

  public getUserRoleValuestring(): string{
    let userToken = this.checkStringIsNullorEmpty(sessionStorage.getItem('userRole'))  ? sessionStorage.getItem("userRole")?.toString():'';
    return userToken != null ? userToken : '';
  }

  public getCurrentUserRoleId(): string{
    let currentRole = this.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))  ? sessionStorage.getItem("currentRole")?.toString():'';
    return currentRole != null ? JSON.parse(currentRole)?.roleId : '';
  }

  public getEnabledFeatures(){
    let currentFeaturesToggle = this.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentFeaturesToggle'))  ? sessionStorage.getItem("CurrentFeaturesToggle")?.toString():'';
    return currentFeaturesToggle != null ? JSON.parse(currentFeaturesToggle): [];
  }

  /*
      Author      - Naveen Tehlan
      Description - ReplaceNullDataTable function used to replace null with "-" for tables.
      CreatedOn   - 18-Sep-2019
      ModifiedBy  - 
      ModifiedOn  - 
      Purpose     -

  */
 public ReplaceNullDataTable(value: string | null) {
  if (value == null || value?.toString() == "") {
    return "-";
  }
  else {
    return value;
  }
  // return value || "-";
}
  /*
      Author      - Naveen Tehlan
      Description - ReplaceNull function used to replace null with "" for controls.
      CreatedOn   - 18-Sep-2019
      ModifiedBy  - 
      ModifiedOn  - 
      Purpose     -
 
  */
  public ReplaceNull(value: string ) {
    return value || "";
  }
  public FirstLetterLower(value: string) {
    if (value !== null && value !== "") {
      return value.charAt(0).toLowerCase() + value.slice(1);
    }
    else {
      return value;
    }
  }
  public FirstLetterUpper(value: string) {
    if (value !== null && value !== "") {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    else {
      return value;
    }
  }

  public ConvertDateFormat(value: string) {
    if (value !== null && value !== "") {
      let Cudate = new Date(value);
      return Cudate.getDate() + '/' + (Number(Cudate.getMonth()) + 1) + '/' + Cudate.getFullYear();
    }
    else {
      return value;
    }

  }
  public ConvertDateFormatMMDDYYYY(value: string) {
    if (value !== null && value !== "") {
      let Cudate = new Date(value);
      return (Number(Cudate.getMonth()) + 1) + '/' + Cudate.getDate() + '/' + Cudate.getFullYear();
    }
    else {
      return value;
    }

  }

  public ConvertDateFormatYYYYMMDD(value: string) {
    if (value !== null && value !== "") {
      let Cudate = new Date(value);
      return  Cudate.getFullYear() + '/' + (Number(Cudate.getMonth()) + 1) + '/' + Cudate.getDate() ;
    }
    else {
      return value;
    }

  }

  public ConvertDateFormatYYYYMMDDwithoutanysign(value: string) {
    if (value !== null && value !== "") {
      let Cudate = new Date(value);
      return  Cudate.getFullYear().toString() + (Number(Cudate.getMonth()) + 1).toString() +  Cudate.getDate().toString() ;
    }
    else {
      return value;
    }

  }

  public ReplaceStringSpace(value: string) {
    if (value !== null && value !== "") {
      return value.replace(/\s+/g, "");
    }
    else {
      return value;
    }
  }
  public SpaceAfterCapitalLetter(value: string) {
    if (value !== null && value !== "") {
      return value.replace(/([A-Z])/g, ' $1').trim();
    }
    else {
      return value;
    }
  }
  static ConvertDateFormat(value: string) {
    if (value !== null && value !== "") {
      let Cudate = new Date(value);
      return Cudate.getDate() + '/' + (Number(Cudate.getMonth()) + 1) + '/' + Cudate.getFullYear();
    }
    else {
      return value;
    }
  }

  public ConvertDateFormatMMDDYYYYString(value: string) {
    if (value !== null && value !== "" && value !== undefined) {
      
      let Cudate = value?.includes("-") ? value.split('-') : value.split('/');
      if(Cudate.length >0)
      return Cudate[1] + '/' + Cudate[0] + '/' + Cudate[2];
      else
      return value;
    }
    else {
      return value;
    }
  }

  public ConvertDateFormatEditChild(value: string) {
    if (value !== null && value !== "") {
      let Cudate = value.split('_')[1].split('-');
      return new Date(Cudate[1] + '/' + Cudate[0] + '/' + Cudate[2]);
    }
    else {
      return value;
    }
  }
  public ConvertStringLowerCase(value:any) {

        return value.toString().toLowerCase();
    }

    public loginroute(errorms:any) {
      if (errorms == 403) {
        sessionStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
      return true;
  
    }
    ValidCaptcha(capValue:string, txtcapvalue:string) {

      if ((txtcapvalue == null || txtcapvalue == '')) {
        return true;
      }
      else {
        let GenCaptcha;
        let EnterredCaptch
  
      
          GenCaptcha = this.ReplaceStringSpace(capValue);
          EnterredCaptch = this.ReplaceStringSpace(txtcapvalue);
        
        // if (GenCaptcha.toUpperCase( ) ==  EnterredCaptch.toUpperCase( )) {
        if (GenCaptcha == EnterredCaptch) {
          return true;
        }
        else {
          return false;
        }
      }
    }
   public loadJson(filePath:string){
      const json : string | null = this.loadTextFile(filePath,"application/json");
      
      return json != null ? JSON.parse(json) : null;
    }
    
    public loadTextFile(filePath:string, mimeType:string)
    {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", filePath,false);
      if(mimeType != null){
        if(xmlhttp.overrideMimeType)
        {
          xmlhttp.overrideMimeType(mimeType);
        }
      }
      xmlhttp.send();
      if(xmlhttp.status == 200){
        return xmlhttp.responseText;
      }
      else{
        return null;
      }
    }

   public createFilterDate(dateObject: any){
    return ('0'+dateObject['day']).slice(-2) +"/"+ ('0'+dateObject['month']).slice(-2) +"/"+ dateObject['year'];
   }

   public createngbDateValue(date: string): NgbDate | null{
    let dateArray = date.split('/');
    return new NgbDate(+dateArray[2], +dateArray[1], +dateArray[0]);
   }

   public setFormLatestValue(formValue: FormGroup,callfrom:string){ 
     console.log(formValue)  
     if(callfrom == "I") 
    this.masterFormLatest.next(formValue);
    else if(callfrom == "E")
    this.masterFormLatestEdit.next(formValue);
  }

  public getFormLatestValue(callfrom:string){   
    if(callfrom == "I") { 
    return this.masterFormLatest.asObservable();
    }
    else {
    return this.masterFormLatestEdit.asObservable();
    }
  }

  public countCharacter(string: string,character:any) {
    let count = 0;
    for (var i = 0; i < string.length; i++) {
        if (string.charAt(i) == character) { //counting : or ,
            count ++;
        }
    }
    return count;
}


  public isJson(json:any) {
    if(typeof(json) == "object")
    json = JSON.stringify(json)

    //Nested Count function only to be used for counting colons and commas
 
  
    json = json.toString().trim(); // remove whitespace, start and end spaces

    //check starting and ending brackets
    if (json.charAt(0) != '{' || json.charAt(json.length-1) != '}') {
        console.log("Brackets {} are not balanced")
        return false
    }
    //else this line will check whether commas(,) are one less than colon(:)
    else if ( !(this.countCharacter(json,':')-1 == this.countCharacter(json, ',')) ){

        console.log("comma or colon are not balanced");
        return false;

    } else {

        json = json.substring(1, json.length-1); //remove first and last brackets
        json = json.split(','); //split string into array, and on each index there is a key-value pair

        //this line iterate the array of key-value pair and check whether key-value string has colon in between
        for (var i = 0; i < json.length; i++) {
        
          let  pairs = json[i];

            if (pairs.indexOf(':') == -1) { //if colon not exist in b/w

                console.log("No colon b/w key and value");
                return false;
            }
        }
    }
    return true;
};

public createJsonFiltervalue(input: string, columnDataType: string): string {
  let _columnValue = '';
  let _inputArray: string[] = input.split(",");
  _inputArray.forEach((value:string)=>{
    
  if (columnDataType =="varchar" || columnDataType =="nvarchar" || columnDataType =="char" || columnDataType =="nchar") {
      // Check if the string is a valid date
       // Try to parse the input string as a number
    const numValue = parseFloat(value);

    // Check if the parsed value is NaN (not a number)
    if (isNaN(numValue)) {
      _columnValue = _columnValue + "'"+ value.toString().trim() + "',";
    } else {
      _columnValue = _columnValue +   value.toString().trim() + ",";
    }
      
  } else if (columnDataType =="int" || columnDataType =="decimal") {
    _columnValue = _columnValue +  value.toString().trim() + ",";
  }
  else if (columnDataType =="date" || columnDataType =="datetime") {
    _columnValue = _columnValue +  "new Date('"+ this.ConvertDateFormatMMDDYYYYString(value.toString().trim()) + "'),";
} 
else if (columnDataType =="bit") {
  _columnValue = _columnValue +  value.toString().trim() + ",";
} 
  });
  return _inputArray.length > 1 ? "["+ this.removeLastComma(_columnValue) +"]"  :  this.removeLastComma(_columnValue);
}

public removeLastComma(input: string): string {
  const lastIndex = input.lastIndexOf(',');
  if (lastIndex !== -1) {
      return input.substring(0, lastIndex) + input.substring(lastIndex + 1);
  }
  return input;
}


commonCustumFunction(param: string,SeltableIDForm: string) {

}

}