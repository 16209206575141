import { Injectable ,  NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Injectable({
  providedIn: 'root'
})
export class IpDeatilsService {
  ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
  constructor(private http: HttpClient, private zone: NgZone){
    // if(!this.localStorageHasKeyWithValue('ExternalIPAddress')){
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( (data:any)  => {
      localStorage.setItem('ExternalIPAddress', data.ip);
      // this.ipAddress = data
    })
  // }
 this.determineLocalIp();
  }


  // Function to check if localStorage key exists and has a value
 localStorageHasKeyWithValue(key: string): boolean {
  // Check if localStorage is supported by the browser
  if (typeof Storage !== "undefined") {
      // Check if the key exists
      const item = localStorage.getItem(key);
      if (item !== null) {
          // Check if the item has a non-null value
          return item !== null && item !== undefined;
      }
  }
  return false; // Return false if localStorage is not supported or key doesn't exist
}

// getExternalIPAddress():any {
//   this.http.get<{ip:string}>('https://jsonip.com');
  
// }
 determineLocalIp() {
  window.RTCPeerConnection = this.getRTCPeerConnection();

  const pc = new RTCPeerConnection({ iceServers: [] });
  pc.createDataChannel("");
  pc.createOffer().then(pc.setLocalDescription.bind(pc));

  pc.onicecandidate = ice => {
    this.zone.run(() => {
      if (!ice || !ice.candidate || !ice.candidate.candidate) {
        return;
      }

      let lipadd= this.ipRegex.exec(ice.candidate.candidate) != null ?this.ipRegex.exec(ice.candidate.candidate)![1] : '';
    
      localStorage.setItem('LocalIPAddress', lipadd);

      pc.onicecandidate = () => {};
      pc.close();
    });
  };
}

private getRTCPeerConnection() {
  return (
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection
  );
}


}


