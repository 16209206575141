import { Component, OnInit } from '@angular/core';
import { Commonfunction } from '../../../shared/commonutility';
import { UserService } from '../../../shared/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalAlertService } from '../../../shared/global-alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { tick } from '@angular/core/testing';
import { custumValidation } from '../../../shared/custum-validation';
import { CustomActionService } from '../../../shared/customAction/custom-action.service';
import { PreviewImageService } from '../../../shared/preview-image/preview-image.service';
import { AlertDialogBoxService } from '../../../shared/alert-dialog-box/alert-dialog-box.service';
import { Customvalidation } from "../../../shared/Validation";
import { ConfirmDialogBoxService } from '../../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GenericPrintService } from '../../../shared/generic-print-service';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { formatDate } from '@angular/common';
import { IgenericFilterParamValue, IviewFullDetailRowData } from '../../../shared/model/genericTableListViewModel';
interface IFeeDuesFliter {
  sessionId: number;
  classId: number;
  sectionId: number;
  studentId: number;
}
interface IFeeDuesShowFliter {
  session: string;
  class: string;
  section: string;
  student: string;
}

@Component({
  selector: 'app-pay-fee-dues',
  templateUrl: './pay-fee-dues.component.html',
  styleUrls: ['./pay-fee-dues.component.css'],
  providers: [DatePipe]
})
export class PayFeeDuesComponent implements OnInit {
  showSelectedFilters: IFeeDuesShowFliter= {} as IFeeDuesShowFliter;
  feeInstalmentFields: any = {};
  feeTransactionFields: any = {};
  isRenderDetailFeeInstalmentProc: number = 0;
  ischeckboxshowFeeInstalmentProc: number = 1;
  sendSelectedData_FeeInstallment: boolean = true;
  selectedfeeIds: string = "";
  GenericPrintDataArray: any[]=[];
  showAllFilter: boolean = true;
  isProceedShow: boolean = false;
  showTransactionStatus: boolean = false;
  showStudentFeeData : boolean = false;
  totalFee: number = 0;
  lateFee: number = 0;
  adjustmentAmount : number = 0;
  balanceFee: number = 0;
  totalSumFee: number = 0;
  transStatus: string = "failed";
  totalSumPaidFee: number = 0;
  totalSumBalanceFee: number = 0;
  totalSumAdjustmentFee: number = 0;
  transId: string = '';
  feefilters: IFeeDuesFliter;
  paymentTypeId: number = 1;
  studentDetails: any = [];
  feeDuesReactiveForm!: FormGroup;
  feeDuesCashPayReactiveForm!: FormGroup;
  feeDuesChequepayReactiveForm!: FormGroup;
  isEnabled: boolean = true;  
  isFormEnabled: boolean = true;
  isPayShow: boolean = false;
  param:string = "";
  SeltableID: string="";
    SeltableIDForm: string="NO Men";
    menulabel: string="";
    formlable: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
  selectedRows: any = [];
  DetailtableRowData: any = [];
  TranstableRowData: any = [];
  headerDetailData: any =[];
  masterSelected: boolean = false;
  dropdownListsessiondrp = [];
  selectedItemssessiondrp: Array<{ id: string, itemName: string, currentSession: boolean }> = [];
  dropdownListclassdrp = [];
  selectedItemsclassdrp: Array<{ id: string, itemName: string }> = [];
  dropdownListsectiondrp = [];
  selectedItemssectiondrp: Array<{ id: string, itemName: string }> = [];
  dropdownListstudentdrp = [];
  selectedItemsstudentdrp: Array<{ id: string, itemName: string }> = [];
  dropdownSettings:any = {
    singleSelection: true,
    // text:"Select Countries",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    // primaryKey:'DrpValue',
    // labelKey:'DrpOption',
    classes: "global-select-box",
    disabled: false
};
jsonFiledata: any[] = [];
userInfodata: any[] = [];
preFilterParmValue: Array<IgenericFilterParamValue> = [] as Array<IgenericFilterParamValue>;
    addPermission: boolean = false;
    editPermission: boolean = false;
    activePermission: boolean = false;
    deletePermission: boolean = false;
    printPermission: number = 0;
    isHistoryShowProc: string = '';
    isWorkflowImplemented: boolean = false;
    isRenderDetail: number = 0;
    status: boolean = false;
  toggle: boolean = false;
    
  constructor(private userService: UserService, private commonfunction: Commonfunction,  private spinner: NgxSpinnerService,
    private globalAlertService: GlobalAlertService,private datePipe:DatePipe, private customActionService: CustomActionService, private previewImageService: PreviewImageService,
     private AlertService: AlertDialogBoxService, public Customvalidation: Customvalidation, private globalConfirmService: ConfirmDialogBoxService,
     private genericPrintService: GenericPrintService, private AmCharts: AmChartsService) {
      this.feefilters = {} as IFeeDuesFliter;
      this.feeInstalmentFields = this.commonfunction.loadJson("~/../assets/formSettingJsonfile/getFeePayFormSetting.json")?.feeInstallments;
      this.feeTransactionFields = this.commonfunction.loadJson("~/../assets/formSettingJsonfile/getFeePayFormSetting.json")?.feeTransactions;
      
     }
     
  ngOnInit(): void {
    
    this.feeDuesReactiveForm = new FormGroup({
      sessionId: new FormControl(this.feefilters.sessionId, [Validators.required]),
      classId: new FormControl(this.feefilters.classId),
      sectionId: new FormControl(this.feefilters.sectionId),
      studentId: new FormControl(this.feefilters.studentId, [Validators.required]),
    });

  
    let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
    this.SeltableID = paramjs.formValue;
    this.SeltableIDForm = paramjs.formName;
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
   
      this.userInfodata = this.commonfunction.getUserId();

   
    this.hideSearhForm();
    
   
   
  }
hideSearhForm(){
  let featureName = 'p2pFeature001';
  let enabledDataList = this.commonfunction.getEnabledFeatures();
  let roleId = +this.commonfunction.getCurrentUserRoleId();
  let chkFeatureEnabled = enabledDataList.filter((x:any)=>x['roleId'] == roleId  && x['formValue'] == this.SeltableID && x['feature'] == featureName);
if(chkFeatureEnabled.length == 0)
{
this.isFormEnabled = true;

}
else{
this.isFormEnabled = false;
// this.getDatafromAPI();
}
this.getDataSessionDatafromAPI();
}
  getDatafromAPI(){
    // T - indicates data for table.
// SL - indicates data for List view but send "T" in API call
// E - incicates data for Excel
//.

this.spinner.show();
let dataRow = [];
let that = this;
let sessionId = this.selectedItemssessiondrp.length > 0 ? this.selectedItemssessiondrp[0].id : '';
let classId= this.selectedItemsclassdrp.length >0 ? this.selectedItemsclassdrp[0].id : '';
let boardId = '';
let sectionId = this.selectedItemssectiondrp.length >0 ? this.selectedItemssectiondrp[0].id : '';
let studentId =  this.selectedItemsstudentdrp.length >0 ? this.selectedItemsstudentdrp[0].id : '';

let procName: string =  "uspAppGeneric_custum_Ischool_GetStudentDetailsFeeScreen|sessionId=" + sessionId +"|classId="+ classId +"|boardId="+ boardId +"|sectionId="+ sectionId +"|studentId="+ studentId  ;

let SpParams:string = procName ;
this.userService.GetDataSetResult(SpParams, this.SeltableIDForm).subscribe((data:any) => {
  this.selectedRows = [];
  this.studentDetails = [];

 
 console.log(this.transId);
  // this.DetailtableRowData = data['table'];
  this.studentDetails = data['table'];
  this.transId = data['table'][0]['transIdNew'];
  // this.editPermission = data['table1'][0]['editPermission'];
  // this.printPermission = data['table1'][0]['printPermission'];
  // this.isWorkflowImplemented = data['table1'][0]['isWorkflowImplemented'];
  // this.activePermission = data['table1'][0]['activePermission'];
  // this.deletePermission = data['table1'][0]['deletePermission'];
  // this.isRenderDetail = data['table1'][0]['isRenderDetail'];
  // this.ischeckboxshowFeeInstalmentProc = data['table1'][0]['isRenderDetail'];
  // this.TranstableRowData = data['table2'];
  console.log(this.DetailtableRowData );
},
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

              // this.checkUncheckAll();
          this.showStudentFeeData = true;
          let _preFilterParamValue: Array<IgenericFilterParamValue> = [] as Array<IgenericFilterParamValue>;
          _preFilterParamValue.push({"paramName":"sessionId", "paramValue":sessionId});
          _preFilterParamValue.push({"paramName":"classId", "paramValue":classId});
          _preFilterParamValue.push({"paramName":"boardId", "paramValue":boardId});
          _preFilterParamValue.push({"paramName":"sectionId", "paramValue":sectionId});
          _preFilterParamValue.push({"paramName":"studentId", "paramValue":studentId});
          this.feeInstalmentFields.isRenderDetailFeeInstalmentProc = 1;
          this.preFilterParmValue = _preFilterParamValue;
          this.onStudentFeePaidSummary(sessionId,studentId);
          this.spinner.hide();
      });

      

}

getDataSessionDatafromAPI():any{
  // T - indicates data for table.
// SL - indicates data for List view but send "T" in API call
// E - incicates data for Excel
//.

this.spinner.show();
let dataRow = [];
let that = this;

let procName: string =  "uspAppGeneric_custum_Ischool_PayFeeDuesSessionDropDown"  ;

let SpParams:string = procName ;
this.userService.GetDataSetResult(SpParams, this.SeltableIDForm).subscribe((data:any) => {
  this.selectedItemssessiondrp = [];
  this.selectedItemsclassdrp = [];
  this.selectedItemssectiondrp = [];
 this.selectedItemsstudentdrp = [];
 this.dropdownListclassdrp = [];
 this.dropdownListsectiondrp = [];
 this.dropdownListstudentdrp = [];
  this.dropdownListsessiondrp = data['table'];
  this.selectedItemssessiondrp = this.dropdownListsessiondrp.filter((x:any)=> x['currentSession'] == 1);
  this.showSelectedFilters.session = this.selectedItemssessiondrp.length > 0 ? this.selectedItemssessiondrp[0].itemName : "";
  this.feefilters.sessionId = this.selectedItemssessiondrp.length > 0 ? +this.selectedItemssessiondrp[0].id : 1;
  // this.feeDuesReactiveForm.get('sessionId')?.patchValue(this.feefilters.sessionId);
  console.log(this.DetailtableRowData );
},
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

              // this.checkUncheckAll();
              if(this.isFormEnabled){
              this.getDataClassDatafromAPI();
              this.getDataStudentDatafromAPI();
              }
              else if(!this.isFormEnabled){
              this.getDatafromAPI();
              }
          this.spinner.hide();
      });
}

getDataClassDatafromAPI():any{
  // T - indicates data for table.
// SL - indicates data for List view but send "T" in API call
// E - incicates data for Excel
//.

this.spinner.show();
let dataRow = [];
let that = this;
let sessionId = this.selectedItemssessiondrp.length > 0 ? this.selectedItemssessiondrp[0].id : '';

let procName: string =  "uspAppGeneric_custum_Ischool_PayFeeDuesClassDropDown|sessionID=" + sessionId   ;

let SpParams:string = procName ;
this.userService.GetDataSetResult(SpParams, this.SeltableIDForm).subscribe((data:any) => {
  
  this.selectedItemsclassdrp = [];
  this.selectedItemssectiondrp = [];
 this.selectedItemsstudentdrp = [];

 this.dropdownListsectiondrp = [];
 this.dropdownListstudentdrp = [];
  this.dropdownListclassdrp = data['table'];
  
  
  console.log(this.DetailtableRowData );
},
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

              // this.checkUncheckAll();
       this.getDataStudentDatafromAPI();
          this.spinner.hide();
      });
}

getDataSectionDatafromAPI():any{
  // T - indicates data for table.
// SL - indicates data for List view but send "T" in API call
// E - incicates data for Excel
//.

this.spinner.show();
let dataRow = [];
let that = this;

let classId= this.selectedItemsclassdrp.length >0 ? this.selectedItemsclassdrp[0].id : '';

let procName: string =  "uspAppGeneric_custum_Ischool_PayFeeDuesSectionDropDown|classId="+ classId  ;

let SpParams:string = procName ;
this.userService.GetDataSetResult(SpParams, this.SeltableIDForm).subscribe((data:any) => {
  
  this.selectedItemssectiondrp = [];
 this.selectedItemsstudentdrp = []; 
 this.dropdownListsectiondrp = [];
 this.dropdownListstudentdrp = [];
  this.dropdownListsectiondrp = data['table'];
},
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

              // this.checkUncheckAll();
       
          this.spinner.hide();
      });
}

getDataStudentDatafromAPI():any{
  // T - indicates data for table.
// SL - indicates data for List view but send "T" in API call
// E - incicates data for Excel
//.

this.spinner.show();
let dataRow = [];
let that = this;
let sessionId = this.selectedItemssessiondrp.length > 0 ? this.selectedItemssessiondrp[0].id : '';
let classId= this.selectedItemsclassdrp.length >0 ? this.selectedItemsclassdrp[0].id : '';
let boardId = '';
let sectionId = this.selectedItemssectiondrp.length >0 ? this.selectedItemssectiondrp[0].id : '';
let studentId = '';
let procName: string =  "uspAppGeneric_custum_Ischool_PayFeeDuesStudentDropDown|sessionId=" + sessionId +"|classId="+ classId  +"|sectionId="+ sectionId  ;

let SpParams:string = procName ;
this.userService.GetDataSetResult(SpParams, this.SeltableIDForm).subscribe((data:any) => {
  
 this.selectedItemsstudentdrp = [];

  this.dropdownListstudentdrp = data['table'];
  
  console.log(this.DetailtableRowData );
},
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {

              // this.checkUncheckAll();
       
          this.spinner.hide();
      });
}

OnFilterSearch(){
  this.getDatafromAPI();
}

onItemSelectSession(item: any) {  
  this.showSelectedFilters.session = this.selectedItemssessiondrp[0].itemName;
  this.showSelectedFilters.class = '';
  this.showSelectedFilters.section = '';
  this.showSelectedFilters.student = '';
 if(!this.isFormEnabled){
         let preFilter = this.preFilterParmValue.filter((x:IgenericFilterParamValue)=> x.paramName == "sessionId");
         preFilter[0].paramValue = this.selectedItemssessiondrp[0].id;
         this.getDatafromAPI();
 }
 else 
this.getDataClassDatafromAPI();

}
OnItemDeSelectSession(item: any) {
  if(!this.isFormEnabled){
    let preFilter = this.preFilterParmValue.filter((x:IgenericFilterParamValue)=> x.paramName == "sessionId");
    preFilter[0].paramValue = this.selectedItemssessiondrp[0].id;
}
  this.showSelectedFilters.session = '';
  this.showSelectedFilters.class = '';
  this.showSelectedFilters.section = '';
  this.showSelectedFilters.student = '';
  this.selectedItemsclassdrp = [];
  this.selectedItemssectiondrp = [];
 this.selectedItemsstudentdrp = [];
 this.dropdownListclassdrp = [];
 this.dropdownListsectiondrp = [];
 if(this.selectedItemssessiondrp.length > 0){
  this.dropdownListstudentdrp = [];

 }
 this.resetDataDetails();
 
}

onItemSelectClass(item: any) {  

  this.showSelectedFilters.class = this.selectedItemsclassdrp[0].itemName;
  this.showSelectedFilters.section = '';
  this.showSelectedFilters.student = '';
  this.getDataSectionDatafromAPI();
  
  }
  OnItemDeSelectClass(item: any) {
    
    this.showSelectedFilters.class = '';
    this.showSelectedFilters.section = '';
    this.showSelectedFilters.student = '';
    this.selectedItemsclassdrp = [];
    this.selectedItemssectiondrp = [];
   this.selectedItemsstudentdrp = [];
   this.dropdownListsectiondrp = [];
   if(this.selectedItemssessiondrp.length > 0){
    this.dropdownListstudentdrp = [];
  
   }

   this.resetDataDetails();
   
  }

  onItemSelectSection(item: any) {  
    
    this.showSelectedFilters.section = this.selectedItemssectiondrp[0].itemName;
    this.showSelectedFilters.student = '';
    this.getDataStudentDatafromAPI();
    
    }
    OnItemDeSelectSection(item: any) {
     
  this.showSelectedFilters.section = '';
  this.showSelectedFilters.student = '';
      this.selectedItemssectiondrp = [];
     this.selectedItemsstudentdrp = [];
     
     if(this.selectedItemssessiondrp.length > 0){
      this.dropdownListstudentdrp = [];
    
     }
     this.resetDataDetails();
    }
    onItemSelectStudent(item: any) {     
    this.showSelectedFilters.student = this.selectedItemsstudentdrp[0].itemName;
      this. onToggleFilter(false);
      this.OnFilterSearch();
      
      }
      OnItemDeSelectStudent(item: any) {
        
    this.showSelectedFilters.student = '';
        this. onToggleFilter(false);
      this.DetailtableRowData = [];
      this.resetDataDetails();
    
       
      }

  FirstLetterLower(value:string) {
    return this.commonfunction.FirstLetterLower(value);
  }
  FirstLetterUpper(value:string) {
    return this.commonfunction.FirstLetterUpper(value);
  }
  ReplaceNullDataTable(value:string) {
    return this.commonfunction.ReplaceNullDataTable(value);
  }
    checkUncheckAll() {
  
      let that = this;
      this.DetailtableRowData.forEach((value:any, index:number)=> {
        if(value.isPaid == 0)
          value.isSelected = that.masterSelected;
      });
      this.getCheckedItemList();
  }
  isAllSelected() {
      this.masterSelected = this.DetailtableRowData.forEach((item: any)=> {      
          return item.isSelected == true;
         
      })
      this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.selectedRows = [];
    let that = this;
    this.DetailtableRowData.forEach((value:any, index:number)=> {
        if (value.isSelected && value.isPaid == 0) {
            that.selectedRows.push(value);
        }
    });
    if(this.selectedRows.length > 0){
      
      this.totalFee = this.selectedRows.map((a:any) => +a['feeAmount']).reduce(function(a:number, b:number)
      {
        return a + b;
      });

      // this.lateFee = this.selectedRows.map((a:any) => +a['feeAmountExcessFine']).reduce(function(a:number, b:number)
      // {
      //   return a + b;
      // });

      this.adjustmentAmount = this.selectedRows.map((a:any) => +a['adjustmentAmount']).reduce(function(a:number, b:number)
      {
        return a + b;
      });

      this.balanceFee = this.selectedRows.map((a:any) => +a['balance']).reduce(function(a:number, b:number)
      {
        return a + b;
      });

      this.totalSumFee  = (this.totalFee + this.lateFee + this.balanceFee) - this.adjustmentAmount;
      
    }
    else{      
      this.totalFee = 0;
      this.lateFee = 0;
      this.balanceFee = 0;
      this.totalSumFee = 0;
      this.adjustmentAmount = 0;
    }
}

OnProceed(callfrom:number){
  if(callfrom == 1){
    this.isProceedShow = true;
  }
  else{
 this.selectedfeeIds ="";
  this.totalSumPaidFee=this.totalSumFee;

  let studentId = 0;
  let selectedIds: Array<string>= [];
  this.selectedRows.forEach((value:any)=>{
    //selectedfeeIds = selectedfeeIds + value['id'].toString() +",";
    selectedIds.push(value['id'].toString());
if(studentId == 0)
{
  studentId =  +value['studentId'];
}
  });
  this.selectedfeeIds = selectedIds.join(", ");

this.transId = this.studentDetails[0]['transIdNew'] + this.datePipe.transform( new Date(),'yyyyMMddHHmmss');
  this.isPayShow = true;
  this.feeDuesCashPayReactiveForm = new FormGroup({
    FtransIdNew: new FormControl(this.transId),
    FtotalSumFee: new FormControl({value:this.totalSumFee, disabled: true}),
    // FtotalSumPaidFee: new FormControl(this.totalSumPaidFee, [Validators.required, custumValidation.validateLessEqualDecimalValues("Paid Amount should be equal or less than Total Sum Fee", "16", "decimal", "textbox", "", this.totalSumFee, "Total Fee Amount", "")]),
    FtotalSumPaidFee: new FormControl(this.totalSumPaidFee, [Validators.required]),
    FtotalSumBalanceFee: new FormControl({value:this.totalSumBalanceFee, disabled: true}),
    FtotalSumAdjustmentFee: new FormControl({value:this.totalSumAdjustmentFee, disabled: true}),
    FselectedFeeIds: new FormControl(this.selectedfeeIds),
    FstudentId: new FormControl(studentId),
    FpaymentTypeId: new FormControl(this.paymentTypeId),
    FchequeNumber: new FormControl(""),
    FbankName: new FormControl("")
  });

  this.feeDuesChequepayReactiveForm = new FormGroup({
    FtransIdNew: new FormControl(this.transId),
    FtotalSumFee: new FormControl({value:this.totalSumFee, disabled: true}),
    FtotalSumPaidFee: new FormControl(this.totalSumPaidFee, [Validators.required]),
    FtotalSumBalanceFee: new FormControl({value:this.totalSumBalanceFee, disabled: true}),
    FtotalSumAdjustmentFee: new FormControl({value:this.totalSumAdjustmentFee, disabled: true}),
    FselectedFeeIds: new FormControl(this.selectedfeeIds),
    FstudentId: new FormControl(studentId),
    FpaymentTypeId: new FormControl(this.paymentTypeId),
    FchequeNumber: new FormControl("",[Validators.required]),
    FbankName: new FormControl("",[Validators.required])
  });
  }

}
onPayCancel(){
  this.globalConfirmService.ConfirmFunction("Confirm Cancel !!", "Are you sure you want to cancel?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
  this.isPayShow = false;
  this.selectedRows = [];
  this.showStudentFeeData = true;
  this.isProceedShow = false;
}
});
}

onPay() {
  this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed to pay?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
              this.submitcall();             
          }
      });
}

submitcall() {
  this.spinner.show();
  let printData;
  console.log(this.feeDuesCashPayReactiveForm);
  let data = "";
  if(this.paymentTypeId == 1){
   data = JSON.stringify("uspAppGeneric_custum_Ischool_FeeDuesPaymentEntry|sessionId=" + this.selectedItemssessiondrp[0]['id'] + '|transID='+ this.feeDuesCashPayReactiveForm?.value['FtransIdNew'] + '|totalSumFee='+ this.totalSumFee.toString()+
  '|totalSumPaidFee='+ this.feeDuesCashPayReactiveForm?.value['FtotalSumPaidFee']+ '|totalSumBalanceFee='+ this.totalSumBalanceFee.toString() + '|totalSumAdjustmentFee='+ this.totalSumAdjustmentFee.toString()+ 
  '|selectedFeeIds='+ this.feeDuesCashPayReactiveForm?.value['FselectedFeeIds']+ '|studentId='+ this.feeDuesCashPayReactiveForm?.value['FstudentId']+ '|paymentTypeId='+ this.feeDuesCashPayReactiveForm?.value['FpaymentTypeId']+ '|chequeNumber='+ this.feeDuesCashPayReactiveForm?.value['FchequeNumber']+ '|bankName='+ this.feeDuesCashPayReactiveForm?.value['FbankName']
  + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
  }
  else if(this.paymentTypeId == 2){
    data = JSON.stringify("uspAppGeneric_custum_Ischool_FeeDuesPaymentEntry|sessionId=" + this.selectedItemssessiondrp[0]['id'] + '|transID='+ this.feeDuesChequepayReactiveForm?.value['FtransIdNew'] + '|totalSumFee='+ this.totalSumFee.toString()+
    '|totalSumPaidFee='+ this.feeDuesChequepayReactiveForm?.value['FtotalSumPaidFee']+ '|totalSumBalanceFee='+ this.totalSumBalanceFee.toString()+ '|totalSumAdjustmentFee='+ this.totalSumAdjustmentFee.toString()+ 
    '|selectedFeeIds='+ this.feeDuesChequepayReactiveForm?.value['FselectedFeeIds']+ '|studentId='+ this.feeDuesChequepayReactiveForm?.value['FstudentId']+ '|paymentTypeId='+ this.feeDuesChequepayReactiveForm?.value['FpaymentTypeId']+ '|chequeNumber='+ this.feeDuesChequepayReactiveForm?.value['FchequeNumber']+ '|bankName='+ this.feeDuesChequepayReactiveForm?.value['FbankName']
    + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
  }

  this.userService.CustumActionSubmitForm(data, this.SeltableIDForm).subscribe((datacom: any) => {
      this.spinner.hide();
      let printArray = [];
      if (datacom != "") {
          let resultarray = JSON.parse(datacom);
          if (resultarray['successFlag'] == "success") {
              printArray.push({ 'template': resultarray['printActionResult'].toString() });              
              this.transStatus ="successful";
              // this.resetForm(1);
              
              this.AlertTitle = "Success!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-success-bg";
              this.AlertAnimationType = "smile";
              this.AlertType = "NP_popup_success";
          }

          else {
           
              this.transStatus ="failed";
              this.AlertTitle = "Error!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }

          this.showTransactionStatus = true;
          this.isPayShow = false;

      }
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
          // this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
          // this.AlertService.AlertFunction(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType)
          //     .then((confirmed) => {
          //         // if (!confirmed) {
          //         //     if (printData = 1) {
          //         //         if (this.printPermission == 1)
          //         //             this.genericPrintService.printDocument();
          //         //         else if (this.printPermission == 2)
          //         //             this.GenericPrintFunction();
          //         //     }
          //         // }
          //     });
      }
  );
}

resetDataDetails(){
  this.DetailtableRowData = [];
  this.isPayShow = false;
  this.isProceedShow = false;
  this.showTransactionStatus = false;
  this.showStudentFeeData = false;
  this.selectedRows = [];
  this.totalSumBalanceFee = 0;
  this.totalSumAdjustmentFee = 0;
}
calculateBalance(callFrom: string){

  this.totalSumBalanceFee = this.totalSumFee < this.totalSumPaidFee ? 0 : this.totalSumFee - this.totalSumPaidFee;
  this.totalSumAdjustmentFee = this.totalSumFee < this.totalSumPaidFee ?  this.totalSumPaidFee - this.totalSumFee : 0;

}

onPaymentType(paymentType:number){
  this.paymentTypeId = paymentType;
  
}

onRestSearch(){
  this.feeDuesReactiveForm.reset();
  this.selectedItemssessiondrp = this.dropdownListsessiondrp.filter((x:any)=> x['currentSession'] == 1);
  this.feefilters.sessionId = this.selectedItemssessiondrp.length > 0 ? +this.selectedItemssessiondrp[0].id : 1;
  this.resetDataDetails();
  this.getDataSessionDatafromAPI();
}

onReset(event:number){
  this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
          this.onRestSearch();
          if(event === 3){

          }
  
}
});
}

onStudentFeePaidSummary(session: string, student: string) {

  let that = this;
     let today= new Date();
     let  jstoday = formatDate(new Date(), 'yyyy/MM/dd', 'en');
     console.log(jstoday);
  let proc = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_StudentFeePaid @sessionId['"+session.toString()+"', @studentId['"+student.toString()+"',"
  let proClick = "P2PtblGeneric_DashboardDonoutGraph_custom_Ischool_AttendanceAsPerDateDetail @fromDate['"+jstoday.toString()+"', @toDate['"+jstoday.toString()+"',"
  console.log(proc);
      this.userService.getDashboardColumnBar("Bar",proc ).subscribe((data: any) => {
        let dataPoints:any[] = [];
  
        $.each(data["table"], function (key:number, value:any) {
          dataPoints.push({ xAxis: value.xAxis, yAxis: value.yAxis,color: value.color});
        });
  
        console.log(dataPoints);
  
        var chart = this.AmCharts.makeChart("feeDuesChart", {
          "type": "pie",
          "theme": "loght",
          "titles": [{
            "text": "Fee Chart (" + this.selectedItemssessiondrp[0].itemName +")",
            "size": 16
          }],
          "dataProvider": dataPoints,
          "valueField": "yAxis",
          "titleField": "xAxis",
          "labelText": "[[yAxis]]",
          "startEffect": "elastic",
          "startDuration": 2,
          "labelRadius": 15,
          "innerRadius": "50%",
          "depth3D": 10,
          "titleField.fill": "color",
          //"balloonText": "[[title]]<br><span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span>",
          "angle": 15,
          "export": {
            "enabled": true
          },
          "legend": {
            "accessibleLabel": "xAxis",
            "align": "center",
            "autoMargins": true
            //"useGraphSettings": true
          }
         
        });
        // this.AmCharts.addListener(chart, "clickSlice", (e) => {
        //   this.onClickChartDetails(e, proClick, "Student's Today Attendance")
        // });
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        });
  
        
    }

    OnPrintFullDetail(event:any, id:string) {

      this.spinner.show();
      var trdata = '';
      const that = this;
    
    
      let param = "uspAppGenericCommonPrintFunctionality|Formname=Student Dues Creation"  + "|recordId=" + id;
      this.userService.getCommonDataSet(this.SeltableIDForm, param).subscribe((data: any) => {
    
     
          that.genericPrintService.printDataArray = data.table;
          that.GenericPrintDataArray = data.table;
    
          this.spinner.hide();
    
      },
          (err: HttpErrorResponse) => {
              this.spinner.hide();
              if (err.status == 401) {
                  this.AlertTitle = "Error!!";
                  this.AlertMessage = "You are not authorized!";
                  this.AlertClass = "NP_emoji-fail-bg";
                  this.AlertAnimationType = "sad";
                  this.AlertType = "NP_popup_fail";
              }
              else if (err.status == 403) {
                  this.Customvalidation.loginroute(err.status);
              }
              else {
                  this.AlertTitle = "Error!!";
                  this.AlertMessage = err.message.toString();
                  this.AlertClass = "NP_emoji-fail-bg";
                  this.AlertAnimationType = "sad";
                  this.AlertType = "NP_popup_fail";
              }
              this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
          },
          () => {
             
                  this.genericPrintService.printDocument();
              
                  // this.GenericPrintFunction();
          });
    }
    GenericPrintFunction() {
      if (this.GenericPrintDataArray != null) {
              
          this.genericPrintService.GenericPrintFunction(this.GenericPrintDataArray);
      }
    }

    onSwitchFilter(){
      this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to switch filters?", "Yes", "No")
          .then((confirmed) => {
              if (confirmed) {
                this.showAllFilter = !this.showAllFilter;
              this.onRestSearch();
            
      
    }
    });
    }
    switchFilters(){
      if(this.DetailtableRowData.length == 0){
      this.showAllFilter = !this.showAllFilter;
      this.onRestSearch();
      }
      else{
      this.onSwitchFilter();
      if(!this.showAllFilter){
        if(this.selectedItemsstudentdrp.length == 0)
        this.getDataStudentDatafromAPI()
        else{

        }
      }
    }
    }

    OnViewFullDetailFeeInstallment(event:IviewFullDetailRowData){

      console.log(event)
    }
    OnViewFullDetailFeeTransaction(event:IviewFullDetailRowData){

      console.log(event)
    }
    OnCustomActionClickDetailFeeInstallment(event:IviewFullDetailRowData){
this.OnPrintFullDetail(event.event, event.rowData['id']);
      console.log(event)
    }

    OnCustomActionClickDetailFeeTransaction(event:IviewFullDetailRowData){
      this.OnPrintFullDetail(event.event, event.rowData['id']);
            console.log(event)
          }
          OnSelectedRowFeeTransaction(selectedRows:any){
            console.log(selectedRows);
          }
    OnSelectedRowFeeInstallment(selectedRows:any){
      this.selectedRows = selectedRows;
      if(this.selectedRows.length > 0){
      
        this.totalFee = this.selectedRows.map((a:any) => +a['feeAmount']).reduce(function(a:number, b:number)
        {
          return a + b;
        });
  
        // this.lateFee = this.selectedRows.map((a:any) => +a['feeAmountExcessFine']).reduce(function(a:number, b:number)
        // {
        //   return a + b;
        // });
  
        this.adjustmentAmount = this.selectedRows.map((a:any) => +a['adjustmentAmount']).reduce(function(a:number, b:number)
        {
          return a + b;
        });
  
        this.balanceFee = this.selectedRows.map((a:any) => +a['balance']).reduce(function(a:number, b:number)
        {
          return a + b;
        });
  
        this.totalSumFee  = (this.totalFee + this.lateFee + this.balanceFee) - this.adjustmentAmount;
        
      }
      else{      
        this.totalFee = 0;
        this.lateFee = 0;
        this.balanceFee = 0;
        this.totalSumFee = 0;
        this.adjustmentAmount = 0;
      }
    }



  
  
  
    onToggleFilter(callfrom:boolean){
      if(callfrom)
              this.status = !this.status;  
            else
            this.status = false;

    }
    
    
    ontogglebtn(){
      this.toggle =!this.toggle;
      this.userService.changeToggleClass(this.toggle);
    }

    delFilter(event:number)
    {
      if(event === 1){
        this.showSelectedFilters.session = '';
        this.showSelectedFilters.class = '';
        this.showSelectedFilters.section = '';
        this.showSelectedFilters.student = '';
        this.selectedItemssessiondrp = [];
      this.OnItemDeSelectSession('');
      }
      else if(event === 2){
       
        this.showSelectedFilters.class = '';
        this.showSelectedFilters.section = '';
        this.showSelectedFilters.student = '';
        this.selectedItemsclassdrp = [];
        this.OnItemDeSelectClass('');
      }
      else if(event === 3){
        this.showSelectedFilters.section = '';
        this.showSelectedFilters.student = '';
        this.selectedItemssectiondrp = [];
        this.OnItemDeSelectSection('')
      }
      else if(event === 4){
        this.showSelectedFilters.student = '';
        this.selectedItemsstudentdrp = [];
       this.OnItemDeSelectStudent('');
       
        
      }
    }

}
