import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { UserService } from '../../../shared/user.service';
import { HttpErrorResponse } from '../../../../../node_modules/@angular/common/http';
import 'jquery';
declare let jQuery: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  PMessage: string="";
  CPMessage: string="";
  tokenId: string="";
  result: any;
  AlertMessgae: string="";
  display = "none";
  flag: boolean = false;
  cpassdisabled: string="";
  btndisabled: string="";
  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService, ) {
    this.route.params.subscribe((params:any) => {
      this.tokenId = params.TokeId;
      console.log('TokeId :' + this.tokenId);
    });
  }
  // constructor() { }

  ngOnInit() {
    this.checkToken();
  }
  checkToken() {


    this.userService.ResetPasswordtokencheck(this.tokenId).subscribe((datacom: any) => {
      
      if (datacom.table.length == 0) {
        this.router.navigate(['/login']);
      }
      else {

      }


    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }
  onKey(value: string) {
    var pswd = value;
    this.checkStrength(pswd);
    //validate the length
    if (pswd.length < 8) {
      jQuery('#length').removeClass('valid').addClass('invalid');
    } else {
      jQuery('#length').removeClass('invalid').addClass('valid');
    }

    //validate letter
    if (pswd.match(/[A-z]/)) {
      jQuery('#letter').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#letter').removeClass('valid').addClass('invalid');
    }

    //validate capital letter
    if (pswd.match(/[A-Z]/)) {
      jQuery('#capital').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#capital').removeClass('valid').addClass('invalid');
    }

    //validate number
    if (pswd.match(/\d/)) {
      jQuery('#number').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#number').removeClass('valid').addClass('invalid');
    }

    //validate space
    if (pswd.match(/[^a-zA-Z0-9\-\/]/)) {
      jQuery('#space').removeClass('invalid').addClass('valid');
    } else {
      jQuery('#space').removeClass('valid').addClass('invalid');
    }
  }
  onFocus() {
    jQuery('#pswd_info').show();
  }
  onblur() {
    jQuery('#pswd_info').hide();
  }
  checkStrength(password: string) {

    var strength = 0
    if (password.length == 0) {
      jQuery('#result').removeClass('short')
      jQuery('#result').removeClass('weak')
      jQuery('#result').removeClass('good')
      jQuery('#result').removeClass('strong')
      return this.PMessage = '';
      // jQuery('#conpaw').attr("disabled", "disabled");
    }
    else {
      // jQuery('#conpaw').removeAttr("disabled");
      if (password.length < 8) {
        jQuery('#result').removeClass()
        jQuery('#result').addClass('short')
        return this.PMessage = 'Too short'
      }
      if (password.length > 7) strength += 1
      // If password contains both lower and uppercase characters, increase strength value.
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1
      // If it has numbers and characters, increase strength value.
      if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1
      // If it has one special character, increase strength value.
      if (password.match(/([!,%,&,@,#,jQuery,^,*,?,_,~])/)) strength += 1
      // If it has two special characters, increase strength value.
      if (password.match(/(.*[!,%,&,@,#,jQuery,^,*,?,_,~].*[!,%,&,@,#,jQuery,^,*,?,_,~])/)) strength += 1
      // Calculated strength value, we can return messages
      // If value is less than 2
      if (strength < 2) {
        jQuery('#result').removeClass()
        jQuery('#result').addClass('weak')
        return this.PMessage = 'Weak'
      } else if (strength == 2) {
        jQuery('#result').removeClass()
        jQuery('#result').addClass('good')
        return this.PMessage = 'Good'
      } else {
        jQuery('#result').removeClass()
        jQuery('#result').addClass('strong')
        return this.PMessage = 'Strong'
      }
    }
  }

  confirmPassword(Pass: string, Cpass:string) {
    if (Pass != Cpass) {
      jQuery('#Cresult').removeClass()
      jQuery('#Cresult').addClass('short')
      return this.CPMessage = 'Password and Confirm Password should match.'
    }
    else {
      jQuery('#Cresult').removeClass('short');
      return this.CPMessage = '';
    }
  }
  onResetPass(Pass:string, Cpass:string) {
    if (Pass == Cpass) {
      this.userService.ResetPassword(Pass, this.tokenId).subscribe((datacom: any) => {
        // this.result = datacom.table1[0].result.split("-");
        let resultarray  = JSON.parse(datacom.table1[0].result);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
        if (resultarray['successFlag'] == "success") {
          this.AlertMessgae = resultarray['resultMessage'];
          // this.router.navigate(['']); 
        }
        else if (resultarray['successFlag'] == "error") {
          this.AlertMessgae = "Password not Changed!";
        }
        this.flag = true;
        this.openModal();
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        });
    }
    else {
      this.openModal();
      this.flag = false;
      this.AlertMessgae = "Password and Confirm Password should match.";
    }
  }

  openModal() {

    jQuery('#AlertModal').show();
    this.display = 'block';
  }
  closeModal() {
    jQuery('#AlertModal').hide();
    this.display = 'none';
    if (this.flag == true)
      this.router.navigate(['']);
  }
}
