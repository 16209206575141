import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Iheader } from '../../allUsedModule';
import { HeaderPageModelComponent } from './header-page-model.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderPageModelService {

  constructor(private activeModel :NgbModal) { }

  public HeaderService(
    headerdata:Iheader,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(HeaderPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.headerdata = headerdata;
    console.log();
    return modalRef.result;
  }
}
