<div class="container-fluid">
  <div class="row justify-content-center">
    <!-- <span>Mobile device <span *ngIf="!mobileView">not</span> detected.</span> -->
    <div class=" p-0" *ngIf="!mobileView">
      <!-- Card Header - Dropdown -->
      <!-- <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold">Leave Records</h6>
  
              </div> -->
      <!-- Card Body -->
      <div class="card-body">


        <div class="row justify-content-md-center tableDiv">

          <div class="col-12">


            <div class="table-responsive main-table-wrappr" [ngClass]="isDateFilter == true ? 'active':''">

              <table class="table row-border hover w-100 ">
                <thead>
                  <tr>
                    <th resizable>S.No.</th>
                    <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">

                      <th resizable scope="headerDetailData">
                        {{headerDetailData['displayName']}}
                      </th>

                    </ng-container>
                    <th resizable class="sorting-none">
                      Action</th>
                  </tr>

                </thead>

                <tbody id="EditTableBody">
                  <ng-container *ngIf="DetailtableRowData.length > 0; else elseEmptyRow">
                    <!-- <ng-container *ngIf="DetailtableRowData?.length >0; else elsedetailTableEmpty"> -->
                    <tr *ngFor="let rowDetailData of DetailtableRowData ; let rowi=index;">
                      <td>{{((currentPage-1) * pageSize )+ (rowi + 1)}}</td>



                      <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">
                        <td *ngIf="!headerDetailData['isImagePreviewEnabled'] &&  !headerDetailData['isActionBtn']">
                          {{ReplaceNullDataTable(rowDetailData[headerDetailData["colName"]])}}
                        </td>
                        <td style="width: 100px;"
                          *ngIf="headerDetailData['isImagePreviewEnabled'] &&  !headerDetailData['isActionBtn']">
                          <img style="width: 100%;"
                            [src]="ReplaceNullDataTable(rowDetailData[headerDetailData['colName']])" alt="">
                        </td>



                        <td *ngIf="!headerDetailData['isImagePreviewEnabled'] &&  headerDetailData['isActionBtn']">
                          <a href="javascript:void(0)" (click)="OnCustomActionClickDetailView(ReplaceNullDataTable(rowDetailData[headerDetailData['colName']]))"
                            ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left"
                            tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-eye'></i>
                          </a>

                          <a href="javascript:void(0)" (click)="OnCustomActionClickDetailEdit(ReplaceNullDataTable(rowDetailData[headerDetailData['colName']]))"
                            ngbTooltip="Edit Record - " container="wrapper" placement="left" tooltipClass="tooltipBlue"
                            ngbTooltip="Tooltip on left" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                        </td>
                      </ng-container>

                      <td>
                        <a href="javascript:void(0)" (click)="OnCustomActionClickDetail($event, rowDetailData)"
                          ngbTooltip="Edit Record - " container="wrapper" placement="left" tooltipClass="tooltipBlue"
                          ngbTooltip="Tooltip on left" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #elseEmptyRow>
                    <ng-container>
                      <tr>
                        <td [attr.colspan]="tableCols.length + 2">No Data Found!</td>
                      </tr>
                    </ng-container>
                  </ng-template>

                </tbody>
              </table>
            </div>


          </div>
        </div>

        <div class="table-footer">
          <div class="table-footer-list">
            <div class="footer-selct">
              <select class="custom-select form-control" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="pageSizeChange();">
                <option [ngValue]="5">5 items</option>
                <option [ngValue]="10">10 items</option>
                <option [ngValue]="25">25 items</option>
                <option [ngValue]="50">50 items</option>
                <option [ngValue]="100">100 items</option>
                <option [ngValue]="100">500 items</option>
              </select>
              <!-- <paging-controls [isItemsPerPage]="true"></paging-controls> -->
              <pre><span class="float-md-left">Total Records: {{ countTotalRecords() }} / {{ totalRecords }}</span></pre>

            </div>
            <div class="footer-pagintion">
              <div class="page-item-txt">
                <span class="float-md-left">Page: {{ currentPage }} /
                  {{ dataPagination.pageCount }}</span>
              </div>
              <ngb-pagination class="d-flex justify-content-center mt-2" #dataPagination [collectionSize]="totalRecords"
                [(page)]="currentPage" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                (pageChange)="pageChange($event)"></ngb-pagination>
              <!-- <pagination-controls #dataPagination (pageChange)="pageChange($event)" [isItemsPerPage]="false" class="my-pagination"></pagination-controls> -->
              <!-- <paging-controls (pageChange)="p = $event" [isItemsPerPage]="false"></paging-controls> -->
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>