import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryPageModelComponent } from './gallery-page-model.component';
import { Igallery } from '../../allUsedModule';

@Injectable({
  providedIn: 'root'
})
export class GalleryPageModelService {

  constructor(private activeModel :NgbModal) { }

  public galleryService(
    gallerydata:Igallery,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(GalleryPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.gallerydata = gallerydata;
    console.log();
    return modalRef.result;
  }
}
