<div class="">
    <div class="closebtn">
        <div class="header-popup">
            <div class="head_text">about Us</div>
            <button type="button" class="btn close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                    aria-hidden="true">&times;</span></button>
        </div>
    </div>
    <div class="col-md-4">
        <div class="ImageShowBox">
            <label class="ImageShow">
                <div class="img_box">
                    <img [src]="aboutImg.imgurl" alt="Image Preview">
                </div>
                <div class="hoverImg">
                    <div class="hover_content">
                        <img style="width: 30px; display: block;" class="hoverImgIcon"
                            src="assets\img\Mask group1.png" alt="Upload Icon">
                        <span class="text-light">Upload</span>
                    </div>
                </div>
                <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
            </label>
        </div>
    </div>

    <div class="carouselbtn">
        <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
        <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
            (click)="OnUpdateAbotModal()">Update</button>
    </div>
</div>
