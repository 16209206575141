import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FooerPageModelComponent } from './fooer-page-model.component';

@Injectable({
  providedIn: 'root'
})
export class FooerPageModelService {

  constructor(private activeModel :NgbModal) { }

  public FooterService(
    footerlink:any,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(FooerPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.footerlink = footerlink;
    console.log();
    return modalRef.result;
  }
}
