import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-generic-child-drop-down',
  templateUrl: './generic-child-drop-down.component.html',
  styleUrls: ['./generic-child-drop-down.component.css']
})
export class GenericChildDropDownComponent implements OnInit {

  @Input() controlId : string ="";
  @Input() controlName : string ="";
  @Input() drpDataSet : any =[];
  @Input() drpDataSelected : any =[];
  @Input() drpSetting : any ={};
  @Input() formControl : string ="";

  constructor() { }

  ngOnInit(): void {
  }

}
