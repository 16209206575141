// captcha.component.ts

import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
  
})
export class CaptchaComponent {
  @Input() isShowErrorMsg: boolean = true;
  @Input() isShowValidateMsg: boolean = false;
  isCaptchaVerified : boolean = false;
  @Input() set pageReset(target: boolean){
    if(target)
    {
      this.refreshCaptcha();
    }
  }
  captchaImage!: string;
  userInput: string = '';
  verificationResult?: string;
  captchaText!: string;
  @Output() captchVerifictionEvent = new EventEmitter<boolean>();
  

  constructor() {
    this.refreshCaptcha();
  }

  refreshCaptcha() {
    const captchaLength = 6; // Adjust the length as needed
    this.captchaText = this.generateCaptcha(captchaLength);
    this.captchaImage = this.generateCaptchaImage(this.captchaText);
    this.userInput = ''; // Clear previous input
    this.verificationResult = undefined;
  }

  generateCaptcha(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(randomIndex);
    }

    return captcha;
  }

  verifyCaptcha() {
 if(this.userInput != undefined && this.userInput != null && this.userInput !="" && this.userInput.length ==6){
    if (this.userInput.toLowerCase() === this.captchaText.toLowerCase()) {   
      this.isCaptchaVerified = true;  
      if(this.isShowValidateMsg)
      this.verificationResult = 'Captcha verified successfully!';
      else
      this.verificationResult = undefined;
    } else {
      this.isCaptchaVerified = false;  
      if(this.isShowErrorMsg)
      this.verificationResult = 'Incorrect captcha. Please try again.';
      else
      this.verificationResult = undefined;
    }
    
  }
  else
  {
    this.isCaptchaVerified = false; 
  }
  this.captchVerifictionEvent.emit(this.isCaptchaVerified);
  }

  generateCaptchaImage(captchaText: string): string {
    // You can use a server-side library to generate an image with the text.
    // For simplicity, we'll use a data URL for an image with the text.

    const canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    canvas.width = 150;
    canvas.height = 50;

    // Draw the text on the canvas
    if(ctx!=null){
    // ctx.font = '20px Arial';
    // ctx.fillStyle = '#000';
    
    ctx.font = "30px Comic Sans MS ";
    //change the color to yellow
    ctx.fillStyle = "#1a73e8";
    //draw the background using yellow color
    ctx?.fillRect(0, 0, 150, 50);
    
    // Set line properties
    ctx.lineWidth = 4;
    ctx.strokeStyle = '#fff';
    // Draw horizontal strike lines
    const numLines = 2; // Adjust the number of lines as needed

    for (let i = 0; i < numLines; i++) {
      const lineY = (canvas.height - canvas.height) / 2 + (i + 1) * (canvas.height / (numLines + 1));      
      ctx.beginPath();
      ctx.moveTo(0, lineY);
      ctx.lineTo((canvas.width+20 + canvas.height) / 2, lineY+5);
      ctx.stroke();
    }
    // fill text
    ctx.fillStyle = "white";
    
    
    ctx.fillText(captchaText, 10, 30);
    }

    // Convert the canvas to a data URL
    return canvas.toDataURL();
  }
}
