<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    
    <!-- Tab forUpdate All About's Detail -->
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Update About Us Details</a>
        <ng-template ngbNavContent>
            <div [formGroup]="AboutUpdateForm">
                <div>
                    <label for="">Home Page  Title</label>
                    <div class="row align-items-center">
                        <div class="col-md-3">
                            <div class="ImageShowBox">
                                <label class="ImageShow">
                                    <div class="img_box">
                                        <img [src]="AboutUsData?.bgimg" alt="Image Preview">
                                    </div>
                                    <div class="hoverImg">
                                        <div class="hover_content">
                                            <img style="width: 30px; display: block;" class="hoverImgIcon"
                                                src="assets\img\Mask group1.png" alt="Upload Icon">
                                            <span class="text-light">Upload</span>
                                        </div>
                                    </div>
                                    <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                                </label>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="ImageShowBox">
                                <label class="ImageShow">
                                    <div class="img_box">
                                        <img [src]="AboutUsData?.logourl" alt="Image Preview">
                                    </div>
                                    <div class="hoverImg">
                                        <div class="hover_content">
                                            <img style="width: 30px; display: block;" class="hoverImgIcon"
                                                src="assets\img\Mask group1.png" alt="Upload Icon">
                                            <span class="text-light">Upload</span>
                                        </div>
                                    </div>
                                    <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" >
                    <div class="row">
                        <div class="col-md-6">
                            <label for="">Home Page Title</label>
                            <input type="text" formControlName="HomePageTitle"  class="form-control">
                        </div>
                        <div class="col-md-6">
                            <label for="">About Title</label>
                            <input type="text" class="form-control" formControlName="aboutTitle">
                        </div>
                    </div>
                    <div>
                        <div class="mt-2">
                            <label for="">About Details</label>
                            <textarea type="text" rows="5" formControlName="aboutDetails"  class="form-control"></textarea>
                            <textarea type="text" rows="5" formControlName="aboutDetails1"  class="form-control mt-3"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mt-4 align-items-center">
                    <div class="col-md-4">
                        <div class="ImageShowBox">
                            <label class="ImageShow">
                                <div class="img_box">
                                    <img [src]="AboutUsData?.aboutdetailImg" alt="Image Preview">
                                </div>
                                <div class="hoverImg">
                                    <div class="hover_content">
                                        <img style="width: 30px; display: block;" class="hoverImgIcon"
                                            src="assets\img\Mask group1.png" alt="Upload Icon">
                                        <span class="text-light">Upload</span>
                                    </div>
                                </div>
                                <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                            </label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div>
                            <textarea name="" id="" formControlName="subtitle" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 align-items-center">
                    <div class="col-md-4">
                        <div class="ImageShowBox">
                            <label class="ImageShow">
                                <div class="img_box">
                                    <img [src]="AboutUsData?.aboutdetailImg2" alt="Image Preview">
                                </div>
                                <div class="hoverImg">
                                    <div class="hover_content">
                                        <img style="width: 30px; display: block;" class="hoverImgIcon"
                                            src="assets\img\Mask group1.png" alt="Upload Icon">
                                        <span class="text-light">Upload</span>
                                    </div>
                                </div>
                                <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                            </label>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div>
                            <textarea name="" id="" formControlName="subtitle1" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="Submitbtn-content mt-3  ">
                <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                    (click)="OnClickSubmit()">Update</button>
            </div>
        </ng-template>
    </li>


    <!-- Tab for Add New Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">Add New Details</a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-md-4">
                    <div class="ImageShowBox">
                        <label class="ImageShow">
                            <div class="img_box">
                                <img [src]="AboutUsData?.bgimg" alt="Image Preview">
                            </div>
                            <div class="hoverImg">
                                <div class="hover_content">
                                    <img style="width: 30px; display: block;" class="hoverImgIcon"
                                        src="assets\img\Mask group1.png" alt="Upload Icon">
                                    <span class="text-light">Upload</span>
                                </div>
                            </div>
                            <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                        </label>
                    </div>
                </div>

                <div class="Submitbtn-content mt-3  ">
                    <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                    <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickAddNew()">Update</button>
                </div>
            </div>
        </ng-template>
    </li>    

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="3">
        <a ngbNavLink class="nav-link">View Details</a>
        <ng-template ngbNavContent>
            <!-- <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class=" p-0">
                        <div class="table-responsive main-table-wrappr">
                            <table class="table row-border hover w-100 ">
                                <thead>
                                    <tr>
        
                                        <th scope="col" resizable>S.No.</th>
                                        <th scope="col" >Images</th>
                                        <th scope="col">Action</th>
                                    </tr>
        
                                </thead>
        
                                <tbody id="EditTableBody">
        
                                    <tr *ngFor="let data of satisfiedSclList ; let sr = index">
                                        <td>{{sr+1}}</td>
                                        
                                        <td>
                                            <img [src]="data.imgurl" style="width: 15%;" alt="">                                           
                                        </td>
        
                                        <td style="width: 100px;">
                                            <a  href="javascript:void(0)" (click)="onClickEditForm(data)" ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                            <a  href="javascript:void(0)" (click)="deleteKey(sr)" ngbTooltip="Delete Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>
                                        </td>
                                    </tr>
        
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  -->


            <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="satisfiedSclList" (customActionDetail)="onClickEditForm($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>


</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>