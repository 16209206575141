/*
  Author      - Naveen Tehlan
  Description - 
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */

import { AbstractControl, ValidatorFn, AsyncValidator, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { Commonfunction } from './commonutility'
import { UserService } from './user.service';
import { of, Observable } from 'rxjs';
import { Customvalidation } from './Validation';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'jquery';
declare var jQuery: any;

@Injectable({ providedIn: 'root' })

export class asyncCustumValidation implements AsyncValidator {

  constructor(private userService: UserService,private Customvalidation: Customvalidation, public commonfunction: Commonfunction) {

  }
private paramValue: string="";
private errorStringNameIn: string="";
controlTypeProp:string="txt";
  validate(control: AbstractControl) {
    
    let msg ="";
    let ControlValue: string | null | undefined = "";
    let finalValue = this.controlTypeProp.toLowerCase() == "dropdown" ? control.value[0] : this.controlTypeProp.toLowerCase() == "date" || this.controlTypeProp.toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(control.value) : control.value;
     if(this.controlTypeProp.toLowerCase() == "dropdown"){      
       ControlValue = control != undefined ? finalValue["id"]?.toString(): "";
     } 
     else if(this.controlTypeProp.toLowerCase() == "date" || this.controlTypeProp.toLowerCase() == "datetime"){ 
      ControlValue = "Date_"+ finalValue ;
     }   
     else{
       ControlValue = finalValue;
     }
    if (ControlValue != "" && ControlValue != null) {
      console.log(this.paramValue+ControlValue);
        return this.userService
        .getDataAlreadyExists(this.paramValue+ControlValue)
        .pipe(
        map((res: any) => {
          
          console.log(res);
            return res?.table?.length <= 0 ? null  : { GenericError: {errorMsg:res?.table[0][this.errorStringNameIn]}};
          }
         ) );
     
     
    }
    else {
      return of(null) ;
    }
  }
//SpName -- Proc Name
//spParam -- paramter name for provided SP may be single if not then seperated by '!' but filter column name which used control value must be last.
//extraColumnVlaue -- this paramter id special  when provided column values more than 1 and it always less 1 from column array.
// errorStringName -- name of the result column that produced by provided SP.
//compareString -- provided any value that we want pass without access any API Call or in edit case to check name is previous or not
// example :- uspAppGenericCheckWorkFlowName%workFlowName%msg%%%
  validateValueInDBExists_Check(controlType:string,SpName: string, spParam: string, errorStringName:string,compareString: string,extraColumnVlaue: string)  {
    return(control: AbstractControl): Observable< { [key: string]: { errorMsg: string } } | null> | Promise< { [key: string]: { errorMsg: string } } | null> => {
      
      let msg =""; 
      this.controlTypeProp = controlType;
      let ControlValue: string  = "";
      let compareStringAfter = controlType.toLowerCase() == "date" || controlType.toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(compareString) : compareString;
      let finalValue = controlType.toLowerCase() == "dropdown" ? control.value[0] : controlType.toLowerCase() == "date" || controlType.toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(control.value) : control.value;
      if(controlType.toLowerCase() == "dropdown"){     
       ControlValue = control != undefined ? finalValue["id"]?.toString(): "";
     }    
     else{
       ControlValue = finalValue;
     }
      let paramArray = [];
      let paramArrayValues:any =[];
      if (ControlValue != "" && ControlValue != null) {
        if(compareStringAfter?.toLowerCase() == ControlValue?.toString()?.toLowerCase()){
          return of(null);
        }
        else{
          if (spParam.indexOf("!") > -1) {
            if (extraColumnVlaue.indexOf("!") > -1) {
              paramArrayValues = extraColumnVlaue.split('!');
            }
            paramArray = spParam.split('!');
            let dtDayCU = paramArray[0];
            let spParams = '';
            paramArray.forEach(function(columnName,Index) {
              if(paramArray.length == (Index+1))
              {
                spParams = spParams + columnName +"="
              }
              else
              {
              spParams = spParams + columnName +"="+ (paramArrayValues.length>0 ? paramArrayValues[Index]:extraColumnVlaue) + "|";
              }
            }); 
            this.paramValue = SpName + "|"+spParams ;
            console.log( this.paramValue);
          }
          else
          {
            this.paramValue = SpName + "|"+spParam +"=";
          }
          
          this.errorStringNameIn = errorStringName;         
          return  this.validate(control);
        }
      }
      else {
        return of(null);
      }
    }
  }


  //SpName -- Proc Name
  // tbName -- table name which we geeting from form not from proc parms.
//spParam -- paramter name for provided SP may be single if not then seperated by '!' but filter column name which used control value must be last.
//extraColumnVlaue -- this paramter id special  when provided column values more than 1 and it always less 1 from column array.
// errorStringName -- name of the result column that produced by provided SP.
// - -- provided any value that we want pass without access any API Call or in edit case to check name is previous or not
//example single form:- uspAppGeneric_custum_Ischool_CheckDuplicateValue%extraColumnName!extraColumnValue!itemValue%msg%%sessionId!masterFormGroup?.value['DrpName_sessionId']%
// Example multi form  form alias name add in control name and used in to get form data :-uspAppGeneric_custum_Ischool_CheckDuplicateValue%extraColumnName!extraColumnValue!itemValue%msg%%sessionId__classId__feeCatId!masterFormGroup.get('FeeStructure')?.value['DrpName_sessionId_FeeStructure']__masterFormGroup.get('FeeStructure')?.value['DrpName_classId_FeeStructure']__masterFormGroup.get('FeeStructure')?.value['DrpName_feeCatId_FeeStructure']%
validateValueInGenericDBExists_Check(controlType:string, callfrom: string, matchColumn: string ,tbName: string,SpName: string, spParam: string, errorStringName:string,compareString: string,extraColumnVlaue: string)  {
  return(control: AbstractControl): Observable< { [key: string]: { errorMsg: string } } | null> | Promise< { [key: string]: { errorMsg: string } } | null> => {
    
   let ControlValue: string = "";
   let finalMatchColumn = controlType.toLowerCase() == "date" || controlType.toLowerCase() == "datetime" ? "Date_"+ matchColumn : matchColumn;
   this.controlTypeProp = controlType;
   let compareStringAfter = controlType.toLowerCase() == "date" || controlType.toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(compareString) : compareString;
   let finalValue = controlType.toLowerCase() == "dropdown" ? control.value[0] : controlType.toLowerCase() == "date" || controlType.toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(control.value) : control.value;
    if(controlType.toLowerCase() == "dropdown"){      
      ControlValue = control != undefined ? finalValue["id"]?.toString(): "";
    }    
    else{
      ControlValue = finalValue;
    }
   let that = this;
    let msg =""; 
    let paramArray = [];
    let paramArrayValues:any =[];
    if (ControlValue != "" && ControlValue != null) {
      if(compareStringAfter?.toLowerCase() == ControlValue?.toString()?.toLowerCase()){
        return of(null);
      }
      else{
        if (spParam.indexOf("!") > -1) {
          if (extraColumnVlaue.indexOf("!") > -1) {
            paramArrayValues = extraColumnVlaue.split('!');
          }
          paramArray = spParam.split('!');
          let dtDayCU = paramArray[0];
          let spParams = '';
          paramArray.forEach(function(columnName,Index) {
            if(paramArray.length == (Index+1))
            {
              spParams = spParams + columnName +"="
            }
            else
            {
            spParams = spParams + columnName +"="+ (paramArrayValues.length>0 ? that.getValueJoin(paramArrayValues[Index],callfrom):that.getValueJoin(extraColumnVlaue,callfrom)) + "|";
            }
          }); 
         
          this.paramValue = SpName + "|tblName="+ tbName + "|matchColumnName="+ finalMatchColumn + "|"+spParams ;
          console.log( this.paramValue);
        }
        else
        {
          this.paramValue = SpName + "|tblName="+ tbName + "|matchColumnName="+ finalMatchColumn + "|"+spParam +"=";
        }
        
        this.errorStringNameIn = errorStringName;         
        return  this.validate(control);
      }
    }
    else {
      return of(null);
    }
  }
}

getValueJoin(value:string,callfrom:string){
  let val: string | null|undefined ="";
  let idType: string[] = value.split('__');
  idType.forEach((xvalue:any)=>{
    val += this.getExtraColumnValue(xvalue,callfrom) +"!";
  });
  return val.substring(0,val.length-1);
}
getExtraColumnValue(id: string,callfrom:string){

  let idType: string[] = id.split('?.');
  let value: string | null|undefined ="";
  let controlVal: any;
  let controlType: string[]  =id.split('$$')
  if(idType.length > 1){
   this.commonfunction.getFormLatestValue(callfrom).pipe().subscribe((masterFormGroup:FormGroup)=>{
    controlVal = eval(controlType[0]);
   });
    if(controlVal != null && controlVal != "" && controlVal != undefined){
  
   let finalValue = controlType[1].toLowerCase() == "drop" ? controlVal[0] : controlType[1].toLowerCase() == "date" || controlType[1].toLowerCase() == "datetime" ? this.commonfunction.ConvertDateFormatYYYYMMDD(controlVal) : controlVal;
    if(controlType[1].toLowerCase() == "drop"){      
     value = controlVal != undefined ? finalValue["id"]?.toString(): "";
    } 
    else if(controlType[1].toLowerCase() == "date" || controlType[1].toLowerCase() == "datetime"){      
      value = "Date_"+ finalValue ;
     }   
    else{
      value = finalValue;
    }
  }
  else
  value = "";

  }
  else{
    value = controlType[1].toLowerCase() == "date" || controlType[1].toLowerCase() == "datetime" ? "Date_"+ controlType[0] : controlType[0];
    
  }
  return value;
}
}

