<div class="login1">
  <header>
    <div class="login-header">
      <div class="home-back"><a routerLink="/"><img src="assets/school-img/left-home-arrow.png" alt="">
          Home</a></div>
      <div class="login-logo"><a routerLink="/"><img src="assets/images/newLogo_2024.svg" alt=""></a></div>
    </div>
  </header>


  <div class="login-container" >

    <div class="login" id="signIn">
      <div class="left-login-side">
        <img src="assets/school-img/image 45.png" alt="">
      </div>

      <div class="right-login-side">
        <div class="form-div">
          <nav><a routerLink="/"><img src="assets/school-img/left-home-arrow.png" alt=""> Home</a></nav>
          <div class="heading-div">
            <h1>Welcome</h1>
            <p>Generic Web Component Tool.</p>
          </div>
          <div *ngIf="isLoginError">
            <span class="text-danger" [class]="invalidMessage"> {{errorMessgae}}</span>
          </div>
          <div class="login-logo-mob"><a routerLink="/"><img src="assets/school-img/Group 78.png" alt=""></a></div>
          <form #loginForm="ngForm" *ngIf="!forgetPassword" [formGroup]="loginReactiveForm">
            <div class="username">
              <input type="text" formControlName="userName" id="username" required>
              <label for="username">Username</label>

            </div>
            <div class="password">
              <input [type]="showpwd? 'text':'password'" formControlName="password" id="password" required>
              <label for="password">Password</label>
              <span class="nb-checkmark-eye" *ngIf="showpwd" (click)="passwordToggel()"></span>
              <span class="nb-checkmark-eyetwo" *ngIf="!showpwd" (click)="passwordToggel()"></span>


            </div>
            <div class="redm-forget">
              <div class="remind" *ngIf="isCaptcha === 1 || isCaptcha === 2">
                <input type="checkbox" formControlName="remember" id="remd">
                <label for="remd">Remember Me</label>
              </div>
              <div class="forgot-password">
                <a class="" href="javascript: void(0);" (click)="onforgotPassword()">Forgot Password?</a>
              </div>
            </div>


            <div class="captcha-div captcha-section d-flex justify-content-between align-items-center mt-3"
              *ngIf="isCaptcha === 1 || isCaptcha === 3">
              <app-captcha (captchVerifictionEvent)="onCaptchVerification($event)" class="w-100" [isShowErrorMsg]="true"
                [isShowValidateMsg]="false" [pageReset]="isPageReset"></app-captcha>
            </div>


            <div class="submit-btn">
              <button type="button" (click)="OnSubmit()" [disabled]="isFormDisabled()">Login</button>

            </div>
          </form>


          <form #ResetEmailForm="ngForm" *ngIf="forgetPassword" [formGroup]="forgetReactiveForm">

            <div class="username">
              <input type="text" formControlName="email" id="forgotemail" required
                [ngClass]="{'is-invalid': (forgetReactiveForm.get('email')?.touched || forgetReactiveForm.get('email')?.dirty) && !forgetReactiveForm.get('email')?.valid,'is-valid': (forgetReactiveForm.get('email')?.touched|| forgetReactiveForm.get('email')?.dirty) && forgetReactiveForm.get('email')?.valid }">
              <label for="forgotemail">Email</label>
              <span class="invalid-feedback mt-1">
                <h6>Invalid Email!</h6>
              </span>
            </div>

            <div class="captcha-div captcha-section d-flex justify-content-between align-items-center mt-3">
              <app-captcha (captchVerifictionEvent)="onCaptchVerificationForgetPassword($event)" class="w-100"
                [isShowErrorMsg]="true" [isShowValidateMsg]="false" [pageReset]="isPageResetForgetPassword">
              </app-captcha>
            </div>


            <div class="submit-btn">

              <span class="text-danger" [class]="invalidMessage"> {{AlertMessgae}}</span>
              <button type="button" (click)="onSendMail()" [disabled]="isForgetPasswordFormDisabled()">Submit</button>

            </div>

            <div class="forgot-password">
              <i class="fa fa-arrow-left" style="padding-right: 5px;"></i>
              <a class="" href="javascript: void(0);" (click)="onforgotPassword()"> Sign-In</a>
            </div>


          </form>
        </div>
      </div>
    </div>

  </div>

  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade"></ngx-spinner>
</div>