import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Commonfunction } from '../../shared/commonutility';

//import { RegistrationValidatorComponent  } from '../shared/register.validator';

@Component({
  selector: 'app-manageprofile',
  templateUrl: './manageprofile.component.html',
  styleUrls: ['./manageprofile.component.css']
})
export class ManageprofileComponent implements OnInit {
  //readonly rootUrl ='D:\\New Development\\Angular6Project\\DynamicFormWebApi_Generic\\WebApi_Generic\\UploadedFiles\\ProfilePhoto\\Dwarka.jpg';
  imageUrl: string | null |undefined = "./assets/img/default-image.png";



  fileToUpload: File | null = null;
  userData: any;
  TokenExpire: any;
  TokenIssued: any;


  display = "none";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "modal NP_popup_success";
  AlertAnimationType: any = "smile";



  imgUrl: string = 'https://picsum.photos/200/300/?random';
  //imageToShow: any;
  //isImageLoading: boolean;

  profileFormGroup: FormGroup;
  submitted = false;
  param: string="";
  menulabel: string="";
  formlable: string="";
  constructor(private formBuilder: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService, private router: Router,
    private commonfunction: Commonfunction) {

    this.profileFormGroup = this.formBuilder.group({
      empEmail: [null, [Validators.required, Validators.email]],
      //empEmail: [null, [Validators.email]],
      phone: [null, [Validators.minLength(10)]]
    });

  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      //this.imageToShow = reader.result;
      this.imageUrl = reader?.result?.toString();
      console.log(this.imageUrl);

    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImageFromService(imgUrl:any) {
    this.userService.getImage(this.imgUrl).subscribe((data:any) => {
      this.createImageFromBlob(data);
      //this.isImageLoading = false;
    }, (error:any) => {
      //this.isImageLoading = false;
      console.log(error);
    });
  }

  ngOnInit() {
   
    // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
    let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
    $('#SeltableID').val(paramjs.formValue);
    $('#SeltableIDForm').val(paramjs.formName);
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.FetchProfile();
  }


  get f() { return this.profileFormGroup.controls; }

  FetchProfile() {
    this.userService.getUserData(this.commonfunction.getUserIdValuestring(), 'home').subscribe((datacom: any) => {
      this.userData = datacom.table[0];

      //this.getImageFromService(datacom.table[0]["photopath"]);

      this.imageUrl = "data:image/jpeg;base64," + datacom.table[0]["photopath"];
      // this.getImageFromService("https://picsum.photos/200/300/?image=1041");

      this.TokenIssued = sessionStorage.getItem("issued");
      this.TokenExpire = sessionStorage.getItem("expires");
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }
    );
  }

  OnCancelFile() {
    this.imageUrl = "data:image/jpeg;base64," + this.userData["photopath"];
  }

  handleFileInput(event:any) {
   let file: FileList = event.target.files;
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    if(this.fileToUpload)
     reader.readAsDataURL(this.fileToUpload);
  }



  OnSubmit(Image:any, name:any, email:any, phone:any) {
    this.submitted = true;

    if (this.profileFormGroup.invalid) {
      return;
    }
    // if(email.value=="")
    // {

    // }
    this.UpdateProfile(Image, name.value, email.value, phone.value, this.userData["userID"] + "_" + this.userData["result"]);
  }

  UpdateImage(ImageName:any, Image:any) {
    if(this.fileToUpload){
    this.userService.postProfileImage(ImageName, this.fileToUpload).subscribe(
      (data:any) => {
        //Caption.value = null;
        Image.value = null;

        //this.imageUrl = "/assets/img/default-image.png";
      }
    );
    }
  }

  UpdateProfile(Image:any, name:any, email:any, phone:any, imagename:any) {

    let stringToSplit = Image.value;
    let fileName = stringToSplit.split('\\');
    fileName = fileName[Number(fileName.length - 1)];
    this.userService.UpdateProfile(name, email, phone, fileName, this.commonfunction.getUserIdValuestring(), imagename).subscribe((datacom: any) => {
      this.openAlertModal();
      let resultarray  = JSON.parse(datacom.table1[0]["result"]);	
      // resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
      if (resultarray['successFlag']  == "success") {
        if (this.fileToUpload != null) {
          
          this.UpdateImage(this.userData["userID"] + "_" + this.userData["result"], Image);
          // this.FetchProfile();
        }
        this.AlertTitle = "Success!!";
        this.AlertMessage = resultarray['resultMessage'].toString();
        this.AlertClass = "NP_emoji-success-bg";
        this.AlertAnimationType = "smile";
        this.AlertType = "modal NP_popup_success";
      }
      else {
        this.AlertTitle = "Error!!";
        this.AlertMessage = resultarray['resultMessage'].toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "modal NP_popup_fail";

      }
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      });

  }


  openAlertModal() {

    $('#AlertModal').show();
    this.display = 'block';

  }

  onCloseAlertModal() {
    $('#AlertModal').hide();
    $('#AlertModalJs').hide();
    $(".AlertbackdropCustom").css({ display: "none" });
    this.display = 'none';
    this.router.navigate(['/Landing']);
  }

  _keyPressNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }


  _keyPressString(event: any) {
    const pattern = /^[a-zA-Z ]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }



}


















