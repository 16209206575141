import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';
import { AdmissionPageModelService } from '../../../allFormPopUpModel/admission-page-model/admission-page-model.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Itableth,IadmissionDeadline} from "../../../allUsedModule";

@Component({
  selector: 'app-admissiondeadline',
  templateUrl: './admissiondeadline.component.html',
  styleUrls: ['./admissiondeadline.component.css']
})
export class AdmissiondeadlineComponent implements OnInit {
  Addmissiondata:any;
  activeTabId:number=2;
  AddNewFrom !:FormGroup;
  obj={
    "title": "",
    "year": "",
    "monthly": "",
  };
  rowdata :any[]=[];

  tableHead : Itableth[] = [
    { "displayName": "Title", "colName": "title","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Year", "colName": "year","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Month", "colName": "monthly","isActionBtn":false,"isImagePreviewEnabled":false },
  ]

  constructor(public commonfunction: Commonfunction, private admissionService: AdmissionPageModelService) { }

  ngOnInit(): void {
    this.Addmissiondata = this.commonfunction.loadJson("~/../assets/Admission.json");
    this.rowdata = this.Addmissiondata?.Applicationdeadline?.ApplicationdeadlineData;

    this.AddNewFrom = new FormGroup({
      AdmissionDeadline: new FormControl(this.Addmissiondata.Applicationdeadline.Applicationdeadlineheading,[Validators.required]),
      title: new FormControl(''),
      year: new FormControl(''),
      monthly: new FormControl(''),
    })
  }

  OnClickSubmit(){
    this.obj.title = this.AddNewFrom.get("title")?.value;
    this.obj.year = this.AddNewFrom.get("year")?.value;
    this.obj.monthly = this.AddNewFrom.get("monthly")?.value;

    this.Addmissiondata?.Applicationdeadline?.ApplicationdeadlineData.push(this.obj)

    const titleControl = this.AddNewFrom.get("title");
    const yearControl = this.AddNewFrom.get("year");
    const monthlyControl = this.AddNewFrom.get("monthly");

    // Check if each control is defined and not empty, then reset it
    if (titleControl && titleControl.value && yearControl && yearControl.value && monthlyControl && monthlyControl.value) {
        titleControl.setValue(''); 
        yearControl.setValue(''); 
        monthlyControl.setValue(''); 
    }
  }

  onClickResetFrom() {
    const titleControl = this.AddNewFrom.get("title");
    const yearControl = this.AddNewFrom.get("year");
    const monthlyControl = this.AddNewFrom.get("monthly");

    // Check if each control is defined and not empty, then reset it
    if (titleControl && titleControl.value && yearControl && yearControl.value && monthlyControl && monthlyControl.value) {
        titleControl.setValue(''); 
        yearControl.setValue(''); 
        monthlyControl.setValue(''); 
    }
}






  receiveData(mesg:any){
    this.admissionService.AdmissionDeadlineService(mesg,true)
  }
}
