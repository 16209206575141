<div class="mainnav-profile">
    <div class="profile-wrap text-left">


        <div class="header-collapse-menu navbar-collapse" id="navbarsGeneric">
            <ul class="navbar-nav header-menu">

                <ng-container *ngFor="let data of MenuItems">
                    <ng-container *ngIf="data.menuflag === 'Y'; else elseif1">
                        <li class="nav-item">
                            <div><a class=""> {{data.menu}} <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                                <div class="np-dropdown-content scrollable-menu side_menu">
                                    <ng-container *ngIf="SubMenuItems.length > 0">
                                        <ng-container *ngFor="let subitem of SubMenuItems">
                                            <ng-container *ngIf="subitem.menu == data.menu ;">
                        <li>
                            <a class="">{{subitem.subMenu}} <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                            <ul>
                                <div class="np-dropdown-content scrollable-menu side_menu">
                                    <ng-container *ngFor="let subMenuItem of SubMenuItemssub">
                                        <ng-container
                                            *ngIf="subMenuItem.subMenu === subitem.subMenu &&  subMenuItem.menu === subitem.menu;">
                                            <li class="">

                                                <ng-container *ngIf="subMenuItem.callingPage === 'M' ; else elseif3">
                                                    <a routerLink=""
                                                        (click)="Action($event,data.menu, subitem.subMenu ,'GenericMasterForm',subMenuItem.activity,subMenuItem.formValue)"
                                                        class="">{{subMenuItem.activity}} </a>
                                                </ng-container>
                                                <ng-template #elseif3>
                                                    <ng-container
                                                        *ngIf="subMenuItem.callingPage === 'T' ; else elseif4">


                                                        <a routerLink=""
                                                            (click)="Action($event,data.menu, subitem.subMenu ,'GenericMultiForm',subMenuItem.activity,subMenuItem.formValue)"
                                                            class="">{{subMenuItem.activity}} </a>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #elseif4>
                                                    <ng-container
                                                        *ngIf="subMenuItem.callingPage === 'R' ; else elseif5">
                                                        <a routerLink=""
                                                            (click)="Action($event,data.menu , subitem.subMenu ,'GenericReportForm',subMenuItem.activity,subMenuItem.formValue)"
                                                            class=" ">{{subMenuItem.activity}}</a>

                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #elseif5>
                                                    <ng-container
                                                        *ngIf="subMenuItem.callingPage === 'L' ; else elseif6">
                                                        <a routerLink=""
                                                            (click)="Action($event,data.menu , subitem.subMenu ,'GenericListForm',subMenuItem.activity,subMenuItem.formValue)"
                                                            class=" ">{{subMenuItem.activity}}</a>

                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #elseif6>
                                                    <a routerLink=""
                                                        (click)="Action($event,data.menu, subitem.subMenu ,subMenuItem.formValue,subMenuItem.activity,subMenuItem.formValue)"
                                                        class="">{{subMenuItem.activity}} </a>
                                                </ng-template>

                                            </li>
                                        </ng-container>
                                    </ng-container>

                                </div>
                            </ul>
                        </li>
                    </ng-container>
                </ng-container>
                </ng-container>
                <ng-container *ngFor="let datasub of MenuItemssub">
                    <ng-container *ngIf="datasub.menu === data.menu ;">
                        <li class="">

                            <ng-container *ngIf="datasub.callingPage === 'M' ; else elseif3">
                                <a routerLink=""
                                    (click)="Action($event,data.menu ,'','GenericMasterForm',datasub.activity,datasub.formValue)"
                                    class="">{{datasub.activity}} </a>
                            </ng-container>
                            <ng-template #elseif3>
                                <ng-container *ngIf="datasub.callingPage === 'T' ; else elseif4">


                                    <a routerLink=""
                                        (click)="Action($event,data.menu,'' ,'GenericMultiForm',datasub.activity,datasub.formValue)"
                                        class="">{{datasub.activity}} </a>
                                </ng-container>
                            </ng-template>
                            <ng-template #elseif4>
                                <ng-container *ngIf="datasub.callingPage === 'R' ; else elseif5">
                                    <a routerLink=""
                                        (click)="Action($event,data.menu,'' ,'GenericReportForm',datasub.activity,datasub.formValue)"
                                        class=" ">{{datasub.activity}}</a>

                                </ng-container>
                            </ng-template>
                            <ng-template #elseif5>
                                <ng-container *ngIf="datasub.callingPage === 'L' ; else elseif6">
                                    <a routerLink=""
                                        (click)="Action($event,data.menu,'' ,'GenericListForm',datasub.activity,datasub.formValue)"
                                        class=" ">{{datasub.activity}}</a>

                                </ng-container>
                            </ng-template>
                            <ng-template #elseif6>
                                <a routerLink=""
                                    (click)="Action($event,data.menu,'' ,datasub.formValue,datasub.activity,datasub.formValue)"
                                    class="">{{datasub.activity}} </a>
                            </ng-template>
                        </li>
                    </ng-container>
                </ng-container>

                <!-- </div> -->
        </div>
    </div>
    </li>
    </ng-container>
    <ng-template #elseif1>
        <li class="">
            <ng-container *ngIf="data.callingPage === 'M'; else elseif2">
                <a routerLink="" (click)="Action($event,'','' ,'GenericMasterForm',data.menu,data.formValue)"
                    class="">{{data.menu}}</a>
            </ng-container>
            <ng-template #elseif2>
                <ng-container *ngIf="data.callingPage === 'T' ; else elseif4">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericMultiForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif4>
                <ng-container *ngIf="data.callingPage === 'R' ; else elseif5">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericReportForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif5>
                <ng-container *ngIf="data.callingPage === 'L' ; else elseif6">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericListForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif6>
                <a routerLink="" (click)="Action($event,'' ,'',data.formValue,data.menu,data.formValue)"
                    class="">{{data.menu}}</a>
            </ng-template>
        </li>
    </ng-template>
    </ng-container>




    </ul>


</div>
</div>
</div>