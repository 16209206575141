import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-carousel-banner',
  templateUrl: './carousel-banner.component.html',
  styleUrls: ['./carousel-banner.component.css']
})
export class CarouselBannerComponent implements OnInit {
  carsouselbannerdata: any;

  constructor(public commonfunction:Commonfunction) {
    const alldatafile = this.commonfunction.loadJson("~/../assets/carsouselbanner.json");
    
    this.carsouselbannerdata = alldatafile[0];
   }

  ngOnInit(): void {
  }

}
