import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdmissionPageModelComponent } from './admission-page-model.component';

@Injectable({
  providedIn: 'root'
})
export class AdmissionPageModelService {

  constructor(private activeModel :NgbModal) { }

  public AdmissionService(
    RequirementData:any,
    reqShowFlag:boolean,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(AdmissionPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.RequirementData = RequirementData;
    modalRef.componentInstance.reqShowFlag = reqShowFlag;
    // console.log(); 
    return modalRef.result;
  }

  public AdmissionDeadlineService(
    AdmissionDeadlineData:any,
    DeadlineShowFlag:boolean,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(AdmissionPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.AdmissionDeadlineData = AdmissionDeadlineData;
modalRef.componentInstance.DeadlineShowFlag = DeadlineShowFlag;
    modalRef.componentInstance.DeadlineShowFlag = DeadlineShowFlag;
    console.log();
    return modalRef.result;
  }
}
