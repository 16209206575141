<div class="inner-content p-3">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div id="BarChartFull" [style.width.%]="100" [style.height.px]="250"></div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-6">
      <div class="card mb-4 mb-lg-0">
        <div id="ThreepreviousMonth" [style.width.%]="100" [style.height.px]="300"></div>
        <div id="legend"></div>
      </div>
    </div>
    <div class="col-md-6" >
      <div class="card">
        <div id="TwopreviousMonth" [style.width.%]="100" [style.height.px]="300"></div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-6">
      <div class="card mb-4 mb-lg-0">
        <div id="previousMonth" [style.width.%]="100" [style.height.px]="300"></div>
        <div id="legend"></div>
      </div>
    </div>
    <div class="col-md-6" >
      <div class="card">
        <div id="currentMonth" [style.width.%]="100" [style.height.px]="300"></div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-6" >
      <div class="card mb-4 mb-lg-0">
        <div id="yesterdayAttendance" [style.width.%]="100" [style.height.px]="300"></div>
      </div>
    </div>
    <div class="col-md-6" >
      <div class="card">
        <div id="todayAttendance" [style.width.%]="100" [style.height.px]="300"></div>
      </div>
    </div>
  </div>
  <br>





  <!-- <button type="button" class="btn btn-secondry" (click)="onClickChartDetails('e','x','g')">Reset</button> -->

<!--------------------------------------------------------------------------------------------------------->
<div class="backdrop " [ngStyle]="{'display':display}"></div>

<div id="ShowChartDetailModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content edit-modal-form">
      <div class="modal-header">
        <!-- <h4 class="modal-title pull-left">View</h4> -->
        <h2 class="modal-title pull-left">{{DetailHeading}}</h2>
        <!-- <input type="hidden" id="updateId" name="updateId">-->
      <button type="button" class="close" aria-label="Close" (click)="onCloseShowDetailModal()"><span aria-hidden="true">&times;</span></button>     
      </div>
      <div class="modal-body">
        <div class="table-responsive" >
          <div class="mt-4">
            <table [id]="Detailtableid" 
            style="width:100%;margin-right:2px;margin-left: 2px;"class="table table-bordered row-border hover">
              <thead>
                <tr *ngIf="isRenderDetail">
                 
                  <th>S.No.</th>                 
                  <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                    <th>
                      {{headerDetailData['title']}}
                    </th>
                  </ng-container>


                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let rowDetailData of DetailtableRowData; let rowi=index;">
                  
                  <td>{{(rowi + 1)}}</td>               
                  <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                    <td>
                      {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["key"])])}}
                    </td>
                  </ng-container>

                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondry" (click)="onCloseShowDetailModal()">Close</button>
        <!-- <button type="button" class="btn btn-primary" (click)="updateEditMasterForm()" >Update</button> -->

      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<!--------------------------------------------------------------------------------------------------------->

