import { Component, OnInit } from '@angular/core';
import { Iblogs } from '../../allUsedModule';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog-page-model',
  templateUrl: './blog-page-model.component.html',
  styleUrls: ['./blog-page-model.component.css']
})
export class BlogPageModelComponent implements OnInit {
  blogsdata:Iblogs ={} as Iblogs;
  updateEditMasterForm !:FormGroup;
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.blogsdata);
    
    this.updateEditMasterForm = new  FormGroup({
      title:new FormControl(this.blogsdata.title , [Validators.required]),
      description:new FormControl(this.blogsdata.description , [Validators.required]),
     })
  }

  OnClickSubmit(){
    this.blogsdata.title = this.updateEditMasterForm.get("title")?.value;
    this.blogsdata.description = this.updateEditMasterForm.get("description")?.value;
    this.activeModal.close();
  }
  onCloseConfirmModal(){
    this.activeModal.close();
  }

}
