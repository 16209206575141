<section class="public-lesson mb-5">
    <div class="container">
        <div class="publicPageHeadingCss" data-aos="fade-right">
            <div class="headline">
                <h1>{{lessondata.topheadline}}</h1>
                <p>{{lessondata.topdescription}}</p>
            </div>
        </div>
        <div class="row lessonbgcolor">
            <div class="col-md-5 px-0" data-aos="fade-right">
                <div class="videobox">
                    <video #videoElement>
                        <source src="assets/video/privet.mov" type="video/mp4">
                        <!-- <source src="assets/video/oggmod.ogg" type="video/ogg"> -->
                    </video>
                    <div class="btnply_div">
                        <button (click)="togglePlayPause()" class="playbtn" [ngClass]="{'playing': isPlaying}">
                            <img *ngIf="isPlaying; else playIcon" src="assets/img/pause.png" alt="Pause">
                            <ng-template #playIcon>
                                <img src="assets/img/play.png" alt="Play">
                            </ng-template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-7" data-aos="fade-left">
                <div class="tabs">
                    <ul class="tab-group">
                        <li *ngFor="let tab of tabs; let i = index"  
                            class="tab" 
                            [class.active]="tab.isactive" 
                            (click)="changeTab(i)">
                            {{ tab.tabName }}
                        </li>
                    </ul>
                
                    <div class="tab-content-group">
                        <ng-container *ngFor="let tab of tabs">
                            <div class="tab-content" *ngIf="tab.isactive">
                                <h5>{{tab.headlinename}}</h5>
                                <ul>
                                    <li *ngFor="let content of tab.tabcontaint">
                                        {{ content.list }}
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</section>