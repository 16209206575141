<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/about-bg.webp" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Discover Our Class Story</p>
                <h1>Class Details</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">Class Details</span></p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="classDetailspage">
    <div class="classdetail_wrapper py-5">
        <div class="container">
            <div class="row position-relative">
                <div class="col-md-8" data-aos="fade-right">
                    <div class="topimgbox">
                        <img src="{{classDetails.mainData[0]?.imageUrl}}" alt="">
                    </div>
                    <div class="content mt-4">
                        <h1>{{classDetails.mainData[0]?.headline1}}</h1>
                        <p>{{classDetails.mainData[0]?.description}}</p>
                    </div>
                    <div class="topimgbox mt-5">
                        <video src="{{classDetails.mainData[0]?.videoUrl}}"></video>
                    </div>
                    <div class="content mt-4">
                        <h1>{{classDetails.mainData[0]?.headline2}}</h1>
                        <p>{{classDetails.mainData[0]?.description2}}</p>
                    </div>
                </div>
                <div class="col-md-4" data-aos="fade-left">
                    <div class="holdBox">
                        <div class="hold_warpper">
                            <h4>Class Features</h4>
                            <div class="topsection">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Start Date</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.startDate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>End Date</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.endDate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Total time</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.totalTime}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Class time</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.classTime}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Class Size</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.classSize}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Class Fee</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.classFee}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p>Rating</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{classDetails.sideData.rating}}</p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="bottomdata">
                                <h4>Most Popular Class</h4>
                                <div class="row py-3">
                                    <div class="col-md-4">
                                        <div class="imgbox-prvw">
                                            <img src="assets/img/classes.webp" width="100%" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <h6>Lorem ipsum dolor sit amet consectetur.</h6>
                                        <p>22 July 2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>