import { Component, HostListener, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-custum-aboutpage',
  templateUrl: './public-custum-aboutpage.component.html',
  styleUrls: ['./public-custum-aboutpage.component.css']
})
export class PublicCustumAboutpageComponent implements OnInit {
  clintLogo:any;
  clintgroup: any[] | undefined;

  Aboutdata:any;

  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.Aboutdata = this.commonfunction.loadJson('assets/about.json')[0]; 
    
    this.clintLogo = this.commonfunction.loadJson('assets/about-clint.json'); // Correct the path if needed
    if (this.clintLogo) {
      console.log(this.clintLogo);  // Check if data is being logged
      this.updateLayout(); 
    } else {
      console.error('Data not loaded from JSON');
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateLayout(); // Update layout when window is resized
  }

  updateLayout(): void {
    const screenWidth = window.innerWidth;
    let itemsPerSlide = 1;

    if (screenWidth >= 992) {
      itemsPerSlide = 4;
    } else if (screenWidth >= 768) {
      itemsPerSlide = 3;
    } else {
      itemsPerSlide = 1;
    }

    if (this.clintLogo && this.clintLogo.clintlogo) {
      this.clintgroup = this.chunk(this.clintLogo.clintlogo, itemsPerSlide);
      console.log('Clint group:', this.clintgroup);  // Check chunking result
    } else {
      console.error('clintLogo or clintlogo array is missing');
    }
  }

  // Helper function to chunk the categories array into groups
  chunk(array: any[], size: number): any[] {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    console.log('Chunked array:', chunkedArray); 
    return chunkedArray;
  }
}
