import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterContentChecked,ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { UserService } from '../../shared/user.service';
import { Subscription, Subject } from 'rxjs';
import { formTypeEnum } from '../model/formTypeEnum';
import { Commonfunction } from '../commonutility'
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalAlertService } from '../global-alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PreviewImageService } from '../preview-image/preview-image.service';
import { Customvalidation } from "../../shared/Validation";
import { WorkFlowProcessServiceService } from '../work-flow-process/work-flow-process-service.service';
import { ConfirmDialogBoxService } from '../confirm-dialog-box-event/confirm-dialog-box.service';
import { GenericPrintService } from '../generic-print-service';
import { IFilterColumns, IviewFullDetails, IgenericFilterParamValue,IgenericMemoryTablePageConfig, IActualQueryFilter, IDisplayQueryFilter, IviewFullDetailRowData } from '../model/genericTableListViewModel';
import { ExcelService } from '../excel.service';
import { lstObjectParam, customAction } from '../object-param.model';
import { GenericChildActionModalServiceService } from '../generic-child-action-modal/generic-child-action-modal-service.service';
import { NgbCalendar,  NgbDateAdapter,  NgbDateParserFormatter,  NgbDateStruct, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../ngbDatePickerFormatter.service'
import { add } from 'ngx-bootstrap/chronos';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-generic-table-list-local-memory-view',
  templateUrl: './generic-table-list-local-memory-view.component.html',
  styleUrls: ['./generic-table-list-local-memory-view.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class GenericTableListLocalMemoryViewComponent implements OnInit,OnDestroy, AfterContentChecked {
  p = 1;
  searchText: string ="";
  isShowHeaderCheckbox: boolean = false;
  config: IgenericMemoryTablePageConfig = {} as IgenericMemoryTablePageConfig;
  isDateFilter: boolean = false;
  hoveredDate: NgbDate | null = null;
  rangeDates: string ="";
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
  buttom_clicked:boolean = true;
  isShowSecondFilterControl: boolean = false;
  filterDataType: number = 0;
  filterControlType: string = "";
  
  columnHideView: boolean = false;
  columnHideMobileView: boolean = false;
  columnFilterMobileView: boolean = false;
  columnHideMobileForm: boolean = false;
  popoverEvent:any;
  filterForm!: FormGroup;
  filterFormValue: IFilterColumns= {} as IFilterColumns;
  filterColumnName: string = "";
  filterColumnDataType:string = ""
  filterColumnParentTableName:string = ""
  filterColumnDisplayName: string = "";
  filterColumnQuery: IFilterColumns[] = []
  _filterFromMainScreen: string ="";
  _isRenderDetail: number=0;
  _preFilterParmValue: Array<IgenericFilterParamValue> = [] as Array<IgenericFilterParamValue>;
  ActionParamvalue: string = "";
  childActionParam: string="";
  excelPDFCall:string ="T";
  filterDisplayQuery: Array<IDisplayQueryFilter> =[];
  filterActualQuery: Array<IActualQueryFilter> = [];
  jsonDataFilterStringfinal: string = '';
  jsonDataFilterStringMobilefinal: string = '';
  @Input() spName: string="";
  @Input() formName: string="";
  @Input() formType: formTypeEnum = 1;
  @Input() editPermission: boolean = false;
  @Input() activePermission: boolean = false;
  @Input() deletePermission: boolean = false;
  @Input() customActionPermission: boolean = true;
  @Input() printPermission: number = 0;
  @Input() isWorkflowImplemented: boolean = false;
  @Input() ischeckboxshow:number = 0;
  @Input() sendSelectedData:boolean = false;
  @Input() isExcelDownload: boolean = false;
  @Input() isPdfDownload: boolean = false;
  @Input() customActionToolTip: string = "";
  @Input() customActionIcon: string = "";
  @Output() viewFullDetail = new EventEmitter<IviewFullDetailRowData>();
  @Output() customActionDetail = new EventEmitter<IviewFullDetailRowData>();
  @Output() getExportExcelMainFilter = new EventEmitter();
  @Output() sendSelectedRows = new EventEmitter<any>();
  _preFilterParmValueString: string = ""; 
  columnWidth: string="200px";
  @Input()
  set isRenderDetail(target:number){
    this._isRenderDetail = target;
    // this.bindSearchDebounce();

    // this.getFormDetailsData("T");

  }
  get isRenderDetail(){
    return this._isRenderDetail ;
  }
  @Input()
  set filterFromMainScreen(target: string){
    if(target != null && target != undefined){     
      this._filterFromMainScreen = target;
  }
  }

  get filterFromMainScreen(){
    return this._filterFromMainScreen ;
  }
  @Input() set preFilterParmValue(target : Array<IgenericFilterParamValue>){
    if(target != null && target != undefined && target.length > 0){
    this._preFilterParmValue = target;    
    this._preFilterParmValueString = '';
    this._preFilterParmValue.forEach((value:IgenericFilterParamValue, index: number)=>{     
      this._preFilterParmValueString = this._preFilterParmValueString + "|"+value.paramName+"="+value.paramValue;      
    });   
    this.bindSearchDebounce();
    this.getFormDetailsData("T");
  }
  }
  get preFilterParmValue(){
    return this._preFilterParmValue;
  }

  
    @Input() dropdownListActionGroupSpdrp:any = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownSettings:any = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box",
      disabled: false
  };
  isFilterShow: boolean = false;
  updateRecordID: string="";
  ViewDetailChildtableHeaderData = [];
    ViewDetailChildtableRowData = [];
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    tableCols:any[] = [];
    tableExportCols:any[] = [];
    searchColsVal:any={};
    listViewSelectedSearchCol:any = 'id';
  DetailtableRowData:any[] = [];
  DetailtableRowDatafromDB:any[] = [];
  DetailtableExportRowData:any[] = [];
  masterSelected: boolean = false;
  selectedRows:any = [];
  tooltipHeader:any = [];
  dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
  selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
  resultData: any;
  GenericPrintDataArray: any[]=[];
  allEmployeeData: any[] = [];
  mobileView: boolean = false;
  totalRecords:number = 0;
  currentPage:number = 1;
  pageSize:number = 5;
  tableOptions:any = {
    limit:this.pageSize,
    offset:0
    // sort options to be added later
  }
  sortOrder: string ="DESC";
  sortColumn:string = "1";
  // tableCols:any[] = [
  //   {key:'id',displayName:'Id'},
  //   {key:'code',displayName:'Code'},
  //   {key:'fName',displayName:'First Name'},
  //   {key:'lName',displayName:'Last Name'},
  //   {key:'total',displayName:'Total'},
  //   {key:'availed',displayName:'Availed'},
  //   {key:'balance',displayName:'Balance'}
  // ];

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  private readonly searchSubject = new Subject<string | undefined>();
  private readonly getDataSubject = new Subject<string | undefined>();
  private searchSubscription?: Subscription;
  private getDataSubscription?: Subscription;
  minDate(year: number) {
    let currentdate = new Date();
    let Cmonth = currentdate.getMonth() + 1;
    let Cdate = currentdate.getDate();
    let Cyear = currentdate.getFullYear() - year;
    return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
}
maxDate(year: number) {
    let currentdate = new Date();
    let Cmonth = currentdate.getMonth() + 1;
    let Cdate = currentdate.getDate();
    let Cyear = currentdate.getFullYear() + year;
    return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
}
bsConfig:any;

  constructor(private userService: UserService, private commonfunction: Commonfunction, private spinner: NgxSpinnerService, private GlobalWorkFlowProcessService: WorkFlowProcessServiceService,
    private globalAlertService: GlobalAlertService, private previewImageService: PreviewImageService, public Customvalidation: Customvalidation, private excelService: ExcelService,
     private globalConfirmService: ConfirmDialogBoxService, private genericPrintService: GenericPrintService, private changeDetectorRef : ChangeDetectorRef,private genChildActionService:GenericChildActionModalServiceService,
     private ngbCalendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>, private route: ActivatedRoute, private router: Router) {
      this.columnWidth = this.commonfunction.loadJson("~/../assets/config.json")['wordWrapWidth'];
      console.log("Naveen In Memory 1");
    console.log(this.formName);
    if (window.matchMedia("(max-width: 767px)").matches) {
      this.mobileView = true;
    }
    this.bsConfig = {
      dateInputFormat: 'DD/MM/YYYY',
      isAnimated: true,
      adaptivePosition: false,
      containerClass: 'theme-blue',
      minDate: this.minDate(50),
      maxDate: this.maxDate(50)
  };
  this.fromDate = ngbCalendar.getToday();
  this.toDate = ngbCalendar.getNext(ngbCalendar.getToday(), 'd', 10);
  this.config.currentPage = this.currentPage;
  this.config.itemsPerPage = this.pageSize;
 
  // this.route.queryParamMap.pipe(
  // map((params:any) => params.get("page").json())
  // .subscribe((page:any) => (this.config.currentPage = page));
  }
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  ngOnInit(): void {
    console.log("Naveen In Memory 2");
    console.log(this.formName);

  }

  

  ngAfterContentChecked() : void {
    this.changeDetectorRef.detectChanges();
}
  reactiveFilterForm(dataType:  string)
  {
    this.isShowSecondFilterControl = false;
    this.filterFormValue = {} as IFilterColumns;
    this.filterFormValue.cloumnName = this.filterColumnName =='rowcondition' ? 'rowconditionvalue' : this.filterColumnName;
    this.filterFormValue.cloumnDisplayName = this.filterColumnDisplayName;
    this.filterFormValue.filterColumnDataType = this.filterColumnDataType;
    this.filterFormValue.filterColumnParentTableName = this.filterColumnParentTableName;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    {
      this.filterFormValue.columnCondition = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].columnCondition) ? "AND" : getExistingValues[0].columnCondition;
      this.filterFormValue.filterColumnOperator = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterColumnOperator?.toString()) ? 1 : getExistingValues[0].filterColumnOperator;
      this.filterFormValue.filterValue = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterValue) ? "" : getExistingValues[0].filterValue;
    }
    else
    {
      this.filterFormValue.columnCondition ="AND";
      this.filterFormValue.filterColumnOperator = 1;
    }
    console.log(dataType);
    if(dataType.toLocaleLowerCase() == "varchar"){
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [Validators.required,Validators.minLength(1),Validators.maxLength(200),],)
    });
  }

  else if(dataType.toLocaleLowerCase() == "date"){
    let columdateValue;
    if(this.filterFormValue.filterColumnOperator == 7){
      this.isShowSecondFilterControl = true;
      this.rangeDates = this.filterFormValue.filterValue;
      this.toDate = this.filterFormValue.filterValue != undefined && this.filterFormValue.filterValue != null? this.commonfunction.createngbDateValue(this.filterFormValue.filterValue.split('-')[1]):null;
      this.fromDate = this.filterFormValue.filterValue != undefined && this.filterFormValue.filterValue != null? this.commonfunction.createngbDateValue(this.filterFormValue.filterValue.split('-')[0]):null;
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
      filterValue: new FormControl(this.rangeDates, [Validators.required,Validators.minLength(1),Validators.maxLength(200),])
    });
    }
    else{
      this.isShowSecondFilterControl = false;
      columdateValue = this.filterFormValue.filterValue != undefined && this.filterFormValue.filterValue != null && this.filterFormValue.filterValue !=""? this.commonfunction.createngbDateValue(this.filterFormValue.filterValue):null;
      this.filterForm = new FormGroup({
        cloumnName : new FormControl(this.filterFormValue.cloumnName),
        cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
        filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
        filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
        columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
        filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
        filterValue: new FormControl(columdateValue, [Validators.required,Validators.minLength(1),Validators.maxLength(200),])
      });

    }

  }
  else if(dataType.toLocaleLowerCase() == "numeric"){
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [Validators.required,Validators.minLength(1),Validators.maxLength(200),])
    });
  }
  else if(dataType.toLocaleLowerCase() == "bit"){
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [Validators.required,Validators.minLength(1),Validators.maxLength(200),])
    });
  }
  else{
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [Validators.required,]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [Validators.required,]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [Validators.required,Validators.minLength(1),Validators.maxLength(200),])
    });
  }
  }

  onConditionDropdown(event:any){
if(event.target.value == 7){
this.isShowSecondFilterControl = true;
this.filterForm.controls['filterValue']?.addValidators([Validators.required,Validators.minLength(1),Validators.maxLength(200),]);
}
else if(event.target.value == 5 || event.target.value == 6){
this.isShowSecondFilterControl = false;
this.filterForm.controls['filterValue']?.clearValidators();
console.log(this.filterForm);
}
else{
  this.isShowSecondFilterControl = false;
  this.filterForm.controls['filterValue']?.addValidators([Validators.required,Validators.minLength(1),Validators.maxLength(200),]);
}
this.filterForm.controls['filterValue'].updateValueAndValidity();
  }

  get columnCondition() {
    return this.filterForm.get('columnCondition')!;
  }

  get filterColumnOperator() {
    return this.filterForm.get('filterColumnOperator')!;
  }

  get filterValue() {
    return this.filterForm.get('filterValue')!;
  }

  genrateFilterQuery(){
    let that = this;
    this.filterActualQuery = [];
    this.filterDisplayQuery = [];
    this.jsonDataFilterStringfinal ='';
    this.filterColumnQuery.forEach((value:IFilterColumns,index:number)=>{
      let filterSQlDate:string="";
      let filterDisplayColumnOperatorValue;
      let filterDisplayColumnOperatorValueDisplay;
      let jsonDataFilterString: string = "";
      let jsonDataKeyFilter: string = "";
      let jsonfilterValue: string = "";
      let jsonfilterValue_to: string = "";
      if(value.filterColumnOperator !=7){
        jsonfilterValue = this.commonfunction.createJsonFiltervalue(value.filterValue, value.filterColumnDataType);
      
      //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
      if((value.filterColumnDataType == 'date' || value.filterColumnDataType == 'datetime') && value.filterValue != undefined && value.filterValue != null && value.filterValue !=""){
        let val = value.filterValue.split('/');
        filterSQlDate = val[0].trim()+"/"+val[1].trim()+"/" + val[2].trim();
        jsonDataKeyFilter = ' new Date(this.commonfunction.ConvertDateFormatMMDDYYYYString(x.'+ value.cloumnName+')) ';
      }
      else if(value.filterColumnDataType != 'date' && value.filterColumnDataType != 'datetime'){
        jsonDataKeyFilter = ' x.'+ value.cloumnName+' ';
      }

       
      // else if(index > 0 && (value.filterColumnDataType == 'date' || value.filterColumnDataType == 'datetime') && value.filterValue != undefined && value.filterValue != null && value.filterValue !=""){
      //   let val = value.filterValue.split('/');
      //   filterSQlDate = val[0].trim()+"/"+val[1].trim()+"/" + val[2].trim();
      //   jsonDataFilterString = jsonDataFilterString +' '+value.columnCondition+ ' new Date(x.'+ value.cloumnName+') ';
      // }
      // else if(index > 0 && value.filterColumnDataType != 'date' && value.filterColumnDataType != 'datetime'){
      //   jsonDataFilterString = jsonDataFilterString +' '+value.columnCondition+ ' x.'+ value.cloumnName+' ';
      // }
      
      let filterColumnOperator = value.filterColumnOperator ==1 ? "equal"  : value.filterColumnOperator ==2 ? "notEqual" : value.filterColumnOperator ==3 ? "Like" :value.filterColumnOperator ==4 ? "IN" :value.filterColumnOperator ==5 ? "IS NULL" : value.filterColumnOperator ==6 ? "IS NOT NULL" :value.filterColumnOperator ==8 ? "GREATER THEN" : "SMALLER THEN";
      jsonDataFilterString = value.filterColumnOperator ==1 ? jsonDataKeyFilter +" == " + jsonfilterValue : value.filterColumnOperator ==2 ? jsonDataKeyFilter +" != " + jsonfilterValue  : value.filterColumnOperator ==3 ? '( new RegExp('+ jsonDataKeyFilter+', "gi").test('+jsonfilterValue+') || '+jsonfilterValue +' === "")'    :value.filterColumnOperator ==4 ? jsonfilterValue+'.includes(' +jsonDataKeyFilter+')' :value.filterColumnOperator ==5 ? jsonDataKeyFilter+" == null " : value.filterColumnOperator ==6 ? jsonDataKeyFilter+" != null " :value.filterColumnOperator ==8 ? jsonDataKeyFilter+" > "+jsonfilterValue : jsonDataKeyFilter+" < "+jsonfilterValue;
      if(value.filterColumnDataType == 'date' || value.filterColumnDataType == 'datetime'){
      filterDisplayColumnOperatorValue = value.filterColumnOperator ==1 ? " = "+ filterSQlDate  : value.filterColumnOperator ==2 ? " ≠ "+ filterSQlDate : value.filterColumnOperator ==3 ? " ≈ "+ filterSQlDate :value.filterColumnOperator ==4 ? " IN ["+ filterSQlDate+"]" :value.filterColumnOperator ==5 ? " Is Blank " : value.filterColumnOperator ==6 ? " Is not Blank " :value.filterColumnOperator ==8 ? " > " + filterSQlDate :" < " + filterSQlDate;
      this.filterActualQuery.push({columnName: value.cloumnName =='rowcondition' ? 'rowconditionvalue' :  value.cloumnName, columnValue:filterSQlDate?.toString(),columnValue2:"", conditionOperator: value.columnCondition, filterColConditionOperator:filterColumnOperator});
      }else{
        filterDisplayColumnOperatorValue = value.filterColumnOperator ==1 ? " = "+ value.filterValue  : value.filterColumnOperator ==2 ? " ≠ "+ value.filterValue : value.filterColumnOperator ==3 ? " ≈ "+ value.filterValue :value.filterColumnOperator ==4 ? " IN ["+ value.filterValue+"]" :value.filterColumnOperator ==5 ? " Is Blank " :value.filterColumnOperator ==6 ? " Is not Blank " :value.filterColumnOperator ==8 ? " > "+value.filterValue :" < "+ value.filterValue;
        this.filterActualQuery.push({columnName: value.cloumnName =='rowcondition' ? 'rowconditionvalue' :  value.cloumnName, columnValue: value.filterValue,columnValue2:"", conditionOperator: value.columnCondition, filterColConditionOperator:filterColumnOperator});
      }
      filterDisplayColumnOperatorValueDisplay = value.filterColumnOperator ==1 ? " = "+ value.filterValue  : value.filterColumnOperator ==2 ? " ≠ "+ value.filterValue : value.filterColumnOperator ==3 ? " ≈ "+ value.filterValue :value.filterColumnOperator ==4 ? " IN ["+ value.filterValue+"]" :value.filterColumnOperator ==5 ? " Is Blank " :value.filterColumnOperator ==6 ? " Is not Blank " :value.filterColumnOperator ==8 ? " > "+value.filterValue :" < "+ value.filterValue;
      this.filterDisplayQuery.push({ columnName: value.cloumnName , displayQuery: value.cloumnDisplayName + filterDisplayColumnOperatorValueDisplay, operator: value.columnCondition});
    }
    else if(value.filterColumnOperator ==7){

      let toSqlDate="";
      let fromSqlDate="";
      let val = value.filterValue.split('-');
      let valToDate = val[1].split('/');
      let valFromDate = val[0].split('/');

      jsonfilterValue = this.commonfunction.createJsonFiltervalue(val[0], value.filterColumnDataType);
      jsonfilterValue_to = this.commonfunction.createJsonFiltervalue(val[1], value.filterColumnDataType);

      toSqlDate = valToDate[0].trim()+"/"+valToDate[1].trim()+"/" + valToDate[2].trim();
      fromSqlDate = valFromDate[0].trim()+"/"+valFromDate[1].trim()+"/" + valFromDate[2].trim();
      //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
      jsonDataKeyFilter = ' new Date(this.commonfunction.ConvertDateFormatMMDDYYYYString(x.'+ value.cloumnName+')) ';

       let filtertoColumnOperator ="range";
       jsonDataFilterString =  jsonDataKeyFilter+" > "+jsonfilterValue +' && '+ jsonDataKeyFilter+" < "+jsonfilterValue_to ;


        this.filterActualQuery.push({columnName: value.cloumnName, columnValue: fromSqlDate,columnValue2:toSqlDate , conditionOperator: value.columnCondition, filterColConditionOperator:filtertoColumnOperator});
       // this.filterActualQuery.push({columnName: value.cloumnName, columnValue: fromSqlDate, conditionOperator: value.columnCondition, filterColConditionOperator:filterfromColumnOperator});

      filterDisplayColumnOperatorValueDisplay = " (Date Range ["+ value.filterValue +"])";
      this.filterDisplayQuery.push({ columnName: value.cloumnName , displayQuery: value.cloumnDisplayName + filterDisplayColumnOperatorValueDisplay, operator: value.columnCondition});

    }
    if(index != 0)
    {
      jsonDataFilterString = value.columnCondition == 'AND' ?  ' && ' + jsonDataFilterString : ' || ' + jsonDataFilterString ;
    }
    this.jsonDataFilterStringfinal = this.jsonDataFilterStringfinal + ' '+ jsonDataFilterString
    });
     this.filterDatafromOriginalDBData();
  }
  filterDatafromOriginalDBData(){
    if(this.jsonDataFilterStringfinal !=""){
    this.DetailtableRowData =  this.DetailtableRowDatafromDB.filter((x:any)=> eval(this.jsonDataFilterStringfinal))
    .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    this.totalRecords = this.DetailtableRowDatafromDB?.filter((x:any)=> eval(this.jsonDataFilterStringfinal)).length;
    }
    else{
      this.DetailtableRowData =  this.DetailtableRowDatafromDB
    .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    this.totalRecords = this.DetailtableRowDatafromDB?.length;
    }
    

  }

  filterDatafromOriginalDBDataMobile(){
    if(this.jsonDataFilterStringMobilefinal !=""){
    this.DetailtableRowData =  this.DetailtableRowDatafromDB.filter((x:any)=> eval(this.jsonDataFilterStringMobilefinal));
   // .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    this.totalRecords = this.DetailtableRowDatafromDB?.filter((x:any)=> eval(this.jsonDataFilterStringMobilefinal)).length;
    }
    else{
      this.DetailtableRowData = [... this.DetailtableRowData, ...this.DetailtableRowDatafromDB
        .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize)]
    //   this.DetailtableRowData.push(this.DetailtableRowDatafromDB
    // .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize));
    this.totalRecords = this.DetailtableRowDatafromDB?.length;
    }
    

  }

  delFilter(columnName:string)
  {
    let filtercolumnIndex: number = this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == columnName);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    this.genrateFilterQuery();
  }
  clearAllFilter()
  {
    this.isDateFilter = false;
    this.userService.changeClass(false);
    this.filterColumnQuery.splice(0, this.filterColumnQuery.length);
    this.genrateFilterQuery();
  }
  clearFilterForm()
  {
    this.isDateFilter = false;
    this.userService.changeClass(false);
    this.filterFormValue.columnCondition="AND";
    this.filterFormValue.filterColumnOperator=1;
    this.filterFormValue.filterValue="";
    this.rangeDates = "";
    this.fromDate = null;
    this.toDate = null;
    let filtercolumnIndex:number =this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == this.filterFormValue.cloumnName);
    this.filterForm.reset(this.filterFormValue);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    console.log(this.filterColumnQuery);
    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName,false,this.filterControlType);
    this.genrateFilterQuery();
  }
  applyFilter()
  {
    this.isDateFilter = false;
    this.userService.changeClass(false);
    this.filterFormValue = this.filterForm.value;
    this.filterFormValue.filterValue = this.filterFormValue.filterValue && (this.filterFormValue.filterColumnDataType =="date" || this.filterFormValue.filterColumnDataType =="datetime") && !this.isShowSecondFilterControl ? this.commonfunction.createFilterDate(this.filterFormValue.filterValue) : this.filterFormValue.filterValue;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    {
      getExistingValues[0].columnCondition = this.filterFormValue.columnCondition;
      getExistingValues[0].filterColumnOperator = this.filterFormValue.filterColumnOperator;
      getExistingValues[0].filterValue =  this.filterFormValue.filterValue;
    }
    else
    this.filterColumnQuery.push(this.filterFormValue);
    this.genrateFilterQuery();
    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName, false,this.filterControlType);


  }
  getNewEmployeeData() {
    this.userService.getNewEmployeeData().subscribe((res: any) => {
      this.allEmployeeData = res.data;
      this.totalRecords = res.totalRecords;
      // set table headers from this api in tableCols
    })
  }

  getDatafromAPI(callfrom:string="T"):any{
        // T - indicates data for table.
    // SL - indicates data for List view but send "T" in API call
    // E - incicates data for Excel
    //.
    let fromCall: string = callfrom == "T" || callfrom =="SL"? "T": callfrom;
    this.spinner.show();
    let dataRow = [];
    let that = this;
    let filterQueryString: string = this.filterActualQuery.length >0 ? JSON.stringify(this.filterActualQuery) : "";
    let procName: string =  this.spName + this._preFilterParmValueString;
   
   return this.userService.GetDataSetResult(procName, this.formName);
  }

  getFormDetailsData(callfrom:string = "T"):any{

     this.getDatafromAPI(callfrom).subscribe((data:any) => {
      this.selectedRows = [];
    this.getData(data,callfrom);
  },
          (err: HttpErrorResponse) => {
              this.spinner.hide();
              console.log(err.message);
              if (err.status == 401) {
                  this.AlertTitle = "Error!!";
                  this.AlertMessage = "You are not authorized!";
                  this.AlertClass = "NP_emoji-fail-bg";
                  this.AlertAnimationType = "sad";
                  this.AlertType = " NP_popup_fail";
              }
              else if (err.status == 403) {
                  this.commonfunction.loginroute(err.status);
              }
              else {

                  this.AlertTitle = "Error!!";
                  this.AlertMessage = err.message.toString();
                  this.AlertClass = "NP_emoji-fail-bg";
                  this.AlertAnimationType = "sad";
                  this.AlertType = " NP_popup_fail";
              }
              this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
          },
          () => {


              // this.dtTriggerDetail.next(true);
              if (this.ischeckboxshow == 2) {
                  this.masterSelected = true;
                  this.checkUncheckAll();
              }
              this.spinner.hide();
          });


  }

  getFormDetailsExcelPDF(callfrom:string = "E"):any{
    let that = this;
  let fromcall = callfrom =="PE" ? "E":callfrom;
    this.getDatafromAPI(callfrom).subscribe((data:any) => {
      that.getExportData(data,callfrom);
 },
         (err: HttpErrorResponse) => {
          that.spinner.hide();
             console.log(err.message);
             if (err.status == 401) {
                 that.AlertTitle = "Error!!";
                 that.AlertMessage = "You are not authorized!";
                 that.AlertClass = "NP_emoji-fail-bg";
                 that.AlertAnimationType = "sad";
                 that.AlertType = " NP_popup_fail";
             }
             else if (err.status == 403) {
              that.commonfunction.loginroute(err.status);
             }
             else {

                 that.AlertTitle = "Error!!";
                 that.AlertMessage = err.message.toString();
                 that.AlertClass = "NP_emoji-fail-bg";
                 that.AlertAnimationType = "sad";
                 that.AlertType = " NP_popup_fail";
             }
             that.globalAlertService.AlertMsg(that.AlertTitle, that.AlertMessage, "Ok", that.AlertType, that.AlertClass, that.AlertAnimationType);
         },
         () => {
          this.spinner.hide();
        if(callfrom =="E")
        that.excelService.exportAsExcelFile(that.extractforExcelColNames(that.tableExportCols), that.DetailtableExportRowData,that.formName.toString());
        else if(callfrom =="PE")
        that.excelService.exportAsPDFFile(that.extractforPDFColNames(that.tableExportCols), that.DetailtableExportRowData,that.formName.toString());
        // this._filterFromMainScreen = "";
        this.tableExportCols = [];
        this.DetailtableExportRowData = [];
         });


 }

getData(data:any,callfrom:string="T"){
  let dataRow = [];
  let that = this;
  if(data?.table1?.length >0)
  this.totalRecords = data?.table1?.length;
  
  // this.columnHideView = data.table[0]['columnHideView'];
  // this.columnHideMobileView = data.table[0]['columnHideMobileView'];
  // this.columnFilterMobileView = data.table[0]['columnFilterMobileView'];
  // this.columnHideMobileForm = data.table[0]['isExcel'];

  // that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? '1' : '0', itemName: 'S.No.' });
  let TrData = '';
  let ThHeader;
  if (this.tableCols.length == 0) {
      this.tableCols = data.table;
        console.log(this.tableCols);
      // let headerdata = data.table.filter((x:any) => x['columN_NAME'] != 'id' && x['columN_NAME'] != 'guid')
      // headerdata.forEach(function (obj:any, index:string) {
      //     that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? (index + 2) : (index + 1), itemName: obj['columnAliasName'] });
      // });

  }
  dataRow = data.table1;
  if (this.ischeckboxshow > 0) {
      dataRow.forEach((obj:any) => {
          obj.isSelected = false
      });
  }
  if (dataRow.length > 0)
     // this.isWorkflowImplemented = dataRow[0]['workFlowImplemented'];
  this.tooltipHeader = this.tableCols.filter((th:any) => th['isViewLink'] == true || th['columN_NAME'] == 'text');
  // if(this.tooltipHeader.length > 1)
  // {
  //     this.tooltipIsViewLink = this.tooltipHeader.filter(th => th['isViewLink'] == true)[0]['columN_NAME'];
  //     this.tooltipText = this.tooltipHeader.filter(th => th['columN_NAME'] == 'text')[0]['columN_NAME'];
  // }
  // else{
  //     this.tooltipText = this.tooltipHeader.filter(th =>th['isViewLink'] == true)[0]['columN_NAME'];
  //     console.log(this.tooltipText);
  // }
  dataRow.forEach((obj:any) => {


      if (that.tooltipHeader.length > 1 ) {

        let isViewLink = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['isViewLink'] == true)[0]['columN_NAME']);
      let colNameValue = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['columN_NAME'] == 'text')[0]['columN_NAME']);


          obj.toolTipRow = obj[colNameValue] + '(' + obj[isViewLink] + ')';

      }
      else if(that.tooltipHeader.length == 1) {
        let isViewColChk = that.tooltipHeader.filter((th:any) => th['isViewLink'] == true);
        let isViewLink = isViewColChk.length >0 ? that.commonfunction.FirstLetterLower(isViewColChk[0]['columN_NAME']): "";

          obj.toolTipRow = isViewLink !=""? obj[isViewLink]: "";
      }
  });
if(callfrom =="SL"){
console.log(dataRow);
dataRow.forEach((value:any)=>{
// this.DetailtableRowData.push(value);
this.DetailtableRowDatafromDB.push(value);
})

}
else 
{ // this.DetailtableRowData = dataRow;
  this.DetailtableRowDatafromDB = dataRow;
}

this.DetailtableRowData =  this.DetailtableRowDatafromDB
.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;
}

getExportData(data:any,callfrom:string="E"){
  let dataRow = [];
  let that = this;


  console.log(this.totalRecords);

  let TrData = '';
  let ThHeader;

      this.tableExportCols = data.table;



  dataRow = data.table1;
  dataRow.forEach((obj:any) => {
    delete obj['id'];
    delete obj['isSelected'];
    delete obj['param1'];
    delete obj['param2'];
    delete obj['guid'];
    Object.keys(obj).forEach((objColm:any) => {
let chkHeader = data.table.filter((x:any)=> x["columN_NAME"] == objColm);
if(chkHeader.length ==0)
delete obj[objColm];
    });
   });


  this.DetailtableExportRowData = dataRow;
}
getFormDetailsDataListView(callfrom:string = "S"):any {
  this.spinner.show();
  let dataRow = [];
  let that = this;
  let filterQueryString: string = this.filterActualQuery.length >0 ? JSON.stringify(this.filterActualQuery) : "";
let SpParams:string = 'uspAppGenericViewGetTableDetails|tblname=' + this.spName + '|filterParams=' + filterQueryString + '|pageSize=' + this.pageSize.toString() + '|page=' + this.currentPage.toString() + '|callFlag='+callfrom  + '|sortOrder=' + this.sortOrder +'|sortColumn='+ this.sortColumn;
  this.userService.GetDataSetResult(SpParams, this.formName).subscribe((data: any) => {
      that.resultData = data;



  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err.message);
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.commonfunction.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
          if (this.ischeckboxshow == 2) {
              this.masterSelected = true;
              this.checkUncheckAll();
          }
          this.spinner.hide();
      });
}



toggleWithFilterCol(popover:any, colName: string, colDisplayName: string,dataType: string,filterColParentTableName: string, callfrom:boolean,filterControl:string) {
  if(callfrom)
  this.popoverEvent = popover;
  if (popover.isOpen()) {
    this.filterColumnName = "";
    this.filterColumnDisplayName = "";
    this.filterColumnDataType = "";
    this.filterColumnParentTableName = "";
    this.filterControlType = "";
    this.rangeDates = "";
    this.fromDate = null;
    this.toDate = null;
    this.isDateFilter = false;
    this.userService.changeClass(false);
     popover.close();
  } else {
    this.filterColumnName = colName;
    this.filterColumnDisplayName = colDisplayName;
    this.filterColumnDataType = dataType;
    this.filterColumnParentTableName = filterColParentTableName;
    this.filterControlType = filterControl;
    this.reactiveFilterForm(dataType);
    this.isDateFilter = true;
    this.userService.changeClass(true);
    popover.open({colName, colDisplayName});
  }
}
changeClass(){
this.isFilterShow = true;
}
onValueChange(event:any): void {
  this.buttom_clicked = false;
}
onSortClick(event:any, columnName:string) {
  let target = event.currentTarget,
    classList = target.classList;

  if (classList.contains('sort-up')) {
    classList.remove('sort-up');
    this.sortOrder="ASC";
  } else {
    classList.add('sort-up');
    this.sortOrder="DESC";
  }
  this.sortColumn = columnName.toLocaleLowerCase() == 'rowcondition'?'rowconditionvalue':columnName;
  this.currentPage = 1;
  // this.sortOrder = columnName.toLocaleLowerCase() === this.sortColumn ? (this.sortOrder * -1) : 1;
  this.DetailtableRowDatafromDB =  [...this.DetailtableRowDatafromDB.sort((a: any, b: any) => {
    // sort comparison function
    let sortColumnDetail = this.tableCols.filter((x:any)=> x['cOLUMN_NAME'] == this.sortColumn)
    let columnA = sortColumnDetail['dataType'] == 'date' || sortColumnDetail['dataType'] == 'datetime' ? this.commonfunction.ConvertDateFormatYYYYMMDDwithoutanysign(a[this.sortColumn]) : a[this.sortColumn ] ;
    let columnB = sortColumnDetail['dataType'] == 'date' || sortColumnDetail['dataType'] == 'datetime' ? this.commonfunction.ConvertDateFormatYYYYMMDDwithoutanysign(b[this.sortColumn]) : b[this.sortColumn ] ;
    let result = 0;
    if (columnA < columnB) {
        result = -1;
    }
    if (columnA > columnB) {
        result = 1;
    }
    return this.sortOrder =="DESC" ?  result * -1 : result * 1;
})];
  
this.DetailtableRowData =  this.DetailtableRowDatafromDB.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    // this.getFormDetailsData("T");
}
  pageSizeChange(){
    this.currentPage = 1;
    this.filterDatafromOriginalDBData();
    let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;
    // this.DetailtableRowData =  this.DetailtableRowDatafromDB
    // .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
  }

   ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}
  checkUncheckAll() {

    let that = this;
    this.DetailtableRowData.forEach((value:any, index:number)=> {
      if(value.checkBoxShowRowWise)
        value.isSelected = that.masterSelected;
    });
    this.getCheckedItemList();
}
isAllSelected() {
    this.masterSelected = this.DetailtableRowData.every((item: any)=> {
        if(item.checkBoxShowRowWise)
        item.isSelected == true;
    })
    this.getCheckedItemList();
}

getCheckedItemList() {
    this.selectedRows = [];
    let that = this;
    this.DetailtableRowData.forEach((value:any, index:number)=> {
        if (value.isSelected && value.checkBoxShowRowWise) {
            that.selectedRows.push(value);
        }
    });
   if(this.sendSelectedData)
    this.sendSelectedRows.emit(this.selectedRows);
   

}


onWorkFlowHistoryOpenDetail(event:any, tableName: string, rowId: number, modalTitle: string) {
  
  this.spinner.show();

  let dialogTitle: string =  "Work Flow History Details" + " - " + modalTitle;
  let param = "uspAppGenericViewGetTableWorkFlowHistoryDetails|tblname=" + tableName + "|id=" + rowId;
  this.userService.getCommonDataSet(this.formName, param).subscribe((data: any) => {
      
      let TrData = '';
      let ThHeader;

      this.ViewDetailChildtableHeaderData = data.table;
      this.ViewDetailChildtableRowData = data.table1;
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();

          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
        this.globalAlertService.ShowTableModel(dialogTitle,this.ViewDetailChildtableHeaderData, this.ViewDetailChildtableRowData,"Close","","");
          this.spinner.hide();
      });
}

OnDynamicGenericWorkFlow(value:any, WorkFlowApprove: boolean, WorkFlowReject: boolean, WorkFlowReturn: boolean, WorkFlowForword: boolean, recordId: string, tableName: string, record: string) {
  
  let that = this;
  // let updatedColumn = this.masterJsonDataEdit['formData'].filter(x => x.columN_NAME.toLowerCase() == 'text');
  this.GlobalWorkFlowProcessService.WorkFlowFunction(" Record - " + record, WorkFlowApprove, WorkFlowReject, WorkFlowReturn, WorkFlowForword, recordId, tableName, this.formName, "Submit", "Cancel", "md")
      .then((confirmed) => {
          
          if (confirmed["GenericWorkFlow"].flag) {
              //this.SearchEdit();
              this.globalAlertService.AlertMsg(this.AlertTitle, confirmed["GenericWorkFlow"].errorMsg, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
              this.retainFilter(1500);
          }
      });

}
retainFilter(ms:any) {
  setTimeout(() => {
      // if (this.filterActualQuery.length > 0)
         // this.CustomFilterusingColumn();
        //  this.getFormDetailsData("T");

  }, ms);
}

// calculate child table column total end
OnMasterFormAction(event:any, id:string, action:string, TableId:number, modalTitle: string) {
  let that = this;
  this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + " Record for " + modalTitle + "?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
              
              this.MasterTableAllAction(id, action, TableId, that.tableCols[0]['tableName']);

          }
      });

}
MasterTableAllAction(id: string, action: string, index: number, tableName: string) {
  
  //code to be done
  this.userService.ActionActivesubmit(id, action, this.formName, tableName, this.formType.toString()).subscribe((data: any) => {
      let resultarray = JSON.parse(data);
      // resultarray['successFlag']
      // resultarray['resultMessage']
      // resultarray['printAction']
      // resultarray['printActionResult']
      // resultarray['otherAction']
      // resultarray['otherActionResult']
      // resultarray['outputVarResult']
      
      if (action == "Active" || action == "InActive") {
          if (resultarray['successFlag'] == "success") {
              this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
              return;
          }
          else {
              this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
              return;
          }
      }
      else {
          if (resultarray['successFlag'] == "success") {
              //
              this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

              return;

          }
          else {
              this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
              return;

          }
      }

  },
      (err: HttpErrorResponse) => {

          if (err.status == 401) {
              this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
          }
      },
      () => {
          
          if (action == "Active") {
              this.DetailtableRowData[index]['isActive'] = true;
          }
          else if (action == "InActive") {
              this.DetailtableRowData[index]['isActive'] = false;
          }
          else {
            if(this.filterActualQuery.length > 0)
              this.retainFilter(1500);
              else
              this.getFormDetailsData("T");
          }

      });
  //

}
OnPrintFullDetail(event:any, id:string, callfrom:any) {
  
  this.spinner.show();
  var trdata = '';
  const that = this;


  let param = "uspAppGenericCommonPrintFunctionality|Formname=" + this.spName + "|recordId=" + id;
  this.userService.getCommonDataSet(this.formName, param).subscribe((data: any) => {

      
      that.genericPrintService.printDataArray = data.table;
      that.GenericPrintDataArray = data.table;

      this.spinner.hide();

  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
          if (this.printPermission == 1)
              this.genericPrintService.printDocument();
          else if (this.printPermission == 2)
              this.GenericPrintFunction();
      });
}
GenericPrintFunction() {
  if (this.GenericPrintDataArray != null) {
      // let printContents, popupWin;
      // setTimeout(() => {
      //     //   printContents = document.getElementById('printContaint').innerHTML;
      //     printContents = jQuery('#printContaint').html();
      //     let FINALDATA = printContents.replace("display: none", "");
      //     popupWin = window.open('', '_blank', '');
      //     popupWin.document.open();
      //     popupWin.document.write(`<html>
      //  <body style="font-family: sans-serif" onload="window.print();window.close()">jQuery{FINALDATA}</body></html>`);
      //     popupWin.document.close();
      //     setTimeout(() => {
      //         popupWin.document.write(`<html><body>jQuery{printContents}</body></html>`);
      //     }, 200);
      // }, 500);

      this.genericPrintService.GenericPrintFunction(this.GenericPrintDataArray);
  }
}
OnViewFullDetail(event:any, rowData:any, callfrom:string) {
  let viewFullDetailsParam: IviewFullDetailRowData = {} as IviewFullDetailRowData;
  viewFullDetailsParam.event = event;
  viewFullDetailsParam.rowData = rowData;
  viewFullDetailsParam.callfrom = callfrom; 
  this.viewFullDetail.emit(viewFullDetailsParam);

}

OnCustomActionClickDetail(event:any, rowData:any) {
  let viewFullDetailsParam: IviewFullDetailRowData = {} as IviewFullDetailRowData;
  viewFullDetailsParam.event = event;
  viewFullDetailsParam.rowData = rowData;
  this.customActionDetail.emit(viewFullDetailsParam);
}

onfiledownloadDetail(event:any, flagDownload:number, filename:string, colmAlias:string) {
  
  this.userService.downloadFile(filename, colmAlias,  this.formName).subscribe((res:any) => {
      if (res == "true") {
          if (flagDownload == 2) {

              this.previewImageService.PreviewImageFunction('Preview', filename, colmAlias,  this.formName, "Download", "Cancel", "sm")
                  .then((confirmed) => {
                      if (confirmed) {

                      }
                  });


          }
          else {
              let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + filename + "&columnName=" + colmAlias;
              // window.open(_url);
              window.location.href = _url;
          }
      }
      else {

          this.AlertTitle = "Alert!!";
          this.AlertMessage = "File not Exists";
          this.AlertClass = "NP_emoji-error-bg";
          this.AlertAnimationType = "NP_emoji-error";
          this.AlertType = "NP_popup_error";
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);

      }
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();


          if (err.status == 401) {

              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });
}

onChildOpenDetail(event:any, params: string, rowId: number, modalTitle: string) {
  
  this.spinner.show();
  let paramArray = params.split(',');
  let dialogTitle: string = this.commonfunction.SpaceAfterCapitalLetter(paramArray[1]) + " Details" + " - " + modalTitle;
  let param = "uspAppGenericViewGetTableDetailswithfilter|tblname=" + paramArray[0].toString() + "|parentTable=" + paramArray[2].toString() + "|filterParameter=" + rowId;
  this.userService.getCommonDataSet(this.formName, param).subscribe((data: any) => {
      
      let TrData = '';
      let ThHeader;

      this.ViewDetailChildtableHeaderData = data.table;
      this.ViewDetailChildtableRowData = data.table1;
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();

          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {

              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
        this.globalAlertService.ShowTableModel(dialogTitle,this.ViewDetailChildtableHeaderData, this.ViewDetailChildtableRowData,"Close","","");
          this.spinner.hide();
      });

}

// mobile view functions
onScroll(){
  if(this.DetailtableRowData.length < this.totalRecords){

  this.currentPage += 1;
 this.filterDatafromOriginalDBDataMobile();
    let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;


  }

}
onSearchQueryInput(event: Event,key?:string): void {
  const searchQuery = (event.target as HTMLInputElement).value;
  this.searchSubject.next(searchQuery?.trim());
  if(key){
    this.listViewSelectedSearchCol = key;
  }
}
bindSearchDebounce(){
  let that = this;
  this.searchSubscription = this.searchSubject
  .pipe(

    debounceTime(300),
    distinctUntilChanged(),
    switchMap((searchQuery:any) => {
      this.filterActualQuery = [];
      this.jsonDataFilterStringfinal ='';

   this.currentPage = 1;
        //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
        let filterColumnOperator = "like"  ;
           this.filterActualQuery.push({columnName: this.listViewSelectedSearchCol =='rowcondition'?'rowconditionvalue':  this.listViewSelectedSearchCol, columnValue: searchQuery,columnValue2:"", conditionOperator: "AND", filterColConditionOperator:filterColumnOperator});
           if(!this.commonfunction.checkStringIsNullorEmpty(this.filterActualQuery[0].columnValue))
           this.jsonDataFilterStringMobilefinal ="";
           else
           this.jsonDataFilterStringMobilefinal = '(x.'+ this.filterActualQuery[0].columnName+'.toLocaleLowerCase().includes("' +this.filterActualQuery[0].columnValue+'") || "'+this.filterActualQuery[0].columnValue +'" === "")'  // '( new RegExp(x.'+ this.filterActualQuery[0].columnName+', "gi").test("'+this.filterActualQuery[0].columnValue+'") || "'+this.filterActualQuery[0].columnValue +'" === "")'   
      console.log(searchQuery);
      let options:any = {
        offset: this.tableOptions.offset,
        limit: this.tableOptions.limit
      };
      options[this.listViewSelectedSearchCol] = searchQuery;
      this.tableOptions[this.listViewSelectedSearchCol] = searchQuery;
      if(!this.mobileView){
        Object.keys(this.searchColsVal).forEach(key=>{
          if(key != this.listViewSelectedSearchCol){
            options[key] = this.searchColsVal[key];
            this.tableOptions[key] = this.searchColsVal[key];
          }
        })
      }
      return this.DetailtableRowDatafromDB;
    })
  )
  .subscribe((data:any) => {
   
   this.filterDatafromOriginalDBDataMobile();
   let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;
    if (this.ischeckboxshow == 2) {
      this.masterSelected = true;
      this.checkUncheckAll();
  }
  this.spinner.hide();
},
(err: HttpErrorResponse) => {
    this.spinner.hide();
    console.log(err.message);
    if (err.status == 401) {
        this.AlertTitle = "Error!!";
        this.AlertMessage = "You are not authorized!";
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = " NP_popup_fail";
    }
    else if (err.status == 403) {
        this.commonfunction.loginroute(err.status);
    }
    else {

        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = " NP_popup_fail";
    }
    this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
},
() => {
    // this.dtTriggerDetail.next(true);
    if (this.ischeckboxshow == 2) {
        this.masterSelected = true;
        this.checkUncheckAll();
    }
    this.spinner.hide();
});

}

onItemSelectChildAction(item: any) {

  this.genChildActionService.createChildActionForm(this.selectedItemsActionGroupSpdrp[0].itemName,"Close","Select","","",this.selectedItemsActionGroupSpdrp[0].id,this.formName,"lg")
  .then((confirmed) => {
    if (confirmed.Action) {
        this.childActionParam = confirmed.genericSPParmas;
    }
    else
    {
      this.childActionParam = "";
    }
});

}
OnItemDeSelectChildAction(item: any) {
  if(this.selectedItemsActionGroupSpdrp.length > 0){
  this.genChildActionService.createChildActionForm(this.selectedItemsActionGroupSpdrp[0].itemName,"Close","Select","","",this.selectedItemsActionGroupSpdrp[0].id,this.formName,"lg")
  .then((confirmed) => {
    if (confirmed.Action) {
        this.childActionParam = confirmed.genericSPParmas;
    }
    else
    {
      this.childActionParam = "";
    }
});
}
  else
  this.childActionParam = "";
}

enableChildActionButton(){
  if(this.childActionParam == "" || this.selectedItemsActionGroupSpdrp.length === 0)
  return true;
  else
  return false;
}

exportAsXLSX(): void {
  this.excelPDFCall ="E";
  let fileName = this.formName;

    this.getFormDetailsExcelPDF("E");
}

exportAsPDF(): void {
  this.excelPDFCall ="PE";
  let fileName = this.formName;

    this.getFormDetailsExcelPDF("PE");
}
extractforPDFColNames(col:any)
{
  let delColumn = ['id','isSelected','param1','param2','guid'];
  let  columns: Array<{header:any,dataKey:any}> =[];
  col.forEach((value:any)=>{

    let isdel:any = delColumn.find((x:any) => (x == value["columN_NAME"].toString()));
    if(isdel == "" || isdel == undefined)
    columns.push({header:value["columnAliasName"], dataKey:value["columN_NAME"]});

  });
  return columns;
}
extractforExcelColNames(col:any)
{
  let delColumn = ['id','isSelected','param1','param2','guid'];
  let  columns: Array<{header:any,key:any, width:number}> =[];
  col.forEach((value:any)=>{

    let isdel:any = delColumn.find((x:any) => (x == value["columN_NAME"].toString()));
    if(isdel == "" || isdel == undefined)
    columns.push({header:value["columnAliasName"], key:value["columN_NAME"],width:value["columnAliasName"].length >20 ?value["columnAliasName"].length :20});

  });
  return columns;
}

onActionArrayList() {

  let that = this;
  let flag = true;
  let msg = '';
  let _data;
  let tableNamefor;
  let objColumn = <lstObjectParam>{};
  let genericColumnList:any = [];
  let mylist = '[';
  let GenericRptProcParams: string="";
  GenericRptProcParams = '';
  console.log(this.DetailtableRowData);
  this.DetailtableRowData.forEach(function (value, index) {

      let objList = <customAction>{};
      objList.rowId = value["id"];
      objList.isChecked = value["isSelected"];
      objList.param1 = value["param1"];
      objList.param2 = value["param2"];
      genericColumnList.push(objList);

  });

  if (genericColumnList.length > 0) {
      objColumn.genericColumnList = genericColumnList;
      console.log(genericColumnList);
      _data = JSON.stringify(objColumn);
      this.openCustomActionModal();
      this.ActionParamvalue = _data;
  }

}

openCustomActionModal() {
  this.globalConfirmService.ConfirmFunction("Confirm Action !!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
          if (confirmed) {
              this.custumActioncall();
              this.ActionParamvalue = '';
          }
      });
}

custumActioncall() {

  this.spinner.show();
  let data:string = JSON.stringify("uspAppGenericCustumActionforAllObject|spName=" + this.childActionParam + "|formName=" + this.formName + "|rowIds=" + this.ActionParamvalue +"|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
  this.userService.CustumActionSubmitForm(data, this.formName).subscribe((datacom: any) => {
      this.spinner.hide();

      if (datacom != "") {
          let resultarray = JSON.parse(datacom);
          // resultarray['successFlag']
          // resultarray['resultMessage']
          // resultarray['printAction']
          // resultarray['printActionResult']
          // resultarray['otherAction']
          // resultarray['otherActionResult']
          // resultarray['outputVarResult']

          this.masterSelected = false;
          this.checkUncheckAll();
          //this.resetForm();
          if (this.selectedItemsActionGroupSpdrp.length > 0)
              this.selectedItemsActionGroupSpdrp.splice(0, this.selectedItemsActionGroupSpdrp.length);
          if (resultarray['successFlag'] == "success") {
              // this.masterProductForm.reset();
              this.AlertTitle = "Success!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-success-bg";
              this.AlertAnimationType = "smile";
              this.AlertType = "NP_popup_success";
          }

          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = resultarray['resultMessage'].toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = "NP_popup_fail";
          }

      }
  },
      (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status == 401) {
              this.AlertTitle = "Error!!";
              this.AlertMessage = "You are not authorized!";
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          else if (err.status == 403) {
              this.Customvalidation.loginroute(err.status);
          }
          else {
              this.AlertTitle = "Error!!";
              this.AlertMessage = err.message.toString();
              this.AlertClass = "NP_emoji-fail-bg";
              this.AlertAnimationType = "sad";
              this.AlertType = " NP_popup_fail";
          }
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      },
      () => {
          this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      }
  );
}

sendMessageArrayList() {

}
sendEmailArrayList() {

}
countTotalRecords(){
  let showRecord = this.currentPage * this.pageSize >= this.totalRecords ? this.totalRecords : this.currentPage * this.pageSize;
  return showRecord;
}
  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.getDataSubscription?.unsubscribe();
  }

  onDateSelection(datepicker:any,date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.rangeDates= this.commonfunction.createFilterDate(this.fromDate)  + ' - ' + this.commonfunction.createFilterDate(this.toDate);
      datepicker.toggle();

		} else {
			this.toDate = null;
      this.fromDate = date;
      this.rangeDates= this.commonfunction.createFilterDate(this.fromDate);

    }
    this.filterForm.get('filterValue')?.patchValue(this.rangeDates);
    this.filterForm.get('filterValue')?.markAsTouched();
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(control:any,currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    if(control['name'] == "dpToDate" )
    {
control.close();
    }
		return parsed && this.ngbCalendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

  openNormalDatefilter(date:any)
  {
    date.open();
    // this.isDateFilter = true;
  }
  openRangeDatefilter(datepicker:any)
  {
    datepicker.toggle();
    // this.isDateFilter = true;
  }
  hiddenPopover(popover:any)
  {
    this.isDateFilter = false;
    this.userService.changeClass(false);
    //return popover.isOpen()
  }

  pageChange(event: any) {

    this.filterDatafromOriginalDBData();
    let checkboxCounter = this.DetailtableRowData.filter((x:any)=> x['checkBoxShowRowWise'] == true);
    this.isShowHeaderCheckbox = checkboxCounter.length > 0 ? true : false;
    
  }

  openSideFilter(){
    console.log("okk");
  }
}
