<div class="tab-content">
  <div class="container col-xl-12">
    <div class="row">
      <div class="container col-lg-12 col-md-12 col-sm-12 col-xl-12">
        <h4 id="Formheading" class=" text-center">Change Password</h4>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xl-12">
        <form [formGroup]="registrationFormGroup" (ngSubmit)="onClickRegister()">

          <div class="form-group">
            <!-- <label for="currentpassword">Current Password</label> -->
            <input class="form-control" #CurrentPassword type="password" name="currentpassword"
              placeholder="Current Password" formControlName="currentpassword" />
            <label class="nb-checkbox-eye1"><input type="checkbox" (click)="myFunction(CurrentPassword)"><span
                class="nb-checkmark-eye1"></span></label>
          </div>
          <p class="text-danger"
            *ngIf="registrationFormGroup.controls.currentpassword.errors?.required && registrationFormGroup.controls.currentpassword.touched">
            Current Password is required</p>

          <div [formGroup]="passwordFormGroup">
            <div class="form-group">
              <!-- <label for="password">New Password</label> -->
              <input class="form-control" #Password type="password" name="password" placeholder="New Password"
                formControlName="password" (input)="onSearchChange()" (focus)="onFocus()" (blur)="onblur()">
              <label class="nb-checkbox-eye2"><input type="checkbox" (click)="myFunction(Password)"><span
                  class="nb-checkmark-eye2"></span></label>

              <span id="result">{{PMessage}}</span>


              <div class="aro-pswd_info">
                <div id="pswd_info">
                  <h4>Password must be requirements</h4>
                  <ul>
                    <li id="letter" class="invalid">At least <strong>one letter</strong></li>
                    <li id="capital" class="invalid">At least <strong>one capital letter</strong></li>
                    <li id="number" class="invalid">At least <strong>one number</strong></li>
                    <li id="length" class="invalid">Be at least <strong>8 characters</strong></li>
                    <li id="space" class="invalid">be<strong> use [~,!,@,#,$,%,^,&,*,-,=,.,;,']</strong></li>
                  </ul>
                </div>
              </div>

            </div>
            <p class="text-danger"
              *ngIf="passwordFormGroup.controls.password.errors?.required && passwordFormGroup.controls.password.touched">
              New Password is required</p>

            <div class="form-group">
              <!-- <label for="repeatPassword">Confirm Password</label> -->
              <input class="form-control" type="password" name="repeatPassword" placeholder="Confirm Password"
                formControlName="repeatPassword">
            </div>
            <p class="text-danger"
              *ngIf="passwordFormGroup.controls.repeatPassword.errors?.required && passwordFormGroup.controls.repeatPassword.touched">
              Confirm password is required</p>
            <p class="text-danger" *ngIf="passwordFormGroup.errors?.doesMatchPassword">Password does not match</p>

          </div>
          <div class="form-group">
            <button class="form-control btn btn-primary" type="submit" [disabled]="!registrationFormGroup.valid">Change
              Password</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>