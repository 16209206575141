import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';
import{IhomeExperiance, Itableth} from "../../../allUsedModule";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-program-dashboard',
  templateUrl: './program-dashboard.component.html',
  styleUrls: ['./program-dashboard.component.css']
})
export class ProgramDashboardComponent implements OnInit {
  HomeProgram:any;
  activeTabId:number|any=1;
  AddNewDetailFrom !: FormGroup;
  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "ImgUrl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Title", "colName": "Heading","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "Content","isActionBtn":false,"isImagePreviewEnabled":false },
  ]
  constructor(public commonfunction:Commonfunction,public homePageservice:HomePageModelService,private confirm: ConfirmDialogBoxService, private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
    this.HomeProgram = this.commonfunction.loadJson("~/../assets/Program.json");

    //Add new List
    this.AddNewDetailFrom = new FormGroup({
      title :new FormControl("",[Validators.required]),
      details :new FormControl("",[Validators.required])
    })
  }



  onClickResetFrom(){
    this.AddNewDetailFrom.reset();
  }
  OnClickSubmit(){
    this.confirm.ConfirmFunction("Confirm Add New!!", "Are you sure you want to Update Record From Home?")
    .then((confirmed) => {
      if (confirmed) {
        let _item :IhomeExperiance ={} as IhomeExperiance;
        _item.Heading = this.AddNewDetailFrom.get('title')?.value;
        _item.Content = this.AddNewDetailFrom.get('details')?.value;
        _item.id = this.HomeProgram.length + 1
        // _item.ImgUrl = 
        this.HomeProgram.push(_item);
    
        this.AddNewDetailFrom.reset();

        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
    
  }




  onClickEditForm(value:IhomeExperiance){
    this.homePageservice.HomeExperianceService(value,true)
    .then((confirm)=>{
      if(confirm){
        // this.HomeProgram[index] = value;
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }
  deleteKey(index:number){
    this.confirm.ConfirmFunction("Confirm Delete!!", "Are you sure you want to Delete Record From Home?")
    .then((confirm)=>{
      if(confirm){
        this.HomeProgram.splice(index,1)
      this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }

}
