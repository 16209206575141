import { SignInComponent } from '../../public/user/sign-in/sign-in.component';
import { ResetPasswordComponent } from '../../public/user/reset-password/reset-password.component';

import{LandingPageComponent} from '../../public/landing-page/landing-page.component';

import { Routes, RouterModule } from '@angular/router';

import { GenericReportFormComponent } from 'src/app/secure/generic-report-form/generic-report-form.component';


export const PUBLIC_ROUTES: Routes = [
  
   { path: '', redirectTo: 'login', pathMatch: 'full' },
  
    { path: 'login', component: SignInComponent },
    { path: 'PasswordReset/:TokeId', component: ResetPasswordComponent }, 
    // { path: 'GenericMultiForm/:formName', component: GenericMultiTableFormComponent },
    // { path: 'GenericReportForm/:formName', component: GenericReportFormComponent }, 
    
    { path: 'Landing', component: LandingPageComponent },
   
];