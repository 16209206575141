<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Add New Detail</a>
        <ng-template ngbNavContent>
            <div class="row"  [formGroup]="AddNewFrom">  
                <div class="col-md-12">
                    <label for="">Main Heading</label>
                    <input placeholder="Add New Heading" class="form-control" type="text" formControlName="Admissionlink">
                </div>
                <hr class="mt-3">
                <div><span>Add New Table Row</span></div>
                <div class="mt-2 col-md-6">
                    <label for="">Title</label>
                    <input placeholder="Add New title" class="form-control" type="text" formControlName="title">
                </div>

            </div>

            <div class="Submitbtn-content mt-5">
                <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
            </div>
        </ng-template>
    </li>

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">View Detail</a>
        <ng-template ngbNavContent>
            <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="rowdata" (customActionDetail)="receiveData($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>
</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>