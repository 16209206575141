<div class="container my-5" data-aos="fade-right">
    <div class="row">
        <div class="col-md-4" *ngFor="let item of GenricProgramList">
            <div class="ProgramBox">
                <div class="ProgramBoxImg"><img  [src]="item.ImgUrl" alt=""></div>
                <div>
                    <h3 style="font-size: 16px;">{{item.Heading}}</h3>
                    <p>{{item.Content}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
