<!-- start submit Modal -->
<div id="ConfirmModal" class="edit-form-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
        <div class="modal-content border-0 edit-modal-form">
            <div class="modal-header">
                <!-- <div class="NP_emoji-confirm-bg">
         <div class="NP_emoji-error"></div>
       </div> -->
                <h2 class="modal-title pull-left">{{title}}</h2>
                <button type="button" class="close" aria-label="Close" (click)="onCloseConfirmModal()"><span
                        aria-hidden="true">&times;</span></button>

            </div>


            <div class="modal-body">
                <!-- <form novalidate [formGroup]="mastertableFormWF">
          <div class="col-xl-12">
            <label class="col-form-label requiredSign">Select Work FLow Status</label>
            <angular2-multiselect id="DrpWorkFlowStatus"  formControlName="WorkFlowStatus" [data]="dropdownListWFStatus" [(ngModel)]="selectedItemsWFStatus"            
            (onClose)="OnClose()"
            [ngClass]="{'is-invalid': (mastertableFormWF.get('WorkFlowStatus').touched || mastertableFormWF.get('WorkFlowStatus').dirty) && !mastertableFormWF.get('WorkFlowStatus').valid,'is-valid': (mastertableFormWF.get('WorkFlowStatus').touched || mastertableFormWF.get('WorkFlowStatus').dirty) && mastertableFormWF.get('WorkFlowStatus').valid }"
              [settings]="dropdownGridSettings">
            </angular2-multiselect>   
            <span class=" invalid-feedback" style="padding-top: 10px;">               
              {{mastertableFormWF.get('WorkFlowStatus').errors?.GenericError['errorMsg']}}
            </span>
                  
          </div>
            <div class="col-xl-12">
                <label class="col-form-label requiredSign"  for="txtRemarksWF">Remarks</label>
                <textarea type='text' class='form-control' id="txtRemarksWF" #Remarks formControlName="Remarks" Placeholder='Please Enter Remarks' autocomplete="off"                 
                  [ngClass]="{'is-invalid': (mastertableFormWF.get('Remarks').touched || mastertableFormWF.get('Remarks').dirty) && !mastertableFormWF.get('Remarks').valid,'is-valid': (mastertableFormWF.get('Remarks').touched || mastertableFormWF.get('Remarks').dirty) && mastertableFormWF.get('Remarks').valid }">
                  </textarea>
                <span class=" invalid-feedback" style="padding-top: 10px;" >
                  {{mastertableFormWF.get('Remarks').errors?.GenericError['errorMsg']}}
                </span>
              </div>
              
                  </form> -->

                <div class="container">
                    <div class="row"> 
                        <div class="col-lg-12 snapshot">
                            <div class="video-capture position-relative">
                            <div *ngIf="!webcamImage" class="capture-camera">
                                <webcam [height]="250" [width]="350" [trigger]="triggerObservable"
                                    (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                    [videoOptions]="videoOptions" [imageQuality]="1"
                                    (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                                </webcam>
                            </div>
                            <div  *ngIf="webcamImage" class="capture-img">
                                <img  width="100%" [src]="webcamImage.imageAsDataUrl" />
                            </div>

                            <!-- <div class="img-rotate">
                                <a href="javascript: void(0);" (click)="showNextWebcam(true);" style="vertical-align: top;" *ngIf="!webcamImage"
                            >
                                 <img src="assets/images/rotateCamera.png" height="50" alt="Rotate Camera"></a>
                            </div> -->
                        </div>
                        </div>
                        
                    </div> 

                    <div class="camera-capture-btn text-center mt-4">
                        
                        <button class="btn btn-primary" *ngIf="!webcamImage" (click)="triggerSnapshot();"><i class="fa fa-camera pr-2"></i> Take Photo</button>
                        <button class="btn btn-primary" *ngIf="webcamImage" (click)="RetriggerSnapshot();"><i class="fa fa-camera pr-2"></i>Retake Photo</button>
                        <!-- <button class="actionBtn" (click)="toggleWebcam();">Toggle Webcam</button> -->
                        <br />                     
                        
                    </div>

                </div>
            </div>
                <div class="modal-footer">
                    <div class="mt-2 text-center">
                        <div class="d-inline-block align-middle">
                            <button type="button" class="btn btn-secondry"
                                (click)="onCloseConfirmModal()">{{btnCancelText}}</button>
                            <button type="button"[disabled]="!webcamImage" class="btn btn-primary ml-2 uplod" (click)="onAcceptConfirmModal()">{{btnOkText}}</button>
                        </div>
                    </div>
                  

                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->

    <!-- End submit Modal -->