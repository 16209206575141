import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserObjectParam, lstUserObjectParam, ObjectParam, lstObjectParam, masterFormRowData } from "../object-param.model";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CopyContentService } from '../copyService/copy-content.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-master-form-rowdata-popup',
  templateUrl: './master-form-rowdata-popup.component.html',
  styleUrls: ['./master-form-rowdata-popup.component.css']
})
export class MasterFormRowdataPopupComponent implements OnInit {
  
  exampleMultiClassColumn = "Example for Multiple classes('%' is Seprator)ex:-'Uiparams%Labelgap%form-control'";
  exampleCustomColumnEvent = "First create function in Type script service Then provide that Only Function Name here.";
  exampleChildTables = "Add multiple tables using ('$' is Seprator)ex:- 'Table1$Table2'";
  exampleDropdownQuery = "Use select Query or Proc whic return dataset having 2 column names must Like 'id' & 'itemName'";
  exampleCustomValidationMsg: string ="{'requiredMsg':'','sizeMsg':'','customMsg':'Employee D.O.B. must be 15 years previous from today'}"
  exampleRegExp = "Any Valid Regex for applied any custom Validation ex:- '^[0-9]*$'";
  
  exampleDataExistDB= `For any data existing param ('%' is Seprator)ex:- uspAppGeneric_custum_Ischool_CheckDuplicateValue%extraColumnName!extraColumnValue!itemValue%msg%%roleId$$drop!masterFormGroup?.value['DrpName_roleId']$$drop%
  here Proc Name then spParam :- paramter name for provided SP may be single if not then seperated by '!' but filter column name which used control value must be last.
 Then extraColumnVlaue :- this paramter id special  when provided column values more than 1 and it always less 1 from column array.
 Then Message column errorStringName -- name of the result column that produced by provided SP.
 Then provided any value that we want pass without access any API Call or in edit case to check name is previous or not
 Example Custom Proc :- uspAppGenericCheckWorkFlowName%workFlowName%msg%%%
 Example Generic Proc :- uspAppGeneric_custum_Ischool_CheckDuplicateValue%itemValue%msg%%%
 example single form:- uspAppGeneric_custum_Ischool_CheckDuplicateValue%extraColumnName!extraColumnValue!itemValue%msg%%sessionId!masterFormGroup?.value['DrpName_sessionId']%
 Example multi form  form alias name add in control name and used in to get form data :-uspAppGeneric_custum_Ischool_CheckDuplicateValue%extraColumnName!extraColumnValue!itemValue%msg%%sessionId__classId__feeCatId!masterFormGroup.get('FeeStructure')?.value['DrpName_sessionId_FeeStructure']__masterFormGroup.get('FeeStructure')?.value['DrpName_classId_FeeStructure']__masterFormGroup.get('FeeStructure')?.value['DrpName_feeCatId_FeeStructure']%
  `;
  exampleDataExistArray = ` ('%' is Seprator) first "formValue" like 'that.childTableDataDynamicArrayList[valueBase['formAliasName']]'
  Then "filterColumn" dbname like 'wfLevel'
  Then "considerSpaces" in given value like true or false
  Then "considerCase" in given value like true or false
  Then "compareString" which is empty or pass
  Then "dropdowncolumn" which is 'itemName'
 Example single form:- that.childTableDataDynamicArrayList[valueBase['formAliasName']]%wfLevel%true%true%%itemName  `;
 exampleDataExist =this.exampleDataExistDB;
 exampleDefaultValue = "For Setting Default Value for dropDown use Value or for other controls use text or boolen as column type nature."
  exampleMiscellaneousType = 'For File upload column:- {"fileCount": 1,"fileCountMessage":"File Count should not be greater than 1 file.","fileSize": 2,"fileSizeMessage":"File Size should be less than 2mb.","fileType": ["image/png","image/jpeg","application/pdf"],"fileTypeMessage":"File Type should be like"}';
  dropdownListpageloadValidationdrp = [];
  selectedItemspageloadValidationdrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControldrp = [];
  selectedItemspageloadControldrp: Array<{ id: string, itemName: string }> = [];
  dropdownListCompareColumnDate = [];
  dropdownListCompareColumnNumber = [];
  dropdownListCompareColumnGeneric = [];
  selectedItemsCompareColumn: Array<{ id: string, itemName: string }> = [];
  
  dropdownListpageloadValueFillTypedrp = [];
  selectedItemspageloadValueFillTypedrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadActiondrp = [];
  selectedItemspageloadActiondrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadDataExistsdrp = [];
  selectedItemspageloadDataExistsdrp: Array<{ id: string, itemName: string }> = [];
  iSNULLCheckbox: boolean = false;
  isViewLinkCheckbox: boolean = false;
  columnHideCheckbox: boolean = false;
  createColumnHideCheckbox: boolean = false;
  editColumnHideCheckbox: boolean = false;
  isEditDisabledCheckbox: boolean = false;
  columnHideViewCheckbox: boolean = false;
  columnHideMobileViewCheckbox: boolean = false;
  columnFilterMobileViewCheckbox: boolean = false;
  columnHideMobileFormCheckbox: boolean = false;
  excelDownloadColumnCheckbox: boolean = false;
  pdfDownloadColumnCheckbox: boolean = false;
  rangeTitle: string = "";
  rangePlaceholder: string = "";
  actionButtonText:string ="Save changes";
  @Input() AlertDialogType: string | null | undefined;
  @Input() ViewUpdateRowData: masterFormRowData = {} as masterFormRowData;
  UpdatedRowIndex: masterFormRowData = {} as masterFormRowData;

  @Input() set dropDownValues(target: any) {
    console.log(target);
    this.dropdownListpageloadValidationdrp = target[0];
    this.dropdownListpageloadControldrp = target[1];
    this.dropdownListCompareColumnDate = target[2];
    this.dropdownListCompareColumnNumber = target[3];
    this.dropdownListpageloadValueFillTypedrp = target[4];
    this.dropdownListpageloadDataExistsdrp = target[5];
    this.dropdownListpageloadActiondrp = target[6];
  }
  dropdownSettings = {};
  selectedRowData: UserObjectParam = {} as UserObjectParam;
  updatedRowData: UserObjectParam = {} as UserObjectParam;
  masterReactiveForm!: FormGroup;
  isRender: boolean = false;
  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal,private copier:CopyContentService, private toastrService: ToastrService) {
    this.dropdownSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
  }

  ngOnInit(): void {

    this.selectedRowData = this.ViewUpdateRowData.rowData;
    if (this.selectedRowData != null && this.selectedRowData?.columnName != null && this.selectedRowData?.columnName != "") {
      console.log(this.selectedRowData);
      if (this.selectedRowData?.valueFillType != null && this.selectedRowData?.valueFillType != "") {
        let selectedVAlue = this.dropdownListpageloadValueFillTypedrp.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.valueFillType);
        this.selectedItemspageloadValueFillTypedrp = selectedVAlue;
      }
      else{
        this.selectedItemspageloadValueFillTypedrp = this.dropdownListpageloadValueFillTypedrp[0];
      }
      if (this.selectedRowData?.controlType != null && this.selectedRowData?.controlType != "") {
        let selectedVAlue = this.dropdownListpageloadControldrp.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.controlType);
        this.selectedItemspageloadControldrp = selectedVAlue;
      }
      if (this.selectedRowData?.Validation != null && this.selectedRowData?.Validation != "") {
        let selectedVAlue = this.dropdownListpageloadValidationdrp.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.Validation);
        this.selectedItemspageloadValidationdrp = selectedVAlue;
      }
      if (this.selectedRowData?.checkExistsDataValidationType != null && this.selectedRowData?.checkExistsDataValidationType != "") {
        let selectedVAlue = this.dropdownListpageloadDataExistsdrp.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.checkExistsDataValidationType);
        this.selectedItemspageloadDataExistsdrp = selectedVAlue;
      }
      if((this.selectedRowData.dataType.toLowerCase() =="date" || this.selectedRowData.dataType.toLowerCase() =="datetime") && (this.selectedRowData.parent_table_name == ""||this.selectedRowData.parent_table_name == undefined)){
       
        this.dropdownListCompareColumnGeneric = this.dropdownListCompareColumnDate;
        this.rangePlaceholder = "ex:- 24";
        this.rangeTitle = "Date Range should be in numeric months ex:- 1year = 12";
        if (this.selectedRowData?.compareColumnName != null && this.selectedRowData?.compareColumnName != "") {
          let selectedVAlue = this.dropdownListCompareColumnDate.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.compareColumnName);
          this.selectedItemsCompareColumn = selectedVAlue;          
        }
      }      
      else if ((this.selectedRowData.dataType.toLowerCase() =="int" || this.selectedRowData.dataType.toLowerCase() =="decimal" || this.selectedRowData.dataType.toLowerCase() =="numeric") && (this.selectedRowData.parent_table_name == ""||this.selectedRowData.parent_table_name == undefined)) {
       
        this.dropdownListCompareColumnGeneric = this.dropdownListCompareColumnNumber;
        this.rangePlaceholder = "ex:- 20-40";
        this.rangeTitle = "Range should be in given format  ex:- 20-40";
        if (this.selectedRowData?.compareColumnName != null && this.selectedRowData?.compareColumnName != "") {
          let selectedVAlue = this.dropdownListCompareColumnNumber.filter((x: { id: string, itemName: string }) => x.id == this.selectedRowData?.compareColumnName);
          this.selectedItemsCompareColumn = selectedVAlue;
      }
    }
      this.masterReactiveForm = this.fb.group({
        columnName: [this.selectedRowData?.columnName],
        dataType: [this.selectedRowData?.dataType],
        columnAliasName: [this.selectedRowData?.columnAliasName, [Validators.required, Validators.minLength(2), Validators.maxLength(100),]],
        // columnAliasName: [{value: this.selectedRowData?.columnAliasName, disabled: this.selectedRowData?.columnName.toLowerCase() =="id" || this.selectedRowData?.columnName.toLowerCase() =="guid" ? true: false}, [Validators.required,Validators.minLength(1),Validators.maxLength(250),]),
        columnSequence: [this.selectedRowData?.columnSequence, [Validators.required,]],
        controlClass: [this.selectedRowData?.controlClass, [Validators.minLength(1), Validators.maxLength(100),]],
        columnEventDefination: [this.selectedRowData?.columnEventDefination, [Validators.minLength(1), Validators.maxLength(500),]],
        originalColumnLength: [{ value:this.selectedRowData?.originalColumnLength == "-1" ? "Max" : this.selectedRowData?.dataType.toLowerCase() == "bit" || this.selectedRowData?.dataType.toLowerCase() == "date" || this.selectedRowData?.dataType.toLowerCase() == "datetime" ? "" : this.selectedRowData?.originalColumnLength, disabled: true }],
        columnLength: [{ value: this.selectedRowData?.columnLength == "-1" ? "Max" : this.selectedRowData?.dataType.toLowerCase() == "bit" || this.selectedRowData?.dataType.toLowerCase() == "date" || this.selectedRowData?.dataType.toLowerCase() == "datetime" ? "" : this.selectedRowData?.columnLength, disabled: this.selectedRowData?.dataType.toLowerCase() == "bit" || this.selectedRowData?.dataType.toLowerCase() == "date" || this.selectedRowData?.dataType.toLowerCase() == "datetime" ? true : false }, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]],
        iS_NULLABLE: [{ value: this.selectedRowData?.iS_NULLABLE?.toLowerCase() =='no'?true:false, disabled: this.selectedRowData?.isOriginalRequired?.toLowerCase() =='no'?true:false}],
        isViewLink: [this.selectedRowData?.isViewLink && this.selectedRowData?.isViewLink?.toLowerCase() =='yes'?true:false],
        columnHide: [this.selectedRowData?.columnHide?.toLowerCase() =='yes'?true:false ],
        createColumnHide: [this.selectedRowData?.createColumnHide?.toLowerCase() =='yes'?true:false],
        editColumnHide: [this.selectedRowData?.editColumnHide?.toLowerCase() =='yes'?true:false],
        isEditDisabled: [this.selectedRowData?.isEditDisabled?.toLowerCase() =='yes'?true:false],
        columnHideView: [this.selectedRowData?.columnHideView?.toLowerCase() =='yes'?true:false],
        columnHideMobileView: [this.selectedRowData?.columnHideMobileView?.toLowerCase() =='yes'?true:false],
        columnFilterMobileView: [this.selectedRowData?.columnFilterMobileView?.toLowerCase() =='yes'?true:false],
        columnHideMobileForm: [this.selectedRowData?.columnHideMobileForm?.toLowerCase() =='yes'?true:false],
        excelDownloadColumn: [this.selectedRowData?.excelDownloadColumn?.toLowerCase() =='yes'?true:false],
        pdfDownloadColumn: [this.selectedRowData?.pdfDownloadColumn?.toLowerCase() =='yes'?true:false],
        valueFillType: [this.selectedItemspageloadValueFillTypedrp],
        dropDownChildtable: [this.selectedRowData?.dropDownChildtable, [Validators.minLength(2), Validators.maxLength(1000),]],
        dropDownQuery: [this.selectedRowData?.dropDownQuery, [Validators.minLength(2), Validators.maxLength(1000),]],
        controlType: [this.selectedItemspageloadControldrp, [Validators.required,]],
        Validation: [this.selectedItemspageloadValidationdrp],
        customValidationMsg: [this.selectedRowData?.customValidationMsg, [Validators.minLength(2), Validators.maxLength(1000),]],
        regExp: [this.selectedRowData?.regExp, [Validators.minLength(2), Validators.maxLength(100),]],
        checkExistsDataValidationType: [this.selectedItemspageloadDataExistsdrp],
        checkExistsDataValidationParams: [this.selectedRowData?.checkExistsDataValidationParams, [Validators.minLength(2), Validators.maxLength(100),]],
        compareColumnName: [{value:this.selectedItemsCompareColumn, disabled: (this.selectedRowData.dataType.toLowerCase() =="date" || this.selectedRowData.dataType.toLowerCase() =="datetime" || this.selectedRowData.dataType.toLowerCase() =="int" || this.selectedRowData.dataType.toLowerCase() =="decimal" || this.selectedRowData.dataType.toLowerCase() =="numeric") && (this.selectedRowData.parent_table_name == "" || this.selectedRowData.parent_table_name == undefined)? false: true}],
        range: [{value: this.selectedRowData?.range, disabled: (this.selectedRowData.dataType.toLowerCase() =="date" || this.selectedRowData.dataType.toLowerCase() =="datetime" || this.selectedRowData.dataType.toLowerCase() =="int" || this.selectedRowData.dataType.toLowerCase() =="decimal" || this.selectedRowData.dataType.toLowerCase() =="numeric") && (this.selectedRowData.parent_table_name == "" || this.selectedRowData.parent_table_name == undefined)? false: true}, [Validators.minLength(2), Validators.maxLength(100),]],
        columnInformation: [this.selectedRowData?.columnInformation, [Validators.minLength(2), Validators.maxLength(1000),]],
        columnDefaultValue: [this.selectedRowData?.columnDefaultValue, [Validators.minLength(2), Validators.maxLength(1000),]],
        miscellaneousType: [this.selectedRowData?.miscellaneousType, [Validators.minLength(2), Validators.maxLength(1000),]],
        expressionJson: [this.selectedRowData?.expressionJson, [Validators.minLength(2), Validators.maxLength(1000),]],
      });
      this.iSNULLCheckbox = this.selectedRowData?.iS_NULLABLE?.toLowerCase() =='no'?true:false;
      this.isViewLinkCheckbox = this.selectedRowData?.isViewLink && this.selectedRowData?.isViewLink?.toLowerCase() =='yes'?true:false;
      this.columnHideCheckbox = this.selectedRowData?.columnHide?.toLowerCase() =='yes'?true:false;
      this.createColumnHideCheckbox = this.selectedRowData?.createColumnHide?.toLowerCase() =='yes'?true:false;
      this.editColumnHideCheckbox = this.selectedRowData?.editColumnHide?.toLowerCase() =='yes'?true:false;
      this.isEditDisabledCheckbox = this.selectedRowData?.isEditDisabled?.toLowerCase() =='yes'?true:false;
      this.columnHideViewCheckbox = this.selectedRowData?.columnHideView?.toLowerCase() =='yes'?true:false;
      this.columnHideMobileViewCheckbox = this.selectedRowData?.columnHideMobileView?.toLowerCase() =='yes'?true:false;
      this.columnFilterMobileViewCheckbox = this.selectedRowData?.columnFilterMobileView?.toLowerCase() =='yes'?true:false;
      this.columnHideMobileFormCheckbox = this.selectedRowData?.columnHideMobileForm?.toLowerCase() =='yes'?true:false;
      this.excelDownloadColumnCheckbox = this.selectedRowData?.excelDownloadColumn?.toLowerCase() =='yes'?true:false;
      this.pdfDownloadColumnCheckbox = this.selectedRowData?.pdfDownloadColumn?.toLowerCase() =='yes'?true:false;
      console.log(this.masterReactiveForm);
    }
    else {
      this.dismiss();
    }
  }
  get f() { return this.masterReactiveForm.controls; }
  public onCloseAlertModal() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
  onSaveAlertModal() {
    console.log(this.masterReactiveForm);
    if (this.masterReactiveForm.invalid) {
      for (const control of Object.keys(this.masterReactiveForm.controls)) {
        this.masterReactiveForm.controls[control].markAsTouched();
      }
      return;
    }

    this.updatedRowData.columnName  = this.masterReactiveForm.get('columnName')!.value;
    this.updatedRowData.dataType  = this.masterReactiveForm.get('dataType')!.value;
    this.updatedRowData.columnAliasName  = this.masterReactiveForm.get('columnAliasName')!.value;
    this.updatedRowData.columnSequence  = this.masterReactiveForm.get('columnSequence')!.value;
    this.updatedRowData.controlClass  = this.masterReactiveForm.get('controlClass')!.value;
    this.updatedRowData.originalColumnLength  = this.masterReactiveForm.get('originalColumnLength')!.value;
    this.updatedRowData.columnLength  = this.masterReactiveForm.get('columnLength')!.value;
    this.updatedRowData.columnEventDefination  = this.masterReactiveForm.get('columnEventDefination')!.value;
    this.updatedRowData.valueFillType  = this.masterReactiveForm.get('valueFillType')!.value != null && this.masterReactiveForm.get('valueFillType')!.value != "" ? this.masterReactiveForm.get('valueFillType')!.value[0].id : "" ;
    this.updatedRowData.dropDownChildtable  = this.masterReactiveForm.get('dropDownChildtable')!.value;
    this.updatedRowData.dropDownQuery  = this.masterReactiveForm.get('dropDownQuery')!.value;
    this.updatedRowData.controlType  = this.masterReactiveForm.get('controlType')!.value != null && this.masterReactiveForm.get('controlType')!.value != "" ? this.masterReactiveForm.get('controlType')!.value[0].id : "" ;
    this.updatedRowData.Validation  = this.masterReactiveForm.get('Validation')!.value != null && this.masterReactiveForm.get('Validation')!.value != "" ? this.masterReactiveForm.get('Validation')!.value[0].id : "" ;
    this.updatedRowData.customValidationMsg  = this.masterReactiveForm.get('customValidationMsg')!.value;
    this.updatedRowData.regExp  = this.masterReactiveForm.get('regExp')!.value;
    this.updatedRowData.checkExistsDataValidationType  = this.masterReactiveForm.get('checkExistsDataValidationType')!.value != null && this.masterReactiveForm.get('checkExistsDataValidationType')!.value != "" ? this.masterReactiveForm.get('checkExistsDataValidationType')!.value[0].id : "" ;
    this.updatedRowData.checkExistsDataValidationParams  = this.masterReactiveForm.get('checkExistsDataValidationParams')!.value;
    this.updatedRowData.range  = this.masterReactiveForm.get('range')!.value;
    this.updatedRowData.columnInformation  = this.masterReactiveForm.get('columnInformation')!.value;
    this.updatedRowData.compareColumnName  = this.masterReactiveForm.get('compareColumnName')!.value != null && this.masterReactiveForm.get('compareColumnName')!.value != "" ? this.masterReactiveForm.get('compareColumnName')!.value[0].id : "";
    this.updatedRowData.columnDefaultValue  = this.masterReactiveForm.get('columnDefaultValue')!.value;
    this.updatedRowData.miscellaneousType  = this.masterReactiveForm.get('miscellaneousType')!.value;
    this.updatedRowData.expressionJson  = this.masterReactiveForm.get('expressionJson')!.value;
    this.updatedRowData.iS_NULLABLE  = this.masterReactiveForm.get('iS_NULLABLE')!.value == undefined && this.masterReactiveForm.get('iS_NULLABLE')!.value == null ? "yes" : this.masterReactiveForm.get('iS_NULLABLE')!.value == true ? "no" : "yes";
    this.updatedRowData.isViewLink  = this.masterReactiveForm.get('isViewLink')!.value == undefined && this.masterReactiveForm.get('isViewLink')!.value == null ? "no" : this.masterReactiveForm.get('isViewLink')!.value == true ? "yes" : "no";
    this.updatedRowData.columnHide  = this.masterReactiveForm.get('columnHide')!.value == undefined && this.masterReactiveForm.get('columnHide')!.value == null ? "no" : this.masterReactiveForm.get('columnHide')!.value == true ? "yes" : "no";
    this.updatedRowData.createColumnHide  = this.masterReactiveForm.get('createColumnHide')!.value == undefined && this.masterReactiveForm.get('createColumnHide')!.value == null ? "no" : this.masterReactiveForm.get('createColumnHide')!.value == true ? "yes" : "no";
    this.updatedRowData.editColumnHide  = this.masterReactiveForm.get('editColumnHide')!.value == undefined && this.masterReactiveForm.get('editColumnHide')!.value == null ? "no" : this.masterReactiveForm.get('editColumnHide')!.value == true ? "yes" : "no";
    this.updatedRowData.isEditDisabled  = this.masterReactiveForm.get('isEditDisabled')!.value == undefined && this.masterReactiveForm.get('isEditDisabled')!.value == null ? "no" : this.masterReactiveForm.get('isEditDisabled')!.value == true ? "yes" : "no";
    this.updatedRowData.columnHideView  = this.masterReactiveForm.get('columnHideView')!.value == undefined && this.masterReactiveForm.get('columnHideView')!.value == null ? "no" : this.masterReactiveForm.get('columnHideView')!.value == true ? "yes" : "no";
    this.updatedRowData.columnHideMobileView  = this.masterReactiveForm.get('columnHideMobileView')!.value == undefined && this.masterReactiveForm.get('columnHideMobileView')!.value == null ? "no" : this.masterReactiveForm.get('columnHideMobileView')!.value == true ? "yes" : "no";
    this.updatedRowData.columnFilterMobileView  = this.masterReactiveForm.get('columnFilterMobileView')!.value == undefined && this.masterReactiveForm.get('columnFilterMobileView')!.value == null ? "no" : this.masterReactiveForm.get('columnFilterMobileView')!.value == true ? "yes" : "no";
    this.updatedRowData.columnHideMobileForm  = this.masterReactiveForm.get('columnHideMobileForm')!.value == undefined && this.masterReactiveForm.get('columnHideMobileForm')!.value == null ? "no" : this.masterReactiveForm.get('columnHideMobileForm')!.value == true ? "yes" : "no";
    this.updatedRowData.excelDownloadColumn  = this.masterReactiveForm.get('excelDownloadColumn')!.value == undefined && this.masterReactiveForm.get('excelDownloadColumn')!.value == null ? "no" : this.masterReactiveForm.get('excelDownloadColumn')!.value == true ? "yes" : "no";
    this.updatedRowData.pdfDownloadColumn  = this.masterReactiveForm.get('pdfDownloadColumn')!.value == undefined && this.masterReactiveForm.get('pdfDownloadColumn')!.value == null ? "no" : this.masterReactiveForm.get('pdfDownloadColumn')!.value == true ? "yes" : "no";
    this.updatedRowData.child_column_name = this.selectedRowData.child_column_name;
    this.updatedRowData.parent_column_name = this.selectedRowData.parent_column_name;
    this.updatedRowData.parent_table_name = this.selectedRowData.parent_table_name;
    this.updatedRowData.isOriginalRequired = this.selectedRowData.isOriginalRequired;
    this.updatedRowData.originalDataType = this.selectedRowData.originalDataType;
    this.updatedRowData.tbleName = this.selectedRowData.tbleName; 
    this.updatedRowData.rowId = this.selectedRowData.rowId;
    this.updatedRowData.userRoleId = this.selectedRowData.userRoleId;
    this.updatedRowData.tableid = this.selectedRowData.tableid;
    this.UpdatedRowIndex.index = this.ViewUpdateRowData.index;
    this.UpdatedRowIndex.rowData = this.updatedRowData;
    

    this.activeModal.close(this.UpdatedRowIndex);

  }
  onItemDataExistSelect(item: any) {

    this.exampleDataExist = +this.selectedItemspageloadDataExistsdrp[0].id == 2 ? this.exampleDataExistArray : this.exampleDataExistDB;
    
}

OnItemDataExistDeSelect(item: any) {
  
  this.exampleDataExist = this.exampleDataExistDB;

}
doCopy(content:string){
  this.copier.copyText(content);  
  this.toastrService.info('Content Copied!', 'Info!');
}
}
