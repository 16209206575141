<section class="experience-main">
    <div class="container">
        <div class="publicPageHeadingCss">
            <div class="headline" data-aos="fade-right">
                <h1>{{experiencedata?.tophead?.headline}}</h1>
                <p>{{experiencedata?.tophead?.description}}</p>
            </div>
            <div class="experience-card-bg">
                <div class="row align-items-center mb-5" style="border-radius: 20px;background-color: #fff;">
                    <div class="col-lg-5 col-md-12 p-0">
                        <div class="custom-about-img">
                            <img [src]="experiencedata?.imgurl" alt="">
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12"  data-aos="fade-left">
                        <div class="about-caption">
                            <div class="single-features mb-30" *ngFor="let exper of experiencedata?.contentlop">
                                <div class="features-icon">
                                    <img [src]="exper.ImgUrl" alt="">
                                </div>
                                <div class="features-caption">
                                    <h3 class="color-font1">{{exper.Heading}}</h3>
                                    <p>{{exper.Content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>