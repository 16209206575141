<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Add New Detail</a>
        <ng-template ngbNavContent>
            <div class="row"  [formGroup]="AddNewFrom">  
                <div class="col-md-12">
                    <label for="">Main Heading</label>
                    <input placeholder="Add New Heading" class="form-control" type="text" formControlName="AdmissionDeadline">
                </div>
                <hr class="mt-3">
                <div><span>Add New Table Row</span></div>
                <div class="mt-2 col-md-6">
                    <label for="">Title</label>
                    <input placeholder="Add New title" class="form-control" type="text" formControlName="title">
                </div>
                <div class="mt-2 col-md-6">
                    <label for="">year</label>
                    <input placeholder="Add New year" class="form-control" type="text" formControlName="year">
                </div>
                <div class="mt-2 col-md-6">
                    <label for="">monthly</label>
                    <input placeholder="Add New monthly" class="form-control" type="text" formControlName="monthly">
                </div>
            </div>

            <div class="Submitbtn-content mt-5">
                <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
            </div>
        </ng-template>
    </li>

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">View Detail</a>
        <ng-template ngbNavContent>
            <!-- <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class=" p-0">
                        <div class="table-responsive main-table-wrappr">
                            <table class="table row-border hover w-100 ">
                                <thead>
                                    <tr>

                                        <th scope="col" resizable>S.No.</th>
                                        <th scope="col" resizable>Tittle</th>
                                        <th scope="col" resizable>Year</th>
                                        <th scope="col" resizable>Month</th>
                                        <th scope="col">Action</th>
                                    </tr>

                                </thead>

                                <tbody id="EditTableBody">

                                    <tr *ngFor="let data of Addmissiondata?.Applicationdeadline?.ApplicationdeadlineData ; let sr = index">
                                        <td>{{sr+1}}</td>  

                                        <td>
                                            <div >{{data.title}}</div>
                                        </td>
                                        <td>
                                            <div >{{data.year}}</div>
                                        </td>
                                        <td>
                                            <div >{{data.monthly}}</div>
                                        </td>
                                        
                                        <td style="width: 100px;">
                                            <a  href="javascript:void(0)" (click)="onClickEditForm(sr, data)" ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                            <a  href="javascript:void(0)" (click)="deleteKey(sr)" ngbTooltip="Delete Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  -->

            <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="rowdata" (customActionDetail)="receiveData($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>
</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>