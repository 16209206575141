import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {WorkFlowProcessComponent} from '../work-flow-process/work-flow-process.component';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowProcessServiceService {

  constructor(private modalService: NgbModal) { }

  public WorkFlowFunction(
    title: string,
    WorkFlowApprove: boolean,
    WorkFlowReject: boolean,
    WorkFlowReturn: boolean,
    WorkFlowForword: boolean,
    recordId: string,
    tableName: string,
    formName: string,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',    
    dialogSize: 'sm'|'lg'| 'md' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(WorkFlowProcessComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.WorkFlowDialogTitle = title;
    modalRef.componentInstance.WorkFlowApprove = WorkFlowApprove;
    modalRef.componentInstance.WorkFlowReject = WorkFlowReject;
    modalRef.componentInstance.WorkFlowReturn = WorkFlowReturn;
    modalRef.componentInstance.WorkFlowForword = WorkFlowForword;
    modalRef.componentInstance.recordId = recordId;
    modalRef.componentInstance.tableName = tableName;
    modalRef.componentInstance.formName = formName;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText; 
    return modalRef.result;
  }

}
