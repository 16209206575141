import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custum',
  templateUrl: './custum.component.html',
  styleUrls: ['./custum.component.css']
})
export class CustumComponent implements OnInit, OnDestroy {

  constructor(private router: Router,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'paddremove');
  }

  ngOnDestroy(): void {
    // Remove 'paddremove' class from the body element when CustumComponent is destroyed
    this.renderer.removeClass(document.body, 'paddremove');
  }

  navigateToComponent() {
    this.router.navigateByUrl('/about-section', { skipLocationChange: true });
  }
}
