import { Injectable } from '@angular/core';
import { Router } from '../../../node_modules/@angular/router';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/map';
import { parse } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class Customvalidation {
  constructor(private router: Router) {

  }
  public loginroute(errorms:any) {
    if (errorms == 403) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }
    return true;

  }
  /*
Author      - Naveen Tehlan
CompanyName - P2pEdge
Description - Validation js file used to write all basic validation for MasterData.html related in this file and we can use this file for other html form or file .
CreatedOn   - 06-August-2019
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/

  public calling(Vtype:any, ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {

    let result = this[Vtype](ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);

    return result;
  }

  /*
  Author      - Naveen Tehlan
  Description - ValidateDate function used to validate input string is valid date format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  public ValidateDate(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + coltype.toLowerCase() + ' for column ' + columnName + ' (dd/mm/yyyy)';
    let currVal = ColumnValue;
    if (currVal == '')
      return [false, msg];

    //Declare Regex  
    let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
    let dtArray = currVal.match(rxDatePattern); // is format OK?

    if (dtArray == null)
      return [false, msg];

    ////Checks for mm/dd/yyyy format.
    //dtMonth = dtArray[1];
    //dtDay = dtArray[3];
    //dtYear = dtArray[5];

    //Checks for dd/mm/yyyy format.
    let dtDay = dtArray[1];
    let dtMonth = dtArray[3];
    let dtYear = dtArray[5];

    if (dtMonth < 1 || dtMonth > 12)
      return [false, msg];
    else if (dtDay < 1 || dtDay > 31)
      return [false, msg];
    else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
      return [false, msg];
    else if (dtMonth == 2) {
      let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
      if (dtDay > 29 || (dtDay == 29 && !isleap))
        return [false, msg];
    }
    return [true, msg];
  }
  /*
  Author      - Naveen Tehlan
  Description - validateEmail function used to validate input string is valid validateEmail format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  public validateEmail(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid email for ' + columnName + '.';
    //let filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    let filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (filter.test(ColumnValue)) {
      return [true, msg];
    }

    else {

      return [false, msg];
    }
  }

  /*
 Author      - Naveen Tehlan
 Description - validateSize function used to validate input string length is within given column size length or not.
 CreatedOn   - 08-August-2019
 ModifiedBy  - 
 ModifiedOn  - 
 Purpose     -

 */
  public validateSize(ColumnSize:any, ColumnValueLen:any) {
    if (Number(ColumnSize) == -1) {
      return true;
    }
    else if (Number(ColumnValueLen) == -1) {
      if (Number(ColumnSize) == -1 && Number(ColumnValueLen) == -1) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (Number(ColumnValueLen) <= Number(ColumnSize)) {
      return true;
    }

    else {

      return false;
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validateRequired function used to validate for required if sting empty then show alert.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  public validateRequired(ColumnValue:any) {

    if (ColumnValue == "" || ColumnValue == "false") {
      return false;
    }

    else {

      return true;
    }
  }

  public validateRequiredtest(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {

    if (ColumnValue == "" || ColumnValue == "false") {
      return [false, "Fail"];
    }

    else {

      return [true, "Pass"];
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validateDecimal function used to validate input string is valid decimal format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  public validateDecimal(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + coltype.toLowerCase() + ' for column ' + columnName;
    let RE = /^\d*(\.\d{1})?\d{0,1}$/;
    if (RE.test(ColumnValue)) {
      return [true, msg];
    } else {
      return [false, msg];
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validatenumeric function used to validate input string is valid numeric format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  public validatenumeric(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + coltype.toLowerCase() + ' for column ' + columnName;
    let RE = /^[0-9]*$/;
    if (RE.test(ColumnValue)) {
      return [true, msg];
    } else {
      return ["false", msg];
    }
  }

  /*
 Author      - Naveen Tehlan
 Description - validateSpecialChracter function used to validate input string has only some specfic special character or not.
 CreatedOn   - 06-August-2019
 ModifiedBy  - 
 ModifiedOn  - 
 Purpose     -

 */

  public validateSpecialCharacter(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Value contains illegal characters in ' + columnName;
    //let RE = /^[a-zA-Z0-9-,'/?.()%& ]*$/;
    //let RE = /^(?!.*([A-Za-z0-9 -,'/?.()%& ])\1{2})(?=.*[a-z \d A-Z])[A-Za-z0-9 ,'\/-?.()%& ]+$/;
    let RE = /^(?!.*([-,'/?.()%&+])\1{1})(?=.*[a-z \d A-Z])[A-Za-z0-9 ,'\/\-?.()%&+ ]+$/;
    if (RE.test(ColumnValue)) {
      return [true, msg];
    } else {
      return [false, msg];
    }
  }

  /*
   Author      - Naveen Tehlan
   Description - validatePhone function used to validate input string is valid Mobile number with fix 10 digit format or not.
   CreatedOn   - 08-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -

   */

  public validatePhone(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid phone/mobile number for ' + columnName + '.';
    let a = ColumnValue;
    if (a[0] == '0') {

      return [false, msg];
    }
    else {
      let filter = /^\d{10}$/;
      if (filter.test(a)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validatealphanumeric function used to validate input string has  allow alpha numeric with space and "_-" .
  CreatedOn   - 03-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validatealphanumeric(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^([a-zA-Z0-9 _-]+)$/;
    if (filter.test(a)) {
      return [true, msg];
    }
    else {
      return [false, msg];
    }

  }

  /*
  Author      - Naveen Tehlan
  Description - validatealphanumericwithoutspace function used to validate input string has  allow alpha numeric without spcae and with "_-" .
  CreatedOn   - 03-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validatealphanumericwithoutspace(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^([a-zA-Z0-9_-]+)$/;
    if (filter.test(a)) {
      return [true, msg];
    }
    else {
      return [false, msg];
    }

  }

  /*
  Author      - Naveen Tehlan
  Description - validateAadharCard function used to validate input string has only Aadhar Card String .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateAadharCard(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid Aadhar Card for ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^\d{12}$/;
    let filtercode = /^\d{16}$/;

    if (filter.test(a)) {

      return [true, msg];
    }
    else if (filtercode.test(a)) {
      return [true, msg];
    }
    else {
      return [false, msg];
    }

  }

  /*
  Author      - Naveen Tehlan
  Description - validatePANCard function used to validate input string has only PAN Card String .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validatePANCard(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid PAN Card for ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
    let filtercode = /^([C,P,H,F,A,T,B,L,J,G])$/;
    let a_code_Check = a.substring(3, 4);
    if (filter.test(a)) {
      if (filtercode.test(a_code_Check)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }

    }

    else {
      return [false, msg];
    }

  }

  /*
  Author      - Naveen Tehlan
  public validatevehiclenumber(ColumnValue:any,colsize:any,coltype:any,contType:any,columnName) {
  Description - validateOnlyAlphabets function used to validate input string has only  vehicle number.
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validatevehiclenumber(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid vehicle number for ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^[a-z]{2}( |-)\d{2}(?: |,)(?:[a-z\d]{1,2}[a-z])\1\d{4}$/;
    if (filter.test(a)) {
      return [true, msg];
    }
    else {
      return [false, msg];
    }

  }

  /*
  Author      - Naveen Tehlan
  Description - validateOnlyAlphabets function used to validate input string has only  allow alpha numeric with spcae .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateOnlyAlphabets(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg = 'Please enter valid ' + columnName + '.';
    let a = ColumnValue;

    let filter = /^([a-zA-Z _-]+)$/;
    if (filter.test(a)) {
      return [true, msg];
    }
    else {
      return [false, msg];
    }

  }
  /*
  Author      - Naveen Tehlan
  Description - validateOnlyPastDates function used to validate input string has only  Past date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateOnlyPastDates(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    
    let msg;
    let currentDate;
    let RangeNewDate;
    let RangeNewDateString;
    let result = this.ValidateDate(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {
      if (RangeString == "" || RangeString.indexOf("+") > -1) {
        RangeNewDateString = "0";
      }
      else {
        RangeNewDateString = RangeString;
      }

      if (CompareColumnName.toLowerCase() == "today") {
        currentDate = new Date();
        RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString))
        msg = 'Future and Today Date not allowed for ' + columnName + '.';
      }
      else {
        let aCU = CompareColumnValue;
        let IdateCU;
        if (aCU.indexOf("/") > -1) {
          IdateCU = aCU.split('/');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
        }
        else if (aCU.indexOf("-") > -1) {
          IdateCU = aCU.split('-');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
        }


        RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString))

        msg = columnName + ' Should be less than ' + CompareColumnName + '.';
      }
      currentDate?.setHours(0);
      currentDate?.setMinutes(0);
      currentDate?.setSeconds(0);
      currentDate?.setMilliseconds(0);
      RangeNewDate?.setHours(0);
      RangeNewDate?.setMinutes(0);
      RangeNewDate?.setSeconds(0);
      RangeNewDate?.setMilliseconds(0);
      let a = ColumnValue;
      let IdateFinal;
      if (a.indexOf("-") > -1) {
        let Idate = a.split('-');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
      }
      else if (a.indexOf("/") > -1) {
        let Idate = a.split('/');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
      }
      if ((IdateFinal && currentDate)&&(IdateFinal.getTime() < currentDate.getTime() && IdateFinal.getTime() < RangeNewDate.getTime())) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validateOnlyFutureDates function used to validate input string has only  Future date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateOnlyFutureDates(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let RangeNewDate;
    let RangeNewDateString;
    let result = this.ValidateDate(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {
      if (RangeString == "" || RangeString.indexOf("-") > -1) {
        RangeNewDateString = "0";
      }
      else {
        RangeNewDateString = RangeString;
      }

      if (CompareColumnName.toLowerCase() == "today") {
        currentDate = new Date();
        RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString))
        msg = 'Today and Past Date not allowed for ' + columnName + '.';
      }
      else {
        let aCU = CompareColumnValue;
        let IdateCU;
        if (aCU.indexOf("/") > -1) {
          IdateCU = aCU.split('/');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);

        }
        else if (aCU.indexOf("-") > -1) {
          IdateCU = aCU.split('-');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
        }
        RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString))

        msg = columnName + ' Should be greater than ' + CompareColumnName + '.';
      }
      currentDate?.setHours(0);
      currentDate?.setMinutes(0);
      currentDate?.setSeconds(0);
      currentDate?.setMilliseconds(0);
      RangeNewDate.setHours(0);
      RangeNewDate.setMinutes(0);
      RangeNewDate.setSeconds(0);
      RangeNewDate.setMilliseconds(0);
      let a = ColumnValue;
      let IdateFinal;
      if (a.indexOf("-") > -1) {
        let Idate = a.split('-');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
      }
      else if (a.indexOf("/") > -1) {
        let Idate = a.split('/');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
      }
      if ((IdateFinal && currentDate)&&(IdateFinal.getTime() > currentDate.getTime() && IdateFinal.getTime() > RangeNewDate.getTime())) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateOnlyTodayDate function used to validate input string has only  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateOnlyTodayDate(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.ValidateDate(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      if (CompareColumnName.toLowerCase() == "today") {
        currentDate = new Date();
        msg = 'Only Today date allowed for ' + columnName + '.';
      }
      else {
        let aCU = CompareColumnValue;
        let IdateCU;
        if (aCU.indexOf("/") > -1) {
          IdateCU = aCU.split('/');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
        }
        else if (aCU.indexOf("-") > -1) {
          IdateCU = aCU.split('-');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
        }

        msg = columnName + ' Should be same as ' + CompareColumnName + '.';
      }
      currentDate?.setHours(0);
      currentDate?.setMinutes(0);
      currentDate?.setSeconds(0);
      currentDate?.setMilliseconds(0);
      let a = ColumnValue;
      let IdateFinal;
      if (a.indexOf("-") > -1) {
        let Idate = a.split('-');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
      }
      else if (a.indexOf("/") > -1) {
        let Idate = a.split('/');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
      }
      if ((IdateFinal && currentDate)&&(IdateFinal.getTime() == currentDate.getTime())) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateCurrentFutureDate function used to validate input string has only future and  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateCurrentFutureDates(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let RangeNewDate;
    let RangeNewDateString;
    let result = this.ValidateDate(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {
      if (RangeString == "" || RangeString.indexOf("-") > -1) {
        RangeNewDateString = "0";
      }
      else {
        RangeNewDateString = RangeString;
      }

      if (CompareColumnName.toLowerCase() == "today") {
        currentDate = new Date();
        RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString));
        msg = 'Past Date not allowed for ' + columnName + '.';
      }
      else {
        let aCU = CompareColumnValue;
        let IdateCU;
        if (aCU.indexOf("/") > -1) {
          IdateCU = aCU.split('/');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
        }
        else if (aCU.indexOf("-") > -1) {
          IdateCU = aCU.split('-');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
        }
        RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString));
        msg = columnName + ' Should not be less than ' + CompareColumnName + '.';
      }
      currentDate?.setHours(0);
      currentDate?.setMinutes(0);
      currentDate?.setSeconds(0);
      currentDate?.setMilliseconds(0);
      RangeNewDate.setHours(0);
      RangeNewDate.setMinutes(0);
      RangeNewDate.setSeconds(0);
      RangeNewDate.setMilliseconds(0);
      let a = ColumnValue;
      let IdateFinal;
      if (a.indexOf("-") > -1) {
        let Idate = a.split('-');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
      }
      else if (a.indexOf("/") > -1) {
        let Idate = a.split('/');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
      }
      if ((IdateFinal && currentDate)&&(IdateFinal.getTime() >= currentDate.getTime() && IdateFinal.getTime() >= RangeNewDate.getTime())) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateCurrentPastDate function used to validate input string has only Past and  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateCurrentPastDates(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let RangeNewDate;
    let RangeNewDateString;
    let result = this.ValidateDate(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {
      if (RangeString == "" || RangeString.indexOf("+") > -1) {
        RangeNewDateString = "0";
      }
      else {
        RangeNewDateString = RangeString;
      }

      if (CompareColumnName.toLowerCase() == "today") {
        currentDate = new Date();
        RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString));
        msg = 'Future Date not allowed for ' + columnName + '.';
      }
      else {
        let aCU = CompareColumnValue;
        let IdateCU;
        if (aCU.indexOf("/") > -1) {
          IdateCU = aCU.split('/');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
        }
        else if (aCU.indexOf("-") > -1) {
          IdateCU = aCU.split('-');
          let dtDayCU = IdateCU[0];
          let dtMonthCU = IdateCU[1];
          let dtYearCU = IdateCU[2];
          currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
        }
        RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString));
        msg = columnName + ' Should not be greater than ' + CompareColumnName + '.';
      }
      currentDate?.setHours(0);
      currentDate?.setMinutes(0);
      currentDate?.setSeconds(0);
      currentDate?.setMilliseconds(0);
      RangeNewDate.setHours(0);
      RangeNewDate.setMinutes(0);
      RangeNewDate.setSeconds(0);
      RangeNewDate.setMilliseconds(0);
      let a = ColumnValue;
      let IdateFinal;
      if (a.indexOf("-") > -1) {
        let Idate = a.split('-');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
      }
      else if (a.indexOf("/") > -1) {
        let Idate = a.split('/');
        let dtDay = Idate[0];
        let dtMonth = Idate[1];
        let dtYear = Idate[2];
        IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
      }

      if ((IdateFinal && currentDate)&&(IdateFinal.getTime() <= currentDate.getTime() && IdateFinal.getTime() <= currentDate.getTime())) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateGreaterDecimalValues function used to validate input should be greater than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateGreaterDecimalValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should be greater than ' + CompareColumnName + '.';

      if (parseFloat(CompareColumnValue) < parseFloat(ColumnValue)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateLessDecimalValues function used to validate input should be less than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateLessDecimalValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should be less than ' + CompareColumnName + '.';

      if (parseFloat(CompareColumnValue) > parseFloat(ColumnValue)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateEqualDecimalValues function used to validate input should be equal as other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateEqualDecimalValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should be equal to ' + CompareColumnName + '.';

      if (parseFloat(CompareColumnValue) == parseFloat(ColumnValue)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateGreaterEqualDecimalValues function used to validate input should be greater/equal than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateGreaterEqualDecimalValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should not be less than ' + CompareColumnName + '.';

      if (parseFloat(CompareColumnValue) <= parseFloat(ColumnValue)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateLessEqualDecimalValues function used to validate input should not be greater than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateLessEqualDecimalValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should not be greater than ' + CompareColumnName + '.';

      if (parseFloat(CompareColumnValue) >= parseFloat(ColumnValue)) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateRangeValues function used to validate input range .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  public validateRangeValues(ColumnValue:any, colsize:any, coltype:any, contType:any, columnName:any, CompareColumnValue:any, CompareColumnName:any, RangeString:any) {
    let msg;
    let currentDate;
    let result = this.validateDecimal(ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
    if (result[0] == false) {
      msg = result[1].toString();
      return [false, msg];
    }
    else {

      msg = columnName + ' Should be lies between ' + RangeString + '.';
      let rangeValue = RangeString.split('-');

      if (parseFloat(ColumnValue) >= parseFloat(rangeValue[0]) && parseFloat(ColumnValue) <= parseFloat(rangeValue[1])) {
        return [true, msg];
      }
      else {
        return [false, msg];
      }
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - addMonths function used to add months in given date .
  CreatedOn   - 27-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */
  public addMonths(dm:any, m:any) {
    let d = new Date(dm);
    let years = Math.floor(m / 12);
    let months = m - (years * 12);
    if (years) d.setFullYear(d.getFullYear() + years);
    if (months) d.setMonth(d.getMonth() + months);
    return d;
  }

}