<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="nav-link">Update Footer Details</a>
        <ng-template ngbNavContent [formGroup]="footerUpdateForm">
            <div>
                <div >
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <label for="address">Address</label>
                            <input type="text" class="form-control" formControlName="address" placeholder="address">
                        </div>
                        <div class="col-md-6">
                            <label for="addressline2">
                                <Address></Address>
                            </label>
                            <input type="text" class="form-control" formControlName="addressline2"
                                placeholder="addressline2">
                        </div>
                        <div class="col-md-6">
                            <label for="addresslastline">Pin code</label>
                            <input type="text" class="form-control" formControlName="addresslastline"
                                placeholder="addresslastline">
                        </div>
                        <div class="col-md-6">
                            <label for="emailIdsDiv">Email</label>
                            <input type="text" class="form-control" formControlName="emailIdsDiv"
                                placeholder="emailIdsDiv">
                        </div>
                        <div class="col-md-6">
                            <label for="phonenumber">Phone Number</label>
                            <input type="text" class="form-control" formControlName="phonenumber"
                                placeholder="phonenumber">
                        </div>
                        <div class="col-md-6">
                            <label for="botomLine">Copy right</label>
                            <input type="text" class="form-control" formControlName="botomLine" placeholder="botomLine">
                        </div>
                        <div class="col-md-12">
                            <label for="footerdescription">Description</label>
                            <textarea type="text" class="form-control" formControlName="footerdescription"
                                placeholder="footerdescription"></textarea>
                        </div>
                    </div>
                </div>

                <div class="Submitbtn-content mt-3  ">
                    <!-- <button type="button" class="btn btn-secondry" (click)="onClickResetFrom()">Reset</button> -->
                    <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                        (click)="OnClickSubmit()">Update</button>
                </div>
            </div>
        </ng-template>
    </li>

    <!-- Tab for View Detail -->
    <li [ngbNavItem]="2">
        <a ngbNavLink class="nav-link">Update Footer Links</a>
        <ng-template ngbNavContent>
             <!-- <div class="container-fluid">
                <div class="row justify-content-center">
                     <div class=" p-0">
                         <div class="table-responsive main-table-wrappr">
                             <table class="table row-border hover w-100 ">
                                 <thead>
                                     <tr>
 
                                         <th scope="col" resizable>S.No.</th>
                                         <th scope="col" resizable>Image</th>
                                         <th scope="col" resizable>Url Link</th>
                                         <th scope="col">Action</th>
                                     </tr>
 
                                 </thead>
 
                                 <tbody id="EditTableBody">
 
                                     <tr *ngFor="let data of footerdata?.socialmedia ; let sr = index">
                                         <td>{{sr+1}}</td>
 
                                         <td>
                                             <img [src]="data.socialImgurl" style="width: 10%;filter: invert(1);" alt="">                                           
                                         </td>
                                         <td>
                                             <div >{{data.socialLink}}</div>
                                         </td>
                                         
                                        
                                         <td style="width: 100px;">
                                             <a  href="javascript:void(0)" (click)="onClickEditForm(data)" ngbTooltip="Edit Record" container="wrapper" placement="left" ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                         </td>
                                     </tr>
 
                                 </tbody>
                             </table>
                         </div>
                     </div>
                 </div> 
             </div>  -->

             <app-frontend-setting-table [tableCols] ="tableHead" [DetailtableRowData]="footerdataArray" (customActionDetail)="onClickEditForm($event)" ></app-frontend-setting-table>
        </ng-template>
    </li>
</ul>

<!-- Tab content container -->
<div [ngbNavOutlet]="nav"></div>