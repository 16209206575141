<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/bg1100.jpg" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Explore Our Gallery</p>
                <h1>Gallery</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">gallery</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="gallerybox" data-aos="fade-left">
    <div class="gallery_wrapper container">
        <div class="tabs">
            <ul class="tab-group">
              <li *ngFor="let tab of tabs; let i = index" class="tab" [class.active]="tab.isactive" (click)="changeTab(i)" >
                {{ tab.tabName }}
              </li>
            </ul>
          
            <div class="tab-content-group">
              <div class="tab-content">
                <div class="photogallerygrid" *ngFor="let img of activeTabData" >
                  <img [src]="img.imgurl" data-fancybox="gallery" alt="">
                </div>
              </div>
            </div>
          </div>
    </div>
</div>