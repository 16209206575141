import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { EmailService } from '../service/email.service';
import { ToastrService } from 'ngx-toastr';
import { IcontactUsmail } from '../interfaceModul/allusedModel';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-generic-contact-page',
  templateUrl: './public-generic-contact-page.component.html',
  styleUrls: ['./public-generic-contact-page.component.css']
})
export class PublicGenericContactPageComponent implements OnInit {
  queryPostedShowMsg: boolean = false;
  isPageReset: boolean = false;
  FormData!: FormGroup;
  isCaptchVerified: boolean = false;
  contactlist: any;
  ContactListFlag: boolean = false;
  @Input() contactClass: string = "contactcontainer";
  seotag: any;

  constructor(
    private builder: FormBuilder,
    private emailService: EmailService,
    private toaster: ToastrService,
    private commonfunction:Commonfunction
  ) { 
       
  }

  ngOnInit(): void {
    this.FormData = this.builder.group({
      Fullname: new FormControl('', [Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100)]),
      Email: new FormControl('', [Validators.required, Validators.email,
      Validators.minLength(1),
      Validators.maxLength(100)]),
      Subject: new FormControl('', [Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100)]),
      Message: new FormControl('', [
        Validators.minLength(1),
        Validators.maxLength(1500)])
    })
  }

  
  onCaptchVerification(verificationResult: boolean) {

    this.isCaptchVerified = verificationResult;

  }

  isFormDisabled() {
    if (this.FormData.valid && this.isCaptchVerified)
      return false;
    else
      return true;
  }


  get Fullname() {
    return this.FormData.get('Fullname')!;
  }

  get Email() {
    return this.FormData.get('Email')!;
  }

  get Subject() {
    return this.FormData.get('Subject')!;
  }

  get Message() {
    return this.FormData.get('Message')!;
  }



  
  onSubmit(FormData: FormGroup) {
    this.isCaptchVerified = false;
    this.queryPostedShowMsg = true;
    this.FormData.disable();
    this.isPageReset = true;

    this.emailService.sendEmail(FormData).subscribe(
      (response: any) => {
        this.queryPostedShowMsg = false;
        this.FormData.reset();
        this.FormData.enable();
        this.toaster.success('Thank you for contacting us we will get back to you shortly', 'Query Sent Successfully!');
      },
      (error: any) => {
        this.isPageReset = true;
        this.FormData.enable();
        this.queryPostedShowMsg = false;
        this.toaster.error('Please try again later!', 'Query not Sent!');
        console.error('Error sending data:', error);
      }
    );


    if (this.FormData) {

      const _maildata: IcontactUsmail = {} as IcontactUsmail;
      _maildata.Email = this.FormData.get("Email")?.value;
      _maildata.Message = this.FormData.get("Message")?.value;
      _maildata.Subject = this.FormData.get("Fullname")?.value + '_' + this.FormData.get("Subject")?.value;
      _maildata.type = "Contact_Us";
      _maildata.createdBy = "System";

      const data = {
        email: _maildata.Email,
        subject: _maildata.Subject,
        type: 1,
        body: _maildata.Message,
        isRead: false,
        isReplied: false,
        isDeleted: false,
        createdDate: new Date().toISOString(),
        modifiedDate: new Date().toISOString(),
        createdBy: _maildata.createdBy,
        modifiedBy: _maildata.createdBy
      };




    } else {
      console.log('Form is invalid');
    }
  }


}
