import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
//import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
//import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelworkBook from "exceljs";
// import jsPDF  from 'jspdf';
//import * as jspdf from 'jspdf';
//import 'jspdf-autotable';
// import 'jspdf-autotable'
// import autoTable from 'jspdf-autotable';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import { Packer } from 'docx';
import { DocumentCreator } from '../shared/reportWord';
import { Commonfunction} from '../shared/commonutility'

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  constructor(private com: Commonfunction) { }
  

  public exportAsExcelFile(columns: any[],json: any[],excelFileName: string): void {
    

    // Create workbook and worksheet
    const workbook: ExcelworkBook.Workbook = new ExcelworkBook.Workbook();
    const worksheet = workbook.addWorksheet(excelFileName);

    // Add Header Row
    worksheet.columns = columns;
    const headerRow = worksheet.getRow(1);

// Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'0479cc'},
  };
  cell.font  = {
    bold: true,
    size: 14,
    color: {argb:'ffffff'}
  };
  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
});
    // Add Data and Conditional Formatting row by row
    // json.forEach((x: any) => {
    //    worksheet.addRow(x);
    // });
    worksheet.addRows(json); // insert all rows once
   
   // Generate Excel File with given name
   workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: EXCEL_TYPE});
  
    FileSaver.saveAs(blob, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
   });

  }

//   public exportAsExcelFile(columns: any[],json: any[],excelFileName: string): void {
// let objectMaxLength:any = [];
// let wscols = [];
// let headers = [];
//     for (let i = 0; i < json.length; i++) {
//       let value = <any>Object.values(json[i]);
//       for (let j = 0; j < value.length; j++) {
//         if (typeof value[j] == "number") {
//           objectMaxLength[j] = 10;
//         } else {
//           objectMaxLength[j] =
//             objectMaxLength[j] >= value[j].length
//               ? objectMaxLength[j]+1
//               : value[j].length+1;
//         }
       
//       }
//       let key = <any>Object.keys(json[i]);
//       for (let j = 0; j < key.length; j++) {
//         objectMaxLength[j] =
//           objectMaxLength[j] >= key[j].length
//             ? objectMaxLength[j]+1
//             : key[j].length+1;
            
//       }
     
//     }
//     for (let j = 0; j < objectMaxLength.length; j++) {
//       wscols.push({ width: objectMaxLength[j] });
//     }
//     let keyz = <any>Object.keys(json[0]);
//     for (let j = 0; j < keyz.length; j++) {
//     let colRow  = columns.filter((x:any)=> x['dataKey']== keyz[j]);
//     if(colRow.length >0)
//     headers.push(colRow[0]['header']);
// else
//           headers.push(keyz[j]);
//     }
//     let Heading:any =[];
//     Heading.push(headers)
//     console.log(wscols);
//     console.log(headers);
    
//         // creating sheet and adding data from 2nd row of column A.
//       // Leaving first row to add Heading
//       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });

//       // adding heading to the first row of the created sheet.
//       // sheet already have contents from above statement.
//       XLSX.utils.sheet_add_json(worksheet,json,{skipHeader:true , origin: 'A2'});
//      // XLSX.utils.sheet_add_aoa(myworksheet, Heading);
//       XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1'  });
//       //One Array#reduce call over rows can calculate the maximum width:
//       // const max_width = json.reduce((w, r) => Math.max(w, r.name.length), 10);
//       // worksheet["!cols"] = [ { wch: max_width } ];
//     worksheet['!cols'] = wscols;
   
//     worksheet['A1'].s =  { // background color
//       patternType: "solid",
//       fgColor: { rgb: "b2b2b2" },
//       bgColor: { rgb: "b2b2b2" } 
//   };
//     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: [excelFileName] };
//     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     this.saveAsExcelFile(excelBuffer, excelFileName);

//   }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  public exportAsPDFFile(columns: any[], data: any[], fileName: string) {
    
    // var doc = new jsPDF('p', 'pt');   
    // (doc as any).autoTable(columns, data, { theme: 'plain' });
    // doc.save(fileName + '_pdf_' + new Date().getTime() + '.pdf');
// var doc = new jspdf("p", "pt", "a4");  // For Portrait

// var doc = new jspdf('l', 'pt', "a4");  // For landscape
//code
    const doc = new jsPDF("p");
    // doc.autoTable({ html: '#my-table' })
    // doc.autoTable(columns, data, { theme: 'plain' });
    autoTable(doc, ({
      columns: columns,
      body:data,
      theme: 'striped',
      pageBreak: 'auto',
      rowPageBreak: 'auto',
      horizontalPageBreak: true,
     // columnStyles: {0: { overflow: 'linebreak'}}
    }))
    doc.save(fileName + '_pdf_' + new Date().getTime() + '.pdf');
  }



}