<div class="edit-modal-form">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Footer Social Media Links</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateEditMasterForm">
        <div class="row">
            <div class="col-md-12 my-3">
                <div class="ImageShowBox">
                    <label class="ImageShow">
                        <div class="img_box">
                            <img style="filter: invert(1);" [src]="footerlink.socialImgurl" alt="Image Preview">
                        </div>
                        <div class="hoverImg">
                            <div class="hover_content">
                                <img style="width: 30px; display: block;" class="hoverImgIcon"
                                    src="assets\img\Mask group1.png" alt="Upload Icon">
                                <span class="text-light">Upload</span>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="text-center"><span class="imgError">* only jpg / png / webp</span></div>
            </div>
            <div class="titlecontainer">
                <label for="home_carousel">Link</label>
                <input class="form-control" id="home_carousel" placeholder="Enter Headline" type="text"
                    formControlName="link">
                <!-- <angular-editor *ngIf="isTextEditorEnabled" formControlName="title" [config]="editorConfig"></angular-editor> -->
            </div>

            <div class="Submitbtn-content mt-3  ">
                <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2"
                    (click)="OnClickSubmit()">Update</button>
            </div>
        </div>
    </div>
</div>
