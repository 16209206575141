<!-- start Alert Modal -->
<div id="AlertDialogModal" class="new-modal-box" [class]="AlertDialogType ">
    <div role="document" class="new-modal">
        <div class="modal-content rowDataTable">
            <div class="modal-body rowdata">
                <div class="table_popup">
                    <div class="table_popup_warpper">
                        <div class="table_popup_header">
                            <div class="headline_tbpop">
                                <div class="col">
                                    <div>[{{selectedRowData.tbleName}}]Details for Column Name(Data Type):- {{selectedRowData.columnName}}({{selectedRowData.dataType}})</div>
                                    <div *ngIf="selectedRowData.dataType != selectedRowData.originalDataType">Current table Data Type:-{{selectedRowData.originalDataType}}</div>
                                </div>
                            </div>
                            <div class="closeBtn_tbpop" (click)="onCloseAlertModal()">
                                <img src="assets/images/close.svg" alt="">
                            </div>
                        </div>

                        <div class="table_popup_content">
                            <div class="tablepopup_wrepper">

                                <form [formGroup]="masterReactiveForm" (ngSubmit)="onSaveAlertModal()">
                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnAlias" class="form-label requiredSign">Column Alias</label>
                                                <input type="text" class="form-control" id="columnAlias" formControlName="columnAliasName"
                                                [class.is-invalid]="f.columnAliasName.invalid && (f.columnAliasName.dirty || f.columnAliasName.touched)">
                                                <div *ngIf="f.columnAliasName.invalid && (f.columnAliasName.dirty || f.columnAliasName.touched)" class="invalid-feedback">
                                                    <div *ngIf="f.columnAliasName.errors?.required">Column Alias Name is required</div>
                                                    <div *ngIf="f.columnAliasName.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnAliasName.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnSequence" class="form-label requiredSign">Column Sequence</label>
                                                <input type="number" class="form-control" id="columnSequence" formControlName="columnSequence"
                                                [class.is-invalid]="f.columnSequence.invalid && (f.columnSequence.dirty || f.columnSequence.touched)">
                                                <div *ngIf="f.columnSequence.invalid && (f.columnSequence.dirty || f.columnSequence.touched)" class="invalid-feedback">
                                                    <div *ngIf="f.columnSequence.errors?.required">Column Sequence is required</div>
                                                    <div *ngIf="f.columnSequence.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnSequence.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnClass" class="form-label">Column Class</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleMultiClassColumn}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                                <span  (click)="doCopy(exampleMultiClassColumn)" class="ml-2"><i class="fa fa-clipboard"></i></span>
                                                <input type="text" class="form-control" id="columnClass" formControlName="controlClass"
                                                [class.is-invalid]="f.controlClass.invalid && (f.controlClass.dirty || f.controlClass.touched)">
                                                <div *ngIf="f.controlClass.invalid && (f.controlClass.dirty || f.controlClass.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.controlClass.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.controlClass.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="savedColumnLength" class="form-label">Column Length</label>
                                                <input type="text" class="form-control" id="savedColumnLength" formControlName="originalColumnLength">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="currentColumnLength" class="form-label">Current Column
                                                    Length</label>
                                                <input type="text" class="form-control" id="currentColumnLength" formControlName="columnLength"
                                                [class.is-invalid]="f.columnLength.invalid && (f.columnLength.dirty || f.columnLength.touched)">
                                                <div *ngIf="f.columnLength.invalid && (f.columnLength.dirty || f.columnLength.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.columnLength.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnLength.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnEvent" class="form-label">Column Event</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleCustomColumnEvent}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                               
                                                <input type="text" class="form-control" id="columnEvent" formControlName="columnEventDefination"
                                                [class.is-invalid]="f.columnEventDefination.invalid && (f.columnEventDefination.dirty || f.columnEventDefination.touched)">
                                                <div *ngIf="f.columnEventDefination.invalid && (f.columnEventDefination.dirty || f.columnEventDefination.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.columnEventDefination.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnEventDefination.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="DrpValueFillType" class="form-label">Value Fill Type</label>                                                
                                                <angular2-multiselect id="DrpValueFillType" name="DrpValueFillType" formControlName="valueFillType" [data]="dropdownListpageloadValueFillTypedrp"
                                                 [(ngModel)]="selectedItemspageloadValueFillTypedrp" [settings]="dropdownSettings">
                                                 </angular2-multiselect>
                                               
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="childTableName" class="form-label">Child Table Name</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleChildTables}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                                
                                                <input type="text" class="form-control" id="childTableName" formControlName="dropDownChildtable"
                                                [class.is-invalid]="f.dropDownChildtable.invalid && (f.dropDownChildtable.dirty || f.dropDownChildtable.touched)">
                                                <div *ngIf="f.dropDownChildtable.invalid && (f.dropDownChildtable.dirty || f.dropDownChildtable.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.dropDownChildtable.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.dropDownChildtable.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="dropDownQuery" class="form-label">DropDownQuery</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleDropdownQuery}}" container="wrapper"
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                                <textarea type="text" class="form-control" id="dropDownQuery" formControlName="dropDownQuery"
                                                [class.is-invalid]="f.dropDownQuery.invalid && (f.dropDownQuery.dirty || f.dropDownQuery.touched)"></textarea>
                                                
                                                <div *ngIf="f.dropDownQuery.invalid && (f.dropDownQuery.dirty || f.dropDownQuery.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.dropDownQuery.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.dropDownQuery.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="DrpControls" class="form-label requiredSign">Select Control</label>                                                
                                                <angular2-multiselect id="DrpControls" name="DrpControls" [data]="dropdownListpageloadControldrp" formControlName="controlType"
                                                 [(ngModel)]="selectedItemspageloadControldrp" [settings]="dropdownSettings" 
                                                 [class.is-invalid]="f.controlType.invalid && (f.controlType.dirty || f.controlType.touched)">
                                                 </angular2-multiselect>
                                                 <div *ngIf="f.controlType.invalid && (f.controlType.dirty || f.controlType.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.controlType.errors?.required">Column Control is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="DrpValidationList" class="form-label">Select Validation</label>                                               
                                                <angular2-multiselect id="DrpValidationList" name="DrpValidationList" [data]="dropdownListpageloadValidationdrp" formControlName="Validation"
                                                 [(ngModel)]="selectedItemspageloadValidationdrp" [settings]="dropdownSettings">
                                                 </angular2-multiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="validationMessage" class="form-label">Validation
                                                    Message</label> 
                                                    <span  class="ml-2"
                                                ngbTooltip="{{exampleCustomValidationMsg}}" container="wrapper"
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>    
                                                <span  (click)="doCopy(exampleCustomValidationMsg)" class="ml-2"><i class="fa fa-clipboard"></i></span>                                          
                                                <textarea type="text" class="form-control" id="validationMessage" formControlName="customValidationMsg"
                                                [class.is-invalid]="f.customValidationMsg.invalid && (f.customValidationMsg.dirty || f.customValidationMsg.touched)"></textarea>
                                                
                                                <div *ngIf="f.customValidationMsg.invalid && (f.customValidationMsg.dirty || f.customValidationMsg.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.customValidationMsg.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.customValidationMsg.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="regexp" class="form-label">RegExp String</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleRegExp}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>  
                                                <span  (click)="doCopy(exampleRegExp)" class="ml-2"><i class="fa fa-clipboard"></i></span> 
                                                <textarea type="text" class="form-control" id="regexp" formControlName="regExp"
                                                [class.is-invalid]="f.regExp.invalid && (f.regExp.dirty || f.regExp.touched)"></textarea>
                                                
                                                <div *ngIf="f.regExp.invalid && (f.regExp.dirty || f.regExp.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.regExp.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.regExp.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="DrpDataExists" class="form-label">Select Data Exists</label>
                                                <angular2-multiselect id="DrpDataExists" name="DrpDataExists" [data]="dropdownListpageloadDataExistsdrp" formControlName="checkExistsDataValidationType"
                                                 [(ngModel)]="selectedItemspageloadDataExistsdrp" [settings]="dropdownSettings"
                                                 (onSelect)="onItemDataExistSelect($event)" (onDeSelect)="OnItemDataExistDeSelect($event)">
                                                 </angular2-multiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="dataExistsParams" class="form-label">Data Exists
                                                    Params</label>
                                                    <span  class="ml-2"
                                                ngbTooltip="{{exampleDataExist}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span> 
                                                <span  (click)="doCopy(exampleDataExist)" class="ml-2"><i class="fa fa-clipboard"></i></span> 
                                                <textarea type="text" class="form-control" id="dataExistsParams" formControlName="checkExistsDataValidationParams"
                                                [class.is-invalid]="f.checkExistsDataValidationParams.invalid && (f.checkExistsDataValidationParams.dirty || f.checkExistsDataValidationParams.touched)"></textarea>
                                                
                                                <div *ngIf="f.checkExistsDataValidationParams.invalid && (f.checkExistsDataValidationParams.dirty || f.checkExistsDataValidationParams.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.checkExistsDataValidationParams.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.checkExistsDataValidationParams.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="comparisonColumn" class="form-label">Comparison
                                                    Column</label>
                                                <angular2-multiselect  id="comparisonColumn" name="comparisonColumn" [data]="dropdownListCompareColumnGeneric" formControlName="compareColumnName"
                                                [(ngModel)]="selectedItemsCompareColumn" [settings]="dropdownSettings">
                                                </angular2-multiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <div>
                                                <label for="range" class="form-label">Range(min-max)</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{rangeTitle}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                                <input type="text" class="form-control" id="range" formControlName="range"
                                                [placeholder]="rangePlaceholder"
                                                [class.is-invalid]="f.range.invalid && (f.range.dirty || f.range.touched)">
                                            </div>
                                                <div *ngIf="f.range.invalid && (f.range.dirty || f.range.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.range.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.range.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnInformation" class="form-label">Column
                                                    Information</label>
                                                <input type="text" class="form-control" id="columnInformation" formControlName="columnInformation"
                                                placeholder="Plain text message."
                                                [class.is-invalid]="f.columnInformation.invalid && (f.columnInformation.dirty || f.columnInformation.touched)">
                                                
                                                <div *ngIf="f.columnInformation.invalid && (f.columnInformation.dirty || f.columnInformation.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.columnInformation.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnInformation.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="row center_class">
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnDefaultValue" class="form-label">Column Default
                                                    Value</label>
                                                    <span  class="ml-2"
                                                ngbTooltip="{{exampleDefaultValue}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>                                                 
                                                <input type="text" class="form-control" id="columnDefaultValue" formControlName="columnDefaultValue"
                                                [class.is-invalid]="f.columnDefaultValue.invalid && (f.columnDefaultValue.dirty || f.columnDefaultValue.touched)">                                                
                                                <div *ngIf="f.columnDefaultValue.invalid && (f.columnDefaultValue.dirty || f.columnDefaultValue.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.columnDefaultValue.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.columnDefaultValue.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="columnMiscellaneousType" class="form-label">Column Miscellaneous Type</label>
                                                <span  class="ml-2"
                                                ngbTooltip="{{exampleMiscellaneousType}}" container="wrapper" 
                                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span> 
                                                <span  (click)="doCopy(exampleMiscellaneousType)" class="ml-2"><i class="fa fa-clipboard"></i></span> 
                                                <textarea type="text" class="form-control" id="columnMiscellaneousType" formControlName="miscellaneousType"
                                                [class.is-invalid]="f.miscellaneousType.invalid && (f.miscellaneousType.dirty || f.miscellaneousType.touched)"></textarea>
                                                
                                                <div *ngIf="f.miscellaneousType.invalid && (f.miscellaneousType.dirty || f.miscellaneousType.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.miscellaneousType.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.miscellaneousType.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col">
                                                <label for="expressionJson" class="form-label">Expression Json</label>
                                                <textarea type="text" class="form-control" id="expressionJson" formControlName="expressionJson"
                                                [class.is-invalid]="f.expressionJson.invalid && (f.expressionJson.dirty || f.expressionJson.touched)"></textarea>
                                                
                                                <div *ngIf="f.expressionJson.invalid && (f.expressionJson.dirty || f.expressionJson.touched)" class="invalid-feedback">                                                    
                                                    <div *ngIf="f.expressionJson.errors?.minlength">Min Length not less than 2</div>
                                                    <div *ngIf="f.expressionJson.errors?.maxlength">Max Length not greater than 100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="toggle_div extraDiv">
                                        <div class="row center_class">
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Required</h5>
                                                    <span class="switch">
                                                        <input id="switch-rounded" type="checkbox" [checked]="iSNULLCheckbox" formControlName="iS_NULLABLE"/>
                                                        <label for="switch-rounded"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>View Link On</h5>
                                                    <span class="switch">
                                                        <input id="View-Link" type="checkbox" [checked]="isViewLinkCheckbox" formControlName="isViewLink"/>
                                                        <label for="View-Link"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Hide Column</h5>
                                                    <span class="switch">
                                                        <input id="Hide-Column" type="checkbox" [checked]="columnHideCheckbox" formControlName="columnHide"/>
                                                        <label for="Hide-Column"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Hide Column Create Form</h5>
                                                    <span class="switch">
                                                        <input id="Column-Form" type="checkbox" [checked]="createColumnHideCheckbox" formControlName="createColumnHide"/>
                                                        <label for="Column-Form"></label>
                                                    </span>
                                                </section>
                                            </div>
                                        </div>

                                        <div class="row center_class">
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Hide Column Update Form</h5>
                                                    <span class="switch">
                                                        <input id="Update-Form" type="checkbox" [checked]="editColumnHideCheckbox" formControlName="editColumnHide"/>
                                                        <label for="Update-Form"></label>
                                                    </span>
                                                </section>
                                            </div>

                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Edit Disabled</h5>
                                                    <span class="switch">
                                                        <input id="Edit-Disabled" type="checkbox" [checked]="isEditDisabledCheckbox" formControlName="isEditDisabled"/>
                                                        <label for="Edit-Disabled"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Column HideView</h5>
                                                    <span class="switch">
                                                        <input id="Column-HideView" type="checkbox" [checked]="columnHideViewCheckbox" formControlName="columnHideView"/>
                                                        <label for="Column-HideView"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Column HideMobileView</h5>
                                                    <span class="switch">
                                                        <input id="HideMobileView" type="checkbox" [checked]="columnHideMobileViewCheckbox" formControlName="columnHideMobileView"/>
                                                        <label for="HideMobileView"></label>
                                                    </span>
                                                </section>
                                            </div>
                                        </div>
                                        <div class="row center_class">
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Column FilterMobileView</h5>
                                                    <span class="switch">
                                                        <input id="FilterMobileView" type="checkbox" [checked]="columnFilterMobileViewCheckbox" formControlName="columnFilterMobileView"/>
                                                        <label for="FilterMobileView"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Column HideMobileForm</h5>
                                                    <span class="switch">
                                                        <input id="HideMobileForm" type="checkbox" [checked]="columnHideMobileFormCheckbox" formControlName="columnHideMobileForm"/>
                                                        <label for="HideMobileForm"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>Excel DownloadColumn</h5>
                                                    <span class="switch">
                                                        <input id="DownloadColumn" type="checkbox" [checked]="excelDownloadColumnCheckbox" formControlName="excelDownloadColumn"/>
                                                        <label for="DownloadColumn"></label>
                                                    </span>
                                                </section>
                                            </div>
                                            <div class="col-md-3">
                                                <section>
                                                    <h5>PDF DownloadColumn</h5>
                                                    <span class="switch">
                                                        <input id="PDF-DownloadColumn" type="checkbox" [checked]="pdfDownloadColumnCheckbox" formControlName="pdfDownloadColumn"/>
                                                        <label for="PDF-DownloadColumn"></label>
                                                    </span>
                                                </section>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="modal-footer m-3">
                                                <button type="button" class="btn btn-secondary closebtn-table"
                                                    (click)="onCloseAlertModal()">Close</button>
                                                <button  class="btn btn-primary"  >{{actionButtonText}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->
<!-- End Alert Modal -->