<div class="page_top_section">
    <div class="page_top_warpper">
        <div class="bg_img_box">
            <img src="assets/img/contactstop.webp" alt="">
        </div>
        <div class="overimg_box">
            <div class="container">
                <p class="headcolor m-0">Reach Out to Us</p>
                <h1>Contact Us</h1>
                <div class="breadcom">
                    <p><a routerLink="/">Home</a> > <span class="headcolor">Contact Us</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contactuspage">
    <div class="contact_warpper">
        <div class="container text-white">
            <div class="row contactmain">
                <div class="col-md-6" data-aos="fade-right">
                    <h1>{{contactUs.contactdetailstitle}}</h1>
                    <p class="my-3">{{contactUs.contactdetail}}</p>
                    <div class="addressoffice py-3">
                        <h6>{{contactUs.stateaddrssing}}</h6>
                        <div class="number d-flex">
                            <span class="me-3"><img [src]="contactUs.phnImg" width="20px" alt=""></span>
                            <p>{{contactUs.stateaddrssingno}}</p>
                        </div>
                        <div class="email d-flex">
                            <span class="me-3"><img [src]="contactUs.EmailImg" width="20px" alt=""></span>
                            <p>{{contactUs.stateaddrssingEmail}}</p>
                        </div>
                        <div class="email d-flex">
                            <span class="me-3"><img [src]="contactUs.AddressImg" width="20px" alt=""></span>
                            <p>{{contactUs.stateaddrssingAdd}}</p>
                        </div>
                    </div>
                    <div class="addressoffice">
                        <h6>{{contactUs.branchaddrssing}}</h6>
                        <div class="number d-flex">
                            <span class="me-3"><img [src]="contactUs.phnImg" width="20px" alt=""></span>
                            <p>{{contactUs.branchaddrssingno}}</p>
                        </div>
                        <div class="email d-flex">
                            <span class="me-3"><img [src]="contactUs.EmailImg" width="20px" alt=""></span>
                            <p>{{contactUs.branchaddrssingEmail}}</p>
                        </div>
                        <div class="email d-flex">
                            <span class="me-3"><img [src]="contactUs.AddressImg" width="20px" alt=""></span>
                            <p>{{contactUs.branchaddrssingAdd}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" data-aos="fade-left">
                    <div class="contactUs">
                        <div class="contact_box">
                            <h3>{{contactUs.rigthsidetxt}}</h3>
                            <p>{{contactUs.rigthsidedetails}}</p>
                            <div class="form-control-box my-4">
                                <input type="text" placeholder="Name">
                            </div>
                            <div class="form-control-box my-4">
                                <input type="text" placeholder="Email">
                            </div>
                            <div class="form-control-box my-4">
                                <input type="text" placeholder="Subject">
                            </div>
                            <div class="form-control-box my-4">
                                <textarea type="text" placeholder="Message"></textarea>
                            </div>
                            <div class="form-control-box my-4">
                                <span>
                                    <app-captcha class="w-100 capchaBoxCss" ></app-captcha>
                                </span>
                            </div>
                            <div class="form-control-box btnli my-4">
                                <button class="w-100">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mapEmb" data-aos="fade-left">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19614.187942383305!2d77.05772173395013!3d28.58264217581439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b162fd3da57%3A0x77bdacd66687262d!2sPeer%202%20peer%20Edge%20Technologies%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1728293897443!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="aboutbtn contactusform">
            <div class="container text-white">
                <div class="row">
                    <div class="col-md-6">
                        <h1>For More Info</h1>
                    </div>
                    <div class="col-md-6 text-right btnli">
                        <button>Connect Us</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>