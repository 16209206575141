import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-custum-admisson',
  templateUrl: './public-custum-admisson.component.html',
  styleUrls: ['./public-custum-admisson.component.css']
})
export class PublicCustumAdmissonComponent implements OnInit {
  Admissiondata:any;
  constructor(public commonfunction: Commonfunction) { }

  ngOnInit(): void {
    this.Admissiondata = this.commonfunction.loadJson("~/../assets/Admission.json");
  }

}
