import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormControlName, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors, AsyncValidatorFn, NgModel } from '@angular/forms'
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { async } from '../../../../node_modules/@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { GenericFormDropDownServiceService } from "../../shared/generic-form-drop-down-service.service";
import { ObjectParam, lstObjectParam, FormFinalObjectParam, lstFormFinalObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from '../../../../node_modules/angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
// import  'src/typings';
import 'jquery';
import { custumValidation } from 'src/app/shared/custum-validation';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { PreviewImageService } from '../../shared/preview-image/preview-image.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
declare var jQuery: any;
//import * as moment from 'moment';
import { asyncCustumValidation } from '../../shared/async-custum-validation';
import { GenericPrintService } from '../../shared/generic-print-service';
import { IfileTypeOutput, filetypeEnum } from '../../shared/model/fileType';
import { WorkFlowProcessServiceService } from '../../shared/work-flow-process/work-flow-process-service.service';
import { CustomActionService } from '../../shared/customAction/custom-action.service';
import { IfStmt } from '@angular/compiler';
import { AlertDialogBoxService } from '../../shared/alert-dialog-box/alert-dialog-box.service';
import { IFilterColumns, IviewFullDetails } from '../../shared/model/genericTableListViewModel';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-generic-multi-table-form',
    templateUrl: './generic-multi-table-form.component.html',
    styleUrls: ['./generic-multi-table-form.component.css']
})
export class GenericMultiTableFormComponent implements OnDestroy, OnInit {

    status: boolean = true;


    isRenderDetail: number = 0;
    activeTabId: number = 1;
    activeTabIdCreate: number = 1;
    activeTabIdEdit: number = 1;
    isWorkflowImplemented: boolean = false;
    customSearchString: string = '';
    get customSearch(): string {
        return this.customSearchString;
    }
    set customSearch(val: string) {
        this.customSearchString = val;
        this.CustomFilterusingColumn();
    }
    isChildRowEdit: boolean = false;
    workflowstep: number = 0;
    workflowStatusId: number = 0;
    SeltableID: string="";
    WFTBName: string = "P2PtblGenericWorkFlowMaster";
    SeltableIDForm: string="";
    addPermission: string = 'True';
    editPermission: string = 'True';
    activePermission: string = 'True';
    deletePermission: string = 'True';
    formTestPermission: string = 'True';
    isHistoryShowProc: string = '';
    printPermission: number = 0;
    ischeckboxshow: number = 0;
    dateFormat = 'MM-DD-YYYY';
    tooltipHeader = [];
    tooltipIsViewLink: string="";
    tooltipText: string="";
    @ViewChild(DataTableDirective)
    dtElement!: DataTableDirective;

    dtOptions: any = {
        pagingType: 'full_numbers',
        pageLength: 10,
        autoWidth: true,
        bAutoWidth: true,
        responsive: true,
        //select from drop down list to select number of records per page
        lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
        scrollY: 300,  // side bar hide on off change table side
        scrollX: true,
        bScrollCollapse: true,
        fixedHeader: {
            header: true,
            footer: true
        },
        // Declare the use of the extension in the dom parameter
        dom: 'Bfltip',
        // fixedColumns:   {
        //     leftColumns: 2
        // },
        // Configure the buttons
        buttons: ['print', 'excel']

    };
    dtOptionsDetail: any = {
        pagingType: 'full_numbers',
        pageLength: 10,
        autoWidth: true,
        bAutoWidth: true,
        responsive: true,
        //select from drop down list to select number of records per page
        lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
        scrollY: 400,// side bar hide on off change table side
        scrollX: true,
        bScrollCollapse: true,
        // bDestroy: true,
        fixedHeader: {
            header: true,
            footer: true
        },
        emptyMessage: "No data available!!",
        //   fixedColumns:   {
        //       leftColumns: 2
        //   }

    };
    viewtableHeaders: TableColumnName[] = [];
    dtTrigger: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    tableid: string="";
    Detailtableid: string="";
    DetailtableHeaderData = [];
    DetailtableRowData:any = [];
    ViewDetailChildtableHeaderData = [];
    ViewDetailChildtableRowData = [];
    viewDetailModalHeading: string = "";
    dropdownSettings = {};
    display = "none";
    displayMain = "none";
    displayMainEdit = "none";
    displayContainer = "block";
    sel: any;
    masterProductForm!: FormGroup;
    isRender: boolean = false;
    masterJsonDataMaster: any = [];
    masterJsonData: any;
    childJsonData: any;
    masterJsonDataDefaultValue = {};
    masterJsonDataDefaultValueEdit = {};
    parentFormAliasName: string="";
    childTableDataDynamicArrayList: { [kName: string]: any } = {};
    childTableDataDynamicArrayListSumColumn: { [kName: string]: any } = {};
    masterProductFormEdit!: FormGroup;
    isRenderEdit: boolean = false;
    masterJsonDataMasterEdit: any=[];
    masterJsonDataEdit: any=[];
    childJsonDataEdit: any =[];
    childTableDataDynamicArrayListEdit: { [kName: string]: any } = {};
    childTableDataDynamicArrayListSumColumnEdit: { [kName: string]: any } = {};
    parentFormAliasNameEdit: string="";
    updateRecordID: string |null="";
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    tblecount: number=0;
    public value: string[]=[];
    public current: string="";
    public selected: string="";
    public dynamicdivdata: any;
    public exampleArray: Array<{ id: string, text: string }>=[];
    dropdownList = [];
    selectedItems = [];
    bsConfig:any;
    changeDetectorRefs: ChangeDetectorRef[] = [];
    paramvaluedata: any;
    FileerrorMessage: string="";
    filesToUpload: Array<File>;
    selectedFileNames: string[] = [];
    public isLoadingData: Boolean = false;
    @ViewChild('fileUpload') fileUploadVar: any;
    filecontrolNameId: string="";
    filehiddencolumn: string="";
    filehiddencolumnCallingfrom: string="";
    filehiddenformAliasName: string="";
    uploadResult: any;
    res: Array<string>=[];
    param: string="";
    menulabel: string="";
    formlable: string="";
    childheaders: string[]=[];
    minDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() - year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    maxDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() + year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    GenericPrintDataArray: any[]=[];
    editModelHeading: string="";
    editModelCallfrom: string="";
    //   childTableDataDynamicArray:{keyName: string,keyValueArray:{}};
    dropdownListActionGroupSpdrp:any = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedRows:any = [];
    masterSelected: boolean = false;
    currentRoleString: string | undefined = '';
    currentRole: string  ="";
    userId: string| undefined  ="";
    constructor(public commonfunction: Commonfunction, public asyncCustomvalidation: asyncCustumValidation, public Customvalidation: Customvalidation,
        private userService: UserService, private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, route: ActivatedRoute,
        private spinner: NgxSpinnerService, private fb: FormBuilder, private globalAlertService: GlobalAlertService,
        private globalConfirmService: ConfirmDialogBoxService, private genericPrintService: GenericPrintService, private GlobalWorkFlowProcessService: WorkFlowProcessServiceService,
        private previewImageService: PreviewImageService, private customActionService: CustomActionService, private AlertService: AlertDialogBoxService, private genericDropDown:GenericFormDropDownServiceService) {
        //   this.childTableDataDynamicArrayList = [];
        this.FileerrorMessage = "";
        this.filesToUpload = [];
        this.selectedFileNames = [];
        this.uploadResult = "";
        this.masterJsonData = [];
        this.childJsonData = [];
        this.bsConfig = {
            dateInputFormat: 'DD-MM-YYYY',
            isAnimated: true,
            adaptivePosition: true,
            containerClass: 'theme-blue',
            minDate: this.minDate(50),
            maxDate: this.maxDate(50)
        };
        this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))? sessionStorage.getItem('currentRole')?.toString()  : "";
      this.currentRole = this.currentRoleString != null ? JSON.parse(this.currentRoleString)?.roleId.toString():"";
      this.userId= sessionStorage.getItem('userId')?.toString();
    }

    ngOnInit() {
        
        let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
        this.SeltableID = paramjs.formValue;
        this.SeltableIDForm = paramjs.formName;
        this.menulabel = paramjs.menu;
        this.formlable = paramjs.formName;
        this.Search(paramjs.formValue, paramjs.formName);
        this.onMainTab();
        this.dropdownSettings = {
            singleSelection: true,
            // text:"Select Countries",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            // primaryKey:'DrpValue',
            // labelKey:'DrpOption',
            // classes: "form-control myclass custom-class",
            classes: "global-select-box",
            disabled: false
        };
    }



    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
    }


    Search(tbname:string, formName:string) {
        this.spinner.show();
        var trdata = '';
        const that = this;
        let param = "uspAppGenericFormGetDetails|Formname=" + tbname;
        this.userService.getGenericMultiTableFormPage(param, formName, "insert").subscribe((data: any) => {
            
            this.displayMain = "block";
            let formArray = {};
            let formArrayDefaultValue = {};
            let chkAsyncGenericDbCheck: boolean = false;
            data.forEach(function (valueBase:any, indexbase:string) {
                valueBase['formData'].forEach(function (value:any, index:string) {
                    value.ChildComponentReset = filetypeEnum.No;
                    value.childComponentFormSequence = valueBase['formSequence'];
                    value.childComponentFileDelete = false;
                });
            });
            this.masterJsonDataMaster = data;
            console.log(this.masterJsonDataMaster);
            // this.addPermission = false;
            // this.editPermission = false;
            // this.activePermission = false;
            // this.deletePermission = false;
            // this.printPermission  = false;
            let columnformArray = {};
            this.addPermission = this.masterJsonDataMaster[0]['formAddPermission'];
            this.editPermission = this.masterJsonDataMaster[0]['formEditPermission'];
            this.activePermission = this.masterJsonDataMaster[0]['formActivePermission'];
            this.deletePermission = this.masterJsonDataMaster[0]['formDeletePermission'];
            this.printPermission = this.masterJsonDataMaster[0]['formPrintPermission'];
            this.formTestPermission =  this.masterJsonDataMaster[0]['formTestPermission'];
            this.isHistoryShowProc =  this.masterJsonDataMaster[0]['formisHistoryShowProc'];
            if(this.addPermission == 'True')
            this.activeTabId = 1;
            else
            this.activeTabId = 2;

            console.log('Print - ' + this.printPermission);
            this.ischeckboxshow = +this.masterJsonDataMaster[0]['formIsCheckbox'];
            if (this.masterJsonDataMaster[0]['formActionGroupList'] != null)
                this.dropdownListActionGroupSpdrp = this.masterJsonDataMaster[0]['formActionGroupList'];
            this.masterJsonData = this.masterJsonDataMaster.filter((Mdata:any) => +Mdata['formSequence'] == 1);
            this.childJsonData = this.masterJsonDataMaster.filter((Mdata: any) => +Mdata['formSequence'] > 1);
            if (this.childJsonData.length > 0) {
                this.childJsonData.forEach(function (valueBase:any, index:number) {
                    columnformArray[`${valueBase['formAliasName']}`] = JSON.parse(valueBase['formTableData']);
                });
                this.childTableDataDynamicArrayList = columnformArray;
            }
            if (this.addPermission == 'True') {
                data.forEach(function (valueBase:any, indexbase:string) {
                    let reactiveFormData = {};
                    let reactiveFormDataDefaultValue = {};

                    valueBase['formData'].forEach(function (value:any, index:string) {

                        let ArrayData: ValidatorFn[] = [];
                        let checkExsistAsync: AsyncValidatorFn[] = [];
                        if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                            let requiredMsg = '';
                            let sizeMsg = '';
                            let customMsg = '';
                            if (value["customValidationMsg"] != "" && value["customValidationMsg"] != null) {
                                let customValidationMsg = JSON.parse(value["customValidationMsg"]);
                                requiredMsg = customValidationMsg['requiredMsg'];
                                sizeMsg = customValidationMsg['sizeMsg'];
                                customMsg = customValidationMsg['customMsg'];
                            }
                            if (value['iS_NULLABLE'].toLowerCase() == "no") {
                                // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));

                                ArrayData.push(custumValidation['validateRequired'](requiredMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                            }
                            if ((value['datA_TYPE'] != "date" && value['datA_TYPE'] != "datetime" && value['datA_TYPE'] != "bit")) {

                                ArrayData.push(custumValidation['validateSize'](sizeMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                            }
                            if (value['validations'] != "" && value['validations'] != null) {

                                let ValName = "'" + value['validations'] + "'"
                                // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                // ArrayData.push(custfunction);

                                ArrayData.push(custumValidation[eval(ValName)](customMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));

                            }

                            if (value['checkDataExists'] != "" && value['checkDataExists'] != null && value['checkDataExistsParam'] != "" && value['checkDataExistsParam'] != null) {
                                if (value['checkDataExists'] == 1) {
                                    let sqlParams = value['checkDataExistsParam'].split('%');

                                    checkExsistAsync.push(that.asyncCustomvalidation.validateValueInDBExists_Check(value['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], sqlParams[3], sqlParams[4]).bind(that.asyncCustomvalidation))

                                }
                                else if (value['checkDataExists'] == 3) {
                                    let sqlParams = value['checkDataExistsParam'].split('%');
                                    chkAsyncGenericDbCheck = true;
                                    checkExsistAsync.push(that.asyncCustomvalidation.validateValueInGenericDBExists_Check(value['controlType'],"I",value['columN_NAME'],value['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], sqlParams[3], sqlParams[4]).bind(that.asyncCustomvalidation))

                                }
                                else if (value['checkDataExists'] == 2) {
                                    let arrayParams = value['checkDataExistsParam'].split('%');
                                    // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                    // ArrayData.push(custfunction);

                                    // console.log(value['columnLength'].toString(), value['datA_TYPE'].toString(), value['control'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString(), eval(arrayParams[0]), arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4]);
                                    ArrayData.push(custumValidation['validateValueInArrayExists_Check'](value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString(), that.childTableDataDynamicArrayList[valueBase['formAliasName']], arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));


                                }

                            }
                        }
                        // validatenumeric('16','numeric','drop','Role','','','')
                        if (value['controlType'].toString().toLowerCase() == 'dropdown') {
                            reactiveFormData[value['controlName']] = new FormControl(JSON.parse(value['columnDefaultValue']), ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = JSON.parse(value['columnDefaultValue']);

                        }
                        else if (value['controlType'].toString().toLowerCase() == 'mdropdown') {
                            reactiveFormData[value['controlName']] = new FormControl(JSON.parse(value['columnDefaultValue']), ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = JSON.parse(value['columnDefaultValue']);

                        }
                        else if (value['controlType'].toString().toLowerCase() == 'labelauto') {
                            reactiveFormData[value['controlName']] = new FormControl(value['columnValue'], ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = value['columnValue'];
                        }
                        else if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') {
                            reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? new Date(value['columnDefaultValue']) : '', ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? new Date(value['columnDefaultValue']) : '';
                        }
                        else if (value['controlType'].toString().toLowerCase() == 'checkbox') {
                            reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' && value['columnDefaultValue'] == '1' ? true : false, ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' && value['columnDefaultValue'] == '1' ? true : false;
                        }
                        else {
                            reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'], ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'];
                        }

                    });


                    formArray[`${valueBase['formAliasName']}`] = that.fb.group(reactiveFormData);
                    formArrayDefaultValue[`${valueBase['formAliasName']}`] = reactiveFormDataDefaultValue;
                });

                this.masterProductForm = this.fb.group(formArray);
                this.masterJsonDataDefaultValue = formArrayDefaultValue;
                console.log(this.masterProductForm);
                this.isRender = true;
            }
            else {

                this.SearchEdit();
            }
            this.spinner.hide();
            if(chkAsyncGenericDbCheck){
            this.masterProductForm.valueChanges.pipe().subscribe((res: FormGroup) =>{
                console.log(this.masterProductForm);
                this.commonfunction.setFormLatestValue(this.masterProductForm, "I");
            });
        }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                

                // this.isRender = true;

                this.parentFormAliasName = this.masterJsonData[0]['formAliasName'];
            });
    };

    testformData(form:FormGroup) {
        let that = this

        Object.keys(form?.controls).forEach(key => {
            const controlErrors: ValidationErrors | null | undefined  = form.get(key) ? form.get(key)?.errors:null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    testformDataEdit(form:FormGroup) {
       let that = this;
        Object.keys(form?.controls).forEach(key => {
            const controlErrors: ValidationErrors | null | undefined = form.get(key) ? form.get(key)?.errors:null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    GenericFormGroup(frmName:string) {

        return this.masterProductForm?.get(frmName) as FormGroup;
    }

    GenericFormGroupControl(frmName:string) {

        return (<FormGroup>this.masterProductForm?.get(frmName))?.controls;
    }
    GenericFormGroupEdit(frmName:string) {

        return this.masterProductFormEdit?.get(frmName) as FormGroup;
    }

    GenericFormGroupControlEdit(frmName: string) {

        return (<FormGroup>this.masterProductFormEdit?.get(frmName))?.controls;
    }
    convertStringLowerCase(value:any) {

        return value.toString().toLowerCase();
    }
    onItemSelectChild(item: any, Index: number, formSequence: number, multiselect: boolean, isChildEditflag: boolean) {

        let formAliasName = this.childJsonData[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonData[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectChild(item: any, Index: number, formSequence: number, multiselect: boolean) {
        let formAliasName = this.childJsonData[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonData[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    onItemSelectChildAll(item: any, Index: number, formSequence: number, multiselect: boolean) {
        let formAliasName = this.childJsonData[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonData[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectChildAll(item: any, Index: number, formSequence: number, multiselect: boolean) {
        let formAliasName = this.childJsonData[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonData[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    onItemSelect(item: any, Index: number, multiselect: boolean) {
        
        //let selectedData = this.masterJsonData[0]['formData'][Index];
        let formAliasName = this.masterJsonData[0]['formAliasName'];
        let masterJsonParent = this.masterJsonData[0]['formData'];
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelect(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonData[0]['formAliasName'];
        let masterJsonParent = this.masterJsonData[0]['formData'];
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    onItemSelectAll(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonData[0]['formAliasName'];
        let masterJsonParent = this.masterJsonData[0]['formData'];
        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectAll(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonData[0]['formAliasName'];
        let masterJsonParent = this.masterJsonData[0]['formData'];
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroup(formAliasName), this.SeltableIDForm, false, false, formAliasName,this.childTableDataDynamicArrayList[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnClose(item: any, controlName: string, formName: string) {
        this.GenericFormGroup(formName)!.get(controlName)?.markAsTouched();
    }

    
    // onItemSelectChildEdit(item: any, Index: number, formSequence: number, multiselect: boolean,isChildEditflag:boolean) {
  
    //     let selectedData = this.childJsonDataEdit[(formSequence - 2)]['formData'][Index];
    //     let selectedValues: string = '';
    //     if (multiselect) {
    //         selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
    //     }
    //     else {
    //         selectedValues = selectedData.drpDataSelected[0]['id'].toString();
    //     }
    //     if (selectedData.drpEventinformation['child_column_name'] != "" && selectedData.drpEventinformation['child_column_name'] != null) {
    //         let nextIndex = this.childJsonDataEdit[(formSequence - 2)]['formData'].findIndex(d => d.columN_NAME.toLowerCase() == selectedData.drpEventinformation['child_column_name'].toLowerCase());
    //         if (nextIndex > -1) {
    //             this.drpRecursiveFunctionChildEdit(nextIndex, formSequence);
    //             this.userService.MasterDrpOnChange(selectedData.drpEventinformation['parent_table_name'], selectedData.drpEventinformation['child_table_name'], selectedData.drpEventinformation['onchangeColumnNamechild'], selectedData.drpEventinformation['onchangeColumnName'], selectedValues, selectedData.drpEventinformation['valueFill'], selectedData.drpEventinformation['dropDownChildtable'], selectedData.drpEventinformation['queryId'], this.SeltableIDForm).subscribe((data) => {

    //                 if (Object.keys(data).length == 1) {

    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex]['drpDataSet'] = data['table'];
    //                 }
    //                 else if (Object.keys(data).length == 2) {

    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['child_table_name'] = data['table'][0]['child_table_name'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['child_column_name'] = data['table'][0]['child_column_name'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['parent_table_name'] = data['table'][0]['parent_table_name'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['parent_table_Aliasname'] = data['table'][0]['parent_table_Aliasname'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['parent_column_name'] = data['table'][0]['parent_column_name'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['valueFill'] = data['table'][0]['valueFill'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['dropDownChildtable'] = data['table'][0]['dropDownChildtable'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['queryId'] = data['table'][0]['queryId'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['expressionJson'] = data['table'][0]['expressionJson'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['onchangetableName'] = data['table'][0]['onchangetableName'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['onchangeColumnName'] = data['table'][0]['onchangeColumnName'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['onchangeColumnNamechild'] = data['table'][0]['onchangeColumnNamechild'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex].drpEventinformation['columnAliasName'] = data['table'][0]['columnAliasName'];
    //                     this.childJsonDataEdit[(formSequence - 2)]['formData'][nextIndex]['drpDataSet'] = data['table1'];
    //                 }

    //             },
    //                 (err: HttpErrorResponse) => {
    //                     this.spinner.hide();


    //                     if (err.status == 401) {

    //                         this.AlertTitle = "Error!!";
    //                         this.AlertMessage = "You are not authorized!";
    //                         this.AlertClass = "NP_emoji-fail-bg";
    //                         this.AlertAnimationType = "sad";
    //                         this.AlertType = "NP_popup_fail";
    //                     }
    //                     else if (err.status == 403) {
    //                         this.Customvalidation.loginroute(err.status);
    //                     }
    //                     else {

    //                         this.AlertTitle = "Error!!";
    //                         this.AlertMessage = err.message.toString();
    //                         this.AlertClass = "NP_emoji-fail-bg";
    //                         this.AlertAnimationType = "sad";
    //                         this.AlertType = "NP_popup_fail";
    //                     }
    //                     this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
    //                 });
    //         }
    //     }
    //     // custum Client side action start from here on basis of  customEvent
    //     if (!isChildEditflag && selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
    //         this.textBoxChangeEvent(item, this.childJsonDataEdit[(formSequence - 2)]['formAliasName'], selectedData['customEvent'], true);
    //     }
    //     // custum Client side action end from here on basis of  customEvent
    // }
    onItemSelectChildEdit(item: any, Index: number, formSequence: number, multiselect: boolean, isChildEditflag: boolean) {
        let formAliasName = this.childJsonDataEdit[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonDataEdit[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    // OnItemDeSelectChildEdit(item: any, Index: number, formSequence: number, multiselect: boolean) {
    //     let selectedData = this.childJsonDataEdit[(formSequence - 2)]['formData'][Index];
    //     // this.masterJsonData[Index]['drpDataSelected'] = null;
    //     let selectedValues: string = '';
    //     if (selectedData.drpDataSelected.length > 0) {
    //         if (multiselect) {
    //             selectedValues = Array.prototype.map.call(selectedData.drpDataSelected, s => s['id']).toString();
    //         }
    //         else {
    //             selectedValues = selectedData.drpDataSelected[0]['id'].toString();
    //         }
    //     }
    //     if (selectedData.drpEventinformation['child_column_name'] != "" && selectedData.drpEventinformation['child_column_name'] != null) {
    //         let nextIndex = this.childJsonDataEdit[(formSequence - 2)]['formData'].findIndex(d => d.columN_NAME.toLowerCase() == selectedData.drpEventinformation['child_column_name'].toLowerCase());
    //         this.drpRecursiveFunctionChildEdit(nextIndex, formSequence);
    //     }

    //     // custum Client side action start from here on basis of  customEvent
    //     if (selectedData['customEvent'] != "" && selectedData['customEvent'] != "null" && selectedData['customEvent'] != null && selectedData['customEvent'] != undefined) {
    //         this.textBoxChangeEvent(item, this.childJsonDataEdit[(formSequence - 2)]['formAliasName'], selectedData['customEvent'], true);
    //     }
    //     // custum Client side action end from here on basis of  customEvent

    // }
    OnItemDeSelectChildEdit(item: any, Index: number, formSequence: number, multiselect: boolean) {
        let formAliasName = this.childJsonDataEdit[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonDataEdit[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectChildEditAll(item: any, Index: number, formSequence: number, multiselect: boolean) {

        let formAliasName = this.childJsonDataEdit[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonDataEdit[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectChildEditAll(item: any, Index: number, formSequence: number, multiselect: boolean) {
        let formAliasName = this.childJsonDataEdit[(formSequence - 2)]['formAliasName'];
        let masterJsonParent = this.childJsonDataEdit[(formSequence - 2)]['formData'];
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    
    onItemSelectEdit(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonDataEdit[0]['formAliasName'];
        let masterJsonParent = this.masterJsonDataEdit[0]['formData'];
        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectEdit(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonDataEdit[0]['formAliasName'];
        let masterJsonParent = this.masterJsonDataEdit[0]['formData'];
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectEditAll(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonDataEdit[0]['formAliasName'];
        let masterJsonParent = this.masterJsonDataEdit[0]['formData'];
        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }
    OnItemDeSelectEditAll(item: any, Index: number, multiselect: boolean) {
        let formAliasName = this.masterJsonDataEdit[0]['formAliasName'];
        let masterJsonParent = this.masterJsonDataEdit[0]['formData'];
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, masterJsonParent, <FormGroup>this.GenericFormGroupEdit(formAliasName), this.SeltableIDForm, false, true, formAliasName,this.childTableDataDynamicArrayListEdit[formAliasName]);
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }
    OnCloseEdit(item: any, controlName: string, formName: string) {
        this.GenericFormGroupEdit(formName)!.get(controlName)?.markAsTouched();
    }

    OnViewFullDetail(viewFullDetail:IviewFullDetails) {
        let event: any = viewFullDetail.event;
        let id: string = viewFullDetail.id;
        let callfrom: string = viewFullDetail.callfrom;
        let modalTitle: string = viewFullDetail.modalTitle;
        
        this.spinner.show();
        var trdata = '';
        const that = this;
        this.editModelCallfrom = callfrom;
        if (callfrom == "update")
            this.editModelHeading = "Update Record - " + modalTitle;
        else
            this.editModelHeading = "View Record - " + modalTitle;
        let param = "uspAppGenericFormGetDetails|Formname=" + this.SeltableID.toString() + "|id=" + id;
        this.userService.getGenericMultiTableFormPage(param, this.SeltableIDForm.toString(), callfrom).subscribe((data: any) => {
            
            let formArray = {};
            let formArrayDefaultValue = {};
            let chkAsyncGenericDbCheck: boolean = false;
            data.forEach(function (valueBase:any, indexbase:number) {
                valueBase['formData'].forEach(function (value:any, index:number) {
                    value.ChildComponentReset = filetypeEnum.No;
                    value.childComponentFormSequence = valueBase['formSequence'];
                    value.childComponentFileDelete = false;
                });
            });
            this.masterJsonDataMasterEdit = data;
            
            console.log(this.masterJsonDataMasterEdit);
            let columnformArray = {};
            this.masterJsonDataEdit = this.masterJsonDataMasterEdit.filter((Mdata:any) => +Mdata['formSequence'] == 1);
            this.childJsonDataEdit = this.masterJsonDataMasterEdit.filter((Mdata:any) => +Mdata['formSequence'] > 1);
            if (this.childJsonDataEdit.length > 0) {
                this.childJsonDataEdit.forEach(function (valueBase:any, index:number) {
                    columnformArray[`${valueBase['formAliasName']}`] = JSON.parse(valueBase['formTableData']);
                });
                this.childTableDataDynamicArrayListEdit = columnformArray;
            }

            data.forEach(function (valueBase:string, indexbase:number) {
                let reactiveFormData = {};
                let reactiveFormDataDefaultValue = {};

                valueBase['formData'].forEach(function (valueEdit: any, index: number) {
                    
                    let ArrayData: ValidatorFn[] = [];
                    let checkExsistAsync: AsyncValidatorFn[] = [];
                    if (valueEdit["identityColumn"].toLowerCase() != "yes" && valueEdit["datA_TYPE"].toLowerCase() != "uniqueidentifier") {

                        let requiredMsg = '';
                        let sizeMsg = '';
                        let customMsg = '';
                        if (valueEdit["customValidationMsg"] != "" && valueEdit["customValidationMsg"] != null) {
                            let customValidationMsg = JSON.parse(valueEdit["customValidationMsg"]);
                            requiredMsg = customValidationMsg['requiredMsg'];
                            sizeMsg = customValidationMsg['sizeMsg'];
                            customMsg = customValidationMsg['customMsg'];
                        }

                        if (valueEdit['iS_NULLABLE'].toLowerCase() == "no") {
                            // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));

                            ArrayData.push(custumValidation['validateRequired'](requiredMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                        }
                        if ((valueEdit['datA_TYPE'] != "date" && valueEdit['datA_TYPE'] != "datetime" && valueEdit['datA_TYPE'] != "bit")) {

                            ArrayData.push(custumValidation['validateSize'](sizeMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                        }
                        if (valueEdit['validations'] != "" && valueEdit['validations'] != null) {

                            let ValName = "'" + valueEdit['validations'] + "'"
                            // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                            // ArrayData.push(custfunction);

                            ArrayData.push(custumValidation[eval(ValName)](customMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));

                        }
                        if (valueEdit['checkDataExists'] != "" && valueEdit['checkDataExists'] != null && valueEdit['checkDataExistsParam'] != "" && valueEdit['checkDataExistsParam'] != null) {
                           let columnValueEdit;
                           if(valueEdit['controlType'].toLowerCase()=="dropdown"){
                            columnValueEdit = valueEdit['drpDataSelected'] !="" && valueEdit['drpDataSelected'] != null && valueEdit['drpDataSelected'] != undefined ? valueEdit['drpDataSelected'][0]['id'] : "";
                           }
                           else if(valueEdit['controlType'].toLowerCase()=="date" || valueEdit['controlType'].toLowerCase()=="datetime"){
                            columnValueEdit = valueEdit['columnValue'] !="" && valueEdit['columnValue'] != null && valueEdit['columnValue'] != undefined ? new Date(valueEdit['columnValue']) : null;
                           }
                           else{
                            columnValueEdit = valueEdit['columnValue']; 
                           }
                            if (valueEdit['checkDataExists'] == 1) {
                                let sqlParams = valueEdit['checkDataExistsParam'].split('%');

                                checkExsistAsync.push(that.asyncCustomvalidation['validateValueInDBExists_Check'](valueEdit['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                            }
                            else if (valueEdit['checkDataExists'] == 3) {
                                let sqlParams = valueEdit['checkDataExistsParam'].split('%');
                                chkAsyncGenericDbCheck = true;
                                checkExsistAsync.push(that.asyncCustomvalidation['validateValueInGenericDBExists_Check'](valueEdit['controlType'],"E",valueEdit['columN_NAME'],valueEdit['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                            } 
                            else if (valueEdit['checkDataExists'] == 2) {
                                let arrayParams = valueEdit['checkDataExistsParam'].split('%');
                                // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                // ArrayData.push(custfunction);

                                ArrayData.push(custumValidation['validateValueInArrayExists_Check'](valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString(), that.childTableDataDynamicArrayListEdit[valueBase['formAliasName']], arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));

                            }

                        }
                    }
                    // validatenumeric('16','numeric','drop','Role','','','')
                    if (valueBase['formSequence'] == "1") {
                        if (valueEdit['controlType'].toString().toLowerCase() == 'dropdown')
                            reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'mdropdown')
                            reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

                        else if (valueEdit['controlType'].toString().toLowerCase() == 'datetime') {

                            reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? new Date(valueEdit['columnValue']) : '', disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                        }
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'checkbox') {

                            reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? valueEdit['columnValue'] : false, disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                        }
                        else
                            reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                    }
                    else {
                        if (valueEdit['controlType'].toString().toLowerCase() == 'dropdown') {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(JSON.parse(valueEdit['columnDefaultValue']), ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = JSON.parse(valueEdit['columnDefaultValue']);

                        }
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'mdropdown') {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(JSON.parse(valueEdit['columnDefaultValue']), ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = JSON.parse(valueEdit['columnDefaultValue']);

                        }
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'labelauto') {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(valueEdit['columnValue'], ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = valueEdit['columnValue'];
                        }
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'datetime' || valueEdit['controlType'].toString().toLowerCase() == 'date') {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(valueEdit['columnDefaultValue'] !== null && valueEdit['columnDefaultValue'] !== '' ? new Date(valueEdit['columnDefaultValue']) : '', ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = valueEdit['columnDefaultValue'] !== null && valueEdit['columnDefaultValue'] !== '' ? new Date(valueEdit['columnDefaultValue']) : '';
                        }
                        else if (valueEdit['controlType'].toString().toLowerCase() == 'checkbox') {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(valueEdit['columnDefaultValue'] !== null && valueEdit['columnDefaultValue'] !== '' && valueEdit['columnDefaultValue'] == '1' ? true : false, ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = valueEdit['columnDefaultValue'] !== null && valueEdit['columnDefaultValue'] !== '' && valueEdit['columnDefaultValue'] == '1' ? true : false;
                        }
                        else {
                            reactiveFormData[valueEdit['controlName']] = new FormControl(valueEdit['columnDefaultValue'], ArrayData, checkExsistAsync);
                            reactiveFormDataDefaultValue[valueEdit['controlName']] = valueEdit['columnDefaultValue'];
                        }
                    }


                });


                formArray[`${valueBase['formAliasName']}`] = that.fb.group(reactiveFormData);
                formArrayDefaultValue[`${valueBase['formAliasName']}`] = reactiveFormDataDefaultValue;
            });

            this.masterProductFormEdit = this.fb.group(formArray);
            // console.log(this.masterProductFormEdit);
            this.masterJsonDataDefaultValueEdit = formArrayDefaultValue
            this.spinner.hide();
            if(chkAsyncGenericDbCheck){
                this.masterProductFormEdit.valueChanges.pipe().subscribe((res: FormGroup) =>{
                    this.commonfunction.setFormLatestValue(this.masterProductFormEdit,"E");
                });
            }

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.updateRecordID = id;
                

                this.isRenderEdit = true;


                this.parentFormAliasNameEdit = this.masterJsonDataEdit[0]['formAliasName'];
                if (this.isWorkflowImplemented) {
                    let isworkArray = this.DetailtableRowData.filter((x:any) => x['id'] == +id);
                    if (isworkArray.length > 0) {
                        this.workflowStatusId = +isworkArray[0]['wfStatusId'];
                        this.workflowstep = +isworkArray[0]['workFlowStep'];
                    }

                }

                this.onOpenEditMasterModal();
                this.getSumEdit();

            });
    }
    // calculate child table column total start
    getSumEdit() {
        
        let that = this;
        let columnformSumArray = {};
        this.childJsonDataEdit.forEach(function (value:any, index:number) {
            let SumArr = that.childTableDataDynamicArrayListEdit[value['formAliasName']].reduce((acc:any, cur:any) => {
                for (let key in cur) {

                    let isValidKey = value['formDataSumColumn'].filter((x:any) => x.toLowerCase() == key.toLowerCase());

                    if (isValidKey.length > 0) {  // "We did this because there was only characters in RTYPE & CHANNEL , so we could not get sum of same."
                        if (acc[key]) {
                            acc[key] += +cur[key]
                        } else {
                            acc[key] = +cur[key]
                        }
                    }


                }
                return acc;
            }, {})
            columnformSumArray[`${value['formAliasName']}`] = SumArr;

        });
        this.childTableDataDynamicArrayListSumColumnEdit = columnformSumArray;
    }
    getSum() {
        
        let that = this;
        let columnformSumArray = {};
        this.childJsonData.forEach(function (value:any, index:number) {
            let SumArr = that.childTableDataDynamicArrayList[value['formAliasName']].reduce((acc:any, cur:any) => {
                for (let key in cur) {
                    let isValidKey = value['formDataSumColumn'].filter((x:any) => x.toLowerCase() == key.toLowerCase());
                    if (isValidKey.length > 0) {  // "We did this because there was only characters in RTYPE & CHANNEL , so we could not get sum of same."
                        if (acc[key]) {
                            acc[key] += +cur[key]
                        } else {
                            acc[key] = +cur[key]
                        }
                    }
                }
                return acc;
            }, {});
            columnformSumArray[`${value['formAliasName']}`] = SumArr;

        });
        this.childTableDataDynamicArrayListSumColumn = columnformSumArray;
        console.log(this.childTableDataDynamicArrayListSumColumn);
    }

    OnPrintFullDetail(event:any, id:string, callfrom:any) {
        
        this.spinner.show();
        var trdata = '';
        const that = this;


        let param = "uspAppGenericCommonPrintFunctionality|Formname=" + this.SeltableID.toString() + "|recordId=" + id;
        this.userService.getCommonDataSet(this.SeltableIDForm.toString(), param).subscribe((data: any) => {

            
            that.genericPrintService.printDataArray = data.table;
            that.GenericPrintDataArray = data.table;

            this.spinner.hide();

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                if (this.printPermission == 1)
                    this.genericPrintService.printDocument();
                else if (this.printPermission == 2)
                    this.GenericPrintFunction();
            });
    }
    // calculate child table column total end
    OnMasterFormAction(event:any, id:string, action:string, TableId:number, modalTitle: string) {
        let that = this;
        this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + " Record for " + modalTitle + "?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    
                    this.MasterTableAllAction(id, action, TableId, that.DetailtableHeaderData[0]['tableName']);
                    this.paramvaluedata = '';
                }
            });

    }

    MasterTableAllAction(id: string, action: string, index: number, tableName: string) {
        
        //code to be done
        this.userService.ActionActivesubmit(id, action, this.SeltableIDForm.toString(), tableName, 'T').subscribe((data: any) => {
            let resultarray = JSON.parse(data);
            // resultarray['successFlag'] 
            // resultarray['resultMessage']
            // resultarray['printAction']
            // resultarray['printActionResult']
            // resultarray['otherAction']
            // resultarray['otherActionResult']
            // resultarray['outputVarResult']           
            
            if (action == "Active" || action == "InActive") {
                if (resultarray['successFlag'] == "success") {
                    this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
                    return;
                }
                else {
                    this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
                    return;
                }
            }
            else {
                if (resultarray['successFlag'] == "success") {
                    //            
                    this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

                    return;

                }
                else {
                    this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
                    return;

                }
            }

        },
            (err: HttpErrorResponse) => {

                if (err.status == 401) {
                    this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
                }
            },
            () => {
                
                if (action == "Active") {
                    this.DetailtableRowData[index]['isActive'] = true;
                }
                else if (action == "InActive") {
                    this.DetailtableRowData[index]['isActive'] = false;
                }
                else {
                    this.SearchEdit();
                    this.retainFilter(1500);
                }

            });
        //

    }

    onAddChildRow(event:any, callfrom: string, childFormAliasName: string, formSequence: number, ichildIndex: number) {
        let that = this;
        
        // let tblAlias = childFormAliasName;
        let NewIdGenrated = 'NewIdGenrated';
        let columnNamefinal = "";
        let columnValuefinal = "";
        let arrayRow = {};
        let drptext = "";
        let RowAddedNumber: number = this.childJsonData[ichildIndex]['formRowAddedNumber'] == ''? 0 : +this.childJsonData[ichildIndex]['formRowAddedNumber'];
      let addRowFlag: boolean = true;
      if(RowAddedNumber == 0){
        addRowFlag = true;
      }
      else if(RowAddedNumber >0 && callfrom =="I" && this.childTableDataDynamicArrayList[childFormAliasName]?.length < RowAddedNumber){
        addRowFlag = true;
    }
      else if(RowAddedNumber >0 && callfrom =="E" && this.childTableDataDynamicArrayList[childFormAliasName]?.length <= RowAddedNumber){
        addRowFlag = true;
    }
    else{
        addRowFlag = false;
    }
      if(addRowFlag){
        this.childJsonData[ichildIndex]['formData'].forEach(function (value:any, index:number) {
            let objList = <ObjectParam>{};
            if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                let colValue;
                let CompareColumnValue;
                if (value["controlType"] == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0) {
                        colValue = null;
                        drptext = "-"
                    }
                    else {
                        colValue = drpvalue[0]['id'].toString();
                        drptext = drpvalue[0]['itemName'].toString();
                    }
                }
                else if (value["controlType"] == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0) {
                        colValue = null;
                        drptext = "-"
                    }
                    else {
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                        drptext = Array.prototype.map.call(drpvalue, s => s['itemName']).toString();
                    }
                }
                else if (value["controlType"] == "checkbox") {
                    let chkValue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                    if (chkValue == null) {
                        colValue = null;
                    }
                    else if (chkValue == false) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"] == "file") {
                    colValue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                    value["controlValue"] = "";

                }
                else if (value["controlType"] == "hidden") {
                    colValue = NewIdGenrated;
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormat(that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]]);
                }
                else if (value["controlType"].toLowerCase() == "switchbutton" ) {
                    drptext = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]] == "1" ? "Yes" :"No";
                    colValue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                }
                else {
                    colValue = that.GenericFormGroup(childFormAliasName)?.value[value["controlName"]];
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        CompareColumnValue = $.trim(jQuery('#' + value["controlName"]).val().toString());
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue !== null && colValue !== '') {
                    columnNamefinal = columnNamefinal + value["columN_NAME"] + '^';
                    if (value["controlType"] == "dropdown" || value["controlType"] == "mdropdown" || value["controlType"] == "switchbutton")
                        arrayRow[`${value["columN_NAME"]}`] = drptext;
                    else
                        arrayRow[`${value["columN_NAME"]}`] = colValue;
                    if (value["datA_TYPE"].toLowerCase().toString() == "date" || value["datA_TYPE"].toLowerCase().toString() == "datetime" || value["datA_TYPE"].toLowerCase().toString() == "smalldatetime")
                        columnValuefinal = columnValuefinal + "Date_" + colValue + "^";
                    else
                        columnValuefinal = columnValuefinal + colValue + "^";


                }
            }
        });
        
        if (callfrom == "I") {
            let createdBy = "createdBy^createdRoleId^";
            let modifiedBy = "modifiedBy^modifiedRoleId^";
            let createdByValue = that.userId + "^" + that.currentRole + "^";
            let modifiedByValue = that.userId+ "^" + that.currentRole + "^";
            columnNamefinal = columnNamefinal + createdBy + modifiedBy;
            columnValuefinal = columnValuefinal + createdByValue + modifiedByValue;
            arrayRow['processedColumnName'] = columnNamefinal;
            arrayRow['processedColumnValue'] = columnValuefinal;
            arrayRow['rowAction'] = callfrom;
            arrayRow['rowCreatedBy'] = createdBy;
            arrayRow['rowModifiedBy'] = modifiedBy;
            arrayRow['rowCreatedByValue'] = createdByValue;
            arrayRow['rowModifiedByValue'] = modifiedByValue;
            this.childTableDataDynamicArrayList[childFormAliasName].push(arrayRow);
            this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.Yes);
        }
        else if (callfrom == "E") {
            let rowChildDataIndex = this.childTableDataDynamicArrayList[childFormAliasName].findIndex((row:any) => row['rowAction'] == 'E');
            let rowChildData = this.childTableDataDynamicArrayList[childFormAliasName][rowChildDataIndex];
            this.childJsonData[formSequence - 2]['formData'].forEach(function (value:any, index:number) {
                if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                    rowChildData[value['columN_NAME']] = arrayRow[value['columN_NAME']];
                }
            });
            rowChildData['modifiedBy'] = "ModifiedBy^modifiedRoleId^";
            rowChildData['modifiedByValue'] = that.userId + "^" + that.currentRole + "^";
            columnNamefinal = columnNamefinal + rowChildData['rowCreatedBy'] + rowChildData['rowModifiedBy'];
            columnValuefinal = columnValuefinal + rowChildData['rowCreatedByValue'] + rowChildData['rowModifiedByValue'];
            rowChildData['processedColumnName'] = columnNamefinal;
            rowChildData['processedColumnValue'] = columnValuefinal;
            rowChildData['rowAction'] = "I";
            this.isChildRowEdit = false;
            this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.YesPreviousDel);
        }

        this.getSum();
        this.buttonCustomEvent(event, childFormAliasName, this.childJsonData[ichildIndex]['customEventForm'], false);

        console.log(this.childTableDataDynamicArrayList[childFormAliasName]);
    }
    else {
        if(callfrom == "I"){
        this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.Yes);
        }
        else{
        this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.YesPreviousDel);
        }
        this.isChildRowEdit = false;
        this.globalAlertService.AlertMsg("Alert!!", `Can't add more than ${RowAddedNumber} Records`, "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;

    }
    console.log(this.childJsonData[ichildIndex]);
    }

    onAddChildRowEdit(event:any, callfrom: string, childFormAliasName: string, formSequence: number, ichildIndex: number) {
        let that = this;

        let tblAlias = childFormAliasName;
        let updatedColumn = this.masterJsonDataEdit[0]['formData'].filter((x:any) => x.columN_NAME.toLowerCase() == 'id');

        let NewIdGenrated = updatedColumn[0]['controlValue'];

        let columnNamefinal = "";
        let columnValuefinal = "";
        let arrayRow = {};
        let drptext = "";
        let RowAddedNumber: number = this.childJsonData[ichildIndex]['formRowAddedNumber'] == ''? 0 : +this.childJsonData[ichildIndex]['formRowAddedNumber'];
      let addRowFlag: boolean = true;
      if(RowAddedNumber == 0){
        addRowFlag = true;
      }
      else if(RowAddedNumber >0 && callfrom =="I" && this.childTableDataDynamicArrayList[childFormAliasName]?.length < RowAddedNumber){
        addRowFlag = true;
    }
      else if(RowAddedNumber >0 && callfrom =="E" && this.childTableDataDynamicArrayList[childFormAliasName]?.length <= RowAddedNumber){
        addRowFlag = true;
    }
    else{
        addRowFlag = false;
    }
      if(addRowFlag){
        this.childJsonDataEdit[ichildIndex]['formData'].forEach(function (value:any, index:number) {
            
            let objList = <ObjectParam>{};
            if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                let colValue;
                let CompareColumnValue;
                if (value["controlType"] == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0) {
                        colValue = null;
                        drptext = "-"
                    }
                    else {
                        colValue = drpvalue[0]['id'].toString();
                        drptext = drpvalue[0]['itemName'].toString();
                    }
                }
                else if (value["controlType"] == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0) {
                        colValue = null;
                        drptext = "-"
                    }
                    else {
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                        drptext = Array.prototype.map.call(drpvalue, s => s['itemName']).toString();
                    }
                }
                else if (value["controlType"] == "checkbox") {
                    let chkValue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                    if (chkValue == null) {
                        colValue = null;
                    }
                    else if (chkValue == false) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }

                else if (value["controlType"] == "file") {
                    colValue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                }
                else if (value["controlType"] == "hidden") {
                    colValue = NewIdGenrated;
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormat(that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]]);
                }
                else if (value["controlType"].toLowerCase() == "switchbutton" ) {
                    drptext = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]] == "1" ? "Yes" :"No";
                    colValue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                }
                else {
                    colValue = that.GenericFormGroupEdit(childFormAliasName)?.value[value["controlName"]];
                }

                if (colValue !== null && colValue !== '') {
                    columnNamefinal = columnNamefinal + value["columN_NAME"] + '^';
                    if (value["controlType"] == "dropdown" || value["controlType"] == "mdropdown" || value["controlType"] == "switchbutton")
                        arrayRow[`${value["columN_NAME"]}`] = drptext;                    
                    else
                        arrayRow[`${value["columN_NAME"]}`] = colValue;
                    if (value["datA_TYPE"].toLowerCase().toString() == "date" || value["datA_TYPE"].toLowerCase().toString() == "datetime" || value["datA_TYPE"].toLowerCase().toString() == "smalldatetime")
                        columnValuefinal = columnValuefinal + "Date_" + colValue + "^";
                    else
                        columnValuefinal = columnValuefinal + colValue + "^";


                }
            }
        });
        
        if (callfrom == "I") {
            let createdBy = "createdBy^createdRoleId^";
            let modifiedBy = "modifiedBy^modifiedRoleId^";
            let createdByValue = that.userId + "^" + that.currentRole + "^";
            let modifiedByValue = that.userId  + "^" + that.currentRole + "^";
            columnNamefinal = "id^" + columnNamefinal + createdBy + modifiedBy;
            columnValuefinal = "0^" + columnValuefinal + createdByValue + modifiedByValue;
            arrayRow['processedColumnName'] = columnNamefinal;
            arrayRow['processedColumnValue'] = columnValuefinal;
            arrayRow['rowAction'] = callfrom;
            arrayRow['rowCreatedBy'] = createdBy;
            arrayRow['rowModifiedBy'] = modifiedBy;
            arrayRow['rowCreatedByValue'] = createdByValue;
            arrayRow['rowModifiedByValue'] = modifiedByValue;
            this.childTableDataDynamicArrayListEdit[childFormAliasName].push(arrayRow);
            this.resetChildFormEdit(childFormAliasName, formSequence, ichildIndex, filetypeEnum.Yes);
        }
        else if (callfrom == "E") {
            let rowChildDataIndex = this.childTableDataDynamicArrayListEdit[childFormAliasName].findIndex((row:any) => row['rowAction'] == 'E');
            let rowChildData = this.childTableDataDynamicArrayListEdit[childFormAliasName][rowChildDataIndex];
            this.childJsonDataEdit[formSequence - 2]['formData'].forEach(function (value:any, index:number) {
                if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                    rowChildData[value['columN_NAME']] = arrayRow[value['columN_NAME']];
                }
            });
            rowChildData['modifiedBy'] = "ModifiedBy^modifiedRoleId^";
            rowChildData['modifiedByValue'] = that.userId  + "^" + that.currentRole + "^";
            columnNamefinal = columnNamefinal + rowChildData['rowCreatedBy'] + rowChildData['rowModifiedBy'];
            columnValuefinal = columnValuefinal + rowChildData['rowCreatedByValue'] + rowChildData['rowModifiedByValue'];
            rowChildData['processedColumnName'] = columnNamefinal;
            rowChildData['processedColumnValue'] = columnValuefinal;
            rowChildData['rowAction'] = "I";
            this.isChildRowEdit = false;
            this.resetChildFormEdit(childFormAliasName, formSequence, ichildIndex, filetypeEnum.YesPreviousDel);
        }
        this.getSumEdit();
        this.buttonCustomEvent(event, childFormAliasName, this.childJsonData[ichildIndex]['customEventForm'], true);
    }
    else {
        if(callfrom == "I"){
        this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.Yes);
        }
        else{
        this.resetChildForm(childFormAliasName, formSequence, ichildIndex, filetypeEnum.YesPreviousDel);
        }
        this.isChildRowEdit = false;
        this.globalAlertService.AlertMsg("Alert!!", `Can't add more than ${RowAddedNumber} Records`, "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;

    }
    }

    deleteChildTableRow(event:any, callfrom: string, childFormAliasName: string, formSequence: number, rowIndex: number) {

        if (callfrom == "I") {
            this.childTableDataDynamicArrayList[childFormAliasName].splice(rowIndex, 1);
            this.getSum();
        }
        else if (callfrom == "E") {
            this.childTableDataDynamicArrayListEdit[childFormAliasName].splice(rowIndex, 1);
            this.getSumEdit();
        }
        this.buttonCustomEvent(event, childFormAliasName, this.childJsonData[formSequence - 2]['customEventForm'], false);

    }

    editChildTableRow(event:any, callfrom: string, childFormAliasName: string, formSequence: number, rowIndex: number) {
        

        let that = this;
        let reactiveFormDataDefaultValue = {};
        this.isChildRowEdit = true;
        if (callfrom == "I") {

            let createdBy = this.childTableDataDynamicArrayList[childFormAliasName][rowIndex]['rowCreatedBy'];
            let modifiedBy = this.childTableDataDynamicArrayList[childFormAliasName][rowIndex]['rowModifiedBy'];
            let ChildFormColumns = this.childTableDataDynamicArrayList[childFormAliasName][rowIndex]['processedColumnName'].replace('^' + createdBy + modifiedBy, '').split('^');
            let ChildFormColumnsValue = this.childTableDataDynamicArrayList[childFormAliasName][rowIndex]['processedColumnValue'].split('^');
            this.childTableDataDynamicArrayList[childFormAliasName][rowIndex]['rowAction'] = "E";
            
            let newValue: Array<{ controlName: string, value: any }> = [];
            this.childJsonData[formSequence - 2]['formData'].forEach(function (value:any, index:number) {
                if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                    let ColumIndex = ChildFormColumns.findIndex((x:any) => x == value['columN_NAME']);
                    value['previousControlValue'] = ChildFormColumnsValue[ColumIndex] == undefined ? null : ChildFormColumnsValue[ColumIndex].toString();
                    let columnValue;
                    let drpColumnValue: Array<{ id: string, itemName: string }> = [];
                    if (value['controlType'].toString().toLowerCase() == 'dropdown') {
                        if(ChildFormColumnsValue[ColumIndex].toString() != 'NULL'){
                        drpColumnValue = [{ id: ChildFormColumnsValue[ColumIndex].toString(), itemName: that.childTableDataDynamicArrayList[childFormAliasName][rowIndex][value['columN_NAME']] }];
                        columnValue = drpColumnValue;
                        }
                        value['drpDataSelected'] = columnValue;
                        if (columnValue != null && columnValue != undefined)
                            that.onItemSelectChild(event, index, formSequence, false, true);
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'mdropdown') {
                        if(ChildFormColumnsValue[ColumIndex].toString() != 'NULL'){
                        let mValue = ChildFormColumnsValue[ColumIndex].split(',');
                        mValue.forEach(function (mvalue:any, mindex:string) {
                            drpColumnValue.push({ id: mvalue.toString(), itemName: that.childTableDataDynamicArrayList[childFormAliasName][rowIndex][value['columN_NAME']].split(',')[mindex] });
                        });
                        columnValue = drpColumnValue;
                    }
                        value['drpDataSelected'] = columnValue;
                        if (columnValue != null && columnValue != undefined)
                            that.onItemSelectChild(event, index, formSequence, true, true);

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') {
                        columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' ? that.commonfunction.ConvertDateFormatEditChild(ChildFormColumnsValue[ColumIndex]) : '';

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'checkbox') {
                        columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' && ChildFormColumnsValue[ColumIndex] == '1' ? true : false;
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'file') {
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'timepicker'){
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;
                    }
                        
                    else if (value['controlType'].toString().toLowerCase() == 'switchbutton'){
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;
                    }
                    else
                        columnValue = ChildFormColumnsValue[ColumIndex];

                    reactiveFormDataDefaultValue[value['controlName']] = columnValue;
                    //  that.GenericFormGroup(childFormAliasName).get(value['controlName']).markAsTouched();

                }
            });
            this.GenericFormGroup(childFormAliasName)?.patchValue(reactiveFormDataDefaultValue);
            this.GenericFormGroup(childFormAliasName)?.markAsTouched();
            this.GenericFormGroup(childFormAliasName)?.updateValueAndValidity();
        }
        else if (callfrom == "E") {
            
            let createdBy = this.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex]['rowCreatedBy'];
            let modifiedBy = this.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex]['rowModifiedBy'];
            let ChildFormColumns = this.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex]['processedColumnName'].replace('^' + createdBy + modifiedBy, '').split('^');
            let ChildFormColumnsValue = this.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex]['processedColumnValue'].split('^');
            this.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex]['rowAction'] = "E";
            
            let newValue: Array<{ controlName: string, value: any }> = [];
            this.childJsonDataEdit[formSequence - 2]['formData'].forEach(function (value:any, index:number) {
                if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                    let ColumIndex = ChildFormColumns.findIndex((x:any) => x == value['columN_NAME']);
                    value['previousControlValue'] = ChildFormColumnsValue[ColumIndex] == undefined ? null : ChildFormColumnsValue[ColumIndex].toString();
                    let columnValue;
                    let drpColumnValue: Array<{ id: string, itemName: string }> = [];
                    if (value['controlType'].toString().toLowerCase() == 'dropdown') {
                        if(ChildFormColumnsValue[ColumIndex].toString() != 'NULL'){
                        drpColumnValue = [{ id: ChildFormColumnsValue[ColumIndex].toString(), itemName: that.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex][value['columN_NAME']] }];
                        columnValue = drpColumnValue;
                        }
                        value['drpDataSelected'] = columnValue;
                        if (columnValue != null && columnValue != undefined)
                            that.onItemSelectChildEdit(event, index, formSequence, false, true);
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'mdropdown') {
                        if(ChildFormColumnsValue[ColumIndex].toString() != 'NULL'){
                        let mValue = ChildFormColumnsValue[ColumIndex].split(',');
                        mValue.forEach(function (mvalue:any, mindex:number) {
                            drpColumnValue.push({ id: mvalue.toString(), itemName: that.childTableDataDynamicArrayListEdit[childFormAliasName][rowIndex][value['columN_NAME']].split(',')[mindex] });
                        });
                        columnValue = drpColumnValue;}
                        value['drpDataSelected'] = columnValue;
                        if (columnValue != null && columnValue != undefined)
                            that.onItemSelectChildEdit(event, index, formSequence, true, true);

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') {
                        columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' ? that.commonfunction.ConvertDateFormatEditChild(ChildFormColumnsValue[ColumIndex]) : '';

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'checkbox') {
                        columnValue = ChildFormColumnsValue[ColumIndex] !== null && ChildFormColumnsValue[ColumIndex] !== '' && ChildFormColumnsValue[ColumIndex] == '1' ? true : false;
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'file') {
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'timepicker'){
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;
                    }
                        
                    else if (value['controlType'].toString().toLowerCase() == 'switchbutton'){
                        columnValue = ChildFormColumnsValue[ColumIndex];
                        value['controlValue'] = columnValue;
                    }
                    else
                        columnValue = ChildFormColumnsValue[ColumIndex];

                    reactiveFormDataDefaultValue[value['controlName']] = columnValue;
                    //  that.GenericFormGroup(childFormAliasName).get(value['controlName']).markAsTouched();

                }
            });
            this.GenericFormGroupEdit(childFormAliasName)?.patchValue(reactiveFormDataDefaultValue);
            this.GenericFormGroupEdit(childFormAliasName)?.markAsTouched();
            this.GenericFormGroupEdit(childFormAliasName)?.updateValueAndValidity();
        }

    }

    OndeleteChildTableRow(event:any, callfrom: string, childFormAliasName: string, formSequence: number, rowIndex: number) {
        let that = this;
        this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to delete?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    
                    this.deleteChildTableRow(event, callfrom, childFormAliasName, formSequence, rowIndex);
                    this.paramvaluedata = '';
                }
            });

    }

    childCReset(event: filetypeEnum, columnname: string, callfrom: string, controlName: string, formAliasName: string, formIndex: number, formType: string, controlIndex: number) {
        
        let that = this;
        const newValue = JSON.parse('{"' + controlName + '":""}');
        if (callfrom == 'I') {
           
            if (formType.toLowerCase() == 'parent') {
                let filecomtrols = this.masterJsonData[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    if(value.control.toLowerCase() == 'file'){
                setTimeout(() => {
                    value['ChildComponentReset'] = event;
                    that.GenericFormGroup(formAliasName)!.get(controlName)?.markAsUntouched();
                    // this.masterProductForm.get(controlName).markAsUntouched();
                }, 200);
            }
            else{
                value['ChildComponentReset'] = event;
                that.GenericFormGroup(formAliasName)!.get(controlName)?.markAsUntouched();
            }
            });
               
            }
            else {
                let filecomtrols = this.childJsonData[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    if(value.control.toLowerCase() == 'file'){
                setTimeout(() => {

                    value['ChildComponentReset'] = event;
                    // this.masterProductForm.get(controlName).markAsUntouched();
                    that.GenericFormGroup(formAliasName)!.get(controlName)?.markAsUntouched();
                }, 200);
            }
            else{
                value['ChildComponentReset'] = event;
                // this.masterProductForm.get(controlName).markAsUntouched();
                that.GenericFormGroup(formAliasName)!.get(controlName)?.markAsUntouched();
            }
            })
            }

        }
        else {
           
            if (formType.toLowerCase() == 'parent') {
                let filecomtrols = this.masterJsonDataEdit[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    
                    if(value.control.toLowerCase() == 'file')
                         value['ChildComponentReset'] = event;
                    else
                         value['ChildComponentReset'] = event;

                });
            }
            else {
                let filecomtrols = this.childJsonDataEdit[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    
                    if(value.control.toLowerCase() == 'file')
                        value['ChildComponentReset'] = event;
                    else
                        value['ChildComponentReset'] = event;

                });
            }

        }



    }

    openSubmitModal() {
        this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.submitcall();
                    this.paramvaluedata = '';
                }
            });
    }

    OnSubmitModal(FormAliasName: string) {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let genericColumnListParam = [];
        let objListfinal = <FormFinalObjectParam>{};
        let objColumn = <lstFormFinalObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';
        let columnNamefinal = "";
        let columnValuefinal = "";
        that.masterJsonData[0]['formData'].forEach(function (value:any, index:number) {
            
            let objList = <ObjectParam>{};
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let colValue;
                let formcontrolValue;
                let CompareColumnValue;
                if (value["controlType"].toLowerCase() == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"].toLowerCase() == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                }
                else if (value["controlType"].toLowerCase() == "checkbox") {
                    let chkValue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false || chkValue == undefined) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"].toLowerCase() == "file") {
                    formcontrolValue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue;
                    }
                    else {
                        colValue = '';
                    }
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    formcontrolValue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = that.commonfunction.ConvertDateFormat(formcontrolValue);
                    }
                    else {
                        colValue = '';
                    }

                }
                else {
                    formcontrolValue = that.GenericFormGroup(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue;
                    }
                    else {
                        colValue = '';
                    }
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        CompareColumnValue = $.trim(jQuery('#' + value["controlName"]).val().toString());
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue !== null && colValue !== '') {

                    columnNamefinal = columnNamefinal + value["columN_NAME"] + '^';
                    if (value["datA_TYPE"].toLowerCase().toString() == "date" || value["datA_TYPE"].toLowerCase().toString() == "datetime" || value["datA_TYPE"].toLowerCase().toString() == "smalldatetime")
                        columnValuefinal = columnValuefinal + "Date_" + colValue + "^";
                    else
                        columnValuefinal = columnValuefinal + colValue + "^";
                }
            }
        });

        if (columnNamefinal !== "" && columnValuefinal != "") {
            columnNamefinal = columnNamefinal + "CreatedBy^ModifiedBy^createdRoleId^modifiedRoleId^";
            columnValuefinal = columnValuefinal + that.userId  + "^" + that.userId  + "^" + that.currentRole + "^" + that.currentRole + "^";
        }
        if (flag == true) {
            let childTableArraysIsRequired = this.childJsonData.filter((xisReqChk:any) => xisReqChk['formIsRequired'].toLowerCase() == 'true');
            childTableArraysIsRequired.forEach(function (valueCJson:any, index:number) {
                let WFTableName = that.masterJsonData[0]['formTableName'].toString();
                if (that.childTableDataDynamicArrayList[valueCJson['formAliasName']].length <= 0) {
                    flag = false;
                    msg = "Please Enter Details for " + that.commonfunction.SpaceAfterCapitalLetter(valueCJson['formAliasName']) + ".";
                }
                else if (WFTableName == that.WFTBName) {
                    let resultWF = that.workFlowValidation(valueCJson['formAliasName']);
                    if (!resultWF.GenericWorkFlowError.flag) {
                        flag = false;
                        msg = resultWF.GenericWorkFlowError.errorMsg;
                    }
                }
            });
        }
        else {
            flag = false;
        }

        if (flag == true) {
            objListfinal.tbleName = this.masterJsonData[0]['formTableName'].toString();
            objListfinal.tbleSequence = this.masterJsonData[0]['formSequence'].toString();
            objListfinal.columnName = columnNamefinal;
            objListfinal.ColumnValue = columnValuefinal;
            genericColumnListParam.push(objListfinal);

            this.childJsonData.forEach(function (valueCJson:any, index:number) {
                if (that.childTableDataDynamicArrayList[valueCJson['formAliasName']].length > 0) {

                    that.childTableDataDynamicArrayList[valueCJson['formAliasName']].forEach(function (valuegetJson:any, indexC:number) {
                        let objListchildsFinal = <FormFinalObjectParam>{};
                        objListchildsFinal.tbleName = valueCJson['formTableName'].toString();
                        objListchildsFinal.tbleSequence = valueCJson['formSequence'].toString();
                        objListchildsFinal.columnName = valuegetJson['processedColumnName'];
                        objListchildsFinal.ColumnValue = valuegetJson['processedColumnValue'].replace(/"/g, "'");
                        genericColumnListParam.push(objListchildsFinal);
                    });
                }
            });
        }

        if (genericColumnListParam.length > 0) {
            objColumn.genericColumnListParam = genericColumnListParam;
            _data = JSON.stringify(objColumn);
        }

        if (flag == true) {
            this.openSubmitModal();
            this.paramvaluedata = _data;
        }
        else {
            // this.AlertTitle = "Error!!";
            // this.AlertMessage = msg.toString();
            // this.AlertClass = "NP_emoji-fail-bg";
            // this.AlertAnimationType = "sad";
            // this.AlertType = " NP_popup_fail";  

            this.AlertTitle = "Alert!!";
            this.AlertMessage = msg.toString();
            this.AlertClass = "NP_emoji-error-bg";
            this.AlertAnimationType = "NP_emoji-error";
            this.AlertType = "NP_popup_error";

            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }


    }
    submitcall() {
        let printData;
        this.spinner.show();
        let param = "uspAppGenericSubmitMultiTableFormData|tables=" + this.paramvaluedata + "|formName=" + this.SeltableIDForm;
        this.userService.SubmitMultiTableForm(param, this.SeltableIDForm).subscribe((datacom: any) => {
            this.spinner.hide();
            
            let printArray = [];

            if (datacom != "") {
                let resultarray = JSON.parse(datacom);


                // resultarray['successFlag'] ;
                // resultarray['resultMessage'];
                // resultarray['printAction'];
                // resultarray['printActionResult'];
                // resultarray['otherAction'];
                // resultarray['otherActionResult'];
                // resultarray['outputVarResult'];
                if (resultarray['successFlag'] == "success") {
                    printArray.push({ 'template': resultarray['printActionResult'].toString() });
                    this.genericPrintService.printDataArray = printArray;
                    this.GenericPrintDataArray = printArray;
                    // this.masterProductForm.reset();
                    this.resetMasterForm(this.parentFormAliasName, 1, 0, 1);
                    this.AlertTitle = "Success!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-success-bg";
                    this.AlertAnimationType = "smile";
                    this.AlertType = "NP_popup_success";
                    printData = resultarray['printAction'];
                }

                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";

                }

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                // this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);

                this.AlertService.AlertFunction(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType)
                    .then((confirmed) => {
                        if (!confirmed) {
                            if (printData = 1) {
                                if (this.printPermission == 1)
                                    this.genericPrintService.printDocument();
                                else if (this.printPermission == 2)
                                    this.GenericPrintFunction();
                            }
                        }
                    });
            }
        );



    }

    // Special Case only for Work Flow Design Form
    workFlowValidation(formAliasName: string) {
        let msg: string = '';
        let flag: boolean = false;
        if (this.childTableDataDynamicArrayList[formAliasName].length < 2) {
            flag = false;
            msg = "Please Enter atleast 2 level for " + this.commonfunction.SpaceAfterCapitalLetter(formAliasName) + ".";
        }
        else {
            let chkwftable:any = [];
            this.childTableDataDynamicArrayList[formAliasName].forEach(function (value:any, index:number) {
                let wfprocessedColumnName = value['processedColumnName'];
                let wfprocessedColumnValue = value['processedColumnValue'];
                let wfArrraycolumn = wfprocessedColumnName.split('^');
                let wfArrraycolumnValue = wfprocessedColumnValue.split('^');
                let wfArrayColumnLevelIndeex = wfArrraycolumn.findIndex((x:any) => x.toLowerCase() == 'wfLevel'.toLowerCase());
                let wfArrayColumnRoleIndeex = wfArrraycolumn.findIndex((x:any) => x.toLowerCase() == 'wfRole'.toLowerCase());


                let wfid = +wfArrraycolumnValue[wfArrayColumnLevelIndeex];
                let roleId = +wfArrraycolumnValue[wfArrayColumnRoleIndeex];

                chkwftable.push({ 'Wid': wfid, 'rid': roleId });

            });
            if (chkwftable[0].Wid != 1) {
                flag = false;
                msg = "First level must be Creator.";
            }
            else if (chkwftable[chkwftable.length - 1].Wid != 2) {
                flag = false;
                msg = "Last Level must be Approver.";
            }
            else {
                flag = true;
                msg = "";

            }


        }
        return { 'GenericWorkFlowError': { flag: flag, errorMsg: msg } };
    }

    // Special Case only for Work Flow Design Form
    workFlowValidationEdit(formAliasName: string) {
        let msg: string = '';
        let flag: boolean = false;
        if (this.childTableDataDynamicArrayListEdit[formAliasName].length < 2) {
            flag = false;
            msg = "Please Enter atleast 2 level for " + this.commonfunction.SpaceAfterCapitalLetter(formAliasName) + ".";
        }
        else {
            let chkwftable:any = [];
            this.childTableDataDynamicArrayListEdit[formAliasName].forEach(function (value:any, index:number) {
                let wfprocessedColumnName = value['processedColumnName'];
                let wfprocessedColumnValue = value['processedColumnValue'];
                let wfArrraycolumn = wfprocessedColumnName.split('^');
                let wfArrraycolumnValue = wfprocessedColumnValue.split('^');
                let wfArrayColumnLevelIndeex = wfArrraycolumn.findIndex((x:any) => x.toLowerCase() == 'wfLevel'.toLowerCase());
                let wfArrayColumnRoleIndeex = wfArrraycolumn.findIndex((x:any) => x.toLowerCase() == 'wfRole'.toLowerCase());


                let wfid = +wfArrraycolumnValue[wfArrayColumnLevelIndeex];
                let roleId = +wfArrraycolumnValue[wfArrayColumnRoleIndeex];

                chkwftable.push({ 'Wid': wfid, 'rid': roleId });

            });
            if (chkwftable[0].Wid != 1) {
                flag = false;
                msg = "First level must be Creator.";
            }
            else if (chkwftable[chkwftable.length - 1].Wid != 2) {
                flag = false;
                msg = "Last Level must be Approver.";
            }
            else {
                flag = true;
                msg = "";

            }


        }
        return { 'GenericWorkFlowError': { flag: flag, errorMsg: msg } };
    }

    FirstLetterLower(value:string) {
        return this.commonfunction.FirstLetterLower(value);
    }
    FirstLetterUpper(value:string) {
        return this.commonfunction.FirstLetterUpper(value);
    }
    ReplaceNullDataTable(value:string) {
        return this.commonfunction.ReplaceNullDataTable(value);
    }

    resetChildForm(childFormAliasName: string, childFormSequence: number, formIndex: number, isFileDelete: filetypeEnum) {
        
        this.isChildRowEdit = false;
        this.GenericFormGroup(childFormAliasName)?.reset(this.masterJsonDataDefaultValue[childFormAliasName]);
        let filecomtrols = this.childJsonData[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
        filecomtrols.forEach(function (value:any, index:number) {
            if(value.control.toLowerCase() == 'file')
            value['ChildComponentReset'] = isFileDelete;
            else{
              
            value['ChildComponentReset'] = isFileDelete;
            // value['controlValue'] = null;
            }

            value['previousControlValue'] = '';
          

        });
    }
    resetChildFormEdit(childFormAliasName: string, childFormSequence: number, formIndex: number, isFileDelete: filetypeEnum) {
        
        let that = this;
        this.isChildRowEdit = false;
        this.GenericFormGroupEdit(childFormAliasName)?.reset(this.masterJsonDataDefaultValueEdit[childFormAliasName]);
        let filecomtrols = this.childJsonDataEdit[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
        filecomtrols.forEach(function (value:any, index:number) {
            if(value.control.toLowerCase() == 'file')
            value['ChildComponentReset'] = isFileDelete;
            else
            value['ChildComponentReset'] = isFileDelete;

            value['previousControlValue'] = '';

        });

    }

    resetMasterForm(masterFormAliasName: string, childFormSequence: number, formIndex: number, isFileDelete: filetypeEnum) {
        
        let that = this;
        this.masterJsonDataMaster.forEach(function (valueMaster:any, index:number) {
            // that.GenericFormGroup(valueMaster['formAliasName']).reset();
            // let filecomtrols = valueMaster['formData'].filter(x => x.control.toLowerCase() == 'file');
            // filecomtrols.forEach(function (value, index) {
            //         value['ChildComponentReset'] = isFileDelete;
            //         value['previousControlValue'] = '';

            // });

            if (+valueMaster['formSequence'] > 1) {
                that.childTableDataDynamicArrayList[valueMaster['formAliasName']].splice(0, that.childTableDataDynamicArrayList[valueMaster['formAliasName']].length);
                that.resetChildForm(valueMaster['formAliasName'], +valueMaster['formSequence'], index - 1, filetypeEnum.YesWithDel);
            }
            else {
                that.GenericFormGroup(masterFormAliasName)?.reset(that.masterJsonDataDefaultValue[masterFormAliasName]);
                let filecomtrols = that.masterJsonData[formIndex]['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    if(value.control.toLowerCase() == 'file')
                    value['ChildComponentReset'] = isFileDelete;
                    else
                    value['ChildComponentReset'] = 1;

                    value['previousControlValue'] = '';

                });

            }

        });

    }

    resetEditForm(masterFormAliasName: string, childFormSequence: number, formIndex: number, isFileDelete: filetypeEnum, callfrom: boolean) {
        
        let that = this;
        this.masterJsonDataMasterEdit.forEach(function (valueMaster:any, index:any) {

            if (+valueMaster['formSequence'] > 1) {
                that.childTableDataDynamicArrayListEdit[valueMaster['formAliasName']].splice(0, that.childTableDataDynamicArrayListEdit[valueMaster['formAliasName']].length);
                that.resetChildFormEdit(valueMaster['formAliasName'], +valueMaster['formSequence'], index - 1, filetypeEnum.YesWithDel);

                let filecomtrols = valueMaster['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    if(value.control.toLowerCase() == 'file'){
                    value['ChildComponentReset'] = isFileDelete;
                    that.deleteAttachmentForEditForm(that.GenericFormGroupEdit(valueMaster['formAliasName'])?.value[value['controlName']]);
                    }
                    else
                        value['ChildComponentReset'] = 1;
                });

            }
            else if (+valueMaster['formSequence'] == 1) {
                // that.GenericFormGroupEdit(masterFormAliasName).reset(that.masterJsonDataDefaultValueEdit[masterFormAliasName]);
                let filecomtrols = valueMaster['formData'].filter((x:any) => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
                filecomtrols.forEach(function (value:any, index:number) {
                    if(value.control.toLowerCase() == 'file'){
                    value['ChildComponentReset'] = isFileDelete;
                    if (callfrom && value['previousControlValue'] =="")
                        that.deleteAttachmentForEditForm(that.GenericFormGroupEdit(valueMaster['formAliasName'])?.value[value['controlName']]);
                    else if (!callfrom && that.GenericFormGroupEdit(valueMaster['formAliasName'])?.value[value['controlName']] != value['previousControlValue'])
                        that.deleteAttachmentForEditForm(value['previousControlValue']);
                    }
                    else
                        value['ChildComponentReset'] = 1;

                });

            }

        });

    }


    openResetModal(childFormAliasName: string, childFormSequence: number, formIndex: number, isFileDelete: filetypeEnum) {
        this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset? you will losse all below data.", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.resetMasterForm(childFormAliasName, childFormSequence, formIndex, isFileDelete);
                }
            });
    }

    CustomFilterusingColumn() {
        if (this.selectedItemsCustomColumnSearchdrp.length > 0) {
            $('#' + this.Detailtableid).DataTable()
                .column(this.selectedItemsCustomColumnSearchdrp[0].id)
                .search(this.customSearch)
                .draw();
        }
    }
    retainFilter(ms:any) {
        setTimeout(() => {
            if (this.selectedItemsCustomColumnSearchdrp.length > 0 && this.customSearch != '')
                this.CustomFilterusingColumn();

        }, ms);
    }
    SearchEdit() {
        
        // this.spinner.show();

        let dataRow = [];
        this.displayMainEdit = "block";
        this.Detailtableid = 'EditDynamictable' + this.SeltableID.toString().replace(/\s/g, '');
        // $('#' + this.Detailtableid).DataTable().destroy();

        // let isTrigger: boolean = false;
        // let that = this;
        // this.userService.getCustomViewForm(this.SeltableID, this.SeltableIDForm).subscribe((data: any) => {
        //     that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? '1' : '0', itemName: 'S.No.' });
        //     let TrData = '';
        //     let ThHeader;
        //     if (this.DetailtableHeaderData.length == 0) {
        //         this.DetailtableHeaderData = data.table;
        //         let headerdata = data.table.filter((x:any) => x['columN_NAME'] != 'id' && x['columN_NAME'] != 'guid')
        //         headerdata.forEach(function (obj:any, index:string) {
        //             that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? (index + 2) : (index + 1), itemName: obj['columnAliasName'] });
        //         });
        //         // this.dtTriggerDetail.next();
        //         isTrigger = true;
        //     }
        //     dataRow = data.table1;
        //     if (this.ischeckboxshow > 0) {
        //         dataRow.forEach((obj:any) => {
        //             obj.isSelected = false
        //         });
        //     }
        //     if (dataRow.length > 0)
        //         this.isWorkflowImplemented = dataRow[0]['workFlowImplemented'];
        //     this.tooltipHeader = this.DetailtableHeaderData.filter(th => th['isViewLink'] == true || th['columN_NAME'] == 'text');
        //     // if(this.tooltipHeader.length > 1)
        //     // {
        //     //     this.tooltipIsViewLink = this.tooltipHeader.filter(th => th['isViewLink'] == true)[0]['columN_NAME'];
        //     //     this.tooltipText = this.tooltipHeader.filter(th => th['columN_NAME'] == 'text')[0]['columN_NAME'];               
        //     // }
        //     // else{
        //     //     this.tooltipText = this.tooltipHeader.filter(th => th['isViewLink'] == true)[0]['columN_NAME']; 
        //     //     console.log(this.tooltipText);
        //     // }
        //     dataRow.forEach((obj:any) => {
        //         let isViewLink = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['isViewLink'] == true)[0]['columN_NAME']);
        //         let colNameValue = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['columN_NAME'] == 'text')[0]['columN_NAME']);
        //         if (that.tooltipHeader.length > 1 && isViewLink && colNameValue) {
        //             obj.toolTipRow = obj[colNameValue] + '(' + obj[isViewLink] + ')';

        //         }
        //         else if(isViewLink) {
        //             obj.toolTipRow = obj[isViewLink];
        //         }
        //     });
        //     this.DetailtableRowData = dataRow;
        //     console.log(this.DetailtableRowData);

        // },
        //     (err: HttpErrorResponse) => {
        //         this.spinner.hide();

        //         if (err.status == 401) {
        //             this.AlertTitle = "Error!!";
        //             this.AlertMessage = "You are not authorized!";
        //             this.AlertClass = "NP_emoji-fail-bg";
        //             this.AlertAnimationType = "sad";
        //             this.AlertType = " NP_popup_fail";
        //         }
        //         else if (err.status == 403) {
        //             this.Customvalidation.loginroute(err.status);
        //         }
        //         else {

        //             this.AlertTitle = "Error!!";
        //             this.AlertMessage = err.message.toString();
        //             this.AlertClass = "NP_emoji-fail-bg";
        //             this.AlertAnimationType = "sad";
        //             this.AlertType = " NP_popup_fail";
        //         }
        //         this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        //     },
        //     () => {


        //         this.dtTriggerDetail.next(true);
        //         if (this.ischeckboxshow == 2) {
        //             this.masterSelected = true;
        //             this.checkUncheckAll();
        //         }
        //         this.spinner.hide();

        //     });
    }


    onMainTab() {
        this.paramvaluedata = '';
        this.displayMain = "block";
        this.displayMainEdit = "none";
        this.isChildRowEdit = false;
    }
    onViewTab() {
        let that = this;
        this.childJsonData.forEach(function (formValue:any, formIndex:any) {
            if (that.childTableDataDynamicArrayList[formValue['formAliasName']].length > 0)
                that.childTableDataDynamicArrayList[formValue['formAliasName']].splice(0, that.childTableDataDynamicArrayList[formValue['formAliasName']].length);
        });
        if (that.childTableDataDynamicArrayListSumColumn.length > 0)
            that.childTableDataDynamicArrayListSumColumn.splice(0, that.childTableDataDynamicArrayListSumColumn.length);
        this.SearchEdit();
        this.isRenderDetail += 1;
        this.paramvaluedata = '';
        this.displayMain = "none";
        this.displayMainEdit = "block";
        this.isChildRowEdit = false;
    }
    onOpenEditMasterModal() {
        jQuery('#EditMasterModal').show();
        this.display = "block";
    }
    onCloseEditMasterModal(isFileDelete: filetypeEnum, callfrom: boolean) {
        
        jQuery('#EditMasterModal').hide();
        this.display = "none";
        this.resetEditForm(this.parentFormAliasNameEdit, 1, 0, isFileDelete, callfrom);
        this.updateRecordID = null;
        this.isRenderEdit = false;
        this.parentFormAliasNameEdit = '';
        if (this.masterJsonDataEdit.length > 0)
            this.masterJsonDataEdit.splice(0, this.masterJsonDataEdit.length);
        if (this.masterJsonDataMasterEdit.length > 0)
            this.masterJsonDataMasterEdit.splice(0, this.masterJsonDataMasterEdit.length);
        if (this.childTableDataDynamicArrayListEdit.length > 0)
            this.childTableDataDynamicArrayListEdit.splice(0, this.childTableDataDynamicArrayListEdit.length);
        if (this.childTableDataDynamicArrayListSumColumnEdit.length > 0)
            this.childTableDataDynamicArrayListSumColumnEdit.splice(0, this.childTableDataDynamicArrayListSumColumnEdit.length);


    }
    updateEditMasterForm(FormAliasName: string) {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let genericColumnListParam = [];
        let objListfinal = <FormFinalObjectParam>{};
        let objColumn = <lstFormFinalObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';
        let columnNamefinal = "";
        let columnValuefinal = "";
        that.masterJsonDataEdit[0]['formData'].forEach(function (value:any, index:number) {
            
            let objList = <ObjectParam>{};
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let colValue;
                let formcontrolValue;
                let CompareColumnValue;
                if (value["controlType"].toLowerCase() == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"].toLowerCase() == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                }
                else if (value["controlType"].toLowerCase() == "checkbox") {
                    let chkValue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false || chkValue == undefined) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"].toLowerCase() == "file") {
                    formcontrolValue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue
                    }
                    else {
                        colValue = '';
                    }
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    formcontrolValue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = that.commonfunction.ConvertDateFormat(formcontrolValue);
                    }
                    else {
                        colValue = '';
                    }
                }
                else {
                    formcontrolValue = that.GenericFormGroupEdit(FormAliasName)?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue
                    }
                    else {
                        colValue = '';
                    }
                }

                // if (colValue !== null && colValue !== '') {

                    columnNamefinal = columnNamefinal + value["columN_NAME"] + '^';
                    if (value["datA_TYPE"].toLowerCase().toString() == "date" || value["datA_TYPE"].toLowerCase().toString() == "datetime" || value["datA_TYPE"].toLowerCase().toString() == "smalldatetime")
                        columnValuefinal = columnValuefinal + "Date_" + colValue + "^";
                    else
                        columnValuefinal = columnValuefinal + colValue + "^";
                // }
            }
        });

        if (columnNamefinal !== "" && columnValuefinal != "") {
            columnNamefinal = columnNamefinal + "ModifiedBy^modifiedRoleId^";
            columnValuefinal = columnValuefinal + that.userId  + "^" + that.currentRole + "^";
        }
        if (flag == true) {
            let childTableArraysIsRequired = this.childJsonDataEdit.filter((xisReqChk: any) => xisReqChk['formIsRequired'].toLowerCase() == 'true');
            childTableArraysIsRequired.forEach(function (valueCJson:any, index:any) {
                // if (that.childTableDataDynamicArrayListEdit[valueCJson['formAliasName']].length <= 0) {
                //     flag = false;
                //     msg = "Please Enter Details for " + that.commonfunction.SpaceAfterCapitalLetter(valueCJson['formAliasName']) + ".";
                // }
                let WFTableName = that.masterJsonDataEdit[0]['formTableName'].toString();
                if (that.childTableDataDynamicArrayListEdit[valueCJson['formAliasName']].length <= 0) {
                    flag = false;
                    msg = "Please Enter Details for " + that.commonfunction.SpaceAfterCapitalLetter(valueCJson['formAliasName']) + ".";
                }
                else if (WFTableName == that.WFTBName) {
                    let resultWF = that.workFlowValidationEdit(valueCJson['formAliasName']);
                    if (!resultWF.GenericWorkFlowError.flag) {
                        flag = false;
                        msg = resultWF.GenericWorkFlowError.errorMsg;
                    }
                }
            });
        }
        else {
            flag = false;
        }

        if (flag == true) {
            objListfinal.tbleName = this.masterJsonDataEdit[0]['formTableName'].toString();
            objListfinal.tbleSequence = this.masterJsonDataEdit[0]['formSequence'].toString();
            objListfinal.columnName = columnNamefinal;
            objListfinal.ColumnValue = columnValuefinal;
            genericColumnListParam.push(objListfinal);

            this.childJsonDataEdit.forEach(function (valueCJson:any, index:any) {
                if (that.childTableDataDynamicArrayListEdit[valueCJson['formAliasName']].length > 0) {
                    that.childTableDataDynamicArrayListEdit[valueCJson['formAliasName']].forEach(function (valuegetJson:any, indexC:number) {
                        let objListchildsFinal = <FormFinalObjectParam>{};
                        objListchildsFinal.tbleName = valueCJson['formTableName'].toString();
                        objListchildsFinal.tbleSequence = valueCJson['formSequence'].toString();
                        objListchildsFinal.columnName = valuegetJson['processedColumnName'];
                        objListchildsFinal.ColumnValue = valuegetJson['processedColumnValue'].replace(/"/g, "'");
                        genericColumnListParam.push(objListchildsFinal);
                    });
                }
            });
        }

        if (genericColumnListParam.length > 0) {
            objColumn.genericColumnListParam = genericColumnListParam;
            _data = JSON.stringify(objColumn);
        }

        if (flag == true) {
            this.openEditMasterModal();
            this.paramvaluedata = _data;
        }
        else {
            this.AlertTitle = "Alert!!";
            this.AlertMessage = msg.toString();
            this.AlertClass = "NP_emoji-error-bg";
            this.AlertAnimationType = "NP_emoji-error";
            this.AlertType = "NP_popup_error";

            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

        //   this.openEditMasterModal();
    }

    UpdateEditcall() {
        this.spinner.show();
        
        let wfStatusId = 1;
        if (this.isWorkflowImplemented) {
            wfStatusId = this.workflowstep == 1 ? 1 : this.workflowStatusId;
        }
        let updatedColumn = this.masterJsonDataEdit[0]['formData'].filter((x:any) => x.columN_NAME.toLowerCase() == 'id')
        let param = "uspAppGenericEditMultiTableFormData|tables=" + this.paramvaluedata + "|id=" + updatedColumn[0]['controlValue'] + "|wfStatusId=" + wfStatusId + "|formName=" + this.SeltableIDForm;
        this.userService.SubmitMultiTableForm(param, this.SeltableIDForm).subscribe((datacom: any) => {
            
            let resultarray = JSON.parse(datacom);
            // resultarray['successFlag'] 
            // resultarray['resultMessage']
            // resultarray['printAction']
            // resultarray['printActionResult']
            // resultarray['otherAction']
            // resultarray['otherActionResult']
            // resultarray['outputVarResult']
            this.spinner.hide();

            if (resultarray['successFlag'] == "success") {
                this.onCloseEditMasterModal(filetypeEnum.YesPreviousDel, false);
                this.SearchEdit();
                this.AlertTitle = "Success!!";
                this.AlertMessage = resultarray['resultMessage'].toString();
                this.AlertClass = "NP_emoji-success-bg";
                this.AlertAnimationType = "smile";
                this.AlertType = "NP_popup_success";
            }

            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = resultarray['resultMessage'].toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";

            }


        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                // this.retainFilter(1500);
                this.isRenderDetail += 1;

            });


    }



    openEditMasterModal() {
        this.globalConfirmService.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.UpdateEditcall();
                    this.paramvaluedata = '';
                }
            });
    }

   
    onfiledownloadDetail(event:any, flagDownload:number, filename:string, colmAlias:string) {
        
        this.userService.downloadFile(filename, colmAlias, this.SeltableIDForm).subscribe((res:any) => {
            if (res == "true") {
                if (flagDownload == 2) {

                    this.previewImageService.PreviewImageFunction('Preview', filename, colmAlias, this.SeltableIDForm, "Download", "Cancel", "sm")
                        .then((confirmed) => {
                            if (confirmed) {

                            }
                        });


                }
                else {
                    let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + filename + "&columnName=" + colmAlias;
                    // window.open(_url);
                    window.location.href = _url;
                }
            }
            else {

                this.AlertTitle = "Alert!!";
                this.AlertMessage = "File not Exists";
                this.AlertClass = "NP_emoji-error-bg";
                this.AlertAnimationType = "NP_emoji-error";
                this.AlertType = "NP_popup_error";
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();


                if (err.status == 401) {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            });
    }
    setFileControlValidation(fileName: string, fileErrorMessage: string, fileErrror: boolean, formSequence: string, columnname: string, callfrom: string, controlName: string, formAliasName: string) {
        
        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + fileName + '"}');
        let requiredFileValidation = [];
        let formValue = [];


        formValue = callfrom == "E" ? this.masterJsonDataMasterEdit.filter((x:any) => x['formAliasName'] == formAliasName) : this.masterJsonDataMaster.filter((x:any)=> x['formAliasName'] == formAliasName);
        requiredFileValidation = formValue[0]["formData"].filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredFileValidation[0]["customValidationMsg"] != "" && requiredFileValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredFileValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
            if (requiredFileValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
                this.GenericFormGroup(formAliasName)!.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            }
            else {
                this.GenericFormGroup(formAliasName)!.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            }
            this.GenericFormGroup(formAliasName)?.patchValue(newValue);

        }

        else if (callingfrom == 'E') {
            if (requiredFileValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
                this.GenericFormGroupEdit(formAliasName)!.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            }
            else {
                this.GenericFormGroupEdit(formAliasName)!.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            }
            this.GenericFormGroupEdit(formAliasName)?.patchValue(newValue);
        }
    }
    upFile(event: IfileTypeOutput, columnname: string, callfrom: string, controlName: string, formAliasName: string) {
        
        event.formSequence
        let colname = columnname;
        let callingfrom = callfrom;
        console.log(this.masterJsonDataMaster);
        let fName = event.fileName ? event.fileName : '';
        let fMessage = event.fileErrorMessage ? event.fileErrorMessage : '';
        let ferror = event.fileErrror? event.fileErrror: false;
        let fSequence = event.formSequence ? event.formSequence : '';
        this.setFileControlValidation(fName, fMessage, ferror, fSequence, columnname, callfrom, controlName, formAliasName);
        const newValue = JSON.parse('{"' + controlName + '":"' + event.fileName + '"}');

        if (callingfrom == 'I') {
            // this.GenericFormGroup(formAliasName).get(controlName).setValidators([custumValidation['validateFileUpoadErrorMsg'](event.fileErrorMessage, event.fileErrror)]);
            // this.GenericFormGroup(formAliasName).patchValue(newValue);
            this.GenericFormGroup(formAliasName)!.get(controlName)?.markAsTouched();
        }
        else if (callingfrom == 'E') {
            //    this.masterProductFormEdit.patchValue(newValue);

            // this.GenericFormGroupEdit(formAliasName).get(controlName).setValidators([custumValidation['validateFileUpoadErrorMsg'](event.fileErrorMessage, event.fileErrror)]);
            // this.GenericFormGroupEdit(formAliasName).patchValue(newValue);
            this.GenericFormGroupEdit(formAliasName)!.get(controlName)?.markAsTouched();
        }

    }

    
    onTimeChanged(event: string, columnname: string, callfrom: string, controlName: string, formAliasName: string) { 
        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + event + '"}');
        let requiredTimerValidation = [];
        let formValue = [];


        formValue = callfrom == "E" ? this.masterJsonDataMasterEdit.filter((x:any) => x['formAliasName'] == formAliasName) : this.masterJsonDataMaster.filter((x:any)=> x['formAliasName'] == formAliasName);
        requiredTimerValidation = formValue[0]["formData"].filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredTimerValidation[0]["customValidationMsg"] != "" && requiredTimerValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredTimerValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.GenericFormGroup(formAliasName)?.patchValue(newValue);

        }
        else if (callingfrom == 'M') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.GenericFormGroup(formAliasName)?.patchValue(newValue);
        }
        else if (callingfrom == 'E') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.GenericFormGroupEdit(formAliasName)?.patchValue(newValue);
        }
        if (callfrom == 'E')
        this.GenericFormGroupEdit(formAliasName)!.get(controlName)?.markAsTouched();
        else
        this.GenericFormGroup(formAliasName)!.get(controlName)?.markAsTouched();

    }

    onToggleChanged(event: boolean, columnname: string, callfrom: string, controlName: string, formAliasName: string) { 

        let tValue = 0;
       if(event)
            tValue = 1;
        else
            tValue = 0;

        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + tValue + '"}');
        let requiredTimerValidation = [];
        let formValue = [];


        formValue = callfrom == "E" ? this.masterJsonDataMasterEdit.filter((x:any) => x['formAliasName'] == formAliasName) : this.masterJsonDataMaster.filter((x:any)=> x['formAliasName'] == formAliasName);
        requiredTimerValidation = formValue[0]["formData"].filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredTimerValidation[0]["customValidationMsg"] != "" && requiredTimerValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredTimerValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
            
            this.GenericFormGroup(formAliasName)?.patchValue(newValue);

        }
        else if (callingfrom == 'M') {
            
            this.GenericFormGroup(formAliasName)?.patchValue(newValue);
        }
        else if (callingfrom == 'E') {
           
            this.GenericFormGroupEdit(formAliasName)?.patchValue(newValue);
        }
        if (callfrom == 'E')
        this.GenericFormGroupEdit(formAliasName)!.get(controlName)?.markAsTouched();
        else
        this.GenericFormGroup(formAliasName)!.get(controlName)?.markAsTouched();

    }

    commonCustumFunction(param:string) {
        let that = this;
        this.userService.getCommonDataSet(this.SeltableIDForm.toString(), param).subscribe((data: any) => {
            
            eval(data.table[0]['msg']);

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();

                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.spinner.hide();
            });
    }

    checkUncheckAll() {
        
        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            value.isSelected = that.masterSelected;
        });
        this.getCheckedItemList();
    }
    isAllSelected() {
        this.masterSelected = this.DetailtableRowData.every(function (item: any) {
            return item.isSelected == true;
        })
        this.getCheckedItemList();
    }

    getCheckedItemList() {
        this.selectedRows = [];
        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            if (value.isSelected) {
                that.selectedRows.push(value);
            }
        });
    }

    sendMessageArrayList() {

    }
    sendEmailArrayList() {

    }
    onActionArrayList() {

    }


    OnDynamicGenericWorkFlow(value:any, WorkFlowApprove: boolean, WorkFlowReject: boolean, WorkFlowReturn: boolean, WorkFlowForword: boolean, recordId: string, tableName: string, record: string) {
        
        let that = this;
        // let updatedColumn = this.masterJsonDataEdit['formData'].filter(x => x.columN_NAME.toLowerCase() == 'text');
        this.GlobalWorkFlowProcessService.WorkFlowFunction(" Record - " + record, WorkFlowApprove, WorkFlowReject, WorkFlowReturn, WorkFlowForword, recordId, tableName, this.SeltableIDForm, "Submit", "Cancel", "md")
            .then((confirmed) => {
                
                if (confirmed["GenericWorkFlow"].flag) {
                    this.SearchEdit();
                    this.globalAlertService.AlertMsg(this.AlertTitle, confirmed["GenericWorkFlow"].errorMsg, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                    this.retainFilter(1500);
                }
            });

    }

    

    calanderClick(inputControl:string) {
        jQuery('#' + inputControl).click();
    }

    textBoxChangeEvent(event: Event, formAliasName: string, customActionName: string, isEdit: boolean) {
        
        if (customActionName != undefined && customActionName != null && customActionName != "") {
            if (isEdit)
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductFormEdit, this.childTableDataDynamicArrayListSumColumnEdit, formAliasName, this.parentFormAliasNameEdit, true, true,this.masterJsonDataEdit,this.childTableDataDynamicArrayListEdit[formAliasName]);
            else
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductForm, this.childTableDataDynamicArrayListSumColumn, formAliasName, this.parentFormAliasName, true, false,this.masterJsonData,this.childTableDataDynamicArrayList[formAliasName]);

        }
    }

    buttonCustomEvent(event: Event, formAliasName: string, customActionName: string, isEdit: boolean) {
        
        if (customActionName != undefined && customActionName != null && customActionName.trim() != "") {
            if (isEdit)
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductFormEdit, this.childTableDataDynamicArrayListSumColumnEdit, formAliasName, this.parentFormAliasNameEdit, true, true,this.masterJsonDataEdit,this.childTableDataDynamicArrayListEdit[formAliasName]);
            else
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductForm, this.childTableDataDynamicArrayListSumColumn, formAliasName, this.parentFormAliasName, true, false,this.masterJsonData,this.childTableDataDynamicArrayList[formAliasName]);

        }
    }

    deleteAttachmentForEditForm(fileName:string) {
        let fileOutPut: IfileTypeOutput = {};
        let that = this;
        this.userService.deleteFile(fileName, this.SeltableIDForm.toString()).subscribe((res:any) => {
        });
    }

    GenericPrintFunction() {
        if (this.GenericPrintDataArray != null) {
            // let printContents, popupWin;
            // setTimeout(() => {
            //     //   printContents = document.getElementById('printContaint').innerHTML;
            //     printContents = jQuery('#printContaint').html();
            //     let FINALDATA = printContents.replace("display: none", "");
            //     popupWin = window.open('', '_blank', '');
            //     popupWin.document.open();
            //     popupWin.document.write(`<html>
            //  <body style="font-family: sans-serif" onload="window.print();window.close()">jQuery{FINALDATA}</body></html>`);
            //     popupWin.document.close();
            //     setTimeout(() => {
            //         popupWin.document.write(`<html><body>jQuery{printContents}</body></html>`);
            //     }, 200);
            // }, 500);

            this.genericPrintService.GenericPrintFunction(this.GenericPrintDataArray);
        }
    }

}
