<div class="edit-modal-form">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Gallery</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateEditMasterForm">
        <div class="row" >
            <div class="col-md-12">
                <div class="ImageShowBox">
                    <label class="ImageShow">
                        <div class="img_box">
                            <img [src]="gallerydata.imgurl" alt="Image Preview">
                        </div>
                        <div class="hoverImg">
                            <div class="hover_content">
                                <img style="width: 30px; display: block;" class="hoverImgIcon"
                                    src="assets\img\Mask group1.png" alt="Upload Icon">
                                <span class="text-light">Upload</span>
                            </div>
                        </div>
                        <!-- <input style="display: none;" accept=".png , .jpg , .webp"  type="file" (change)="onFileSelected($event,'caraousel')"> -->
                    </label>
                </div>
            </div>

            <div class="col-md-12 mt-3">
                <select name="" id="" formControlName="tabs" class="form-control">
                    <option value="Select">Select</option>
                    <option value="Event">Event</option>
                    <option value="Highlights">Highlights</option>
                </select>
            </div>

            <div class="Submitbtn-content mt-3  ">
                <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">Cancel</button>
                <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickUpdate()">Update</button>
            </div>
        </div>
    </div>
</div>