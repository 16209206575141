<div class="aboutsection">
  <div class="aboutsection_wrapper position-relative">
    <div class="bg_img_about categorieBacImg" style="background-image: url(../../../assets/img/bg-gym.jpg);"></div>
    <div class="bg_about_overly">
      <div class="publicPageHeadingCss container">
        <div class="headline" data-aos="fade-right">
          <h1>Trending Categories</h1>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page
            when looking at its layout.</p>
        </div>
        <ngb-carousel>
          <ng-template ngbSlide *ngFor="let group of groupedCategories">
            <div class="row">
              <div *ngFor="let item of group" class="col">
                <div class="categoriesBox">
                  <div class="categoriesBoxImg">
                    <img [src]="item.ImgUrl" alt="">
                  </div>
                  <div>
                    <h3>{{item.Heading}}</h3>
                    <p>{{item.Content}}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>


      </div>


    </div>
  </div>
</div>