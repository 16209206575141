import { Component, HostListener, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';


@Component({
  selector: 'app-public-genric-categories',
  templateUrl: './public-genric-categories.component.html',
  styleUrls: ['./public-genric-categories.component.css']
})
export class PublicGenricCategoriesComponent implements OnInit {
  GenricCategories:any;
  groupedCategories: any[] | undefined;
  
  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.GenricCategories = this.commonfunction.loadJson('~/../assets/categories.json');
    console.log(this.GenricCategories);
    this.updateLayout(); 
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateLayout(); // Update layout when window is resized
  }

  updateLayout(): void {
    const screenWidth = window.innerWidth;
    let itemsPerSlide = 1;

    if (screenWidth >= 992) {
      // Desktop: 4 items per slide
      itemsPerSlide = 4;
    } else if (screenWidth >= 768) {
      // Tablet: 3 items per slide
      itemsPerSlide = 3;
    } else {
      // Mobile: 1 item per slide
      itemsPerSlide = 1;
    }

    this.groupedCategories = this.chunk(this.GenricCategories, itemsPerSlide);
  }

  // Helper function to chunk the categories array into groups
  chunk(array: any[], size: number): any[] {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  }
}

