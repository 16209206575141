import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewImageService } from '../preview-image/preview-image.service';
import { GlobalAlertService } from '../global-alert.service';
import { UserService } from '../user.service';
import { Commonfunction } from '../commonutility';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { Customvalidation } from "../../shared/Validation";

@Component({
  selector: 'app-generic-table-view-alert',
  templateUrl: './generic-table-view-alert.component.html',
  styleUrls: ['./generic-table-view-alert.component.css']
})
export class GenericTableViewAlertComponent implements OnInit {
  @Input() DialogTitle: string |null |undefined;
 
  @Input() btnOkText: string |null |undefined;
  @Input() AlertDialogType: string |null |undefined;
  @Input() AlertDialogClass: string |null |undefined;
 
  @Input() ViewDetailChildtableHeaderData = [];
  @Input() ViewDetailChildtableRowData = [];
  @Input() formName: string = "";
  callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
  constructor(private activeModal: NgbActiveModal,private previewImageService: PreviewImageService,
    private userService: UserService, private commonfunction: Commonfunction, private spinner: NgxSpinnerService, 
    private globalAlertService: GlobalAlertService, public Customvalidation: Customvalidation) { }

  ngOnInit() {
  }

  public onCloseAlertModal() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  onfiledownloadDetail(event:any, flagDownload:number, filename:string, colmAlias:string) {
    
    this.userService.downloadFile(filename, colmAlias,  this.formName).subscribe((res:any) => {
        if (res == "true") {
            if (flagDownload == 2) {
  
                this.previewImageService.PreviewImageFunction('Preview', filename, colmAlias,  this.formName, "Download", "Cancel", "sm")
                    .then((confirmed) => {
                        if (confirmed) {
  
                        }
                    });
  
  
            }
            else {
                let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + filename + "&columnName=" + colmAlias;
                // window.open(_url);
                window.location.href = _url;
            }
        }
        else {
  
            this.AlertTitle = "Alert!!";
            this.AlertMessage = "File not Exists";
            this.AlertClass = "NP_emoji-error-bg";
            this.AlertAnimationType = "NP_emoji-error";
            this.AlertType = "NP_popup_error";
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
  
        }
    },
        (err: HttpErrorResponse) => {
            this.spinner.hide();
  
  
            if (err.status == 401) {
  
                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (err.status == 403) {
                this.Customvalidation.loginroute(err.status);
            }
            else {
                this.AlertTitle = "Error!!";
                this.AlertMessage = err.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        });
  }

ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}
}
