import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Response, RequestOptions } from "@angular/http";
import { map } from 'rxjs/operators';
import { User } from "../shared/user.model";
import { AuthGuard } from "../auth/auth.guard";
import { environment } from '../../environments/environment';
import { Commonfunction } from './commonutility';
import { Observable,BehaviorSubject } from 'rxjs';

// import{ bcrypt } from 'bcrypt';

//import 'rxjs/add/operator/map';
export const URLs = {
  getAllEmployeeAttendance:'assets/json/getAllEmployeeAttendance.json',
  getUserTimeLog:'assets/json/getUserTimeLog.json',
  getUserLeaveData:'assets/json/getUserLeaveData.json',
  getNewEmployeeData:'assets/json/getNewEmployeeData.json'
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // readonly rootUrl ='http://172.20.10.3:6449';
  //  readonly SSOrootUrl ='http://localhost:60450';
  public ststusNav: boolean = false;
  readonly rootUrl = environment.rootUrl;
  private messageSource = new BehaviorSubject("");
  currentMessage = this.messageSource.asObservable();

  private toggleClassSecurePayFeeClick = new BehaviorSubject(false);

  changeToggleClass(value:boolean){
    this.toggleClassSecurePayFeeClick.next(value);
  }

  readToggleClass(){
    return this.toggleClassSecurePayFeeClick.asObservable();
  }

  private toggleClassTBFilterClick = new BehaviorSubject(false);

  changeClass(value:boolean){
    console.log(value);
    this.toggleClassTBFilterClick.next(value);
  }

  readClass(){
    return this.toggleClassTBFilterClick.asObservable();
  }

  // currentRoleString: string|null| undefined ="";
  // currentRole: string|null| undefined ="";
  headerSettings: { [name: string]: string | string[]; } = {};
  constructor(private http: HttpClient, private commonfunction: Commonfunction) { 
    // this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole')) ? sessionStorage.getItem("currentRole")?.toString():null;
    // this.commonfunction.getCurrentUserRoleId() =  this.currentRoleString ? JSON.parse(this.currentRoleString)?.roleId : null;
    
  }
  //constructor() { }
  Setdynamicformparam(param: string) {
    this.messageSource.next(param)
  }
  FirstLetterLower(value:string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }
  getdynamicformparam() {
    return this.messageSource.asObservable();
    // this.currentMessage;
  }
  userAuthentication(userName: string, password: string) {

    let data = "username=" + userName + "&password=" + password + "&grant_type=password";
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
  }
  userAuthenticationLogOut(userToken: string, userId: string) {
    let _data = JSON.stringify("uspAppGenericUserLogOut|username=" + userId + "|userToken=" + userToken);
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'dataType': 'json', 'No-Auth': 'True' });
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterfromBodyData', _data, { headers: reqHeader });
  }
  userAuthenticationLogOutSYC(userToken: string, userId: string) {
    let _data = "uspAppGenericUserLogOut|username=" + userId + "|userToken=" + userToken;
    console.log("Ending chat..");
    let xhr = new XMLHttpRequest()
    xhr.open("POST", this.rootUrl + "/values/PostReturnOutParameter/?UiParam=" + _data, false);
    xhr.send();
  }
  UpdateClaims(userToken: string, userId: string, TokenExpireAfter: number,externalIPAddress:any,localIpAddress: any) {
    let data = JSON.stringify("uspAppGenericUserUpdateToken|userToken=" + userToken + "|userId=" + userId + "|TokenExpireAfter=" + TokenExpireAfter.toString()+ "|IPAddressDetails="+ localIpAddress?.toString() + "|ExternalIPAddress=" + externalIPAddress?.toString());
    let reqHeader = new HttpHeaders({ 'content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.rootUrl + '/Values/authenticate', data, { headers: reqHeader });
  }

  getUserClaims(userId: string, activity: string) {
    let authlet = 'Bearer ' + sessionStorage.getItem('userToken');
    let _data = "uspAppGenericUserGetDetailUsingToken|userId=" + userId + '|RoleId=' + this.commonfunction.getCurrentUserRoleId();
    //  let reqHeader = new HttpHeaders({ 'content-Type': 'application/json'});
    this.headerSettings = {};
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = activity;
    this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/Values/GetUpdateDataSet/?UiParam=' + _data, { headers: newHeader });
  }

  getEmail(EmailId: string) {
    let _data = "uspAppGenericUserValidateLoginEmail|email=" + EmailId;
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/Values/Check_SendEmail/?UiParam=' + _data, { headers: newHeader });
  }
  ResetPassword(Pass: string, token: string) {

    let _data = JSON.stringify("uspAppGenericUserResetPasswordUsingToken|password=" + Pass + "|RestPasswordLink=" + token);
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    // return this.http.get(this.rootUrl + '/Values/GetDataSet/?UiParam='+_data, {headers: newHeader});
    return this.http.post(this.rootUrl + '/Values/ResetPasswordfromBody', _data, { headers: newHeader });
  }

  ResetPasswordtokencheck(token:string) {

    let _data = "uspAppGenericCheckResetPasswordToken|RestPasswordLink=" + token;
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/Values/checkResetPasswordToken/?UiParam=' + _data, { headers: newHeader });
    // return this.http.post(this.rootUrl + '/Values/ResetPasswordfromBody', _data,{ headers: newHeader });
  }

  getMasterFormPageLoadDrp() {
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetTableNamesWithAlias|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  MasterDrpOnChange(tblname: string,basetblname:string,ValueColumn:string,filterValueColumn:string,filtervalue:string,valueFillType:string,childTableName:string,queryId:string,formName:string) {
    this.headerSettings = {};
     
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let result = this.http.get(this.rootUrl + "/values/GetDataSet/?UiParam=uspAppGenericDropdownValuesOnchange|tableName=" + tblname + "|basetableName=" + basetblname + "|ValueColumn=" + ValueColumn + "|filterValueColumn=" + filterValueColumn + "|filtervalue=" + filtervalue + "|valueFillType=" + valueFillType+ "|childTableName=" +childTableName + "|queryId=" + queryId+ '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    // let result =  this.http.get(this.rootUrl +"/values/GetDataSet/?UiParam=uspMasterGetTableDropdownValuesOnPageLoad|"+ rurl, {headers: newHeader}).toPromise().then((response => response) => { return response}).catch();
    return result;
  }
  ReportDrpOnChange(tblname: string,basetblname: string,ValueColumn: string,filterValueColumn: string,filtervalue: string,valueFillType: string,childTableName: string,queryId: string,formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let result = this.http.get(this.rootUrl + "/values/GetDataSet/?UiParam=uspAppGenericReportDropdownValuesOnchange|tableName=" + tblname + "|basetableName=" + basetblname + "|ValueColumn=" + ValueColumn + "|filterValueColumn=" + filterValueColumn + "|filtervalue=" + filtervalue + "|valueFillType=" + valueFillType+ "|childTableName=" +childTableName + "|queryId=" + queryId+ '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    // let result =  this.http.get(this.rootUrl +"/values/GetDataSet/?UiParam=uspMasterGetTableDropdownValuesOnPageLoad|"+ rurl, {headers: newHeader}).toPromise().then((response => response) => { return response}).catch();
    return result;
  }

  GetDataSetResult(SpParams: string,formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let result = this.http.get(this.rootUrl + "/values/GetDataSet/?UiParam="+ encodeURIComponent(SpParams) +'|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    // let result =  this.http.get(this.rootUrl +"/values/GetDataSet/?UiParam=uspMasterGetTableDropdownValuesOnPageLoad|"+ rurl, {headers: newHeader}).toPromise().then((response => response) => { return response}).catch();
    return result;
  }

  getMasterFormPage(tbname: string, formName: string) {
    // let sendperm = JSON.parse(tbname);
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetMasterForm/?UiParam=uspAppGenericGetTableDetailswithColumnAlias|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=insert', { headers: newHeader });
  }

  getPublicMasterFormPage(tbname: string, formName: string) {
    // let sendperm = JSON.parse(tbname);
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True' ;
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetPublicMasterForm/?UiParam=uspAppGenericGetTableDetailswithColumnAlias|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=insert', { headers: newHeader });
  }

  SubmitForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericSubmitDataSingleTable|query=" + paramsparameter + '|formName='+ formName +'|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserialize', data, { headers: newHeader });
  }
  getViewForm(tbname: string, formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericViewGetTableDetails|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  getEditViewForm(tbname: string, formName: string) {
    // let sendperm = JSON.parse(tbname);
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericViewGetTableDetails|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  //Added By Naveen AT
  ActionActivesubmit(id: string, action: string, formName: string, tableName: string, formType: string) {
  
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    // if (action == "Deleted") {
      return this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericTableActions|tableName=' + tableName + '|id=' + id + '|formType=' + formType + '|Action=' + action + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    // }
    // else if (action == "Active" || action == "InActive") {

    //   return this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericTableActions|tableName=' +  $('#SeltableID').val() + '|id=' + id+ '|Action=' + action  + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });

    // }
  }
  FormActionActivesubmit(id: string, action: string, formName: string) {
     let result:any;
     this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    if (action == "Deleted") {
      result = this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericTableEntryDelete|tableName=' + $('#tableName').val() + '|id=' + id + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    }
    else if (action == "Active" || action == "InActive") {

      result = this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericFormEntryAction|tableName=' + $('#tableName').val() + '|id=' + id + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });

    }
    return result;
  }
  TableAliasAction(id: string, action: string,userRoleId: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericTableEntryAliasActionData|action=' + action + '|id=' + id + '|userRoleId=' + userRoleId + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });

  } 
  MasterReportAliasAction(id: string, action: string,formType: string ,formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericMasterReportEntryAliasActionData|action=' + action + '|id=' + id + '|formType'+formType +'|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  } 

  generateCustumFormAliasAction(id: string, action: string,roleId: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericMasterCustumFormEntryAction|action=' + action + '|id=' + id +'|roleId=' + roleId + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  } 
  getEditMasterPage(tbname: string,rowid: string,callfrom: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let param ='uspAppGenericGetMasterTableViewDetailsEdit|tblname=' + tbname + '|id='+ rowid + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId();
    return this.http.get(this.rootUrl + '/values/GetMasterForm', { headers: newHeader, params:{
      UiParam: param,
      callfrom: callfrom
    } });
  }

  EditMasterForm(paramsparameter: string, id: string,wfStatusId: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericUpdateSingleTableData|query=" + paramsparameter + "|id=" + id +"|formName=" + formName +"|wfStatusId=" + wfStatusId + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeUpdate', data, { headers: newHeader });
  }


  getCommonDrpdownList(formName: string, paramValue: string) {
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);    
    let param =  paramValue + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId()
    return this.http.get(this.rootUrl + '/values/GetDropDownlist', { headers: newHeader, params:{
      UiParam: param
    } });
  }

  getFillDrpdown(formName: string, query: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';

    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=' + query, { headers: newHeader });
  }
  getUserMasterFormPageGenricDrp(formName: string, proc: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';

    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=' + proc, { headers: newHeader });
  }

  getUserMasterFormcolumnDetails(tbname: string, formName: string, userRoleId: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericTableGetDetails|tblname=' + tbname  + '|userRoleId=' + userRoleId + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=insert', { headers: newHeader });
  }
  UserAliasSubmitForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericSubmitTableAliasData|query=" + paramsparameter + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());

      // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutUserParameterDeserialize', data, { headers: newHeader });
  }

  CustumActionSubmitForm(data: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings); 
   
    return this.http.post(this.rootUrl + '/values/PostReturnOutCustumActionParameterDeserialize', data, { headers: newHeader });
  }

  getDataAlreadyExists(paramValue: string) {
    
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'home'; 
    const newHeader = new HttpHeaders(this.headerSettings);
    let param =  paramValue + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId()
    return this.http.get(this.rootUrl + '/values/GetDataSet', { headers: newHeader, params:{
      UiParam: param
    } })
  }
  getchildTableName(name: string, formName: string, userId: string) {
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGetChildTableNames|tblname=' + name + '|userId=' + userId+ '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getmultiFormPageLoadDrp(formName: string) {
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetTableNamesWithAlias|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  SubmitGenrateForm(paramsparameter: string, formName: string) {
    this.headerSettings = {}; 
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericMasterSubmitMultiTableData|tables=" + paramsparameter + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeMultiTable', data, { headers: newHeader });
  }

  getmultiFormPagedetailsView(formName: string) {
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGetFormNamesDetail|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  EditGenratedForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericTableEditFormTable|tables=" + paramsparameter + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());    
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeMultiTable', data, { headers: newHeader });
  }
  getCustomFormPageLoadDrp(formName: string) {
    this.headerSettings = {};
    // const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetFormNames|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  getGenericMultiTableFormPage(paramValue: string, formName: string, callfrom: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let param =  paramValue + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId()
    return this.http.get(this.rootUrl + '/values/GetCustomrForm', { headers: newHeader, params:{
      UiParam: param,
      callfrom:callfrom
    } });
    // return this.http.get(this.rootUrl + '/values/GetCustomrForm/?UiParam=uspAppGenericFormGetDetails|Formname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=I', { headers: newHeader });
  }

  SubmitMultiTableForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify(paramsparameter + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeMultiTable', data, { headers: newHeader });
  }

  getCustomViewForm(tbname: string, formName: string) {

this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGetFormViewDetails|formName=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
// common function for dataset Start
  getCommonDataSet(formName: string,paramValue: string) {
    this.headerSettings = {};
     
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let param =  paramValue + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId()
    return this.http.get(this.rootUrl + '/values/GetDataSet', { headers: newHeader, params:{
      UiParam: param
    } });
   }

// common function for dataset END

  CustomFormActionActivesubmit(id: string, letaction: string, TableId: string, formName: string) {  
    this.headerSettings = {};
    let result:any;   
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    if (letaction == "Deleted") {
      //  return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspMasterTableGetDetailsInList|tblname=' + tbname, {headers: newHeader});
      result = this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspAppGenericCustomFormDelete|tableName=' + TableId + '|id=' + id + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    }
    else if (letaction == "Active" || letaction == "InActive") {
      result = this.http.delete(this.rootUrl + '/values/Delete/?UiParam=uspCustomFormActiveData|tableName=' + TableId + '|id=' + id + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
    }
    return result;
  }

  CustomEditForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericEditMultiTableFormData|query=" + paramsparameter + "|id=" + $('#updateId').val() + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeMultiTable', data, { headers: newHeader });
  }
  fileupload(caption: string, fileToUpload: Array<File>) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken'); 
    // this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    const formData: FormData = new FormData();
    $.each(fileToUpload, function (index, value) {
      formData.append('Image', value, value["name"]);
    });
    formData.append('ImageCaption', caption);
    //let data = JSON.stringify("uspAppGenericEditMultiTableFormData|query=" + paramsparameter+ "|id=" + $('#updateId').val());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    // return this.http.post(this.rootUrl + '/values/UploadFiles', formData,{ headers: newHeader });
    return this.http.post(this.rootUrl + '/values/UploadFiles', formData, { headers: newHeader });
  }
  deleteFile(fileName: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify(fileName)
    return this.http.post(this.rootUrl + '/values/MasterDeleteFiles', data, { headers: newHeader });
  }
  downloadFile(fileName: string, colmAlias: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/checkfileexists', { headers: newHeader, params:{filename: fileName,columnName: colmAlias} });
 
  }
  previewFile(fileName: string, colmAlias: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/previewImage', { headers: newHeader, params:{filename: fileName,columnName: colmAlias} });
 
  }
  Mastrtfileupload(fileToUpload: Array<File>) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken'); 
    // this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    const formData: FormData = new FormData();
    $.each(fileToUpload, function (index, value) {
      formData.append('Image', value, value["name"]);
    });

    //let data = JSON.stringify("uspAppGenericEditMultiTableFormData|query=" + paramsparameter+ "|id=" + $('#updateId').val());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    // return this.http.post(this.rootUrl + '/values/UploadFiles', formData,{ headers: newHeader });
    return this.http.post(this.rootUrl + '/values/MasterUploadFiles', formData, { headers: newHeader });
  }
  postFile(caption: string, fileToUpload: Array<File>) {


    const formData: FormData = new FormData();
    $.each(fileToUpload, function (index, value) {
      formData.append('Image', value, value["name"]);
    });
    formData.append('ImageCaption', caption);
    return this.http
      .post(this.rootUrl + '/values/UploadImage', formData);
  }

  //-------------- Added by Naveen BT ----------------------//
  getDashboardColumnBar(tbname: string, procname: string) {
    this.headerSettings = {};
     
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'change';
    const newHeader = new HttpHeaders(this.headerSettings);
    //console.log(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericDashboardwithProcNameBarGraph|ChartType=' + tbname);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericDashboardwithProcNameBarGraph|ChartType=' + tbname + '|procName=' + procname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getDashboardSummaryGraph() {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'change';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericDashboardSummaryBarGraph|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });

  }
  getDashboardDetail( proc: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'change';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericDashboardDonoutGraphStatusDetails|procName=' + proc + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });

  }
  userAuthenticationChangePassword(Username: any, CurrentPassword: string, NewPassword: string) {
    let _data = JSON.stringify('uspAppGenericUserChangePassword|Username=' + Username + '|CurrentPassword=' + CurrentPassword + '|NewPassword=' + NewPassword + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId());

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'change';
    const newHeader = new HttpHeaders(this.headerSettings);

    return this.http.post(this.rootUrl + '/values/ChangePassword', _data, { headers: newHeader });
  }
  getUserData(userId: string, activity: string) {
    let authlet = 'Bearer ' + sessionStorage.getItem('userToken');
    // let _data = "uspApplicationUserGetDetail|userId=" + userId;
    let _data = "uspAppGenericUserGetDetailUsingToken|userId=" + userId + "|RoleId=" + this.commonfunction.getCurrentUserRoleId();
    //  let reqHeader = new HttpHeaders({ 'content-Type': 'application/json'});
    this.headerSettings = {};
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = activity;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/Values/GetUpdateDataSetProfile/?UiParam=' + _data, { headers: newHeader });
  }
  getChangeRoleUserData(userId: string, Roleid: string, activity: string) {
    
    let authlet = 'Bearer ' + sessionStorage.getItem('userToken');
    // let _data = "uspApplicationUserGetDetail|userId=" + userId;
    let _data = "uspAppGenericUserGetDetailUsingToken|userId=" + userId + "|RoleId=" + Roleid;
    //  let reqHeader = new HttpHeaders({ 'content-Type': 'application/json'});
    this.headerSettings = {};
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = Roleid;
    this.headerSettings['Activity'] = activity;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/Values/GetUpdateDataSetProfile/?UiParam=' + _data, { headers: newHeader });
  }
  UpdateProfile(name: string, email: string, phone: string, file: string, userId: string, imagename: string) {

    let _data = JSON.stringify('uspAppGenericUserUpdateProfile|name=' + name + '|email=' + email + '|phone=' + phone + '|file=' + file + '|userId=' + userId + '|imagename=' + imagename + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId());

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = 'home';
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.post(this.rootUrl + '/values/PostReturnDataSet', _data, { headers: newHeader });
  }

  postProfileImage(caption: string, fileToUpload: File) {
    //alert(caption);
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken'); 
    // this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    const endpoint = this.rootUrl + '/values/UploadPImage';
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    formData.append('ImageCaption', caption);


    //console.log(formData);
    return this.http.post(endpoint, formData, { headers: newHeader });
  }


  getImage(imageUrl: string): Observable<Blob> {
    //imageUrl="https://picsum.photos/200/300/?image=1041";
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  uploadGISfile(caption: string, fileToUpload: Array<File>) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken'); 
    // this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);
    const formData: FormData = new FormData();
    $.each(fileToUpload, function (index, value) {
      formData.append('Image', value, value["name"]);
    });
    formData.append('Sector', caption);
    //let data = JSON.stringify("uspAppGenericEditMultiTableFormData|query=" + paramsparameter+ "|id=" + $('#updateId').val());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    // return this.http.post(this.rootUrl + '/values/UploadFiles', formData,{ headers: newHeader });
    return this.http.post(this.rootUrl + '/values/UploadGISFiles', formData, { headers: newHeader });
  }

  GISFileDataReadSubmitForm(paramsparameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify(paramsparameter + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/ReadandUpdateGISFileStatus', data, { headers: newHeader });
  }
  getBeatMaster(formName: string, query: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';

    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=' + query, { headers: newHeader });
  }

  getBeatMasterEmployeeDetail(formName: string, query: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';

    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetBeatMarkerEmployeeDetail/?UiParam=' + query, { headers: newHeader });
  }

  //added by Naveen AT
  getUserMasterFormPageValidationDrp2(formName: string, value: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';

    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetValidationNamesWithParameter|drpvalue=' + value + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getUserMasterFormPageControlDrp2(formName: string, value: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetControlNamesforTableAliasWithParam|drpvalue=' + value + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  //



  //Start
  //Added By Naveen AT
  userAliasUpdateForm(paramsparameter: string,tableId: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericUpdateTableAliasData|tables=" + paramsparameter + "|tableId=" + tableId +"|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutUserParameterDeserialize', data, { headers: newHeader });
  }


  getTableEntryViewForm(tbname: string, formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericTableEntryGetViewDetails|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  getTableNameExists(tbname: string, userrole: string, formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericCheckFormName|formName=' + tbname + '|userRoleId=' + userrole +'|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getTableColumnEntryView(tbname: string, formName: string) {

this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericTableColumnsGetDetails|tblname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getProcParametersEntryView(spName: string, formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGenerateReportProcColumnsGetDetails|spname=' + spName + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  getEditCustumParametersEntry(formId: string, formName: string) {
    this.headerSettings = {};
     
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGetCustumFormNamesDetailwithFilterByFormId|formNameId=' + formId + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  //Added By Naveen AT
  //End
  //=======================Naveen BT Code Start=========================//
  getMasterReportPageLoadDrp(formName: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetProcNamesforGenerateReport|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getMasterReportFormcolumnDetails(SPname: string, formName: string, userRoleId: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericProcGetDetails|spname=' + SPname + '|userRoleId=' + userRoleId + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=insert', { headers: newHeader });
  }

  UserProcedureAliasSubmitForm(paramsparameter: string, formName: string) {

    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericSubmitReport|procDetail=" + paramsparameter + "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutUserReportParameterDeserialize', data, { headers: newHeader });
  }


  getMasterReportViewForm(procName: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericProcGetViewDetails|tblname=' + procName + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }


  UserProcedureAliasUpdateForm(paramsparameter: string,procId: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify("uspAppGenericUpdateReport|procDetail=" + paramsparameter +"|procId="+procId+ "|appUserId=" + sessionStorage.getItem('userId') + "|appUserRole=" + this.commonfunction.getCurrentUserRoleId());
    // let reqHeader = new HttpHeaders({ 'content-Type': 'application/json','No-Auth':'True'});
    return this.http.post(this.rootUrl + '/values/PostReturnOutUserReportParameterDeserialize', data, { headers: newHeader });
  }





  getGenericReportFormPage(tbname: string, formName: string, callfrom: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetReportForm/?UiParam=uspAppGenericProcDetailsGetwithColumnAlias|spname=' + tbname + '|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom='+callfrom, { headers: newHeader });
  }

  getGenericReportData(procWithParameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=' + procWithParameter, { headers: newHeader });
  }

  getGenericListFormData(procWithParameter: string, formName: string) {
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetMasterListForm/?UiParam=' + procWithParameter, { headers: newHeader });
  }

  getUserMasterFormPageControlDrp(formName: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetControlNamesforTableAliasWithParam|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  getUserMasterFormPageValidationDrp(formName: string) {
    this.headerSettings = {};
    const headerSettings: { [name: string]: string | string[] } = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDropDownlist/?UiParam=uspAppGenericGetValidationNamesWithParameter|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }

  // =======================Naveen BT Code End=========================//
  getWFViewForm(formName: string) {
    this.headerSettings = {};
     
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericGetWFMasterViewDetails|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId(), { headers: newHeader });
  }
  getWorkFlowPageLoadDetails(formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/GetDataSet/?UiParam=uspAppGenericWFLevelGetForGenerateWF|appUserId=' + sessionStorage.getItem('userId') + '|appUserRole=' + this.commonfunction.getCurrentUserRoleId() + '&callfrom=insert', { headers: newHeader });
  }
  filesupload(formData: FormData) {
     
     this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    this.headerSettings['No-Auth'] = 'True';
    //this.headerSettings['Content-Type'] = 'multipart/form-data';
    //this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken'); 
    // this.headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(this.headerSettings);   
    
    return this.http.post(this.rootUrl + '/values/MasterUploadFiles', formData, { headers: newHeader });
  }
  Capturefilesupload(webImage:any) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = "home";
    const newHeader = new HttpHeaders(this.headerSettings);   
    let data = JSON.stringify(webImage);
    return this.http.post(this.rootUrl + '/values/MasterUploadCaptureFiles', data, { headers: newHeader });
  }
  fileDelete(fileName: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    let data = JSON.stringify(fileName)
    return this.http.post(this.rootUrl + '/values/MasterDeleteFiles', data, { headers: newHeader });
  }
  fileDownload(fileName: string, colmAlias: string, formName: string) {
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);
    return this.http.get(this.rootUrl + '/values/checkfileexists', { headers: newHeader, params:{filename: fileName,columnName: colmAlias} });
 
  }

  updateWfProcessData(paramsparameter: string,formName: string){ 
    
    this.headerSettings = {};
    this.headerSettings['dataType'] = 'json';
    //  this.headerSettings['No-Auth'] = 'True' ;
    this.headerSettings['Authorization'] = 'Bearer ' + sessionStorage.getItem('userToken');
    this.headerSettings['Content-Type'] = 'application/json';
    this.headerSettings['CurrentRole'] = this.commonfunction.getCurrentUserRoleId() ? this.commonfunction.getCurrentUserRoleId() : "";
    this.headerSettings['Activity'] = formName;
    const newHeader = new HttpHeaders(this.headerSettings);   
    return this.http.post(this.rootUrl + '/values/PostReturnOutParameterDeserializeMultiTable', paramsparameter,{ headers: newHeader });
   }

   getNewEmployeeData(){
    return this.http.get(URLs.getNewEmployeeData);
  }
  updateEmployeeRecords(options:any){
    return this.http.get(URLs.getNewEmployeeData);
  }
 
}