import { Component, OnInit } from '@angular/core';
import {Itableth} from '../../allUsedModule'
import { Commonfunction } from 'src/app/shared/commonutility';
import { FormControl, FormGroup } from '@angular/forms';
import {Igallery} from '../../allUsedModule'
import { GalleryPageModelService } from '../../allFormPopUpModel/gallery-page-model/gallery-page-model.service';

@Component({
  selector: 'app-gallery-dashboard',
  templateUrl: './gallery-dashboard.component.html',
  styleUrls: ['./gallery-dashboard.component.css']
})
export class GalleryDashboardComponent implements OnInit {
  activeTabId:number=2;
  Gallerydata:any;
  AddNewFrom !:FormGroup;
  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "imgurl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Shown", "colName": "tabName","isActionBtn":false,"isImagePreviewEnabled":false }
  ]
  constructor(public commonfunction:Commonfunction,public gallryService:GalleryPageModelService) { }

  ngOnInit(): void {
    this.Gallerydata = this.commonfunction.loadJson("~/../assets/gallerydb.json");

    this.AddNewFrom =new  FormGroup({
      tabs: new FormControl("")
    })
  }


  //Form Update Fuction
  OnClickAddNew(){
    const _item:Igallery ={} as Igallery;
    _item.tabName = this.AddNewFrom.get('tabs')?.value;
    _item.imgurl = "";
    _item.isactive = true
    console.log(_item);
    
    this.Gallerydata.push(_item)
  }






  //Pop up Model Function
  onClickEditForm(value:any){
    console.log(value);  
    this.gallryService.galleryService(value)
  }

}
