import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, OnChanges, DoCheck } from '@angular/core';
import { FormGroup, FormControl, FormControlName, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors, AsyncValidatorFn } from '@angular/forms'
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { async } from '@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { GenericFormDropDownServiceService } from "../../shared/generic-form-drop-down-service.service";
import { ObjectParam, lstObjectParam, lstFormFinalObjectParam, FormFinalObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
// import  'src/typings';
import 'jquery';
import { custumValidation } from 'src/app/shared/custum-validation';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
//import * as moment from 'moment';
import { asyncCustumValidation } from '../../shared/async-custum-validation';
import { GenericPrintService } from '../../shared/generic-print-service';
import { IfileTypeOutput, filetypeEnum } from '../../shared/model/fileType';
import { WorkFlowActionEnum } from '../../shared/model/WorkFlowEnum';
import { WorkFlowProcessServiceService } from '../../shared/work-flow-process/work-flow-process-service.service'
import { CustomActionService } from '../../shared/customAction/custom-action.service';
import { PreviewImageService } from '../../shared/preview-image/preview-image.service';
import { AlertDialogBoxService } from '../../shared/alert-dialog-box/alert-dialog-box.service';
import { Subject } from 'rxjs';
import { IFilterColumns, IviewFullDetails } from '../../shared/model/genericTableListViewModel';
declare var jQuery: any;

@Component({
    selector: 'app-generic-master-form',
    templateUrl: './generic-master-form.component.html',
    styleUrls: ['./generic-master-form.component.css']
})
export class GenericMasterFormComponent implements OnDestroy, OnInit {

    status: boolean = true;

    isRenderDetail: number = 0;
    timepick: string = "12:00 AM";
    isWorkflowImplemented: boolean = false;
    customSearchString: string = '';
    get customSearch(): string {
        return this.customSearchString;
    }
    set customSearch(val: string) {
        this.customSearchString = val;
        this.CustomFilterusingColumn();
    }
    activeTabId: number = 1;
    workflowstep: number = 0;
    workflowStatusId: number = 0;
    addPermission: string = 'True';
    editPermission: string = 'True';
    activePermission: string = 'True';
    formTestPermission: string = 'True';
    deletePermission: string = 'True';
    printPermission: number = 0;
    isHistoryShowProc: string = '';
    isFormReset: filetypeEnum = filetypeEnum.No;
    tooltipHeader = [];
    tooltipIsViewLink: string="";
    tooltipText: string="";
    ischeckboxshow: number = 0;
    dateFormat = 'MM-DD-YYYY';
    @ViewChild(DataTableDirective)
    dtElement!: DataTableDirective;
    SeltableID: string="";
    SeltableIDForm: string="";
    masterProductForm!: FormGroup;
    isRender: boolean = false;
    masterProductFormEdit!: FormGroup;
    isRenderEdit: boolean = false;    
    viewtableHeaders: TableColumnName[] = [];
    ViewDetailChildtableHeaderData = [];
    ViewDetailChildtableRowData = [];
    ViewDetailHistorytableHeaderData = [];
    ViewDetailHistorytableRowData = [];
    fileRequiredValidation: Array<{ controlName: string, controlValidation: string }> = [];
    fileRequiredValidationEdit: Array<{ controlName: string, controlValidation: string }> = [];
    viewDetailModalHeading: string = "";
    dtTrigger: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    tableid: string="";
    Detailtableid: string="";
    DetailtableHeaderData = [];
    DetailtableRowData:any = [];
    dropdownSettings = {};
    display = "none";
    displayMain = "none";
    displayMainEdit = "none";
    displayContainer = "block";
    sel: any;
    masterJsonData: any[];
    masterJsonDataFull: any[]=[];
    masterJsonDataEdit: any[]=[];
    masterJsonDataEditFull: any[]=[];
    GenericPrintDataArray: any[]=[];
    masterJsonDataDefaultValue = {};
    updateRecordID: string="";
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    tblecount: number=0;
    public value: string[]=[];
    public current: string="";
    public selected: string="";
    public dynamicdivdata: any;
    public exampleArray: Array<{ id: string, text: string }>=[];
    dropdownList = [];
    selectedItems = [];
    bsConfig:any;
    changeDetectorRefs: ChangeDetectorRef[] = [];
    paramvaluedata: any;
    FileerrorMessage: string="";
    filesToUpload: Array<File>;
    selectedFileNames: string[] = [];
    public isLoadingData: Boolean = false;
    @ViewChild('fileUpload') fileUploadVar: any;
    filecontrolNameId: string="";
    filehiddencolumn: string="";
    filehiddencolumnCallingfrom: string="";
    uploadResult: any;
    res: Array<string>=[];
    param: string="";
    menulabel: string="";
    formlable: string="";
    minDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() - year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    maxDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() + year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    editModelHeading: string="";
    editModelCallfrom: string="";
    dropdownListActionGroupSpdrp:any = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedRows:any = [];
    resultData: any;
    masterSelected: boolean = false;
    currentRoleString: string | undefined = '';
    currentRole: string  ="";
    userId: string| undefined  ="";
    constructor(public commonfunction: Commonfunction, public asyncCustomvalidation: asyncCustumValidation,
        public Customvalidation: Customvalidation, private userService: UserService, private router: Router,
        public zone: NgZone, private ref: ChangeDetectorRef, route: ActivatedRoute, private spinner: NgxSpinnerService,
        private fb: FormBuilder, private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService,
        private genericPrintService: GenericPrintService, private GlobalWorkFlowProcessService: WorkFlowProcessServiceService
        , private customActionService: CustomActionService, private previewImageService: PreviewImageService, private AlertService: AlertDialogBoxService, private genericDropDown:GenericFormDropDownServiceService) {
        this.FileerrorMessage = "";
        this.filesToUpload = [];
        this.selectedFileNames = [];
        this.uploadResult = "";
        this.masterJsonData = [];
        this.bsConfig = {
            dateInputFormat: 'DD/MM/YYYY',
            isAnimated: true,
            adaptivePosition: true,
            containerClass: 'theme-blue',
            minDate: this.minDate(50),
            maxDate: this.maxDate(50)
        };
        this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))? sessionStorage.getItem('currentRole')?.toString()  : "";
      this.currentRole = this.currentRoleString != null ? JSON.parse(this.currentRoleString)?.roleId.toString():"";
      this.userId= sessionStorage.getItem('userId')?.toString();
    }

    ngOnInit() {

       
        // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
        let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
        this.SeltableID = paramjs.formValue;
        this.SeltableIDForm = paramjs.formName;
        this.menulabel = paramjs.menu;
        this.formlable = paramjs.formName;
        this.Search(paramjs.formValue, paramjs.formName);
        this.onMainTab();
        this.dropdownSettings = {
            singleSelection: true,
            // text:"Select Countries",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            // primaryKey:'DrpValue',
            // labelKey:'DrpOption',
            classes: "global-select-box",
            disabled: false
        };
      
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
    }


    Search(tbname:string, formName:string) {
        this.spinner.show();
        var trdata = '';
        const that = this;
        this.userService.getMasterFormPage(tbname, formName).subscribe((data: any) => {
            this.displayMain = "block";
            let reactiveFormData = {};
            let reactiveFormDataDefaultValue = {};
            // this.addPermission = false;
            // this.editPermission = false;
            // this.activePermission = false;
            // this.deletePermission = false;
            // this.printPermission =false;
            this.addPermission = data['formAddPermission'];
            this.editPermission = data['formEditPermission'];
            this.activePermission = data['formActivePermission'];
            this.formTestPermission = data['formTestPermission'];
            this.deletePermission = data['formDeletePermission'];
            this.printPermission = data['formPrintPermission'];
            this.isHistoryShowProc = data['formisHistoryShowProc'];
            let chkAsyncGenericDbCheck: Boolean = false;
            if(this.addPermission == 'True')
            this.activeTabId = 1;
            else
            this.activeTabId = 2;

            this.masterJsonDataFull = data;
            this.masterJsonData = data['formData'];
            this.ischeckboxshow = +data['formIsCheckbox'];
            if (data['formActionGroupList'] != null)
                this.dropdownListActionGroupSpdrp = data['formActionGroupList'];

            if (this.addPermission == 'True') {
                this.masterJsonData.forEach(function (value, index) {
                    value.ChildComponentReset = filetypeEnum.No;
                    value.childComponentFormSequence = 1;
                    value.childComponentFileDelete = false;
                    let ArrayData: ValidatorFn[] = [];
                    let checkExsistAsync: AsyncValidatorFn[] = [];
                    if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                        
                        let requiredMsg = '';
                        let sizeMsg = '';
                        let customMsg = '';
                        //let genericRegex ;
                        if (value["customValidationMsg"] != "" && value["customValidationMsg"] != null) {
                            let customValidationMsg = JSON.parse(value["customValidationMsg"]);
                            requiredMsg = customValidationMsg['requiredMsg'];
                            sizeMsg = customValidationMsg['sizeMsg'];
                            customMsg = customValidationMsg['customMsg'];
                            // genericRegex = customValidationMsg['genericRegex'];
                        }
                        if (value['iS_NULLABLE'].toLowerCase() == "no") {
                            if (value['controlType'].toString().toLowerCase() == 'file') {
                                let filevalidation: string = "custumValidation['validateRequired']('" + requiredMsg + "','" + value['columnLength'].toString() + "','" + value['datA_TYPE'].toString() + "','" + value['controlType'].toString() + "','" + value['columnAliasName'].toString() + "','" + value['compareColumnName'].toString() + "','" + value['compareColumnAliasName'].toString() + "','" + value['range'].toString() + "')";
                                that.fileRequiredValidation.push({ controlName: value['controlName'].toString(), controlValidation: filevalidation });

                            }

                            // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));


                            ArrayData.push(custumValidation['validateRequired'](requiredMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                        }
                        if ((value['datA_TYPE'] != "date" && value['datA_TYPE'] != "datetime" && value['datA_TYPE'] != "bit")) {

                            ArrayData.push(custumValidation['validateSize'](sizeMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                        }
                        if (value['validations'] != "" && value['validations'] != null) {

                            let ValName = "'" + value['validations'] + "'"
                            // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                            // ArrayData.push(custfunction);                            
                            if (value['validations'] == "validateGeneric")
                                ArrayData.push(custumValidation['validateGeneric'](value['genericRegx'], customMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));
                            else
                                ArrayData.push(custumValidation[eval(ValName)](customMsg, value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString()));

                        }

                        if (value['checkDataExists'] != "" && value['checkDataExists'] != null && value['checkDataExistsParam'] != "" && value['checkDataExistsParam'] != null) {
                            if (value['checkDataExists'] == 1) {
                                let sqlParams = value['checkDataExistsParam'].split('%');

                                checkExsistAsync.push(that.asyncCustomvalidation.validateValueInDBExists_Check(value['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], sqlParams[3], sqlParams[4]).bind(that.asyncCustomvalidation))

                            }
                            else if (value['checkDataExists'] == 3) {
                                let sqlParams = value['checkDataExistsParam'].split('%');
                                chkAsyncGenericDbCheck = true;
                                checkExsistAsync.push(that.asyncCustomvalidation.validateValueInGenericDBExists_Check(value['controlType'],"I",value['columN_NAME'],value['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], sqlParams[3], sqlParams[4]).bind(that))

                            }
                            else if (value['checkDataExists'] == 2) {
                                let arrayParams = value['checkDataExistsParam'].split('%');
                                // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                // ArrayData.push(custfunction);

                                ArrayData.push(custumValidation['validateValueInArrayExists_Check'](value['columnLength'].toString(), value['datA_TYPE'].toString(), value['controlType'].toString(), value['columnAliasName'].toString(), value['compareColumnName'].toString(), value['compareColumnAliasName'].toString(), value['range'].toString(), arrayParams[0], arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));


                            }

                        }
                    }
                    // validatenumeric('16','numeric','drop','Role','','','')
                    // if (value['controlType'].toString().toLowerCase() == 'dropdown')
                    //     reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'], ArrayData, checkExsistAsync);
                    // else if (value['controlType'].toString().toLowerCase() == 'labelauto') 
                    //     reactiveFormData[value['controlName']] = new FormControl(value['columnValue'], ArrayData, checkExsistAsync);
                    // else  if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') 
                    //     reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? new Date(value['columnDefaultValue']) : '', ArrayData, checkExsistAsync);
                    // else if (value['controlType'].toString().toLowerCase() == 'checkbox') 
                    //      reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? value['columnValue'] : false, ArrayData, checkExsistAsync);
                    // else
                    //     reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] , ArrayData, checkExsistAsync);

                    if (value['controlType'].toString().toLowerCase() == 'dropdown') {
                        reactiveFormData[value['controlName']] = new FormControl(JSON.parse(value['columnDefaultValue']), ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = JSON.parse(value['columnDefaultValue']);

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'mdropdown') {
                        reactiveFormData[value['controlName']] = new FormControl(JSON.parse(value['columnDefaultValue']), ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = JSON.parse(value['columnDefaultValue']);

                    }
                    else if (value['controlType'].toString().toLowerCase() == 'labelauto') {
                        reactiveFormData[value['controlName']] = new FormControl(value['columnValue'], ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = value['columnValue'];
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'datetime' || value['controlType'].toString().toLowerCase() == 'date') {
                        reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? new Date(value['columnDefaultValue']) : '', ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' ? new Date(value['columnDefaultValue']) : '';
                    }
                    else if (value['controlType'].toString().toLowerCase() == 'checkbox') {
                        reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' && value['columnDefaultValue'] == '1' ? true : false, ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'] !== null && value['columnDefaultValue'] !== '' && value['columnDefaultValue'] == '1' ? true : false;
                    }
                    else {
                        reactiveFormData[value['controlName']] = new FormControl(value['columnDefaultValue'], ArrayData, checkExsistAsync);
                        reactiveFormDataDefaultValue[value['controlName']] = value['columnDefaultValue'];
                    }
                });

                this.masterProductForm = this.fb.group(reactiveFormData);
                this.masterJsonDataDefaultValue = reactiveFormDataDefaultValue;
                console.log(this.masterJsonData);

                this.isRender = true;
            }
            else {

                this.SearchEdit();
            }

            this.spinner.hide();
            if(chkAsyncGenericDbCheck){
            this.masterProductForm.valueChanges.pipe().subscribe((res: FormGroup) =>{
                this.commonfunction.setFormLatestValue(this.masterProductForm,"I");
            });
        }

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            });
    };
    testformData() {
let that = this;
        console.log(this.masterProductForm);
        Object.keys(this.masterProductForm?.controls).forEach(key => {
            const controlErrors:  ValidationErrors | null | undefined = that.masterProductForm.get(key) !=null ? that.masterProductForm.get(key)?.errors:null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }
    testformDataEdit() {
let that = this;
        console.log(this.masterProductFormEdit);
        Object.keys(this.masterProductFormEdit?.controls).forEach(key => {
            const controlErrors: ValidationErrors | null | undefined = that.masterProductFormEdit.get(key) != null ? that.masterProductFormEdit.get(key)?.errors:null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    // ngDoCheck(){
      
    //    this.commonfunction.setFormLatestValue(this.masterProductForm);
     
    // }
    
    onItemSelect(item: any, Index: number, multiselect: boolean) {


        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm, false, false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelect(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm, false, false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectAll(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm, false, false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectAll(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm, false, false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    OnClose(item: any, controlName: string) {
        this.masterProductForm.get(controlName)?.markAsTouched();
    }

    onItemSelectEdit(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm, false, true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectEdit(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm, false, true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectEditAll(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm, false, true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectEditAll(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterJsonDataEdit, this.masterProductFormEdit, this.SeltableIDForm, false, true,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }
    OnCloseEdit(item: any, controlName: string) {
        this.masterProductFormEdit.get(controlName)?.markAsTouched();
    }
    childCReset(event: filetypeEnum, columnname: string, callfrom: string, controlName: string, index: number) {
        
        if (callfrom == 'I') {
            setTimeout(() => {
                this.masterJsonData[index]['ChildComponentReset'] = event;
                this.masterProductForm.get(controlName)?.markAsUntouched();
            }, 200);
            // this.setFileControlValidation("","",false,columnname,callfrom,controlName);

            // const newValue = JSON.parse('{"' + controlName + '":""}');
            // this.masterProductForm.get(controlName).setValidators([custumValidation['validateFileUpoadErrorMsg']('', false)]);
            // this.masterProductForm.patchValue(newValue);

        }


    }

    OnViewFullDetail(viewFullDetail:IviewFullDetails) {
        let event: any = viewFullDetail.event;
        let id: string = viewFullDetail.id;
        let callfrom: string = viewFullDetail.callfrom;
        let modalTitle: string = viewFullDetail.modalTitle;
        let chkAsyncGenericDbCheck: boolean = false;

        this.spinner.show();
        var trdata = '';
        const that = this;
        this.editModelCallfrom = callfrom;
        if (callfrom == "update")
            this.editModelHeading = "Update Record - " + modalTitle;
        else
            this.editModelHeading = "View Record - " + modalTitle;
        this.userService.getEditMasterPage(this.SeltableID, id, callfrom, this.SeltableIDForm).subscribe((data: any) => {
            
            let reactiveFormData = {};
            this.masterJsonDataEditFull = data;
            this.masterJsonDataEdit = data['formData'];
            // this.addPermission = data['formAddPermission'];
            // this.editPermission = data['formEditPermission'];
            // this.activePermission = data['formActivePermission'];
            // this.formTestPermission = data['formTestPermission'];
            // this.deletePermission = data['formDeletePermission'];
            // this.printPermission = data['formPrintPermission'];
            // this.isHistoryShowProc = data['formisHistoryShowProc'];
            this.masterJsonDataEdit.forEach(function (valueEdit, index) {
                

                valueEdit.ChildComponentReset = filetypeEnum.No;
                valueEdit.childComponentFormSequence = 1;
                valueEdit.childComponentFileDelete = false;


                let ArrayData: ValidatorFn[] = [];
                let checkExsistAsync: AsyncValidatorFn[] = [];
                if (valueEdit["identityColumn"].toLowerCase() != "yes" && valueEdit["datA_TYPE"].toLowerCase() != "uniqueidentifier") {

                    let requiredMsg = '';
                    let sizeMsg = '';
                    let customMsg = '';
                    if (valueEdit["customValidationMsg"] != "" && valueEdit["customValidationMsg"] != null) {
                        let customValidationMsg = JSON.parse(valueEdit["customValidationMsg"]);
                        requiredMsg = customValidationMsg['requiredMsg'];
                        sizeMsg = customValidationMsg['sizeMsg'];
                        customMsg = customValidationMsg['customMsg'];
                    }

                    if (valueEdit['iS_NULLABLE'].toLowerCase() == "no") {
                        ArrayData.push(custumValidation['validateRequired'](requiredMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                    }
                    if ((valueEdit['datA_TYPE'] != "date" && valueEdit['datA_TYPE'] != "datetime" && valueEdit['datA_TYPE'] != "bit")) {

                        ArrayData.push(custumValidation['validateSize'](sizeMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                    }
                    if (valueEdit['validations'] != "" && valueEdit['validations'] != null) {

                        let ValName = "'" + valueEdit['validations'] + "'"
                        if (valueEdit['validations'] == "validateGeneric")
                            ArrayData.push(custumValidation['validateGeneric'](valueEdit['genericRegx'], customMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));
                        else
                            ArrayData.push(custumValidation[eval(ValName)](customMsg, valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString()));

                    }

                    if (valueEdit['checkDataExists'] != "" && valueEdit['checkDataExists'] != null && valueEdit['checkDataExistsParam'] != "" && valueEdit['checkDataExistsParam'] != null) {
                        let columnValueEdit;
                           if(valueEdit['controlType']=="dropdown"){
                            columnValueEdit = valueEdit['drpDataSelected'] !="" && valueEdit['drpDataSelected'] != null && valueEdit['drpDataSelected'] != undefined ? valueEdit['drpDataSelected'][0]['id'] : "";
                           }
                           else if(valueEdit['controlType'].toLowerCase()=="date" || valueEdit['controlType'].toLowerCase()=="datetime"){
                            columnValueEdit = valueEdit['columnValue'] !="" && valueEdit['columnValue'] != null && valueEdit['columnValue'] != undefined ? new Date(valueEdit['columnValue']) : null;
                           }
                           else{
                            columnValueEdit = valueEdit['columnValue']; 
                           }
                        if (valueEdit['checkDataExists'] == 1) {
                            let sqlParams = valueEdit['checkDataExistsParam'].split('%');

                            checkExsistAsync.push(that.asyncCustomvalidation['validateValueInDBExists_Check'](valueEdit['controlType'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                        }
                        else  if (valueEdit['checkDataExists'] == 3) {
                            let sqlParams = valueEdit['checkDataExistsParam'].split('%');
                            chkAsyncGenericDbCheck = true;
                            checkExsistAsync.push(that.asyncCustomvalidation['validateValueInGenericDBExists_Check'](valueEdit['controlType'],"E",valueEdit['columN_NAME'],valueEdit['tblname'],sqlParams[0], sqlParams[1], sqlParams[2], columnValueEdit.toString(), sqlParams[4]).bind(that.asyncCustomvalidation))

                        }
                        else if (valueEdit['checkDataExists'] == 2) {
                            let arrayParams = valueEdit['checkDataExistsParam'].split('%');
                            // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                            // ArrayData.push(custfunction);

                            ArrayData.push(custumValidation['validateValueInArrayExists_Check'](valueEdit['columnLength'].toString(), valueEdit['datA_TYPE'].toString(), valueEdit['controlType'].toString(), valueEdit['columnAliasName'].toString(), valueEdit['compareColumnName'].toString(), valueEdit['compareColumnAliasName'].toString(), valueEdit['range'].toString(), eval(arrayParams[0]), arrayParams[1], arrayParams[2], arrayParams[3], arrayParams[4], arrayParams[5]));


                        }

                    }
                }
                if (valueEdit['controlType'].toString().toLowerCase() == 'dropdown')
                    reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                else if (valueEdit['controlType'].toString().toLowerCase() == 'mdropdown')
                    reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['drpDataSelected'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

                else if (valueEdit['controlType'].toString().toLowerCase() == 'datetime') {
                    //console.log(moment(valueEdit['columnValue']).format(that.dateFormat));
                    reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? new Date(valueEdit['columnValue']) : '', disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                }
                else if (valueEdit['controlType'].toString().toLowerCase() == 'checkbox') {

                    reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'] !== null && valueEdit['columnValue'] !== '' ? valueEdit['columnValue'] : false, disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);
                }
                else
                    reactiveFormData[valueEdit['controlName']] = new FormControl({ value: valueEdit['columnValue'], disabled: valueEdit['isDisabled'] == 'disabled' ? true : false }, ArrayData, checkExsistAsync);

            });
            this.masterProductFormEdit = this.fb.group(reactiveFormData);
            


            this.spinner.hide();
            if(chkAsyncGenericDbCheck){
                this.masterProductFormEdit.valueChanges.pipe().subscribe((res: FormGroup) =>{
                    this.commonfunction.setFormLatestValue(this.masterProductFormEdit,"E");
                });
            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();

                console.log(err.message);
                if (err.status == 401) {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.isRenderEdit = true;
                this.updateRecordID = id;

console.log( this.masterJsonDataEditFull);
                if (this.isWorkflowImplemented) {
                    let isworkArray = this.DetailtableRowData.filter((x:any) => x['id'] == +id);
                    if (isworkArray.length > 0) {
                        this.workflowStatusId = +isworkArray[0]['wfStatusId'];
                        this.workflowstep = +isworkArray[0]['workFlowStep'];
                    }

                }
            });
        this.onOpenEditMasterModal();
    }
    OnMasterFormAction(event:any, id:string, action:string, TableId:number, modalTitle: string) {
        this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + " Record for " + modalTitle + "?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {

                    this.MasterTableAllAction(id, action, TableId);
                    this.paramvaluedata = '';
                }
            });

    }

    MasterTableAllAction(id: string, action: string, index: number) {

        //code to be done
        this.userService.ActionActivesubmit(id, action, this.SeltableIDForm.toString(), this.SeltableID.toString(), 'M').subscribe((data: any) => {
            if (data != "") {

                let resultarray = JSON.parse(data);
                // resultarray['successFlag'] 
                // resultarray['resultMessage']
                // resultarray['printAction']
                // resultarray['printActionResult']
                // resultarray['otherAction']
                // resultarray['otherActionResult']
                // resultarray['outputVarResult']
                if (action == "Active" || action == "InActive") {
                    if (resultarray['successFlag'] == "success") {
                        this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
                        return;
                    }
                    else {
                        this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
                        return;
                    }
                }
                else {
                    if (resultarray['successFlag'] == "success") {
                        //            
                        this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

                        return;

                    }
                    else {
                        this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
                        return;

                    }
                }
            }
        },
            (err: HttpErrorResponse) => {

                if (err.status == 401) {
                    this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
                }
            },
            () => {

                if (action == "Active") {
                    this.DetailtableRowData[index]['isActive'] = true;
                }
                else if (action == "InActive") {
                    this.DetailtableRowData[index]['isActive'] = false;
                }
                else {
                    this.SearchEdit();
                    this.retainFilter(1500);
                }

            });
        //

    }


    openSubmitModal() {
        this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.submitcall();
                    this.paramvaluedata = '';
                }
            });
    }

    OnSubmitModal() {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor='';
        let objColumn = <lstObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';

        this.masterJsonData.forEach(function (value, index) {

            let objList = <ObjectParam>{};
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let colValue;
                let formcontrolValue;
                let CompareColumnValue:string ='';
                if (value["controlType"].toLowerCase() == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"].toLowerCase() == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                }
                else if (value["controlType"].toLowerCase() == "checkbox") {
                    let chkValue = that.masterProductForm?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false || chkValue == undefined) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }


                else if (value["controlType"].toLowerCase() == "file") {
                    formcontrolValue = that.masterProductForm?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue;
                    }
                    else {
                        colValue = '';
                    }
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    formcontrolValue = that.masterProductForm?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = that.commonfunction.ConvertDateFormat(formcontrolValue);
                    }
                    else {
                        colValue = '';
                    }
                }
                else {
                    formcontrolValue = that.masterProductForm?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue;
                    }
                    else {
                        colValue = '';
                    }
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        let controlValue = $('#' + value["controlName"]).val() ? $('#' + value["controlName"]).val()?.toString() : '';
                        CompareColumnValue = controlValue ? $.trim(controlValue.toString()) :'';  
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue !== null && colValue !== '') {
                    objList.TblName = value["tblname"];
                    objList.columnName = value["columN_NAME"];
                    objList.ColumnType = value["datA_TYPE"];
                    objList.ColumnValue = colValue;
                    objList.RequiredField = value["iS_NULLABLE"];
                    objList.ColSize = value["columnLength"];
                    objList.ContType = value["controlType"];
                    objList.validation = value["validations"];
                    objList.columnAliasName = value["columnAliasName"];
                    objList.compareColumnAliasName = value["compareColumnAliasName"];
                    objList.CompareColumnName = value["compareColumnName"];
                    objList.CompareColumnValue = CompareColumnValue;
                    objList.Rangestring = value["range"];
                    genericColumnList.push(objList);
                }
            }
        });

        if (genericColumnList.length > 0) {
            for (let i = 0; i < 2; i++) {
                let objList = <ObjectParam>{};

                objList.TblName = tableNamefor;
                if (i == 0) {
                    objList.columnName = "CreatedBy";
                }
                else {
                    objList.columnName = "ModifiedBy";
                }
                objList.ColumnType = "varchar";
                objList.ColumnValue = that.userId ? that.userId : '';
                objList.RequiredField = "no";
                objList.ColSize = "200";
                objList.ContType = "txt";
                objList.validation = "";
                objList.columnAliasName = "";
                genericColumnList.push(objList);
            }
            for (let i = 0; i < 2; i++) {
                let objList = <ObjectParam>{};

                objList.TblName = tableNamefor;
                if (i == 0) {
                    objList.columnName = "createdRoleId";
                }
                else {
                    objList.columnName = "modifiedRoleId";
                }
                objList.ColumnType = "int";
                objList.ColumnValue = that.currentRole;
                objList.RequiredField = "no";
                objList.ColSize = "5";
                objList.ContType = "txt";
                objList.validation = "";
                objList.columnAliasName = "";
                genericColumnList.push(objList);
            }
        }
        if (genericColumnList.length > 0) {
            objColumn.genericColumnList = genericColumnList;
            console.log(genericColumnList);
            _data = JSON.stringify(objColumn);
            // alert(_data);
            var objDatestatusFinalModal = <lstObjectParam>{};
            objDatestatusFinalModal.genericColumnList = genericColumnList;
            this.openSubmitModal();
            this.paramvaluedata = _data;
        }

    }
    submitcall() {
        this.spinner.show();
        let printData;
        this.userService.SubmitForm(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {
            this.spinner.hide();
            let printArray = [];
            if (datacom != "") {
                let resultarray = JSON.parse(datacom);
                // resultarray['successFlag'] 
                // resultarray['resultMessage']
                // resultarray['printAction']
                // resultarray['printActionResult']
                // resultarray['otherAction']
                // resultarray['otherActionResult']
                // resultarray['outputVarResult']

                if (resultarray['successFlag'] == "success") {
                    // this.masterProductForm.reset();
                    printArray.push({ 'template': resultarray['printActionResult'].toString() });
                    this.genericPrintService.printDataArray = printArray;
                    this.GenericPrintDataArray = printArray;

                    this.resetForm(1);
                    this.AlertTitle = "Success!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-success-bg";
                    this.AlertAnimationType = "smile";
                    this.AlertType = "NP_popup_success";
                }

                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                // this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                this.AlertService.AlertFunction(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType)
                    .then((confirmed) => {
                        if (!confirmed) {
                            if (printData = 1) {
                                if (this.printPermission == 1)
                                    this.genericPrintService.printDocument();
                                else if (this.printPermission == 2)
                                    this.GenericPrintFunction();
                            }
                        }
                    });
            }
        );
    }

    FirstLetterLower(value:string) {
        return this.commonfunction.FirstLetterLower(value);
    }
    FirstLetterUpper(value:string) {
        return this.commonfunction.FirstLetterUpper(value);
    }
    ReplaceNullDataTable(value:string) {
        return this.commonfunction.ReplaceNullDataTable(value);
    }
    convertStringLowerCase(value:any) {

        return value.toString().toLowerCase();
    }
    resetForm(isFileDelete: filetypeEnum) {
        this.masterProductForm?.reset(this.masterJsonDataDefaultValue);
        let filecomtrols = this.masterJsonData.filter(x => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
        filecomtrols.forEach(function (value, index) {
            if(value.control.toLowerCase() == 'file')
            value['ChildComponentReset'] = isFileDelete;
            else
            value['ChildComponentReset'] = 1;

        });


    }
    resetEditForm(isFileDelete: filetypeEnum, callfrom: boolean) {
        
        let that = this;
        let filecomtrols = this.masterJsonDataEdit.filter(x => x.control.toLowerCase() == 'file' || x.control.toLowerCase() == 'timepicker' ||  x.control.toLowerCase() == 'switchbutton');
        filecomtrols.forEach(function (value, index) {
            if(value.control.toLowerCase() == 'file'){
            value['ChildComponentReset'] = isFileDelete;
            if (callfrom && value['previousControlValue'] =="")
                that.deleteAttachmentForEditForm(that.masterProductFormEdit?.value[value['controlName']]);
            else if (!callfrom && that.masterProductFormEdit?.value[value['controlName']] != value['previousControlValue'])
                that.deleteAttachmentForEditForm(value['previousControlValue']);
            }
            else
            value['ChildComponentReset'] = 1;

        });

    }
    CustomFilterusingColumn() {
        
        if (this.selectedItemsCustomColumnSearchdrp.length > 0) {
            $('#' + this.Detailtableid).DataTable()
                .column(this.selectedItemsCustomColumnSearchdrp[0].id)
                .search(this.customSearch)
                .draw();
        }
    }
    retainFilter(ms:any) {
        setTimeout(() => {
            if (this.selectedItemsCustomColumnSearchdrp.length > 0 && this.customSearch != '')
                this.CustomFilterusingColumn();

        }, ms);
    }
    SearchEdit() {

      //  this.spinner.show();

        let dataRow = [];

        this.displayMainEdit = "block";
        this.Detailtableid = 'EditDynamictable' + this.SeltableID.toString().replace(/\s/g, '');
        // $('#' + this.Detailtableid).DataTable().destroy();

        let isTrigger: boolean = false;
        let that = this;

        // this.userService.getEditViewForm(this.SeltableID, this.SeltableIDForm).subscribe((data: any) => {
        //     that.resultData = data;
        //     that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? '1' : '0', itemName: 'S.No.' });
        //     let TrData = '';
        //     let ThHeader;
        //     if (this.DetailtableHeaderData.length == 0) {
        //         this.DetailtableHeaderData = data.table;
        //         let headerdata = data.table.filter((x:any) => x['columN_NAME'] != 'id' && x['columN_NAME'] != 'guid')
        //         headerdata.forEach(function (obj:any, index:string) {
        //             that.dropdownListCustomColumnSearchdrp.push({ id: that.ischeckboxshow ? (index + 2) : (index + 1), itemName: obj['columnAliasName'] });
        //         });
        //         // this.dtTriggerDetail.next();
        //         isTrigger = true;
        //     }
        //     dataRow = data.table1;
        //     if (this.ischeckboxshow > 0) {
        //         dataRow.forEach((obj:any) => {
        //             obj.isSelected = false
        //         });
        //     }
        //     if (dataRow.length > 0)
        //         this.isWorkflowImplemented = dataRow[0]['workFlowImplemented'];
        //     this.tooltipHeader = this.DetailtableHeaderData.filter((th:any) => th['isViewLink'] == true || th['columN_NAME'] == 'text');
        //     // if(this.tooltipHeader.length > 1)
        //     // {
        //     //     this.tooltipIsViewLink = this.tooltipHeader.filter(th => th['isViewLink'] == true)[0]['columN_NAME'];
        //     //     this.tooltipText = this.tooltipHeader.filter(th => th['columN_NAME'] == 'text')[0]['columN_NAME'];               
        //     // }
        //     // else{
        //     //     this.tooltipText = this.tooltipHeader.filter(th =>th['isViewLink'] == true)[0]['columN_NAME']; 
        //     //     console.log(this.tooltipText);
        //     // }
        //     dataRow.forEach((obj:any) => {
        //         let isViewLink = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['isViewLink'] == true)[0]['columN_NAME']);
        //         let colNameValue = that.commonfunction.FirstLetterLower(that.tooltipHeader.filter((th:any) => th['columN_NAME'] == 'text')[0]['columN_NAME']);

        //         if (that.tooltipHeader.length > 1 && isViewLink && colNameValue) {
        //             obj.toolTipRow = obj[colNameValue] + '(' + obj[isViewLink] + ')';

        //         }
        //         else if(isViewLink) {
        //             obj.toolTipRow = obj[isViewLink];
        //         }
        //     });
        //     this.DetailtableRowData = dataRow;


        // },
        //     (err: HttpErrorResponse) => {
        //         this.spinner.hide();
        //         console.log(err.message);
        //         if (err.status == 401) {
        //             this.AlertTitle = "Error!!";
        //             this.AlertMessage = "You are not authorized!";
        //             this.AlertClass = "NP_emoji-fail-bg";
        //             this.AlertAnimationType = "sad";
        //             this.AlertType = " NP_popup_fail";
        //         }
        //         else if (err.status == 403) {
        //             this.Customvalidation.loginroute(err.status);
        //         }
        //         else {

        //             this.AlertTitle = "Error!!";
        //             this.AlertMessage = err.message.toString();
        //             this.AlertClass = "NP_emoji-fail-bg";
        //             this.AlertAnimationType = "sad";
        //             this.AlertType = " NP_popup_fail";
        //         }
        //         this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        //     },
        //     () => {


        //         // this.dtTriggerDetail.next(true);
        //         if (this.ischeckboxshow == 2) {
        //             this.masterSelected = true;
        //             this.checkUncheckAll();
        //         }
        //         this.spinner.hide();
        //     });
    }

    onMainTab() {
        this.paramvaluedata = '';
        this.displayMain = "block";
        this.displayMainEdit = "none";
    }
    onViewTab() {
        this.SearchEdit();
        this.isRenderDetail += 1;
        this.paramvaluedata = '';
        this.displayMain = "none";
        this.displayMainEdit = "block";
        this.resetForm(2);
    }
    onOpenEditMasterModal() {
        $('#EditMasterModal').show();
        this.display = "block";
    }
    onCloseEditMasterModal(isFileDelete: filetypeEnum, callfrom: boolean) {
        $('#EditMasterModal').hide();
        this.display = "none";
        this.workflowStatusId = 0;
        this.workflowstep = 0;
        this.resetEditForm(isFileDelete, callfrom);
        this.updateRecordID = '';
        this.isRenderEdit = false;
        if (this.masterJsonDataEdit.length > 0)
            this.masterJsonDataEdit.splice(0, this.masterJsonDataEdit.length);
    }
    updateEditMasterForm() {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor='';
        let objColumn = <lstObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';

        this.masterJsonDataEdit.forEach(function (value, index) {

            let objList = <ObjectParam>{};
            if (value["identityColumn"].toLowerCase() != "yes" && value["datA_TYPE"].toLowerCase() != "uniqueidentifier") {
                let colValue;
                let formcontrolValue;
                let CompareColumnValue:string='';
                if (value["controlType"].toLowerCase() == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductFormEdit?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id'].toString();
                }
                else if (value["controlType"].toLowerCase() == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductFormEdit?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue === undefined || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                }
                else if (value["controlType"].toLowerCase() == "checkbox") {
                    let chkValue = that.masterProductFormEdit?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false || chkValue == undefined) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"].toLowerCase() == "file") {

                    formcontrolValue = that.masterProductFormEdit?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue
                    }
                    else {
                        colValue = '';
                    }
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    formcontrolValue = that.masterProductFormEdit?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = that.commonfunction.ConvertDateFormat(formcontrolValue);
                    }
                    else {
                        colValue = '';
                    }
                }
                else {
                    formcontrolValue = that.masterProductFormEdit?.value[value["controlName"]];
                    if (formcontrolValue !== null && formcontrolValue !== '' && formcontrolValue !== undefined) {
                        colValue = formcontrolValue
                    }
                    else {
                        colValue = '';
                    }
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        let controlValue = $('#' + value["controlName"]).val() ? $('#' + value["controlName"]).val()?.toString() : '';
                        CompareColumnValue = controlValue ? $.trim(controlValue.toString()) :'';                        
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                // if (colValue !== null && colValue !== '') {
                    objList.TblName = value["tblname"];
                    objList.columnName = value["columN_NAME"];
                    objList.ColumnType = value["datA_TYPE"];
                    objList.ColumnValue = colValue;
                    objList.RequiredField = value["iS_NULLABLE"];
                    objList.ColSize = value["columnLength"];
                    objList.ContType = value["controlType"];
                    objList.validation = value["validations"];
                    objList.columnAliasName = value["columnAliasName"];
                    objList.compareColumnAliasName = value["compareColumnAliasName"];
                    objList.CompareColumnName = value["compareColumnName"];
                    objList.CompareColumnValue = CompareColumnValue;
                    objList.Rangestring = value["range"];
                    genericColumnList.push(objList);
                // }

            }
        });
        if (genericColumnList.length > 0) {

            let objList = <ObjectParam>{};

            objList.TblName = tableNamefor;
            objList.columnName = "ModifiedBy";
            objList.ColumnType = "varchar";
            objList.ColumnValue =that.userId ? that.userId : '';
            objList.RequiredField = "no";
            objList.ColSize = "200";
            objList.ContType = "txt";
            objList.validation = "";
            objList.columnAliasName = "";
            genericColumnList.push(objList);

            let objListRole = <ObjectParam>{};
            objListRole.columnName = "modifiedRoleId";
            objListRole.ColumnType = "int";
            objListRole.ColumnValue = that.currentRole;
            objListRole.RequiredField = "no";
            objListRole.ColSize = "5";
            objListRole.ContType = "txt";
            objListRole.validation = "";
            objListRole.columnAliasName = "";
            genericColumnList.push(objListRole);


        }
        if (genericColumnList.length > 0) {
            objColumn.genericColumnList = genericColumnList;
            _data = JSON.stringify(objColumn);
            let objDatestatusFinalModal = <lstObjectParam>{};
            objDatestatusFinalModal.genericColumnList = genericColumnList;

            this.openEditMasterModal();
            this.paramvaluedata = _data;

        }


    }

    UpdateEditcall() {
        this.spinner.show();
        let wfStatusId = 1;
        if (this.isWorkflowImplemented) {
            wfStatusId = this.workflowstep == 1 ? 1 : this.workflowStatusId;
        }

        let updatedColumn = this.masterJsonDataEdit.filter(x => x.columN_NAME.toLowerCase() == 'id')
        this.userService.EditMasterForm(this.paramvaluedata, updatedColumn[0]['controlValue'], wfStatusId.toString(), this.SeltableIDForm).subscribe((datacom: any) => {

            this.spinner.hide();
            if (datacom != "") {
                let resultarray = JSON.parse(datacom);
                // resultarray['successFlag'] 
                // resultarray['resultMessage']
                // resultarray['printAction']
                // resultarray['printActionResult']
                // resultarray['otherAction']
                // resultarray['otherActionResult']
                // resultarray['outputVarResult']

                if (resultarray['successFlag'] == "success") {
                    this.onCloseEditMasterModal(filetypeEnum.YesPreviousDel, false);
                    this.SearchEdit();
                    this.AlertTitle = "Success!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-success-bg";
                    this.AlertAnimationType = "smile";
                    this.AlertType = "NP_popup_success";
                }

                else {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = resultarray['resultMessage'].toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";

                }

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                this.isRenderDetail += 1;
            });


    }
    openEditMasterModal() {
        this.globalConfirmService.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.UpdateEditcall();
                    this.paramvaluedata = '';
                }
            });
    }

    onfiledownloadDetail(event:any, flagDownload:number, filename:string, colmAlias:string) {

        this.userService.downloadFile(filename, colmAlias, this.SeltableIDForm).subscribe((res:any) => {
            if (res == "true") {
                if (flagDownload == 2) {

                    this.previewImageService.PreviewImageFunction('Preview', filename, colmAlias, this.SeltableIDForm, "Download", "Cancel", "sm")
                        .then((confirmed) => {
                            if (confirmed) {

                            }
                        });


                }
                else {
                    let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + filename + "&columnName=" + colmAlias;
                    // window.open(_url);
                    window.location.href = _url;
                }
            }
            else {

                this.AlertTitle = "Alert!!";
                this.AlertMessage = "File not Exists";
                this.AlertClass = "NP_emoji-error-bg";
                this.AlertAnimationType = "NP_emoji-error";
                this.AlertType = "NP_popup_error";
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();

                console.log(err.message);
                if (err.status == 401) {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            });
    }

    setFileControlValidation(fileName: string, fileErrorMessage: string, fileErrror: boolean, columnname: string, callfrom: string, controlName: string) {
        
        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + fileName + '"}');
        let requiredFileValidation = [];

        requiredFileValidation = callfrom == "E" ? this.masterJsonDataEdit.filter((x:any) => x['controlName'] == controlName) : this.masterJsonData.filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredFileValidation[0]["customValidationMsg"] != "" && requiredFileValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredFileValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
            if (requiredFileValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
                this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            }
            else {
                this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            }
            this.masterProductForm?.patchValue(newValue);

        }
        else if (callingfrom == 'M') {
            if (requiredFileValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
                this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            }
            else {
                this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            }
            this.masterProductForm?.patchValue(newValue);
        }
        else if (callingfrom == 'E') {
            if (requiredFileValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
                this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            }
            else {
                this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            }
            this.masterProductFormEdit?.patchValue(newValue);
        }
    }
    upFile(event: IfileTypeOutput, columnname: string, callfrom: string, controlName: string) {
        let fName = event.fileName ? event.fileName : '';
        let fMessage = event.fileErrorMessage ? event.fileErrorMessage : '';
        let ferror = event.fileErrror? event.fileErrror: false;
        this.setFileControlValidation(fName, fMessage, ferror, columnname, callfrom, controlName);
        if (callfrom == 'E')
            this.masterProductFormEdit.get(controlName)?.markAsTouched();
        else
            this.masterProductForm.get(controlName)?.markAsTouched();

    }

    onTimeChanged(event: string, columnname: string, callfrom: string, controlName: string) {
       
       
        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + event + '"}');
        let requiredTimerValidation = [];

        requiredTimerValidation = callfrom == "E" ? this.masterJsonDataEdit.filter((x:any) => x['controlName'] == controlName) : this.masterJsonData.filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredTimerValidation[0]["customValidationMsg"] != "" && requiredTimerValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredTimerValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.masterProductForm?.patchValue(newValue);

        }
        else if (callingfrom == 'M') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductForm.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.masterProductForm?.patchValue(newValue);
        }
        else if (callingfrom == 'E') {
            // if (requiredTimerValidation[0]['iS_NULLABLE'].toLowerCase() == "no") {
            //     this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror), custumValidation['validateRequired'](requiredMsg, requiredFileValidation[0]['columnLength'].toString(), requiredFileValidation[0]['datA_TYPE'].toString(), requiredFileValidation[0]['controlType'].toString(), requiredFileValidation[0]['columnAliasName'].toString(), requiredFileValidation[0]['compareColumnName'].toString(), requiredFileValidation[0]['compareColumnAliasName'].toString(), requiredFileValidation[0]['range'].toString())]);

            // }
            // else {
            //     this.masterProductFormEdit.get(controlName)?.setValidators([custumValidation['validateFileUpoadErrorMsg'](fileErrorMessage, fileErrror)]);

            // }
            this.masterProductFormEdit?.patchValue(newValue);
        }
        if (callfrom == 'E')
            this.masterProductFormEdit.get(controlName)?.markAsTouched();
        else
            this.masterProductForm.get(controlName)?.markAsTouched();

    }

    onToggleChanged(event: boolean, columnname: string, callfrom: string, controlName: string) {
       let tValue = 0;
       if(event)
            tValue = 1;
        else
            tValue = 0;
        let colname = columnname;
        let callingfrom = callfrom;
        let newValue = JSON.parse('{"' + controlName + '":"' + tValue + '"}');
        let requiredTimerValidation = [];

        requiredTimerValidation = callfrom == "E" ? this.masterJsonDataEdit.filter((x:any) => x['controlName'] == controlName) : this.masterJsonData.filter((x:any) => x['controlName'] == controlName);
        let requiredMsg = '';
        let sizeMsg = '';
        let customMsg = '';
        if (requiredTimerValidation[0]["customValidationMsg"] != "" && requiredTimerValidation[0]["customValidationMsg"] != null) {
            let customValidationMsg = JSON.parse(requiredTimerValidation[0]["customValidationMsg"]);
            requiredMsg = customValidationMsg['requiredMsg'];
            sizeMsg = customValidationMsg['sizeMsg'];
            customMsg = customValidationMsg['customMsg'];
        }

        if (callingfrom == 'I') {
           
            this.masterProductForm?.patchValue(newValue);

        }
        else if (callingfrom == 'M') {
            
            this.masterProductForm?.patchValue(newValue);
        }
        else if (callingfrom == 'E') {
           
            this.masterProductFormEdit?.patchValue(newValue);
        }
        if (callfrom == 'E')
            this.masterProductFormEdit.get(controlName)?.markAsTouched();
        else
            this.masterProductForm.get(controlName)?.markAsTouched();

    }

    commonCustumFunction(param:any) {
        let that = this;
        this.userService.getCommonDataSet(this.SeltableIDForm.toString(), param).subscribe((data: any) => {

            eval(data.table[0]['msg']);
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                console.log(err.message);
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {

                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.spinner.hide();
            });
    }

    OnPrintFullDetail(event:any, id:string, callfrom:any) {

        this.spinner.show();
        var trdata = '';
        const that = this;


        let param = "uspAppGenericCommonPrintFunctionality|Formname=" + this.SeltableID.toString() + "|recordId=" + id;
        this.userService.getCommonDataSet(this.SeltableIDForm.toString(), param).subscribe((data: any) => {


            that.genericPrintService.printDataArray = data.table;
            that.GenericPrintDataArray = data.table;

            this.spinner.hide();
            //   console.log(this.masterProductFormEdit);

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                // this.genericPrintService.printDocument();
                if (this.printPermission == 1)
                    this.genericPrintService.printDocument();
                else if (this.printPermission == 2)
                    this.GenericPrintFunction();

            });
    }

    checkUncheckAll() {

        let that = this;
        this.DetailtableRowData.forEach((value:any, index:number)=> {
            value.isSelected = that.masterSelected;
        });
        this.getCheckedItemList();
    }
    isAllSelected() {
        this.masterSelected = this.DetailtableRowData.every((item: any)=> {
            return item.isSelected == true;
        })
        this.getCheckedItemList();
    }

    getCheckedItemList() {
        this.selectedRows = [];
        let that = this;
        this.DetailtableRowData.forEach((value:any, index:number)=> {
            if (value.isSelected) {
                that.selectedRows.push(value);
            }
        });
    }

    sendMessageArrayList() {

    }
    sendEmailArrayList() {

    }
    onActionArrayList() {

    }

    OnDynamicGenericWorkFlow(value:any, WorkFlowApprove: boolean, WorkFlowReject: boolean, WorkFlowReturn: boolean, WorkFlowForword: boolean, recordId: string, tableName: string, record: string) {
        
        let that = this;
        // let updatedColumn = this.masterJsonDataEdit.filter(x => x.columN_NAME.toLowerCase() == 'text');
        this.GlobalWorkFlowProcessService.WorkFlowFunction(" Record - " + record, WorkFlowApprove, WorkFlowReject, WorkFlowReturn, WorkFlowForword, recordId, tableName, this.SeltableIDForm, "Submit", "Cancel", "md")
            .then((confirmed) => {
                
                if (confirmed["GenericWorkFlow"].flag) {
                    this.SearchEdit();
                    this.globalAlertService.AlertMsg(this.AlertTitle, confirmed["GenericWorkFlow"].errorMsg, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                    this.retainFilter(1500);
                }
            });

    }

    
    calanderClick(inputControl:string) {
        $('#' + inputControl).click();
    }

    textBoxChangeEvent(event: Event, formAliasName: string, customActionName: string, isEdit: boolean) {
       let childTableDataDynamicArrayList: { [kName: string]: any };
        
        if (customActionName != undefined && customActionName.trim() != "") {
           if (isEdit)
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductFormEdit, null, formAliasName, "", true, true,this.masterJsonDataEdit,"");
            else
                this.customActionService[eval("'" + customActionName + "'")](this.masterProductForm,null, formAliasName, "", true, false,this.masterJsonData,"");


        }
    }

    deleteAttachmentForEditForm(fileName:string) {
        let fileOutPut: IfileTypeOutput = {};
        let that = this;
        this.userService.deleteFile(fileName, this.SeltableIDForm.toString()).subscribe((res:any) => {
        });
    }

    GenericPrintFunction() {
        if (this.GenericPrintDataArray != null) {
            // let printContents, popupWin;
            // setTimeout(() => {
        
            //     //   printContents = document.getElementById('printContaint').innerHTML;
            //     printContents = $('#printContaint').html();
            //     let FINALDATA = printContents.replace("display: none", "");
            //     popupWin = window.open('', '_blank', '');
            //     popupWin.document.open();
            //     popupWin.document.write(`<html>
            //  <body style="font-family: sans-serif" onload="window.print();window.close()">${FINALDATA}</body></html>`);
            //     popupWin.document.close();
            //     setTimeout(() => {
            //         popupWin.document.write(`<html><body>${printContents}</body></html>`);
            //     }, 200);
            // }, 500);

            this.genericPrintService.GenericPrintFunction(this.GenericPrintDataArray);
        }
    }

    onHistoryOpenDetail() {
        
       let event:any;
       let procName:string; let params: string; let rowId: number; let modalTitle: string;
        this.spinner.show();
        let updatedColumn = this.masterJsonDataEdit.filter(x => x.columN_NAME.toLowerCase() == 'id');        
        let dialogTitle: string = "History Details" + " - " + this.editModelHeading.split('-')[1];
        let param = this.isHistoryShowProc+"|id=" + updatedColumn[0]['controlValue'].toString();
        this.userService.getCommonDataSet(this.SeltableIDForm.toString(), param).subscribe((data: any) => {
            
            let TrData = '';
            let ThHeader;
      
            this.ViewDetailHistorytableHeaderData = data.table;
            this.ViewDetailHistorytableRowData = data.table1;
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
      
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
      
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
              this.globalAlertService.ShowTableModel(dialogTitle,this.ViewDetailHistorytableHeaderData, this.ViewDetailHistorytableRowData,"Close","","");
                this.spinner.hide();
            });
      
      }

      switchChangeValue(event:any){

      }

}
