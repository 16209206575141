import { Component, OnInit } from '@angular/core';
import { Igallery } from '../../allUsedModule';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery-page-model',
  templateUrl: './gallery-page-model.component.html',
  styleUrls: ['./gallery-page-model.component.css']
})
export class GalleryPageModelComponent implements OnInit {
  gallerydata:Igallery={} as Igallery;
  updateEditMasterForm !:FormGroup;
  constructor(public activeModel:NgbActiveModal) { }

  ngOnInit(): void {
    this.updateEditMasterForm=new FormGroup({
      tabs:new FormControl (this.gallerydata.tabName)
    })
  }

  //Update List Data
  OnClickUpdate(){
    this.gallerydata.tabName = this.updateEditMasterForm.get("tabs")?.value;
    this.updateEditMasterForm.reset()
  }

  //Cancel Btn Function
  onCloseConfirmModal(){
    this.activeModel.close();
  }

}
