import { Component, OnInit } from '@angular/core';
import "node_modules/jquery/dist/jquery";
@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	
	}

}
