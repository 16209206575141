<div class="aboutsection">
    <div class="aboutsection_wrapper position-relative">
        <div class="bg_img_about" style="background-image: url({{aboutData.bgimg}});">
        </div>
        <div class="bg_about_overly" style="align-content: center;">
            <div class="container">
                <div class="main_about_containt">
                    <div class="logo_wrapper" data-aos="fade-right">
                        <div class="logo_box">
                            <img [src]="aboutData.logourl" alt="">
                        </div>
                        <h1>{{aboutData.logobotmheadline}}</h1>
                    </div>
                    <div class="right-containt" data-aos="fade-left">
                        <p>{{aboutData.description1}}</p>
                        <p class="headabout">
                            {{aboutData.description2}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>