import { Component, OnInit } from '@angular/core';
import { Iheader } from '../../allUsedModule';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-page-model',
  templateUrl: './header-page-model.component.html',
  styleUrls: ['./header-page-model.component.css']
})
export class HeaderPageModelComponent implements OnInit {
  headerdata:Iheader = {} as Iheader;
  updateEditMasterForm !:FormGroup
  constructor(private activeModel:NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.headerdata);

    this.updateEditMasterForm =new FormGroup({
      title:new FormControl(this.headerdata.text,[Validators.required]),
      type:new FormControl(this.headerdata.type,[Validators.required]),
      routkey:new FormControl(this.headerdata.routkey,[Validators.required]),
      position:new FormControl(this.headerdata.position,[Validators.required]),
      level0:new FormControl(this.headerdata.level0,[Validators.required]),
      level1:new FormControl(this.headerdata.level1,[Validators.required]),
    })
    
  }

  onCloseConfirmModal(){
    this.activeModel.close()
  }

  OnClickSubmit(){
    this.headerdata.text = this.updateEditMasterForm.get('title')?.value;
    this.headerdata.type = this.updateEditMasterForm.get('type')?.value;
    this.headerdata.routkey = this.updateEditMasterForm.get('routkey')?.value;
    this.headerdata.position = this.updateEditMasterForm.get('position')?.value;
    this.headerdata.level0 = this.updateEditMasterForm.get('level0')?.value;
    this.headerdata.level1 = this.updateEditMasterForm.get('level1')?.value;
    this.activeModel.close(this.headerdata)
  }

}
