import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomePgeModelComponent } from './home-pge-model.component';
import { IhomeCr,IhomeExperiance,IhomeLessons,IhomeLessonsSectionList } from '../../allUsedModule';

@Injectable({
  providedIn: 'root'
})
export class HomePageModelService {

  constructor(private activeModel :NgbModal) { }

  public HomeCrService(
    HomeCrLink:IhomeCr,
    Crflag:boolean,
    modelPopUpTitle:string,
    modelPopUpSetTitle:string,
    modelPopUpdetails:string,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(HomePgeModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.HomeCrLink = HomeCrLink;
    modalRef.componentInstance.Crflag = Crflag
    modalRef.componentInstance.modelPopUpTitle = modelPopUpTitle
    modalRef.componentInstance.modelPopUpSetTitle = modelPopUpSetTitle
    modalRef.componentInstance.modelPopUpdetails = modelPopUpdetails
    console.log();
    return modalRef.result;
  }


  public HomeExperianceService(
    HomeExpLink:IhomeExperiance,
    Expflag:boolean,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(HomePgeModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.HomeExpLink = HomeExpLink;
    modalRef.componentInstance.Expflag = Expflag;
    console.log();
    return modalRef.result;
  }


  public HomeLessonsService(
    HomeLessTabLink:IhomeLessons,
    LessonShowflag:boolean,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(HomePgeModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.HomeLessTabLink = HomeLessTabLink;
    modalRef.componentInstance.LessonShowflag = LessonShowflag;
    console.log();
    return modalRef.result;
  }

  public HomeLessonsListViewService(
    HomeListViewTabLink:Array<IhomeLessonsSectionList>,
    ListViewShowflag:boolean,
    ListEditShowflag:boolean,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(HomePgeModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.HomeListViewTabLink = HomeListViewTabLink;
    modalRef.componentInstance.ListViewShowflag = ListViewShowflag;
    modalRef.componentInstance.ListEditShowflag = ListEditShowflag;
    console.log();
    return modalRef.result;
  }
}
