<header id="main">
    <div>
        <nav class="navbar navbar-expand-sm navbar-dark">
            <ng-template #headerTipContent>{{tooltiptext}}</ng-template>
            <div class="menu_icon" id="sidebarCollapse" [ngbTooltip]="headerTipContent" placement="auto"
                tooltipClass="tooltipBlue" (click)="openNav()">
                <span class="bar"></span>
                <span class="bar bar1"></span>
                <span class="bar bar2"></span>
            </div>
            <div class="mobile_logo">
                <div class="company-logo-mobile" routerLink="/Dashboard"><img src="assets/images/newLogo_2024.svg" alt="Company Logo"></div>
            </div>
            <button class="navbar-toggler menu_icon" type="button"  data-bs-toggle="collapse"
            data-bs-target="#navbarsGeneric" aria-controls="navbarsGeneric" aria-expanded="false"
                aria-label="Toggle navigation">
                
                <span class="multi-menu-icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                </span>
                <span class="multi-menu-icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                </span>
                <span class="multi-menu-icon">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                </span>
            </button>
            <div class="collapse header-collapse-menu navbar-collapse" id="navbarsGeneric">
                <div class="company-logo-inside" routerLink="/Dashboard"><img src="assets/images/newLogo_2024.svg" alt="Company Logo"></div>
                <ul class="navbar-nav header-menu">

                   <div class="onlymobileview">
                    <ng-container *ngFor="let data of MenuItems">
                        <ng-container *ngIf="data.menuflag === 'Y'; else elseif1">
                            <li class="nav-item">
                                <div><a class="">{{data.menu}} <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                                    <div class="np-dropdown-content scrollable-menu">
                                        <ng-container *ngIf="SubMenuItems.length > 0">
                                            <ng-container *ngFor="let subitem of SubMenuItems">
                                                <ng-container *ngIf="subitem.menu == data.menu ;">
                            <li>
                                <a class="">{{subitem.subMenu}} <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                <ul>
                                    <div class="np-dropdown-content scrollable-menu">
                                        <ng-container *ngFor="let subMenuItem of SubMenuItemssub">
                                            <ng-container
                                                *ngIf="subMenuItem.subMenu === subitem.subMenu &&  subMenuItem.menu === subitem.menu;">
                                                <li class="">

                                                    <ng-container
                                                        *ngIf="subMenuItem.callingPage === 'M' ; else elseif3">
                                                        <a routerLink=""
                                                            (click)="Action($event,data.menu, subitem.subMenu ,'GenericMasterForm',subMenuItem.activity,subMenuItem.formValue)"
                                                            class="">{{subMenuItem.activity}} </a>
                                                    </ng-container>
                                                    <ng-template #elseif3>
                                                        <ng-container
                                                            *ngIf="subMenuItem.callingPage === 'T' ; else elseif4">


                                                            <a routerLink=""
                                                                (click)="Action($event,data.menu, subitem.subMenu ,'GenericMultiForm',subMenuItem.activity,subMenuItem.formValue)"
                                                                class="">{{subMenuItem.activity}} </a>
                                                        </ng-container>
                                                    </ng-template>
                                                    <ng-template #elseif4>
                                                        <ng-container
                                                            *ngIf="subMenuItem.callingPage === 'R' ; else elseif5">
                                                            <a routerLink=""
                                                                (click)="Action($event,data.menu , subitem.subMenu ,'GenericReportForm',subMenuItem.activity,subMenuItem.formValue)"
                                                                class=" ">{{subMenuItem.activity}}</a>

                                                        </ng-container>
                                                    </ng-template>
                                                    <ng-template #elseif5>
                                                        <ng-container
                                                            *ngIf="subMenuItem.callingPage === 'L' ; else elseif6">
                                                            <a routerLink=""
                                                                (click)="Action($event,data.menu , subitem.subMenu ,'GenericListForm',subMenuItem.activity,subMenuItem.formValue)"
                                                                class=" ">{{subMenuItem.activity}}</a>

                                                        </ng-container>
                                                    </ng-template>
                                                    <ng-template #elseif6>
                                                        <a routerLink=""
                                                            (click)="Action($event,data.menu, subitem.subMenu ,subMenuItem.formValue,subMenuItem.activity,subMenuItem.formValue)"
                                                            class="">{{subMenuItem.activity}} </a>
                                                    </ng-template>

                                                </li>
                                            </ng-container>
                                        </ng-container>

                                    </div>
                                </ul>
                            </li>
                        </ng-container>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let datasub of MenuItemssub">
                        <ng-container *ngIf="datasub.menu === data.menu ;">
                            <li class="">

                                <ng-container *ngIf="datasub.callingPage === 'M' ; else elseif3">
                                    <a routerLink=""
                                        (click)="Action($event,data.menu ,'','GenericMasterForm',datasub.activity,datasub.formValue)"
                                        class="">{{datasub.activity}} </a>
                                </ng-container>
                                <ng-template #elseif3>
                                    <ng-container *ngIf="datasub.callingPage === 'T' ; else elseif4">


                                        <a routerLink=""
                                            (click)="Action($event,data.menu,'' ,'GenericMultiForm',datasub.activity,datasub.formValue)"
                                            class="">{{datasub.activity}} </a>
                                    </ng-container>
                                </ng-template>
                                <ng-template #elseif4>
                                    <ng-container *ngIf="datasub.callingPage === 'R' ; else elseif5">
                                        <a routerLink=""
                                            (click)="Action($event,data.menu,'' ,'GenericReportForm',datasub.activity,datasub.formValue)"
                                            class=" ">{{datasub.activity}}</a>

                                    </ng-container>
                                </ng-template>
                                <ng-template #elseif5>
                                    <ng-container *ngIf="datasub.callingPage === 'L' ; else elseif6">
                                        <a routerLink=""
                                            (click)="Action($event,data.menu,'' ,'GenericListForm',datasub.activity,datasub.formValue)"
                                            class=" ">{{datasub.activity}}</a>

                                    </ng-container>
                                </ng-template>
                                <ng-template #elseif6>
                                    <a routerLink=""
                                        (click)="Action($event,data.menu,'' ,datasub.formValue,datasub.activity,datasub.formValue)"
                                        class="">{{datasub.activity}} </a>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ng-container>

                
            </div>
    </div>
    </li>
    </ng-container>
    <ng-template #elseif1>
        <li class="">
            <ng-container *ngIf="data.callingPage === 'M'; else elseif2">
                <a routerLink="" (click)="Action($event,'','' ,'GenericMasterForm',data.menu,data.formValue)"
                    class="">{{data.menu}}</a>
            </ng-container>
            <ng-template #elseif2>
                <ng-container *ngIf="data.callingPage === 'T' ; else elseif4">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericMultiForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif4>
                <ng-container *ngIf="data.callingPage === 'R' ; else elseif5">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericReportForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif5>
                <ng-container *ngIf="data.callingPage === 'L' ; else elseif6">
                    <a routerLink="" (click)="Action($event,'','' ,'GenericListForm',data.menu,data.formValue)"
                        class=" ">{{data.menu}}</a>
                </ng-container>
            </ng-template>
            <ng-template #elseif6>
                <a routerLink="" (click)="Action($event,'' ,'',data.formValue,data.menu,data.formValue)"
                    class="">{{data.menu}}</a>
            </ng-template>
        </li>
    </ng-template>
    </ng-container>
                   </div>


    <li class="nav-item ">
        <div><a class="header_user"><span class="user-image"></span><span class="role-name">{{currentRole}} </span> <i
                    class="fa fa-angle-down header_icon" aria-hidden="true"></i></a>
            <div class="np-dropdown-content haeder_user">
    <li class="" *ngFor="let role of RoleList.Roles">
        <a routerLink="" (click)="changeRole($event,role.roleId,role.rolDes)" class="">{{role.rolDes}} </a>
    </li>

    <ul class="list-unstyled m-0">
        <li>
            <a href="JavaScript:Void(0);" routerLink="/ManageProfile" class="list-group-item">
                <i class="demo-pli-male icon-lg icon-fw"></i>Manage Profile
            </a>
        </li>
        <li>
            <a href="JavaScript:Void(0);" routerLink="/Changepassword" class="list-group-item">
                <i class="demo-pli-gear icon-lg icon-fw"></i> Change Password
             </a>
        </li>
    </ul>  

    
    <li class="">

        <a href="javascript: void(0);" routerLink="" (click)="Logout()" class="signout-txt">
            <i class="demo-pli-unlock icon-lg icon-fw"></i> Sign Out
        </a>

    
    </li>
    </div>
    </div>
    </li>

    </ul>



    </div>

    </nav>
    </div>
</header>