import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { async } from '@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";

import { NgxSpinnerService } from 'ngx-spinner';
import { UserObjectParam, lstUserObjectParam, ObjectParam, lstObjectParam, UserReportObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from 'angular-datatables';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { IFilterColumns, IviewFullDetails } from '../../shared/model/genericTableListViewModel';
// import  'src/typings';
import 'jquery';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare let jQuery: any;

@Component({
  selector: 'app-generate-report-form',
  templateUrl: './generate-report-form.component.html',
  styleUrls: ['./generate-report-form.component.css']
})
export class generateReportFormComponent implements  OnDestroy, OnInit {

  totalRecords:number = 0;
  pageSize:number = 10;
  currentPage:number = 1;
  sortOrder: string ="ASC";
  sortColumn:string = "1";
  popoverEvent:any;
  activeTabId: number = 1;
  filterFormValue: IFilterColumns= {} as IFilterColumns;
  filterColumnName: string = ""; 
  filterColumnDataType:string = ""
  filterColumnParentTableName:string = ""
  filterColumnDisplayName: string = "";
  filterColumnQuery: IFilterColumns[] = [];  
  filterForm!: FormGroup;
  filterDisplayQuery: Array<{ columnName: string, displayQuery: string, operator: string }> =[];
  filterActualQuery: Array<{ columnName: string, columnValue: string, conditionOperator: string , filterColConditionOperator:string}> = [];
  tableCols:any[] = [
    {key:'procName',displayName:'Procedure Name'},
    {key:'procAliasName',displayName:'Procedure Alias Name'},
    {key:'userRole',displayName:'Procedure User Role'},
    {key:'checkBoxFlag',displayName:'Is Check Box'},
    {key:'baseTable',displayName:'Base Table'},
    {key:'actionGroupName',displayName:'Action Group'},
    {key:'pageStyle',displayName:'Page Style'},
    {key:'isExcelDownload',displayName:'Is Excel Download'},
    {key:'isPdfDownload',displayName:'Is Pdf Download'}
  
  ]; 

  SeltableID: string="";
    SeltableIDForm: string="";
    txtTableAlias: string="";
    txtBaseTable: string="";
    txtTableAliasE: string="";
    txtBaseTableE: string="";
    savedtxtTableAliasE  :string="";
  @ViewChild(DataTableDirective) 
  dtElement!: DataTableDirective;
  validationdrpOption: string = '';
  controldrpOption: string = '';
  controldrpOptionDate: string = '';
  controldrpOptionNumber: string = '';
  valueFillTypedrpOption: string = '';
  MailTemplatedrpOption: string = 'testtt';
  wfdrpOption: string = '';
  PageStyledrpOption: string = '';
  controldrpOption2: string = '';
  validationdrpOption2: string = '';
  dtOptions: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    //select from drop down list to select number of records per page
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 300,
    scrollX: true,
    scrollCollapse: true,
    fixedHeader: {
      header: true,
      footer: true
    },
    // Declare the use of the extension in the dom parameter
    dom: 'Bfltip',

    // Configure the buttons
    buttons: ['print', 'excel']

  };
  dtOptionsEdit: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    //select from drop down list to select number of records per page
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 300,
    scrollX: true,
    scrollCollapse: true,
    fixedHeader: {
      header: true,
      footer: true
    },



  };
  viewtableHeaders: TableColumnName[] = []; 
  dtTrigger: Subject<any> = new Subject();
  dtTriggerEdit: Subject<any> = new Subject();
  mainTableId: string="";
  mainTableIdE: string="";
  selectedTableId: string="";
  tableData:any;
  viewTableData:any;
  editTableData:any;
  detailTableData:any;
  tableid: string="";
  Edittableid: string="";
  display = "none";
  displayMain = "none";
  displayMainEdit = "none";
  displayContainer = "block";
  sel: any;
  callfor: string="";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "NP_popup_success";
  AlertAnimationType: any = "smile";
  tblecount: number=0;
  public value: string[]=[];
  public current: string="";
  public Validationcurrent: string="";
  public selected: string="";
  public dynamicdivdata: any;
  public exampleArray: Array<{ id: string, text: string }>=[];
  dropdownListProc = [];
  selectedItemsProc: Array<{ id: string, itemName: string }> = [];
  dropdownListRole = [];
  selectedItemsRole: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadValidationdrp = [];
  selectedItemspageloadValidationdrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControldrp = [];
  selectedItemspageloadControldrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControlDatedrp = [];
  selectedItemspageloadControlDatedrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadControldrpNumber = [];
  selectedItemspageloadControldrpNumber: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadValueFillTypedrp = [];
  selectedItemspageloadValueFillTypedrp: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadMailTemplatedrp = [];
  selectedItemspageloadMailTemplatedrp: Array<{ id: string, itemName: string }> = [];
  selectedItemspageloadMailTemplatedrpE: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadActiondrp = [];
  selectedItemspageloadActiondrp: Array<{ id: string, itemName: string }> = [];
  selectedItemspageloadActiondrpE: Array<{ id: string, itemName: string }> = [];
  dropdownListpageloadPageStyle: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: '1 control in line' },
  { id: 2, itemName: '2 control in line' },
  { id: 3, itemName: '3 control in line' },
  { id: 4, itemName: '4 control in line' }];
  selectedItemspageloadPageStyle: Array<{ id: number, itemName: string }> = [{ id: 4, itemName: '4 control in line' }];
  selectedItemspageloadPageStyleE: Array<{ id: number, itemName: string }> = [{ id: 4, itemName: '4 control in line' }];
  dropdownListFormType: Array<{ id: string, itemName: string }> = [{ id: 'R', itemName: 'R - Report Form' },
  { id: 'L', itemName: 'L - List Form' }];
  selectedDropdownListFormType: Array<{ id: string, itemName: string }> = [{ id: 'R', itemName: 'R - Report Form' }];
  selectedDropdownListFormTypeE: Array<{ id: string, itemName: string }> = [{ id: 'R', itemName: 'R - Report Form' }];
  dropdownListReportCheckBoxAction: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox in First Column' },
  { id: 1, itemName: 'Yes Checkbox in First Column' },
  { id: 2, itemName: 'Yes Checkbox Select All' }];
  selectedItemsReportCheckBoxAction: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox in First Column' }];
  selectedItemsReportCheckBoxActionE: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No Checkbox in First Column' }];
  dropdownListpageloadIsExcel: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadIsExcel: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadIsExcelE: Array<{ id: number, itemName: string }> = [];

  dropdownListpageloadIsPdf: Array<{ id: number, itemName: string }> = [{ id: 1, itemName: 'Yes' },
  { id: 0, itemName: 'No' }];
  selectedItemspageloadIsPdf: Array<{ id: number, itemName: string }> = [{ id: 0, itemName: 'No' }];
  selectedItemspageloadIsPdfE: Array<{ id: number, itemName: string }> = [];
  validationdropdownList = [];
  selectedItems: string = '';
  validationselectedItems = [];
  dropdownSettings = {};
  dropdownGridSettings = {};
  ValidationdropdownSettings = {};
  changeDetectorRefs: ChangeDetectorRef[] = [];
  paramvaluedata: any;
  column_Data: any[]=[];
  param: any;
  menulabel: string="";
  formlable: string="";
  constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation, private userService: UserService,
    private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, private spinner: NgxSpinnerService,
    private http: HttpClient, private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService) {


  }

  ngOnInit() {
    // $('.selectpicker').selectpicker();
    

    this.param = sessionStorage.getItem("menuItem");
    // this.userService.getdynamicformparam().subscribe(data => { this.param = data });
    let paramjs = JSON.parse(this.param);
    this.SeltableID = paramjs.formValue;
    this.SeltableIDForm = paramjs.formName;
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.pageloaddrp();   
    this.onMainTab();
    this.dropdownSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
    this.dropdownGridSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      position: 'bottom',
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "form-control myclass custom-class"
    };

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTriggerEdit.unsubscribe();
  }


  pageloaddrp() {
    let item = '';
    let itemMulti = '';
    
    let param ="uspAppGenericGetAllRole";
    this.userService.getCommonDrpdownList(this.SeltableIDForm.toString(),param).subscribe((res: any) => {
      this.dropdownListRole = res;
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      });

  }
  onMainTab() {
   
    this.paramvaluedata = '';    
    this.displayMainEdit = "none";
    this.displayMain = "none";
    this.displayContainer = "block";  
    this.txtBaseTable = '';
    this.txtBaseTableE = '';
    this.txtTableAlias = '';
    this.txtTableAliasE = ''; 
  }
  onViewTab() {
    
    this.txtBaseTable = '';
    this.txtBaseTableE = '';
    this.txtTableAlias = '';
    this.txtTableAliasE = '';
    this.SearchEdit(1);
    this.displayMainEdit = "block";
    this.displayMain = "none";
    this.displayContainer = "none"; 
    this.paramvaluedata = '';
   
    this.selectedItemsProc = [];
  }

  onItemSelect(item: any) {
    
    let param ="uspAppGenericGetProcNamesforGenerateReport|roleId="+  this.selectedItemsRole[0].id.toString();
    this.userService.getCommonDrpdownList(this.SeltableIDForm.toString(),param).subscribe((res: any) => {
     
      this.dropdownListProc = res;
      if(this.selectedItemsProc.length > 0)
      {
        this.selectedItemsProc.splice(0,this.selectedItemsProc.length)
      }
     
    },
      (err: HttpErrorResponse) => {
        //console.log(err.message);  
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      ()=>{
        this.  OnResetModal();
      });
  }
  OnItemDeSelect(item: any) {

      if(this.dropdownListProc.length > 0)
      {
        this.dropdownListProc.splice(0,this.dropdownListProc.length)
      }
      if(this.selectedItemsProc.length > 0)
      {
        this.selectedItemsProc.splice(0,this.selectedItemsProc.length)
      }
      if(this.tableData.length > 0)
      {
        this.tableData.splice(0,this.tableData.length)
      }

       this.  OnResetModal();
  
  

  }
  onItemSelectProc(item: any) {
  
    this.Search(this.selectedItemsProc);
    
  }
  OnItemDeSelectProc(item: any) {
       this.  OnResetModal();
  }
  onAddclick() {

    
    this.Search(this.selectedItemsProc);
  }

  Search(tbname:any) {
    if (tbname.length > 0) {
      const that = this;
      $('#tblHead').empty();
      $('#tblHead').append("Register " + tbname[0]['id'] + " SP Form.");
      this.userService.getMasterReportFormcolumnDetails(tbname[0]['id'], this.SeltableIDForm, this.selectedItemsRole[0].id).subscribe((data: any) => {
        this.mainTableId = "Dynamictable" + tbname[0]['id'];
        this.selectedTableId = tbname[0]['id'];
        this.tableData = data.table;
        console.log(this.tableData)
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;       
        this.dropdownListpageloadValueFillTypedrp = data.table3;   
        // this.dropdownListpageloadMailTemplatedrp = data.table4;
        this.dropdownListpageloadControlDatedrp = data.table4;
        this.dropdownListpageloadControldrpNumber = data.table5;
        this.dropdownListpageloadActiondrp = data.table6;

        this.txtTableAlias = tbname[0]['id'];
        this.displayMain = "block";
      });
    }
    else {
      this.globalAlertService.AlertMsg("Alert!!", "Please Select Procedure.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");

    }
  };

  openResetModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Reset !!", "Are you sure you want to reset?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.OnResetModal();
        }
      });
  }

  OnResetModal() {   
    this.displayMain = "none";
    this.displayMainEdit = "none"
    this.paramvaluedata = '';    
    this.selectedItemsProc = [];
    this.txtBaseTable = '';
    this.txtTableAlias = '';
  }
  openSubmitModal() {
    this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.submitcall();
          this.paramvaluedata = '';
        }
      });
  }

  OnSubmitModal() {
    
    // alert("3");

    let baseTable = $.trim(this.txtBaseTable.toString());
    let tableAliasName = $.trim(this.txtTableAlias.toString());
    let tablePageStyle = $.trim(this.selectedItemspageloadPageStyle[0].id.toString());    
  
    let userRoleId = '';
    if (this.selectedItemsRole.length > 0) {
      userRoleId = this.selectedItemsRole[0].id;
    }
    let chkBoxId = '';
    if (this.selectedItemsReportCheckBoxAction.length > 0) {
      chkBoxId = this.selectedItemsReportCheckBoxAction[0].id.toString();
    }
    let childActionGroup = '';
    if (this.selectedItemspageloadActiondrp.length > 0) {
      childActionGroup = this.selectedItemspageloadActiondrp[0].id.toString();
    }
    let formType = '';
    if (this.selectedDropdownListFormType.length > 0) {
      formType = this.selectedDropdownListFormType[0].id.toString();
    }
    let isExcel = 0;
    if (this.selectedItemspageloadIsExcel.length > 0) {
      isExcel = this.selectedItemspageloadIsExcel[0].id;
    }
    let isPDF = 0;
    if (this.selectedItemspageloadIsPdf.length > 0) {
      isPDF = this.selectedItemspageloadIsPdf[0].id;
    }
    if (tableAliasName != "") {
      let that = this;
      let flag = true;
      let msg = '';
      let _data;
      let objColumn = <lstUserObjectParam>{};
      let genericColumnList:any = [];
      let mylist = '[';
      this.tableData.forEach(function (valuerow:any, index:number) {
      // $('.Uiparams').each(()=> {
        let objList = <UserReportObjectParam>{};
        let columnAliasName:string ="";
        let txtColSize:string ="";
        let spnColType:string ="";
        let namecol:string ="";
        let chkid:string ="";
        let chkidHide:string ="";
        let chkidValue:string ="";        
        let requiredfield:string ="";
        let columnHide:string ="";
        let Validations:string ="";
        let valueFillType:string ="";
        let controlType:string ="";
        let compareColumnName:string ="";
        let range:string ="";
        let dropDownQuery:string ="";
        let dropDownChildtable:string ="";
        let expressionJson:string ="";
        let colSeq:string ="";
        let colClass:string ="";
        let colEvent:string ="";
        let colViewLink:string ="";
        let customValidationMsg:string ="";
        let columnInformation:string ="";
        let regExpString:string ="";
        // let TblName = $(this).closest('tr').attr('tableId');
        // let columnName = $(this).closest('tr').attr('columnId');
        // let colsize = $(this).closest('tr').attr('colsize');
        let TblName: string = that.selectedTableId!.toString();
        let columnName:string = valuerow['columN_NAME']!.toString(); //columnName;
        let colsize:string = valuerow['columnLength']!.toString();
        chkid = 'input[id=chk_' + columnName + ']';
        if ($(chkid).prop('checked')) {
          requiredfield = "true";
        } else {
          requiredfield = "false";
        }
       
        chkidHide = 'input[id=chkHide_' + columnName + ']';
        if ($(chkidHide).prop('checked')) {
          columnHide = "true";
        } else {
          columnHide = "false";
        }
        
        valueFillType = $('#DrpValue_' + columnName).val()!.toString();
        columnAliasName = $.trim($('#txtc_' + columnName).val()!.toString());
        colSeq = $.trim($('#txtSeq_' + columnName).val()!.toString());
        colClass = $.trim($('#txtClass_' + columnName).val()!.toString());       
        txtColSize = $.trim($('#txt_' + columnName).val()!.toString());
        range = $.trim($('#txtRange_' + columnName).val()!.toString());
        spnColType = $.trim($('#spn_' + columnName)[0].innerText!.toString());
        Validations = $('#Drp_' + columnName).val()!.toString();        
        controlType = $('#DrpC_' + columnName).val()!.toString();
        dropDownChildtable = $.trim($('#txtChild_' + columnName).val()!.toString());
        dropDownQuery = $.trim($('#txtQuery_' + columnName).val()!.toString());
        compareColumnName = $('#DrpCC_' + columnName).val()!.toString();
        compareColumnName = $('#DrpCC_' + columnName).val()!.toString();
        customValidationMsg = $.trim($('#txtValidationMsg_' + columnName).val()!.toString());
        columnInformation = $.trim($('#txtcolumnInformation_' + columnName).val()!.toString());
        regExpString = $.trim($('#txtRegExp_' + columnName).val()!.toString());


        if (txtColSize.toLowerCase() == "max") {
          txtColSize = "-1";
        }
        if (spnColType.toLowerCase() == "date" || spnColType.toLowerCase() == "datetime" || spnColType.toLowerCase() == "bit") {
          txtColSize = "0";
        }
        if (flag) {
          if (that.Customvalidation.validateRequired(columnAliasName) == false) {

            msg = 'Please enter column alias for ' + columnName;

            flag = false;
            return flag;
            
          }
          else {
            flag = true;

          }
          

        }
        if (columnName.toLowerCase() != "id" && columnName.toLowerCase() != "guid" && columnHide == "false") {
          if (flag) {
            if (that.Customvalidation.validateRequired(controlType) == false) {

              msg = 'Please Select Control for ' + columnName;

              flag = false;
              return flag;
            }
            else {
              flag = true;

            }
           
          }
        }
        else {
          controlType = "empty";
        }
        if (spnColType.toLowerCase() != "date" && spnColType.toLowerCase() != "datetime" && spnColType.toLowerCase() != "bit") {
          if (flag) {
            if (that.Customvalidation.validateRequired(txtColSize) == false) {

              msg = 'Please enter column length for ' + columnName;

              flag = false;
              return flag;
            }
            else {
              flag = true;

            }
           

          }
          if (flag && txtColSize != "-1") {
            if (!that.Customvalidation.validatenumeric(txtColSize, '', '', '', '', '', '', '')[0]) {

              msg = 'Please enter column length in only digts for ' + columnName;

              flag = false;
              return flag;
             
            }
            else {
              flag = true;

            }
           
          }
          if (flag) {
            if (that.Customvalidation.validateSize(colsize, txtColSize) == false) {

              msg = 'Max length exceeded from table column length for ' + columnName;

              flag = false;
              return flag; 
            }
            else {
              flag = true;

            }
           
          }
        }

        if (flag) {
          objList.procName = TblName;
          objList.formType = formType;
          objList.procAliasName = tableAliasName;
          objList.tablePageStyle = tablePageStyle;
          objList.userRoleId = userRoleId;
          objList.tablecheckBoxFlag = chkBoxId;
          objList.tablemailTriggerSP = childActionGroup;
          objList.columnName = columnName;
          objList.dataType = spnColType;
          objList.columnLength = txtColSize;
          objList.columnAliasName = columnAliasName;
          objList.Validation = Validations;
          objList.columnHide = columnHide;
          objList.valueFillType = valueFillType;
          objList.dropDownChildtable = dropDownChildtable;
          objList.dropDownQuery = dropDownQuery;
          objList.controlType = controlType;
          objList.isRequired = requiredfield;   
          objList.compareColumnName = compareColumnName       
          objList.range = range;          
          objList.columnSequence = colSeq;
          objList.controlClass = colClass;  
          objList.baseTable = baseTable;
          objList.customValidationMsg = customValidationMsg;
          objList.columnInformation = columnInformation;
          objList.regExp = regExpString;
          objList.isExcelDownload = isExcel!.toString();
          objList.isPdfDownload = isPDF!.toString();
          genericColumnList.push(objList);
          return flag;
        }

        else {
          return flag;
        }

      });
      if (genericColumnList.length > 0) {
        objColumn.genericColumnList = genericColumnList;
        _data = JSON.stringify(objColumn);
        //   console.log(_data);
        // alert(_data);
      }
      let objDatestatusFinalModal = <lstUserObjectParam>{};
      objDatestatusFinalModal.genericColumnList = genericColumnList;
      if (flag) {

        this.openSubmitModal();
        this.paramvaluedata = _data;
      }
      else {
        this.paramvaluedata = '';
        this.globalAlertService.AlertMsg("Alert!!", msg.toString(), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;
      }
    }
    else {
      this.paramvaluedata = '';
      this.globalAlertService.AlertMsg("Alert!!", "Please enter Table Alias Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
      return;
    }
  }
  submitcall() {

    //alert("hello");
    this.userService.UserProcedureAliasSubmitForm(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {

      
      if (datacom != "") {
        let resultarray  = JSON.parse(datacom);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
        this.SearchEdit(2);
        if (resultarray['successFlag'] == "success") {
          //   this.Search(this.SeltableID);   
          this.OnResetModal();
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";
        }
      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },
      () => {
        this.pageloaddrp();
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });

  }


  openViewtModal() {

    $('#ViewModal').show();
    this.display = 'block';

  }

  onCloseViewModal() {

    $('#ViewModal').hide();
    this.display = 'none';
  }

  SearchEdit(callfrom: number) {

    this.spinner.show();
    
    let tablename;
    if (callfrom == 1) {
      tablename = this.SeltableID;
    }
    else if (callfrom == 2) {
      tablename = this.SeltableID;
    }
    this.displayMainEdit = "block";     
    $('#Edittableid').DataTable().destroy();

    let that = this;
    let filterQueryString: string = this.filterActualQuery.length >0 ? JSON.stringify(this.filterActualQuery) : "";
    let dataRep = 'uspAppGenericProcGetViewDetails|tblname=' + tablename+ '|filterParams=' + filterQueryString + '|pageSize=' + this.pageSize.toString() + '|page=' + this.currentPage.toString() + '|callFlag='+callfrom  + '|sortOrder=' + this.sortOrder +'|sortColumn='+ this.sortColumn;


    this.userService.GetDataSetResult(dataRep, this.SeltableIDForm).subscribe((data: any) => {
      if(data?.table1?.length >0)  
      this.totalRecords = data.table1[0]['totalRecords'];
      this.detailTableData = data.table;
      
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err.message);
        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      ()=>{
       
      this.spinner.hide();
      });


  }
  // onGetProcDetailsByProcId
  onGetProcDetailsByProcId(spName: string,userRoleId: string, event: Event) {
    let param ='uspAppGenericGenerateReportProcColumnsGetDetails|spname=' + spName + '|userRoleId='+userRoleId;
    this.userService.getCommonDataSet( this.SeltableIDForm.toString(),param)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;
        // this.mainTableId ="Dynamictable" + tblName + "E";
        this.selectedTableId = spName;
        this.viewTableData = data.table;
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;        
        this.dropdownListpageloadValueFillTypedrp = data.table3;
        this.dropdownListpageloadControlDatedrp = data.table4;
        this.dropdownListpageloadControldrpNumber = data.table5;
        this.dropdownListpageloadActiondrp = data.table6;
        this.openViewtModal();
        console.log(this.viewTableData);
      });
  }
  // onGetEditProcedureDetailsByProcId(tbname)
  onGetEditProcedureDetailsByProcId(procName: string, procAliasName: string, formPageStyle: string, checkBoxFlag: string, actionGroupId: string,
    userRoleId: string, baseTable: string, formType: string , isExcelDownload: string, isPdfDownload: string, event: Event) {
   let param = 'uspAppGenericGenerateReportProcColumnsGetDetails|spname='+procName+ '|userRoleId='+userRoleId;
    this.userService.getCommonDataSet( this.SeltableIDForm.toString(),param)
      .subscribe((data: any) => {
        
        let TrData = '';
        let ThHeader;
        let TableId;
        this.txtTableAliasE = procAliasName;
        this.txtBaseTableE = baseTable;
        this.savedtxtTableAliasE = procAliasName;
        this.mainTableId = "Dynamictable" + procName + "E";
        this.selectedTableId = procName;
        this.editTableData = data.table;
        this.dropdownListpageloadValidationdrp = data.table1;
        this.dropdownListpageloadControldrp = data.table2;        
        this.dropdownListpageloadValueFillTypedrp = data.table3; 
        this.dropdownListpageloadActiondrp = data.table6;       
        this.selectedItemspageloadPageStyleE = this.dropdownListpageloadPageStyle.filter((ps:any) => ps.id == +formPageStyle);
        this.selectedItemsReportCheckBoxActionE = this.dropdownListReportCheckBoxAction.filter((ps:any) => ps.id == +checkBoxFlag);
        this.selectedItemspageloadActiondrpE = this.dropdownListpageloadActiondrp.filter((ps:any) => ps.id == +actionGroupId);
        this.selectedItemspageloadIsExcelE = this.dropdownListpageloadIsExcel.filter(ps => ps.itemName == isExcelDownload);
        this.selectedItemspageloadIsPdfE = this.dropdownListpageloadIsPdf.filter(ps => ps.itemName == isPdfDownload);
        this.selectedDropdownListFormTypeE = this.dropdownListFormType.filter((ps:any) => ps.id == formType);
        // this.selectedItemspageloadWfdrpE = this.dropdownListpageloadWfdrp.filter(ps => ps.id == workFlowId);

        this.onOpenEditModal();
        console.log(this.editTableData);

      });
  }

  onTableAliasAction(id:string, action:string,formType:string, TableId:number) {
    this.globalConfirmService.ConfirmFunction("Confirm!!", "Are you sure you want to " + action + "?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.TableAliasAllAction(id, action,formType, TableId);
          this.paramvaluedata = '';
        }
      });
  }
  TableAliasAllAction(id: string, action: string,formType: string, index: number) {
    
    //code to be done
    let resultarray:any ;
    this.userService.MasterReportAliasAction(id, action,formType, this.SeltableIDForm.toString()).subscribe((data: any) => {
      if (data != "") {
         resultarray  = JSON.parse(data);			
        // resultarray['successFlag'] 
        // resultarray['resultMessage']
        // resultarray['printAction']
        // resultarray['printActionResult']
        // resultarray['otherAction']
        // resultarray['otherActionResult']
        // resultarray['outputVarResult']
        if (action == "Active" || action == "InActive") {
          if (resultarray['successFlag'] == "success") {                      
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
            return;
          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deactive.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;
          }
        }
        else {
          if (resultarray['successFlag'] == "success") {
            //            
            this.globalAlertService.AlertMsg("Success!!", "Data " + action, "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");

            return;

          }
          else {
            this.globalAlertService.AlertMsg("Alert!!", "Data cannot be deleted.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
            return;

          }
        }
      }
    },
      (err: HttpErrorResponse) => {

        if (err.status == 401) {
          this.globalAlertService.AlertMsg("Error!!", "You are not authorized!", "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.globalAlertService.AlertMsg("Error!!", err.message.toString(), "Ok", "NP_popup_fail", "NP_emoji-fail-bg", "sad");
        }
      },
      () => {
        if(resultarray['successFlag'] == "success"){
        if (action == "Active") {
          this.detailTableData[index]['isActive'] = true;
        }
        else if (action == "InActive") {
          this.detailTableData[index]['isActive'] = false;
        }
        else {
          this.SearchEdit(2); 
          this.pageloaddrp();
        }
      }

      });
    //

  }


  onOpenEditModal() {
    $('#EditMasterModal').show();
    this.display = 'block';

  }
  onCloseEditModal() {
    $('#EditMasterModal').hide();
    this.display = 'none';
  }

  updateEditTableEntry() {   
    
    let tableAliasName = $.trim(this.txtTableAliasE);
    let baseTable = $.trim(this.txtBaseTableE);
    let tablePageStyle = this.selectedItemspageloadPageStyleE[0].id.toString();
   

    let chkBoxId = '';
    if (this.selectedItemsReportCheckBoxActionE.length > 0) {
      chkBoxId = this.selectedItemsReportCheckBoxActionE[0].id.toString();
    }
    let childActionGroup = '';
    if (this.selectedItemspageloadActiondrpE.length > 0) {
      childActionGroup = this.selectedItemspageloadActiondrpE[0].id.toString();
    }
    let formType = '';
    if (this.selectedDropdownListFormTypeE.length > 0) {
      formType = this.selectedDropdownListFormTypeE[0].id.toString();
    }
    let isExcel = 0;
    if (this.selectedItemspageloadIsExcelE.length > 0) {
      isExcel = this.selectedItemspageloadIsExcelE[0].id;
    }
    let isPDF = 0;
    if (this.selectedItemspageloadIsPdfE.length > 0) {
      isPDF = this.selectedItemspageloadIsPdfE[0].id;
    }
    // alert(tableAliasName);
    if (tableAliasName != "") {
      let that = this;
      let flag = true;
      let msg = '';
      let _data;
      let objColumn = <lstUserObjectParam>{};
      let genericColumnList:any = [];
      let mylist = '[';
      this.editTableData.forEach(function (valuerow:any, index:number) {
      // $('.UiparamsE').each(()=> {
        let objList = <UserReportObjectParam>{};
        let columnAliasName;
        let txtColSize;
        let txtColCurrentSize;
        let spnColType;
        let spnOColType;
        let namecol;
        let chkid;
        let chkoid;
        let chkidHide;
        let chkidValue;
        let requiredfield;
        let originalrequiredfield;
        let columnHide;
        let Validations;
        let valueFillType;
        let controlType;
        let compareColumnName;
        let range;
        let dropDownQuery;
        let dropDownChildtable;
        let expressionJson;
        let columnSequence;
        let controlClass;
        let columnEventDefination;
        let chkviewLink;
        let colViewLink;
        let colSeq;
        let colClass;
        let customValidationMsg;
        let columnInformation;
        let regExpString;
        
        let TblName: string = that.selectedTableId!.toString();
        let columnName:string = valuerow['columN_NAME']!.toString(); //columnName;
        // let TblId = $(this).closest('tr').attr('tableId');
        let ColumnId = valuerow['id']!.toString(); 
        // let TblName = $(this).closest('tr').attr('tablename');
        // let columnName = columnName;
        let TblId =  valuerow['tableid']!.toString(); 
        // let ColumnId = $(this).closest('tr').attr('columnId');
        let colsize = valuerow['columnLength']!.toString(); 
        chkid = 'input[id=chk_' + columnName + 'E' + ']';
        if ($(chkid).prop('checked')) {
          requiredfield = "true";
        } else {
          requiredfield = "false";
        }
        
        if (columnName.toLowerCase() == "to_be_deleted") {
          msg = "Please remove all 'to_be_deleted' row's."
          flag = false;
          return false;
        }
        chkidHide = 'input[id=chkHide_' + columnName + 'E' + ']';
        if ($(chkidHide).prop('checked')) {
          columnHide = "true";
        } else {
          columnHide = "false";
        }

        valueFillType = $('#DrpValue_' + columnName + 'E').val()!.toString();
        columnAliasName = $.trim($('#txtc_' + columnName + 'E').val()!.toString());
        txtColSize = $.trim($('#txt_' + columnName + 'E').val()!.toString());       
        range = $.trim($('#txtRange_' + columnName + 'E').val()!.toString());
        txtColCurrentSize = $.trim($('#txtCurrent_' + columnName + 'E').val()!.toString());
        spnColType = $.trim($('#spnCurrent_' + columnName + 'E')[0].innerText.toString());
        Validations = $('#Drp_' + columnName + 'E').val()!.toString();        
        controlType = $('#DrpC_' + columnName + 'E').val()!.toString();
        dropDownChildtable = $.trim($('#txtChild_' + columnName + 'E').val()!.toString());
        dropDownQuery = $.trim($('#txtQuery_' + columnName + 'E').val()!.toString());        
        columnSequence = $.trim($('#txtSeq_' + columnName + 'E').val()!.toString());
        controlClass = $.trim($('#txtClass_' + columnName + 'E').val()!.toString());   
        compareColumnName = $('#DrpCC_' + columnName + 'E').val()!.toString();      
        customValidationMsg = $.trim($('#txtValidationMsg_' + columnName+ 'E').val()!.toString());
        columnInformation = $.trim($('#txtcolumnInformation_' + columnName+ 'E').val()!.toString());
        regExpString = $.trim($('#txtRegExp_' + columnName+ 'E').val()!.toString());    


        if (txtColSize.toLowerCase() == "max") {
          txtColSize = "-1";
        }
        if (txtColCurrentSize.toLowerCase() == "max") {
          txtColCurrentSize = "-1";
        }
        if (spnColType.toLowerCase() == "date" || spnColType.toLowerCase() == "datetime" || spnColType.toLowerCase() == "bit") {
          txtColSize = "0";
        }
        if (flag) {
          if (that.Customvalidation.validateRequired(columnAliasName) == false) {

            msg = 'Please enter column alias for ' + columnName;

            flag = false;
            return flag;
          }
          else {
            flag = true;

          }
          

        }
        if (columnName.toLowerCase() != "id" && columnName.toLowerCase() != "guid") {
          if (flag) {
            if (that.Customvalidation.validateRequired(controlType) == false) {

              msg = 'Please Select Control for ' + columnName;

              flag = false;
              return flag;
            }
            else {
              flag = true;

            }
            
          }
        }
        else {
          controlType = "empty";
        }
        if (spnColType.toLowerCase() != "date" && spnColType.toLowerCase() != "datetime" && spnColType.toLowerCase() != "bit") {
          if (flag) {
            if (that.Customvalidation.validateRequired(txtColSize) == false) {

              msg = 'Please enter column length for ' + columnName;

              flag = false;
              return flag;
            }
            else {
              flag = true;

            }

          }
          if (flag && txtColSize != "-1") {
            if (!that.Customvalidation.validatenumeric(txtColSize, '', '', '', '', '', '', '')[0]) {

              msg = 'Please enter column length in only digts for ' + columnName;

              flag = false;
              return flag;
              
            }
            else {
              flag = true;

            }
          }

          if (flag) {
            if (that.Customvalidation.validateSize(txtColCurrentSize, txtColSize) == false) {

              msg = 'Max length exceeded from table column length for ' + columnName;

              flag = false;
              return flag;
            }
            else {
              flag = true;

            }
          }
        }

        if (flag) {


          
          objList.rowId = ColumnId;
          objList.formType = formType;
          objList.procName = TblName;
          objList.procAliasName = tableAliasName;
          objList.userRoleId = that.editTableData[0]['userRoleId'].toString() ;
          objList.tablePageStyle = tablePageStyle;
          objList.tablecheckBoxFlag = chkBoxId;
          objList.tablemailTriggerSP = childActionGroup;
          objList.columnName = columnName;
          objList.dataType = spnColType;
          objList.columnLength = txtColSize;
          objList.columnAliasName = columnAliasName;
          objList.Validation = Validations;
          objList.columnHide = columnHide;
          objList.valueFillType = valueFillType;
          objList.dropDownChildtable = dropDownChildtable;
          objList.dropDownQuery = dropDownQuery;
          objList.controlType = controlType;
          objList.isRequired = requiredfield;  
          objList.compareColumnName = compareColumnName;        
          objList.range = range;          
          objList.columnSequence = columnSequence;
          objList.controlClass = controlClass;  
          objList.baseTable = baseTable;
          objList.customValidationMsg = customValidationMsg;
          objList.columnInformation = columnInformation;
          objList.regExp = regExpString;
          objList.isExcelDownload = isExcel!.toString();
          objList.isPdfDownload = isPDF!.toString();
          genericColumnList.push(objList);
          return flag;
        }

        else {
          return flag;
        }

      });
      if (genericColumnList.length > 0) {
        objColumn.genericColumnList = genericColumnList;
        _data = JSON.stringify(objColumn);

      }
      let objDatestatusFinalModal = <lstUserObjectParam>{};
      objDatestatusFinalModal.genericColumnList = genericColumnList;
      if (flag) {
        this.openUpdatemodel();
        this.paramvaluedata = _data;

      }
      else {
        this.paramvaluedata = '';
        this.globalAlertService.AlertMsg("Alert!!", msg.toString(), "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
        return;
      }
    }
    else {
      this.paramvaluedata = '';
      this.globalAlertService.AlertMsg("Alert!!", "Please enter Table Alias Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error");
      return;
    }
  }
  openUpdatemodel() {
    this.globalConfirmService.ConfirmFunction("Confirm Update!!", "Are you sure you want to proceed?", "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          this.updatesubmitcall();

        }
        else {
          this.paramvaluedata = '';
        }
      });
  }

  updatesubmitcall() {
    

    let procId = this.editTableData.filter((x:any)=> x['tableid'] >0)[0]['tableid']
    this.userService.UserProcedureAliasUpdateForm(this.paramvaluedata,procId, this.SeltableIDForm).subscribe((data: any) => {
      
      if (data != "") {
        let resultarray  = JSON.parse(data);			
			// resultarray['successFlag'] 
			// resultarray['resultMessage']
			// resultarray['printAction']
			// resultarray['printActionResult']
			// resultarray['otherAction']
			// resultarray['otherActionResult']
			// resultarray['outputVarResult']
this.onCloseEditModal();
        if (resultarray['successFlag'] == "success") {
          this.SearchEdit(2);
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "NP_popup_success";
          
          this.paramvaluedata = '';
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray['resultMessage'].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";

        }

      }
    },
      (err: HttpErrorResponse) => {
        this.AlertTitle = "Error!!";
        this.AlertMessage = err.message.toString();
        this.AlertClass = "NP_emoji-fail-bg";
        this.AlertAnimationType = "sad";
        this.AlertType = "NP_popup_fail";
        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        return true;
      },
      () => {
        this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
      });
  }
 
  txtTableNameFocuscheckFormAlreadyExists(event: any) {
    
    let result = this.Customvalidation.validatealphanumeric($.trim(event.target.value.toString()), '', '', '', '', '', '', '')
    if (!result[0] && $.trim(event.target.value.toString()) != "") {
      this.globalAlertService.AlertMsgFocus("Alert!!", "Please Enter valid Form Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);
    }
    else if ($.trim(event.target.value.toString()) == this.savedtxtTableAliasE ) {
      return;

    }
    this.onCheckFunctionTableNameonfocus(event);
  }
  onCheckFunctionTableNameonfocus(event: any) {
    let flag = true;
    let that = this;
    this.userService.getTableNameExists($.trim(event.target.value.toString()),this.selectedItemsRole[0].id.toString() , this.SeltableIDForm).subscribe((data: any) => {
      
      if (data.table.length > 0) {
        flag = false;
      }

    },
      (err: HttpErrorResponse) => {

        console.log(err.message);
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      },

      () => {
        if (!flag) {
          that.globalAlertService.AlertMsgFocus("Alert!!", "Table Alias Name Already Exits. Please Choose Another Name.", "Ok", "NP_popup_error", "NP_emoji-error-bg", "NP_emoji-error", event);

        }

      });

    return flag;
  }
  onDeletecustumtablechild(index: number) {
    this.editTableData.splice(index, 1);
  }

  toggleWithFilterCol(popover:any, colName: string, colDisplayName: string,dataType: string,filterColParentTableName: string, callfrom:boolean) {
    if(callfrom)
    this.popoverEvent = popover;
    if (popover.isOpen()) {
      this.filterColumnName = "";
      this.filterColumnDisplayName = "";  
      this.filterColumnDataType = "";
      this.filterColumnParentTableName = "";
      popover.close();
    } else {
      this.filterColumnName = colName;
      this.filterColumnDisplayName = colDisplayName;    
      this.filterColumnDataType = dataType;
      this.filterColumnParentTableName = filterColParentTableName;
      this.reactiveFilterForm();
      popover.open({colName, colDisplayName});
    }
  }
  reactiveFilterForm()
  {
    this.filterFormValue = {} as IFilterColumns;
    this.filterFormValue.cloumnName = this.filterColumnName;    
    this.filterFormValue.cloumnDisplayName = this.filterColumnDisplayName;
    this.filterFormValue.filterColumnDataType = this.filterColumnDataType;
    this.filterFormValue.filterColumnParentTableName = this.filterColumnParentTableName;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    {
      this.filterFormValue.columnCondition = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].columnCondition) ? "AND" : getExistingValues[0].columnCondition;
      this.filterFormValue.filterColumnOperator = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterColumnOperator?.toString()) ? 1 : getExistingValues[0].filterColumnOperator;
      this.filterFormValue.filterValue = !this.commonfunction.checkStringIsNullorEmpty(getExistingValues[0].filterValue) ? "" : getExistingValues[0].filterValue;
    }
    else
    {
      this.filterFormValue.columnCondition ="AND";
      this.filterFormValue.filterColumnOperator = 1;
    }
    this.filterForm = new FormGroup({
      cloumnName : new FormControl(this.filterFormValue.cloumnName),
      cloumnDisplayName : new FormControl(this.filterFormValue.cloumnDisplayName),
     
      filterColumnDataType : new FormControl(this.filterFormValue.filterColumnDataType),
      filterColumnParentTableName : new FormControl(this.filterFormValue.filterColumnParentTableName),
      columnCondition : new FormControl(this.filterFormValue.columnCondition , [
        Validators.required,
      ]),
      filterColumnOperator : new FormControl(this.filterFormValue.filterColumnOperator, [
        Validators.required,
      ]),
      filterValue: new FormControl(this.filterFormValue.filterValue, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),        
      ])      
    });
  }
  get columnCondition() {
    return this.filterForm.get('columnCondition')!;
  }

  get filterColumnOperator() {
    return this.filterForm.get('filterColumnOperator')!;
  }

  get filterValue() {
    return this.filterForm.get('filterValue')!;
  }
  genrateFilterQuery(){
    this.filterActualQuery = [];
    this.filterDisplayQuery = [];
    this.filterColumnQuery.forEach((value:IFilterColumns,index:number)=>{
      //let columnName = value.filterColumnDataType =='numeric' && value.filterColumnParentTableName !=""? 'text': value.cloumnName;
      let filterColumnOperator = value.filterColumnOperator ==1 ? "equal"  : value.filterColumnOperator ==2 ? "notEqual" : "Like"
      let filterDisplayColumnOperatorValue = value.filterColumnOperator ==1 ? " = "+ value.filterValue  : value.filterColumnOperator ==2 ? " ≠ "+ value.filterValue : " ≈ "+ value.filterValue;
      this.filterActualQuery.push({columnName: value.cloumnName , columnValue: value.filterValue, conditionOperator: value.columnCondition, filterColConditionOperator:filterColumnOperator});
      this.filterDisplayQuery.push({ columnName: value.cloumnName , displayQuery: value.cloumnDisplayName + filterDisplayColumnOperatorValue, operator: value.columnCondition});

    });
    this.SearchEdit(1);
  }
  delFilter(columnName:string)
  {    
    let filtercolumnIndex: number = this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == columnName);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    this.genrateFilterQuery();
  }
  clearAllFilter()
  {
    this.filterColumnQuery.splice(0, this.filterColumnQuery.length);
    this.genrateFilterQuery();
  }
  clearFilterForm()
  {
    this.filterFormValue.columnCondition="AND";
    this.filterFormValue.filterColumnOperator=1;
    this.filterFormValue.filterValue="";
    let filtercolumnIndex:number =this.filterColumnQuery.findIndex((x:IFilterColumns)=> x.cloumnName == this.filterFormValue.cloumnName);
    this.filterForm.reset(this.filterFormValue);
    this.filterColumnQuery.splice(filtercolumnIndex, 1);
    console.log(this.filterColumnQuery);
    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName,false);
    this.genrateFilterQuery();
  }
  applyFilter()
  {
    this.filterFormValue = this.filterForm.value;
    let getExistingValues: IFilterColumns[] = this.filterColumnQuery.filter((x:IFilterColumns) => x.cloumnName == this.filterColumnName);
    if(getExistingValues.length >0)
    { 
      getExistingValues[0].columnCondition = this.filterFormValue.columnCondition;
      getExistingValues[0].filterColumnOperator = this.filterFormValue.filterColumnOperator;
      getExistingValues[0].filterValue = this.filterFormValue.filterValue;
    }
    else
    this.filterColumnQuery.push(this.filterFormValue);

    this.toggleWithFilterCol(this.popoverEvent, this.filterColumnName, this.filterColumnDisplayName,this.filterColumnDataType,this.filterColumnParentTableName, false);
    this.genrateFilterQuery();

  }
  ConvertStringLowerCase(value:any) {

    return this.commonfunction.ConvertStringLowerCase(value);
}
FirstLetterLower(value:string) {
  return this.commonfunction.FirstLetterLower(value);
}
FirstLetterUpper(value:string) {
  return this.commonfunction.FirstLetterUpper(value);
}
ReplaceNullDataTable(value:string) {
  return this.commonfunction.ReplaceNullDataTable(value);
}
onSortClick(event:any, columnName:string) {
  let target = event.currentTarget,
    classList = target.classList;
   
  if (classList.contains('sort-up')) {
    classList.remove('sort-up');
    this.sortOrder="ASC";
  } else {
    classList.add('sort-up');
    this.sortOrder="DESC";
  }
  this.sortColumn = columnName;
  this.currentPage = 1;
  this.SearchEdit(1);
}
pageSizeChange(){
  this.currentPage = 1;
  this.SearchEdit(1);
}

}
