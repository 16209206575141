<div class="row">
  <div class="uploadfilecontainer col-lg-4 form-control" *ngIf="controlisDisabled !=='disabled'"
    (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event,fileInput)">
    <input hidden type="file" #fileInput [multiple]="false" (click)="fileClickEvent()"
      (change)="uploadFile($event,fileInput)">
  </div>
  <div class="CaptureFilecontainer col-lg-4 form-control"
    *ngIf="controlisDisabled !=='disabled' && mediaDeviceAvailable >0" (click)="CaptureInput.click()">
    <input hidden type="button" #CaptureInput (click)="captureFileClickEvent()">
  </div>
  <div class="previewUploadImg col-lg-4 form-control" >
    <!-- *ngIf="imagePreview" -->
    <img [src]="imagePreview ? imagePreview : 'assets/images/preview.png'" alt="Preview" class="img-fluid">
  </div>
  <div class="files-list col-lg-12" *ngFor="let file of files;let i=index">
    <!-- <p>{{ file.uploadedFileName }}</p> -->
    <a href="javascript: void(0);" class="" data-toggle="tooltip" data-placement="top" title="{{ file.uploadedFileName }}" (click)="downLoadAttachment(file.uploadedFileNewName,1)">
      {{ file.uploadedFileName }}
    </a>
    <a href="javascript:void(0)" class='' (click)="downLoadAttachment(file.uploadedFileNewName,2)">
      <i class='fa fa-file-image-o'></i></a>
    <!-- <button type="button" class="delete-file" (click)="downLoadAttachment(file.uploadedFileNewName)">
        <img src="assets/images/download.png">
      </button> -->
    <button type="button" class="delete-file" *ngIf="controlisDisabled !=='disabled'"
      (click)="deleteAttachment(i,file.uploadedFileNewName)">
      <img src="assets/images/Recycle_Bin_Full.png">
    </button>
  </div>
  <div class="files-list col-lg-12" *ngIf="files.length == 0 && controlisDisabled =='disabled'">
    {{fileColumnName}} not uploaded yet!
  </div>
</div>