import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-blogdetails-dashboard',
  templateUrl: './blogdetails-dashboard.component.html',
  styleUrls: ['./blogdetails-dashboard.component.css']
})
export class BlogdetailsDashboardComponent implements OnInit {
  activeTabId:number=1;
  Blogsdata:any;
  AddNewFrom !: FormGroup;

  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.Blogsdata = this.commonfunction.loadJson("~/../assets/blogs.json");
    
    this.AddNewFrom = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''), 
    })
  }
  

  deleteKey(index:number){
    // this.Blogsdata.splice(index,1);
  }

  onClickResetFrom(){}  

  OnClickSubmit(){}

}
