import { Component, OnInit } from '@angular/core';
import { IhomeCr, IhomeExperiance, IhomeLessons, IhomeLessonsSectionList } from '../../allUsedModule';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogBoxService } from '../../../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { AlertDialogBoxService } from '../../../../shared/alert-dialog-box/alert-dialog-box.service';
import { GlobalAlertService } from '../../../../shared/global-alert.service';

@Component({
  selector: 'app-home-pge-model',
  templateUrl: './home-pge-model.component.html',
  styleUrls: ['./home-pge-model.component.css']
})
export class HomePgeModelComponent implements OnInit {
  Crflag: boolean = false;
  HomeCrLink: IhomeCr = {} as IhomeCr;
  // homeCrEditForm !: FormGroup;
  homeCrEditForm !:FormGroup
  isCheckBtnFlag: boolean = true;
  modelPopUpTitle:string | any;
  modelPopUpSetTitle:string | any;
  modelPopUpdetails:string | any;


  HomeExpLink: IhomeExperiance = {} as IhomeExperiance;
  Expflag: boolean = false;
  HomeExpEditForm !: FormGroup;


  HomeLessTabLink: IhomeLessons = {} as IhomeLessons;
  LessonShowflag: boolean = false;
  HomeLessonForm !: FormGroup;
  HomeLessonListForm !: FormGroup;
  EditIndex: number | any;
  HomeListViewTabLink:Array<IhomeLessonsSectionList>=[];
  ListViewShowflag: boolean = false;
  ListEditShowflag: boolean = false;

  constructor(private activeModal: NgbActiveModal, private confirm: ConfirmDialogBoxService, private alertBox: AlertDialogBoxService,private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
    this.homeCrEditForm = new FormGroup({
      img1: new FormControl(this.HomeCrLink.ImgUrl, [Validators.required]),
      headline: new FormControl(this.HomeCrLink.Heading, [Validators.required]),
      headline2: new FormControl(this.HomeCrLink.position, [Validators.required]),
      description: new FormControl(this.HomeCrLink.description, [Validators.required]),
    });



    //Home Experience Form Start
    this.HomeExpEditForm = new FormGroup({
      imgurl: new FormControl(this.HomeExpLink.ImgUrl, [Validators.required]),
      headline: new FormControl(this.HomeExpLink.Heading, [Validators.required]),
      description: new FormControl(this.HomeExpLink.Content, [Validators.required])
    });

    //Home Lesson Form Start
    this.HomeLessonForm = new FormGroup({
      title: new FormControl(this.HomeLessTabLink.tabName, [Validators.required]),
      heading: new FormControl(this.HomeLessTabLink.headlinename, [Validators.required]),
    })

    //Home Lesson List Form Start
    this.HomeLessonListForm = new FormGroup({
      lessonlist: new FormControl("", [Validators.required]),
    })

  }

  onCloseConfirmModal() {
    this.activeModal.close()
  }
  //Home Cr Update Function
  onUpdateHomeCrmModal() {
    this.confirm.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?")
    .then((confirm)=>{
      if(confirm){
        this.HomeCrLink.ImgUrl = this.homeCrEditForm.get("img1")?.value
        this.HomeCrLink.Heading = this.homeCrEditForm.get("headline")?.value
        // this.HomeCrLink.headline2 = this.homeCrEditForm.get("headline2")?.value
        this.HomeCrLink.description = this.homeCrEditForm.get("description")?.value
        this.HomeCrLink.position = this.homeCrEditForm.get("headline2")?.value
        this.activeModal.close(true)
        // console.log(this.HomeCrLink);
      }
    })
  }

  //Home Experiance Update Function
  OnUpdateHomeExpmModal() {
    this.confirm.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?")
      .then((confirmed) => {
        if (confirmed) {

          // this.alertBox.AlertFunction("Success!!","Data Saved.",)
          this.HomeExpLink.ImgUrl = this.HomeExpEditForm.get("imgurl")?.value
          this.HomeExpLink.Heading = this.HomeExpEditForm.get("headline")?.value
          this.HomeExpLink.Content = this.HomeExpEditForm.get("description")?.value
          this.activeModal.close(true)
        }
      })
  }


  //Home Lesson List  Start
  onClickResetFrom() {
    this.HomeLessonListForm.reset();
    this.isCheckBtnFlag = true;
  }

  onClickEditForm(index: number, value: IhomeLessonsSectionList) {
    this.HomeLessonListForm.patchValue({
      lessonlist: value.list
    })
    this.EditIndex = index;
    this.isCheckBtnFlag = false;
  }

  deleteKey(index: number) {
    this.confirm.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?")
    .then((confirm)=>{
      if(confirm){
        this.HomeLessTabLink?.tabcontaint.splice(index, 1);
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }

  OnclickAddNewList() {
    let _item: IhomeLessonsSectionList = {} as IhomeLessonsSectionList;
    _item.list = this.HomeLessonListForm.get("lessonlist")?.value;

    this.HomeListViewTabLink.push(_item);
    this.HomeLessonListForm.reset();
  }
  
  OnclickEditList() {
    let _item: IhomeLessonsSectionList = {} as IhomeLessonsSectionList;
    _item.list = this.HomeLessonListForm.get("lessonlist")?.value;
    console.log(_item);

    if (this.HomeLessTabLink && this.EditIndex !== undefined && this.EditIndex !== null) {
      this.HomeLessTabLink.tabcontaint[this.EditIndex].list = _item.list;
    }

    this.HomeLessonListForm.reset()
  }

  OnUpdateHomeLessonListTabModal(){
    this.HomeListViewTabLink =  this.HomeListViewTabLink
  }
  //Home Lesson List  End

  
  OnUpdateHomeLessonTabModal(){
    this.confirm.ConfirmFunction("Confirm update !!", "Are you sure you want to proceed?")
    .then((confirm)=>{
      if(confirm){
        this.HomeLessTabLink.tabName =  this.HomeLessonForm.get("title")?.value;
        this.HomeLessTabLink.headlinename =  this.HomeLessonForm.get("heading")?.value;
        this.activeModal.close(true);
      }
    }) 
  }

}
