<!-- <ul class="nav nav-tabs master-form-tab mb-4" role="tablist">
  <li role="presentation" class="active"><a class="nav-link active" id="LinkAdd" href="#divAddNewForm"
      (click)="onMainTab();" data-toggle="tab">Add New Detail</a></li>
  <li role="presentation"> <a class="nav-link" id="LinkView" href="#divViewDetail" (click)="onViewTab();"
      data-toggle="tab">View Detail</a></li>
</ul> -->

<!-- Tab panes -->
<div class="tab-content">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onMainTab();" class="nav-link">Add New Detail</a>
      <ng-template ngbNavContent>
        <div class="" [ngStyle]="{'display':displayContainer}">
          <div class="">
            <div class="form-group">
              <!-- <div class="row col-lg-12">        
          <h5>Select Procedure Object</h5>
        </div> -->
              <div class="row m-0">
                <div class="col-lg-6">
                  <label for="DrpRole" class="bmd-label-floating">Select Role</label>
                  <angular2-multiselect id="DrpRole" [data]="dropdownListRole" [(ngModel)]="selectedItemsRole"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                    [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>

                <div class=" col-lg-6">
                  <label>Select Procedure Object</label>
                  <angular2-multiselect id="DrpProc" [data]="dropdownListProc" [(ngModel)]="selectedItemsProc"
                    (onSelect)="onItemSelectProc($event)" (onDeSelect)="OnItemDeSelectProc($event)"
                    [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>

                <!-- <div class="col-lg-4 text-right p-2 mt-4">
            <input type="button" id="btnReset" (click)="openResetModal()" class="btn btn-secondry"
            value="Reset">
       
            <input type="button" id="btnSearch" (click)="onAddclick()" class="btn btn-primary ml-3"
              value="Add New">
           
          
           
          </div> -->
              </div>
            </div>

          </div>

        </div>
        <div role="tabpanel" class=" tab-pane  active" id="divAddNewForm">

          <div class="inner-content Divbackground mt-4 pt-4 border-top" [ngStyle]="{'display':displayMain}">

            <div class="row m-0">
              <div class="col-lg-2">
                <label>Table Alias Name</label>
                <input [(ngModel)]="txtTableAlias" type="text" class="form-control" id="txtTableAlias"
                  (blur)="txtTableNameFocuscheckFormAlreadyExists($event)" name="txtTableAlias" required>
              </div>
              <div class="col-lg-2">
                <label>Base Table Name</label>
                <input [(ngModel)]="txtBaseTable" type="text" class="form-control" id="txtBaseTable" name="txtBaseTable"
                  required>
              </div>
              <div class="col-lg-2">
                <label>Form Size</label>
                <angular2-multiselect id="DrpStyle" [data]="dropdownListpageloadPageStyle"
                  [(ngModel)]="selectedItemspageloadPageStyle" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-lg-2">
                <label>Form Type</label>
                <angular2-multiselect id="DrpFormType" [data]="dropdownListFormType"
                  [(ngModel)]="selectedDropdownListFormType" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-lg-2">
                <label>Action</label>
                <angular2-multiselect id="DrpAction" [data]="dropdownListReportCheckBoxAction"
                  [(ngModel)]="selectedItemsReportCheckBoxAction" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-lg-2">
                <label>Action Group</label>
                <angular2-multiselect id="DrpActionGroup" [data]="dropdownListpageloadActiondrp"
                  [(ngModel)]="selectedItemspageloadActiondrp" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-lg-2">
                <label class="col-form-label">Is Excel</label>
                <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsExcel"
                  [(ngModel)]="selectedItemspageloadIsExcel" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-lg-2">
                <label class="col-form-label">Is PDF</label>
                <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsPdf"
                  [(ngModel)]="selectedItemspageloadIsPdf" [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
            <div id="DivData" class="table-responsive-class  mt-5 pt-4 border-top">
              <table [id]="mainTableId" class='table  table-bordered'>
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Column Name</th>
                    <th>Data Type</th>
                    <th>Column Alias</th>
                    <th>Column Sequence</th>
                    <th>Column Class</th>
                    <th>Column Length</th>
                    <th>Required</th>
                    <th>Hide Column</th>
                    <th>Value Fill Type</th>
                    <th>ChildTable Name</th>
                    <th>DropDownQuery</th>
                    <th>Select Control</th>
                    <th>Select Validation</th>
                    <th>Validation Message</th>
                    <th>RegExp String</th>
                    <th>Comparison Column</th>
                    <th>Range(min-max)</th>
                    <th>Column Information</th>

                  </tr>

                </thead>
                <tbody>
                  <tr *ngFor="let rowData of tableData; let i=index;" [attr.tableId]="selectedTableId"
                    [attr.columnId]="rowData['columN_NAME']" [attr.colsize]="rowData['columnLength']" class='Uiparams'>
                    <td>{{(i +1)}}</td>
                    <td>{{rowData['columN_NAME']}}</td>
                    <td><span id="spn_{{rowData['columN_NAME']}}">{{rowData['datA_TYPE']}}</span></td>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseif1">
                      <td><input type='text' style='width:140px;' readonly disabled id="txtc_{{rowData['columN_NAME']}}"
                          class='form-control' name="txtc_{{rowData['columN_NAME']}}"
                          value="{{rowData['columN_NAME']}}"></td>
                    </ng-container>
                    <ng-template #elseif1>
                      <ng-container>
                        <td><input type='text' style='width:140px;' id="txtc_{{rowData['columN_NAME']}}"
                            class='form-control' name="txtc_{{rowData['columN_NAME']}}"
                            value="{{rowData['columN_NAME'] }}"></td>
                      </ng-container>
                    </ng-template>
                    <td>
                      <input type='text' style='width:140px;' id="txtSeq_{{rowData['columN_NAME']}}"
                        class='form-control' name="txtSeq_{{rowData['columN_NAME']}}" value="{{(i +1)}}">
                    </td>
                    <td>
                      <input type='text' style='width:140px;' id="txtClass_{{rowData['columN_NAME']}}"
                        class='form-control' name="txtClass_{{rowData['columN_NAME']}}" value="">
                    </td>

                    <ng-container *ngIf="rowData['columnLength'] == '-1'; else elseifcolumnlength1">
                      <td><input type='text' style='width:140px;' id="txt_{{rowData['columN_NAME']}}"
                          class='form-control' name="txt_{{rowData['columN_NAME']}}" value="Max"></td>
                    </ng-container>
                    <ng-template #elseifcolumnlength1>
                      <ng-container
                        *ngIf="rowData['datA_TYPE'] == 'date' || rowData['datA_TYPE'] == 'datetime' || rowData['datA_TYPE'] == 'bit'; else elseifcolumnlength2">
                        <td><input type='text' style='width:140px;' id="txt_{{rowData['columN_NAME']}}"
                            class='form-control' name="txt_{{rowData['columN_NAME']}}" value="" disabled></td>
                      </ng-container>
                    </ng-template>
                    <ng-template #elseifcolumnlength2>
                      <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifcolumnlength3">
                        <td><input type='text' style='width:140px;' id="txt_{{rowData['columN_NAME']}}"
                            class='form-control' name="txt_{{rowData['columN_NAME']}}"
                            value="{{rowData['columnLength']}}" disabled></td>
                      </ng-container>
                    </ng-template>
                    <ng-template #elseifcolumnlength3>
                      <ng-container>
                        <td><input type='text' style='width:140px;' id="txt_{{rowData['columN_NAME']}}"
                            class='form-control' name="txt_{{rowData['columN_NAME']}}"
                            value="{{rowData['columnLength']}}"></td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['iS_NULLABLE'].toLowerCase() == 'yes'; else elseifrequired">
                      <td><input type='checkbox' id="chk_{{rowData['columN_NAME']}}"><span class='checkmark'></span>
                      </td>
                    </ng-container>
                    <ng-template #elseifrequired>
                      <ng-container>
                        <td><input type='checkbox' id="chk_{{rowData['columN_NAME']}}" checked disabled><span
                            class='checkmark'></span></td>
                      </ng-container>
                    </ng-template>

                    <ng-container *ngIf="rowData['iS_NULLABLE'].toLowerCase() == 'yes'; else elseifHide">
                      <td><input type='checkbox' id="chkHide_{{rowData['columN_NAME']}}"><span class='checkmark'></span>
                      </td>
                    </ng-container>
                    <ng-template #elseifHide>
                      <ng-container>
                        <td><input type='checkbox' id="chkHide_{{rowData['columN_NAME']}}" disabled><span
                            class='checkmark'></span></td>
                      </ng-container>
                    </ng-template>
                    <td> <select style='width:200px;' id="DrpValue_{{rowData['columN_NAME']}}" class="form-control">

                        <option *ngFor="let opt of dropdownListpageloadValueFillTypedrp" value="{{opt['id']}}">
                          {{opt['itemName']}}
                        </option>
                      </select> </td>
                    <ng-container *ngIf=" rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifchildTable">
                      <td><input type='text' style='width:140px;' readonly disabled
                          id="txtChild_{{rowData['columN_NAME']}}" class='form-control'
                          name="txtChild_{{rowData['columN_NAME']}}" value=""></td>
                    </ng-container>
                    <ng-template #elseifchildTable>
                      <ng-container>
                        <td><input type='text' style='width:140px;' id="txtChild_{{rowData['columN_NAME']}}"
                            class='form-control' name="txtChild_{{rowData['columN_NAME']}}" value=""></td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropQuery">
                      <td><textarea rows='2' cols='50' type='text' style='width:140px;' readonly disabled
                          id="txtQuery_{{rowData['columN_NAME']}}" class='form-control'
                          name="txtQuery_{{rowData['columN_NAME']}}" value=""></textarea></td>
                    </ng-container>
                    <ng-template #elseifDropQuery>
                      <ng-container>
                        <td><textarea rows='2' cols='50' type='text' style='width:140px;'
                            id="txtQuery_{{rowData['columN_NAME']}}" class='form-control'
                            name="txtQuery_{{rowData['columN_NAME']}}" value=""></textarea></td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropControl">
                      <td><select style='width:200px;' disabled id="DrpC_{{rowData['columN_NAME']}}"
                          class="form-control">
                          <option value="">Select</option>
                          <option *ngFor="let opt of dropdownListpageloadControldrp" value="{{opt['id']}}">
                            {{opt['itemName']}}
                          </option>
                        </select>
                      </td>
                    </ng-container>
                    <ng-template #elseifDropControl>
                      <ng-container>
                        <td><select style='width:200px;' id="DrpC_{{rowData['columN_NAME']}}" class="form-control">
                            <option value="">Select</option>
                            <option *ngFor="let opt of dropdownListpageloadControldrp" value="{{opt['id']}}">
                              {{opt['itemName']}}
                            </option>
                          </select>
                        </td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropValidation">
                      <td><select style='width:200px;' disabled id="Drp_{{rowData['columN_NAME']}}"
                          class="form-control">
                          <option value="">Select</option>
                          <option *ngFor="let opt of dropdownListpageloadValidationdrp" value="{{opt['id']}}">
                            {{opt['itemName']}}
                          </option>
                        </select>
                      </td>
                    </ng-container>
                    <ng-template #elseifDropValidation>
                      <ng-container>
                        <td><select style='width:200px;' id="Drp_{{rowData['columN_NAME']}}" class="form-control">
                            <option value="">Select</option>
                            <option *ngFor="let opt of dropdownListpageloadValidationdrp" value="{{opt['id']}}">
                              {{opt['itemName']}}
                            </option>
                          </select>
                        </td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifValidationMsg">
                      <td>
                        <input type='text' id="txtValidationMsg_{{rowData['columN_NAME']}}" disabled
                          class='form-control' name="txtValidationMsg_{{rowData['columN_NAME']}}" value=""
                          title='Custom Validation Message' placeholder='Validation Message'>
                      </td>
                    </ng-container>
                    <ng-template #elseifValidationMsg>
                      <ng-container>
                        <td><input type='text' id="txtValidationMsg_{{rowData['columN_NAME']}}" class='form-control'
                            name="txtValidationMsg_{{rowData['columN_NAME']}}" value=""
                            title='Custom Validation Message' placeholder='Validation Message'>
                        </td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifRegExp">
                      <td><input type='text' id="txtRegExp_{{rowData['columN_NAME']}}" disabled class='form-control'
                          name="txtRegExp_{{rowData['columN_NAME']}}" value="" title='Custom RegExp String'
                          placeholder='RegExp String'>
                      </td>
                    </ng-container>
                    <ng-template #elseifRegExp>
                      <ng-container>
                        <td><input type='text' id="txtRegExp_{{rowData['columN_NAME']}}" class='form-control'
                            name="txtRegExp_{{rowData['columN_NAME']}}" value="" title='Custom RegExp String'
                            placeholder='RegExp String'>
                        </td>
                      </ng-container>
                    </ng-template>
                    <ng-container
                      *ngIf="rowData['datA_TYPE'].toLowerCase() == 'int' || rowData['datA_TYPE'].toLowerCase() == 'decimal'|| rowData['datA_TYPE'].toLowerCase() == 'numeric'; else elseifDropCompair">
                      <td><select style='width:200px;' id="DrpCC_{{rowData['columN_NAME']}}" class="form-control">
                          <option value="">Select</option>
                          <option *ngFor="let opt of dropdownListpageloadControldrpNumber" value="{{opt['id']}}">
                            {{opt['itemName']}}
                          </option>
                        </select>
                      </td>
                    </ng-container>
                    <ng-template #elseifDropCompair>
                      <ng-container
                        *ngIf="rowData['datA_TYPE'].toLowerCase() == 'date' || rowData['datA_TYPE'].toLowerCase() == 'datetime'; else elseifDropCompair2">
                        <td><select style='width:200px;' id="DrpCC_{{rowData['columN_NAME']}}" class="form-control">
                            <option value="">Select</option>
                            <option data-tokens='Current Date' value='Today'> Current Date</option>
                            <option *ngFor="let opt of dropdownListpageloadControlDatedrp" value="{{opt['id']}}">
                              {{opt['itemName']}}</option>
                          </select>
                        </td>
                      </ng-container>

                      <ng-template #elseifDropCompair2>
                        <ng-container>
                          <td><select style='width:200px;' disabled id="DrpCC_{{rowData['columN_NAME']}}"
                              class="form-control">
                              <option value="">Select</option>
                              <option *ngFor="let opt of dropdownListpageloadControldrpNumber" value="{{opt['id']}}">
                                {{opt['itemName']}}</option>
                            </select>
                          </td>
                        </ng-container>
                      </ng-template>
                    </ng-template>
                    <ng-container
                      *ngIf="rowData['datA_TYPE'].toLowerCase() == 'int' || rowData['datA_TYPE'].toLowerCase() == 'decimal' || rowData['datA_TYPE'].toLowerCase() == 'numeric'; else elseifRange1">
                      <td><input type='text' style='width:140px;' id="txtRange_{{rowData['columN_NAME']}}"
                          class='form-control' name="txtRange_{{rowData['columN_NAME']}}" value=""
                          title='Range should be in given format  ex:- 20-40' placeholder='ex:- 20-40'></td>
                    </ng-container>
                    <ng-template #elseifRange1>
                      <ng-container
                        *ngIf="rowData['datA_TYPE'].toLowerCase() == 'date' || rowData['datA_TYPE'].toLowerCase() == 'datetime'; else elseifRange2">
                        <td><input type='text' style='width:140px;' id="txtRange_{{rowData['columN_NAME']}}"
                            class='form-control' name="txtRange_{{rowData['columN_NAME']}}" value=""
                            title='Date Range should be in numeric months ex:- 1year = 12' placeholder='ex:- 24'></td>
                      </ng-container>
                    </ng-template>
                    <ng-template #elseifRange2>
                      <ng-container>
                        <td><input type='text' style='width:140px;' disabled id="txtRange_{{rowData['columN_NAME']}}"
                            class='form-control' name="txtRange_{{rowData['columN_NAME']}}" value=""></td>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifcolumnInformation">
                      <td>
                        <input type='text' id="txtcolumnInformation_{{rowData['columN_NAME']}}" disabled
                          class='form-control' name="txtcolumnInformation_{{rowData['columN_NAME']}}" value=""
                          title='Column Information Text' placeholder='Information Text'>
                      </td>
                    </ng-container>
                    <ng-template #elseifcolumnInformation>
                      <ng-container>
                        <td><input type='text' id="txtcolumnInformation_{{rowData['columN_NAME']}}" class='form-control'
                            name="txtcolumnInformation_{{rowData['columN_NAME']}}" value=""
                            title='Column Information Text' placeholder='Information Text'>
                        </td>
                      </ng-container>
                    </ng-template>

                  </tr>
                </tbody>
              </table>
            </div>

            <div class="Submitbtn-content px-3 mt-5">

              <button type="button" id="btnSubmit" (click)="OnSubmitModal()" class="btn btn-primary">Submit</button>

            </div>

          </div>

          <!-- </div> -->
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="nav-link" (click)="onViewTab();">View Detail</a>
      <ng-template ngbNavContent>
        <div role="tabpanel" class="tab-pane" id="divView">
          <div class="row justify-content-md-center tableDiv">
            <div class="col-12" style="margin-bottom: 15px;"><button type="button" class="btn btn-danger"
                (click)="clearAllFilter()">Clear</button>
              <ng-container *ngFor="let filterQuery of filterDisplayQuery; let i=index">
                <b *ngIf="i != 0">{{filterQuery.operator}}</b>
                <span class="badge-box"> {{filterQuery.displayQuery}} <a (click)="delFilter(filterQuery.columnName)"><i
                      class="fa fa-trash" aria-hidden="true"></i></a></span>
              </ng-container>
            </div>

            <div class="col-12">
              <div id="DivtableDataEdit" class="table-responsive main-table-wrappr">
                <table [id]="Edittableid" class="table table-bordered row-border hover w-100 ">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <!-- <th>Procedure Name</th>
              <th>Procedure Alias Name</th>
              <th>Procedure User Role</th>
              <th>Is Check Box</th>
              <th>Base Table</th>
              <th>Action Group</th>
              <th>Page Style</th> -->
                      <!-- <th style='display:none;'>MailId</th> -->
                      <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">
                        <th scope="headerDetailData">
                          {{FirstLetterUpper(headerDetailData['displayName'])}}

                          <i (click)="onSortClick($event,headerDetailData['key'])" class="fa fa-sort-amount-desc"></i>
                          <div class="filter-box">

                            <a placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="manual"
                              #p2="ngbPopover" [autoClose]="'outside'"
                              (click)="toggleWithFilterCol(p2, ConvertStringLowerCase(headerDetailData['key']), FirstLetterUpper(headerDetailData['displayName']),'','',true)">
                              <i class="fa fa-filter" aria-hidden="true"></i></a>

                          </div>
                        </th>
                      </ng-container>
                      <th>Procedure Parameters</th>
                      <th>Action</th>
                    </tr>

                  </thead>
                  <tbody>


                    <tr *ngFor="let rowDetailData of detailTableData; let i=index;">
                      <td>{{(i + 1) }}</td>
                      <!-- <td>{{rowDetailData["procName"]}}</td>
                <td>{{rowDetailData["procAliasName"] }}</td>
                <td>{{rowDetailData["userRole"] }}</td>
                <td>{{rowDetailData["checkBoxFlag"]==0?'No Checkbox in First Column':rowDetailData["checkBoxFlag"]==1 ?'Yes Checkbox in First Column': 'Yes Checkbox Select All'}}</td>
                <td>{{rowDetailData["baseTable"]==''?'-':rowDetailData["baseTable"]}}</td>
                <td>{{rowDetailData["actionGroupName"]==''?'-':rowDetailData["actionGroupName"]}}</td>
             
                <td>{{rowDetailData["pageStyle"]}}</td> -->
                      <ng-container *ngFor="let headerDetailData of tableCols; let i=index;">

                        <td>
                          <div>
                            {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["key"])])}}
                          </div>

                        </td>

                      </ng-container>
                      <!-- <td style='display:none;'>{{rowDetailData["mailId"]}}</td> -->
                      <td><a href="javascript:void(0)"
                          (click)="onGetProcDetailsByProcId(rowDetailData['procName'],rowDetailData['userRoleId'],$event)"
                          class='active opendetail'><i class='fa fa-info'></i></a>
                      </td>
                      <td><a href="javascript:void(0)"
                          (click)="onGetEditProcedureDetailsByProcId(rowDetailData['procName'],rowDetailData['procAliasName'],rowDetailData['pageStyle'],rowDetailData['checkBoxFlag'],rowDetailData['actionGroupNameId'],rowDetailData['userRoleId'],rowDetailData['baseTable'],rowDetailData['formType'],rowDetailData['isExcelDownload'],rowDetailData['isPdfDownload'],$event)"
                          class='edit  opendetail'><i class='fa fa-pencil'></i></a>
                        <a href="javascript:void(0)" *ngIf="rowDetailData['isActive']"
                          (click)="onTableAliasAction(rowDetailData['id'],'InActive',rowDetailData['formType'],i)"
                          class='active  opendetail'><i class='fa fa-check'></i></a>
                        <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive']"
                          (click)="onTableAliasAction(rowDetailData['id'],'Active',rowDetailData['formType'],i)"
                          class='inactive   opendetail'><i class='fa fa-times'></i></a>
                        <a href="javascript:void(0)"
                          (click)="onTableAliasAction(rowDetailData['id'],'Deleted',rowDetailData['formType'],i)"
                          class='delete  opendetail'><i class='fa fa-trash'></i></a>

                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ng-template #popContent>

              <form [formGroup]="filterForm" #form="ngForm">
                <div class="row">
                  <div class="col mb-2">
                    <div class="btn-group">
                      <input type="radio" class="btn-check form-control form-control-sm"
                        formControlName="columnCondition" id="radio1" autocomplete="off" value="AND" checked>
                      <label class="btn btn-outline-primary" for="radio1">AND</label>

                      <input type="radio" class="btn-check form-control form-control-sm"
                        formControlName="columnCondition" id="radio2" autocomplete="off" value="OR">
                      <label class=" btn btn-outline-primary" for="radio2">OR</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <select class="form-control form-control-sm form-select form-select-sm"
                      formControlName="filterColumnOperator" aria-label=".form-select-sm example">
                      <option selected value=1>Equal</option>
                      <option value=2>Not Equal</option>
                      <option value=3>Contains</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <input type="text" [placeholder]="filterColumnDisplayName" required minlength="1" maxlength="200"
                      class="form-control form-control-sm"
                      [class.is-invalid]="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                      formControlName="filterValue">

                    <div *ngIf="filterValue.invalid && (filterValue.dirty || filterValue.touched)"
                      class="invalid-feedback">
                      <div *ngIf="filterValue.errors?.['required']">
                        This field is required.
                      </div>
                      <div *ngIf="filterValue.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="filterValue.errors?.['maxlength']">
                        This field must have at most 200 characters.
                      </div>
                      <!-- <div *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
              Invalid email format.
            </div> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mt-2 popover-btn d-flex">
                    <button type="button" class="btn btn-sm clear-btn" (click)="clearFilterForm()">Clear</button>
                    <button type="button" [disabled]="!filterValue.valid" (click)="applyFilter()"
                      class="btn btn-sm apply-btn btn-primary">Apply</button>
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #popTitle>Filter {{filterColumnDisplayName}}</ng-template>
          </div>
          <div class="table-footer">
            <div class="footer-selct">
              <select class="custom-select form-control" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="pageSizeChange();">
                <option [ngValue]="05">05 items</option>
                <option [ngValue]="10">10 items</option>
                <option [ngValue]="25">25 items</option>
                <option [ngValue]="50">50 items</option>
                <option [ngValue]="100">100 items</option>
              </select>
              <pre><span class="float-md-left">Page: {{ currentPage }} / {{ dataPagination.pageCount }}</span></pre>
            </div>
            <div class="footer-pagintion">
              <ngb-pagination class="d-flex justify-content-center mt-2" #dataPagination [collectionSize]="totalRecords"
                [(page)]="currentPage" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                (pageChange)="SearchEdit(1)"></ngb-pagination>
            </div>

          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <!-- </div> -->
  <!-- start Modals -->
  <div class="backdrop " [ngStyle]="{'display':display}"></div>
  <!-- start UpdateMaster Modal -->
  <div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content edit-modal-form">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="MasterUpdateHeaderId">Table Column Record</h2>
          <input type="hidden" id="updateId" name="updateId">
          <button type="button" class="close" aria-label="Close" (click)="onCloseEditModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body p-0">
          <div class="p-3">
            <div class="table-responsive">
              <div class="row mx-0 mb-4  pb-4 border-bottom">

                <div class="col-lg-2">
                  <label class="col-form-label ">Table Alias Name</label>
                  <input [(ngModel)]="txtTableAliasE" type="text" class="form-control" id="txtTableAliasE"
                    (blur)="txtTableNameFocuscheckFormAlreadyExists($event)" name="txtTableAliasE" required>

                </div>
                <div class="col-lg-2">
                  <label class="col-form-label ">Base Table</label>
                  <input [(ngModel)]="txtBaseTableE" type="text" class="form-control" id="txtBaseTableE"
                    name="txtBaseTableE" required>
                </div>

                <div class="col-lg-2">
                  <label class="col-form-label ">Page Style</label>
                  <angular2-multiselect id="DrpStyleE" [data]="dropdownListpageloadPageStyle"
                    [(ngModel)]="selectedItemspageloadPageStyleE" [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>
                <div class="col-lg-2">
                  <label>Form Type</label>
                  <angular2-multiselect id="DrpFormTypeE" [data]="dropdownListFormType"
                    [(ngModel)]="selectedDropdownListFormTypeE" [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>

                <div class="col-lg-2">
                  <label class="col-form-label "> Action</label>
                  <angular2-multiselect id="DrpActionE" [data]="dropdownListReportCheckBoxAction"
                    [(ngModel)]="selectedItemsReportCheckBoxActionE" [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>

                <div class="col-lg-2">
                  <label class="col-form-label "> Action Group</label>
                  <angular2-multiselect id="DrpActionGroupE" [data]="dropdownListpageloadActiondrp"
                    [(ngModel)]="selectedItemspageloadActiondrpE" [settings]="dropdownSettings">
                  </angular2-multiselect>

                </div>
                <div class="col-lg-2">
                  <label class="col-form-label">Is Excel</label>
                  <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsExcel"
                    [(ngModel)]="selectedItemspageloadIsExcelE" [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>
                <div class="col-lg-2">
                  <label class="col-form-label">Is PDF</label>
                  <angular2-multiselect id="DrpEdit" [data]="dropdownListpageloadIsPdf"
                    [(ngModel)]="selectedItemspageloadIsPdfE" [settings]="dropdownSettings">
                  </angular2-multiselect>
                </div>
              </div>
              <div id="DivData" class="inner-content">
                <table [id]="mainTableIdE" class='table  table-bordered'>
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>Column Name</th>
                      <th>Saved Data Type</th>
                      <th>Current Data Type</th>
                      <th>Column Alias</th>
                      <th>Column Sequence</th>
                      <th>Column Class</th>
                      <th>Saved Column Length</th>
                      <th>Current Column Length</th>
                      <th>Saved Required</th>
                      <th>Current Required</th>
                      <th>Hide Column</th>
                      <th>Value Fill Type</th>
                      <th>ChildTable Name</th>
                      <th>DropDownQuery</th>
                      <th>Select Control</th>
                      <th>Select Validation</th>
                      <th>Validation Message</th>
                      <th>RegExp String</th>
                      <th>Comparison Column</th>
                      <th>Range(min-max)</th>
                      <th>Column Information</th>
                      <th>Action</th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr *ngFor="let rowData of editTableData ; let i=index;" [attr.tableName]="selectedTableId"
                      [attr.tableId]="rowData['tableid']"
                      [ngClass]="{'bg-danger text-white': rowData.columnFlag==1, 'bg-info text-white': rowData.columnFlag==0 }"
                      [attr.columnName]="rowData['columN_NAME']" [attr.columnId]="rowData['id']"
                      [attr.colsize]="rowData['columnLength']" class='UiparamsE'>
                      <td>{{(i +1)}}</td>
                      <td>{{rowData['columN_NAME']}}</td>
                      <td><span id="spn_{{rowData['columN_NAME']}}E">{{rowData['datA_TYPE']}}</span></td>
                      <td><span id="spnCurrent_{{rowData['columN_NAME']}}E">{{rowData['table_datA_TYPE']}}</span></td>
                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'id' || rowData['columN_NAME'].toLowerCase() == 'guid'; else elseif1E">
                        <td><input type='text' readonly disabled id="txtc_{{rowData['columN_NAME']}}E"
                            class='form-control' name="txtc_{{rowData['columN_NAME']}}E"
                            value="{{rowData['columnAlias']}}"></td>
                      </ng-container>
                      <ng-template #elseif1E>
                        <ng-container>
                          <td><input type='text' id="txtc_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtc_{{rowData['columN_NAME']}}E" value="{{rowData['columnAlias'] }}"></td>
                        </ng-container>
                      </ng-template>
                      <td>
                        <input type='text' id="txtSeq_{{rowData['columN_NAME']}}E" class='form-control'
                          name="txtSeq_{{rowData['columN_NAME']}}E" value="{{rowData['columnSequence'] }}">
                      </td>
                      <td>
                        <input type='text' id="txtClass_{{rowData['columN_NAME']}}E" class='form-control'
                          name="txtClass_{{rowData['columN_NAME']}}E" value="{{rowData['controlClass'] }}">
                      </td>
                      <ng-container *ngIf="rowData['columnLength'] == '-1'; else elseifcolumnlengthSaved1E">
                        <td><input type='text' id="txt_{{rowData['columN_NAME']}}E" class='form-control'
                            name="txt_{{rowData['columN_NAME']}}E" value="Max"></td>
                      </ng-container>
                      <ng-template #elseifcolumnlengthSaved1E>
                        <ng-container
                          *ngIf="rowData['datA_TYPE'] == 'date' || rowData['datA_TYPE'] == 'datetime' || rowData['datA_TYPE'] == 'bit'; else elseifcolumnlengthSaved2E">
                          <td><input type='text' id="txt_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txt_{{rowData['columN_NAME']}}E" value="" disabled></td>
                        </ng-container>
                      </ng-template>

                      <ng-template #elseifcolumnlengthSaved2E>
                        <ng-container>
                          <td><input type='text' id="txt_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txt_{{rowData['columN_NAME']}}E" value="{{rowData['columnLength']}}"></td>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngIf="rowData['table_columnLength'] == '-1'; else elseifcolumnlength1E">
                        <td><input type='text' id="txtCurrent_{{rowData['columN_NAME']}}E" class='form-control'
                            name="txtCurrent_{{rowData['columN_NAME']}}E" disabled value="Max"></td>
                      </ng-container>
                      <ng-template #elseifcolumnlength1E>
                        <ng-container
                          *ngIf="rowData['datA_TYPE'] == 'date' || rowData['datA_TYPE'] == 'datetime' || rowData['datA_TYPE'] == 'bit'; else elseifcolumnlength2E">
                          <td><input type='text' id="txtCurrent_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtCurrent_{{rowData['columN_NAME']}}E" value="" disabled></td>
                        </ng-container>
                      </ng-template>
                      <ng-template #elseifcolumnlength2E>
                        <ng-container>
                          <td><input type='text' id="txtCurrent_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtCurrent_{{rowData['columN_NAME']}}E" disabled
                              value="{{rowData['table_columnLength']}}"></td>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngIf="rowData['iS_NULLABLE'].toLowerCase() == 'yes'; else elseifrequiredE">
                        <td><input type='checkbox' id="chk_{{rowData['columN_NAME']}}E"><span class='checkmark'></span>
                        </td>
                      </ng-container>
                      <ng-template #elseifrequiredE>
                        <ng-container>
                          <td><input type='checkbox' id="chk_{{rowData['columN_NAME']}}E" checked
                              [attr.disabled]="rowData['table_iS_NULLABLE'].toLowerCase() == 'no' ? true : null"><span
                              class='checkmark'></span></td>
                        </ng-container>
                      </ng-template>

                      <ng-container
                        *ngIf="rowData['table_iS_NULLABLE'].toLowerCase() == 'yes'; else elseifrequiredcurrentE">
                        <td><input type='checkbox' id="chkCurrent_{{rowData['columN_NAME']}}E " disabled> <span
                            class='checkmark'></span></td>
                      </ng-container>
                      <ng-template #elseifrequiredcurrentE>
                        <ng-container>
                          <td><input type='checkbox' id="chkCurrent_{{rowData['columN_NAME']}}E" checked disabled><span
                              class='checkmark'></span></td>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngIf=" rowData['hideColumn'].toLowerCase() == 'yes'; else elseifHideE">
                        <td><input type='checkbox' checked id="chkHide_{{rowData['columN_NAME']}}E"><span
                            class='checkmark'></span></td>
                      </ng-container>
                      <ng-template #elseifHideE>
                        <ng-container>
                          <td><input type='checkbox' id="chkHide_{{rowData['columN_NAME']}}E"
                              [attr.disabled]="rowData['table_iS_NULLABLE'].toLowerCase() == 'no' ? true : null"><span
                              class='checkmark'></span></td>
                        </ng-container>
                      </ng-template>
                      <td> <select id="DrpValue_{{rowData['columN_NAME']}}E" class="form-control">
                          <ng-container *ngFor="let opt of dropdownListpageloadValueFillTypedrp">
                            <option *ngIf="opt['id'] == rowData['valueFillType']" value="{{opt['id']}}" selected>
                              {{opt['itemName']}}</option>
                            <option *ngIf="opt['id'] != rowData['valueFillType']" value="{{opt['id']}}">
                              {{opt['itemName']}}
                            </option>
                          </ng-container>
                        </select> </td>
                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'id' || rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifchildTableE">
                        <td><input type='text' readonly disabled id="txtChild_{{rowData['columN_NAME']}}E"
                            class='form-control' name="txtChild_{{rowData['columN_NAME']}}E"
                            value="{{rowData['dropDownChildtable']}}"></td>
                      </ng-container>
                      <ng-template #elseifchildTableE>
                        <ng-container>
                          <td><input type='text' id="txtChild_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtChild_{{rowData['columN_NAME']}}E" value="{{rowData['dropDownChildtable']}}">
                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'id' || rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropQueryE">
                        <td><textarea rows='2' cols='50' type='text' style='width:140px;' readonly disabled
                            id="txtQuery_{{rowData['columN_NAME']}}E" class='form-control'
                            name="txtQuery_{{rowData['columN_NAME']}}E">{{rowData['dropDownQuery']}}</textarea></td>
                      </ng-container>
                      <ng-template #elseifDropQueryE>
                        <ng-container>
                          <td><textarea rows='2' cols='50' type='text' style='width:140px;'
                              id="txtQuery_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtQuery_{{rowData['columN_NAME']}}E">{{rowData['dropDownQuery']}}</textarea></td>
                        </ng-container>
                      </ng-template>
                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'id' || rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropControlE">
                        <td><select disabled id="DrpC_{{rowData['columN_NAME']}}E" class="form-control">
                            <option value="">Select</option>
                            <ng-container *ngFor="let opt of dropdownListpageloadControldrp">
                              <option *ngIf="opt['id'] == rowData['control']" value="{{opt['id']}}" selected>
                                {{opt['itemName']}}
                              </option>
                              <option *ngIf="opt['id'] != rowData['control']" value="{{opt['id']}}">{{opt['itemName']}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                      </ng-container>
                      <ng-template #elseifDropControlE>
                        <ng-container>
                          <td><select id="DrpC_{{rowData['columN_NAME']}}E" class="form-control">
                              <option value="">Select</option>
                              <ng-container *ngFor="let opt of dropdownListpageloadControldrp">
                                <option *ngIf="opt['id'] == rowData['control']" value="{{opt['id']}}" selected>
                                  {{opt['itemName']}}
                                </option>
                                <option *ngIf="opt['id'] != rowData['control']" value="{{opt['id']}}">
                                  {{opt['itemName']}}</option>
                              </ng-container>
                            </select>
                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'id' || rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifDropValidationE">
                        <td><select disabled id="Drp_{{rowData['columN_NAME']}}E" class="form-control">
                            <option value="">Select</option>
                            <ng-container *ngFor="let opt of dropdownListpageloadValidationdrp">
                              <option *ngIf="opt['id'] == rowData['validation']" value="{{opt['id']}}" selected>
                                {{opt['itemName']}}
                              </option>
                              <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">
                                {{opt['itemName']}}</option>
                            </ng-container>
                          </select>
                        </td>
                      </ng-container>
                      <ng-template #elseifDropValidationE>
                        <ng-container>
                          <td><select id="Drp_{{rowData['columN_NAME']}}E" class="form-control">
                              <option value="">Select</option>
                              <ng-container *ngFor="let opt of dropdownListpageloadValidationdrp">
                                <option *ngIf="opt['id'] == rowData['validation']" value="{{opt['id']}}" selected>
                                  {{opt['itemName']}}</option>
                                <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">
                                  {{opt['itemName']}}
                                </option>
                              </ng-container>
                            </select>
                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifValidationMsgE">
                        <td><input type='text' id="txtValidationMsg_{{rowData['columN_NAME']}}E" disabled
                            class='form-control' name="txtValidationMsg_{{rowData['columN_NAME']}}E"
                            value="{{rowData['customValidationMsg']}}" title='Custom Validation Message'
                            placeholder='Validation Message'>
                        </td>
                      </ng-container>
                      <ng-template #elseifValidationMsgE>
                        <ng-container>
                          <td><input type='text' id="txtValidationMsg_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtValidationMsg_{{rowData['columN_NAME']}}E"
                              value="{{rowData['customValidationMsg']}}" title='Custom Validation Message'
                              placeholder='Validation Message'>
                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifRegExpE">
                        <td><input type='text' id="txtRegExp_{{rowData['columN_NAME']}}E" disabled class='form-control'
                            name="txtRegExp_{{rowData['columN_NAME']}}E" value="" title='Custom RegExp String'
                            placeholder='RegExp String'>
                        </td>
                      </ng-container>
                      <ng-template #elseifRegExpE>
                        <ng-container>
                          <td><input type='text' id="txtRegExp_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtRegExp_{{rowData['columN_NAME']}}E" value="{{rowData['regExp']}}"
                              title='Custom RegExp String' placeholder='RegExp String'>
                          </td>
                        </ng-container>
                      </ng-template>
                      <ng-container
                        *ngIf="rowData['datA_TYPE'].toLowerCase() == 'int' || rowData['datA_TYPE'].toLowerCase() == 'decimal'|| rowData['datA_TYPE'].toLowerCase() == 'numeric'; else elseifDropCompairE">
                        <td><select id="DrpCC_{{rowData['columN_NAME']}}E" class="form-control">
                            <option value="">Select</option>
                            <ng-container *ngFor="let opt of dropdownListpageloadControldrpNumber">
                              <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}" selected>
                                {{opt['itemName']}}</option>
                              <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">
                                {{opt['itemName']}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                      </ng-container>
                      <ng-template #elseifDropCompairE>
                        <ng-container
                          *ngIf="rowData['datA_TYPE'].toLowerCase() == 'date' || rowData['datA_TYPE'].toLowerCase() == 'datetime'; else elseifDropCompair2E">
                          <td><select id="DrpCC_{{rowData['columN_NAME']}}E" class="form-control">
                              <option value="">Select</option>
                              <option *ngIf="'Today' == rowData['compareColumnName']" value='Today' selected> Current
                                Date
                              </option>
                              <option *ngIf="'Today' != rowData['compareColumnName']" value='Today'> Current Date
                              </option>
                              <ng-container *ngFor="let opt of dropdownListpageloadControlDatedrp">
                                <option *ngIf="opt['id'] == rowData['compareColumnName']" value="{{opt['id']}}"
                                  selected>
                                  {{opt['itemName']}}</option>
                                <option *ngIf="opt['id'] != rowData['validation']" value="{{opt['id']}}">
                                  {{opt['itemName']}}
                                </option>
                              </ng-container>
                            </select>
                          </td>
                        </ng-container>

                        <ng-template #elseifDropCompair2E>
                          <ng-container>
                            <td><select disabled id="DrpCC_{{rowData['columN_NAME']}}E" class="form-control">
                                <option value="">Select</option>
                                <option *ngFor="let opt of dropdownListpageloadControldrpNumber" value="{{opt['id']}}">
                                  {{opt['itemName']}}</option>
                              </select>
                            </td>
                          </ng-container>
                        </ng-template>
                      </ng-template>
                      <ng-container
                        *ngIf="rowData['datA_TYPE'].toLowerCase() == 'int' || rowData['datA_TYPE'].toLowerCase() == 'decimal' || rowData['datA_TYPE'].toLowerCase() == 'numeric'; else elseifRange1E">
                        <td><input type='text' id="txtRange_{{rowData['columN_NAME']}}E" class='form-control'
                            name="txtRange_{{rowData['columN_NAME']}}E" value="{{rowData['range']}}"
                            title='Range should be in given format  ex:- 20-40' placeholder='ex:- 20-40'></td>
                      </ng-container>
                      <ng-template #elseifRange1E>
                        <ng-container
                          *ngIf="rowData['datA_TYPE'].toLowerCase() == 'date' || rowData['datA_TYPE'].toLowerCase() == 'datetime'; else elseifRange2E">
                          <td><input type='text' id="txtRange_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtRange_{{rowData['columN_NAME']}}E" value="{{rowData['range']}}"
                              title='Date Range should be in numeric months ex:- 1year = 12' placeholder='ex:- 24'></td>
                        </ng-container>
                      </ng-template>
                      <ng-template #elseifRange2E>
                        <ng-container>
                          <td><input type='text' disabled id="txtRange_{{rowData['columN_NAME']}}E" class='form-control'
                              name="txtRange_{{rowData['columN_NAME']}}E" value="{{rowData['range']}}"></td>
                        </ng-container>
                      </ng-template>

                      <ng-container
                        *ngIf="rowData['columN_NAME'].toLowerCase() == 'guid'; else elseifcolumnInformationE">
                        <td>
                          <input type='text' id="txtcolumnInformation_{{rowData['columN_NAME']}}E" disabled
                            class='form-control' name="txtcolumnInformation_{{rowData['columN_NAME']}}E"
                            value="{{rowData['columnInformation']}}" title='Column Information Text'
                            placeholder='Information Text'>
                        </td>
                      </ng-container>
                      <ng-template #elseifcolumnInformationE>
                        <ng-container>
                          <td><input type='text' id="txtcolumnInformation_{{rowData['columN_NAME']}}E"
                              class='form-control' name="txtcolumnInformation_{{rowData['columN_NAME']}}E"
                              value="{{rowData['columnInformation']}}" title='Column Information Text'
                              placeholder='Information Text'>
                          </td>
                        </ng-container>
                      </ng-template>

                      <td>
                        <a href="javascript:void(0)" *ngIf="rowData.columnFlag==1" class='delete opendetail'
                          (click)=onDeletecustumtablechild(i)><i class='fa fa-trash'></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondry" (click)="onCloseEditModal()">Close</button>
          <button type="button" id="btnUpdate" class="btn btn-primary ml-3"
            (click)="updateEditTableEntry()">Update</button>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- /.modal !-->
  <!-- End UpdateMaster Modal -->


  <!-- start View Modal -->

  <div id="ViewModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg inner-content " role="document">
      <div class="modal-content edit-modal-form">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="HmodalHeaderId"></h2>
          <button type="button" class="close" aria-label="Close" (click)="onCloseViewModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div id="DivtableViewData" class="inner-content mt-5 pt-4 border-top">
            <table class="table table-bordered row-border hover">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Column Name</th>
                  <th>Saved Data Type</th>
                  <th>Current Data Type</th>
                  <th>Column Alias</th>
                  <th>Column Sequence</th>
                  <th>Column Class</th>
                  <th>Saved Column Length</th>
                  <th>Current Column Length</th>
                  <th>Saved Required</th>
                  <th>Current Required</th>
                  <th>Hide Column</th>
                  <th>Value Fill Type</th>
                  <th>ChildTable Name</th>
                  <th>DropDownQuery</th>
                  <th>Select Control</th>
                  <th>Select Validation</th>
                  <th>Validation Message</th>
                  <th>Comparison Column</th>
                  <th>Range(min-max)</th>
                  <th>Column Information</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trData of viewTableData; let i=index;"
                  [ngClass]="{'bg-danger text-white': trData.columnFlag==1, 'bg-info text-white': trData.columnFlag==0 }">
                  <td>{{(i+1) }}</td>
                  <td>{{ trData.columN_NAME }}</td>
                  <td>{{ trData.datA_TYPE }}</td>
                  <td>{{ trData.table_datA_TYPE }}</td>
                  <td>{{ trData.columnAlias }}</td>
                  <td>{{ trData.columnSequence }}</td>
                  <td>{{ trData.controlClass }}</td>

                  <td>{{ trData.columnLength }}</td>
                  <td>{{ trData.table_columnLength }}</td>
                  <td>{{ trData.iS_NULLABLE }}</td>
                  <td>{{ trData.table_iS_NULLABLE }}</td>

                  <td>{{ trData.hideColumn }}</td>
                  <td>{{ trData.valueFillTypeText }}</td>
                  <td>{{ trData.dropDownChildtable }}</td>
                  <td>{{ trData.dropDownQuery }}</td>
                  <td>{{ trData.controlText }}</td>
                  <td>{{ trData.validation }}</td>
                  <td>{{ trData.customValidationMsg }}</td>
                  <td>{{ trData.compareColumnName }}</td>
                  <td>{{ trData.range }}</td>
                  <td>{{ trData.columnInformation }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">


          <button type="button" class="btn btn-secondry" (click)="onCloseViewModal()">Cancel</button>


        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End View Modal -->

  <!-- End Modals -->


</div>