import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-box-event',
  templateUrl: './confirm-dialog-box-event.component.html',
  styleUrls: ['./confirm-dialog-box-event.component.css']
})
export class ConfirmDialogBoxEventComponent implements OnInit {

  @Input() AlertDialogTitle: string |null |undefined;
  @Input() AlertDialogMessage: string |null |undefined;
  @Input() btnOkText: string |null |undefined;
  @Input() btnCancelText: string |null |undefined;
    
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public onCloseConfirmModal() {
    this.activeModal.close(false);
  }

  public onAcceptConfirmModal() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.close(false);
    // this.activeModal.dismiss();
  }

}
