import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-dialog-box',
  templateUrl: './alert-dialog-box.component.html',
  styleUrls: ['./alert-dialog-box.component.css']
})
export class AlertDialogBoxComponent implements OnInit {
  @Input() AlertDialogTitle: string |null |undefined;
  @Input() AlertDialogMessage: string |null |undefined;
  @Input() btnOkText: string |null |undefined;
  @Input() AlertDialogType: string |null |undefined;
  @Input() AlertDialogClass: string |null |undefined;
  @Input() AlertDialogAnimationType: string |null |undefined; 
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public onCloseAlertModal() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
