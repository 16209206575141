import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { IfileValidationType, IfileTypeOutput, IfileType ,filetypeEnum} from '../model/fileType';
import { GlobalAlertService } from '../global-alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.css']
})
export class PreviewImageComponent implements OnInit {


  fileValidationObject?: IfileValidationType;
  maxFileSize?: number;
  fileSizeMessage?: string |null |undefined;
  maxFileCount?: number;
  fileCountMessage?: string |null |undefined;
  maxFileType?: string[];
  fileTypeMessage?: string |null |undefined;
  @Input() formName: string =""; 
  @Input() fileName: string="";
  @Input() fileColumnName: string ="";
  @Input() title: string |null |undefined;
  @Input() btnOkText: string |null |undefined;
  @Input() btnCancelText: string |null |undefined;
ImageUrl: string ="";
  constructor(private userService: UserService,private globalAlertService: GlobalAlertService,private activeModal: NgbActiveModal) {

   

  }

  
  ngOnInit() {
    
    let that = this;
    this.userService.previewFile(this.fileName, this.fileColumnName, this.formName).subscribe((res:any) => {     
      this.ImageUrl = "data:image/jpeg;base64," + res.toString();
      console.log(this.ImageUrl);
      
   });

  }
  
  onfiledownloadDetail() {
    let _url = this.userService.rootUrl + "/values/MasterDownloadFiles/?filename=" + this.fileName + "&columnName=" + this.fileColumnName;
    // window.open(_url);
    console.log(_url);
    
    window.location.href = _url;

  }

  public onCloseConfirmModal() {
    this.activeModal.close(false);
  }

  public onAcceptConfirmModal() {
   
    this.onfiledownloadDetail();
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.close(false);
    // this.activeModal.dismiss();
  }

}
