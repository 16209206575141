<div class="row printData col-sm-12 text-left pt-4 mb-4 mx-0 px-3">
<div id="invoice-POS"
  style="box-shadow: 0 0 1in -0.25in rgb(0 0 0 / 50%); padding: 2mm; margin: 0 auto; width: 65mm; background: #FFF;">
  <div id="top">
    <div></div>
    <div style="text-align: center;">
      <h2 style="font-size: 16px; ">MoonLight Dry Cleaner</h2>
    </div>
    <!--End Info-->
  </div>
  <!--End InvoiceTop-->
  <div id="mid">
    <div style="text-align: center;">
      <!-- <h2 style="font-size: 20px;">Contact Info</h2> -->
      <p style="color: #000000; font-size: 14px;"> Address : street city, state 0000<span
          style="display: block;"></span> Email : JohnDoe@gmail.com<span style="display: block;"></span> Phone :
        555-555-5555 </p>
    </div>
  </div>
  <!--End Invoice Mid-->
  <div id="bot">
    <div id="table">
      <div><strong> Order Id: ML/0003-1</strong></div>
      <div><strong> Order Date: 01-01-2021</strong></div>
      <div><strong> Delivery Date: 20-02-2021</strong></div>
      <table style="width: 100%;">
        <tr>
          <td style="padding: 10px 5px; width: 50%; font-size: 15px;"><strong>Item</strong></td>
          <td style="padding: 10px 5px; width: 20%; font-size: 15px; text-align: center;">
            <p style="margin: 0px;"><strong>Qty</strong></p>
          </td>
          <td style="padding: 10px 5px; width: 30%; font-size: 15px; text-align: center;">
            <p style="margin: 0px; width: 70px; word-break: break-all;"><strong>Sub Total</strong></p>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px 5px; width: 50%; font-size: 15px; color: #000000;">
            <p style="margin: 0px; width: 75px; word-break: break-all;">3 piece suits</p>
          </td>
          <td style="padding: 10px 5px; width: 20%; font-size: 15px; color: #000000; text-align: center;">
            <p style="margin: 0px;">3</p>
          </td>
          <td style="padding: 10px 5px; width: 30%; font-size: 15px; color: #000000; text-align: center;">
            <p style="margin: 0px; word-break: break-all; width: 70px;">1500.00</p>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px 5px; width: 50%; font-size: 15px; color: #000000;">
            <p style="margin: 0px; width: 75px; word-break: break-all;">3 piece saree</p>
          </td>
          <td style="padding: 10px 5px; width: 20%; font-size: 15px; color: #000000; text-align: center;">
            <p style="margin: 0px;">5</p>
          </td>
          <td style="padding: 10px 5px; width: 30%; font-size: 15px; color: #000000; text-align: center;">
            <p style="margin: 0px; word-break: break-all; width: 70px;">2500.00</p>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px 5px; font-size: 15px; width: 50%;"><strong> Total</strong></td>
          <td style="padding: 10px 5px; font-size: 15px; width: 20%; text-align: center;"> <strong>8</strong></td>
          <td style="padding: 10px 5px; font-size: 15px; width: 30%; text-align: center;"> <strong>4000.00</strong></td>
        </tr>
      </table>
    </div>
    <!--End Table-->
    <div id="legalcopy" style="padding-top: 15px; text-align: center; padding-bottom: 10px;">
      <p style="margin: 0px; font-size: 18px;"><strong>Thank you for your business!</strong></p>
      <p style="margin: 0px; font-size: 16px; color: #000000; padding-top: 5px;"> Payment is expected within 31 days;
        please process this invoice within that time. There will be a 5% interest charge per month on late invoices.
      </p>
    </div>
  </div>
  <!--End InvoiceBot-->
</div>
</div>