import { Component,Input, OnInit  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {WorkFlowProcessServiceService} from './work-flow-process-service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { custumValidation } from "../../shared/custum-validation";
import { Customvalidation } from "../../shared/Validation";
import { WorkFlowActionEnum } from '../../shared/model/WorkFlowEnum';
import { FormFinalObjectParam, lstFormFinalObjectParam } from '../object-param.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogBoxService } from '../confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../global-alert.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Commonfunction } from "../../shared/commonutility";

@Component({
  selector: 'app-work-flow-process',
  templateUrl: './work-flow-process.component.html',
  styleUrls: ['./work-flow-process.component.css']
})
export class WorkFlowProcessComponent implements OnInit {

  AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
 
  @Input() WorkFlowApprove: boolean = false;
  @Input() WorkFlowReject: boolean = false;
  @Input() WorkFlowReturn: boolean = false;
  @Input() WorkFlowForword: boolean = false;
  @Input() recordId: string = '';
  @Input() tableName: string = '';
  @Input() formName: string ='';
  @Input() WorkFlowDialogTitle: string ="";
  @Input() btnOkText: string ="";
  @Input() btnCancelText: string="";
  dropdownGridSettings = {};
  mastertableFormWF!: FormGroup;
  dropdownListWFStatus:any = [];
  selectedItemsWFStatus: Array<{ id: string, itemName: string }> = [];
  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder, public Customvalidation: Customvalidation,private spinner: NgxSpinnerService
    , private globalAlertService: GlobalAlertService, private globalConfirmService: ConfirmDialogBoxService,  private userService: UserService, private router: Router, private commonfunction:Commonfunction) { }

  ngOnInit() {
    
if(this.WorkFlowApprove)
{
  this.dropdownListWFStatus.push({id: WorkFlowActionEnum.Approve.toString(), itemName: WorkFlowActionEnum[WorkFlowActionEnum.Approve] });
}
if(this.WorkFlowReject)
{
  this.dropdownListWFStatus.push({id: WorkFlowActionEnum.Reject.toString(), itemName: WorkFlowActionEnum[WorkFlowActionEnum.Reject] });
}
if(this.WorkFlowReturn)
{
  this.dropdownListWFStatus.push({id: WorkFlowActionEnum.Return.toString(), itemName: WorkFlowActionEnum[WorkFlowActionEnum.Return] });
}
if(this.WorkFlowForword)
{
  this.dropdownListWFStatus.push({id: WorkFlowActionEnum.Forword.toString(), itemName: WorkFlowActionEnum[WorkFlowActionEnum.Forword] });
}

    this.mastertableFormWF = this.fb.group({ 
      WorkFlowStatus: ['',  [custumValidation['validateRequired']("","","int","drop","Work Flow Status","","","")]],  
      Remarks: ['',  [custumValidation['validateRequired']("","","varchar","text","Remarks","","",""),custumValidation['validatealphanumeric']("","","varchar","text","Remarks","","",""),custumValidation['validateSize']("","2000","varchar","text","Remarks","","","")]],
   }
    );
    this.dropdownGridSettings = {
      singleSelection: true,
      // text:"Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // primaryKey:'DrpValue',
      // labelKey:'DrpOption',
      classes: "global-select-box"
    };
  }

  onSubmit(){
    let _data;
    let objColumn=<lstFormFinalObjectParam>{};
    let genericColumnListParam = [];

     for(let i=1; i<=4; i++ )
     {
         if(i==1)
         {
        let objListfinal = <FormFinalObjectParam>{};
        objListfinal.tbleName = this.tableName.toString();
        objListfinal.tbleSequence = i.toString();
        objListfinal.columnName = 'tableName';
        objListfinal.ColumnValue = this.tableName.toString();
        genericColumnListParam.push(objListfinal);
         }
         else  if(i==2)
         {
        let objListfinal = <FormFinalObjectParam>{};
        objListfinal.tbleName = this.tableName.toString();
        objListfinal.tbleSequence = i.toString();
        objListfinal.columnName = 'recordId';
        objListfinal.ColumnValue = this.recordId.toString();;
        genericColumnListParam.push(objListfinal);
         }

         else if (i==3)
         {
        let objListfinal = <FormFinalObjectParam>{};
        objListfinal.tbleName = this.tableName.toString();
        objListfinal.tbleSequence = i.toString();
        objListfinal.columnName = 'wfStatusId';
        objListfinal.ColumnValue = this.mastertableFormWF?.value["WorkFlowStatus"][0]['id'].toString();
        genericColumnListParam.push(objListfinal);
         }
         else if (i==4)
         {
        let objListfinal = <FormFinalObjectParam>{};
        objListfinal.tbleName = this.tableName.toString();
        objListfinal.tbleSequence = i.toString();
        objListfinal.columnName = 'remarks';
        objListfinal.ColumnValue = this.mastertableFormWF?.value["Remarks"].toString();
        genericColumnListParam.push(objListfinal);
         }

     }
     if (genericColumnListParam.length > 0) {
        objColumn.genericColumnListParam = genericColumnListParam;
        _data = JSON.stringify(objColumn);
        // alert(_data);
    }
this.openEditMasterModal(_data?.toString());

 
   
  }
  openEditMasterModal(params?: string) {
    this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
        .then((confirmed:any) => {
            if (confirmed) {
                this.submitCall(params);
                // this.paramvaluedata = '';
            }
        });
}
  submitCall(params?: string){
    this.spinner.show();
    let currentRole = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole')) ? sessionStorage.getItem("currentRole")?.toString():null;
    let appUserRole = currentRole ? JSON.parse(currentRole).roleId : '';
    let data = JSON.stringify("uspAppGenericWorkFlowFProcessData|tables=" + params+'|appUserId='+sessionStorage.getItem('userId')+'|appUserRole='+appUserRole);
       this.userService.updateWfProcessData(data,this.formName).subscribe((datacom : any)=>{
        this.spinner.hide();
     
      if (datacom != "") {
        var resultarray = datacom.split("-");

        if (resultarray[1] == "success") {
            // this.Search($('#SeltableID').val(),$('#SeltableIDForm').val());        
            this.AlertTitle ="Success!!";
            this.AlertMessage = resultarray[0].toString();
            this.AlertClass ="NP_emoji-success-bg";
            this.AlertAnimationType="smile";
            this.AlertType="modal NP_popup_success";
            $('#EditProcessMasterModalC').hide();
            $(".backdropCustom").css({display: "none"});
            this.onAcceptConfirmModal();
        }
        else {
          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray[0].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "NP_popup_fail";
      }

  }
},
  (err: HttpErrorResponse) => {
      this.spinner.hide();
      if (err.status == 401) {
          this.AlertTitle = "Error!!";
          this.AlertMessage = "You are not authorized!";
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = " NP_popup_fail";
      }
      else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
      }
      else {
          this.AlertTitle = "Error!!";
          this.AlertMessage = err.message.toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = " NP_popup_fail";
      }
      this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
  },
  () => {
      
  }
       
        ); 
  }
  OnClose() {
    this.mastertableFormWF?.get("WorkFlowStatus")?.markAsTouched();
    

}

  public onCloseConfirmModal() {
    this.activeModal.close({ 'GenericWorkFlow': { flag: false, errorMsg: "" } });
  }

  public onAcceptConfirmModal() {
    this.activeModal.close({ 'GenericWorkFlow': { flag: true, errorMsg: this.WorkFlowDialogTitle+ " "+this.mastertableFormWF?.value["WorkFlowStatus"][0]['itemName'].toString()+" successfully."  } });   
  }

  public dismiss() {
    this.activeModal.close({ 'GenericWorkFlow': { flag: false, errorMsg: "" } });
    // this.activeModal.dismiss();
  }

}
