import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-add-edit',
  templateUrl: './generic-add-edit.component.html',
  styleUrls: ['./generic-add-edit.component.css']
})
export class GenericAddEditComponent implements OnInit {
  @Output() removeActive = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  oneditaddbtn() {
    this.removeActive.emit(); // Emit event to the parent
  }



}
