<input [(ngModel)]="value" readonly [required]="required"
[ngbPopover]="popTemplate"
 [autoClose]="'outside'"
  placement="bottom"
  class="form-control"
/>

<ng-template #popTemplate class="timepicker-box"  style="position: absolute; background: #333;" >
  <div class="qeqe">
	  <timepicker class="timepicker-box" [(ngModel)]="timePickerVal" (ngModelChange)="changedTime()" [disabled]="disabled" [showMeridian]="isShowMeridian"
    (isValid)="onisValidate($event)">
    </timepicker>
  </div>
</ng-template> 