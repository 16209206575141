/*
  Author      - Naveen Tehlan
  Description - 
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Commonfunction } from './commonutility'
@Injectable({
  providedIn: 'root'
})
export class validationType {
  public flag: boolean=false;
  public msgString: string="";
}

export class custumValidation {

  constructor(private Commonfunction: Commonfunction) {

  }

 static dynamicMethods(method: string) {
    switch (method) {
        case 'ValidateDate': return custumValidation.ValidateDate.bind(this);
        //case 'ValidateDatechk': return custumValidation.ValidateDatechk.bind(this);
        case 'validateAadharCard': return custumValidation.validateAadharCard.bind(this);
        case 'validateCurrentFutureDates': return custumValidation.validateCurrentFutureDates.bind(this);
        case 'validateCurrentPastDates': return custumValidation.validateCurrentPastDates.bind(this);
        case 'validateDecimal': return custumValidation.validateDecimal.bind(this);
        //case 'validateDecimalChk': return custumValidation.validateDecimalChk.bind(this);
        case 'validateDecimalallowNegative': return custumValidation.validateDecimalallowNegative.bind(this);
        case 'validateEmail': return custumValidation.validateEmail.bind(this);
        case 'validateEqualDecimalValues': return custumValidation.validateEqualDecimalValues.bind(this);
        //case 'validateFileUpoadErrorMsg': return custumValidation.validateFileUpoadErrorMsg.bind(this);
        //case 'validateGeneric': return custumValidation.validateGeneric.bind(this);
        //case 'validateGenericwithRange': return custumValidation.validateGenericwithRange.bind(this);
        case 'validateGreaterDecimalValues': return custumValidation.validateGreaterDecimalValues.bind(this);
        case 'validateGreaterEqualDecimalValues': return custumValidation.validateGreaterEqualDecimalValues.bind(this);
        case 'validateLessDecimalValues': return custumValidation.validateLessDecimalValues.bind(this);
        case 'validateLessEqualDecimalValues': return custumValidation.validateLessEqualDecimalValues.bind(this);
        case 'validateOnlyAlphabets': return custumValidation.validateOnlyAlphabets.bind(this);
        case 'validateOnlyFutureDates': return custumValidation.validateOnlyFutureDates.bind(this);
        case 'validateOnlyPastDates': return custumValidation.validateOnlyPastDates.bind(this);
        case 'validateOnlyTodayDate': return custumValidation.validateOnlyTodayDate.bind(this);
        case 'validatePANCard': return custumValidation.validatePANCard.bind(this);
        case 'validatePhone': return custumValidation.validatePhone.bind(this);
        case 'validateRangeValues': return custumValidation.validateRangeValues.bind(this);
       //case 'validateRangeValuesChk': return custumValidation.validateRangeValuesChk.bind(this);
        case 'validateRequired': return custumValidation.validateRequired.bind(this);
        case 'validateSize': return custumValidation.validateSize.bind(this);
        case 'validateSpecialCharacter': return custumValidation.validateSpecialCharacter.bind(this);
        //case 'validateValueInArrayExists_Check': return custumValidation.validateValueInArrayExists_Check.bind(this);
        case 'validatealphanumeric': return custumValidation.validatealphanumeric.bind(this);
        case 'validatealphanumericwithoutspace': return custumValidation.validatealphanumericwithoutspace.bind(this);
        case 'validatenumeric': return custumValidation.validatenumeric.bind(this);
        case 'validatevehiclenumber': return custumValidation.validatevehiclenumber.bind(this);       
        default: return  custumValidation.fakeFunction.bind(this);
    }
}

static fakeFunction( custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any) {
  
  // this.masterProductForm.get(frmName);
  // (<FormGroup>this.masterProductForm.get(frmName)).controls;
  
}
  /*
  Author      - Naveen Tehlan
  Description - chk control value exixts in provided Array or Not .
  CreatedOn   - 27-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */
  static validateValueInArrayExists_Check(colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any,
    RangeString: any, formValue:any, filterColumn: string, considerSpaces: boolean, considerCase: boolean, compareString: string, dropdowncolumn: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let chkvalue = [];
      let chkEditvalue = [];
      let chkExistsValue: boolean = false;

      let msg = '';
      let controlValueChk = '';
      if (c.value != '' && c.value != null) {
        if (c.value !== compareString) {
          if (contType == "dropdown") {
            controlValueChk = c.value[0][dropdowncolumn].toString();
            msg = 'Aleady Added. Please select different ' + columnName + '.';
          }
          else if (contType == "mdropdown") {
            controlValueChk = Array.prototype.map.call(c.value, s => s['id']).toString();
            msg = 'Aleady Added. Please select different ' + columnName + '.';
          }
          else {
            controlValueChk = c.value.toString();
            msg = 'Already entered. Please enter different ' + columnName + '.';
          }
          if (formValue != null && formValue != undefined) {
            chkEditvalue = formValue.filter((x:any) => x['rowAction'] == "E");
            if (considerSpaces) {
              if (considerCase)
                chkvalue = formValue.filter((x:any) => x[filterColumn].replace(/ /g, '') == controlValueChk.replace(/ /g, ''));
              else
                chkvalue = formValue.filter((x:any) => x[filterColumn].replace(/ /g, '').toLowerCase() == controlValueChk.replace(/ /g, '').toLowerCase());
            }
            else {
              if (considerCase)
                chkvalue = formValue.filter((x:any) => x[filterColumn] == controlValueChk);
              else
                chkvalue = formValue.filter((x:any) => x[filterColumn].toLowerCase() == controlValueChk.toLowerCase());
            }
          }
          else {
            return null;
          }
          if (chkvalue !== null && chkvalue.length > 0) {
            if (chkvalue[0]['rowAction'] == "E" && chkEditvalue !== null && chkEditvalue.length > 0) {
              if (considerSpaces) {
                if (considerCase)
                  chkExistsValue = chkEditvalue[0][filterColumn].replace(/ /g, '') == controlValueChk.replace(/ /g, '') ? false : true;
                else
                  chkExistsValue = chkEditvalue[0][filterColumn].replace(/ /g, '').toLowerCase() == controlValueChk.replace(/ /g, '').toLowerCase() ? false : true;
              }
              else {
                if (considerCase)
                  chkExistsValue = chkEditvalue[0][filterColumn] == controlValueChk ? false : true;
                else
                  chkExistsValue = chkEditvalue[0][filterColumn].toLowerCase() == controlValueChk.toLowerCase() ? false : true;

              }
              if (chkExistsValue)
                return { 'GenericError': { flag: true, errorMsg: msg } };
              else
                return null;


            }
            else
              return { 'GenericError': { flag: true, errorMsg: msg } };
          }
          else {
            return null;
          }
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    }

  }

  /*
   Author      - Naveen Tehlan
   Description - validateDecimal function used to validate input string is valid decimal format or not.
   CreatedOn   - 06-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
 
   */
  static validateGeneric(genericRegx: RegExp, custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid value for ' + columnName;
      }
      //new RegExp
      let RE = new RegExp(genericRegx);
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (RE.test(columnValue)) {
          return null;
        } else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;
    }
  }

  /*
   Author      - Naveen Tehlan
   Description - validateDecimal function used to validate input string is valid decimal format or not.
   CreatedOn   - 06-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
 
   */
  static validateGenericwithRange(genericRegx: RegExp, custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid value for ' + columnName;
      }
      //new RegExp
      let RE = new RegExp("^"+genericRegx);
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (RE.test(columnValue)) {
          if (RangeString != null && RangeString != '') {
            let resultDecimal = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
            if (resultDecimal[0] == true) {               
           
            let result = this.validateRangeValuesChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
            if (result[0] == false) {
              msg = result[1].toString();
              return { 'GenericError': { flag: true, errorMsg: msg } };
            }
            else
              return null;
          }
          else 
          return null;
          }
          else
            return null;
        } else {

          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - addMonths function used to add months in given date .
  CreatedOn   - 27-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */
  static addMonths(dm:any, m:any) {
    let d = new Date(dm);
    let years = Math.floor(m / 12);
    let months = m - (years * 12);
    if (years) d.setFullYear(d.getFullYear() + years);
    if (months) d.setMonth(d.getMonth() + months);
    return d;
  }

  /*
Author      - Naveen Tehlan
Description - validateFileUpoadErrorMsg function used to show file upload error messages like size type and count show alert.
CreatedOn   - 16-September-2020
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/

  static validateFileUpoadErrorMsg(custMsg: any, fileError: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      msg = custMsg;
      if (fileError) {
        return { 'GenericError': { flag: true, errorMsg: msg } };
      }
      else {
        return null;
      }
    }
  }

  /*
Author      - Naveen Tehlan
Description - validateRequired function used to validate for required if sting empty then show alert.
CreatedOn   - 06-August-2019
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/

  static validateRequired(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        if (contType == 'dropdown' || contType == 'mdropdown') {
          msg = 'Please select value for ' + columnName;
        }

        else if (contType == 'file') {
          msg = 'Please upload file for ' + columnName;
        }
        else {
          msg = 'Please enter value for ' + columnName;
        }
      }

      if (c.value == "" || c.value == null) {
        return { 'GenericError': { flag: true, errorMsg: msg } };
      }
      else {
        return null;
      }
    }
  }

  /*
 Author      - Naveen Tehlan
 Description - validateSize function used to validate input string length is within given column size length or not.
 CreatedOn   - 08-August-2019
 ModifiedBy  - 
 ModifiedOn  - 
 Purpose     -

 */
  static validateSize(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let colLength = 0;
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Max length exceeded from ' + colsize + ' for ' + columnName + '.';
      }
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          colLength = c.value[0]["id"].toString().length;
        }
        else if (contType == "mdropdown") {
          colLength = Array.prototype.map.call(c.value, s => s['id']).toString().length;
        }
        else {
          colLength = c.value.toString().length;
        }
        if (Number(colsize) == -1) {
          return null;
        }
        else if (Number(colLength) == -1) {
          if (Number(colsize) == -1 && Number(colLength) == -1) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
        else if (Number(colLength) <= Number(colsize)) {
          return null;
        }
        else {

          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }

      else {
        return null;
      }
    }
  }
  /*
Author      - Naveen Tehlan
Description - ValidateDate function used to validate input string is valid date format or not.
CreatedOn   - 06-August-2019
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/
  static ValidateDate(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        let msg = 'Please enter valid Date for ' + columnName + ' (dd/mm/yyyy)';
      }
      // let Cudate = new Date(c.value);
      let currVal = Commonfunction.ConvertDateFormat(c.value);
      if (currVal == '' || currVal == null)
        return null;

      //Declare Regex  
      let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
      let dtArray = String(currVal).match(rxDatePattern); // is format OK?

      if (dtArray == null)
        return { 'GenericError': { flag: true, errorMsg: msg } };

      ////Checks for mm/dd/yyyy format.
      //dtMonth = dtArray[1];
      //dtDay = dtArray[3];
      //dtYear = dtArray[5];

      //Checks for dd/mm/yyyy format.
      let dtDay = Number(dtArray[1]);
      let dtMonth = Number(dtArray[3]);
      let dtYear = Number(dtArray[5]);

      if (dtMonth < 1 || dtMonth > 12)
        return { 'GenericError': { flag: true, errorMsg: msg } };
      else if (dtDay < 1 || dtDay > 31)
        return { 'GenericError': { flag: true, errorMsg: msg } };
      else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
        return { 'GenericError': { flag: true, errorMsg: msg } };
      else if (dtMonth == 2) {
        let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
        if (dtDay > 29 || (dtDay == 29 && !isleap))
          return { 'GenericError': { flag: true, errorMsg: msg } };
      }
      return null;
    }
  }

  /*
Author      - Naveen Tehlan
Description - ValidateTime function used to validate input string is valid time format or not.
CreatedOn   - 06-August-2019
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/
  static ValidateTime(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      coltype = 'Time format'
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid time for ' + columnName + ' (hh:mm am/pm).';
      }
      let currVal = c.value;
      if (currVal == '' || currVal == null)
        return null;

      //Declare Regex  
      let rxDatePattern = /^((([0]?[1-9]|1[0-2])(:|\.)[0-5][0-9]((:|\.)[0-5][0-9])?( )?(AM|am|aM|Am|PM|pm|pM|Pm))|(([0]?[0-9]|1[0-9]|2[0-3])(:|\.)[0-5][0-9]((:|\.)[0-5][0-9])?))$/;
      let dtArray = String(currVal).match(rxDatePattern); // is format OK?

      if (dtArray == null)
        return { 'GenericError': { flag: true, errorMsg: msg } };
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateEmail function used to validate input string is valid validateEmail format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  static validateEmail(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid email for ' + columnName + '.';
      }

      let filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (c.value != '' && c.value != null) {
        if (filter.test(c.value)) {
          return null;
        }

        else {

          return { 'GenericError': { flag: true, errorMsg: msg } };

        }
      }
      else
        return null;
    }
  }

  /*
   Author      - Naveen Tehlan
   Description - validateDecimal function used to validate input string is valid decimal format or not.
   CreatedOn   - 06-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
 
   */
  static validateDecimal(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid decimal for ' + columnName;
      }
      let RE = /^\d*(\.\d{1})?\d{0,1}$/;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (RE.test(columnValue)) {
          return null;
        } else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateDecimalallowNegative function used to validate input string is valid decimal format or not and allow negative value.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
 
  */
  static validateDecimalallowNegative(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid decimal for ' + columnName;
      }
      let RE = /^-?\d*(\.\d{1})?\d{0,1}$/;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (RE.test(columnValue)) {
          return null;
        } else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validatenumeric function used to validate input string is valid numeric format or not.
  CreatedOn   - 06-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -

  */
  static validatenumeric(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid number for ' + columnName;
      }
      let RE = /^[0-9]*$/;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (RE.test(columnValue)) {
          return null;
        } else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;
    }
  }

  /*
Author      - Naveen Tehlan
Description - validateSpecialChracter function used to validate input string has only some specfic special character or not.
CreatedOn   - 06-August-2019
ModifiedBy  - 
ModifiedOn  - 
Purpose     -

*/

  static validateSpecialCharacter(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = columnName + ' contains illegal characters.';
      }
      let RE = /^(?!.*([-,'/?.()%&+])\1{1})(?=.*[a-z \d A-Z])[A-Za-z0-9 ,'\/\-?.()%&+ ]+$/;
      if (c.value != '' && c.value != null) {
        if (RE.test(c.value)) {
          return null;
        } else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }

  /*
     Author      - Naveen Tehlan
     Description - validatePhone function used to validate input string is valid Mobile number with fix 10 digit format or not.
     CreatedOn   - 08-August-2019
     ModifiedBy  - 
     ModifiedOn  - 
     Purpose     -
  
      */

  static validatePhone(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid phone/mobile number for ' + columnName + '.';
      }
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        if (columnValue[0] == '0') {

          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          let filter = /^\d{10}$/;
          if (filter.test(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
    Author      - Naveen Tehlan
    Description - validatealphanumeric function used to validate input string has  allow alpha numeric with space and "_-" .
    CreatedOn   - 03-August-2019
    ModifiedBy  - 
    ModifiedOn  - 
    Purpose     -
    
    */

  static validatealphanumeric(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid value for ' + columnName + '.';
      }
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }

        let filter = /^([a-zA-Z0-9 _-]+)$/;
        if (filter.test(columnValue)) {
          return null;
        }
        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }

  /*
   Author      - Naveen Tehlan
   Description - validatealphanumericwithoutspace function used to validate input string has  allow alpha numeric without spcae and with "_-" .
   CreatedOn   - 03-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
   
   */

  static validatealphanumericwithoutspace(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid value for ' + columnName + '.(without spaces)';
      }
      let filter = /^([a-zA-Z0-9_-]+)$/;
      if (c.value != '' && c.value != null) {
        if (filter.test(c.value)) {
          return null;
        }
        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateAadharCard function used to validate input string has only Aadhar Card String .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateAadharCard(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid Aadhar Card for ' + columnName + '.';
      }
      let adrData = c.value;

      let filter = /^\d{12}$/;
      let filtercode = /^\d{16}$/;
      if (c.value != '' && c.value != null) {
        if (filter.test(adrData)) {

          return null;
        }
        else if (filtercode.test(adrData)) {
          return null;
        }
        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }

  /*
    Author      - Naveen Tehlan
    Description - validatePANCard function used to validate input string has only PAN Card String .
    CreatedOn   - 21-August-2019
    ModifiedBy  - 
    ModifiedOn  - 
    Purpose     -
    
    */

  static validatePANCard(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid PAN Card for ' + columnName + '.';
      }
      let panData = c.value;

      let filter = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
      let filtercode = /^([C,P,H,F,A,T,B,L,J,G])$/;
      
      if (c.value != '' && c.value != null) {
        let a_code_Check = panData.substring(3, 4);
        if (filter.test(panData)) {
          if (filtercode.test(a_code_Check)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }

        }

        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }

      else
        return null;

    }
  }

  /*
  Author      - Naveen Tehlan
  public validatevehiclenumber(c.value,colsize,coltype,contType,columnName) {
  Description - validateOnlyAlphabets function used to validate input string has only  vehicle number.
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validatevehiclenumber(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid vehicle number for ' + columnName + '.';
      }
      let vehicleData = c.value;

      let filter = /^[a-z]{2}( |-)\d{2}(?: |,)(?:[a-z\d]{1,2}[a-z])\1\d{4}$/;
      if (c.value != '' && c.value != null) {
        if (filter.test(vehicleData)) {
          return null;
        }
        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateOnlyAlphabets function used to validate input string has only  allow alpha numeric with spcae .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateOnlyAlphabets(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      if (custMsg != '') {
        msg = custMsg;
      }
      else {
        msg = 'Please enter valid value for ' + columnName + '.';
      }
      if (c.value != '' && c.value != null) {
        let filter = /^([a-zA-Z _-]+)$/;
        if (filter.test(c.value)) {
          return null;
        }
        else {
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
      }
      else
        return null;

    }
  }
  /*
   Author      - Naveen Tehlan
   Description - ValidateDate function used to validate input string is valid date format or not.
   CreatedOn   - 06-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
 
   */
  static ValidateDatechk(custMsg: any, ColumnValue:any, colsize:string, coltype:string, contType:string, columnName:string, CompareColumnValue:any, CompareColumnName:string, RangeString:string) {
    let msg = '';
    if (custMsg != '') {
      msg = custMsg;
    }
    else {
      msg = 'Please enter valid date for ' + columnName + ' (dd/mm/yyyy).';
    }
    let Cudate = new Date(ColumnValue)
    let currVal = Commonfunction.ConvertDateFormat(ColumnValue);
    if (ColumnValue == '')
      return [false, msg];

    //Declare Regex  
    let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
    let dtArray = String(currVal).match(rxDatePattern); // is format OK?

    if (dtArray == null)
      return [false, msg];

    ////Checks for mm/dd/yyyy format.
    //dtMonth = dtArray[1];
    //dtDay = dtArray[3];
    //dtYear = dtArray[5];

    //Checks for dd/mm/yyyy format.
    let dtDay = Number(dtArray[1]);
    let dtMonth = Number(dtArray[3]);
    let dtYear = Number(dtArray[5]);

    if (dtMonth < 1 || dtMonth > 12)
      return [false, msg];
    else if (dtDay < 1 || dtDay > 31)
      return [false, msg];
    else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
      return [false, msg];
    else if (dtMonth == 2) {
      let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
      if (dtDay > 29 || (dtDay == 29 && !isleap))
        return [false, msg];
    }
    return [true, msg];
  }
  /*
   Author      - Naveen Tehlan
   Description - validateOnlyPastDates function used to validate input string has only  Past date .
   CreatedOn   - 21-August-2019
   ModifiedBy  - 
   ModifiedOn  - 
   Purpose     -
   
   */

  static validateOnlyPastDates(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {

      let msg = '';
      let currentDate;
      let RangeNewDate;
      let RangeNewDateString;
      let cudateIndex = new Date(c.value);
      let aDate = Commonfunction.ConvertDateFormat(c.value);
      let IdateFinal;
      if (c.value != '' && c.value != null) {
        let result = this.ValidateDatechk(custMsg, c.value, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          if (RangeString == "" || RangeString.indexOf("+") > -1) {
            RangeNewDateString = "0";
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = 'Future and Today Date not allowed for ' + columnName + '.';
            }
          }
          else {
            RangeNewDateString = RangeString;
            let year = parseInt(RangeNewDateString) * -1 / 12;
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = "Date Should be lesser than " + year + " year's from today's date allowed for " + columnName + ".";
            }
          }

          if (CompareColumnName.toLowerCase() == "today") {
            currentDate = new Date();
            RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString))

          }
          else {
            let aCU = CompareColumnValue;
            let IdateCU;
            if (aCU.indexOf("/") > -1) {
              IdateCU = aCU.split('/');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
            }
            else if (aCU.indexOf("-") > -1) {
              IdateCU = aCU.split('-');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
            }
            RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString));
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = columnName + ' Should be less than ' + CompareColumnName + '.';
            }
          }
          currentDate?.setHours(0);
          currentDate?.setMinutes(0);
          currentDate?.setSeconds(0);
          currentDate?.setMilliseconds(0);
          RangeNewDate?.setHours(0);
          RangeNewDate?.setMinutes(0);
          RangeNewDate?.setSeconds(0);
          RangeNewDate?.setMilliseconds(0);
          if (aDate != null && aDate.indexOf("-") > -1) {
            let Idate = aDate?.split('-');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
          }
          else if (aDate != null && aDate.indexOf("/") > -1) {
            let Idate = aDate.split('/');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
          }
          
          if ((IdateFinal !=  null && currentDate != null) && (IdateFinal.getTime() < currentDate.getTime() && IdateFinal.getTime() < RangeNewDate.getTime())) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }
  /*
  Author      - Naveen Tehlan
  Description - validateOnlyFutureDates function used to validate input string has only  Future date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateOnlyFutureDates(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let RangeNewDate;
      let RangeNewDateString;
      let IdateFinal;
      let cudateIndex = new Date(c.value);
      let aDate = cudateIndex.getDate() + '/' + (Number(cudateIndex.getMonth()) + 1) + '/' + cudateIndex.getFullYear();
      if (c.value != '' && c.value != null) {
        let result = this.ValidateDatechk(custMsg, c.value, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          if (RangeString == "" || RangeString.indexOf("-") > -1) {
            RangeNewDateString = "0";
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = 'Past and Today Date not allowed for ' + columnName + '.';
            }
          }
          else {
            RangeNewDateString = RangeString;
            let year = parseInt(RangeNewDateString) * 1 / 12;
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = "Date Should be greater than " + year + " year's from today's date allowed for " + columnName + ".";
            }
          }

          if (CompareColumnName.toLowerCase() == "today") {
            currentDate = new Date();
            RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString))

          }
          else {
            let aCU = CompareColumnValue;
            let IdateCU;
            if (aCU.indexOf("/") > -1) {
              IdateCU = aCU.split('/');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);

            }
            else if (aCU.indexOf("-") > -1) {
              IdateCU = aCU.split('-');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
            }
            RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString))

            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = columnName + ' Should be greater than ' + CompareColumnName + '.';
            }
          }
          currentDate?.setHours(0);
          currentDate?.setMinutes(0);
          currentDate?.setSeconds(0);
          currentDate?.setMilliseconds(0);
          RangeNewDate.setHours(0);
          RangeNewDate.setMinutes(0);
          RangeNewDate.setSeconds(0);
          RangeNewDate.setMilliseconds(0);
          if (aDate.indexOf("-") > -1) {
            let Idate = aDate.split('-');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
          }
          else if (aDate.indexOf("/") > -1) {
            let Idate = aDate.split('/');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
          }
          if ((IdateFinal && currentDate)&&(IdateFinal.getTime() > currentDate.getTime() && IdateFinal.getTime() > RangeNewDate.getTime())) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateOnlyTodayDate function used to validate input string has only  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateOnlyTodayDate(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let IdateFinal;
      let cudateIndex = new Date(c.value);
      let aDate = cudateIndex.getDate() + '/' + (Number(cudateIndex.getMonth()) + 1) + '/' + cudateIndex.getFullYear();
      if (c.value != '' && c.value != null) {
        let result = this.ValidateDatechk(custMsg, c.value, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {

          if (CompareColumnName.toLowerCase() == "today") {
            currentDate = new Date();
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = 'Only Today date allowed for ' + columnName + '.';
            }
          }
          else {
            let aCU = CompareColumnValue;
            let IdateCU;
            if (aCU.indexOf("/") > -1) {
              IdateCU = aCU.split('/');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
            }
            else if (aCU.indexOf("-") > -1) {
              IdateCU = aCU.split('-');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
            }
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = columnName + ' Should be same as ' + CompareColumnName + '.';
            }
          }
          currentDate?.setHours(0);
          currentDate?.setMinutes(0);
          currentDate?.setSeconds(0);
          currentDate?.setMilliseconds(0);
          if (aDate.indexOf("-") > -1) {
            let Idate = aDate.split('-');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
          }
          else if (aDate.indexOf("/") > -1) {
            let Idate = aDate.split('/');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
          }
          if ((IdateFinal && currentDate)&&(IdateFinal.getTime() == currentDate.getTime())) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateCurrentFutureDate function used to validate input string has only future and  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateCurrentFutureDates(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let RangeNewDate;
      let RangeNewDateString;
      let IdateFinal;
      let cudateIndex = new Date(c.value);
      let aDate = cudateIndex.getDate() + '/' + (Number(cudateIndex.getMonth()) + 1) + '/' + cudateIndex.getFullYear();
      if (c.value != '' && c.value != null) {
        let result = this.ValidateDatechk(custMsg, c.value, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          if (RangeString == "" || RangeString.indexOf("-") > -1) {
            RangeNewDateString = "0";
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = 'Past Date not allowed for ' + columnName + '.';
            }
          }
          else {
            RangeNewDateString = RangeString;
            let year = parseInt(RangeNewDateString) * 1 / 12;
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = "Date Should be greater than " + year + " year's from today's date allowed for " + columnName + ".";
            }
          }

          if (CompareColumnName.toLowerCase() == "today") {
            currentDate = new Date();
            RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString));
          }
          else {
            let aCU = CompareColumnValue;
            let IdateCU;
            if (aCU.indexOf("/") > -1) {
              IdateCU = aCU.split('/');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
            }
            else if (aCU.indexOf("-") > -1) {
              IdateCU = aCU.split('-');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
            }
            RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString));
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = columnName + ' Should not be less than ' + CompareColumnName + '.';
            }
          }
          currentDate?.setHours(0);
          currentDate?.setMinutes(0);
          currentDate?.setSeconds(0);
          currentDate?.setMilliseconds(0);
          RangeNewDate.setHours(0);
          RangeNewDate.setMinutes(0);
          RangeNewDate.setSeconds(0);
          RangeNewDate.setMilliseconds(0);
          if (aDate.indexOf("-") > -1) {
            let Idate = aDate.split('-');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
          }
          else if (aDate.indexOf("/") > -1) {
            let Idate = aDate.split('/');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
          }
          if ((IdateFinal && currentDate)&&(IdateFinal.getTime() >= currentDate.getTime() && IdateFinal.getTime() >= RangeNewDate.getTime())) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateCurrentPastDate function used to validate input string has only Past and  Current date .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateCurrentPastDates(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {

      let msg;
      let currentDate;
      let RangeNewDate;
      let RangeNewDateString;
      let IdateFinal;
      let cudateIndex = new Date(c.value);
      let aDate = cudateIndex.getDate() + '/' + (Number(cudateIndex.getMonth()) + 1) + '/' + cudateIndex.getFullYear();
      if (c.value != '' && c.value != null) {
        let result = this.ValidateDatechk(custMsg, c.value, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          if (RangeString == "" || RangeString.indexOf("+") > -1) {
            RangeNewDateString = "0";
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = 'Future Date not allowed for ' + columnName + '.';
            }
          }
          else {
            RangeNewDateString = RangeString;
            let year = parseInt(RangeNewDateString) * -1 / 12;
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = "Date Should be lesser than " + year + " year's from today's date allowed for " + columnName + ".";
            }
          }

          if (CompareColumnName.toLowerCase() == "today") {
            currentDate = new Date();
            RangeNewDate = this.addMonths(new Date(), parseInt(RangeNewDateString));

          }
          else {
            let aCU = CompareColumnValue;
            let IdateCU;
            if (aCU.indexOf("/") > -1) {
              IdateCU = aCU.split('/');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '/' + dtDayCU + '/' + dtYearCU);
            }
            else if (aCU.indexOf("-") > -1) {
              IdateCU = aCU.split('-');
              let dtDayCU = IdateCU[0];
              let dtMonthCU = IdateCU[1];
              let dtYearCU = IdateCU[2];
              currentDate = new Date(dtMonthCU + '-' + dtDayCU + '-' + dtYearCU);
            }
            RangeNewDate = this.addMonths(currentDate, parseInt(RangeNewDateString));
            if (custMsg != '') {
              msg = custMsg;
            }
            else {
              msg = columnName + ' Should not be greater than ' + CompareColumnName + '.';
            }
          }
          currentDate?.setHours(0);
          currentDate?.setMinutes(0);
          currentDate?.setSeconds(0);
          currentDate?.setMilliseconds(0);
          RangeNewDate.setHours(0);
          RangeNewDate.setMinutes(0);
          RangeNewDate.setSeconds(0);
          RangeNewDate.setMilliseconds(0);
          if (aDate.indexOf("-") > -1) {
            let Idate = aDate.split('-');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '-' + dtDay + '-' + dtYear);
          }
          else if (aDate.indexOf("/") > -1) {
            let Idate = aDate.split('/');
            let dtDay = Idate[0];
            let dtMonth = Idate[1];
            let dtYear = Idate[2];
            IdateFinal = new Date(dtMonth + '/' + dtDay + '/' + dtYear);
          }

          if ((IdateFinal && currentDate)&&(IdateFinal.getTime() <= currentDate.getTime() && IdateFinal.getTime() <= currentDate.getTime())) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
 Author      - Naveen Tehlan
 Description - validateDecimal function used to validate input string is valid decimal format or not.
 CreatedOn   - 06-August-2019
 ModifiedBy  - 
 ModifiedOn  - 
 Purpose     -

 */
  static validateDecimalChk(custMsg: any, ColumnValue:any, colsize:string, coltype:string, contType:string, columnName:string, CompareColumnValue:any, CompareColumnName:string, RangeString:string) {
    let msg = '';
    if (custMsg != '') {
      msg = custMsg;
    }
    else {
      msg = 'Please enter valid ' + coltype.toLowerCase() + ' for column ' + columnName;
    }
    let RE = /^\d*(\.\d{1})?\d{0,1}$/;
    if (RE.test(ColumnValue)) {
      return [true, msg];
    } else {
      return [false, msg];
    }
  }

  /*
    Author      - Naveen Tehlan
    Description - validateGreaterDecimalValues function used to validate input should be greater than other value .
    CreatedOn   - 21-August-2019
    ModifiedBy  - 
    ModifiedOn  - 
    Purpose     -
    
    */

  static validateGreaterDecimalValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg = '';
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
          if (custMsg != '') {
            msg = custMsg;
          }
          else {
            msg = columnName + ' Should be greater than ' + CompareColumnName + '.';
          }

          if (parseFloat(CompareColumnValue) < parseFloat(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateLessDecimalValues function used to validate input should be less than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateLessDecimalValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {

          if (custMsg != '') {
            msg = custMsg;
          }
          else {
            msg = columnName + ' Should be less than ' + CompareColumnName + '.';
          }

          if (parseFloat(CompareColumnValue) > parseFloat(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateEqualDecimalValues function used to validate input should be equal as other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateEqualDecimalValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {

          if (custMsg != '') {
            msg = custMsg;
          }
          else {
            msg = columnName + ' Should be equal to ' + CompareColumnName + '.';
          }

          if (parseFloat(CompareColumnValue) == parseFloat(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateGreaterEqualDecimalValues function used to validate input should be greater/equal than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateGreaterEqualDecimalValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {

          if (custMsg != '') {
            msg = custMsg;
          }
          else {
            msg = columnName + ' Should not be less than ' + CompareColumnName + '.';
          }

          if (parseFloat(CompareColumnValue) <= parseFloat(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateLessEqualDecimalValues function used to validate input should not be greater than other value .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateLessEqualDecimalValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateDecimalChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {

          if (custMsg != '') {
            msg = custMsg;
          }
          else {
            msg = columnName + ' Should not be greater than ' + CompareColumnName + '.';
          }

          if (parseFloat(CompareColumnValue) >= parseFloat(columnValue)) {
            return null;
          }
          else {
            return { 'GenericError': { flag: true, errorMsg: msg } };
          }
        }
      }
      else
        return null;
    }
  }

  /*
  Author      - Naveen Tehlan
  Description - validateRangeValues function used to validate input range .
  CreatedOn   - 21-August-2019
  ModifiedBy  - 
  ModifiedOn  - 
  Purpose     -
  
  */

  static validateRangeValues(custMsg: any, colsize: any, coltype: any, contType: any, columnName: any, CompareColumnValue: any, CompareColumnName: any, RangeString: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: { flag: boolean, errorMsg: string } } | null => {
      let msg;
      let currentDate;
      let columnValue;
      if (c.value != '' && c.value != null) {
        if (contType == "dropdown") {
          columnValue = c.value[0]["id"];
        }
        else if (contType == "mdropdown") {
          columnValue = Array.prototype.map.call(c.value, s => s['id']);
        }
        else {
          columnValue = c.value;
        }
        let result = this.validateRangeValuesChk(custMsg, columnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
        if (result[0] == false) {
          msg = result[1].toString();
          return { 'GenericError': { flag: true, errorMsg: msg } };
        }
        else {
         return null;
        }
      }
      else
        return null;
    }
  }

    /*
 Author      - Naveen Tehlan
 Description - validateDecimal function used to validate input string is valid decimal format or not.
 CreatedOn   - 06-August-2019
 ModifiedBy  - 
 ModifiedOn  - 
 Purpose     -

 */
static validateRangeValuesChk(custMsg: any, ColumnValue:any, colsize:string, coltype:string, contType:string, columnName:string, CompareColumnValue:any, CompareColumnName:string, RangeString:string) {
  let msg = ''; 
  let result = this.validateDecimalChk(custMsg, ColumnValue, colsize, coltype, contType, columnName, CompareColumnValue, CompareColumnName, RangeString);
  if (result[0] == false) {
    msg = result[1].toString();
    return [false, msg];    
  }
  else {
    if (custMsg != '') {
      msg = custMsg;
    }
    else {
      msg = columnName + ' Should be lies between ' + RangeString + '.';
    }
    let rangeValue = RangeString.split('-');

    if (parseFloat(ColumnValue) >= parseFloat(rangeValue[0]) && parseFloat(ColumnValue) <= parseFloat(rangeValue[1])) {
      return [true, msg];
    }
    else {
      return [false, msg];
      
    }
  }
}


}

