
// import { HomeComponent } from './home/home.component';
// import { UserComponent } from './user/user.component';
// import{ ResetPasswordComponent } from './user/reset-password/reset-password.component'
// import { SignInComponent } from './user/sign-in/sign-in.component';
// import { MasterFormComponent } from './master-form/master-form.component';
// import{MasterTableFormComponent} from './master-table-form/master-table-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from '../../src/app/auth/auth.guard';
import { SecureComponent, SECURE_ROUTES } from './layout/secure';
import { PublicComponent, PUBLIC_ROUTES } from './layout/public';
import { DefaultComponent,DEFAULT_ROUTES } from './layout/default';
import { CustumComponent, CUSTUM_ROUTES } from './layout/custum';
import { PrintLayoutComponent} from './shared/print-layout/print-layout.component';
import { GenericPrintFormComponent} from './shared/generic-print-form/generic-print-form.component';
import { AlertDialogBoxEventComponent } from './shared/alert-dialog-box-event/alert-dialog-box-event.component';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload' //Must have if you want to be able to use the anchor more than once
};

export const appRoutes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', redirectTo: 'login', pathMatch: 'full' },
    { path: 'printForm',
    outlet: 'printForm',
    component: PrintLayoutComponent,
    children: [
      { path: 'printRecord', component: GenericPrintFormComponent }
      
    ]
  },
  { path: '', component: CustumComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange', data: { title: 'Custum Views' }, children: CUSTUM_ROUTES },
    { path: '', component: PublicComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange', data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
    
    { path: '', component: SecureComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange', canActivate: [AuthGuard], data: { title: 'Secure Views' }, children: SECURE_ROUTES },
    { path: '', component: DefaultComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange', canActivate: [AuthGuard], data: { title: 'Home Views' }, children: DEFAULT_ROUTES },
    
     { path: '**', redirectTo: 'home' }
];

/**
 * App routing module
 */
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, routerOptions)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }