import { GenericMasterFormComponent } from '../../secure/generic-master-form/generic-master-form.component';
import {GenerateMasterFormComponent} from '../../secure/generate-master-form/generate-master-form.component';
import {DashboardComponent} from '../../secure/dashboard/dashboard.component';
import {ChangepasswordComponent} from '../../secure/changepassword/changepassword.component';
import {ManageprofileComponent} from '../../secure/manageprofile/manageprofile.component';
import {generateReportFormComponent} from '../../secure/generate-report-form/generate-report-form.component';
import {GenericListFormComponent} from '../../secure/generic-list-form/generic-list-form.component';
import{GenerateCustumFormComponent} from '../../secure/generate-custum-form/generate-custum-form.component';
import{WFMasterComponent} from '../../secure/wfmaster/wfmaster.component';
import{GenericReportFormComponent} from '../../secure/generic-report-form/generic-report-form.component';
import{GenericMultiTableFormComponent} from '../../secure/generic-multi-table-form/generic-multi-table-form.component';
import{PayFeeDuesComponent} from '../../secure/SchoolERPFee/pay-fee-dues/pay-fee-dues.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { HomeDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/home-dashboard/home-dashboard.component';
import { ExperienceDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/experience-dashboard/experience-dashboard.component';
import { LessonmanagementDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/lessonmanagement-dashboard/lessonmanagement-dashboard.component';
import { CategoriesDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/categories-dashboard/categories-dashboard.component';
import { ProgramDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/program-dashboard/program-dashboard.component';
import { TestimonialDashboardComponent } from '../../secure/Frontend Setting/dashboard/Home/testimonial-dashboard/testimonial-dashboard.component';
import { AboutComponent } from 'src/app/secure/Frontend Setting/dashboard/about/about.component';
import { ContactusDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/contactus-dashboard/contactus-dashboard.component';
import { ClasseslistDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/classes/classeslist-dashboard/classeslist-dashboard.component';
import { BlogDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/blogs/blog-dashboard/blog-dashboard.component';
import { BlogdetailsDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/blogs/blogdetails-dashboard/blogdetails-dashboard.component';
import { FooterDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/footer-dashboard/footer-dashboard.component';
import { HeaderDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/header-dashboard/header-dashboard.component';
import { AdmissionDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/Addmission/admission-dashboard/admission-dashboard.component';
import { AdmissiondeadlineComponent } from 'src/app/secure/Frontend Setting/dashboard/Addmission/admissiondeadline/admissiondeadline.component';
import { AdmissionLinkDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/Addmission/admission-link-dashboard/admission-link-dashboard.component';
import { GalleryDashboardComponent } from 'src/app/secure/Frontend Setting/dashboard/gallery-dashboard/gallery-dashboard.component';


export const SECURE_ROUTES: Routes = [  
    
    { path: 'GenericMasterForm/:formName', component: GenericMasterFormComponent, canActivate: [AuthGuard] },   
    { path: 'GenericMultiForm/:formName', component: GenericMultiTableFormComponent, canActivate: [AuthGuard] },
    { path: 'GenericReportForm/:formName', component: GenericReportFormComponent, canActivate: [AuthGuard] }, 
    { path: 'GenericListForm/:formName', component: GenericListFormComponent, canActivate: [AuthGuard] },  
    
    // { path: 'GenericMasterForm', component: GenericMasterFormComponent, canActivate: [AuthGuard] },   
    // { path: 'GenericMultiForm', component: GenericMultiTableFormComponent, canActivate: [AuthGuard] },
    // { path: 'GenericReportForm', component: GenericReportFormComponent, canActivate: [AuthGuard] }, 
    // { path: 'GenericListForm', component: GenericListFormComponent, canActivate: [AuthGuard] },  

    { path: 'GenerateMasterForm', component: GenerateMasterFormComponent, canActivate: [AuthGuard] },   
    { path: 'GenerateReportForm', component: generateReportFormComponent, canActivate: [AuthGuard]  },
    { path: 'GenerateCustomForm', component: GenerateCustumFormComponent, canActivate: [AuthGuard]  },

    { path: 'Dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'Changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard] },
    { path: 'ManageProfile', component: ManageprofileComponent, canActivate: [AuthGuard] },  
   
    { path: 'WorkFlowForm', component: WFMasterComponent, canActivate: [AuthGuard]  },
    { path: 'FeeDuePayment', component: PayFeeDuesComponent, canActivate: [AuthGuard]  },
    { path: 'TimeSheet',
        loadChildren: () => import('../../secure/attendance/attendance.module').then(m => m.AttendanceModule)},
    { path: 'HomeDashboard', component: HomeDashboardComponent, canActivate: [AuthGuard]  },
    { path: 'HomeExperienceDashboard', component: ExperienceDashboardComponent, canActivate: [AuthGuard]},
    { path: 'HomeLessonDashboard', component: LessonmanagementDashboardComponent, canActivate: [AuthGuard]},
    { path: 'HomeCategoriesDashboard', component: CategoriesDashboardComponent, canActivate: [AuthGuard]},
    { path: 'HomeProgramDashboard', component: ProgramDashboardComponent, canActivate: [AuthGuard]},
    { path: 'HomeTestimonialDashboard', component: TestimonialDashboardComponent, canActivate: [AuthGuard]},
    { path: 'AboutUsDashboard', component: AboutComponent, canActivate: [AuthGuard]},
    { path: 'ContactUsDashboard', component: ContactusDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'ClassesDashboard', component: ClasseslistDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'BlogDashboard', component: BlogDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'BlogListDashboard', component: BlogdetailsDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'FooterDashboard', component: FooterDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'HeaderDashboard', component: HeaderDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'AdmissionDashboard', component: AdmissionDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'AdmissionDeadlineDashboard', component: AdmissiondeadlineComponent, canActivate: [AuthGuard]}, 
    { path: 'AdmissionLinkDashboard', component: AdmissionLinkDashboardComponent, canActivate: [AuthGuard]}, 
    { path: 'GalleryDashboard', component: GalleryDashboardComponent, canActivate: [AuthGuard]}, 
];