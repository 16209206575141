
<div class="main_captcha">
  <div class="d-flex captcha_wrapper align-items-center">
    <img [src]="captchaImage" class="captchaImages" alt="Captcha" />
    <input [(ngModel)]="userInput" class="form-control mx-2"  #txtcapEmail="ngModel" ngModel name="txtcapEmail" placeholder="Enter CAPTCHA" (input)="verifyCaptcha()" />
    <div class="refresh-btn  d-inline-block align-middle mr-2">
    <a href="JavaScript:Void(0);"
                    (click)="refreshCaptcha()"><img class="captchrefreshbtn" src="assets/img/refresh.webp" width="" height=""
                      alt="Refresh" title="Refresh"></a>
    </div>
    <!-- <input type="button" (click)="refreshCaptcha()" value="Refresh"/>   -->
    <!-- <input type="button" (click)="verifyCaptcha()" value="Verify"/> -->
  </div>
  <div *ngIf="verificationResult !== undefined">
    <span [ngClass]=" !isCaptchaVerified ? 'primary' : 'secondary'">{{ verificationResult }}</span></div>
</div>