import { HomeComponent } from '../../default/home/home.component';
import { RedirectComponent } from '../../default/redirect-component'

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';

export const DEFAULT_ROUTES: Routes = [
     // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
     { path: 'redirect', component: RedirectComponent, canActivate: [AuthGuard] }, 
   
    
];