import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { PublicGenericServiceService } from '../publicGenericService/public-generic-service.service';
import { Commonfunction } from '../../shared/commonutility';


export interface Iheader{
  id:number ;
  text: string;
  type: string,
  submenus: null,
  routkey: string,
  isactive: boolean,
  opennewtab: false,
  position: number,
  level0: string,
  level1:string
}

@Component({
  selector: 'app-public-generic-header',
  templateUrl: './public-generic-header.component.html',
  styleUrls: ['./public-generic-header.component.css']
})
export class PublicGenericHeaderComponent implements OnInit {
  jsonFiledata:any[] = [] ;
  menuUrl: any[] = [];
  isRenderMenu: boolean = false;
  headerFile:any;
  schLogo: any;

  menulevel0:Array<Iheader>=[]
  menulevel1:Array<Iheader>=[]
  menulevel2:Array<Iheader>=[]
  menuBtn:Array<Iheader>=[]
  // menulevel2:any=[]

  constructor(private userService: UserService, private publicGenericServiceService:PublicGenericServiceService, private commonfunction: Commonfunction) {
   // sessionStorage.clear();
   this.jsonFiledata = this.commonfunction.loadJson("~/../assets/punlicFormsConfig.json");

   const headerdatafile = this.commonfunction.loadJson("~/../assets/header.json");

   this.menulevel0 = headerdatafile.filter((x:any)=>(x['level0'] == null || x['level0'] == '') && (x['level1'] == null || x['level1'] == '') && (x['type'] === "link"));
   this.menulevel1 = headerdatafile.filter((x:any)=> (x['level0'] != null && x['level0'] != '') && (x['level1'] == null || x['level1'] == '') && (x['type'] === "link"));
   this.menulevel2 = headerdatafile.filter((x:any)=> (x['level0'] != null && x['level0'] != '') && (x['level1'] != null && x['level1'] != '') && (x['type'] === "link"));
   this.menuBtn = headerdatafile.filter((x:any)=> (x['level0'] == null || x['level0'] == '') && (x['level1'] == null || x['level1'] == '') && (x['type'] === "btn"));
  

   this.schLogo = this.commonfunction.loadJson("~/../assets/SchoolLogo.json")

   }
   islogin(){
    return this.publicGenericServiceService.getdynamicformparam();
   }
  ngOnInit(): void {
   
    if(sessionStorage.getItem('userToken') != ""){
      this.menuUrl = [];
    this.publicGenericServiceService.loginPublicGenericForm("publiclogin");
    this.isRenderMenu = true;
    }

  }

  status: boolean = false;
clickEvent(){
    this.status = !this.status;
}

checkLevel0child(value: string): boolean {
  let _checkrows: Array<Iheader> = this.menulevel1.filter((x: Iheader) => (x.level0 == value));
  if (_checkrows.length > 0)
    return true;
  return false;
}

scrolled: boolean = false;

@HostListener("window:scroll", [])
onWindowScroll() {
    this.scrolled = window.scrollY > 0;
}

}
