import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-custum-contactus',
  templateUrl: './public-custum-contactus.component.html',
  styleUrls: ['./public-custum-contactus.component.css']
})
export class PublicCustumContactusComponent implements OnInit {
  contactUs:any;
  constructor(public commonfunction:Commonfunction) { }

  ngOnInit(): void {
    this.contactUs = this.commonfunction.loadJson("~/../assets/Contactus.json");
    console.log(this.contactUs);
    
  }

}
