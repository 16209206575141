import { Component, ElementRef, OnInit } from '@angular/core';
import { Fancybox } from '@fancyapps/ui';
import { Commonfunction } from 'src/app/shared/commonutility';


export interface ITabName{
  tabName:string,
  isactive:boolean
}

@Component({
  selector: 'app-public-custum-gallery',
  templateUrl: './public-custum-gallery.component.html',
  styleUrls: ['./public-custum-gallery.component.css']
})

export class PublicCustumGalleryComponent implements OnInit {
  gallerydata: any;
  activeTabData: any[] = [];
 
  tabs: ITabName[] = [
    {"tabName":"All","isactive":true},
    {"tabName":"Event","isactive":false},
    {"tabName":"Highlights","isactive":false}
  ];

  constructor(private elRef: ElementRef, public commonfunction: Commonfunction) {
    const alldatafile = this.commonfunction.loadJson("~/../assets/gallerydb.json");
    this.gallerydata = alldatafile;
    console.log(this.gallerydata);
    
  }

  ngOnInit(): void {
    // if (this.gallerydata) {
    //   this.tabs = this.gallerydata.map((tab: any, index: number) => ({
    //     ...tab,
    //     isactive: index === 0
    //   }));
    // }

    console.log(this.tabs);
    
    this.updateActiveTabData(0); // Load "All" tab by default



    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });
  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }

  changeTab(index: number) {
    this.tabs = this.tabs.map((tab, i) => ({
      ...tab,
      isactive: i === index
    }));
    this.updateActiveTabData(index);
  }

  updateActiveTabData(index: number) {
    const activeTab = this.tabs[index].tabName;
    
    if (activeTab === 'All') {
      // If "All" is selected, show all the images
      this.activeTabData = this.gallerydata
      console.log(this.activeTabData , "alltab");
      
    } else {
      // Filter data by category based on the selected tab
      this.activeTabData = this.gallerydata
        .filter((item: any) => item.tabName === activeTab)
    }

    console.log(this.activeTabData,"another tab");
  }
}
