import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-filter-side',
  templateUrl: './generic-filter-side.component.html',
  styleUrls: ['./generic-filter-side.component.css']
})
export class GenericFilterSideComponent implements OnInit {
  @Input() AlertDialogType: string |null |undefined;
  constructor() { }

  ngOnInit(): void {
  }
  

}
