import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';
import{IhomeCr,Itableth} from '../../../allUsedModule';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { log } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../../shared/user.service';
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { AlertDialogBoxService } from 'src/app/shared/alert-dialog-box/alert-dialog-box.service';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.css']
})
export class HomeDashboardComponent implements OnInit {
  carouseldata:any;
  liveExperianceTab:any;
  activeTabId:number|any=1;
  addPermission: string = 'True';
  editPermission: string = 'True';
  activePermission: string = 'True';
  formTestPermission: string = 'True';
  deletePermission: string = 'True';
  printPermission: number = 0;
  isHistoryShowProc: string = '';
  paramvaluedata: any;
  displayMainEdit = "none";
  displayMain = "none";
  isRender: boolean = false;
  isRenderEdit: boolean = false; 
  masterProductForm!: FormGroup;
  masterJsonData: any[]=[];
  masterJsonDataFull: any[]=[];
  masterJsonDataEdit: any[]=[];
  masterJsonDataEditFull: any[]=[];
  ischeckboxshow: number = 0;
  dropdownListActionGroupSpdrp:any = [];


  AddNewFrom !: FormGroup;

  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "ImgUrl","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Title", "colName": "Heading","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Sub Title", "colName": "position","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "description","isActionBtn":false,"isImagePreviewEnabled":false },
  ]


  constructor(public commonfunction:Commonfunction,public homeCrservice:HomePageModelService,
    private confirm:ConfirmDialogBoxService, private spinner: NgxSpinnerService, private userService: UserService,private fb: FormBuilder,private AlertService:AlertDialogBoxService, private globalAlertService: GlobalAlertService){ 
    const data = this.commonfunction.loadJson("~/../assets/carsouselbanner.json")[0];
    this.carouseldata = data?.carouselData
    // console.log(this.carouseldata);
  }

  ngOnInit(): void {
    let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
    
    this.AddNewFrom = new FormGroup({
      img1:new FormControl("" , [Validators.required]),
      headline:new FormControl("" , [Validators.required]),
      headline2:new FormControl("", [Validators.required]),
      description:new FormControl("" , [Validators.required])
    })
    
  }
  onMainTab(){
    this.paramvaluedata = '';
    this.displayMain = "block";
    this.displayMainEdit = "none";
  }

  onViewTab(){
    this.paramvaluedata = '';
    this.displayMain = "none";
    this.displayMainEdit = "block";
  }

  Search(tbname:string, formName:string) {
    // this.spinner.show();
    this.userService.getMasterFormPage(tbname, formName).subscribe((data: any) => {
      this.displayMain = "block";
      let reactiveFormData = {};
      const that = this;

      this.addPermission = data['formAddPermission'];
      this.editPermission = data['formEditPermission'];
      this.activePermission = data['formActivePermission'];
      this.formTestPermission = data['formTestPermission'];
      this.deletePermission = data['formDeletePermission'];
      this.printPermission = data['formPrintPermission'];
      this.isHistoryShowProc = data['formisHistoryShowProc'];
      this.masterJsonDataFull = data;
      this.masterJsonData = data['formData'];
      this.ischeckboxshow = +data['formIsCheckbox'];
      let chkAsyncGenericDbCheck: Boolean = false;
      if(this.addPermission == 'True')
        this.activeTabId = 1;
        else
        this.activeTabId = 2;


        if (data['formActionGroupList'] != null)
          this.dropdownListActionGroupSpdrp = data['formActionGroupList'];

        if (this.addPermission == 'True') {
          this.masterJsonData.forEach(function (value, index) {
            console.log(value);
            
          });





          this.masterProductForm = this.fb.group(reactiveFormData);
          // this.masterJsonDataDefaultValue = reactiveFormDataDefaultValue;
          this.isRender = true;
        } else {
          // this.SearchEdit();
        }

        this.spinner.hide();
        if(chkAsyncGenericDbCheck){
          this.masterProductForm.valueChanges.pipe().subscribe((res: FormGroup) =>{
              this.commonfunction.setFormLatestValue(this.masterProductForm,"I");
          });
        }
    })
  }


  OnSubmitModal() {}
 

  onClickEditForm( value:any){
    this.homeCrservice.HomeCrService(value,true,"Heading","Heading2","Description")
    .then((confirm)=>{
      if(confirm){
        // this.carouseldata.carouselData[index] = value;
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile")
      }
    })
  }

  deleteKey(index:number){
    this.confirm.ConfirmFunction("Confirm!!","Are you sure you want to Deleted Record From Home?")
    .then((confirm)=>{
      if (confirm) {
        this.carouseldata?.carouselData.splice(index, 1);
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
      }
    })
  }

  onClickResetFrom(){
    this.AddNewFrom.reset()
  }
  OnClickSubmit(){
    this.confirm.ConfirmFunction("Confirm Add New !!", "Are you sure you want to proceed?")
    .then((confirm)=>{
      if (confirm) {
        const _item : IhomeCr={} as IhomeCr;
        _item.id = this.carouseldata?.carouselData.length + 1;
        _item.Heading = this.AddNewFrom.get("headline")?.value;
        _item.position = this.AddNewFrom.get("headline2")?.value;
        _item.description = this.AddNewFrom.get("description")?.value;
        _item.ImgUrl = this.AddNewFrom.get("img1")?.value;
        
        // console.log(_item);
        this.carouseldata?.carouselData.push(_item)
        this.AddNewFrom.reset()
        this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile")
      }
    })  
  }

}
