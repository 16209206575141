import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericChildActionModalComponent} from  './generic-child-action-modal.component';
import { IgenericChildActionOutput} from '../model/genericTableListViewModel';
@Injectable({
  providedIn: 'root'
})
export class GenericChildActionModalServiceService {

  constructor(private modalService: NgbModal) { }

  public createChildActionForm(
    title: string,
    btnCancelText: string ="Cancel",
    btnOkText: string = 'Set Value',
    AlertDialogType: string,
    AlertDialogClass: string,
    selectedActionName: string, 
    formName: string,  
    dialogSize: 'sm'|'lg' = 'lg'): Promise<IgenericChildActionOutput> {
    const modalRef = this.modalService.open(GenericChildActionModalComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.DialogTitle = title;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.AlertDialogType = AlertDialogType + " custom-action-modal";
    modalRef.componentInstance.AlertDialogClass = AlertDialogClass ;
    modalRef.componentInstance.selectedActionName = selectedActionName;
    modalRef.componentInstance.formName = formName;
    return modalRef.result;
  }
}
