import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogBoxEventComponent} from '../confirm-dialog-box-event/confirm-dialog-box-event.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogBoxService {

  constructor(private modalService: NgbModal) { }

  public ConfirmFunction(
    title: string,
    message: string,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',    
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmDialogBoxEventComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.AlertDialogTitle = title;
    modalRef.componentInstance.AlertDialogMessage = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText; 
    return modalRef.result;
  }

}
