import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';
import {IhomeLessons,IhomeLessonsSectionList, Itableth} from "../../../allUsedModule"
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { HomePageModelService } from '../../../allFormPopUpModel/home-pge-model/home-page-model.service';

@Component({
  selector: 'app-lessonmanagement-dashboard',
  templateUrl: './lessonmanagement-dashboard.component.html',
  styleUrls: ['./lessonmanagement-dashboard.component.css']
})
export class LessonmanagementDashboardComponent implements OnInit {
  activeTabId:number|any=1;
  LessonsHome:any;
  UpadteTitleFrom !: FormGroup;

  tableHead : Itableth[] = [
    { "displayName": "Title", "colName": "tabName","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Sub Title", "colName": "headlinename","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "tabcontaint","isActionBtn":true,"isImagePreviewEnabled":false },
  ]
 

  constructor(public commonfunction:Commonfunction,public homePageservice:HomePageModelService,private confirm: ConfirmDialogBoxService, private globalAlertService: GlobalAlertService) { }

  ngOnInit(): void {
    const data = this.commonfunction.loadJson("~/../assets/lessondb.json");
    this.LessonsHome = data?.tabsdata

    this.UpadteTitleFrom = new FormGroup({
      title: new FormControl(this.LessonsHome.topheadline , [Validators.required]),
      details: new FormControl(this.LessonsHome.topdescription , [Validators.required]),
    })
  }


  //Tab 1 Section
  onClickResetFrom(){
    this.UpadteTitleFrom.reset();
  }

  OnClickSubmit(){
    this.confirm.ConfirmFunction("Confirm Update!!", "Are you sure you want to Update Record From Home?")
  }

  //Tab 2 Section
  onClickEditForm(value:IhomeLessons){
      this.homePageservice.HomeLessonsService(value,true)
      .then((confirm)=>{
        if (confirm) {
          this.LessonsHome?.tabsdata.push(value);
          this.globalAlertService.AlertMsg("Success!!", "Data " + "Active", "Ok", "NP_popup_success", "NP_emoji-success-bg", "smile");
        }
      })
  }
  deleteKey(index:number){}


  onClickViewForm(value:Array<IhomeLessonsSectionList>){
    this.homePageservice.HomeLessonsListViewService(value,true,false)
  }

  onClickRowEditForm(value:Array<IhomeLessonsSectionList>){
    this.homePageservice.HomeLessonsListViewService(value,false,true)
  }

}
