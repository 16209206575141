
 <!-- start submit Modal -->
 <div id="ConfirmModal" class="edit-form-modal" tabindex="-1" role="dialog" 
 aria-hidden="true">
 <div  role="document"> 
   <div class="modal-content border-0 edit-modal-form">
     <div class="modal-header">
       <!-- <div class="NP_emoji-confirm-bg">
         <div class="NP_emoji-error"></div>
       </div> -->
       <h2 class="modal-title pull-left">{{WorkFlowDialogTitle}}</h2>
       <button type="button" class="close" aria-label="Close" (click)="onCloseConfirmModal()"><span
           aria-hidden="true">&times;</span></button>

     </div>
   
  
     <div class="modal-body">
        <form *ngIf="mastertableFormWF" novalidate [formGroup]="mastertableFormWF">
          <div class="col-xl-12">
            <label class="col-form-label requiredSign">Select Work FLow Status</label>
            <angular2-multiselect id="DrpWorkFlowStatus"  formControlName="WorkFlowStatus" [data]="dropdownListWFStatus" [(ngModel)]="selectedItemsWFStatus"            
            (onClose)="OnClose()"
            [ngClass]="{'is-invalid': (mastertableFormWF.get('WorkFlowStatus')?.touched || mastertableFormWF.get('WorkFlowStatus')?.dirty) && !mastertableFormWF.get('WorkFlowStatus')?.valid,'is-valid': (mastertableFormWF.get('WorkFlowStatus')?.touched || mastertableFormWF.get('WorkFlowStatus')?.dirty) && mastertableFormWF.get('WorkFlowStatus')?.valid }"
              [settings]="dropdownGridSettings">
            </angular2-multiselect>   
            <span class=" invalid-feedback" style="padding-top: 10px;">               
              {{mastertableFormWF.get('WorkFlowStatus')?.errors?.['GenericError']['errorMsg']}}
            </span>
                  
          </div>
            <div class="col-xl-12">
                <label class="col-form-label requiredSign"  for="txtRemarksWF">Remarks</label>
                <textarea type='text' class='form-control' id="txtRemarksWF" #Remarks formControlName="Remarks" Placeholder='Please Enter Remarks' autocomplete="off"                 
                  [ngClass]="{'is-invalid': (mastertableFormWF.get('Remarks')?.touched || mastertableFormWF.get('Remarks')?.dirty) && !mastertableFormWF.get('Remarks')?.valid,'is-valid': (mastertableFormWF.get('Remarks')?.touched || mastertableFormWF.get('Remarks')?.dirty) && mastertableFormWF.get('Remarks')?.valid }">
                  </textarea>
                <span class=" invalid-feedback" style="padding-top: 10px;" >
                  {{mastertableFormWF.get('Remarks')?.errors?.['GenericError']['errorMsg']}}
                </span>
              </div>
              
                  </form>
     </div>

     <div class="modal-footer">
     <div class="mt-2 text-center"> 
       <div class="d-inline-block align-middle">
         <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">{{btnCancelText}}</button> 
         <button type="button" [disabled]="!mastertableFormWF.valid" class="btn btn-primary ml-2" (click)="onSubmit()">Submit</button>
       </div>
      </div>
       <!-- <div class="d-inline-block align-middle ml-3">
         <p class="yes">
           <a href="javascript: void(0);" class="btn btn-primary" [disabled]="!mastertableFormWF?.valid" (click)="onAcceptConfirmModal(Remarks.value)">{{btnOkText}}</a>
           <button type="button" [disabled]="!mastertableFormWF?.valid" class="btn btn-primary ml-2" (click)="onAcceptConfirmModal(Remarks.value)">{{btnOkText}}</button>
          </p>
       </div>  -->

     </div>
   </div><!-- /.modal-content --> 
 </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->

<!-- End submit Modal -->