<!-- <ul class="nav nav-tabs master-form-tab mb-4" role="tablist">
  <li role="presentation" *ngIf="addPermission == 'True'" class="active"><a class="nav-link active" id="LinkAdd"
      href="#divAddNewData" (click)="onMainTab();" data-toggle="tab">Add New Detail</a></li>
  <li role="presentation" [ngClass]="{'': addPermission == 'True' ,'active': addPermission == 'False' }"> <a
      class="nav-link" [ngClass]="{'': addPermission == 'True' ,'active': addPermission == 'False' }" id="LinkView"
      href="#divViewDataDetail" (click)="onViewTab();" data-toggle="tab">View Detail</a></li>
</ul> -->
<div class="tab-content">
<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-2">
<!-- Tab panes -->

  <li [ngbNavItem]="1" *ngIf="addPermission == 'True'">
    <a ngbNavLink (click)="onMainTab();" class="nav-link">Add New Detail</a>
    <ng-template ngbNavContent>
  <div role="tabpanel" class="tab-panel  " id="divAddNewData">


    <div class="inner-content" [ngStyle]="{'display':displayMain}">

      <div id="DivData" class="DivData-content m-0 p-2">
        <div *ngIf="masterJsonData && isRender && addPermission" class="">
          <ng-container *ngFor="let formNameData of masterJsonData; let ibase=index">

            <fieldset class="mb-3">
              <legend>{{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"]) }} </legend>
              <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">

                <!-- {{masterJsonData.formType}} -->
                <div class="row m-0 afterUploadImag" [ngClass]="status ? 'fromProfileUpload' : 'danger'">
                  
                  <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                    <ng-container
                      *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                      <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>
                       
                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                          <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                            [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                            [(ngModel)]="formData.drpDataSelected"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            (onSelect)="onItemSelect($event,i,false)" (onDeSelect)="OnItemDeSelect($event,i,false)"
                            (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'])">
                            <!-- <c-badge>
                        <ng-template let-item="item">
                          <label  class=" m-0">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge> -->
                          </angular2-multiselect>
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      </div>
                      
                      <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                          <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                            [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                            [(ngModel)]="formData.drpDataSelected"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            (onSelect)="onItemSelect($event,i,true)" (onDeSelect)="OnItemDeSelect($event,i,true)"
                            (onSelectAll)="onItemSelectAll($event,i,true)"
                            (onDeSelectAll)="OnItemDeSelectAll($event,i,true)"
                            (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'])">
                            <!-- <c-badge>
                        <ng-template let-item="item">
                          <label  class=" m-0">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge> -->
                          </angular2-multiselect>
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      </div>
                      <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                          class='{{formData.controlDivClass}} calnder-icon'>
                          <input type='text' class="{{formData.controlClass}}"
                            (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                            [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                            [bsConfig]="bsConfig" autocomplete="off" readonly
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            formControlName="{{formData.controlName}}">
                          <div class="calenderinput-icon"><a href="javascript: void(0);"
                              (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                aria-hidden="true"></i></a></div>
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>

                        </div>

                      </div>
                      <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label"
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                          class="form-check {{formData.controlDivClass}}">

                          <input type='checkbox' value='' class="{{formData.controlClass}}" id="{{formData.controlId}}"
                            (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            formControlName="{{formData.controlName}}">
                          <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                            for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                          </label>
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>

                      </div>
                      <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                          <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                            [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                            formControlName="{{formData.controlName}}"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                        </textarea>
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>

                      </div>
                      <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}" [ngClass]="status ? 'fromProfileUpload' : 'danger'">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label"
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                          <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                            [FormReset]=formData.ChildComponentReset
                            [childComponentFormSequence]=formData.childComponentFormSequence
                            [childComponentFileDelete]=formData.childComponentFileDelete
                            [uploadedPreviousFile]='formData.previousControlValue'
                            (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                            id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                            [controlisDisabled]=formData.isDisabled
                            (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                          </app-upload-file>


                          <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                            formControlName="{{formData.controlName}}"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>

                      </div>
                      <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                          <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                            id="{{formData.controlId}}" formControlName="{{formData.controlName}}" readonly [disabled]="true"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      </div>
                      <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                          <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                            id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            readonly [disabled]="true">
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      </div>
                      <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                            ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                            tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                        </div>
      
                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                            <app-generic-child-time-picker  showMeridian="true"
                            [timerValue]="formData.controlValue"
                            [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                            [FormReset]=formData.ChildComponentReset
                            [childComponentFormSequence] = formData.childComponentFormSequence
                            [timerControlId] = formData.controlId
                            (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                            id="{{'timerControl_'+formData.controlId}}" 
                            [controlisDisabled]=formData.isDisabled></app-generic-child-time-picker>
      
                            <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                            formControlName="{{formData.controlName}}"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
      
      
                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>
                        </div>
                      </div>
                      <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                            ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                            tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                        </div>
      
                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                         
                          <app-generic-child-switch-button  
                          [toggleCheckedValue]="formData.controlValue"
                          [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                          [FormReset]="formData.ChildComponentReset"
                          [childComponentFormSequence] = formData.childComponentFormSequence
                          [toggleControlId] = formData.controlId
                          (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                          id="{{'switchControl_'+formData.controlId}}" 
                          [controlisDisabled]=formData.isDisabled></app-generic-child-switch-button >
    
                          <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                          formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
    
    
                        <span class=" invalid-feedback mt-1">
                          {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                        </div>
                      </div>
                      <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                        <div>
                          <label id="{{formData.spanId}}" class="col-form-label "
                            [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                            for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                          <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                            class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                            placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                              aria-hidden="true"></i></span>
                        </div>

                        <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                          [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                          <input type='text' class="{{formData.controlClass}}"
                            (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                            [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                            [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                            formControlName="{{formData.controlName}}">

                          <span class=" invalid-feedback mt-1">
                            {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                          </span>

                        </div>

                      </div>
                    </ng-container>

                  </ng-container>

                </div>

              </form>
            </fieldset>
          </ng-container>
          <fieldset *ngIf="childJsonData.length > 0" class="tab-fielset p-0">            
            <div class='tab-content'>
              <ul ngbNav #navCreateChild="ngbNav" [(activeId)]="activeTabIdCreate" role='tablist' class="nav-tabs master-form-tab border-0 mb-2">
              <ng-container *ngFor="let formNameData of childJsonData; let ichild=index">               
                  <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']==2" role='presentation' >
                    <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
                    <ng-template ngbNavContent>
                <div  id="{{'divcalling_'+formNameData['formAliasName']}}"
                  class='tab-pane  active legendclass' legendsequence="formNameData['formSequence']"
                  legendRequired="formNameData['formIsRequired']" legendName="formNameData['formAliasName']">
                  <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">

                    <div class="row m-0">
                      <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                        <ng-container
                          *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                          <div *ngIf="formData.controlType == 'dropdown'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],false,false)"
                                (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],false)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label>{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'mdropdown'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],true,false)"
                                (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],true)"
                                (onSelectAll)="onItemSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                (onDeSelectAll)="OnItemDeSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label>{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <!-- Hidden field case for child tables start -->
                          <div *ngIf="formData.controlType == 'hidden'">

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                              <input type='hidden' id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}">
                            </div>

                          </div>
                          <!-- Hidden field case for child tables End -->
                          <div *ngIf="formData.controlType == 'datetime'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class='{{formData.controlDivClass}} calnder-icon'>
                              <input type='text' class="{{formData.controlClass}}"
                                (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                [bsConfig]="bsConfig" autocomplete="off" readonly
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <div class="calenderinput-icon"><a href="javascript: void(0);"
                                  (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                    aria-hidden="true"></i></a></div>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>

                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'checkbox'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label"
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class="form-check {{formData.controlDivClass}}">

                              <input type='checkbox' value='' class="{{formData.controlClass}}"
                                id="{{formData.controlId}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                              </label>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'textarea'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                              </textarea>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>

                              <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                                [FormReset]=formData.ChildComponentReset
                                [childComponentFormSequence]=formData.childComponentFormSequence
                                [childComponentFileDelete]=formData.childComponentFileDelete
                                [uploadedPreviousFile]='formData.previousControlValue'
                                [uploadedFile]=formData.controlValue
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                [controlisDisabled]=formData.isDisabled
                                (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                              </app-upload-file>

                              <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'labelauto'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>

                              <input type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                readonly [disabled]="true">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                                <app-generic-child-time-picker  showMeridian="true"
                                [timerValue]="formData.controlValue"
                                [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                                [FormReset]=formData.ChildComponentReset
                                [childComponentFormSequence] = formData.childComponentFormSequence
                                [timerControlId] = formData.controlId
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                id="{{'timerControl_'+formData.controlId}}" 
                                [controlisDisabled]=formData.isDisabled ></app-generic-child-time-picker>
          
                                <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
          
          
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                             
                              <app-generic-child-switch-button  
                              [toggleCheckedValue]="formData.controlValue"
                              [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                              [FormReset]="formData.ChildComponentReset"
                              [childComponentFormSequence] = formData.childComponentFormSequence
                              [toggleControlId] = formData.controlId
                              (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                              id="{{'switchControl_'+formData.controlId}}" 
                              [controlisDisabled]=formData.isDisabled></app-generic-child-switch-button >
        
                              <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                              formControlName="{{formData.controlName}}"
                              [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
        
        
                            <span class=" invalid-feedback mt-1">
                              {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                            </span>
                            </div>
                          </div>

                          <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">

                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>

                            </div>

                          </div>
                        </ng-container>

                      </ng-container>
                      <!-- <div class="row"> -->
                      <div class='form-group col-sm-12 text-right'>                     
                        <button type="button" class="btn btn-secondry"
                          (click)="resetChildForm(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                             <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'"  (click)="testformData(GenericFormGroup(formNameData['formAliasName']))">Test Form</button>
                        <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                          *ngIf="!isChildRowEdit"
                          (click)="onAddChildRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                          class="btn btn-primary ml-3">Add</button>
                        <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                          *ngIf="isChildRowEdit"
                          (click)="onAddChildRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                          class="btn btn-primary ml-3">Update Item</button>
                          
                      </div>
                      <!-- </div> -->
                    </div>


                  </form>

                  <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">

                    <table *ngIf="childTableDataDynamicArrayList[formNameData['formAliasName']]?.length>0"
                      [id]="formNameData['formDataTableId']" class='table  '>
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                            <th
                              *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                              {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                            </th>
                          </ng-container>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let rowData of childTableDataDynamicArrayList[formNameData['formAliasName']]; let icRow=index;"
                          class='Uiparams'>
                          <td>{{(icRow+1)}}</td>
                          <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                            <ng-container
                              *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                              <td>
                                <div appEllipsifyLongText>
                                  {{rowData[formChildTableRow['columN_NAME']]}}
                                </div>
                              </td>
                            </ng-container>
                            <ng-template #elsedetailTableFile>
                              <ng-container
                                *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                <td class="file-dowmload">
                                  <a href="javascript:void(0)" class='active'
                                    [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                    (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                    ngbTooltip="Download {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue">
                                    <i class='fa fa-download'></i></a>
                                  <a href="javascript:void(0)" class='active'
                                    [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                    (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                    ngbTooltip="Open {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue">
                                    <i class='fa fa-file-image-o'></i></a>

                                </td>
                              </ng-container>
                            </ng-template>
                          </ng-container>
                          <td>
                            <a href="javascript:void(0)"
                              (click)="editChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                              class='edit opendetail'><i class='fa fa-pencil'></i></a>
                            <a href="javascript:void(0)"
                              (click)="OndeleteChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                              class='delete opendetail'><i class='fa fa-trash'></i></a>

                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                          <th>Total</th>
                          <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                            <th
                              *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                              {{childTableDataDynamicArrayListSumColumn[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                            </th>
                          </ng-container>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>

                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']>2" role='presentation'>
              <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
              <ng-template ngbNavContent>
                <div id="{{'divcalling_'+formNameData['formAliasName']}}"
                  class=' tab-pane  legendclass' legendsequence="formNameData['formSequence']"
                  legendRequired="formNameData['formIsRequired']" legendName="formNameData['formAliasName']">
                  <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">

                    <!-- {{masterJsonData.formType}} -->
                    <div class="row m-0">
                      <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                        <ng-container
                          *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                          <div *ngIf="formData.controlType == 'dropdown'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],false,false)"
                                (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],false)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label class=" m-0">{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'mdropdown'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],true,false)"
                                (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],true)"
                                (onSelectAll)="onItemSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                (onDeSelectAll)="OnItemDeSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                <c-badge>
                                  <ng-template let-item="item">
                                    <label class=" m-0">{{item.itemName}}</label>
                                  </ng-template>
                                </c-badge>
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <!-- Hidden field case for child tables start -->
                          <div *ngIf="formData.controlType == 'hidden'">

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                              <input type='hidden' id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}">
                            </div>

                          </div>
                          <!-- Hidden field case for child tables End -->
                          <div *ngIf="formData.controlType == 'datetime'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class='{{formData.controlDivClass}} calnder-icon'>
                              <input type='text' class="{{formData.controlClass}}"
                                (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                [bsConfig]="bsConfig" autocomplete="off" readonly
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <div class="calenderinput-icon"><a href="javascript: void(0);"
                                  (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                    aria-hidden="true"></i></a></div>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>

                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'checkbox'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label"
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class="form-check {{formData.controlDivClass}}">

                              <input type='checkbox' value='' class="{{formData.controlClass}}"
                                id="{{formData.controlId}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                              </label>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'textarea'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                </textarea>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>

                              <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                                [FormReset]=formData.ChildComponentReset
                                [childComponentFormSequence]=formData.childComponentFormSequence
                                [childComponentFileDelete]=formData.childComponentFileDelete
                                [uploadedPreviousFile]='formData.previousControlValue'
                                [uploadedFile]=formData.controlValue
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                [controlisDisabled]=formData.isDisabled
                                (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                              </app-upload-file>

                              <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>

                          </div>
                          <div *ngIf="formData.controlType == 'labelauto'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>

                              <input type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'readonly'"
                            class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                readonly [disabled]="true">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                                <app-generic-child-time-picker  showMeridian="true"
                                [timerValue]="formData.controlValue"
                                [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                                [FormReset]=formData.ChildComponentReset
                                [childComponentFormSequence] = formData.childComponentFormSequence
                                [timerControlId] = formData.controlId
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                id="{{'timerControl_'+formData.controlId}}" 
                                [controlisDisabled]=formData.isDisabled ></app-generic-child-time-picker>
          
                                <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
          
          
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                            </div>
          
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                             
                              <app-generic-child-switch-button  
                              [toggleCheckedValue]="formData.controlValue"
                              [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'
                              [FormReset]="formData.ChildComponentReset"
                              [childComponentFormSequence] = formData.childComponentFormSequence
                              [toggleControlId] = formData.controlId
                              (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                              id="{{'switchControl_'+formData.controlId}}" 
                              [controlisDisabled]=formData.isDisabled></app-generic-child-switch-button >
        
                              <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                              formControlName="{{formData.controlName}}"
                              [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
        
        
                            <span class=" invalid-feedback mt-1">
                              {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                            </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>

                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">

                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>

                            </div>

                          </div>
                        </ng-container>

                      </ng-container>

                      <div class='form-group col-sm-12 text-right'>
                        <button type="button" class="btn btn-secondry"
                          (click)="resetChildForm(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                          <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformData(GenericFormGroup(formNameData['formAliasName']))">Test Form</button>
                        <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                          (click)="onAddChildRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                          class="btn btn-primary ml-3">Add</button>
                        <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                          *ngIf="isChildRowEdit"
                          (click)="onAddChildRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                          class="btn btn-primary ml-3">Update Item</button>
                        


                      </div>
                    </div>


                  </form>

                  <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">

                    <table *ngIf="childTableDataDynamicArrayList[formNameData['formAliasName']]?.length>0"
                      [id]="formNameData['formDataTableId']" class='table '>
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                            <th
                              *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                              {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                            </th>
                          </ng-container>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let rowData of childTableDataDynamicArrayList[formNameData['formAliasName']]; let icRow=index;"
                          class='Uiparams'>
                          <td>{{(icRow+1)}}</td>
                          <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                            <ng-container>
                              <td
                                *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                                <div appEllipsifyLongText>
                                  {{rowData[formChildTableRow['columN_NAME']]}}
                                </div>

                              </td>
                            </ng-container>
                            <ng-template #elsedetailTableFile>
                              <ng-container>
                                <td class="file-dowmload"
                                  *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                  <a href="javascript:void(0)" class='active'
                                    [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                    (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                    ngbTooltip="Download item - {{formChildTableRow['toolTipRow']}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue">
                                    <i class='fa fa-download'></i></a>
                                  <a href="javascript:void(0)" class='active'
                                    [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                    (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                    ngbTooltip="Open item - {{formChildTableRow['toolTipRow']}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue">
                                    <i class='fa fa-file-image-o'></i></a>
                                </td>
                              </ng-container>
                            </ng-template>
                          </ng-container>
                          <td>
                            <a href="javascript:void(0)"
                              (click)="editChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                              class='edit opendetail'><i class='fa fa-pencil'></i></a>
                            <a href="javascript:void(0)"
                              (click)="OndeleteChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                              class='delete opendetail'><i class='fa fa-trash'></i></a>

                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                          <th>Total</th>
                          <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                            <th
                              *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                              {{childTableDataDynamicArrayListSumColumn[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                            </th>
                          </ng-container>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>
              </ng-template>
            </li>
          
              </ng-container>
            </ul>
            <div [ngbNavOutlet]="navCreateChild" class="mt-2"></div>
            </div>
          </fieldset>
          <br>
          <div *ngIf="masterJsonData && isRender && addPermission" class="Submitbtn-content">
            <button type="button" class="btn btn-secondry"
              (click)="openResetModal(parentFormAliasName,1,0,2)">Reset</button>
              <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformData(GenericFormGroup(parentFormAliasName))">Test Form</button>   
            <button type="button" id="btnSubmit" [disabled]="!GenericFormGroup(parentFormAliasName).valid"
              (click)="OnSubmitModal(parentFormAliasName)" class="btn btn-primary ml-3">Submit</button>
                   
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
</ng-template>
</li>
<li [ngbNavItem]="2">
  <a ngbNavLink class="nav-link"   (click)="onViewTab();">View Detail</a>
  <ng-template ngbNavContent>
  <div role="tabpanel" class="tab-panel "   id="divViewDataDetail">
    <div [ngStyle]="{'display':displayMainEdit}">
      <!-- <div class="row m-0">
        <div *ngIf="ischeckboxshow > 0 && dropdownListActionGroupSpdrp != undefined && dropdownListActionGroupSpdrp?.length > 0" class="col-xl-6 col-sm-12 col-md-6">
          <angular2-multiselect id="DrpActionGroupSP" [data]="dropdownListActionGroupSpdrp"
            [(ngModel)]="selectedItemsActionGroupSpdrp" [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div> -->
      <!-- <div class="custom-search-input">
        <div class="row m-0">
          <div class="col-xl-6 col-sm-12 col-md-4">
            <div class="pt-2">
              Custom Filter:
            </div>
          </div>
          <div class="col-xl-3 col-sm-12 col-md-4">
            <angular2-multiselect id="DrpColumn" [data]="dropdownListCustomColumnSearchdrp"
              [(ngModel)]="selectedItemsCustomColumnSearchdrp" [settings]="dropdownSettings">
            </angular2-multiselect>
          </div>
          <div class="col-xl-3 col-sm-12 col-md-4">
            <div class="custom-input-box position-relative">
              <input type='search' class="form-control report-input custom-column-serch" [(ngModel)]="customSearch "
                Placeholder="Custom Column Search" id="txtcustomSearch">
              <span class="input-search"><i class="fa fa-search" aria-hidden="true"></i></span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div id="DivtableDataDetail" class="mt-4 inner-content custom-column-serch">
        <table [id]="Detailtableid" datatable="ng" [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail"
          class="table table-bordered row-border hover w-100">
          <thead>
            <tr>
              <th *ngIf="ischeckboxshow > 0 ">
                <input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" />

              </th>
              <th>S.No.</th>
              <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                <th
                  *ngIf="convertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && convertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid'">

                  {{FirstLetterUpper(headerDetailData['columnAliasName'])}}

                </th>
              </ng-container>
              <th class="sorting-none"
                *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 || isWorkflowImplemented == true">
                Action</th>


            </tr>

          </thead>
          <tbody id="EditTableBody">
         
            <tr *ngFor="let rowDetailData of DetailtableRowData; let rowi=index;"
              [ngClass]="rowDetailData['pendingRole']">
              <td *ngIf="ischeckboxshow > 0">
                <input type="checkbox" [(ngModel)]="rowDetailData['isSelected']" (change)="isAllSelected()" />
              </td>
              <td>{{(rowi + 1)}}</td>
              <ng-container *ngFor="let headerDetailData of DetailtableHeaderData; let i=index;">
                <td
                  *ngIf="convertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && convertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && headerDetailData['isViewLink'] == true; else elsedetailTableViewLink">
                  <a href="javascript:void(0)" ngbTooltip="Show Details - {{rowDetailData['toolTipRow']}}"
                    container="wrapper" placement="left" tooltipClass="tooltipBlue"
                    (click)="OnViewFullDetail($event,rowDetailData['id'],'view',rowDetailData['toolTipRow'])">
                    {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}</a>

                </td>
                <ng-template #elsedetailTableViewLink>
                  <ng-container>
                    <td
                      *ngIf="convertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && convertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid'  && convertStringLowerCase(headerDetailData['controlType']) != 'file' && convertStringLowerCase(headerDetailData['controlType']) != 'childtable'; else elsedetailChildTableViewLink">
                      <div appEllipsifyLongText>
                      {{ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData["columN_NAME"])])}}
</div>
                    </td>
                  </ng-container>
                </ng-template>
                <ng-template #elsedetailChildTableViewLink>
                  <ng-container>
                    <td
                      *ngIf="convertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && convertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid'  && convertStringLowerCase(headerDetailData['controlType']) != 'file' && convertStringLowerCase(headerDetailData['controlType']) == 'childtable'; else elsedetailTableFile">

                   

                      <a href="javascript:void(0)" class='active'
                        ngbTooltip="Show {{headerDetailData['columN_NAME']}} Details - {{rowDetailData['toolTipRow']}}"
                        container="wrapper" placement="left" tooltipClass="tooltipBlue"
                        (click)="onChildOpenDetail($event, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),rowDetailData['id'], rowDetailData['toolTipRow'])">
                        <i class='fa fa-info'></i></a>

                    </td>
                  </ng-container>
                </ng-template>
                <ng-template #elsedetailTableFile>
                  <ng-container>
                    <td
                      *ngIf="convertStringLowerCase(headerDetailData['columN_NAME'])  != 'id' && convertStringLowerCase(headerDetailData['columN_NAME'])  != 'guid' && convertStringLowerCase(headerDetailData['controlType']) == 'file'">
                    
                      <a href="javascript:void(0)" class='active'
                        [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                        (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                        ngbTooltip="Download {{headerDetailData['columN_NAME']}} item - {{rowDetailData['toolTipRow']}}"
                        container="wrapper" placement="left" tooltipClass="tooltipBlue">
                        <i class='fa fa-download'></i></a>
                      <a href="javascript:void(0)" class='active'
                        [hidden]="ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]) =='-'"
                        (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowDetailData[FirstLetterLower(headerDetailData['columN_NAME'])]),headerDetailData['columnAliasName'])"
                        ngbTooltip="Open {{headerDetailData['columN_NAME']}} item - {{rowDetailData['toolTipRow']}}"
                        container="wrapper" placement="left" tooltipClass="tooltipBlue">
                        <i class='fa fa-file-image-o'></i></a>
                 
                    </td>
                  </ng-container>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="isWorkflowImplemented == false;else elseWFButtonAdd">
                <td
                  *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 ">

                  <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                    (click)="OnViewFullDetail($event, rowDetailData['id'],'update',rowDetailData['toolTipRow'])"
                    ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                    ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i
                      class='fa fa-pencil'></i></a>

                  <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                    ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                    tooltipClass="tooltipBlue"
                    (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',rowi,rowDetailData['toolTipRow'])"
                    class='active opendetail'><i class='fa fa-check'></i> </a>

                  <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                    ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                    tooltipClass="tooltipBlue"
                    (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',rowi,rowDetailData['toolTipRow'])"
                    class='inactive opendetail'><i class='fa fa-times '></i></a>

                  <a *ngIf="printPermission != 0" href="javascript:void(0)"
                    (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                    ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                    tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-print'></i></a>

                  <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                    (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',rowi, rowDetailData['toolTipRow'])"
                    ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                    tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>
                </td>
              </ng-container>
              <ng-template #elseWFButtonAdd>
                <ng-container *ngIf="isWorkflowImplemented == true">
                  <td
                    *ngIf="editPermission == 'True' || activePermission == 'True' || deletePermission == 'True' || printPermission != 0 || rowDetailData['workFlowImplemented'] == true ">

                    <a *ngIf="editPermission == 'True'" href="javascript:void(0)"
                      (click)="OnViewFullDetail($event, rowDetailData['id'],'update',rowDetailData['toolTipRow'])"
                      ngbTooltip="Edit Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      ngbTooltip="Tooltip on left" tooltipClass="tooltipBlue" class='edit opendetail'><i
                        class='fa fa-pencil'></i></a>

                    <a href="javascript:void(0)" *ngIf="rowDetailData['isActive'] && activePermission == 'True'"
                      ngbTooltip="Active Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'InActive',rowi,rowDetailData['toolTipRow'])"
                      class='active opendetail'><i class='fa fa-check'></i> </a>

                    <a href="javascript:void(0)" *ngIf="!rowDetailData['isActive'] && activePermission == 'True'"
                      ngbTooltip="InActive Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                      placement="left" tooltipClass="tooltipBlue"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'Active',rowi,rowDetailData['toolTipRow'])"
                      class='inactive opendetail'><i class='fa fa-times '></i></a>

                    <a *ngIf="printPermission != 0" href="javascript:void(0)"
                      (click)="OnPrintFullDetail($event, rowDetailData['id'],'update')"
                      ngbTooltip="Print Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue" class='edit opendetail'><i class='fa fa-print'></i></a>

                    <a *ngIf="deletePermission == 'True'" href="javascript:void(0)"
                      (click)="OnMasterFormAction($event,rowDetailData['id'],'Deleted',rowi, rowDetailData['toolTipRow'])"
                      ngbTooltip="Delete Record - {{rowDetailData['toolTipRow']}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue" class='delete opendetail'><i class='fa fa-trash'></i></a>

                    <a *ngIf="convertStringLowerCase(rowDetailData['pendingLevel']) == 'pending' && rowDetailData['workFlowStep'] > 1"
                      href="javascript:void(0)"
                      (click)="OnDynamicGenericWorkFlow($event,rowDetailData['workFlowApprove'],rowDetailData['workFlowReject'],rowDetailData['workFlowReturn'],rowDetailData['workFlowForward'],rowDetailData['id'],rowDetailData['tableName'],rowDetailData['toolTipRow'])"
                      ngbTooltip="Workflow Record - {{rowDetailData['toolTipRow']}}" container="wrapper"
                      placement="left" tooltipClass="tooltipBlue" class='edit opendetail'><i
                        class='fa fa-snowflake-o'></i></a>

                    <a href="javascript:void(0)"
                      (click)="onWorkFlowHistoryOpenDetail($event,rowDetailData['tableName'],rowDetailData['id'],rowDetailData['toolTipRow'])"
                      ngbTooltip="Workflow  Record History - {{rowDetailData['toolTipRow']}}" container="wrapper"
                      placement="left" tooltipClass="tooltipBlue" class='edit opendetail ml-2'><i
                        class='fa fa-history'></i></a>

                  </td>
                </ng-container>
              </ng-template>
            </tr>
          </tbody>

        </table>
      </div> -->
      <app-generic-table-list-view [tbname] ="SeltableID" [formName]="SeltableIDForm" [formType]="2" [editPermission]="editPermission" [printPermission]="printPermission" [isWorkflowImplemented]="isWorkflowImplemented"
      [activePermission]="activePermission" (viewFullDetail)="OnViewFullDetail($event)" 
      [isRenderDetail]="isRenderDetail" [deletePermission]="deletePermission" [ischeckboxshow] ="ischeckboxshow" [dropdownListActionGroupSpdrp]="dropdownListActionGroupSpdrp"></app-generic-table-list-view>
      <!-- <div *ngIf="ischeckboxshow > 0 && dropdownListActionGroupSpdrp  && dropdownListActionGroupSpdrp?.length > 0" class="mt-5 px-3">
        <div class="Submitbtn-content">
          <button type="button" id="btnSendMessage"
            [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
            class="btn btn-primary btn-raised mx-2" (click)="sendMessageArrayList()">Send Message</button>
          <button type="button" id="btnSendMail"
            [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
            class="btn btn-primary btn-raised mx-2" (click)="sendEmailArrayList()">Send Mail</button>
          <button type="button" id="btnAction"
            [disabled]="selectedRows.length === 0 ? true:false || selectedItemsActionGroupSpdrp.length === 0? true:false"
            class="btn btn-primary btn-raised mx-2" (click)="onActionArrayList()">Action</button>
        </div>

      </div>
      <input type="hidden" id="selectHiddenValues" name="selectHiddenValues"> -->

    </div>
  </div>
</ng-template>
</li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <!-- </div> -->
  <!-- start Modals -->
  <div class="backdrop " [ngStyle]="{'display':display}"></div>
  <!-- start EditMaster Modal -->
  <div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content edit-modal-form">

        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left">{{editModelHeading}}</h2>
          <!-- <input type="hidden" id="updateId" name="updateId"> -->
          <button type="button" class="close" aria-label="Close" (click)="onCloseEditMasterModal(2, true)"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div class="inner-content">
            <div id="DivDataEdit" class="table-responsive">
              <div *ngIf="masterJsonDataEdit && isRenderEdit" class="row m-0">
                <ng-container *ngFor="let formNameData of masterJsonDataEdit; let ibase=index">

                  <fieldset class="">
                    <legend>{{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"]) }} </legend>
                    <form *ngIf="isRenderEdit && GenericFormGroupEdit(formNameData['formAliasName'])" class="" novalidate
                      [formGroup]="GenericFormGroupEdit(formNameData['formAliasName'])">

                      <!-- {{masterJsonDataEdit.formType}} -->
                      <div class="row m-0">
                        <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                          <ng-container
                            *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                            <div *ngIf="formData.controlType == 'dropdown'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>
                                <angular2-multiselect id="{{formData.controlId}}"
                                  formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                  [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  (onSelect)="onItemSelectEdit($event,i,false)"
                                  (onDeSelect)="OnItemDeSelectEdit($event,i,false)"
                                  (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'])">
                                  <c-badge>
                                    <ng-template let-item="item">
                                      <label class=" m-0">{{item.itemName}}</label>
                                    </ng-template>
                                  </c-badge>
                                </angular2-multiselect>
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'mdropdown'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>
                                <angular2-multiselect id="{{formData.controlId}}"
                                  formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                  [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  (onSelect)="onItemSelectEdit($event,i,true)"
                                  (onDeSelect)="OnItemDeSelectEdit($event,i,true)"
                                  (onSelectAll)="onItemSelectEditAll($event,i,true)"
                                  (onDeSelectAll)="OnItemDeSelectEditAll($event,i,true)"
                                  (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'])">
                                  <c-badge>
                                    <ng-template let-item="item">
                                      <label class=" m-0">{{item.itemName}}</label>
                                    </ng-template>
                                  </c-badge>
                                </angular2-multiselect>
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'datetime'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class='{{formData.controlDivClass}} calnder-icon'>
                                <input type='text' class="{{formData.controlClass}}"
                                  (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                  [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                  [bsConfig]="bsConfig" autocomplete="off" readonly
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  formControlName="{{formData.controlName}}">
                                <div class="calenderinput-icon"><a href="javascript: void(0);"
                                    (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                      aria-hidden="true"></i></a></div>
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>

                              </div>

                            </div>
                            <div *ngIf="formData.controlType == 'checkbox'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label"
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class="form-check {{formData.controlDivClass}}">

                                <input type='checkbox' value='' class="{{formData.controlClass}}"
                                  id="{{formData.controlId}}"
                                  (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  formControlName="{{formData.controlName}}">
                                <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                  for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                                </label>
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>

                            </div>
                            <div *ngIf="formData.controlType == 'textarea'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>
                                <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                  [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                  formControlName="{{formData.controlName}}"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                </textarea>
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>

                            </div>
                            <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>

                                <app-upload-file [fileValidationstring]=formData.miscellaneousType
                                  [uploadedFile]=formData.controlValue [formName]='formlable'
                                  [FormReset]=formData.ChildComponentReset
                                  [childComponentFormSequence]=formData.childComponentFormSequence
                                  [childComponentFileDelete]=formData.childComponentFileDelete
                                  [uploadedPreviousFile]='formData.previousControlValue'
                                  (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                                  id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                  [controlisDisabled]=formData.isDisabled
                                  (upFile)='upFile($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'>
                                </app-upload-file>


                                <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                  formControlName="{{formData.controlName}}"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>

                            </div>
                            <div *ngIf="formData.controlType == 'labelauto'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>

                                <input type='text' class="{{formData.controlClass}}"
                                  [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                  formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'readonly'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>
                                <input type='text' class="{{formData.controlClass}}"
                                  [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                  formControlName="{{formData.controlName}}"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  readonly [disabled]="true">
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                  ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                  tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                              </div>
            
                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                                  <app-generic-child-time-picker  showMeridian="true"
                                  [timerValue]="formData.controlValue"
                                  [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                  [FormReset]=formData.ChildComponentReset
                                  [childComponentFormSequence] = formData.childComponentFormSequence
                                  [timerControlId] = formData.controlId
                                  (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                                  id="{{'timerControl_'+formData.controlId}}" 
                                  [controlisDisabled]=formData.isDisabled ></app-generic-child-time-picker>
            
                                  <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                  formControlName="{{formData.controlName}}"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
            
            
                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                  ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                  tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                              </div>
            
                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                               
                                <app-generic-child-switch-button  
                                [toggleCheckedValue]="formData.controlValue"
                                [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                [FormReset]="formData.ChildComponentReset"
                                [childComponentFormSequence] = formData.childComponentFormSequence
                                [toggleControlId] = formData.controlId
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                                id="{{'switchControl_'+formData.controlId}}" 
                                [controlisDisabled]=formData.isDisabled ></app-generic-child-switch-button>
          
                                <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
          
          
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                              </div>
                            </div>
                            <div *ngIf="formData.controlType == 'textbox'"
                              class="form-group {{formData.pageStyleclass}}">
                              <div>
                                <label id="{{formData.spanId}}" class="col-form-label "
                                  [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                  for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                  class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                  placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                    aria-hidden="true"></i></span>
                              </div>

                              <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                class={{formData.controlDivClass}}>
                                <input type='text' class="{{formData.controlClass}}"
                                  (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                  [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                  [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                  formControlName="{{formData.controlName}}">

                                <span class=" invalid-feedback mt-1">
                                  {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                </span>

                              </div>

                            </div>
                          </ng-container>

                        </ng-container>

                      </div>

                    </form>
                  </fieldset>
                </ng-container>
                <fieldset *ngIf="childJsonDataEdit && childJsonDataEdit?.length > 0" class="mt-4">
                  <!-- <ul class='nav nav-tabs master-form-tab mb-4' role='tablist'>
                    <ng-container *ngFor="let tabData of childJsonDataEdit; let iTab=index">
                      <li *ngIf="+tabData['formSequence']==2" role='presentation' class='active'><a
                          class='nav-link active' id="{{'LinkAdd_'+tabData['formAliasName']}}"
                          href="{{'#Edivcalling_'+tabData['formAliasName']}}"
                          data-toggle='tab'>{{commonfunction.SpaceAfterCapitalLetter(tabData["formAliasName"])}} </a>
                      </li>
                      <li *ngIf="+tabData['formSequence']>2" role='presentation'><a class='nav-link '
                          id="{{'LinkAdd_'+tabData['formAliasName']}}"
                          href="{{'#Edivcalling_'+tabData['formAliasName']}}"
                          data-toggle='tab'>{{commonfunction.SpaceAfterCapitalLetter(tabData["formAliasName"])}} </a>
                      </li>
                    </ng-container>
                  </ul> -->
                  <div class='tab-content'>
                    <ul ngbNav #navEditChild="ngbNav" [(activeId)]="activeTabIdEdit" role='tablist' class="nav-tabs master-form-tab border-0 mb-2">
                    <ng-container *ngFor="let formNameData of childJsonDataEdit; let ichild=index">
                      <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']==2" role='presentation' >
                        <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
                        <ng-template ngbNavContent>
                      <div id="{{'Edivcalling_'+formNameData['formAliasName']}}" class='tab-pane  active legendclass'
                        legendsequence="formNameData['formSequence']" legendRequired="formNameData['formIsRequired']"
                        legendName="formNameData['formAliasName']">
                        <form *ngIf="isRenderEdit && editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1' && GenericFormGroupEdit(formNameData['formAliasName'])" class="" novalidate
                          [formGroup]="GenericFormGroupEdit(formNameData['formAliasName'])">

                          <!-- {{masterJsonDataEdit.formType}} -->
                          <div class="row m-0">
                            <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                              <ng-container
                                *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                                <div *ngIf="formData.controlType == 'dropdown'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <angular2-multiselect id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                      [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      (onSelect)="onItemSelectChildEdit($event,i,+formNameData['formSequence'],false,false)"
                                      (onDeSelect)="OnItemDeSelectChildEdit($event,i,+formNameData['formSequence'],false)"
                                      (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'] )">
                                      <c-badge>
                                        <ng-template let-item="item">
                                          <label class=" m-0">{{item.itemName}}</label>
                                        </ng-template>
                                      </c-badge>
                                    </angular2-multiselect>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'mdropdown'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <angular2-multiselect id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                      [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      (onSelect)="onItemSelectChildEdit($event,i,+formNameData['formSequence'],true,false)"
                                      (onDeSelect)="OnItemDeSelectChildEdit($event,i,+formNameData['formSequence'],true)"
                                      (onSelectAll)="onItemSelectChildEditAll($event,i,+formNameData['formSequence'],true)"
                                      (onDeSelectAll)="OnItemDeSelectChildEditAll($event,i,+formNameData['formSequence'],true)"
                                      (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'] )">
                                      <c-badge>
                                        <ng-template let-item="item">
                                          <label class=" m-0">{{item.itemName}}</label>
                                        </ng-template>
                                      </c-badge>
                                    </angular2-multiselect>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <!-- Hidden field case for child tables start -->
                                <div *ngIf="formData.controlType == 'hidden'">

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                                    <input type='hidden' id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}">
                                  </div>

                                </div>
                                <!-- Hidden field case for child tables End -->
                                <div *ngIf="formData.controlType == 'datetime'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class='{{formData.controlDivClass}} calnder-icon'>
                                    <input type='text' class="{{formData.controlClass}}"
                                      (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                      [bsConfig]="bsConfig" autocomplete="off" readonly
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">
                                    <div class="calenderinput-icon"><a href="javascript: void(0);"
                                        (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                          aria-hidden="true"></i></a></div>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>

                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'checkbox'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label"
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class="form-check {{formData.controlDivClass}}">

                                    <input type='checkbox' value='' class="{{formData.controlClass}}"
                                      id="{{formData.controlId}}"
                                      (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">
                                    <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                      for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                                    </label>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'textarea'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                      </textarea>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'file'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>

                                    <app-upload-file [fileValidationstring]=formData.miscellaneousType
                                      [uploadedFile]=formData.controlValue [formName]='formlable'
                                      [FormReset]=formData.ChildComponentReset
                                      [childComponentFormSequence]=formData.childComponentFormSequence
                                      [childComponentFileDelete]=formData.childComponentFileDelete
                                      [uploadedPreviousFile]='formData.previousControlValue'
                                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                      id="{{'fileControl_'+formData.controlId}}"
                                      [fileColumnName]=formData.columnAliasName [controlisDisabled]=formData.isDisabled
                                      (upFile)='upFile($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'>
                                    </app-upload-file>

                                    <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'labelauto'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>

                                    <input type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'readonly'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <input type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      readonly [disabled]="true">
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                  </div>
                
                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                                      <app-generic-child-time-picker  showMeridian="true"
                                      [childComponentFormSequence] = formData.childComponentFormSequence
                                      [timerValue]="formData.controlValue"
                                      [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                      [FormReset]=formData.ChildComponentReset
                                      [timerControlId] = formData.controlId                                    
                                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                      id="{{'timerControl_'+formData.controlId}}" 
                                      [controlisDisabled]=formData.isDisabled ></app-generic-child-time-picker>
                
                                      <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                
                
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                  </div>
                
                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                                   
                                    <app-generic-child-switch-button  
                                    [toggleCheckedValue]="formData.controlValue"
                                    [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                    [FormReset]="formData.ChildComponentReset"
                                    [childComponentFormSequence] = formData.childComponentFormSequence
                                    [toggleControlId] = formData.controlId
                                    (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                    id="{{'switchControl_'+formData.controlId}}" 
                                    [controlisDisabled]=formData.isDisabled ></app-generic-child-switch-button>
              
                                    <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
              
              
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'textbox'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <input type='text' class="{{formData.controlClass}}"
                                      (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">

                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>

                                  </div>

                                </div>

                              </ng-container>

                            </ng-container>

                            <div class='form-group col-sm-12  text-right'>
                              <button type="button" class="btn btn-secondry"
                                (click)="resetChildFormEdit(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                                <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformData(GenericFormGroupEdit(formNameData['formAliasName']))">Test Form</button>
                              <button type="button"
                                [disabled]="!GenericFormGroupEdit(formNameData['formAliasName']).valid"
                                *ngIf="!isChildRowEdit"
                                (click)="onAddChildRowEdit($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                                class="btn btn-primary ml-3">Add</button>
                              <button type="button"
                                [disabled]="!GenericFormGroupEdit(formNameData['formAliasName']).valid"
                                *ngIf="isChildRowEdit"
                                (click)="onAddChildRowEdit($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                                class="btn btn-primary ml-3">Update Item</button>                             

                            </div>
                          </div>


                        </form>

                        <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">

                          <table *ngIf="childTableDataDynamicArrayListEdit[formNameData['formAliasName']]?.length>0"
                            [id]="formNameData['formDataTableId']" class='table '>
                            <thead>
                              <tr>
                                <th>S.no</th>
                                <ng-container
                                  *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                  <th
                                    *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                    {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                                  </th>
                                </ng-container>
                                <th class="text-center" *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let rowData of childTableDataDynamicArrayListEdit[formNameData['formAliasName']]; let icRow=index;"
                                class='Uiparams'>
                                <td>{{(icRow+1)}}</td>

                                <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                  <ng-container
                                    *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                                    <td>
                                      <div appEllipsifyLongText>
                                        {{rowData[formChildTableRow['columN_NAME']]}}
                                      </div>

                                    </td>
                                  </ng-container>
                                  <ng-template #elsedetailTableFile>
                                    <ng-container
                                      *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                      <td class="file-dowmload">
                                        <a href="javascript:void(0)" class='active'
                                          [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                          (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                          ngbTooltip="Download {{formChildTableRow['columnAliasName']}}"
                                          container="wrapper" placement="left" tooltipClass="tooltipBlue">
                                          <i class='fa fa-download'></i></a>
                                        <a href="javascript:void(0)" class='active'
                                          [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                          (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                          ngbTooltip="Open {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                          placement="left" tooltipClass="tooltipBlue">
                                          <i class='fa fa-file-image-o'></i></a>
                                      </td>
                                    </ng-container>
                                  </ng-template>
                                </ng-container>


                                <!-- <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                  <td  *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid'">
                                    {{rowData[formChildTableRow['columN_NAME']]}}
                                   
                                  </td> 
                                 
                                  </ng-container>-->
                                <td class="text-center" *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'">
                                  <a href="javascript:void(0)"
                                    (click)="editChildTableRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                    class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                  <a href="javascript:void(0)" *ngIf="editModelCallfrom == 'update'"
                                    (click)="OndeleteChildTableRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                    class='delete opendetail'><i class='fa fa-trash'></i></a>

                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                                <th>Total</th>
                                <ng-container
                                  *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                  <th
                                    *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                    {{childTableDataDynamicArrayListSumColumnEdit[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                                  </th>
                                </ng-container>
                                <th *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'"></th>
                              </tr>
                            </tfoot>
                          </table>
                          <div *ngIf="childTableDataDynamicArrayListEdit[formNameData['formAliasName']]?.length == 0">
                            No Data Available
                          </div>
                        </div>

                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']>2" role='presentation' >
                    <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
                    <ng-template ngbNavContent>
                      <div id="{{'Edivcalling_'+formNameData['formAliasName']}}"
                        class=' tab-pane  legendclass' legendsequence="formNameData['formSequence']"
                        legendRequired="formNameData['formIsRequired']" legendName="formNameData['formAliasName']">
                        <form *ngIf="isRenderEdit && editModelCallfrom == 'update'  && formNameData['formIsChildEditPermission'] == '1' && GenericFormGroupEdit(formNameData['formAliasName'])" class="mx-2" novalidate
                          [formGroup]="GenericFormGroupEdit(formNameData['formAliasName'])">

                          <!-- {{masterJsonDataEdit.formType}} -->
                          <div class="row">
                            <ng-container *ngFor="let formData of formNameData['formData']; let i=index">

                              <ng-container
                                *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                                <div *ngIf="formData.controlType == 'dropdown'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <angular2-multiselect id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                      [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      (onSelect)="onItemSelectChildEdit($event,i,+formNameData['formSequence'],false,false)"
                                      (onDeSelect)="OnItemDeSelectChildEdit($event,i,+formNameData['formSequence'],false)"
                                      (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'] )">
                                      <c-badge>
                                        <ng-template let-item="item">
                                          <label class=" m-0">{{item.itemName}}</label>
                                        </ng-template>
                                      </c-badge>
                                    </angular2-multiselect>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'mdropdown'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <angular2-multiselect id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                      [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      (onSelect)="onItemSelectChildEdit($event,i,+formNameData['formSequence'],true,false)"
                                      (onDeSelect)="OnItemDeSelectChildEdit($event,i,+formNameData['formSequence'],true)"
                                      (onSelectAll)="onItemSelectChildEditAll($event,i,+formNameData['formSequence'],true)"
                                      (onDeSelectAll)="OnItemDeSelectChildEditAll($event,i,+formNameData['formSequence'],true)"
                                      (onClose)="OnCloseEdit($event,formData.controlName,formNameData['formAliasName'] )">
                                      <c-badge>
                                        <ng-template let-item="item">
                                          <label class=" m-0">{{item.itemName}}</label>
                                        </ng-template>
                                      </c-badge>
                                    </angular2-multiselect>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <!-- Hidden field case for child tables start -->
                                <div *ngIf="formData.controlType == 'hidden'">

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                                    <input type='hidden' id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}">
                                  </div>

                                </div>
                                <!-- Hidden field case for child tables End -->
                                <div *ngIf="formData.controlType == 'datetime'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class='{{formData.controlDivClass}} calnder-icon'>
                                    <input type='text' class="{{formData.controlClass}}"
                                      (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                      [bsConfig]="bsConfig" autocomplete="off" readonly
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">
                                    <div class="calenderinput-icon"><a href="javascript: void(0);"
                                        (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                          aria-hidden="true"></i></a></div>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>

                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'checkbox'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label"
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class="form-check {{formData.controlDivClass}}">

                                    <input type='checkbox' value='' class="{{formData.controlClass}}"
                                      id="{{formData.controlId}}"
                                      (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">
                                    <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                      for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                                    </label>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'textarea'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                        </textarea>
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'file'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>

                                    <app-upload-file [fileValidationstring]=formData.miscellaneousType
                                      [uploadedFile]=formData.controlValue [formName]='formlable'
                                      [FormReset]=formData.ChildComponentReset
                                      [childComponentFormSequence]=formData.childComponentFormSequence
                                      [childComponentFileDelete]=formData.childComponentFileDelete
                                      [uploadedPreviousFile]='formData.previousControlValue'
                                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                      id="{{'fileControl_'+formData.controlId}}"
                                      [fileColumnName]=formData.columnAliasName [controlisDisabled]=formData.isDisabled
                                      (upFile)='upFile($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'>
                                    </app-upload-file>

                                    <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">

                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>

                                </div>
                                <div *ngIf="formData.controlType == 'labelauto'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>

                                    <input type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'readonly'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <input type='text' class="{{formData.controlClass}}"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      readonly [disabled]="true">
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                  </div>
                
                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                          
                                      <app-generic-child-time-picker  showMeridian="true" class=""
                                      [timerValue]="formData.controlValue"
                                      [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                      [FormReset]=formData.ChildComponentReset
                                      [childComponentFormSequence] = formData.childComponentFormSequence
                                      [timerControlId] = formData.controlId
                                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                      id="{{'timerControl_'+formData.controlId}}" 
                                      [controlisDisabled]=formData.isDisabled ></app-generic-child-time-picker>
                
                                      <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                      formControlName="{{formData.controlName}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                
                
                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                                  </div>
                
                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                                   
                                    <app-generic-child-switch-button  
                                    [toggleCheckedValue]="formData.controlValue"
                                    [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"E",formData.controlName,formNameData.formAliasName)'
                                    [FormReset]="formData.ChildComponentReset"
                                    [childComponentFormSequence] = formData.childComponentFormSequence
                                    [toggleControlId] = formData.controlId
                                    (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,formNameData.formAliasName, ichild,'ichild',i)"
                                    id="{{'switchControl_'+formData.controlId}}" 
                                    [controlisDisabled]=formData.isDisabled ></app-generic-child-switch-button>
              
                                    <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }">
              
              
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                  </div>
                                </div>
                                <div *ngIf="formData.controlType == 'textbox'"
                                  class="form-group {{formData.pageStyleclass}}">
                                  <div>
                                    <label id="{{formData.spanId}}" class="col-form-label "
                                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                      class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                      placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                        aria-hidden="true"></i></span>
                                  </div>

                                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                    class={{formData.controlDivClass}}>
                                    <input type='text' class="{{formData.controlClass}}"
                                      (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],true)"
                                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                      [ngClass]="{'is-invalid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                      formControlName="{{formData.controlName}}">

                                    <span class=" invalid-feedback mt-1">
                                      {{GenericFormGroupEdit(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                    </span>

                                  </div>

                                </div>
                              </ng-container>

                            </ng-container>

                            <div class='form-group col-sm-12 text-right '>
                              
                              <button type="button" class="btn btn-secondry"
                                (click)="resetChildFormEdit(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                                <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformData(GenericFormGroupEdit(formNameData['formAliasName']))">Test Form</button>
                              <button type="button"
                                [disabled]="!GenericFormGroupEdit(formNameData['formAliasName']).valid"
                                *ngIf="!isChildRowEdit"
                                (click)="onAddChildRowEdit($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                                class="btn btn-primary ml-3">Add</button>
                              <button type="button"
                                [disabled]="!GenericFormGroupEdit(formNameData['formAliasName']).valid"
                                *ngIf="isChildRowEdit"
                                (click)="onAddChildRowEdit($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                                class="btn btn-primary ml-3">Update Item</button>

                            </div>
                          </div>


                        </form>

                        <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">

                          <table *ngIf="childTableDataDynamicArrayListEdit[formNameData['formAliasName']]?.length>0"
                            [id]="formNameData['formDataTableId']" class='table  '>
                            <thead>
                              <tr>
                                <th>S.no</th>
                                <ng-container
                                  *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                  <th
                                    *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                    {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                                  </th>
                                </ng-container>
                                <th *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let rowData of childTableDataDynamicArrayListEdit[formNameData['formAliasName']]; let icRow=index;"
                                class='Uiparams'>
                                <td>{{(icRow+1)}}</td>

                                <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                  <ng-container
                                    *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                                    <td>
                                      <div appEllipsifyLongText>
                                        {{rowData[formChildTableRow['columN_NAME']]}}
                                      </div>

                                    </td>
                                  </ng-container>
                                  <ng-template #elsedetailTableFile>
                                    <ng-container
                                      *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                      <td class="file-dowmload">
                                        <a href="javascript:void(0)" class='active'
                                          [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                          (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                          ngbTooltip="Download {{formChildTableRow['columnAliasName']}}"
                                          container="wrapper" placement="left" tooltipClass="tooltipBlue">
                                          <i class='fa fa-download'></i></a>
                                        <a href="javascript:void(0)" class='active'
                                          [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                          (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                          ngbTooltip="Open {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                          placement="left" tooltipClass="tooltipBlue">
                                          <i class='fa fa-file-image-o'></i></a>
                                      </td>
                                    </ng-container>
                                  </ng-template>
                                </ng-container>



                                <!-- <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                    <td  *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid'">
                                      {{rowData[formChildTableRow['columN_NAME']]}}
                                     
                                    </td>                                   
                                    </ng-container> -->
                                <td *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'">
                                  <a href="javascript:void(0)" class='active' *ngIf="editModelCallfrom == 'update'"
                                    (click)="editChildTableRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                    class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                  <a href="javascript:void(0)" *ngIf="editModelCallfrom == 'update'" class='active'
                                    (click)="OndeleteChildTableRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                    class='delete opendetail'><i class='fa fa-trash'></i></a>

                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                                <th>Total</th>
                                <ng-container
                                  *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                  <th
                                    *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                    {{childTableDataDynamicArrayListSumColumnEdit[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                                  </th>
                                </ng-container>
                                <th *ngIf="editModelCallfrom == 'update' && formNameData['formIsChildEditPermission'] == '1'"></th>
                              </tr>
                            </tfoot>
                          </table>
                          <div *ngIf="childTableDataDynamicArrayListEdit[formNameData['formAliasName']]?.length == 0">
                            No Data Available
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                
                    </ng-container>
                  </ul>
                  <div [ngbNavOutlet]="navEditChild" class="mt-2"></div>
                  </div>
                </fieldset>
                <br>
                <!-- <div *ngIf="masterJsonDataEdit && isRender"  class="Submitbtn-content">
                  <button type="button" id="btnSubmit" [disabled]="!GenericFormGroupEdit(parentFormAliasName).valid" (click)="OnSubmitModal(parentFormAliasName)"
                    class="btn btn-primary btn-raised">Submit</button>
                    <button type="button" class="btn btn-secondary btn-raised" (click)="testformData()">testform</button>
                  <button type="button" class="btn btn-secondary btn-raised" (click)="resetMasterForm(parentFormAliasName,1,0)">Reset</button>
                </div> -->
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div *ngIf="masterJsonDataEdit && isRenderEdit" class="Submitbtn-content">
            <button type="button" class="btn btn-secondry" (click)="onCloseEditMasterModal(2,true)">Close</button>
            <button type="button" class="btn btn-primary ml-3"
              [disabled]="!GenericFormGroupEdit(parentFormAliasNameEdit).valid" *ngIf="editModelCallfrom == 'update'"
              (click)="updateEditMasterForm(parentFormAliasNameEdit)">Update</button>
            <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformDataEdit(GenericFormGroupEdit(parentFormAliasNameEdit))">Test Form</button>
          </div>

        </div>

      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End Edit  Modal -->


  <!-- End Modals -->
  <div id="printContaint" *ngIf="GenericPrintDataArray">
    <app-generic-print [GenericPrintDataArray]="GenericPrintDataArray" style="display: none;"></app-generic-print>
  </div>

</div>