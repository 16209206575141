import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { ActivationStart, Router } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Customvalidation } from "../../shared/Validation";
import { dynamicformvalues } from "../../shared/object-param.model";
import { Commonfunction } from "../../shared/commonutility";
import { GenericFormDropDownServiceService } from "../../shared/generic-form-drop-down-service.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map, pairwise } from 'rxjs';
import { RedirectComponent } from '../../default/redirect-component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidelogin',
  templateUrl: './sidelogin.component.html',
  styleUrls: ['./sidelogin.component.css']
})
export class SideLoginComponent implements OnInit {
  userData: any;
  userData1: any;
  TokenExpire: any;
  TokenIssued: any;
  
  CurrentRole: any;
  imageUrl: string = "assets/images/user-profile.png";

  
  MenuItems: any;
  SubMenuItems: any;
  MenuItemssub: any;
  SubMenuItemssub: any;
  tooltiptext: string = "hide sidebar!";
  param: any;
  RoleList: any;
  isRole = false;
  currentRole : string="";
  constructor(private userService: UserService, private router: Router, public Customvalidation: Customvalidation, private commonfunction: Commonfunction,
     private genericDropDown:GenericFormDropDownServiceService,  private spinner: NgxSpinnerService, 
     private loction: Location) { 
      router.events
      .pipe(
        filter((event:any) => event instanceof ActivationStart),
        map((x:any) => x as ActivationStart),
        pairwise()
      )
      .subscribe(([p, c]) => {
        if (
          p.snapshot.component === c.snapshot.component &&
          JSON.stringify(p.snapshot.params) !== JSON.stringify(c.snapshot.params)
        ) {
          const dPath = c.snapshot.url.map((x:any) => x.path);
          RedirectComponent.navigateTo(this.router).then(() =>
            this.router.navigate(dPath)
          );
        }
      });

     }

     ngOnInit() {
      let userInfo = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('UserInfo')) ? sessionStorage.getItem("UserInfo")?.toString():null;
      let CurrentUserMenu = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentUserMenu')) ? sessionStorage.getItem("CurrentUserMenu")?.toString():null;
      let CurrentUserMenusub = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentUserMenusub')) ? sessionStorage.getItem("CurrentUserMenusub")?.toString():null;
      let CurrentUserSubMenu = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentUserSubMenu')) ? sessionStorage.getItem("CurrentUserSubMenu")?.toString():null;
      let CurrentUserSubMenusub = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('CurrentUserSubMenusub')) ? sessionStorage.getItem("CurrentUserSubMenusub")?.toString():null;
      let currentRole = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole')) ? sessionStorage.getItem("currentRole")?.toString():null;
      let userRole = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('userRole')) ? sessionStorage.getItem("userRole")?.toString():null;
   
      this.userData = userInfo!=null ? JSON.parse(userInfo) : null; 
      this.TokenIssued = sessionStorage.getItem("issued");
      this.TokenExpire = sessionStorage.getItem("expires");
      if (currentRole?.toString() != '') {
        this.isRole = true;
        this.MenuItems = CurrentUserMenu ? JSON.parse(CurrentUserMenu):null;
        this.MenuItemssub = CurrentUserMenusub ? JSON.parse(CurrentUserMenusub) : null;
        this.SubMenuItems = CurrentUserSubMenu ? JSON.parse(CurrentUserSubMenu) : null;
        this.SubMenuItemssub = CurrentUserSubMenusub ? JSON.parse(CurrentUserSubMenusub): null;
        this.RoleList = userRole ? JSON.parse(userRole) : null;
        this.currentRole = currentRole ? JSON.parse(currentRole)?.rolDes : null;
        console.log('changeRole--'+this.currentRole );
      }
      else {
        
        this.isRole = false;
      }
      // console.log(this.RoleList.Roles);
      //  console.log(JSON.parse(sessionStorage.getItem("CurrentUserMenu")));
      // console.log(sessionStorage.getItem("CurrentUserMenu"));
      // this.userService.getUserClaims( sessionStorage.getItem('userId'),'header').subscribe((datacom : any)=>{
  
      //   this.userData = datacom.table[0];
      //   sessionStorage.setItem("UserInfo",JSON.stringify(datacom.table[0]));
      //   console.log(JSON.stringify(datacom.table[0]));
      //   this.TokenIssued = sessionStorage.getItem("issued");
      //   this.TokenExpire = sessionStorage.getItem("expires");
  
      // },
      // (err : HttpErrorResponse)=>{
      //  //console.log(err.message);
      // this.Customvalidation.loginroute(err.status);
      // });
    }
    Action(obj:any, menu:any,subMenu:any, page:any, form:any, formvale:any) {
      let objList = <dynamicformvalues>{};
      objList.formName = form;
      objList.formValue = formvale;
      objList.menu = menu;
      objList.subMenu = subMenu;
  
      this.userService.Setdynamicformparam(JSON.stringify(objList));
      if (!sessionStorage["menuItem"]) {
        sessionStorage.setItem("menuItem", JSON.stringify(objList));
      }
      else {
        sessionStorage.removeItem('menuItem');
        sessionStorage.setItem("menuItem", JSON.stringify(objList));
      }
      
     
      // this.router.navigate(['/home'])
      // .then(()=>{this.router.navigate(['/'+page])});
      if(page.toString() == "GenericMasterForm" || page.toString() == "GenericMultiForm" || page.toString() == "GenericReportForm" || page.toString() == "GenericListForm"){
      //  let url: string = this.router.createUrlTree(['/'+page, this.commonfunction.ReplaceStringSpace(form) ]).toString();
      //   this.loction.go('http://localhost:4200/'+url);
      this.router.navigate(['/redirect'])
      .then(()=>{this.router.navigate([`/${page}/${ this.commonfunction.ReplaceStringSpace(form)}`])});
      // this.router.navigate([`/${page}/${ this.commonfunction.ReplaceStringSpace(form)}`]);
      }
      else
      {
         this.router.navigate(['/redirect'])
      .then(()=>{this.router.navigate(['/'+page])});
      // this.router.navigate(['/'+page]);
      }
    
    }
  
    changeRole(obj:any, roleId:any, roleDes:any) {
   
      this.spinner.show();
      
      sessionStorage.removeItem('currentRole');
      sessionStorage.setItem('currentRole', '{"roleId":"' + roleId + '","rolDes":"' + roleDes + '"}');
      //let currentRole = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole')) ? sessionStorage.getItem("currentRole")?.toString():null;
      this.currentRole = this.commonfunction.getCurrentUserRoleId();
      
      console.log('changeRole--'+this.currentRole );
      // console.log(JSON.stringify(datacomget.table[0]));
      this.router.navigate(['/Landing']);
  
    }

    openNav()
    {
      this.userService.ststusNav =!this.userService.ststusNav;
      $(".dataTables_scrollHeadInner").toggleClass("show-toggle-table"); // class toggle for all datatables on all screens.
      this.tooltiptext = "show sidebar!"
    }
}