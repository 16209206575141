import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fooer-page-model',
  templateUrl: './fooer-page-model.component.html',
  styleUrls: ['./fooer-page-model.component.css']
})
export class FooerPageModelComponent implements OnInit {
  footerlink:any;
  updateEditMasterForm!:FormGroup;
  constructor(private activeModel:NgbActiveModal) { }

  ngOnInit(): void {
    this.updateEditMasterForm =new FormGroup({
      link:new FormControl(this.footerlink.socialLink,[Validators.required])
    })
  }

  onCloseConfirmModal(){
    this.activeModel.close()
  }

  OnClickSubmit(){
    this.footerlink.socialLink = this.updateEditMasterForm.get("link")?.value;
    this.activeModel.close(true)
  }

}
