import { Component, OnInit,  Input,  EventEmitter,  Output,  forwardRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import {  NG_VALUE_ACCESSOR,  NG_VALIDATORS,  ControlValueAccessor,  Validator,  FormControl} from "@angular/forms";
import { IfileValidationType, IfileTypeOutput, IfileType ,filetypeEnum} from '../model/fileType';
@Component({
  selector: 'app-generic-child-time-picker',
  templateUrl: './generic-child-time-picker.component.html',
  styleUrls: ['./generic-child-time-picker.component.css'],
  providers: [
  
    DatePipe
  ]
})
export class GenericChildTimePickerComponent implements ControlValueAccessor, Validator {
  childRestCounter = 0;
  @Input() fileValidationstring?: string |null |undefined;
  @Input() formName: string ='';
  @Input() timerControlId: string  ='';
  @Input() 
  set controlisDisabled(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
      if(target?.toString() == "disabled"){
        this.disabled = true;
      }
      else{
        this.disabled = false;
      }
      
    }
    else{

      this.disabled = false;
    }
  }
  @Input() childComponentFormSequence?: string |null |undefined;
  @Output() FormResetEvent = new EventEmitter();
  @Output() timeChanged = new EventEmitter<string>();
  @Input() 
  set FormReset(target: filetypeEnum){
    
    if(target !== filetypeEnum.No)
    {
    this.value = "";
    this.timePickerVal = "";
  let rvalue = this.childRestCounter == 0 ? 1 : 0;
  setTimeout(() => {
    this.FormResetEvent.emit(filetypeEnum.No);}, 0);
    }
  }
  _timerValue: string = "";
  @Input() 
  set timerValue(target: string |null |undefined){
    if(target != "" && target != null && target != undefined){
     
    let DateTimeGenerated = this.formatTimeToDateTime(target);
     this.value = DateTimeGenerated;
     this.timePickerVal = DateTimeGenerated;
     this.onisValidate(true);
     this.changedTime();
   
  }
    

  }

  get timerValue(){
    return this._timerValue ;
  }
  @Input("label") label: string = "";
  isShowMeridian: boolean = true;

  
  @Input("placeholder") placeholder: string = "";
  @Input("readonly") readonly: boolean = false;
  @Input("required") required: boolean = false;
  @Input() disabled = false;
  isValid: boolean = true;
  timePickerVal: any = null;
  @Input("value") val: string = "";
  onTouched: any = () => {};
  onChange = (_: any) => {};
  constructor(private _datePipe: DatePipe) { }

  // ngOnInit(): void {
  //   if(this.val == "")
  //   this.val = "12:00 AM"
  // }

  public writeValue(v: any) {
    if (v && v != "") {
      this.value = v;
      this.formatTimeToDateTime(v);
    }
  }
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public validate(c: FormControl) {
    return !this.isValid
      ? null
      : {
          invalidTime: {
            valid: false
          }
        };
  }
  get value() {
    return this.val;
  }
  set value(val) {
    this.val = val;
    this._timerValue = this.val;
    this.onChange(val);
    this.onTouched();
    this.timeChanged.emit(this._timerValue);
  }

  public registerOnTouched(fn:any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  changedTime() {
    this.value = this._datePipe.transform(this.timePickerVal, "hh:mm aa") || "";
  }

  formatTimeToDateTime(time: string = "") {
    if (time && time.trim() == "") return "";
    let currentDateStr = `${this._datePipe.transform(
      new Date(),
      "yyyy-MM-dd"
    )}T`;
    this.timePickerVal = new Date(
      this.convert12HourTo24Hour(currentDateStr, time)
    );
    return this.timePickerVal ;
  }
  convert12HourTo24Hour(date: string, time: string) {
    let concateSS = `${time.replace(" ", ":00")}`;
    let h24 ="";
    let m24 ="";
    let sec24 = "";
    const splittedDate = concateSS.split(":");
    const h = parseInt(splittedDate[0], 10);
    const m = parseInt(splittedDate[1], 10);
    const sec = parseInt(splittedDate[2][0] + splittedDate[2][1], 10);
    const meridiem = splittedDate[2][2] + splittedDate[2][3];
    if (meridiem === "AM") {
      if (h === 12) {
        h24 = "00";
      } else {
        h24 = h.toString();
        if (+h24 < 10) {
          h24 = "0" + h24;
        }
      }
      m24 = m.toString();
      sec24 = sec.toString();
    } else if (meridiem === "PM") {
      if (h === 12) {
        h24 = h.toString();
      } else {
        let toH = h + 12;
        h24 = toH.toString();
        if (+h24 < 10) {
          h24 = "0" + h24;
        }
      }
      m24 = m.toString();
      sec24 = sec.toString();
    }
    if (+m24 < 10) {
      m24 = "0" + m24;
    }
    if (+sec24 < 10) {
      sec24 = "0" + sec24;
    }
    return `${date}${h24}:${m24}:${sec24}`;
  }

  onisValidate(event:any){
    this.isValid = event;
  }
}
