<ul class="nav nav-tabs" role="tablist">
  <li role="presentation" class="active"><a class="nav-link active" id="LinkAdd" href="#divAddNew"
      (click)="onMainTab();" data-toggle="tab">Add New Detail</a></li>
  <li role="presentation"> <a class="nav-link" id="LinkView" href="#divView" (click)="onViewTab();"
      data-toggle="tab">View Detail</a></li>


</ul>

<!-- Tab panes -->
<div class="tab-content">
  <div class="container col-xl-12" id="divContainer">

  </div>
  <div role="tabpanel" class="container tab-pane inner-content  active" id="divAddNew">
    <input type="hidden" id="SeltableIDForm" name="SeltableIDForm">
    <input type="hidden" id="SeltableID" name="SeltableID">
    <div class="row ">
      <div class=" col-lg-6">
        <input id="txtWorkFlowName" type="text" class="form-control" placeholder="Please Enter Work Flow Name">
      </div>
    </div>
    <br>
    <div class="row ">
      <div class=" col-lg-12 table-responsive">
        <table id="wfTable" class="table   table-striped table-hover">
          <thead class="thead-dark">
            <tr>
              <!-- <th>
Id
</th> -->
              <th>
                Level
              </th>
              <th>
                Role
              </th>
              <th>
                Action
              </th>
            </tr>
          </thead>
          <tbody id="wrkflowtr">

          </tbody>
        </table>
      </div>
    </div>
    <br>
    <div class="row">
      <div id="SelDiv" class=" col-lg-4">

      </div>
      <div id="SelDivRole" class=" col-lg-4">
      </div>
      <div class=" col-lg-4">
        <div class="row">
          <div class=" col-lg-6">
            <input type="button" class="btn btn-primary" value="Add Level" (click)="AddNewLevel('S')">
          </div>
          <div class=" col-lg-6">
            <input type="button" class="btn btn-primary" value="Submit" (click)="OnSubmit()">
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
  <div role="tabpanel" class="tab-pane fade" id="divView">
    <div id="DivMainEdit" class="container" style="width:100%; background-color:white;">
      <div class="row">
        <div class="col-lg-12 col-md-4 col-sm-4 col-xl-12">
          <h3 class="text-center" id="tblHeadEdit"></h3>
          <input type="hidden" id="SeltableIDEdit" name="SeltableIDEdit">
        </div>

      </div>
      <div id="DivtableDataEdit" class="inner-content" style="width:100%;margin-right:2px;margin-left: 2px;">
        <table id="wfEdittableid" datatable [dtOptions]="dtOptionsEdit" [dtTrigger]="dtTriggerEdit"
          style="width:100%;margin-right:2px;margin-left: 2px;" class="table table-bordered table-striped hover">
          <thead bgcolor="#8f99a3" style="color:#ffffff" id="EditTableHeader">

          </thead>
          <tbody id="EditTableBody">

          </tbody>

        </table>
      </div>
      <input type="hidden" id="selectHiddenValues" name="selectHiddenValues">

    </div>
  </div>

  <!-- </div> -->



  <div class="backdrop " [ngStyle]="{'display':display}"></div>
  <div class="backdropCustom" style="display: none;"></div>
  <div class="AlertbackdropCustom" style="display: none;"></div>

  <!-- start EditMaster Modal -->
  <div id="EditMasterModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content screenbg">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="EditMasterUpdateHeaderId">Update Record</h2>
          <input type="hidden" id="updateId" name="updateId">
          <button type="button" class="close" aria-label="Close" (click)="onCloseEditMasterModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div id="DivDataWFEdit" class="table-responsive"
            style="overflow:scroll; overflow-x: hidden;height: 400px;width:100%">
            <div class="row ">
              <div class=" col-lg-6">
                <input id="txtWorkFlowNameE" type="text" class="form-control" placeholder="Please Enter Work Flow Name">
              </div>
            </div>
            <br>
            <div class="row ">
              <div class=" col-lg-12 table-responsive">
                <table id="wfTableE" class="table   table-striped table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th>Level</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="wrkflowtrE">

                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <div id="EditcontrolRow" class="row">
              <div id="SelDivEdit" class=" col-lg-4">

              </div>
              <div id="SelDivRoleEdit" class=" col-lg-4">
              </div>
              <div class=" col-lg-4">
                <div class="row">
                  <div class=" col-lg-6">
                    <input type="button" class="btn btn-primary" id="btnadd" value="Add Level"
                      (click)="AddNewLevel('E')">
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="onCloseEditMasterModal()">Close</button>
          <button type="button" class="btn btn-primary" id="btnupdate" (click)="updateEditMasterForm()">Update</button>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End EditMaster Modal -->
  <!-- start EditMasterConformation Modal -->
  <div id="EditMasterConformationModal" class="modal NP_popup_confirm" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="NP_emoji-confirm-bg">
            <!-- <div class="smile"></div> -->
          </div>
          <button type="button" class="close" aria-label="Close" (click)="onCloseEditMasterConformationModal()"><span
              aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">Confirm?</h2>
        <div class="modal-body">
          <p>Are you sure you want to update?</p>
        </div>
        <div class="NP_popup-footer row">
          <div class="col-1"></div>

          <div class="col-4">
            <p><a href="javascript: void(0);" (click)="onCloseEditMasterConformationModal()">No</a></p>
          </div>
          <div class="col-4">
            <p class="yes"><a href="javascript: void(0);" (click)="UpdateEditcall()">Yes</a></p>
          </div>
          <div class="col-1"></div>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End EditMasterConformation Modal -->
  <!-- start Alert Modal -->
  <div id="AlertModal" [class]="AlertType" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div [class]="AlertClass">
            <div [class]="AlertAnimationType "></div>
          </div>
          <button type="button" class="close" aria-label="Close" (click)="onCloseAlertModal()"><span
              aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">{{AlertTitle}}</h2>
        <div class="modal-body">
          <p>{{AlertMessage}}</p>
        </div>
        <div class="NP_popup-footer row">
          <div class="col-2"></div>
          <div class="col-2"></div>
          <div class="col-4">
            <p><a href="javascript: void(0);" (click)="onCloseAlertModal()">Ok</a></p>
          </div>
          <!-- <div class="col-3"><p class="yes"><a href="javascript: void(0);" (click)="submitcall()">Yes</a></p></div> -->
          <div class="col-3"></div>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End Alert Modal -->
  <!-- start submit Modal -->
  <div id="SubmitModal" class="modal NP_popup_confirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="NP_emoji-confirm-bg">
            <!-- <div class="smile"></div> -->
          </div>
          <button type="button" class="close" aria-label="Close" (click)="onCloseSubmitModal()"><span
              aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">Confirm?</h2>
        <div class="modal-body">
          <p>Are you sure you want to proceed?</p>
        </div>
        <div class="NP_popup-footer row">
          <div class="col-1"></div>

          <div class="col-4">
            <p><a href="javascript: void(0);" (click)="onCloseSubmitModal()">No</a></p>
          </div>
          <div class="col-4">
            <!-- <p class="yes"><a href="javascript: void(0);" (click)="submitcall()">Yes</a></p> -->
          </div>
          <div class="col-1"></div>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End submit Modal -->
  <!-- start View Modal -->
  <!-- start ActionActive Modal -->
  <div id="ActionActiveModal" class="modal NP_popup_confirm" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="NP_emoji-confirm-bg">
            <!-- <div class="smile"></div> -->
          </div>
          <button type="button" class="close" aria-label="Close" (click)="onCloseActionActiveModal()"><span
              aria-hidden="true">&times;</span></button>

        </div>
        <h2 class="modal-title">Confirm?</h2>
        <div class="modal-body container">
          <h6 id="ActionActiveMessage"></h6>
          <input type="hidden" id="varactionId" name="varactionId">
          <input type="hidden" id="recordId" name="recordId">
          <input type="hidden" id="TableId" name="TableId">
        </div>
        <div class="NP_popup-footer row">
          <div class="col-1"></div>

          <div class="col-4">
            <p><a href="javascript: void(0);" (click)="onCloseActionActiveModal()">No</a></p>
          </div>
          <div class="col-4">
            <p class="yes"><a href="javascript: void(0);" (click)="CustomFormActionActivesubmit()">Yes</a></p>
          </div>
          <div class="col-1"></div>

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End ActionActive Modal -->
  <div id="ViewModal" class="modal  " tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg inner-content " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">View</h4> -->
          <h2 class="modal-title pull-left" id="HmodalHeaderId"></h2>
          <button type="button" class="close" aria-label="Close" (click)="onCloseViewModal()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div id="DivtableData" class="table-responsive" style="width:100%">
            <table id="wftableid" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width:100%"
              class="table table-bordered row-border hover">
              <thead id="ViewTableHeader">

              </thead>
              <tbody id="ViewTableBody">

              </tbody>
              <!-- <tbody>
               <tr *ngFor="let bodyData of TableBodyData">
                 <td>{{ person.id }}</td>
                 <td>{{ person.firstName }}</td>
                 <td>{{ person.lastName }}</td>
               </tr>
             </tbody> -->
            </table>
          </div>
        </div>
        <div class="modal-footer">


          <button type="button" class="btn btn-default" (click)="onCloseViewModal()">Cancel</button>


        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
  <!-- End View Modal -->
</div>