<div class="stepper">
  <div class="stepper-wrappr">
    <ul>
      <li [ngClass]="!isProceedShow ? 'in-prpgress' :'active'" class="active">
        <span><i class="fa fa-check" aria-hidden="true"></i></span>
        <p>Select Installment</p>
      </li>
      <li
        [ngClass]="!isPayShow && !showTransactionStatus && !isProceedShow ? '' : !isPayShow && !showTransactionStatus && isProceedShow  ? 'in-prpgress' : isPayShow && !showTransactionStatus && !isProceedShow  ?  'active' : 'active' ">
        <span>
          <i class="fa fa-check" aria-hidden="true"></i>
          <i class="fa fa-file-text-o" aria-hidden="true"></i>
        </span>
        <p>Payment Details</p>
      </li>
      <li
        [ngClass]="!isPayShow && !showTransactionStatus ? '' : isPayShow && !showTransactionStatus ? 'in-prpgress' :  !isPayShow && showTransactionStatus ? 'active':'active' ">
        <span>
          <i class="fa fa-check" aria-hidden="true"></i>
          <i class="fa fa-money" aria-hidden="true"></i>
        </span>
        <p>Payment</p>
      </li>
      <li [ngClass]="!isPayShow && !showTransactionStatus ? '' :isPayShow && !showTransactionStatus ? '':'active' ">
        <span>
          <i class="fa fa-check" aria-hidden="true"></i>
          <i class="fa fa-shield" aria-hidden="true"></i>
        </span>
        <p>Payment Status</p>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="!isPayShow && !showTransactionStatus">


  <div >

    <div class="pay-fee-due-list">
      <div class="form-div" id="divAddNewData">
        <form #loginForm="ngForm" [formGroup]="feeDuesReactiveForm">
          <div id="DivData" class="DivData-content newUpdate row">


           <div class="filter_wrp">
            <div class="col-md-4">
              <div class="filter_btn_div">
                <div *ngIf="isFormEnabled" class="select_box" [ngClass]="status ? 'active' : ''">
                  <label class="col-form-label requiredSign" for="studentId">Student</label>
                <angular2-multiselect formControlName="studentId" id="studentId" [data]="dropdownListstudentdrp"
                  ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                  [(ngModel)]="selectedItemsstudentdrp" [settings]="dropdownSettings"
                  (onSelect)="onItemSelectStudent($event)" (onDeSelect)="OnItemDeSelectStudent($event)">
                </angular2-multiselect>
                </div>
                <div *ngIf="!isFormEnabled" class="select_box" [ngClass]="status ? 'active' : ''">
                  <label class="col-form-label requiredSign" for="sessionId">Session</label>
                  <angular2-multiselect [data]="dropdownListsessiondrp" formControlName="sessionId" id="sessionId"
                    ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                    [(ngModel)]="selectedItemssessiondrp" [settings]="dropdownSettings"
                    (onSelect)="onItemSelectSession($event)" (onDeSelect)="OnItemDeSelectSession($event)">
                  </angular2-multiselect>
                </div>
                <div class="imgfilter" *ngIf="isFormEnabled"  ngbTooltip="filter" (click)="onToggleFilter(true)">
                  <img src="assets/images/settings.svg" width="20px" alt="">
                </div>


              </div>
            </div>
            <div class="col-md-8">
              <div class="bedge_filter">
                <div *ngIf="showSelectedFilters.session !='' && showSelectedFilters.session != null" class="bedge_box">
                  <p>{{showSelectedFilters.session}}</p>
                  <a (click)="delFilter(1)"><span><img src="assets/images/close.svg" width="10px" alt=""></span></a>
                </div>
                <div *ngIf="showSelectedFilters.class !='' && showSelectedFilters.class != null" class="bedge_box">
                  <p>{{showSelectedFilters.class}}</p>
                  <a (click)="delFilter(2)"><span><img src="assets/images/close.svg" width="10px" alt=""></span></a>
                </div>
                <div *ngIf="showSelectedFilters.section !='' && showSelectedFilters.section != null" class="bedge_box">
                  <p>{{showSelectedFilters.section}}</p>
                  <a (click)="delFilter(3)"><span><img src="assets/images/close.svg" width="10px" alt=""></span></a>
                </div>
                <div *ngIf="showSelectedFilters.student !='' && showSelectedFilters.student != null" class="bedge_box">
                  <p>{{showSelectedFilters.student}}</p>
                  <a (click)="delFilter(4)"><span><img src="assets/images/close.svg" width="10px" alt=""></span></a>
                </div>
              </div>
            </div>

         


            <div class="drop_filter_div col-md-4" [ngClass]="status ? 'active' : ''">
              <div *ngIf="showAllFilter" class="">
                <label class="col-form-label requiredSign" for="sessionId">Session</label>
                <angular2-multiselect [data]="dropdownListsessiondrp" formControlName="sessionId" id="sessionId"
                  ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                  [(ngModel)]="selectedItemssessiondrp" [settings]="dropdownSettings"
                  (onSelect)="onItemSelectSession($event)" (onDeSelect)="OnItemDeSelectSession($event)">
                </angular2-multiselect>
              </div>
              <div *ngIf="showAllFilter" class="">
                <label class="col-form-label " for="classId">Class</label>
                <angular2-multiselect formControlName="classId" id="classId" [data]="dropdownListclassdrp"
                  ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                  [(ngModel)]="selectedItemsclassdrp" [settings]="dropdownSettings"
                  (onSelect)="onItemSelectClass($event)" (onDeSelect)="OnItemDeSelectClass($event)">
                </angular2-multiselect>
              </div>
              <div *ngIf="showAllFilter" class="">
                <label class="col-form-label " for="sectionId">Section</label>
                <angular2-multiselect formControlName="sectionId" id="sectionId" [data]="dropdownListsectiondrp"
                  ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                  [(ngModel)]="selectedItemssectiondrp" [settings]="dropdownSettings"
                  (onSelect)="onItemSelectSection($event)" (onDeSelect)="OnItemDeSelectSection($event)">
                </angular2-multiselect>
              </div>
              <div class="select_box">
                <label class="col-form-label requiredSign" for="studentId">Student</label>
              <angular2-multiselect formControlName="studentId" id="studentId" [data]="dropdownListstudentdrp"
                ngbTooltip="Select Session" container="wrapper" placement="left" tooltipClass="tooltipBlue"
                [(ngModel)]="selectedItemsstudentdrp" [settings]="dropdownSettings"
                (onSelect)="onItemSelectStudent($event)" (onDeSelect)="OnItemDeSelectStudent($event)">
              </angular2-multiselect>
              </div>
            </div>
           </div>
      
          
            
            <!-- <div class="col-md-2">
          <button type="button" class="filterSwap">
            <img src="assets/images/filter-search-j.png" (click)="switchFilters()" class="filterSwapimg" alt="buttonpng" border="0" />
          </button>
        </div> -->
          </div>

         

        </form>
       
      </div>
    </div>
  </div>

  <div class="blankmsg" *ngIf="!showStudentFeeData && isProceedShow">
    <h1>Please Select Studant</h1>
  </div>

  <div *ngIf="showStudentFeeData && !isProceedShow" class="pay-fee-content">
    <div class="over-div">
      <div class="row rele_div">
        <div class="table-responsive wrpper_pay_fee table-fee-data col-md-8" [ngClass]="toggle ? 'active' : ''">
          <ul ngbNav #nav="ngbNav" class="nav-tabs master-form-tab border-0 mb-4">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="nav-link">Student Details</a>
              <ng-template ngbNavContent>

                <div class="table-box">
                  <div class="top-div">Student details</div>
                  <div class="fst-row">
                    <div class="table-fee">
                      <div class="details-fixd">
                       <div class="row">
                        <div class="name_box col-md-4 margnbotm-10">
                          <div class="name text-label">Name</div>
                          <div class="name-change reg_update">{{studentDetails[0]['studentName']}}</div>
                        </div>
                        <div class="parents_box col-md-4 margnbotm-10">
                          <div class="pname text-label">Parent Name</div>
                          <div class="pname-change reg_update">{{studentDetails[0]['parentName']}}</div>
                        </div>
                        <div class="mobile_number_box col-md-4 margnbotm-10">
                          <div class="mob-num text-label">Mobile Number</div>
                        <div class="number-change reg_update">{{studentDetails[0]['primaryContactNumber']}}</div>
                        </div>
                       </div>
                        <div class="row">
                          <div class="emailId_box col-md-4">
                            <div class="email text-label">Email Id</div>
                            <div class="email-change reg_update">{{studentDetails[0]['email']}}</div>
                          </div>
                          <div class="address_box col-md-8">
                            <div class="add-div text-label">Address</div>
                          <div class="address-change reg_update">{{studentDetails[0]['studentAddress']}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="nav-link">Fee Details</a>
              <ng-template ngbNavContent>
                <!-- <table class="table table-bordered row-border hover w-100 ">
          <thead>
            <tr>
              <th>
                <input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll()" />
      
              </th>
              <th>S.No.</th>
              <th>Installment</th>
              <th>Amount</th>
              <th>Paid</th>
              <th>Transaction No.</th>
              <th>Balance</th>
              <th>Adjustment Amount</th>
              <th>Req Slip</th>
            
             
            </tr>
      
          </thead>
      
          <tbody id="EditTableBody">
      
         
            <tr *ngFor="let rowDetailData of DetailtableRowData; let rowi=index;">
              <td><input type="checkbox" [(ngModel)]="rowDetailData['isSelected']" *ngIf="rowDetailData['isPaid'] == 0" (change)="isAllSelected()" /></td>
              <td>{{ (rowi + 1)}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['installement'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['feeAmount'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['feeAmountPaid'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['transId'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['balance'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['adjustmentAmount'])}}</td>
              <td> <a *ngIf="rowDetailData['isPaid'] != 0" href="javascript:void(0)"
                (click)="OnPrintFullDetail($event, rowDetailData['id'])"
                ngbTooltip="Print" container="wrapper"
                placement="left" tooltipClass="tooltipBlue" ngbTooltip="Tooltip on left"
                class='edit opendetail'><i class='fa fa-print'></i></a> </td>
          </tr>
         
          </tbody>
        </table> -->
                <app-generic-table-list-local-memory-view [spName]="feeInstalmentFields['proc']"
                  [formName]="SeltableIDForm" [formType]="1" [editPermission]="feeInstalmentFields['editPermission']"
                  [printPermission]="feeInstalmentFields['printPermission']"
                  [isWorkflowImplemented]="feeInstalmentFields['isWorkflowImplemented']"
                  [activePermission]="feeInstalmentFields['activePermission']"
                  [deletePermission]="feeInstalmentFields['deletePermission']"
                  [isRenderDetail]="feeInstalmentFields['isRenderDetail']"
                  [ischeckboxshow]="feeInstalmentFields['isCheckboxShow']" [preFilterParmValue]="preFilterParmValue"
                  [sendSelectedData]="feeInstalmentFields['sendSelectedData']"
                  [isExcelDownload]="feeInstalmentFields['isExcel']" [isPdfDownload]="feeInstalmentFields['isPdf']"
                  [customActionPermission]="feeInstalmentFields['customAction']"
                  [customActionIcon]="feeInstalmentFields['customActionConfiguration']['customActionIcon']"
                  [customActionToolTip]="feeInstalmentFields['customActionConfiguration']['toolTipText']"
                  (viewFullDetail)="OnViewFullDetailFeeInstallment($event)"
                  (customActionDetail)="OnCustomActionClickDetailFeeInstallment($event)"
                  (sendSelectedRows)="OnSelectedRowFeeInstallment($event)">
                </app-generic-table-list-local-memory-view>
                <div class="submit-btn btn-div text-right">
                  <!-- <button type="button" class="btn btn-danger" (click)="onReset(1)">Reset</button> -->
                  <button type="button" class="btn btn-primary tablefee" [disabled]="selectedRows.length ==0 ? 'disabled' : ''"
                    (click)="OnProceed(1)">Proceed</button>
                </div>
              </ng-template>
            
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink class="nav-link">Transaction Details</a>
              <ng-template ngbNavContent>
                <!-- <table class="table table-bordered row-border hover w-100 ">
          <thead>
            <tr>             
              <th>S.No.</th>
              <th>Transaction No.</th>
              <th>Bank Name</th>
              <th>Cheque Number</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Balance Amount</th>
              <th>Advance Amount</th>
              <th>Payment Mode</th>
              <th>Payment Date</th>
              <th>Transaction Status</th>
            
             
            </tr>
      
          </thead>
      
          <tbody id="EditTransTableBody">      
         
            <tr *ngFor="let rowDetailData of TranstableRowData; let rowi=index;">
             
              <td>{{ (rowi + 1)}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['transID'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['bankName'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['chequeNumber'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['totalAmount'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['paidAmount'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['balanceAmount'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['adjustmentAmount'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['paymentMode'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['paymentDate'])}}</td>
              <td> {{ReplaceNullDataTable(rowDetailData['isSucessfull'])}}</td>
             
          </tr>
         
          </tbody>
        </table> -->
                <app-generic-table-list-local-memory-view [spName]="feeTransactionFields['proc']"
                  [formName]="SeltableIDForm" [formType]="1" [editPermission]="feeTransactionFields['editPermission']"
                  [printPermission]="feeTransactionFields['printPermission']"
                  [isWorkflowImplemented]="feeTransactionFields['isWorkflowImplemented']"
                  [activePermission]="feeTransactionFields['activePermission']"
                  [deletePermission]="feeTransactionFields['deletePermission']"
                  [isRenderDetail]="feeTransactionFields['isRenderDetail']"
                  [ischeckboxshow]="feeTransactionFields['isCheckboxShow']" [preFilterParmValue]="preFilterParmValue"
                  [sendSelectedData]="feeTransactionFields['sendSelectedData']"
                  [isExcelDownload]="feeTransactionFields['isExcel']" [isPdfDownload]="feeTransactionFields['isPdf']"
                  [customActionPermission]="feeTransactionFields['customAction']"
                  [customActionIcon]="feeTransactionFields['customActionConfiguration']['customActionIcon']"
                  [customActionToolTip]="feeTransactionFields['customActionConfiguration']['toolTipText']"
                  (viewFullDetail)="OnViewFullDetailFeeTransaction($event)"
                  (customActionDetail)="OnCustomActionClickDetailFeeTransaction($event)"
                  (sendSelectedRows)="OnSelectedRowFeeTransaction($event)">
                </app-generic-table-list-local-memory-view>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>

        <div class="col-md-4 rele_div feedata" [ngClass]="toggle ? 'active' : ''">
          <div class="closing_btn" [ngClass]="toggle ? 'active' : ''" (click)="ontogglebtn()"><img src="assets/images/right-arrow.svg" width="17px" alt=""></div>
            <div class="chart_box"  [ngClass]="toggle ? 'active' : ''">
              <div class="card" style="border-radius: 10px;">
                <div id="feeDuesChart" [style.width.%]="100" [style.height.px]="300"></div>
    
              </div>
          
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="due-div col-md-8" *ngIf="isProceedShow">
    <div class="amount-div d-flex">
      <div class="right-amount"><span>Fee Total Amount: - {{totalSumFee}}</span></div>
    </div>
    <div class="form-div row" id="divFeeDuesData">
      <!-- <div class="row fine-row">
      <div class="col-md-6">
    Fine/Excess
  </div>
  <div class="col-md-6">
    <input type='text' class="form-control" Placeholder="Fine/Excess"
    id="txtLateFee"  [(ngModel)]="lateFee"  readonly [disabled]="true">

  </div>
    </div> -->
      <div class="row fine-row">
        <div class="col-md-4">
          Adjustment Amount
        </div>
        <div class="col-md-6">
          <input type='text' class="form-control" Placeholder="Adjustment Amount" id="txtAdjustmentAmount"
            [(ngModel)]="adjustmentAmount" readonly [disabled]="true">

        </div>
      </div>
      <div class="row fine-row">
        <div class="col-md-4">
          Balance
        </div>
        <div class="col-md-6">
          <input type='text' class="form-control" Placeholder="Balance" id="txtBalance" [(ngModel)]="balanceFee"
            readonly [disabled]="true">

        </div>
      </div>
      <div class="row fine-row">
        <div class="col-md-4">
          Total
        </div>
        <div class="col-md-6">
          <input type='text' class="form-control" Placeholder="Total" id="txtTotal" [(ngModel)]="totalFee" readonly
            [disabled]="true">
        </div>
      </div>

      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          Total Amount <b> {{ totalSumFee }}</b>
        </div>
      </div>


      <div class="submit-btn col-md-12 btn-div text-right">
        <button type="button" class="cancel-btn" (click)="onPayCancel()">Reset</button>
        <button type="button" (click)="OnProceed(2)">Proceed</button>

      </div>
    </div>
  </div>
</div>
<div class="payment-detail" *ngIf="isPayShow && !showTransactionStatus">


  <div class="amount-div d-flex">
    <div class="left-amount"><span>check amount</span></div>
    <div class="right-amount"><span>your fee amount {{totalSumFee}}</span></div>
  </div>

  <div class="trns-numb">system transaction no is <span>{{transId}}</span></div>

  <div class="table-box">
    <div class="top-div">payment details</div>
    <div class="fst-row">
      <div class="table-fee">
        <div class="details-fixd">
         <div class="row">
          <div class="name_box col-md-4 margnbotm-10">
            <div class="name text-label">Name</div>
            <div class="name-change reg_update">{{studentDetails[0]['studentName']}}</div>
          </div>
          <div class="parents_box col-md-4 margnbotm-10">
            <div class="pname text-label">Parent Name</div>
            <div class="pname-change reg_update">{{studentDetails[0]['parentName']}}</div>
          </div>
          <div class="mobile_number_box col-md-4 margnbotm-10">
            <div class="mob-num text-label">Mobile Number</div>
          <div class="number-change reg_update">{{studentDetails[0]['primaryContactNumber']}}</div>
          </div>
         </div>
          <div class="row">
            <div class="emailId_box col-md-4">
              <div class="email text-label">Email Id</div>
              <div class="email-change reg_update">{{studentDetails[0]['email']}}</div>
            </div>
            <div class="address_box col-md-8">
              <div class="add-div text-label">Address</div>
            <div class="address-change reg_update">{{studentDetails[0]['studentAddress']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="payment-method-tab">
    <div class="payment-method-tab-wrappr">
      <ul ngbNav #nav="ngbNav" class="nav-tabs master-form-tab border-0 mb-4">
        <li class="nav-item">
          <a class="nav-link active" (click)="onPaymentType(1)" data-bs-toggle="tab" href="#cash">Cash</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" (click)="onPaymentType(2)" href="#cheque">Cheque</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" (click)="onPaymentType(3)" href="#paymentgateway">Payment Gateway</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane container active" id="cash">
          <form #cashForm="ngForm" [formGroup]="feeDuesCashPayReactiveForm">
            <div class="payment-tab-wrappr">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Total Amount</label>
                    <input type="text" placeholder="Enter total amount" formControlName="FtotalSumFee"
                      [(ngModel)]="totalSumFee" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Paid Amount</label>
                    <input type="text" placeholder="Enter paid amount" formControlName="FtotalSumPaidFee"
                      [(ngModel)]="totalSumPaidFee" (change)="calculateBalance('cash')" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Balance Amount</label>
                    <input type="text" placeholder="Enter balance amount" formControlName="FtotalSumBalanceFee"
                      [(ngModel)]="totalSumBalanceFee" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Adjustment Amount</label>
                    <input type="text" placeholder="Enter adjustment amount" formControlName="FtotalSumAdjustmentFee"
                      [(ngModel)]="totalSumAdjustmentFee" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane container fade" id="cheque">
          <form #chequeForm="ngForm" [formGroup]="feeDuesChequepayReactiveForm">
            <div class="payment-tab-wrappr">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Total Amount</label>
                    <input type="text" placeholder="Enter total amount" formControlName="FtotalSumFee"
                      [(ngModel)]="totalSumFee" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Paid Amount</label>
                    <input type="text" placeholder="Enter paid amount" formControlName="FtotalSumPaidFee"
                      [(ngModel)]="totalSumPaidFee" (change)="calculateBalance('cash')" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Balance Amount</label>
                    <input type="text" placeholder="Enter balance amount" formControlName="FtotalSumBalanceFee"
                      [(ngModel)]="totalSumBalanceFee" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Bank Name</label>
                    <input type="text" placeholder="Enter Bank Name" formControlName="FbankName" class="form-control">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Cheque No.</label>
                    <input type="text" placeholder="Enter Cheque No." formControlName="FchequeNumber"
                      class="form-control">
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane container fade" id="paymentgateway">wsds</div>
      </div>
    </div>
  </div>


  <div class="submit-btn btn-div">
    <button type="button" class="cancel-btn" (click)="onPayCancel()">Cancel</button>
    <button type="button" class="pay-btn" *ngIf="paymentTypeId == 1" [disabled]="!cashForm.valid" (click)="onPay()">Pay
      Now</button>
    <button type="button" class="pay-btn" *ngIf="paymentTypeId == 2" [disabled]="!chequeForm.valid"
      (click)="onPay()">Pay Now</button>

  </div>



</div>

<div class="payment-detail" *ngIf="!isPayShow && showTransactionStatus ">


  <div class="amount-div d-flex">

    <div class="right-amount"><span>Transaction ({{transId}}) is {{transStatus}}</span></div>
  </div>



  <div class="table-box">
    <div class="top-div">transaction details</div>
    <div class="fst-row">
      <div class="table-fee d-flex">
        <!-- <div class="details-fixd">
          <div class="name">Name</div>
          <div class="name-change">{{studentDetails[0]['studentName']}}</div>
          <div class="pname">Parent Name</div>
          <div class="pname-change">{{studentDetails[0]['parentName']}}</div>
          <div class="mob-num">Mobile Number</div>
          <div class="number-change">{{studentDetails[0]['primaryContactNumber']}}</div>
          <div class="email">Email Id</div>
          <div class="email-change">{{studentDetails[0]['email']}}</div>
          <div class="add-div">Address</div>
          <div class="address-change">{{studentDetails[0]['studentAddress']}}</div>
          <div class="add-div">Total Amount</div>
          <div class="address-change">{{totalSumFee}}</div>
          <div class="add-div">Paid Amount</div>
          <div class="address-change">{{totalSumPaidFee}}</div>
          <div class="add-div">Balance Amount</div>
          <div class="address-change">{{totalSumBalanceFee}}</div>
        </div>
        <div class="dynm-details">
          
     
      
    
  
   
   
        </div> -->
      </div>
    </div>
    <div class="fst-row">
      <div class="table-fee">
        <div class="details-fixd">
         <div class="row">
          <div class="name_box col-md-4 margnbotm-10">
            <div class="name text-label">Name</div>
            <div class="name-change reg_update">{{studentDetails[0]['studentName']}}</div>
          </div>
          <div class="parents_box col-md-4 margnbotm-10">
            <div class="pname text-label">Parent Name</div>
            <div class="pname-change reg_update">{{studentDetails[0]['parentName']}}</div>
          </div>
          <div class="mobile_number_box col-md-4 margnbotm-10">
            <div class="mob-num text-label">Mobile Number</div>
          <div class="number-change reg_update">{{studentDetails[0]['primaryContactNumber']}}</div>
          </div>
         </div>
          <div class="row">
            <div class="emailId_box col-md-4">
              <div class="email text-label">Email Id</div>
              <div class="email-change reg_update">{{studentDetails[0]['email']}}</div>
            </div>
            <div class="address_box col-md-4">
              <div class="add-div text-label">Address</div>
            <div class="address-change reg_update">{{studentDetails[0]['studentAddress']}}</div>
            </div>
            <div class="emailId_box col-md-4">
              <div class="email text-label">Total Amount</div>
              <div class="email-change reg_update">{{totalSumFee}}</div>
            </div>
          </div>
          <div class="row">
            <div class="address_box col-md-4">
              <div class="add-div text-label">Paid Amount</div>
            <div class="address-change reg_update">{{totalSumPaidFee}}</div>
            </div>
            <div class="address_box col-md-4">
              <div class="add-div text-label">Balance Amount</div>
            <div class="address-change reg_update">{{totalSumBalanceFee}}</div>
            </div>
            <div class="address_box col-md-4">
              <div class="add-div text-label">Adjustment Amount</div>
            <div class="address-change reg_update">{{totalSumAdjustmentFee}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="submit-btn btn-div">
    <button type="button" class="cancel-btn" (click)="onReset(3)">Pay Fee Again</button>
    <button type="button" class="pay-btn" (click)="OnPrintFullDetail('',selectedfeeIds)">Print Reciept</button>

  </div>



</div>