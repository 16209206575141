<div class="edit-modal-form" *ngIf="reqShowFlag">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Admission Requirments</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateEditMasterForm">
        <div>
            <label for="">Title</label>
            <input placeholder="Add New Heading" class="form-control" type="text" formControlName="title">
        </div>


        <div class="Submitbtn-content mt-5">
            <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">cancel</button>
            <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
        </div>
    </div>
</div>  





<div class="edit-modal-form" *ngIf="DeadlineShowFlag">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Admission Deadline</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateAdmissionDeadlineMasterForm">
        <div>
            <label for="">Title</label>
            <input placeholder="Add New Heading" class="form-control" type="text" formControlName="title">
        </div>
        <div>
            <label for="">Year </label>
            <input placeholder="Add New Heading" class="form-control" type="text" formControlName="year">
        </div>
        <div>
            <label for="">Month</label>
            <input placeholder="Add New Heading" class="form-control" type="text" formControlName="month">
        </div>


        <div class="Submitbtn-content mt-5">
            <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">cancel</button>
            <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickAdmissionDeadlineSubmit()">Submit</button>
        </div>
    </div>
</div>    