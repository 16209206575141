import { Injectable } from '@angular/core';
import {ClassesPageModelComponent} from "./classes-page-model.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ClassesPageModelService {

  constructor(private activeModel :NgbModal) { }

  public AboutService(
    allpopupdata:any,
    dialogSize: 'sm'|'lg' = 'lg'
  ):Promise<boolean>{
    const modalRef = this.activeModel.open(ClassesPageModelComponent, { size: dialogSize, centered: true ,backdrop:true});
    modalRef.componentInstance.allpopupdata = allpopupdata;
    return modalRef.result;
  }
}
