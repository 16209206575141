import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Commonfunction } from 'src/app/shared/commonutility';
import { Iclasses,Itableth } from '../../../allUsedModule';
import { ConfirmDialogBoxService } from 'src/app/shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from 'src/app/shared/global-alert.service';
import { ClassesPageModelService } from '../../../allFormPopUpModel/classes-page-model/classes-page-model.service';

@Component({
  selector: 'app-classeslist-dashboard',
  templateUrl: './classeslist-dashboard.component.html',
  styleUrls: ['./classeslist-dashboard.component.css']
})
export class ClasseslistDashboardComponent implements OnInit {
  activeTabId:number=1;
  classesdata:any;
  AddNewFrom !: FormGroup;

  tableHead : Itableth[] = [
    { "displayName": "Image", "colName": "image","isActionBtn":false,"isImagePreviewEnabled":true },
    { "displayName": "Title", "colName": "title","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Description", "colName": "description","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "class Timing", "colName": "classTime","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "class Size", "colName": "classSize","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "duration", "colName": "duration","isActionBtn":false,"isImagePreviewEnabled":false },
    { "displayName": "Router Link", "colName": "routerlink","isActionBtn":false,"isImagePreviewEnabled":false },
  ]
  

  constructor(public commonfunction:Commonfunction,private confirm: ConfirmDialogBoxService,
     private globalAlertService: GlobalAlertService, private popupmodelservice:ClassesPageModelService) { }

  ngOnInit(): void {
    this.classesdata = this.commonfunction.loadJson("~/../assets/allclasses.json");
    
    this.AddNewFrom = new FormGroup({
      title: new FormControl(''),
      timing: new FormControl(''),
      description: new FormControl(''),
      classsize: new FormControl(''),    
      duration: new FormControl(''),    
      routerlink: new FormControl('')  
    })
  }

  onClickResetFrom(){
    this.AddNewFrom.reset()
  }

  OnClickSubmit(){
    const _item : Iclasses = {} as Iclasses;
    _item.title = this.AddNewFrom.get('title')?.value;
    _item.classTime = this.AddNewFrom.get('timing')?.value;
    _item.description = this.AddNewFrom.get('description')?.value;
    _item.classSize = this.AddNewFrom.get('classsize')?.value;
    _item.duration = this.AddNewFrom.get('duration')?.value;
    _item.routerlink = this.AddNewFrom.get('routerlink')?.value;
    _item.id = this.classesdata.length +1;
    _item.isActive = true;
    console.log(_item);
    this.classesdata.push(_item)  
  }

  onClickEditForm(value:Iclasses){
    this.popupmodelservice.AboutService(value)
    .then((confirm)=>{
      if(confirm){
        // this.classesdata[index].push(value)
      }
    })
  }
  deleteKey(index:number){
    this.classesdata.splice(index,1);
  }

  addrouterlink(value:any){
    this.AddNewFrom.patchValue({
      routerlink: value.target.value
      
    })
  }

}
