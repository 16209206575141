import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaptureImageComponent} from '../capture-image/capture-image.component';

@Injectable({
  providedIn: 'root'
})
export class CaptureImageServiceService {

  constructor(private modalService: NgbModal) { }
  public CaptureImageFunction(
    title: string,    
    tableName: string,
    formName: string,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',    
    dialogSize: 'sm'|'lg'| 'md' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(CaptureImageComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.title = title;    
    modalRef.componentInstance.tableName = tableName;
    modalRef.componentInstance.formName = formName;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText; 
    return modalRef.result;
  }
}
