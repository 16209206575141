import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-about-school',
  templateUrl: './about-school.component.html',
  styleUrls: ['./about-school.component.css']
})
export class AboutSchoolComponent implements OnInit {
  aboutData: any;

  constructor(public commonfunction:Commonfunction) {
    const alldatafile = this.commonfunction.loadJson("~/../assets/about.json");
    this.aboutData = alldatafile[0];
  }

  ngOnInit(): void {
  }

}
