import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-generic-lesson',
  templateUrl: './public-generic-lesson.component.html',
  styleUrls: ['./public-generic-lesson.component.css']
})
export class PublicGenericLessonComponent implements OnInit {
  lessondata: any;
  tabs: any[] = [];
  isPlaying: boolean = false;

  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;

  constructor(public commonfunction: Commonfunction) {
    const alldatafile = this.commonfunction.loadJson("~/../assets/lessondb.json");
    this.lessondata = alldatafile;
    console.log(this.lessondata);
  }

  ngOnInit(): void {
    if (this.lessondata?.tabsdata) {
      this.tabs = this.lessondata.tabsdata.map((tab: any, index: number) => ({
        ...tab,
        isactive: index === 0 
      }));
    }
  }

  togglePlayPause() {
    this.isPlaying = !this.isPlaying;
    const video = this.videoElement.nativeElement;

    if (this.isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  }

  ngAfterViewInit() {
    const video = this.videoElement.nativeElement;
    video.addEventListener('ended', () => {
      this.isPlaying = false;
    });
  }

  changeTab(index: number) {
    this.tabs = this.tabs.map((tab, i) => ({
      ...tab,
      isactive: i === index 
    }));
  }
}
