import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormControlName, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms'
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { async } from '../../../../node_modules/@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { GenericFormDropDownServiceService } from "../../shared/generic-form-drop-down-service.service";
import { ObjectParam, lstObjectParam, customAction, FormFinalObjectParam, lstFormFinalObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from '../../../../node_modules/angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../../shared/excel.service';
// import  'src/typings';
import 'jquery';
import { custumValidation } from '../../shared/custum-validation';
import { ConfirmDialogBoxService } from '../../shared/confirm-dialog-box-event/confirm-dialog-box.service';
import { GlobalAlertService } from '../../shared/global-alert.service';
import { asyncCustumValidation } from '../../shared/async-custum-validation';
declare var jQuery: any;
import * as moment from 'moment';
import { isAnyArrayBuffer } from 'util/types';

@Component({
    selector: 'app-generic-list-form',
    templateUrl: './generic-list-form.component.html',
    styleUrls: ['./generic-list-form.component.css']
})
export class GenericListFormComponent implements OnDestroy, OnInit {
    customSearchString: string = '';
    get customSearch(): string {
        return this.customSearchString;
    }
    set customSearch(val: string) {
        this.customSearchString = val;
        this.CustomFilterusingColumn();
    }
    SeltableID: string="";
    SeltableIDForm: string="";
    dateFormat = 'MM-DD-YYYY';
    @ViewChild(DataTableDirective)
    dtElement!: DataTableDirective;
    masterProductForm!: FormGroup;
    isRender: boolean = false;
    isRenderDetail: boolean = false;
    masterProductListForm!: FormGroup;
    // masterProductListArray: FormArray;
    listFormDataGrid: any[] = [];
    isRenderList: boolean = false;
    editModelHeading: string="";
    editModelCallfrom: string="";
    childActionParam: string="";

    viewtableHeaders: TableColumnName[] = [];
    tableid: string="";
    Detailtableid: string="";
    DetailtableHeaderData:any = [];
    DetailtableRowData = []
    dropdownSettings = {};
    display = "none";
    Divdisplay = "none";
    displayMain = "none";
    displayMainEdit = "none";
    displayContainer = "block";
    sel: any;
    masterJsonDataFull: any[];
    masterJsonData: any[];
    masterJsonDataListFull: any[]=[];
    masterJsonDataList: any[]=[];
    callfor: string="";
    AlertMessage: any;
    AlertTitle: any;
    AlertClass: any = "NP_emoji-success-bg";
    AlertType: any = " NP_popup_success";
    AlertAnimationType: any = "smile";
    tblecount: number=0;
    public value: string[]=[];
    public current: string="";
    public selected: string="";
    public dynamicdivdata: any;
    ischeckboxshow: number = 0;
    dropdownListActionGroupSpdrp = [];
    selectedItemsActionGroupSpdrp: Array<{ id: string, itemName: string }> = [];
    dropdownListCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    selectedItemsCustomColumnSearchdrp: Array<{ id: string, itemName: string }> = [];
    dropdownList = [];
    selectedItems = [];
    bsConfig:any;
    changeDetectorRefs: ChangeDetectorRef[] = [];
    paramvaluedata: any;
    FileerrorMessage: string="";
    filesToUpload: Array<File>;
    selectedFileNames: string[] = [];
    public isLoadingData: Boolean = false;
    @ViewChild('fileUpload') fileUploadVar: any;
    uploadResult: any;
    res: Array<string>=[];
    param: string="";
    menulabel: string="";
    formlable: string="";
    minDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() - year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    maxDate(year: number) {
        let currentdate = new Date();
        let Cmonth = currentdate.getMonth() + 1;
        let Cdate = currentdate.getDate();
        let Cyear = currentdate.getFullYear() + year;
        return new Date(Cmonth + '/' + Cdate + '/' + Cyear);
    }
    selectedRows:any = [];
    masterSelected: boolean = false;
    formValue: string="";
    formName: string="";
    currentRoleString: string | undefined = '';
    currentRole: string  ="";
    userId: string| undefined  ="";
    constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation, private userService: UserService, public asyncCustomvalidation: asyncCustumValidation,
        private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, route: ActivatedRoute, private spinner: NgxSpinnerService, private fb: FormBuilder, private globalAlertService: GlobalAlertService,
         private globalConfirmService: ConfirmDialogBoxService, private excelService: ExcelService, private genericDropDown:GenericFormDropDownServiceService) {
        this.FileerrorMessage = "";
        this.filesToUpload = [];
        this.selectedFileNames = [];
        this.uploadResult = "";
        this.masterJsonData = [];
        this.masterJsonDataFull = [];
        this.bsConfig = {
            dateInputFormat: 'DD/MM/YYYY',
            isAnimated: true,
            adaptivePosition: true,
            containerClass: 'theme-blue',
            minDate: this.minDate(50),
            maxDate: this.maxDate(50)
        };
        this.currentRoleString = this.commonfunction.checkStringIsNullorEmpty(sessionStorage.getItem('currentRole'))? sessionStorage.getItem('currentRole')?.toString()  : "";
      this.currentRole = this.currentRoleString != null ? JSON.parse(this.currentRoleString)?.roleId.toString():"";
      this.userId= sessionStorage.getItem('userId')?.toString();
    }

    ngOnInit() {
       
        let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
        this.formValue = paramjs.formValue;
        this.formName = paramjs.formName;
        this.SeltableID = paramjs.formValue;
        this.SeltableIDForm = paramjs.formName;
        this.menulabel = paramjs.menu;
        this.formlable = paramjs.formName;
        this.Search(paramjs.formValue, paramjs.formName);

        this.dropdownSettings = {
            singleSelection: true,
            // text:"Select Countries",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            // primaryKey:'DrpValue',
            // labelKey:'DrpOption',
            classes: "global-select-box",
            disabled: false
        };
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

    }


    Search(tbname:string, formName:string) {
        this.spinner.show();
        var trdata = '';
        const that = this;
        this.userService.getGenericReportFormPage(tbname, formName, 'insert').subscribe((data: any) => {

            this.displayMain = 'block';
            let reactiveFormData = {};

            this.masterJsonDataFull = data;
            this.masterJsonData = data['formData'];
            console.log(this.masterJsonData);
            this.masterJsonData.forEach(function (value, index) {
                let ArrayData: ValidatorFn[] = [];
                if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                    let requiredMsg = '';
                    let sizeMsg = '';
                    let customMsg = '';
                    if (value["customValidationMsg"] != "" && value["customValidationMsg"] != null) {
                        let customValidationMsg = JSON.parse(value["customValidationMsg"]);
                        requiredMsg = customValidationMsg['requiredMsg'];
                        sizeMsg = customValidationMsg['sizeMsg'];
                        customMsg = customValidationMsg['customMsg'];
                    }
                    if (value['iS_NULLABLE'] == "no") {
                        // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));

                        ArrayData.push(custumValidation['validateRequired'](requiredMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));
                    }
                    if ((value['datA_TYPE'] != "date" && value['datA_TYPE'] != "datetime" && value['datA_TYPE'] != "bit")) {

                        ArrayData.push(custumValidation['validateSize'](sizeMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));
                    }
                    if (value['validations'] != "" && value['validations'] != null) {

                        let ValName = "'" + value['validations'] + "'"
                        // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                        // ArrayData.push(custfunction);

                        ArrayData.push(custumValidation[eval(ValName)](customMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));

                    }
                }

                // validatenumeric('16','numeric','drop','Role','','','')
                if (value['controlType']?.toString().toLowerCase() == 'dropdown')
                    reactiveFormData[value['controlName']] = new FormControl(value['drpDataSelected'], ArrayData);
                else if (value['controlType']?.toString().toLowerCase() == 'mdropdown')
                    reactiveFormData[value['controlName']] = new FormControl(value['drpDataSelected'], ArrayData);
                else
                    reactiveFormData[value['controlName']] = new FormControl(value['columnValue'], ArrayData);

            });

            this.masterProductForm = that.fb.group(reactiveFormData);
            console.log(that.masterProductForm);
            this.isRender = true;

            this.spinner.hide();

        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = "NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {

                this.ischeckboxshow = +this.masterJsonDataFull['formIsCheckbox'];
                if (this.masterJsonDataFull['formActionGroupList'] != null)
                    this.dropdownListActionGroupSpdrp = this.masterJsonDataFull['formActionGroupList'];
                console.log(this.ischeckboxshow);
            });
    };


    onItemSelect(item: any, Index: number, multiselect: boolean) {


        let result = this.genericDropDown.onItemSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelect(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelect(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    onItemSelectAll(item: any, Index: number, multiselect: boolean) {

        let result = this.genericDropDown.onItemSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }
    }

    OnItemDeSelectAll(item: any, Index: number, multiselect: boolean) {
        let result = this.genericDropDown.OnItemDeSelectAll(item, Index, multiselect, this.masterJsonData, this.masterProductForm, this.SeltableIDForm,true,false,"","");
        if (result.status != 200) {
            if (result.status == 401) {

                this.AlertTitle = "Error!!";
                this.AlertMessage = "You are not authorized!";
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            else if (result.status == 403) {
                this.Customvalidation.loginroute(result.status);
            }
            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = result.message.toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";
            }
            this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
        }

    }

    OnClose(item: any, controlName: string) {
        this.masterProductForm.get(controlName)?.markAsTouched();
    }



    OnItemDeSelectChildAction(item: any) {


    }


    openSubmitModal() {
        this.globalConfirmService.ConfirmFunction("Confirm Submit !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.submitcall();
                    this.paramvaluedata = '';
                }
            });
    }
    FirstLetterLower(value: string) {
        return this.commonfunction.FirstLetterLower(value);
    }
    FirstLetterUpper(value:string) {

        return this.commonfunction.FirstLetterUpper(value);
    }
    ReplaceNullDataTable(value:string) {
        return this.commonfunction.ReplaceNullDataTable(value);
    }
    OnSubmitModal() {
        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let objColumn = <lstObjectParam>{};
        let genericColumnList = [];
        let mylist = '[';
        let GenericRptProcParams: string="";
        GenericRptProcParams = '';
        this.masterJsonData.forEach(function (value, index) {
            
            let objList = <ObjectParam>{};
            if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                let colValue;
                let CompareColumnValue;
                if (value["controlType"] == "dropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue.length <= 0)
                        colValue = null;
                    else
                        colValue = drpvalue[0]['id']?.toString();
                }
                else if (value["controlType"] == "mdropdown") {
                    let drpvalue = [];
                    drpvalue = that.masterProductForm?.value[value["controlName"]]
                    if (drpvalue === null || drpvalue.length <= 0)
                        colValue = null;
                    else {
                        colValue = Array.prototype.map.call(drpvalue, s => s['id']).toString();
                    }

                }
                else if (value["controlType"] == "checkbox") {
                    let chkValue = that.masterProductForm?.value[value["controlName"]]
                    if (chkValue == null || chkValue == false) {
                        colValue = "false";
                    }
                    else {
                        colValue = "true";
                    }
                }
                else if (value["controlType"] == "file") {
                    colValue = that.masterProductForm?.value[value["controlName"]]
                }
                else if (value["datA_TYPE"].toLowerCase() == "date" || value["datA_TYPE"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterProductForm?.value[value["controlName"]]);
                }
                else if (value["controlType"].toLowerCase() == "datetime") {
                    colValue = that.commonfunction.ConvertDateFormatMMDDYYYY(that.masterProductForm?.value[value["controlName"]]);
                }
                else {
                    colValue = that.masterProductForm?.value[value["controlName"]]
                }
                if (value["compareColumnName"] != "") {
                    if (value["compareColumnName"].toLowerCase() != "today") {
                        let controlValue = $('#' + value["controlName"]).val() ? $('#' + value["controlName"]).val()?.toString() : '';
                        CompareColumnValue = controlValue ? $.trim(controlValue.toString()) :'';  
                    }
                    else {
                        CompareColumnValue = '';
                    }
                }
                if (colValue != null && colValue != "") {

                    GenericRptProcParams += '|' + value["columN_NAME"] + '=' + colValue;
                }

            }
        });

        //   this.openSubmitModal();
        this.paramvaluedata = this.SeltableID + GenericRptProcParams;
        this.submitcall();

    }
    CustomFilterusingColumn() {
        
        if (this.selectedItemsCustomColumnSearchdrp.length > 0) {
            $('#' + this.Detailtableid).DataTable()
                .column(this.selectedItemsCustomColumnSearchdrp[0].id)
                .search(this.customSearch)
                .draw();
        }
    }
    aliasesArrayControl() {
        return (this.masterProductListForm.get('listForm') as FormArray).controls;
    }
    submitcall() {
        let that = this;
        let headerData:any = [];
        let dataRow = [];
        this.spinner.show();
        //   if(this.DetailtableHeaderData.length > 0)
        //   this.DetailtableHeaderData.splice(0, this.DetailtableHeaderData.length);
        // if (this.DetailtableRowData.length > 0)
        //     this.DetailtableRowData.splice(0, this.DetailtableRowData.length);
        this.Divdisplay = "block";

        this.userService.getGenericListFormData(this.paramvaluedata, this.SeltableIDForm).subscribe((datacom: any) => {

            console.log(datacom);
            this.displayMain = 'block';
            let reactiveFormData = {};
            let masterProductListArray:any = [];
            this.listFormDataGrid = datacom['listFormDataGrid'];

            this.masterJsonDataListFull = datacom;
            this.masterJsonDataList = datacom['listFormData']['formData'];
            let headerdata = [];

            console.log(this.masterJsonDataList);
            this.DetailtableHeaderData;
            if (this.DetailtableHeaderData.length <= 0) {
                $.each(datacom['listColumnData'], function (index: number, value: string) {
                    if (value['controlType'] != 'empty')
                        headerData.push({ "key": value['columnName'], title: value['columnAliasName'] });

                });
                this.DetailtableHeaderData = headerData;
            }

            if (this.listFormDataGrid != undefined && this.listFormDataGrid != null) {
                this.listFormDataGrid.forEach(function (valueData, indexData) {
                    that.masterJsonDataList.forEach(function (value, index) {
                        let ArrayData: ValidatorFn[] = [];
                        if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier") {
                            let requiredMsg = '';
                            let sizeMsg = '';
                            let customMsg = '';
                            if (value["customValidationMsg"] != "" && value["customValidationMsg"] != null) {
                                let customValidationMsg = JSON.parse(value["customValidationMsg"]);
                                requiredMsg = customValidationMsg['requiredMsg'];
                                sizeMsg = customValidationMsg['sizeMsg'];
                                customMsg = customValidationMsg['customMsg'];
                            }
                            if (value['iS_NULLABLE'].toLowerCase() == "no") {
                                // ArrayData.push(custumValidators.calling('validateRequired' ,value['columnLength'], value['datA_TYPE'], +value['control'], value['columnAliasName'], value['compareColumnName'], value['compareColumnAliasName'], value['range']));

                                ArrayData.push(custumValidation['validateRequired'](requiredMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));
                            }
                            if ((value['datA_TYPE'] != "date" && value['datA_TYPE'] != "datetime" && value['datA_TYPE'] != "bit")) {

                                ArrayData.push(custumValidation['validateSize'](sizeMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));
                            }
                            if (value['validations'] != "" && value['validations'] != null) {

                                let ValName = "'" + value['validations'] + "'"
                                // let custfunction =eval("validatenumeric"+"('" +value['columnLength']+"','"+ value['datA_TYPE']+"','"+ value['control']+"','"+ value['columnAliasName']+"','"+ value['compareColumnName']+"','"+ value['compareColumnAliasName']+"','"+ value['range']+"')");
                                // ArrayData.push(custfunction);
                                if (value['validations'] == "validateGeneric" || value['validations'] == "validateGenericwithRange")
                                    ArrayData.push(custumValidation[eval(ValName)](value['genericRegx'], customMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));
                                else
                                    ArrayData.push(custumValidation[eval(ValName)](customMsg, value['columnLength']?.toString(), value['datA_TYPE']?.toString(), value['controlType']?.toString(), value['columnAliasName']?.toString(), value['compareColumnName']?.toString(), value['compareColumnAliasName']?.toString(), value['range']?.toString()));

                            }
                        }
                        reactiveFormData[value['controlName']] = new FormControl(valueData[value['columN_NAME']], ArrayData);

                    })

                    masterProductListArray.push(that.fb.group(reactiveFormData));

                });

                this.masterProductListForm = this.fb.group({
                    listForm: this.fb.array(masterProductListArray)
                });
                console.log(that.masterProductListForm);
                this.isRenderList = true;
            }
            this.spinner.hide();
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {



                this.spinner.hide();
                //   this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            }
        );



    }

    resetForm() {

        this.Divdisplay = "none";
        this.masterProductForm?.reset();
        let filecomtrols = this.masterJsonData.filter(x => x.control.toLowerCase() == 'file');
        filecomtrols.forEach(function (value, index) {
            let btnfile;
            let fhdn;
            let BtnDownload;
            btnfile = '#btnfile_';
            fhdn = '#fhdn_';
            BtnDownload = '#BtnDownload_';
            $(btnfile + value['columN_NAME']).attr('hidden', 'hidden');
            $(BtnDownload + value['columN_NAME']).attr('hidden', 'hidden');

        });

    }
    exportAsXLSX(): void {
        
        let fileName = this.SeltableIDForm;
        let dataXL = this.DetailtableRowData;
        dataXL.forEach(obj => {
            delete obj['id'];
            delete obj['isSelected'];
            delete obj['param1'];
            delete obj['param2'];
        });
        //alert(fileName.toString())
        this.excelService.exportAsExcelFile(dataXL,dataXL, fileName.toString());
    }

    exportAsPDF(): void {
        let fileName = this.SeltableIDForm;
        // let PDFcolumns = [
        //     {title: "ID", key: "id"}, 
        //     {title: "Name", key: "name"}, 
        //     {title: "Country", key: "country"}, 
        //     {title: "Email", key: "email"}
        // ];
        // let PDFdata = [
        //     {"id": 1, "name": "Shaw", "country": "Tanzania", "email": "abrown@avamba.info"},
        //     {"id": 2, "name": "Nelson", "country": "Kazakhstan", "email": "jjordan@agivu.com"},
        //     {"id": 3, "name": "Garcia", "country": "Madagascar", "email": "jdean@skinte.biz"}
        // ];
        // console.log(PDFcolumns);
        // console.log(this.header);
        // console.log(PDFdata);
        // console.log(this.data)
        let dataXL = this.DetailtableRowData;
        dataXL.forEach(obj => {
            delete obj['id'];
            delete obj['isSelected'];
            delete obj['param1'];
            delete obj['param2'];
        });
        this.excelService.exportAsPDFFile(this.DetailtableHeaderData, dataXL, fileName.toString());
    }

    exportAsDoc(): void {
        let fileName = this.SeltableIDForm;
        // this.excelService.exportAsDoc(this.DetailtableHeaderData, this.DetailtableRowData, this.DetailtableHeaderData.length, this.DetailtableRowData.length, fileName.toString());
    }


    checkUncheckAll() {

        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            value.isSelected = that.masterSelected;
        });
        this.getCheckedItemList();
    }
    isAllSelected() {
        this.masterSelected = this.DetailtableRowData.every(function (item: any) {
            return item.isSelected == true;
        })
        this.getCheckedItemList();
    }

    getCheckedItemList() {
        this.selectedRows = [];
        let that = this;
        this.DetailtableRowData.forEach(function (value:any, index:number) {
            if (value.isSelected) {
                that.selectedRows.push(value);
            }
        });
    }

    sendMessageArrayList() {

    }
    sendEmailArrayList() {

    }
    onActionArrayList() {

        let that = this;
        let flag = true;
        let msg = '';
        let _data;
        let tableNamefor;
        let arrayRow = {};
        let objColumn = <lstFormFinalObjectParam>{};
        let genericColumnListParam:any = [];
        let mylist = '[';
        let GenericRptProcParams: string="";
        GenericRptProcParams = '';
        console.log(this.DetailtableRowData);
        this.aliasesArrayControl().forEach(function (valueControl:any, indexC:number) {
            let objListfinal = <FormFinalObjectParam>{};
            let columnNamefinal = "";
            let columnValuefinal = "";
            that.masterJsonDataList.forEach(function (value, index) {

                if (value["identityColumn"] != "yes" && value["datA_TYPE"] != "uniqueidentifier" && value["insertQueryInclude"] == "yes") {
                    let colValue;
                    let CompareColumnValue;
                    if (value["controlType"] == "checkbox") {
                        let chkValue = valueControl.value[value["controlName"]];
                        if (chkValue == null) {
                            colValue = null;
                        }
                        else if (chkValue == false) {
                            colValue = "false";
                        }
                        else {
                            colValue = "true";
                        }
                    }
                    else {
                        colValue = valueControl.value[value["controlName"]];
                    }

                    if (colValue !== null && colValue !== '') {
                        columnNamefinal = columnNamefinal + value["columN_NAME"] + '^';
                        arrayRow[`${value["columN_NAME"]}`] = colValue;
                        if (value["datA_TYPE"].toLowerCase().toString() == "date" || value["datA_TYPE"].toLowerCase().toString() == "datetime" || value["datA_TYPE"].toLowerCase().toString() == "smalldatetime")
                            columnValuefinal = columnValuefinal + "Date_" + colValue + "^";
                        else
                            columnValuefinal = columnValuefinal + colValue + "^";
                    }

                }
            });

            if (columnNamefinal !== "" && columnValuefinal != "") {
                columnNamefinal = columnNamefinal + "createdBy^createdRoleId^";
                columnValuefinal = columnValuefinal + that.userId + "^" + that.currentRole + "^";
                columnNamefinal = columnNamefinal + "ModifiedBy^modifiedRoleId^";
                columnValuefinal = columnValuefinal + that.userId  + "^" + that.currentRole + "^";
            }

            if (flag == true) {
                objListfinal.tbleName = that.masterJsonDataList[0]['tblname'].toString();
                objListfinal.tbleSequence = "1";
                objListfinal.columnName = columnNamefinal;
                objListfinal.ColumnValue = columnValuefinal;
                genericColumnListParam.push(objListfinal);
            }
        });

        if (genericColumnListParam.length > 0) {
            objColumn.genericColumnListParam = genericColumnListParam;
            console.log(genericColumnListParam);
            _data = JSON.stringify(objColumn);
            this.openCustomActionModal();
            this.paramvaluedata = _data;
        }

    }

    openCustomActionModal() {
        this.globalConfirmService.ConfirmFunction("Confirm Action !!", "Are you sure you want to proceed?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    this.custumActioncall();
                    this.paramvaluedata = '';
                }
            });
    }

    custumActioncall() {

        this.spinner.show();
        let wfStatusId = 1;
        // if (this.isWorkflowImplemented) {
        //     wfStatusId = this.workflowstep == 1 ? 1 : this.workflowStatusId;
        // }
        let param = "uspAppGenericInsUpdateListFormData|tables=" + this.paramvaluedata + "|wfStatusId=" + wfStatusId + "|formName=" + this.SeltableIDForm;
        this.userService.SubmitMultiTableForm(param, this.SeltableIDForm).subscribe((datacom: any) => {
            let resultarray = JSON.parse(datacom);
            // resultarray['successFlag'] 
            // resultarray['resultMessage']
            // resultarray['printAction']
            // resultarray['printActionResult']
            // resultarray['otherAction']
            // resultarray['otherActionResult']
            // resultarray['outputVarResult']
            this.spinner.hide();

            if (resultarray['successFlag'] == "success") {
                this.resetForm();
                this.AlertTitle = "Success!!";
                this.AlertMessage = resultarray['resultMessage'].toString();
                this.AlertClass = "NP_emoji-success-bg";
                this.AlertAnimationType = "smile";
                this.AlertType = "NP_popup_success";
            }

            else {

                this.AlertTitle = "Error!!";
                this.AlertMessage = resultarray['resultMessage'].toString();
                this.AlertClass = "NP_emoji-fail-bg";
                this.AlertAnimationType = "sad";
                this.AlertType = "NP_popup_fail";

            }
        },
            (err: HttpErrorResponse) => {
                this.spinner.hide();
                if (err.status == 401) {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = "You are not authorized!";
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                else if (err.status == 403) {
                    this.Customvalidation.loginroute(err.status);
                }
                else {
                    this.AlertTitle = "Error!!";
                    this.AlertMessage = err.message.toString();
                    this.AlertClass = "NP_emoji-fail-bg";
                    this.AlertAnimationType = "sad";
                    this.AlertType = " NP_popup_fail";
                }
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
            },
            () => {
                this.globalAlertService.AlertMsg(this.AlertTitle, this.AlertMessage, "Ok", this.AlertType, this.AlertClass, this.AlertAnimationType);
                this.isRenderList = false;
            }
        );
    }




    onCloseEditMasterModal(callfrom: number) {
        if (callfrom == 0)
            this.childActionParam = this.selectedItemsActionGroupSpdrp[0].id.toString();
        $('#EditMasterModal').hide();
        this.display = "none";
    }
    onOpenEditMasterModal() {
        $('#EditMasterModal').show();
        this.display = "block";
    }

    calanderClick(inputControl:string) {
        $('#' + inputControl).click();
    }

}