<ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs master-form-tab border-0 mb-4">
  <li [ngbNavItem]="1" *ngIf="addPermission == 'True'">
    <a ngbNavLink (click)="onMainTab();" class="nav-link">Add New Detail</a>
    <ng-template ngbNavContent>
    <!-- Tab panes -->


  <div role="tabpanel" class="tab-panel" id="divAddNewData">


    <div class="inner-content" [ngStyle]="{'display':displayMain}">

      <div id="DivData" class="DivData-content m-0 px-3">
        <!-- <form *ngIf="isRender" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductForm"> -->
        <form *ngIf="isRender && masterProductForm" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductForm">
          <!-- {{masterJsonData.formType}} -->
          <div *ngIf="masterJsonDataFull" class="row">
            <ng-container *ngFor="let formData of masterJsonData; let i=index">

              <ng-container
                *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                      [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                      [(ngModel)]="formData.drpDataSelected"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      (onSelect)="onItemSelect($event,i,false)" (onDeSelect)="OnItemDeSelect($event,i,false)"
                      (onClose)="OnClose($event,formData.controlName)">
                      <c-badge>
                        <ng-template let-item="item">
                          <label class="m-0">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge>
                    </angular2-multiselect>
                    <span class="invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>
                </div>
                <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                      [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                      [(ngModel)]="formData.drpDataSelected"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      (onSelect)="onItemSelect($event,i, true)" (onDeSelect)="OnItemDeSelect($event,i,true)"
                      (onSelectAll)="onItemSelectAll($event,i, true)" (onDeSelectAll)="OnItemDeSelectAll($event,i,true)"
                      (onClose)="OnClose($event,formData.controlName)">
                      <c-badge>
                        <ng-template let-item="item">
                          <label class="m-0">{{item.itemName}}</label>
                        </ng-template>
                      </c-badge>
                    </angular2-multiselect>
                    <span class="invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>
                </div>
                <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                    class='{{formData.controlDivClass}} calnder-icon'>
                    <input type='text' class="{{formData.controlClass}}"
                      (ngModelChange)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                      [bsConfig]="bsConfig" autocomplete="off" readonly
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      formControlName="{{formData.controlName}}">
                    <div class="calenderinput-icon"><a href="javascript: void(0);"
                        (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                          aria-hidden="true"></i></a></div>
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>

                </div>
                <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label"
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                    class="form-check {{formData.controlDivClass}}">

                    <input type='checkbox' value='' class="{{formData.controlClass}}" id="{{formData.controlId}}"
                      (change)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      formControlName="{{formData.controlName}}">
                    <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                      for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                    </label>
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>

                </div>
                <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                      formControlName="{{formData.controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">
                        </textarea>
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>

                </div>
                <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}" [ngClass]="status ? 'fromProfileUpload' : 'danger'">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}} >

                    <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                    [FormReset]=formData.ChildComponentReset
                    [childComponentFormSequence] = formData.childComponentFormSequence
                    [childComponentFileDelete] = formData.childComponentFileDelete
                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,i)"
                      id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                      [controlisDisabled]=formData.isDisabled
                      (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName)'></app-upload-file>

                    <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                      formControlName="{{formData.controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">

                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>

                </div>
                <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                    <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                      id="{{formData.controlId}}" formControlName="{{formData.controlName}}" readonly [disabled]="true"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                      id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      readonly [disabled]="true">
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>                    
                      <app-generic-child-time-picker  showMeridian="true"
                      [timerValue]="formData.controlValue"
                      [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"I",formData.controlName)'
                      [FormReset]=formData.ChildComponentReset
                      [childComponentFormSequence] = formData.childComponentFormSequence
                      [timerControlId] = formData.controlId
                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,i)"
                      id="{{'timerControl_'+formData.controlId}}" 
                      [controlisDisabled]=formData.isDisabled
                     ></app-generic-child-time-picker>

                      <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                      formControlName="{{formData.controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">


                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <app-generic-child-switch-button  
                      [toggleCheckedValue]="formData.controlValue"
                      [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"I",formData.controlName)'
                      [FormReset]=formData.ChildComponentReset
                      [childComponentFormSequence] = formData.childComponentFormSequence
                      [toggleControlId] = formData.controlId
                      (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,i)"
                      id="{{'switchControl_'+formData.controlId}}" 
                      [controlisDisabled]=formData.isDisabled
                     ></app-generic-child-switch-button>

                      <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                      formControlName="{{formData.controlName}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }">


                      
                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>
                  </div>
                </div>
                <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                  <div>
                    <label id="{{formData.spanId}}" class="col-form-label "
                      [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                      for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                    <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                      ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                      tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                  </div>

                  <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                    [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                    <input type='text' class="{{formData.controlClass}}"
                      (change)="textBoxChangeEvent($event,'',formData['customEvent'],false)"
                      [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                      [ngClass]="{'is-invalid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && !masterProductForm.get(formData.controlName)?.valid,'is-valid': (masterProductForm.get(formData.controlName)?.touched || masterProductForm.get(formData.controlName)?.dirty) && masterProductForm.get(formData.controlName)?.valid }"
                      formControlName="{{formData.controlName}}">

                    <span class=" invalid-feedback mt-1">
                      {{masterProductForm.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                    </span>

                  </div>

                </div>
              </ng-container>

            </ng-container>

          </div>




          <div class="Submitbtn-content">
            <button type="button" class="btn btn-secondry" (click)="resetForm(2)">Reset</button>
            <button type="button" *ngIf="formTestPermission =='True'" class="btn btn-info ml-2" (click)="testformData()">Test Form</button> 
            <button type="submit" id="btnSubmit" [disabled]="!masterProductForm.valid"
              class="btn btn-primary ml-2">Submit</button>
          </div>

        </form>
      </div>
    </div>

    <!-- </div> -->
  </div>
</ng-template>
</li>
<li [ngbNavItem]="2">
  <a ngbNavLink class="nav-link"   (click)="onViewTab();">View Detail</a>
  <ng-template ngbNavContent>
  <div role="tabpanel" class="tab-panel "
     id="divViewDataDetail">
    <div [ngStyle]="{'display':displayMainEdit}" style="width:100%; background-color:white;">
      <div class="row m-0">
        <div *ngIf="ischeckboxshow > 0 && dropdownListActionGroupSpdrp && dropdownListActionGroupSpdrp.length > 0" class="col-xl-6 col-sm-12 col-md-6">
          <angular2-multiselect id="DrpActionGroupSP" [data]="dropdownListActionGroupSpdrp"
            [(ngModel)]="selectedItemsActionGroupSpdrp" [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>
      </div>
     
      <app-generic-table-list-view [tbname] ="SeltableID" [formName]="SeltableIDForm" [formType]="1" [editPermission]="editPermission" [printPermission]="printPermission" [isWorkflowImplemented]="isWorkflowImplemented"
      [activePermission]="activePermission" (viewFullDetail)="OnViewFullDetail($event)" [deletePermission]="deletePermission"
      [isRenderDetail]="isRenderDetail" [ischeckboxshow] ="ischeckboxshow"></app-generic-table-list-view>
    </div>
  </div>
</ng-template>
</li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <!-- </div> -->
  <!-- start Modals -->
  <div class="backdrop " [ngStyle]="{'display':display}"></div>
  <!-- start EditMaster Modal -->
  <div id="EditMasterModal" class="modal edit-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg " role="document">
      <div class="modal-content edit-modal-form">
        <form *ngIf="isRenderEdit && masterProductFormEdit" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductFormEdit">
          <div class="modal-header">
            <!-- <h4 class="modal-title pull-left">View</h4> -->
            <h2 class="modal-title pull-left">{{editModelHeading}}</h2>
            <input type="hidden" id="updateId" name="updateId">
            <button type="button" class="close" aria-label="Close" (click)="onCloseEditMasterModal(2,true)"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body-div">
            <div id="DivDataEdit" >

              <!-- <form *ngIf="isRenderEdit" (ngSubmit)="OnSubmitModal()" novalidate [formGroup]="masterProductFormEdit"> -->

              <!-- {{masterJsonData.formType}} -->
              <div *ngIf="masterJsonDataEditFull" class="row m-0">
                <ng-container *ngFor="let formData of masterJsonDataEdit; let i=index">

                  <ng-container
                    *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                    <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                          [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                          [(ngModel)]="formData.drpDataSelected"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          (onSelect)="onItemSelectEdit($event,i,false)"
                          (onDeSelect)="OnItemDeSelectEdit($event,i,false)"
                          (onClose)="OnCloseEdit($event,formData.controlName)">
                          <c-badge>
                            <ng-template let-item="item">
                              <label>{{item.itemName}}</label>
                            </ng-template>
                          </c-badge>
                        </angular2-multiselect>
                        <span class=" invalid-feedback">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                          [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                          [(ngModel)]="formData.drpDataSelected"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          (onSelect)="onItemSelectEdit($event,i,true)" (onDeSelect)="OnItemDeSelectEdit($event,i,true)"
                          (onSelectAll)="onItemSelectEditAll($event,i,true)"
                          (onDeSelectAll)="OnItemDeSelectEditAll($event,i,true)"
                          (onClose)="OnCloseEdit($event,formData.controlName)">
                          <c-badge>
                            <ng-template let-item="item">
                              <label>{{item.itemName}}</label>
                            </ng-template>
                          </c-badge>
                        </angular2-multiselect>
                        <span class=" invalid-feedback">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                        class='{{formData.controlDivClass}} calnder-icon position-relative'>
                        <input type='text' class="{{formData.controlClass}} calnder-icon"
                          [attr.Placeholder]="formData.placeHolder" readonly
                          (ngModelChange)="textBoxChangeEvent($event,'',formData['customEvent'],true)"
                          id="{{formData.controlId}}" bsDatepicker [bsConfig]="bsConfig" autocomplete="off"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          formControlName="{{formData.controlName}}">
                        <div class="calenderinput-icon"><a href="javascript: void(0);"
                            (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                              aria-hidden="true"></i></a></div>
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>

                      </div>

                    </div>
                    <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label"
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                        class="form-check {{formData.controlDivClass}}">

                        <!-- <input type='checkbox' value='' class="{{formData.controlClass}}"
                          id="{{formData.controlId}}"
                         
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          formControlName="{{formData.controlName}}"> -->
                        <input type='checkbox' [checked]="formData.columnValue == true ? true : false"
                          class="{{formData.controlClass}}" id="{{formData.controlId}}"
                          (change)="textBoxChangeEvent($event,'',formData['customEvent'],true)"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          formControlName="{{formData.controlName}}">

                        <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                          for="{{formData.controlId}}">Please check
                          {{formData.columnAliasName}}
                        </label>
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>

                    </div>
                    <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                          [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                          formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }">
                                  </textarea>
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>

                    </div>
                    <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                        <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                          [uploadedFile]=formData.controlValue 
                          [FormReset]=formData.ChildComponentReset
                            [childComponentFormSequence] = formData.childComponentFormSequence
                            [childComponentFileDelete] = formData.childComponentFileDelete
                            [uploadedPreviousFile] = 'formData.previousControlValue'
                          (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,i)"
                          id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                          [controlisDisabled]=formData.isDisabled
                          (upFile)='upFile($event,formData.columN_NAME,"E",formData.controlName)'></app-upload-file>
                        <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                          formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }">

                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>

                    </div>
                    <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                        <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                          id="{{formData.controlId}}" formControlName="{{formData.controlName}}" readonly [disabled]="true"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }">
                        <span class=" invalid-feedback ">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                          id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          readonly [disabled]="true">
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'timepicker'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                          ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>
    
                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        
                          <app-generic-child-time-picker  showMeridian="true"
                          [timerValue]="formData.controlValue"
                          [formName]='formlable' (timeChanged) = 'onTimeChanged($event,formData.columN_NAME,"E",formData.controlName)'
                        [FormReset]="formData.ChildComponentReset"
                        [timerControlId] = formData.controlId
                        [childComponentFormSequence] = "formData.childComponentFormSequence"                        
                          (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,i)"
                          id="{{'timerControl_'+formData.controlId}}" 
                          [controlisDisabled]="formData.isDisabled"></app-generic-child-time-picker>
    
                          <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                          formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }">
    
    
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'switchbutton'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null" class="ml-2"
                          ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>
    
                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>

                        <app-generic-child-switch-button  
                          [toggleCheckedValue]="formData.controlValue"
                          [formName]='formlable' (toggleChanged) = 'onToggleChanged($event,formData.columN_NAME,"E",formData.controlName)'
                        [FormReset]="formData.ChildComponentReset"
                        [toggleControlId] = formData.controlId
                        [childComponentFormSequence] = "formData.childComponentFormSequence"                        
                          (FormResetEvent)="childCReset($event,formData.columN_NAME,'E',formData.controlName,i)"
                          id="{{'switchControl_'+formData.controlId}}" 
                          [controlisDisabled]="formData.isDisabled"></app-generic-child-switch-button>
    
                          <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                          formControlName="{{formData.controlName}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }">
    
    
                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>

                        
                      </div>
                    </div>
                    <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                      <div>
                        <label id="{{formData.spanId}}" class="col-form-label "
                          [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                          for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                        <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                          class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper" placement="left"
                          tooltipClass="tooltipBlue"> <i class="fa fa-info-circle " aria-hidden="true"></i></span>
                      </div>

                      <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                        [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                        <input type='text' class="{{formData.controlClass}}"
                          (change)="textBoxChangeEvent($event,'',formData['customEvent'],true)"
                          [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                          [ngClass]="{'is-invalid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && !masterProductFormEdit.get(formData.controlName)?.valid,'is-valid': (masterProductFormEdit.get(formData.controlName)?.touched || masterProductFormEdit.get(formData.controlName)?.dirty) && masterProductFormEdit.get(formData.controlName)?.valid }"
                          formControlName="{{formData.controlName}}">

                        <span class=" invalid-feedback mt-1">
                          {{masterProductFormEdit.get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                        </span>

                      </div>

                    </div>
                  </ng-container>

                </ng-container>

              </div>

              <!-- </form> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" *ngIf="isHistoryShowProc !=''" class="btn btn-info ml-2" (click)="onHistoryOpenDetail()">History</button> 
            <button type="button" class="btn btn-secondry m-0" (click)="onCloseEditMasterModal(2,true)">Close</button>
            <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'" (click)="testformDataEdit()">Test Form</button> 
            <button type="button" class="btn btn-primary ml-3" [disabled]="!masterProductFormEdit.valid"
              *ngIf="editModelCallfrom == 'update'" (click)="updateEditMasterForm()">Update</button>
            <!-- <button type="button" class="btn btn-primary ml-3"  *ngIf = "masterJsonDataEdit['workFlowApprove']?.toLowerCase() == 'true' && masterJsonDataEdit['workFlowId'] !='0'"
              (click)="DynamicGenericWorkFlow(masterJsonDataEdit['workFlowId'],2)">Approve</button>
              <button type="button" class="btn btn-primary ml-3"  *ngIf = "masterJsonDataEdit['workFlowReject']?.toLowerCase() == 'true' && masterJsonDataEdit['workFlowId'] !='0'"
              (click)="DynamicGenericWorkFlow(masterJsonDataEdit['workFlowId'],3)">Reject</button>
              <button type="button" class="btn btn-primary ml-3"  *ngIf = "masterJsonDataEdit['workFlowReturn']?.toLowerCase() == 'true' && masterJsonDataEdit['workFlowId'] !='0'"
              (click)="DynamicGenericWorkFlow(masterJsonDataEdit['workFlowId'],4)">Return</button>
              <button type="button" class="btn btn-primary ml-3"  *ngIf = "masterJsonDataEdit['workFlowForward']?.toLowerCase() == 'true' && masterJsonDataEdit['workFlowId'] !='0'"
              (click)="DynamicGenericWorkFlow(masterJsonDataEdit['workFlowId'],5)">Forword</button> -->

          </div>
        </form>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->


  <!-- End Modals -->
  <div id="printContaint"  *ngIf="GenericPrintDataArray">
    <app-generic-print [GenericPrintDataArray]="GenericPrintDataArray" style="display: none;"></app-generic-print>
  </div>

