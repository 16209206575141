import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-custum-bloglist',
  templateUrl: './public-custum-bloglist.component.html',
  styleUrls: ['./public-custum-bloglist.component.css']
})
export class PublicCustumBloglistComponent implements OnInit {
  classDetails: any;
  popularClasses: any;
  paramsSub: Subscription | undefined;
  recentBlogs:any;
  constructor(public commonfunction: Commonfunction, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.recentBlogs = this.commonfunction.loadJson("~/../assets/blogs.json");
    console.log(this.recentBlogs);
    

    this.paramsSub = this.route.params.subscribe((params: any) => {
      const routeKey = params['routeKey'];
      console.log(routeKey, "routeKey");

      const allclassesData = this.commonfunction.loadJson("~/../assets/blogslistpage.json");
      console.log(allclassesData);
      
      this.classDetails = allclassesData.find((x:any) =>x.routeKey === routeKey);
      console.log(this.classDetails)
      this.popularClasses = this.classDetails;
    }); 
  }

}
