import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'validateFileSize' })
export class FileSizePipe implements PipeTransform {
    transform(size: number,maxSize: number): boolean {
        
        if( (size / (1024 * 1024)) >  maxSize)
            return false;
        else
            return true;
        
      }
}