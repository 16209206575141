<div id="DivData" class="DivData-content form-header-div-content m-0 p-3">
    <div class="form-header-div">
      <a [routerLink]="backURL">Back</a>
    </div>
         
            <div *ngIf="masterJsonData && isRender && addPermission" class="">
              <ng-container *ngFor="let formNameData of masterJsonData; let ibase=index">
    
                <fieldset class="mb-3 form-header-div-field">
                  <legend>{{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"]) }} </legend>
                  <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">
    
                    <!-- {{masterJsonData.formType}} -->
                    <div class="row m-0">
                      <ng-container *ngFor="let formData of formNameData['formData']; let i=index">
    
                        <ng-container
                          *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                          <div *ngIf="formData.controlType == 'dropdown'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                                [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                                [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelect($event,i,false)" (onDeSelect)="OnItemDeSelect($event,i,false)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'])">
                                <!-- <c-badge>
                            <ng-template let-item="item">
                              <label  class=" m-0">{{item.itemName}}</label>
                            </ng-template>
                          </c-badge> -->
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'mdropdown'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                              <angular2-multiselect id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                                [data]="formData.drpDataSet" [settings]="formData.drpSetting"
                                [(ngModel)]="formData.drpDataSelected"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                (onSelect)="onItemSelect($event,i,true)" (onDeSelect)="OnItemDeSelect($event,i,true)"
                                (onSelectAll)="onItemSelectAll($event,i,true)"
                                (onDeSelectAll)="OnItemDeSelectAll($event,i,true)"
                                (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'])">
                                <!-- <c-badge>
                            <ng-template let-item="item">
                              <label  class=" m-0">{{item.itemName}}</label>
                            </ng-template>
                          </c-badge> -->
                              </angular2-multiselect>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'datetime'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class='{{formData.controlDivClass}} calnder-icon'>
                              <input type='text' class="{{formData.controlClass}}"
                                
                                (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                [bsConfig]="bsConfig" autocomplete="off" readonly
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <div class="calenderinput-icon"><a href="javascript: void(0);"
                                  (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                    aria-hidden="true"></i></a></div>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
    
                            </div>
    
                          </div>
                          <div *ngIf="formData.controlType == 'checkbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label"
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                              class="form-check {{formData.controlDivClass}}">
    
                              <input type='checkbox' value='' class="{{formData.controlClass}}" id="{{formData.controlId}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
                              <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                              </label>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
    
                          </div>
                          <div *ngIf="formData.controlType == 'textarea'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                              <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                            </textarea>
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
    
                          </div>
                          <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
    
                              <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                                [FormReset]=formData.ChildComponentReset
                                [childComponentFormSequence]=formData.childComponentFormSequence
                                [childComponentFileDelete]=formData.childComponentFileDelete
                                [uploadedPreviousFile]='formData.previousControlValue'
                                (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ibase,'parent',i)"
                                id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                [controlisDisabled]=formData.isDisabled
                                (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                              </app-upload-file>
    
    
                              <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
    
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
    
                          </div>
                          <div *ngIf="formData.controlType == 'labelauto'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
    
                              <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                                id="{{formData.controlId}}" formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'readonly'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}" [attr.Placeholder]="formData.placeHolder"
                                id="{{formData.controlId}}" formControlName="{{formData.controlName}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                readonly [disabled]="true">
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                            <div>
                              <label id="{{formData.spanId}}" class="col-form-label "
                                [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                              <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                  aria-hidden="true"></i></span>
                            </div>
    
                            <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                              [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME" class={{formData.controlDivClass}}>
                              <input type='text' class="{{formData.controlClass}}"
                                (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                formControlName="{{formData.controlName}}">
    
                              <span class=" invalid-feedback mt-1">
                                {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                              </span>
    
                            </div>
    
                          </div>
                        </ng-container>
    
                      </ng-container>
    
                    </div>
    
                  </form>
                </fieldset>
              </ng-container>
              <fieldset *ngIf="childJsonData.length > 0" class="tab-fielset p-0 form-header-div-field">
                <!-- <ul class='nav nav-tabs master-form-tab mb-4' role='tablist'>
                  <ng-container *ngFor="let tabData of childJsonData; let iTab=index">
                    <li *ngIf="+tabData['formSequence']==2" role='presentation' class='active'><a class='nav-link active'
                        id="{{'LinkAdd_'+tabData['formAliasName']}}" href="{{'#divcalling_'+tabData['formAliasName']}}"
                        [ngClass]="{'requiredSign': (tabData.formIsRequired?.toLowerCase() === 'true')}"
                        data-toggle='tab'>
                        {{commonfunction.SpaceAfterCapitalLetter(tabData["formAliasName"])}} </a>
                        
                      </li>
                    <li *ngIf="+tabData['formSequence']>2" role='presentation'><a class='nav-link '
                        id="{{'LinkAdd_'+tabData['formAliasName']}}" href="{{'#divcalling_'+tabData['formAliasName']}}"
                        [ngClass]="{'requiredSign': (tabData.formIsRequired?.toLowerCase() === 'true')}"
                        data-toggle='tab'>
                        {{commonfunction.SpaceAfterCapitalLetter(tabData["formAliasName"])}}  </a></li>
                  </ng-container>
                </ul> -->
                <div class='tab-content'>
                  <ul ngbNav #navCreateChild="ngbNav" [(activeId)]="activeTabIdCreate" role='tablist' class="nav-tabs master-form-tab border-0 mb-2">
                  <ng-container *ngFor="let formNameData of childJsonData; let ichild=index">               
                      <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']==2" role='presentation' >
                        <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
                        <ng-template ngbNavContent>
                    <div  id="{{'divcalling_'+formNameData['formAliasName']}}"
                      class='tab-pane  active legendclass' legendsequence="formNameData['formSequence']"
                      legendRequired="formNameData['formIsRequired']" legendName="formNameData['formAliasName']">
                      <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">
    
                        <!-- {{masterJsonData.formType}} -->
                        <div class="row m-0">
                          <ng-container *ngFor="let formData of formNameData['formData']; let i=index">
    
                            <ng-container
                              *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                              <div *ngIf="formData.controlType == 'dropdown'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <angular2-multiselect id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                    [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],false,false)"
                                    (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],false)"
                                    (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                    <c-badge>
                                      <ng-template let-item="item">
                                        <label>{{item.itemName}}</label>
                                      </ng-template>
                                    </c-badge>
                                  </angular2-multiselect>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'mdropdown'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <angular2-multiselect id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                    [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],true,false)"
                                    (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],true)"
                                    (onSelectAll)="onItemSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                    (onDeSelectAll)="OnItemDeSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                    (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                    <c-badge>
                                      <ng-template let-item="item">
                                        <label>{{item.itemName}}</label>
                                      </ng-template>
                                    </c-badge>
                                  </angular2-multiselect>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <!-- Hidden field case for child tables start -->
                              <div *ngIf="formData.controlType == 'hidden'">
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                                  <input type='hidden' id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}">
                                </div>
    
                              </div>
                              <!-- Hidden field case for child tables End -->
                              <div *ngIf="formData.controlType == 'datetime'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class='{{formData.controlDivClass}} calnder-icon'>
                                  <input type='text' class="{{formData.controlClass}}"
                                    (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                    [bsConfig]="bsConfig" autocomplete="off" readonly
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
                                  <div class="calenderinput-icon"><a href="javascript: void(0);"
                                      (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                        aria-hidden="true"></i></a></div>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
    
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'checkbox'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label"
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class="form-check {{formData.controlDivClass}}">
    
                                  <input type='checkbox' value='' class="{{formData.controlClass}}"
                                    id="{{formData.controlId}}"
                                    (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
                                  <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                    for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                                  </label>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'textarea'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                  </textarea>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
    
                                  <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                                    [FormReset]=formData.ChildComponentReset
                                    [childComponentFormSequence]=formData.childComponentFormSequence
                                    [childComponentFileDelete]=formData.childComponentFileDelete
                                    [uploadedPreviousFile]='formData.previousControlValue'
                                    [uploadedFile]=formData.controlValue
                                    (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                    id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                    [controlisDisabled]=formData.isDisabled
                                    (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                                  </app-upload-file>
    
                                  <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
    
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'labelauto'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
    
                                  <input type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'readonly'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <input type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    readonly [disabled]="true">
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <input type='text' class="{{formData.controlClass}}"
                                    (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
    
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
    
                                </div>
    
                              </div>
                            </ng-container>
    
                          </ng-container>
                          <!-- <div class="row"> -->
                          <div class='form-group col-sm-12 text-right'>                          
                            <button type="button" class="btn btn-secondry"
                              (click)="resetChildForm(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                              <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'"  (click)="testformData(GenericFormGroup(formNameData['formAliasName']))">Test Form</button>
                            <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                              *ngIf="!isChildRowEdit"
                              (click)="onAddChildRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                              class="btn btn-primary ml-3">Add</button>
                            <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                              *ngIf="isChildRowEdit"
                              (click)="onAddChildRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                              class="btn btn-primary ml-3">Update Item</button>
                          </div>
                          <!-- </div> -->
                        </div>
    
    
                      </form>
    
                      <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">
    
                        <table *ngIf="childTableDataDynamicArrayList[formNameData['formAliasName']]?.length>0"
                          [id]="formNameData['formDataTableId']" class='table  table-bordered'>
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                <th
                                  *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                  {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                                </th>
                              </ng-container>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let rowData of childTableDataDynamicArrayList[formNameData['formAliasName']]; let icRow=index;"
                              class='Uiparams'>
                              <td>{{(icRow+1)}}</td>
                              <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                <ng-container
                                  *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                                  <td>
                                    <div appEllipsifyLongText>
                                      {{rowData[formChildTableRow['columN_NAME']]}}
                                    </div>
                                  </td>
                                </ng-container>
                                <ng-template #elsedetailTableFile>
                                  <ng-container
                                    *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                    <td class="file-dowmload">
                                      <a href="javascript:void(0)" class='active'
                                        [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                        (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                        ngbTooltip="Download {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                        placement="left" tooltipClass="tooltipBlue">
                                        <i class='fa fa-download'></i></a>
                                      <a href="javascript:void(0)" class='active'
                                        [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                        (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                        ngbTooltip="Open {{formChildTableRow['columnAliasName']}}" container="wrapper"
                                        placement="left" tooltipClass="tooltipBlue">
                                        <i class='fa fa-file-image-o'></i></a>
    
                                    </td>
                                  </ng-container>
                                </ng-template>
                              </ng-container>
                              <td>
                                <a href="javascript:void(0)"
                                  (click)="editChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                  class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                <a href="javascript:void(0)"
                                  (click)="OndeleteChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                  class='delete opendetail'><i class='fa fa-trash'></i></a>
    
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                              <th>Total</th>
                              <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                <th
                                  *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                  {{childTableDataDynamicArrayListSumColumn[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                                </th>
                              </ng-container>
                              <th></th>
                            </tr>
                          </tfoot>
                        </table>
    
                      </div>
    
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="(ichild+1)"*ngIf="+formNameData['formSequence']>2" role='presentation'>
                  <a ngbNavLink  class="nav-link" [ngClass]="{'requiredSign': (formNameData.formIsRequired?.toLowerCase() === 'true')}"> {{commonfunction.SpaceAfterCapitalLetter(formNameData["formAliasName"])}}</a>
                  <ng-template ngbNavContent>
                    <div id="{{'divcalling_'+formNameData['formAliasName']}}"
                      class=' tab-pane  legendclass' legendsequence="formNameData['formSequence']"
                      legendRequired="formNameData['formIsRequired']" legendName="formNameData['formAliasName']">
                      <form *ngIf="isRender && GenericFormGroup(formNameData['formAliasName'])" novalidate [formGroup]="GenericFormGroup(formNameData['formAliasName'])">
    
                        <!-- {{masterJsonData.formType}} -->
                        <div class="row m-0">
                          <ng-container *ngFor="let formData of formNameData['formData']; let i=index">
    
                            <ng-container
                              *ngIf="formData.identityColumn?.toLowerCase() !=='yes' && formData.datA_TYPE?.toLowerCase() !=='uniqueidentifier'">
                              <div *ngIf="formData.controlType == 'dropdown'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <angular2-multiselect id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                    [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],false,false)"
                                    (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],false)"
                                    (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                    <c-badge>
                                      <ng-template let-item="item">
                                        <label class=" m-0">{{item.itemName}}</label>
                                      </ng-template>
                                    </c-badge>
                                  </angular2-multiselect>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'mdropdown'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <angular2-multiselect id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" [data]="formData.drpDataSet"
                                    [settings]="formData.drpSetting" [(ngModel)]="formData.drpDataSelected"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    (onSelect)="onItemSelectChild($event,i,+formNameData['formSequence'],true,false)"
                                    (onDeSelect)="OnItemDeSelectChild($event,i,+formNameData['formSequence'],true)"
                                    (onSelectAll)="onItemSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                    (onDeSelectAll)="OnItemDeSelectChildAll($event,i,+formNameData['formSequence'],true)"
                                    (onClose)="OnClose($event,formData.controlName,formNameData['formAliasName'] )">
                                    <c-badge>
                                      <ng-template let-item="item">
                                        <label class=" m-0">{{item.itemName}}</label>
                                      </ng-template>
                                    </c-badge>
                                  </angular2-multiselect>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <!-- Hidden field case for child tables start -->
                              <div *ngIf="formData.controlType == 'hidden'">
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME">
                                  <input type='hidden' id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}">
                                </div>
    
                              </div>
                              <!-- Hidden field case for child tables End -->
                              <div *ngIf="formData.controlType == 'datetime'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class='{{formData.controlDivClass}} calnder-icon'>
                                  <input type='text' class="{{formData.controlClass}}"
                                    (ngModelChange)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}" bsDatepicker
                                    [bsConfig]="bsConfig" autocomplete="off" readonly
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
                                  <div class="calenderinput-icon"><a href="javascript: void(0);"
                                      (click)="calanderClick(formData.controlId)"><i class="fa fa-calendar"
                                        aria-hidden="true"></i></a></div>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
    
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'checkbox'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label"
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class="form-check {{formData.controlDivClass}}">
    
                                  <input type='checkbox' value='' class="{{formData.controlClass}}"
                                    id="{{formData.controlId}}"
                                    (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
                                  <label id="lbl_{{formData.columN_NAME}}" class="form-check-label"
                                    for="{{formData.controlId}}">Please check {{formData.columnAliasName}}
                                  </label>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'textarea'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <textarea rows='2' cols='50' type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                    </textarea>
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'file'" class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
    
                                  <app-upload-file [fileValidationstring]=formData.miscellaneousType [formName]='formlable'
                                    [FormReset]=formData.ChildComponentReset
                                    [childComponentFormSequence]=formData.childComponentFormSequence
                                    [childComponentFileDelete]=formData.childComponentFileDelete
                                    [uploadedPreviousFile]='formData.previousControlValue'
                                    [uploadedFile]=formData.controlValue
                                    (FormResetEvent)="childCReset($event,formData.columN_NAME,'I',formData.controlName,formNameData.formAliasName, ichild,'child',i)"
                                    id="{{'fileControl_'+formData.controlId}}" [fileColumnName]=formData.columnAliasName
                                    [controlisDisabled]=formData.isDisabled
                                    (upFile)='upFile($event,formData.columN_NAME,"I",formData.controlName,formNameData.formAliasName)'>
                                  </app-upload-file>
    
                                  <input type='hidden' id="{{formData.controlId}}" class="{{formData.controlClass}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
    
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
    
                              </div>
                              <div *ngIf="formData.controlType == 'labelauto'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
    
                                  <input type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}" readonly [disabled]="true"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }">
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'readonly'"
                                class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <input type='text' class="{{formData.controlClass}}"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    formControlName="{{formData.controlName}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    readonly [disabled]="true">
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
                                </div>
                              </div>
                              <div *ngIf="formData.controlType == 'textbox'" class="form-group {{formData.pageStyleclass}}">
                                <div>
                                  <label id="{{formData.spanId}}" class="col-form-label "
                                    [ngClass]="{'requiredSign': (formData.iS_NULLABLE?.toLowerCase() === 'no')}"
                                    for="{{formData.controlId}}">{{formData.columnAliasName}}</label>
                                  <span *ngIf="formData.columnInformation != '' && formData.columnInformation != null"
                                    class="ml-2" ngbTooltip="{{formData.columnInformation}}" container="wrapper"
                                    placement="left" tooltipClass="tooltipBlue"> <i class="fa fa-info-circle "
                                      aria-hidden="true"></i></span>
                                </div>
    
                                <div id="{{formData.controlDivId}}" [attr.columnAliasName]="formData.columnAliasName"
                                  [attr.colIndex]="i" [attr.columnId]="formData.columN_NAME"
                                  class={{formData.controlDivClass}}>
                                  <input type='text' class="{{formData.controlClass}}"
                                    (change)="textBoxChangeEvent($event,formNameData['formAliasName'],formData['customEvent'],false)"
                                    [attr.Placeholder]="formData.placeHolder" id="{{formData.controlId}}"
                                    [ngClass]="{'is-invalid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && !GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid,'is-valid': (GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.touched || GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.dirty) && GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.valid }"
                                    formControlName="{{formData.controlName}}">
    
                                  <span class=" invalid-feedback mt-1">
                                    {{GenericFormGroup(formNameData['formAliasName']).get(formData.controlName)?.errors?.['GenericError']['errorMsg']}}
                                  </span>
    
                                </div>
    
                              </div>
                            </ng-container>
    
                          </ng-container>
    
                          <div class='form-group col-sm-12 text-right'>
                            <button type="button" class="btn btn-secondry"
                              (click)="resetChildForm(formNameData['formAliasName'],+formNameData['formSequence'],ichild,2)">Reset</button>
                              <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'"  (click)="testformData(GenericFormGroup(formNameData['formAliasName']))">Test Form</button>
                            <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                            *ngIf="!isChildRowEdit"
                              (click)="onAddChildRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                              class="btn btn-primary ml-3">Add</button>
                            <button type="button" [disabled]="!GenericFormGroup(formNameData['formAliasName']).valid"
                              *ngIf="isChildRowEdit"
                              (click)="onAddChildRow($event,'E',formNameData['formAliasName'],+formNameData['formSequence'],ichild)"
                              class="btn btn-primary ml-3">Update Item</button>    
                          </div>
                        </div>
    
    
                      </form>
    
                      <div class="mt-4 pt-4 mb-4 mx-0 px-3 overflow-auto border-top inner-content">
    
                        <table *ngIf="childTableDataDynamicArrayList[formNameData['formAliasName']]?.length>0"
                          [id]="formNameData['formDataTableId']" class='table  table-bordered'>
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                <th
                                  *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                  {{FirstLetterUpper(formChildTableHeader['columnAliasName'])}}
                                </th>
                              </ng-container>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let rowData of childTableDataDynamicArrayList[formNameData['formAliasName']]; let icRow=index;"
                              class='Uiparams'>
                              <td>{{(icRow+1)}}</td>
                              <ng-container *ngFor="let formChildTableRow of formNameData['formData']; let i=index">
                                <ng-container>
                                  <td
                                    *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() != 'file'; else elsedetailTableFile">
                                    <div appEllipsifyLongText>
                                      {{rowData[formChildTableRow['columN_NAME']]}}
                                    </div>
    
                                  </td>
                                </ng-container>
                                <ng-template #elsedetailTableFile>
                                  <ng-container>
                                    <td class="file-dowmload"
                                      *ngIf="formChildTableRow['columN_NAME']?.toLowerCase() != 'id' && formChildTableRow['columN_NAME']?.toLowerCase() != 'guid' && formChildTableRow['control']?.toLowerCase() != 'hid' && formChildTableRow['control']?.toLowerCase() == 'file'">
                                      <a href="javascript:void(0)" class='active'
                                        [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                        (click)="onfiledownloadDetail($event,1, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                        ngbTooltip="Download item - {{formChildTableRow['toolTipRow']}}" container="wrapper"
                                        placement="left" tooltipClass="tooltipBlue">
                                        <i class='fa fa-download'></i></a>
                                      <a href="javascript:void(0)" class='active'
                                        [hidden]="ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]) =='-'"
                                        (click)="onfiledownloadDetail($event,2, ReplaceNullDataTable(rowData[formChildTableRow['columN_NAME']]),formChildTableRow['columnAliasName'])"
                                        ngbTooltip="Open item - {{formChildTableRow['toolTipRow']}}" container="wrapper"
                                        placement="left" tooltipClass="tooltipBlue">
                                        <i class='fa fa-file-image-o'></i></a>
                                    </td>
                                  </ng-container>
                                </ng-template>
                              </ng-container>
                              <td>
                                <a href="javascript:void(0)"
                                  (click)="editChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                  class='edit opendetail'><i class='fa fa-pencil'></i></a>
                                <a href="javascript:void(0)"
                                  (click)="OndeleteChildTableRow($event,'I',formNameData['formAliasName'],+formNameData['formSequence'],icRow)"
                                  class='delete opendetail'><i class='fa fa-trash'></i></a>
    
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr *ngIf="formNameData['formDataSumColumn']?.length >0">
                              <th>Total</th>
                              <ng-container *ngFor="let formChildTableHeader of formNameData['formData']; let i=index">
                                <th
                                  *ngIf="formChildTableHeader['columN_NAME']?.toLowerCase() != 'id' && formChildTableHeader['columN_NAME']?.toLowerCase() != 'guid' && formChildTableHeader['control']?.toLowerCase() != 'hid'">
                                  {{childTableDataDynamicArrayListSumColumn[formNameData['formAliasName']][formChildTableHeader['columN_NAME']]}}
                                </th>
                              </ng-container>
                              <th></th>
                            </tr>
                          </tfoot>
                        </table>
    
                      </div>
                    </div>
                  </ng-template>
                </li>
              
                  </ng-container>
                </ul>
                <div [ngbNavOutlet]="navCreateChild" class="mt-2"></div>
                </div>
              </fieldset>
              <br>
              <div class="d-flex mt-4 form-header-div-capture">
                <div class="capture-code m-0">
                  <app-captcha (captchVerifictionEvent)="onCaptchVerification($event)" class="w-100" [isShowErrorMsg]="true"
                  [isShowValidateMsg]="false" [pageReset]="isPageReset"></app-captcha>
                  </div> 
      
      
                <div  *ngIf="masterJsonData && isRender && addPermission" class="Submitbtn-content">
                  <button type="button" class="btn btn-secondry" (click)="openResetModal(parentFormAliasName,1,0,2)">Reset</button>
                  <button type="button" class="btn btn-info ml-2" *ngIf="formTestPermission =='True'"  (click)="testformData(GenericFormGroup(parentFormAliasName))">Test Form</button>
                  <button type="submit" id="btnSubmit" [disabled]="isFormDisabled()"
                  (click)="OnSubmitModal(parentFormAliasName)"
                    class="btn btn-primary ml-2">Submit</button>
                </div>
              </div>
              
            </div>
         
        </div>
        
    
       
      <!-- End Modals -->
      <div id="printContaint" *ngIf="GenericPrintDataArray">
        <app-generic-print [GenericPrintDataArray]="GenericPrintDataArray" style="display: none;"></app-generic-print>
      </div>
    
  