import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserObjectParam, lstUserObjectParam, ObjectParam, lstObjectParam,masterFormRowData } from "../object-param.model";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterFormRowdataPopupComponent} from  './master-form-rowdata-popup.component';

@Injectable({
  providedIn: 'root'
})
export class MasterFormRowdataPopupService {

  constructor(private modalService: NgbModal) { }

  public updateViewMasterFormRowDataModelFunction(   
    ViewUpdateRowData: masterFormRowData,
    dropDownValues:any[],
    AlertDialogType: string, 
    actionButtonText: string,  
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(MasterFormRowdataPopupComponent, {fullscreen:true, backdrop:false});
    // modalRef.componentInstance.DialogTitle = title;
    // modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.AlertDialogType = AlertDialogType + " custom-action-modal";
    modalRef.componentInstance.actionButtonText = actionButtonText ;
    modalRef.componentInstance.ViewUpdateRowData = ViewUpdateRowData;
    modalRef.componentInstance.dropDownValues = dropDownValues;
    return modalRef.result;
  }
}
