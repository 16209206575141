import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AlertDialogBoxComponent} from '../alert-dialog-box/alert-dialog-box.component';

@Injectable({
  providedIn: 'root'
})
export class AlertDialogBoxService {

  constructor(private modalService: NgbModal) { }

  public AlertFunction(
    title: string,
    message: string,
    btnOkText: string = 'OK123',
    AlertDialogType: string,
    AlertDialogClass: string,
    AlertDialogAnimationType: string,
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(AlertDialogBoxComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.AlertDialogTitle = title;
    modalRef.componentInstance.AlertDialogMessage = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.AlertDialogType = AlertDialogType + " custom-action-modal";
    modalRef.componentInstance.AlertDialogClass = AlertDialogClass ;
    modalRef.componentInstance.AlertDialogAnimationType = AlertDialogAnimationType;

    return modalRef.result;
  }
}
