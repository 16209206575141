import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-generic-print',
  templateUrl: './generic-print.component.html',
  styleUrls: ['./generic-print.component.css']
})
export class GenericPrintComponent implements OnInit {
@Input() GenericPrintDataArray: any=[];
  constructor() { }

  ngOnInit(): void {
  }

}
