<!-- <div class="backdrop " style="display: block;"></div> -->
<div class="edit-modal-form">
    <div class="modal-header">
        <h2 class="modal-title pull-left">Classes</h2>
        <button type="button" class="close" style="top: 0;" (click)="onCloseConfirmModal()" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body" [formGroup]="updateEditMasterForm">
        <div class="row">
            <div class="col-md-4">
                <div class="ImageShowBox">
                    <label class="ImageShow">
                        <div class="img_box">
                            <img [src]="allpopupdata.image" alt="Image Preview">
                        </div>
                        <div class="hoverImg">
                            <div class="hover_content">
                                <img style="width: 30px; display: block;" class="hoverImgIcon"
                                    src="assets\img\Mask group1.png" alt="Upload Icon">
                                <span class="text-light">Upload</span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div class="mt-2 col-md-8">
                <div>
                    <label for="">Title</label>
                    <input placeholder="Add New Heading" class="form-control" type="text" formControlName="title">
                </div>

                <div>
                    <label for="">Description</label>
                    <textarea placeholder="Add New Description" class="form-control" type="text"
                        formControlName="description"></textarea>
                </div>
            </div>

            <div class="mt-2 col-md-6">
                <label for="">Class Time</label>
                <input placeholder="Add New Sub Heading" class="form-control" type="text" formControlName="timing">
            </div>

            <div class="mt-2 col-md-6">
                <label for="">class Size</label>
                <input placeholder="Add New Description" class="form-control" type="text" formControlName="classsize">
            </div>
            <div class="mt-2 col-md-6">
                <label for="">Duration</label>
                <input placeholder="Add New Description" class="form-control" type="text" formControlName="duration">
            </div>
            <div class="mt-2 col-md-6">
                <label for="">Router Link</label>
                <input placeholder="Add New Description" class="form-control" type="text" formControlName="routerlink">
            </div>
        </div>

        <div class="Submitbtn-content mt-5">
            <button type="button" class="btn btn-secondry" (click)="onCloseConfirmModal()">cancel</button>
            <button type="submit" id="btnSubmit" class="btn btn-primary ml-2" (click)="OnClickSubmit()">Submit</button>
        </div>
    </div>
</div>