import { Component, OnInit } from '@angular/core';
import { Commonfunction } from 'src/app/shared/commonutility';

@Component({
  selector: 'app-public-custum-blog',
  templateUrl: './public-custum-blog.component.html',
  styleUrls: ['./public-custum-blog.component.css']
})
export class PublicCustumBlogComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 9;
  totalPages = 0;
  visiblePages: (number | string)[] = [];
  items: any[] = [];

  constructor(public commonfunction: Commonfunction) { }

  ngOnInit(): void {
    const allclassesData = this.commonfunction.loadJson("~/../assets/blogs.json");
    this.items = Array.isArray(allclassesData) ? allclassesData : [allclassesData[0]];
    this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
    this.updateVisiblePages();
  }


  get paginatedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.items.slice(startIndex, startIndex + this.itemsPerPage);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateVisiblePages();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateVisiblePages();
    }
  }

  goToPage(page: number | string) {
    if (typeof page === 'number' && page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updateVisiblePages();
    }
  }


  updateVisiblePages() {
    const visibleCount = 4;
    if (this.totalPages <= visibleCount) {
      this.visiblePages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      this.visiblePages = [];
      const middleOffset = Math.floor(visibleCount / 2);
      if (this.currentPage <= middleOffset) {
        this.visiblePages = [1, 2, 3, 4];
      } else if (this.currentPage >= this.totalPages - middleOffset) {
        this.visiblePages.push(this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages);
      } else {
        this.visiblePages.push(this.currentPage - 1, this.currentPage, this.currentPage + 1);
      }
    }
  }

}
