import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Iclasses} from '../../allUsedModule'

@Component({
  selector: 'app-classes-page-model',
  templateUrl: './classes-page-model.component.html',
  styleUrls: ['./classes-page-model.component.css']
})
export class ClassesPageModelComponent implements OnInit {
  @Input() allpopupdata :any;
  updateEditMasterForm !:FormGroup;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.updateEditMasterForm = new  FormGroup({
      title:new FormControl(this.allpopupdata.title , [Validators.required]),
      description:new FormControl(this.allpopupdata.description , [Validators.required]),
      timing:new FormControl(this.allpopupdata.classTime , [Validators.required]),
      classsize:new FormControl(this.allpopupdata.classSize , [Validators.required]),
      duration:new FormControl(this.allpopupdata.duration , [Validators.required]),
      routerlink:new FormControl(this.allpopupdata.routerlink , [Validators.required]),
    })
  }

  onCloseConfirmModal(){
    this.activeModal.close();
  }

  OnClickSubmit(){
    let _item :  Iclasses = {} as Iclasses;
    this.allpopupdata.title = this.updateEditMasterForm.get("title")?.value;
    this.allpopupdata.description = this.updateEditMasterForm.get("description")?.value;
    this.allpopupdata.classTime = this.updateEditMasterForm.get("timing")?.value;
    this.allpopupdata.classSize = this.updateEditMasterForm.get("classsize")?.value;
    this.allpopupdata.duration = this.updateEditMasterForm.get("duration")?.value;
    this.allpopupdata.routerlink = this.updateEditMasterForm.get("routerlink")?.value;
    this.activeModal.close(true);
  }
}

