import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { async } from '../../../../node_modules/@angular/core/testing';
import { Customvalidation } from "../../shared/Validation";
import { TableColumnName } from "../../shared/user.model";
import { Commonfunction } from "../../shared/commonutility";
import { ObjectParam, lstObjectParam, FormObjectSubmitParam, lstFormObjectSubmitParam, FormFinalObjectParam, lstFormFinalObjectParam } from "../../shared/object-param.model";
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpClient } from '@angular/common/http';
//import * as jQuery from 'jquery';
// import  'src/typings';
import 'jquery';
import { Subject } from 'rxjs';
declare let jQuery: any;

@Component({
  selector: 'app-wfmaster',
  templateUrl: './wfmaster.component.html',
  styleUrls: ['./wfmaster.component.css']
})
export class WFMasterComponent implements OnDestroy, OnInit {
  param:string = "";
  menulabel:any='';
  formlable:any='';
  display = "none";
  AlertMessage: any;
  AlertTitle: any;
  AlertClass: any = "NP_emoji-success-bg";
  AlertType: any = "modal NP_popup_success";
  AlertAnimationType: any = "smile";
  chkwftable:any;
  paramvaluedata:any;
  dtOptions: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,
    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 300,
    scrollX: true,
    scrollCollapse: true,
    fixedHeader: {
      header: true,
      footer: true
    },

    dom: 'Bfltip',

    buttons: ['print', 'excel']

  };
  dtOptionsEdit: any = {
    pagingType: 'full_numbers',
    pageLength: 10,
    autoWidth: true,
    responsive: true,

    lengthMenu: [[2, 5, 10, 25, 50, -1], [2, 5, 10, 25, 50, "All"]],
    scrollY: 400,
    scrollX: true,
    scrollCollapse: true,
    fixedHeader: {
      header: true,
      footer: true
    },

  };
  dtTrigger: Subject<any> = new Subject();
  dtTriggerEdit: Subject<any> = new Subject();
  constructor(public commonfunction: Commonfunction, public Customvalidation: Customvalidation, private userService: UserService, private router: Router, public zone: NgZone, private ref: ChangeDetectorRef, private spinner: NgxSpinnerService, private http: HttpClient) {
    this.chkwftable = [];

  }

  ngOnInit() {

    let paramjs = this.commonfunction.getMenuItemValues(sessionStorage.getItem("menuItem"));
    jQuery('#SeltableID').val(paramjs.formValue);
    jQuery('#SeltableIDForm').val(paramjs.formName);
    this.menulabel = paramjs.menu;
    this.formlable = paramjs.formName;
    this.pageloaddrp();
    jQuery('#DivMain').hide();
    jQuery('#DivMainEdit').hide();
    this.onMainTab();


  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }

  pageloaddrp() {
    let item = '';
    let itemMulti = '';
    
    this.userService.getWorkFlowPageLoadDetails(this.formlable).subscribe((res: any) => {
      let seloption = " <select class='form-control selectpickerclass'  id='DrptableWFLevel' data-live-search='true'><option data-tokens='--Select WF Level--' value = '0'> --Select WF Level--</option>";
      let seloptionrole = " <select class='form-control selectpickerclass'  id='Drptablerole' data-live-search='true'><option data-tokens='--Select Role--' value = '0'> --Select Role--</option>";
      let seloptionEdit = " <select class='form-control selectpickerclass'  id='DrptableWFLevelE' data-live-search='true'><option data-tokens='--Select WF Level--' value = '0'> --Select WF Level--</option>";
      let seloptionroleEdit = " <select class='form-control selectpickerclass'  id='DrptableroleE' data-live-search='true'><option data-tokens='--Select Role--' value = '0'> --Select Role--</option>";

      jQuery.each(res.table, function (index:any, value1:any) {
        seloption = seloption + "<option data-tokens='" + value1.drpOption + "'  value='" + value1.drpValue + "'>" + value1.drpOption + "</option>";
        seloptionEdit = seloptionEdit + "<option data-tokens='" + value1.drpOption + "'  value='" + value1.drpValue + "'>" + value1.drpOption + "</option>";

      });
      jQuery.each(res.table1, function (index:any, value1:any) {
        seloptionrole = seloptionrole + "<option data-tokens='" + value1.drpOption + "'  value='" + value1.drpValue + "'>" + value1.drpOption + "</option>";
        seloptionroleEdit = seloptionroleEdit + "<option data-tokens='" + value1.drpOption + "'  value='" + value1.drpValue + "'>" + value1.drpOption + "</option>";

      });
      seloption = seloption + '</select>';
      seloptionrole = seloptionrole + '</select>';
      seloptionEdit = seloptionEdit + '</select>';
      seloptionroleEdit = seloptionroleEdit + '</select>';
      jQuery("#SelDiv").html(seloption);
      jQuery("#SelDivRole").html(seloptionrole);
      jQuery("#SelDivEdit").html(seloptionEdit);
      jQuery("#SelDivRoleEdit").html(seloptionroleEdit);
      jQuery('.selectpickerclass').selectpicker();
    },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          sessionStorage.setItem('userToken', '');
          this.Customvalidation.loginroute(err.status);
          return false;
        }
        return true;
      });

  }
  onMainTab() {
    //   if(jQuery('#ViewTableHeader')[0].innerHTML !="")
    // {
    //     jQuery('#wftableid').DataTable().destroy();
    // }
    //   if(jQuery('#EditTableHeader')[0].innerHTML !="")
    //   {
    //     jQuery('#wfEdittableid').DataTable().destroy();
    //   } 
    jQuery('#btnView').show();
    jQuery('#btnSearch').show();
    jQuery('#btnResetView').hide();
    jQuery('#btnReset').show();
    jQuery('#btnEditSearch').hide();
    jQuery('#Formheading').html('Enter Work Flow Details');
    jQuery('#EditTableHeader').html('');
    jQuery('#EditTableBody').html('');
    jQuery('#ViewTableHeader').html('');
    jQuery('#ViewTableBody').html('');
    jQuery('SeltableIDEdit').val('');
    jQuery('#DivDataEdit').html('');
    jQuery('#tblHeadEdit').html('');
    jQuery('#DivMainEdit').hide();
    jQuery('#divContainer').show();
    jQuery('#DivMain').hide();

  }
  onViewTab() {

    // if(jQuery('#ViewTableHeader')[0].innerHTML !="")
    // {
    //     jQuery('#wftableid').DataTable().destroy();

    // }
    this.SearchEdit(1);
    this.paramvaluedata = '';
    jQuery('#DivMasterUpdateData').html('');
    jQuery('#ViewTableHeader').html('');
    jQuery('#ViewTableBody').html('');
    jQuery('#tblHead').html('');
    jQuery('#DivMain').hide();
    jQuery('#DivMainEdit').show();
  }
  onCloseViewModal() {


    if (jQuery('#ViewTableHeader')[0].innerHTML != "") {
      jQuery('#wftableid').DataTable().destroy();

    }
    jQuery(".backdropCustom").css({ display: "none" });
    jQuery("#ViewTableHeader").html('');
    jQuery("#ViewTableBody").html('');
    jQuery('#ViewModal').hide();
    this.display = 'none';
  }
  openAlertModal() {

    jQuery('#AlertModal').show();
    this.display = 'block';
  }

  onCloseAlertModal() {
    jQuery('#AlertModal').hide();
    jQuery('#AlertModalJs').hide();
    jQuery(".AlertbackdropCustom").css({ display: "none" });
    this.display = 'none';
  }
  AddNewLevel(callfor:string) {
    
    let wfid;
    let roleid;
    let wfName;
    let wfclass;
    if (callfor == "S") {
      wfid = jQuery('#DrptableWFLevel').val();
      roleid = jQuery('#Drptablerole').val();
      wfName = jQuery('#txtWorkFlowName').val();
      wfclass = "Uiparams";
    }
    else {
      wfid = jQuery('#DrptableWFLevelE').val();
      roleid = jQuery('#DrptableroleE').val();
      wfName = jQuery('#txtWorkFlowNameE').val();
      wfclass = "UiparamsE";
    }
    if ((wfid && roleid) &&(wfid > 0 && roleid > 0 && wfName != "")) {
      if (callfor == "S") {
        let wf = jQuery('#DrptableWFLevel option:selected').text();
        let role = jQuery('#Drptablerole option:selected').text();
        let indexwf = jQuery('#DrptableWFLevel option:selected').index();
        let indexrole = jQuery('#Drptablerole option:selected').index();
        // let wfName =jQuery('#txtWorkFlowName').val();
        jQuery('#Drptablerole option:eq(' + indexrole + ')').remove();
        jQuery('#DrptableWFLevel option:eq(' + indexwf + ')').remove();
        let newwf = jQuery('#DrptableWFLevel')[0].innerHTML;
        let newrole = jQuery('#Drptablerole')[0].innerHTML;
        jQuery('#SelDiv').empty();
        jQuery('#SelDivRole').empty();
        jQuery('#SelDiv').html('');
        jQuery('#SelDivRole').html('');
        let seloption = "<select class='form-control selectpickerclass'  id='DrptableWFLevel' data-live-search='true'>" + newwf + "</select>";
        let seloptionrole = "<select class='form-control selectpickerclass'  id='Drptablerole' data-live-search='true'>" + newrole + "</select>";
        jQuery("#SelDiv").html(seloption);
        jQuery("#SelDivRole").html(seloptionrole);
        jQuery('.selectpickerclass').selectpicker();
        let wftr = "<tr class='" + wfclass + "' columnName='wfId^wfLevel^wfRole' columnValue='NewIdGenrated^" + wfid + "^" + roleid + "' wfnameid='NewId' roleid='" + roleid + "' wfid='" + wfid + "' wf='" + wf + "'  role='" + role + "' ><td>" + wf + "</td><td>" + role + "</td><td> <button class='btn btn-link opendetail' onclick=deleteWfRow(this,'P')><i class='fa fa-close' style='font-size:24px;color:red'></i></button></td></tr>";
        jQuery('#wrkflowtr').append(wftr);
      }
      else {
        let wf = jQuery('#DrptableWFLevelE option:selected').text();
        let role = jQuery('#DrptableroleE option:selected').text();
        let indexwf = jQuery('#DrptableWFLevelE option:selected').index();
        let indexrole = jQuery('#DrptableroleE option:selected').index();
        // let wfName =jQuery('#txtWorkFlowName').val();
        jQuery('#DrptableroleE option:eq(' + indexrole + ')').remove();
        jQuery('#DrptableWFLevelE option:eq(' + indexwf + ')').remove();
        let newwf = jQuery('#DrptableWFLevelE')[0].innerHTML;
        let newrole = jQuery('#DrptableroleE')[0].innerHTML;
        jQuery('#SelDivEdit').empty();
        jQuery('#SelDivRoleEdit').empty();
        jQuery('#SelDivEdit').html('');
        jQuery('#SelDivRoleEdit').html('');
        let seloptionEdit = "<select class='form-control selectpickerclass'  id='DrptableWFLevelE' data-live-search='true'>" + newwf + "</select>";
        let seloptionroleEdit = "<select class='form-control selectpickerclass'  id='DrptableroleE' data-live-search='true'>" + newrole + "</select>";
        jQuery("#SelDivEdit").html(seloptionEdit);
        jQuery("#SelDivRoleEdit").html(seloptionroleEdit);
        jQuery('.selectpickerclass').selectpicker();
        let wftr = "<tr class='" + wfclass + "' columnName='wfId^wfLevel^wfRole' columnValue='" + jQuery('#updateId').val() + "^" + wfid + "^" + roleid + "' wfnameid='NewId' roleid='" + roleid + "' wfid='" + wfid + "' wf='" + wf + "'  role='" + role + "' ><td>" + wf + "</td><td>" + role + "</td><td> <button class='btn btn-link opendetail' onclick=deleteWfRow(this,'E')><i class='fa fa-close' style='font-size:24px;color:red'></i></button></td></tr>";
        jQuery('#wrkflowtrE').append(wftr);
      }
    }
    else if (wfid == 0) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Select Work Flow Level.";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else if (roleid == 0) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Select Role.";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else if (wfName == "") {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Enter Work Flow Name.";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }

  }
  OnSubmit() {
    let that = this;
    
    let result;
    let _data;
    let genericColumnListParam = [];
    let objListfinal = <FormFinalObjectParam>{};
    let objColumn = <lstFormFinalObjectParam>{};
    if (jQuery('#txtWorkFlowName').val() != "") {
      result = this.Customvalidation.validatealphanumeric(jQuery('#txtWorkFlowName').val(), '', '', '', 'Work Flow Name', '', '', '');

    }
    if (jQuery('#txtWorkFlowName').val() == "") {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Enter Work Flow Name";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else if (result && !result[0]) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = result[1];
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";

    }
    else if (jQuery('#wfTable tr').length <= 2) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Enter atleast 2 level";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else {
      this.chkwftable = [];
      jQuery('.Uiparams').each(()=> {
        let wfid = jQuery(this).closest('tr').attr('wfid');
        let roleId = jQuery(this).closest('tr').attr('roleid');
        let wftext = jQuery(this).closest('tr').attr('wf');
        let roletext = jQuery(this).closest('tr').attr('role');
        that.chkwftable.push({ 'Wid': wfid, 'rid': roleId, 'wftext': wftext, 'roletext': roletext });

      });
      if (this.chkwftable[0].Wid != 1) {
        this.openAlertModal();
        this.AlertTitle = "Alert!!";
        this.AlertMessage = "First level must be Creator.";
        this.AlertClass = "NP_emoji-error-bg";
        this.AlertAnimationType = "NP_emoji-error";
        this.AlertType = "modal NP_popup_error";
      }
      else if (this.chkwftable[this.chkwftable.length - 1].Wid != 3) {
        this.openAlertModal();
        this.AlertTitle = "Alert!!";
        this.AlertMessage = "Last Level must be Approver.";
        this.AlertClass = "NP_emoji-error-bg";
        this.AlertAnimationType = "NP_emoji-error";
        this.AlertType = "modal NP_popup_error";
      }
      else {

        if (jQuery('#txtWorkFlowName').val() != "") {
          let workflowName = jQuery('#txtWorkFlowName').val() ? jQuery('#txtWorkFlowName').val()?.toString():'';
          let objListfinal = <FormFinalObjectParam>{};
          objListfinal.tbleName = "tblWorkFlowMaster";
          objListfinal.tbleSequence = "1";
          objListfinal.columnName = "text";
          objListfinal.ColumnValue =  workflowName ? workflowName :'';
          genericColumnListParam.push(objListfinal);
        }

        jQuery('.Uiparams').each((event:any) =>{
          let tbleName = '';
          let tbleSequence = '';
          let columnName: string|null|undefined="";
          let ColumnValue: string|null|undefined=""
          let objListfinal = <FormFinalObjectParam>{};
          tbleName = "tblWorkFlowMasterChild";
          tbleSequence = "2";
          columnName = jQuery(this).closest('tr').attr('columnName');
          ColumnValue = jQuery(this).closest('tr').attr('columnValue');
          objListfinal.tbleName = tbleName;
          objListfinal.tbleSequence = tbleSequence;
          objListfinal.columnName = columnName ? columnName : '';
          objListfinal.ColumnValue = ColumnValue ? ColumnValue?.replace(/"/g, "'"): '';
          genericColumnListParam.push(objListfinal);
        });
        console.log(genericColumnListParam);
        if (genericColumnListParam.length > 0) {
          objColumn.genericColumnListParam = genericColumnListParam;
          _data = JSON.stringify(objColumn);

        }

        this.openSubmitModal();
        this.paramvaluedata = _data;

      }

    }


  }
  // submitcall() {
  //   this.spinner.show();
  //   this.userService.CustomSubmitForm(this.paramvaluedata, this.formlable).subscribe((datacom: any) => {


  //     if (datacom != "") {
  //       var resultarray = datacom.split("-");

  //       if (resultarray[1] == "success") {

  //         this.pageloaddrp();
  //         jQuery('#wrkflowtr').empty();
  //         jQuery('#wrkflowtr').html();
  //         jQuery('#txtWorkFlowName').val('');
  //         this.AlertTitle = "Success!!";
  //         this.AlertMessage = resultarray[0].toString();
  //         this.AlertClass = "NP_emoji-success-bg";
  //         this.AlertAnimationType = "smile";
  //         this.AlertType = "modal NP_popup_success";
  //       }

  //       else {

  //         this.AlertTitle = "Error!!";
  //         this.AlertMessage = resultarray[0].toString();
  //         this.AlertClass = "NP_emoji-fail-bg";
  //         this.AlertAnimationType = "sad";
  //         this.AlertType = "modal NP_popup_fail";

  //       }
  //     }
  //     this.spinner.hide();
  //   },
  //     (err: HttpErrorResponse) => {
  //       this.spinner.hide();
  //       console.log(err.message);
  //       if (err.status == 401) {
  //         this.AlertTitle = "Error!!";
  //         this.AlertMessage = "You are not authorized!";
  //         this.AlertClass = "NP_emoji-fail-bg";
  //         this.AlertAnimationType = "sad";
  //         this.AlertType = "modal NP_popup_fail";
  //       }
  //       else if (err.status == 403) {
  //         this.Customvalidation.loginroute(err.status);
  //       }
  //       else {
  //         this.AlertTitle = "Error!!";
  //         this.AlertMessage = err.message.toString();
  //         this.AlertClass = "NP_emoji-fail-bg";
  //         this.AlertAnimationType = "sad";
  //         this.AlertType = "modal NP_popup_fail";
  //       }
  //     });

  //   this.onCloseSubmitModal();
  //   this.openAlertModal();

  // }
  openSubmitModal() {

    jQuery('#SubmitModal').show();
    this.display = 'block';
  }
  onCloseSubmitModal() {
    this.paramvaluedata = '';
    jQuery('#SubmitModal').hide();
    this.display = 'none';
  }
  SearchEdit(callfrom: number) {
    this.spinner.show();
    
    let tablename;
    if (callfrom == 1) {
      tablename = "tblWorkFlowMaster";
    }
    else if (callfrom == 2) {
      tablename = "tblWorkFlowMaster";
    }
    jQuery('#DivMainEdit').show();
    if (jQuery('#EditTableHeader')[0].innerHTML != "") {
      jQuery('#wfEdittableid').DataTable().destroy();

    }

    let that = this;

    jQuery('#tblHeadEdit').html(jQuery("#Drptable option:selected").text());

    this.userService.getWFViewForm(this.formlable).subscribe((data: any) => {
      
      let TrData = '';
      let ThHeader:string|null|undefined="";
      let TableId:string|null|undefined="";
      if (data.table1.length > 0) {
        ThHeader = "<tr><th>S.No.</th>";
        jQuery.each(data.table, function (index:any, value:any) {
          if (value["columN_NAME"].toLowerCase() != 'id' && value["columN_NAME"].toLowerCase() != 'guid') {
            ThHeader = ThHeader + "<th>" + value["columnAliasName"] + "</th>";
            TableId = value["tableName"];
          }
        });
        ThHeader = ThHeader + "<th>Action</th></tr>";
        jQuery.each(data.table1, function (index: number, value1:any) {
          TrData = TrData + "<tr  fName='" + jQuery("#SeltableIDForm").val() + "'  tblcolid='" + value1['id'] + "' tblcolguid='" + value1['guid'] + "'><td>" + (index + 1) + "</td>";
          jQuery.each(data.table, function (index:any, value:any) {
            if (value["columN_NAME"].toLowerCase() != 'id' && value["columN_NAME"].toLowerCase() != 'guid') {
              if (value["controlType"].toLowerCase() != "file") {
                TrData = TrData + "<td>" + that.commonfunction.ReplaceNullDataTable(value1[that.FirstLetterLower(value["columN_NAME"])]) + "</td>";
              }
              else {
                TrData = TrData + "<td><div columnAliasName='" + value["columnAliasName"].toLowerCase() + "'><button type='button'  class='btn btn-link ' onclick=filedownloadview(this,'" + that.commonfunction.ReplaceNullDataTable(value1[that.FirstLetterLower(value["columN_NAME"])]) + "')><i class='fa fa-download' style='font-size:24px;'></i></button></div> </td>";
              }
            }
          });
          TrData = TrData + "<td><button type='button' onClick=GetWFById(" + value1['id'] + ",'V') class='btn btn-link opendetail'><i class='fa fa-folder-open' style='font-size:24px'></i></button>";
          TrData = TrData + "<button type='button' onClick=GetWFById(" + value1['id'] + ",'E') class='btn btn-link opendetail'><i class='fa fa-edit' style='font-size:24px'></i></button>";
          if (value1['isActive'] == true) {
            TrData = TrData + "<button type='button'  onClick=CustomFormActionActivesubmit(" + value1['id'] + ",'InActive','" + TableId + "') class='btn btn-link opendetail' ><i class='fa fa-plus-square' style='font-size:24px;color:green'></i></button>";

          }
          else {
            TrData = TrData + "<button type='button' onClick=CustomFormActionActivesubmit(" + value1['id'] + ",'Active','" + TableId + "') class='btn btn-link opendetail' ><i class='fa fa-minus-square' style='font-size:24px;color:red'></i></button>";

          }
          TrData = TrData + "<button type='button'  onClick=CustomFormActionActivesubmit(" + value1['id'] + ",'Deleted','" + TableId + "') class='btn btn-link opendetail' ><i class='fa fa-close' style='font-size:24px;color:red'></i></button></td>";

          TrData = TrData + "</tr>";

        });
        jQuery("#EditTableHeader").html('');
        jQuery("#EditTableBody").html('');
        jQuery("#EditTableHeader").html(ThHeader);
        jQuery("#EditTableBody").html(TrData);
        this.dtTriggerEdit.next(true);
      }
      else {
        ThHeader = '';
        TrData = '';
        ThHeader = "<tr><th>S.No.</th>";
        jQuery.each(data.table, function (index:any, value:any) {
          if (value["columN_NAME"].toLowerCase() != 'id' && value["columN_NAME"].toLowerCase() != 'guid') {
            ThHeader = ThHeader + "<th>" + value["columnAliasName"] + "</th>";
          }
        });
        ThHeader = ThHeader + "<th>Action</th></tr>";
        jQuery("#EditTableHeader").html(ThHeader);
        this.dtTriggerEdit.next(true);
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err.message);
        if (err.status == 401) {
          this.openAlertModal();
          this.AlertTitle = "Error!!";
          this.AlertMessage = "You are not authorized!";
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.openAlertModal();
          this.AlertTitle = "Error!!";
          this.AlertMessage = err.message.toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";
        }
      });

  }
  FirstLetterLower(value:string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }
  onCloseEditMasterModal() {
    jQuery('#EditMasterModal').hide();
    jQuery("#DivDataEdit").html('');
    jQuery(".backdropCustom").css({ display: "none" });
    this.pageloaddrp();
  }
  updateEditMasterForm() {
    
    let that = this;
    
    let result;
    let _data;
    let genericColumnListParam = [];
    let objListfinal = <FormFinalObjectParam>{};
    let objColumn = <lstFormFinalObjectParam>{};
    if (jQuery('#txtWorkFlowNameE').val() != "") {
      result = this.Customvalidation.validatealphanumeric(jQuery('#txtWorkFlowNameE').val(), '', '', '', 'Work Flow Name', '', '', '');

    }
    if (jQuery('#txtWorkFlowNameE').val() == "") {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Enter Work Flow Name";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else if (result && !result[0]) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = result[1];
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";

    }
    else if (jQuery('#wfTableE tr').length <= 2) {
      this.openAlertModal();
      this.AlertTitle = "Alert!!";
      this.AlertMessage = "Please Enter atleast 2 level";
      this.AlertClass = "NP_emoji-error-bg";
      this.AlertAnimationType = "NP_emoji-error";
      this.AlertType = "modal NP_popup_error";
    }
    else {
      this.chkwftable = [];
      jQuery('.UiparamsE').each(()=> {
        let wfid = jQuery(this).closest('tr').attr('wfid');
        let roleId = jQuery(this).closest('tr').attr('roleid');
        let wftext = jQuery(this).closest('tr').attr('wf');
        let roletext = jQuery(this).closest('tr').attr('role');
        that.chkwftable.push({ 'Wid': wfid, 'rid': roleId, 'wftext': wftext, 'roletext': roletext });

      });
      if (this.chkwftable[0].Wid != 1) {
        this.openAlertModal();
        this.AlertTitle = "Alert!!";
        this.AlertMessage = "First level must be Creator.";
        this.AlertClass = "NP_emoji-error-bg";
        this.AlertAnimationType = "NP_emoji-error";
        this.AlertType = "modal NP_popup_error";
      }
      else if (this.chkwftable[this.chkwftable.length - 1].Wid != 3) {
        this.openAlertModal();
        this.AlertTitle = "Alert!!";
        this.AlertMessage = "Last Level must be Approver.";
        this.AlertClass = "NP_emoji-error-bg";
        this.AlertAnimationType = "NP_emoji-error";
        this.AlertType = "modal NP_popup_error";
      }
      else {

        if (jQuery('#txtWorkFlowNameE').val() != "") {
          let workflowName =  jQuery('#txtWorkFlowNameE').val()?.toString();
          let objListfinal = <FormFinalObjectParam>{};
          objListfinal.tbleName = "tblWorkFlowMaster";
          objListfinal.tbleSequence = "1";
          objListfinal.columnName = "text";
          objListfinal.ColumnValue = workflowName ? workflowName : '';
          genericColumnListParam.push(objListfinal);
        }

        jQuery('.UiparamsE').each(()=> {
          let tbleName = '';
          let tbleSequence = '';
          let columnName: string|null|undefined = '';
          let ColumnValue: string|null|undefined = '';
          let objListfinal = <FormFinalObjectParam>{};
          tbleName = "tblWorkFlowMasterChild";
          tbleSequence = "2";
          columnName = jQuery(this).closest('tr').attr('columnName');
          ColumnValue = jQuery(this).closest('tr').attr('columnValue');
          objListfinal.tbleName = tbleName;
          objListfinal.tbleSequence = tbleSequence;
          objListfinal.columnName = columnName ? columnName : '';
          objListfinal.ColumnValue = ColumnValue? ColumnValue?.replace(/"/g, "'"):'';
          genericColumnListParam.push(objListfinal);
        });
        console.log(genericColumnListParam);
        if (genericColumnListParam.length > 0) {
          objColumn.genericColumnListParam = genericColumnListParam;
          _data = JSON.stringify(objColumn);

        }

        this.openEditMasterModal();
        this.paramvaluedata = _data;

      }

    }
  }
  UpdateEditcall() {
    this.spinner.show();
    this.userService.CustomEditForm(this.paramvaluedata, this.formlable).subscribe((datacom: any) => {


      if (datacom != "") {
        var resultarray = datacom.split("-");

        if (resultarray[1] == "success") {
          this.SearchEdit(2);
          this.AlertTitle = "Success!!";
          this.AlertMessage = resultarray[0].toString();
          this.AlertClass = "NP_emoji-success-bg";
          this.AlertAnimationType = "smile";
          this.AlertType = "modal NP_popup_success";
          this.onCloseEditMasterModal();
        }

        else {

          this.AlertTitle = "Error!!";
          this.AlertMessage = resultarray[0].toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";

        }

      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err.message);
        if (err.status == 401) {
          this.openAlertModal();
          this.AlertTitle = "Error!!";
          this.AlertMessage = "You are not authorized!";
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";
        }
        else if (err.status == 403) {
          this.Customvalidation.loginroute(err.status);
        }
        else {
          this.openAlertModal();
          this.AlertTitle = "Error!!";
          this.AlertMessage = err.message.toString();
          this.AlertClass = "NP_emoji-fail-bg";
          this.AlertAnimationType = "sad";
          this.AlertType = "modal NP_popup_fail";
        }
      });

    jQuery(".backdropCustom").css({ display: "none" });
    this.onCloseEditMasterConformationModal();
    this.openAlertModal();

  }
  openEditMasterModal() {

    jQuery('#EditMasterConformationModal').show();
    this.display = 'block';
  }
  onCloseEditMasterConformationModal() {
    this.paramvaluedata = '';
    jQuery('#EditMasterConformationModal').hide();
    this.display = 'none';
  }

  onCloseActionActiveModal() {
    jQuery('#ActionActiveModal').hide();
    jQuery(".backdropCustom").css({ display: "none" });
    //    this.display='none'; 
  }

  CustomFormActionActivesubmit() {
    // this.spinner.show();
    // jQuery('#ActionActiveModal').hide();
    // jQuery(".backdropCustom").css({ display: "none" });
    // let varactionId:string = '' ;
    // let recordId:string =  '' ;
    // let TId:string = '';
    // this.userService.CustomFormActionActiavesubmit('1', '2', '3', '4').subscribe((data: any) => {
    //   if (data != "") {
    //     var resultarray = data.split("-");
    //     if (varactionId == "Active" || varactionId == "InActive") {

    //       if (resultarray[1] == "success") {
    //         this.spinner.hide();
    //         this.SearchEdit(2);
    //         this.openAlertModal();
    //         this.AlertTitle = "Success!!";
    //         this.AlertMessage = "Data " + varactionId;
    //         this.AlertClass = "NP_emoji-success-bg";
    //         this.AlertAnimationType = "smile";
    //         this.AlertType = "modal NP_popup_success";
    //         return;

    //       }
    //       else {
    //         this.spinner.hide();
    //         this.openAlertModal();
    //         this.AlertTitle = "Alert!!";
    //         this.AlertMessage = "Data cannot be deactive";
    //         this.AlertClass = "NP_emoji-error-bg";
    //         this.AlertAnimationType = "NP_emoji-error";
    //         this.AlertType = "modal NP_popup_error";
    //         return;

    //       }
    //     }
    //     if (varactionId == "Deleted") {
    //       if (resultarray[1] == "success") {
    //         this.spinner.hide();
    //         this.SearchEdit(2);
    //         this.openAlertModal();
    //         this.AlertTitle = "Success!!";
    //         this.AlertMessage = "Data " + varactionId;
    //         this.AlertClass = "NP_emoji-success-bg";
    //         this.AlertAnimationType = "smile";
    //         this.AlertType = "modal NP_popup_success";
    //         return;

    //       }
    //       else {
    //         this.spinner.hide();
    //         this.openAlertModal();
    //         this.AlertTitle = "Alert!!";
    //         this.AlertMessage = "Data cannot be deleted";
    //         this.AlertClass = "NP_emoji-error-bg";
    //         this.AlertAnimationType = "NP_emoji-error";
    //         this.AlertType = "modal NP_popup_error";
    //         return;

    //       }
    //     }
    //   }
    //   this.spinner.hide();
    // },
    //   (err: HttpErrorResponse) => {
    //     this.spinner.hide();
    //     if (err.status == 401) {
    //       this.openAlertModal();
    //       this.AlertTitle = "Error!!";
    //       this.AlertMessage = "You are not authorized!";
    //       this.AlertClass = "NP_emoji-fail-bg";
    //       this.AlertAnimationType = "sad";
    //       this.AlertType = "modal NP_popup_fail";
    //     }
    //     else if (err.status == 403) {
    //       this.Customvalidation.loginroute(err.status);
    //     }
    //     else {
    //       this.openAlertModal();
    //       this.AlertTitle = "Error!!";
    //       this.AlertMessage = err.message.toString();
    //       this.AlertClass = "NP_emoji-fail-bg";
    //       this.AlertAnimationType = "sad";
    //       this.AlertType = "modal NP_popup_fail";
    //     }
    //   });

  }


}

